import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Accordion, Button, Col, Nav, Row, Tab, Table } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { crossRed, plusV2 } from "../../../assets/images";
import { useSelector } from "react-redux";


export default function Basic(props: any) {
    const { packageDatalist, handleStepsFormData, loading, editPackageData, apiError } = props;
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm();

    const [packDesc, setpackDesc] = useState('');
    const [afetrDesc, setafetrDesc] = useState('');
    const [terms, setTerms] = useState('');
    const [imageProfile, setProfileImage] = useState<any>([]);
    const [image, setImage] = useState<any>([]);
    const [existImage, setExistImage] = useState([]);
    const [branches, setBranches] = useState<any>([]);

    useEffect(() =>{
        const filterBranch = branches?.filter((item:any) => {
            return item !== branchId
        })
        setBranches([...filterBranch,branchId]);
    },[branchId])

    useEffect(() => {
        if (editPackageData) {
            reset();
            setValue('ServiceAvailableFor', editPackageData.ServiceAvailableFor);
            setpackDesc(editPackageData.PackageDescription);
            setafetrDesc(editPackageData.PackageAfterCareDescription);
            setTerms(editPackageData.TermsAndConditions);
            setExistImage(editPackageData?.image);
            const filterBranch = branches?.filter((item:any) => {
                return !editPackageData?.branchId?.includes(item);
            } )
            setBranches([...filterBranch, ...editPackageData?.branchId]);
        }
    }, [editPackageData]);

    function submitBasicInfo(basicdata: any) {
        let basicInfoObj = {
            ...basicdata,
            PackageDescription: packDesc,
            PackageAfterCareDescription: afetrDesc,
            TermsAndConditions: terms,
            image: image,
            existImage: existImage,
            branchId: branches
        };
        handleStepsFormData(basicInfoObj, 'step1');

    }

    const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files) {
            const multipleImages: any[] = [];
            for (let i = 0; i < files.length; i++) {
                multipleImages.push(files[i]);
            }

            if (multipleImages.length !== 0) {
                const imageUrls = multipleImages.map((data: File) => {
                    return { blob: URL.createObjectURL(data), original: data };
                });

                setProfileImage([...imageProfile, ...imageUrls]);
                setImage([...image, ...multipleImages]);
            }
        }
    };


    const handleDeleteImage = (img: any, i: any, type: any) => {
        if (type === "update") {
            setExistImage(existImage?.filter((image: any, index: any) => {
                return index !== i
            }))
        }
        const filterBlob = imageProfile?.filter((blob: any) => {
            return blob !== img
        })

        const filterImage = image?.filter((image: any) => {
            return image !== img.original
        });
        setProfileImage(filterBlob);
        setImage(filterImage)
    }

    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
            ["link"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
        ],
    };

    const handleAbout = (newContent: string, input: string) => {
        if (input === "description") {
            setpackDesc(newContent);
            return;
        }
        if (input === "after") {
            setafetrDesc(newContent);
            return;
        }
        if (input === "term") {
            setTerms(newContent);
            return;
        }
    };

    return (
        <div>
            <Row>
                <Col lg={6} md={8}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Package Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Package Name"
                            defaultValue={editPackageData?.PackageName}
                            {...register("PackageName", {
                                required: true,
                            })}
                        />
                        {errors?.PackageName?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                        {apiError && (
                            <p className={"errorMessage"}>{apiError}</p>
                        )}
                    </div>
                </Col>
                <Col lg={6}></Col>
                <Col lg={6} md={6} className="ms-auto mb-3">
                    <div className={`${st.formBox}`}>
                        <label className="form-label">
                            Service available for
                        </label>
                        <ul className={`${st.listUl}`}>
                            <li>
                                <label className={`${st.radio}`}>
                                    <input
                                        type="radio"
                                        value={"male"}
                                        {...register("ServiceAvailableFor", {
                                            required: true,
                                        })}
                                    />
                                    <span className={`${st.checkmark}`}></span> Male
                                </label>
                            </li>
                            <li>
                                <label className={`${st.radio}`}>
                                    <input
                                        type="radio"
                                        value={"female"}
                                        {...register("ServiceAvailableFor", {
                                            required: true,
                                        })}
                                    />
                                    <span className={`${st.checkmark}`}></span> Female
                                </label>
                            </li>
                            <li>
                                <label className={`${st.radio}`}>
                                    <input
                                        type="radio"
                                        value={"anyone"}
                                        {...register("ServiceAvailableFor", {
                                            required: true,
                                        })}
                                    />
                                    <span className={`${st.checkmark}`}></span> Anyone
                                </label>
                            </li>
                        </ul>
                        {errors?.ServiceAvailableFor?.type === "required" && (
                            <p className={"errorMessage"}>
                                Please select a gender
                            </p>
                        )}
                    </div>
                </Col>
                <Col lg={6} md={6}></Col>

                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <label htmlFor="gallery">Gallery</label>
                        <div className={`${cx.galleryBox}`}>
                            <div className={`${cx.item} ${cx.add}`}>
                                <label htmlFor="galleryImage">
                                    <img
                                        src={plusV2}
                                        alt="add"
                                        className={`${cx.add}`}
                                    />
                                    <input type="file" multiple value={""} id="galleryImage" onChange={handleImage} />
                                </label>
                            </div>
                            {imageProfile.map((image: any, i: any) => {
                                return (
                                    <div className={`${cx.item} `} key={i}>
                                        <img src={crossRed} alt="delete-icon" onClick={() => handleDeleteImage(image, i, "add")} className={`${cx.deleteCross}`} />
                                        <img
                                            src={image.blob}
                                            className={cx.bgImage}
                                            alt="img"

                                        />
                                    </div>
                                )
                            })}

                            {existImage?.length !== 0 && existImage?.map((image: any, i: any) => {
                                return (
                                    <div className={`${cx.item} `} key={i}>
                                        <img src={crossRed} alt="delete-icon" onClick={() => handleDeleteImage(image, i, "update")} className={`${cx.deleteCross}`} />
                                        <img
                                            src={image}
                                            className={cx.bgImage}
                                            alt="img"

                                        />
                                    </div>
                                )
                            })}
                        </div>
                        {/* {errorTrig && image.length === 0 && (
                            <p className={"errorMessage"}>This field is required</p>
                        )} */}
                    </div>
                </Col>

                <Col lg={6} md={8}>
                    <div className={`${st.formBox} ${cx.editorBox}`}>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <label htmlFor="Details">Package Description</label>
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className={`${cx.editorContainer}`}>
                                        <ReactQuill
                                            value={packDesc}
                                            onChange={(newContent) =>
                                                handleAbout(newContent, "description")
                                            }
                                            modules={modules}
                                        />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Col>
                <Col lg={6}></Col>

                <Col lg={6} md={8}>
                    <div className={`${st.formBox} ${cx.editorBox}`}>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <label htmlFor="Details">
                                        Package After Care Description
                                    </label>
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className={`${cx.editorContainer}`}>
                                        <ReactQuill
                                            value={afetrDesc}
                                            onChange={(newContent) =>
                                                handleAbout(newContent, "after")
                                            }
                                            modules={modules}
                                        />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Col>
                <Col lg={6}></Col>
                <Col lg={6} md={8}>
                    <div className={`${st.formBox} ${cx.editorBox}`}>
                        <Accordion>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    <label htmlFor="Details">
                                        Terms and Conditions
                                    </label>
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className={`${cx.editorContainer}`}>
                                        <ReactQuill
                                            value={terms}
                                            onChange={(newContent) =>
                                                handleAbout(newContent, "term")
                                            }
                                            modules={modules}
                                        />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Col>

                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => navigate(-1)}
                            >
                                Back
                            </button>
                            <button
                                className={`btn ${st.btn4}`}
                                onClick={handleSubmit(submitBasicInfo)} disabled={loading}>
                                {loading ? <LoadingBtn /> : 'Save'}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
