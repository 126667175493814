import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import AssignPage from "../../../components/Business/Modals/AssignPage";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";

const Packages = () => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);

  const [showAssignModal, setShowAssignModal] = useState(false);
  const handleAssignModalClose = () => setShowAssignModal(false);
  const handleAssignModalShow = () => setShowAssignModal(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [packageData, setPackageData] = useState<any>([]);
  
  useEffect(() => {
    if (branchId) {
      getPackages();
    }
  }, [branchId]);

  const getPackages = async () => {
    let res = await httpRequest(`getPackages?branchId=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setPackageData(res?.data);
    }
  };

  const canCreatePackage = subModuleRoles?.['Branch Set Up']?.Packages?.Create;

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Packages</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>
        <div className={`${st.buttonsAction} ${cx.actionBtn}`}>
          {canCreatePackage && <ul>
            <li>
              <ExportBtn />
            </li>
            <li>
              <ImportBtn />
            </li>
            <li>
              <NavLink to="addPackage/basicInfo" className={`btn ${st.darkBtn}`}>
                <img src={plus} alt="Create new Package" /> Add a new Package
              </NavLink>
            </li>
          </ul>}
        </div>

        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <DataGridTable packageData={packageData} getPackages={getPackages} />
          </div>
        </div>
      </section>
      <AssignPage
        showAssignModal={showAssignModal}
        handleAssignModalClose={handleAssignModalClose}
        page={"Package"}
      />
    </>
  );
};

export default Packages;
