import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";

import { plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";

const BrannchAmenities = () => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const [activeButton, setActiveButton] = useState<String>("all");
  const [amenities, setAmenities] = useState<any>([]);
  const [amenityList, setAmenityList] = useState<any>([]);
  const [globalAmenities, setGlobalAmenities] = useState<any>([]);

  useEffect(() => {
    if (branchId) {
      getAmenities();
    }
  }, [branchId]);

  const getAmenities = async () => {
    setAmenities([])
    const res = await httpRequest(`getAmenities?branchId=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status == true) {
      setAmenityList(res?.data);
    }
  };

  const getGlobbalAmenities = async () => {
      let res = await httpRequest(`getGlobalAmenities?businessId=${businessData?._id}`, "get", null, "json");
      if (res.status) {
        setGlobalAmenities(res?.data);
      }
    
  };

  useEffect(() => {
    getGlobbalAmenities();
  }, [businessData, amenityList])

  useEffect(() => {
    if (globalAmenities?.length !== 0) {
      setAmenities([...amenityList, ...globalAmenities?.filter((g: any) => !amenityList?.some((data: any) => data?.AmenitiesGlobalList?._id === g?._id))?.map((item: any, index: any) => {
        return {
          available: "0",
          AmenitiesGlobalList: item,
          occupied: "0",
          qty: "0",
          status: true,
          _id: item?._id,
          global: true,
        }
      })])
    }
  }, [globalAmenities])
  const canCreateAmnt = subModuleRoles?.['Branch Set Up']?.Amenities?.Create;

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle} `}>
          <div className={`${st.titleInfo}`}>
            <h5>Amenities</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.buttonsAction} ${st.buttonflexDiv} `}>
          <div className={`${st.threeButton} ${cx.threeBtn}`}>
            {/* <ul>
              <li>
                <button className={`${st.btn} ${activeButton === "all" ? st.active : ""}`} onClick={() => setActiveButton("all")}>All</button>
              </li>
              <li>
                <button className={`${st.btn} ${activeButton === "available" ? st.active : ""}`} onClick={() => setActiveButton("available")}>Available</button>
              </li>
              <li>
                <button className={`${st.btn} ${activeButton === "occupied" ? st.active : ""}`} onClick={() => setActiveButton("occupied")}>Occupied</button>
              </li>
            </ul> */}
          </div>

          {canCreateAmnt && <ul className={cx.btnGroup}>
            <li>
              <ExportBtn />
            </li>
            <li>
              <ImportBtn />
            </li>
            <li>
              <NavLink to="addAmenities" className={`btn ${st.darkBtn}`}>
                <img src={plus} alt="Create new Service" /> Add a New Item
              </NavLink>
            </li>
          </ul>}
        </div>

        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <DataGridTable amenities={amenities} getAmenities={getAmenities} activeButton={activeButton}/>
          </div>
        </div>
      </section>
    </>
  );
};

export default BrannchAmenities;
