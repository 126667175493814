import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Col, Row } from "react-bootstrap";
import {
    union,
    facebookIcon,
    instagram,
    deleteCross,
} from "../../../assets/images";
import { AiFillCamera } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";

export default function About(props: any) {
    const { handleStepsFormData, editBranchData, loading } = props;
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [images, setImages] = useState<any>([]);
    const [previewImage, setPreviewImage] = useState<any>([]);
    const [oldImage, setoldImage] = useState<any>([]);
    const [about, setAbout] = useState<any>('');
    const [briefAbout, setBriefAbout] = useState<any>('');
    const [brieferr, setBrieferr] = useState("");
    useEffect(() => {
        if (editBranchData) {
            reset();
            if (editBranchData?.about) {
                setAbout(editBranchData?.about);
            }
            if (editBranchData?.briefAbout) {
                setBriefAbout(editBranchData?.briefAbout);
            }
            if (editBranchData?.image && editBranchData?.image?.length !== 0) {
                setoldImage(editBranchData?.image);
            }
        }
    }, [editBranchData]);


    useEffect(() => {
        if (businessData) {
            setoldImage(businessData?.image)
            setAbout(businessData?.about)
            setBriefAbout(businessData?.briefAbout);
        }
    }, [businessData])

    const submitHandler = async (postdata: any) => {
        if (briefAbout?.length >= 900) {
            setBrieferr("Input too long: exceeds 900-character limit.");
            return;
        }
        let aboutFormVals = {
            facebook: postdata.facebook,
            instagram: postdata.instagram,
            website: postdata.website,
            about: about,
            briefAbout: briefAbout,
            oldImages: JSON.stringify(oldImage),
            image: images,
        }
        handleStepsFormData(aboutFormVals, 'step2');
    };

    const handleAbout = (newContent: string, type: string) => {
        if (type === "about") {
            setAbout(newContent);
        } else {
            if (newContent.length <= 900) {
                setBriefAbout(newContent)
                setBrieferr("")
            } else {
                setBriefAbout(newContent)
                setBrieferr("Maximum character limit (900) exceeded!");
            }

        }
    };

    function onFileChange(event: any) {
        let files = event.target.files;
        let profileImg = [...images];
        for (let i = 0; i < files.length; i++) {
            let image: File = files[i];
            profileImg.push(image);
            if (files[i]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target && e.target.result) {
                        let img = e.target.result as string;
                        setPreviewImage((prevImages: any) => [...prevImages, img]);
                    }
                };
                reader.readAsDataURL(files[i]);
            }
        };
        setImages(profileImg);
    }

    function handleRemoveImg(id: string) {
        let newSelected: any = [...previewImage];
        newSelected.splice(id, 1);
        setPreviewImage(newSelected);
        let imgs: any = [...images];
        imgs.splice(id, 1);
        setImages(imgs);
    }

    function handleRemoveOldImg(id: string) {
        let newSelected: any = [...oldImage];
        newSelected.splice(id, 1);
        setoldImage(newSelected);
    }

    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
            ["link"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
        ],
    };

    return (
        <>
        <Row>
        <Col md={12}>
                  <div className="text-end">
                    <HelpButon number={34} type={"help"} className={"btn"} />
                  </div>
                </Col>
                <form onSubmit={handleSubmit(submitHandler)}>
                <Row>

                    <Col md={12}>
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                            <div>
                                <h2>Social Media</h2>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                            <label className="form-label">Website <HoverEyeContent number={9084}/></label>
                            <div className="position-relative">
                                <img
                                    src={union}
                                    className={`${cx.icon} ${cx.v3} `}
                                    alt="budiness icon"
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Eg-www.google.com"
                                    defaultValue={editBranchData?.website ? editBranchData?.website : businessData?.website}
                                    {...register("website", {
                                        required: false,
                                    })}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                            <label className="form-label">Instagram <HoverEyeContent number={9085}/></label>
                            <div className="position-relative">
                                <img
                                    src={instagram}
                                    className={`${cx.icon} ${cx.v3}`}
                                    alt="email icon"
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Eg-www.instagram.com"

                                    defaultValue={editBranchData?.instagram ? editBranchData?.instagram : businessData?.instagram}
                                    {...register("instagram", {
                                        required: false,
                                    })}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                            <label className="form-label">Facebook <HoverEyeContent number={9086}/></label>
                            <div className="position-relative">
                                <img
                                    src={facebookIcon}
                                    className={`${cx.icon} ${cx.v3}`}
                                    alt="Facebook icon"
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Eg-www.facebook.com"
                                    defaultValue={editBranchData?.facebook ? editBranchData?.facebook : businessData?.facebook}
                                    {...register("facebook", {
                                        required: false,
                                    })}
                                />
                            </div>
                        </div>
                    </Col>

                    <Col lg={8} md={12}>
                        <div className={`${st.formBox} `}>
                            <label className="form-label">Brief Summary about your branch <HoverEyeContent number={9087}/></label>
                            <div className={cx.editorContainer}>
                                <ReactQuill
                                    defaultValue={editBranchData?.briefAbout ? editBranchData?.briefAbout : briefAbout}
                                    onChange={(e: any) => handleAbout(e, "brief")}
                                    modules={modules}
                                    value={briefAbout}
                                />
                            </div>
                            <p className={"errorMessage"}>{brieferr}</p>
                        </div>
                    </Col>
                    <Col lg={8} md={12}>
                        <div className={`${st.formBox} `}>
                            <label className="form-label">About <HoverEyeContent number={9088}/></label>
                            <div className={cx.editorContainer}>
                                <ReactQuill
                                    defaultValue={editBranchData?.about ? editBranchData?.about : about}
                                    onChange={(e: any) => handleAbout(e, "about")}
                                    modules={modules}
                                    value={about}
                                />
                            </div>
                        </div>
                    </Col>

                    <Col md={12} className="mt-3">
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                            <div>
                                <h2>Gallery <HoverEyeContent number={9089}/></h2>
                                <span
                                    className={`${cx.smallText}`}
                                    style={{ color: "#808080" }}
                                >
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={2} lg={3} md={4} sm={4} xs={6}>
                        <div className={`${cx.uploadImageBox} `}>
                            <label htmlFor="img-upload">
                                <AiFillCamera className={`${cx.boxImage}`} />
                                <input
                                    type="file"
                                    id="img-upload"
                                    accept="image/*"
                                    onChange={onFileChange}
                                    multiple
                                />
                            </label>
                            <h2>
                                Upload Photos{" "}
                                <span style={{ color: "red" }}>
                                    <sup>*</sup>
                                </span>
                            </h2>
                        </div>
                    </Col>

                    {oldImage?.map((item: any, index: any) => {
                        return (
                            <Col xl={2} lg={3} md={4} sm={4} xs={6}>
                                <div className={`${cx.uploadImageBox} `}>
                                    <img
                                        src={deleteCross}
                                        alt="delete"
                                        className={cx.cross}
                                        onClick={() => handleRemoveOldImg(index)}
                                    />
                                    <img
                                        src={item}
                                        className={cx.bgImage}
                                        alt="img"
                                    />
                                </div>
                            </Col>)
                    })}
                    {previewImage?.map((item: any, index: any) => {
                        return (
                            <Col xl={2} lg={3} md={4} sm={4} xs={6} key={index}>
                                <div className={`${cx.uploadImageBox} `}>
                                    <img
                                        src={deleteCross}
                                        alt="delete"
                                        className={cx.cross}
                                        onClick={() => handleRemoveImg(index)}
                                    />
                                    <img
                                        src={item}
                                        className={cx.bgImage}
                                        alt="img"
                                    />
                                </div>
                            </Col>)
                    })}

                </Row>

                <Row className="mt-5">
                    <Col md={12}>
                        <div className={`${st.formBox}`}>
                            <div className={`${st.btnsGroup}`}>
                                <button
                                    className={`btn ${st.btn1}`}
                                    onClick={() => handleStepsFormData('prevStep', 'info')}
                                >
                                    Back
                                </button>
                                <button type='submit' className={`btn ${st.btn4}`} disabled={loading}>
                                    {loading ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-light"
                                            role="status"
                                        ></div>
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </Row>
            
        </>
    )
}
