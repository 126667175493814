
export const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

export const getDynamicURIName = (url: string) => {
    const urlParts = url.split('/');
    const dynamicName = urlParts[urlParts.length - 1];
    return dynamicName;
};

export const getDpName = (name: string) => {
    if (name.split(' ')[1]) {
        let firstLetter = name.split(' ')[0].slice(0, 1).toUpperCase();
        let lastLetter = name.split(' ')[1].slice(0, 1).toUpperCase();
        let finalLastLetter = lastLetter !== "(" ? lastLetter : ""
        return firstLetter + finalLastLetter;
    } else {
        return name.slice(0, 1).toUpperCase();
    }
};

export const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const refreshPage = () => {
    window.location.reload();
};

export const getRoleListObj = () => {
    const RoleForArr = {
        SuperAdmin: false,
        Admin: false,
        FrontDesk: false,
        Accounts: false,
        Staff: false,
    }

    const roleForHomeArr = {
        SuperAdmin: true,
        Admin: true,
        FrontDesk: true,
        Accounts: true,
        Staff: true,
    }

    const fullRolesArr = {
        Create: RoleForArr,
        View: RoleForArr,
        Edit: RoleForArr,
        Delete: RoleForArr,
        deactivate: RoleForArr
    }

    const homeRoleArr = {
        View: roleForHomeArr,
        Edit: RoleForArr,
    }

    const busienssRoleArr = {
        View: RoleForArr,
        EditBasicInfo: RoleForArr,
        EditAbout: RoleForArr,
        EditSetting: RoleForArr,

    }
    const reportRoleArr = {
        View: RoleForArr,
        Bookings: RoleForArr,
        Payment: RoleForArr,
        Team: RoleForArr,
        Promotion: RoleForArr,
        Branch: RoleForArr,
    }

    const bookingRoleArr = {
        ViewOwnBookings: RoleForArr,
        ViewAllBookings: RoleForArr,
        ChangeOwnBooking: RoleForArr,
        ChangeAllBookings: RoleForArr,
        CreateOwnAppointment: RoleForArr,
        CreateAllAppointments: RoleForArr,
    }



    const editDeleteArr = {
        View: RoleForArr,
        Edit: RoleForArr,
    }

    let businessSubModules = {
        // ['Business Setup']: fullRolesArr,
        ['Business Setup']: busienssRoleArr,
        ['Branch/Location']: fullRolesArr,
        // ['Service Category']: fullRolesArr,
        // ['Services']: fullRolesArr,
        // ['Packages']: fullRolesArr,
        // ['Memberships']: fullRolesArr,
        // ['Team Title']: fullRolesArr,
        // ['Team']: fullRolesArr,
        // ['Assign Services to Branch']: editDeleteArr,
        // ['Assign Services to Team']: editDeleteArr,
        // ['Client']: fullRolesArr,
        // // ['Sales']: fullRolesArr,
        // ['Promotion']: fullRolesArr,
        ['User Access']: editDeleteArr,
    }

    const roleManagementList = {
        // Calendar: {
        //     ['Call/ Message Client']: RoleForArr,
        //     ['Add Appointment']: RoleForArr,
        //     ['Edit/ Reschedule Appointment']: RoleForArr,
        //     ['No Show Appointment']: RoleForArr,
        //     ['Cancel Appointment']: RoleForArr,
        //     ['View History']: RoleForArr,
        // },
        Dashboard: homeRoleArr,
        Booking: bookingRoleArr,
        Reports: reportRoleArr,
        ['Services']: { subModules: businessSubModules },
        ['Teams']: { subModules: businessSubModules },
        // Sales: fullRolesArr,
        // Services: fullRolesArr,
        // Team: fullRolesArr,
        Customer: fullRolesArr,
        Promotion: fullRolesArr,
        // Category: fullRolesArr,
        // ['Branch Set Up']: { subModules: branchsSubModules },
        ['Business Set Up']: { subModules: businessSubModules },
        Chat: fullRolesArr,
        Publish: fullRolesArr,
    }
    return roleManagementList;
};

export const getRoleListArr = (RoleForArr: any) => {

    const homeRoleArr = RoleForArr?.map((item: any) => ({ ...item, access: true }))

    const fullRolesArr = [
        {
            action: 'View',
            actionFor: RoleForArr
        },
        {
            action: 'Create',
            actionFor: RoleForArr
        },
        {
            action: 'Edit',
            actionFor: RoleForArr
        },
        {
            action: 'Delete',
            actionFor: RoleForArr
        },
        {
            action: 'deactivate',
            actionFor: RoleForArr
        },
    ]

    const businessRoleArr = [
        {
            action: 'View',
            actionFor: RoleForArr
        },
        {
            action: 'EditBasicInfo',
            actionFor: RoleForArr
        },
        {
            action: 'EditAbout',
            actionFor: RoleForArr
        },
        {
            action: 'EditSetting',
            actionFor: RoleForArr
        },
    ]


    const bookingRoleArr = [
        {
            action: 'ViewOwnBookings',
            actionFor: RoleForArr
        },
        {
            action: 'ViewAllBookings',
            actionFor: RoleForArr
        },
        {
            action: 'ChangeOwnBooking',
            actionFor: RoleForArr
        },
        {
            action: 'ChangeAllBookings',
            actionFor: RoleForArr
        },
        {
            action: 'CreateOwnAppointment',
            actionFor: RoleForArr
        },
        {
            action: 'CreateAllAppointments',
            actionFor: RoleForArr
        },
    ]

    const reportRoleArr = [
        {
            action: 'Bookings',
            actionFor: RoleForArr
        },
        {
            action: 'Payment',
            actionFor: RoleForArr
        },
        {
            action: 'Team',
            actionFor: RoleForArr
        },
        {
            action: 'Promotion',
            actionFor: RoleForArr
        },
        {
            action: 'Branch',
            actionFor: RoleForArr
        },
    ]

    const viewEditArr = [
        {
            action: 'View',
            actionFor: RoleForArr
        },
        {
            action: 'Edit',
            actionFor: RoleForArr
        },
    ]
    const viewEditHomeArr = [
        {
            action: 'View',
            actionFor: homeRoleArr
        },
        {
            action: 'Edit',
            actionFor: RoleForArr
        },
    ]

    const roleManagementList = [
        {
            module: 'Dashboard',
            link: 'dashboard/today',
            roles: viewEditHomeArr
        },
        // {
        //     module: 'Calendar',
        //     link: 'calendar',
        //     roles: [
        //         {
        //             action: 'View',
        //             actionFor: RoleForArr
        //         },
        //         {
        //             action: 'Call/ Message Client',
        //             actionFor: RoleForArr
        //         },
        //         {
        //             action: 'Add Appointment',
        //             actionFor: RoleForArr
        //         },
        //         {
        //             action: 'Edit/ Reschedule Appointment',
        //             actionFor: RoleForArr
        //         },
        //         {
        //             action: 'No Show Appointment',
        //             actionFor: RoleForArr
        //         },
        //         {
        //             action: 'Cancel Appointment',
        //             actionFor: RoleForArr
        //         },
        //         {
        //             action: 'View History',
        //             actionFor: RoleForArr
        //         },
        //     ]
        // },
        {
            module: 'Bookings',
            link: 'bookings',
            roles: bookingRoleArr
        },
        // {
        //     name: 'Services',
        //     link: 'services',
        //     roles: fullRolesArr,
        // },
        // {
        //     name: 'Team',
        //     roles: fullRolesArr,
        //     link: 'teams'
        // },
        {
            module: 'Reports',
            roles: reportRoleArr,
            link: 'reports'
        },
        {
            module: 'Customer',
            roles: fullRolesArr,
            link: 'clients'
        },
        {
            module: 'Promotion',
            roles: fullRolesArr,
            link: 'promotion'
        },
        {
            module: 'Services',
            subModule: [
                {
                    name: 'Service Category',
                    roles: fullRolesArr,
                    link: 'category'
                },
                // {
                //     name: 'Team Title',
                //     link: 'team-title',
                //     roles: fullRolesArr,
                // },
                // {
                //     name: 'Old Service',
                //     link: 'old-services',
                //     roles: fullRolesArr,
                // },
                {
                    name: 'Services',
                    link: 'services',
                    roles: fullRolesArr,
                },

                {
                    name: 'Assign Branch',
                    roles: viewEditArr,
                    link: 'assign-services-to-branches'
                },
                {
                    name: 'Bundles',
                    link: 'Bundles',
                    roles: fullRolesArr,
                },
            ]
        },
        {
            module: 'Teams',
            subModule: [
                {
                    name: 'Team',
                    roles: fullRolesArr,
                    link: 'new-teams'
                },
                {
                    name: 'Team Title',
                    roles: fullRolesArr,
                    link: 'team-title'
                },
                {
                    name: 'Assign Services',
                    roles: viewEditArr,
                    link: 'assign-services-to-teams'
                },
                {
                    name: 'WN APP Access',
                    roles: viewEditArr,
                    link: 'roles-list'
                },

            ]
        },
        {
            module: 'Business Set Up',
            subModule: [
                {
                    name: 'Business Home',
                    // roles: fullRolesArr,
                    roles: viewEditArr,
                    link: 'business-setting/basic/info'
                },
                {
                    name: 'Branch/Location',
                    link: 'branch',
                    roles: fullRolesArr
                },
                {
                    name: 'User Access',
                    link: 'roll-management',
                    roles: viewEditArr
                },
            ]
        },
        {
            module: 'Chat',
            roles: viewEditArr,
            link: 'chat'
        },
        {
            module: 'Publish',
            roles: viewEditArr,
            link: 'publish'
        },

    ]
    return roleManagementList;
};

export const sidebarFullAccessRoles = () => {

    const fullRolesArr = [
        {
            actionName: 'Create',
            access: true
        },
        {
            actionName: 'View',
            access: true
        },
        {
            actionName: 'Edit',
            access: true
        },
        {
            actionName: 'Delete',
            access: true
        },
        {
            actionName: 'deactivate',
            access: true
        },
    ]

    const bookingRoleArr = [
        {
            actionName: 'ViewOwnBookings',
            access: true
        },
        {
            actionName: 'ViewAllBookings',
            access: true
        },
        {
            actionName: 'ChangeOwnBooking',
            access: true
        },
        {
            actionName: 'ChangeAllBookings',
            access: true
        },
        {
            actionName: 'CreateOwnAppointment',
            access: true
        },
        {
            actionName: 'CreateAllAppointments',
            access: true
        },
    ]

    const reportRoleArr = [
        {
            actionName: 'View',
            access: true
        },
        {
            actionName: 'Bookings',
            access: true
        },
        {
            actionName: 'Payment',
            access: true
        },
        {
            actionName: 'Team',
            access: true
        },
        {
            actionName: 'Promotion',
            access: true
        },
        {
            actionName: 'Branch',
            access: true
        },
    ]

    const viewEditArr = [
        {
            actionName: 'View',
            access: true
        },
        {
            actionName: 'Edit',
            access: true
        },
    ]

    const roleManagementList = [
        {
            moduleName: 'Dashboard',
            link: 'dashboard/today',
            actions: viewEditArr
        },
        // {
        //     moduleName: 'Calendar',
        //     link: 'calendar',
        //     actions: [
        //         {
        //             actionName: 'View',
        //             access: true
        //         },
        //         {
        //             actionName: 'Call/ Message Client',
        //             access: true
        //         },
        //         {
        //             actionName: 'Add Appointment',
        //             access: true
        //         },
        //         {
        //             actionName: 'Edit/ Reschedule Appointment',
        //             access: true
        //         },
        //         {
        //             actionName: 'No Show Appointment',
        //             access: true
        //         },
        //         {
        //             actionName: 'Cancel Appointment',
        //             access: true
        //         },
        //         {
        //             actionName: 'View History',
        //             access: true
        //         },
        //     ]
        // },
        {
            moduleName: 'Bookings',
            link: 'bookings',
            actions: bookingRoleArr
        },
        {
            moduleName: 'Reports',
            link: 'reports',
            actions: reportRoleArr
        },
        {
            moduleName: 'Customer',
            actions: fullRolesArr,
            link: 'clients'
        },
        {
            moduleName: 'Promotion',
            actions: fullRolesArr,
            link: 'promotion'
        },
        {
            moduleName: 'Chat',
            actions: viewEditArr,
            link: 'chat'
        },
        {
            moduleName: 'Publish',
            actions: viewEditArr,
            link: 'publish'
        },
        // {
        //     moduleName: 'Chat',
        //     actions: fullRolesArr,
        //     link: 'chat'
        // },
        // {
        //     moduleName: 'Sales',
        //     actions: fullRolesArr,
        //     link: 'sales'
        // },
        // {
        //     moduleName: 'Reports',
        //     actions: fullRolesArr,
        //     link: 'reports'
        // },

        // {
        //     moduleName: 'Category',
        //     link: 'category',
        //     actions: fullRolesArr
        // },
    ]
    return roleManagementList;
};

export const sidebarFullAccessRoles2 = () => {

    const fullRolesArr = [
        {
            actionName: 'Create',
            access: true
        },
        {
            actionName: 'View',
            access: true
        },
        {
            actionName: 'Edit',
            access: true
        },
        {
            actionName: 'Delete',
            access: true
        },
        {
            actionName: 'deactivate',
            access: true
        },
    ]

    const viewEditArr = [
        {
            actionName: 'View',
            access: true
        },
        {
            actionName: 'Edit',
            access: true
        },
    ]

    const roleManagementList = [
        {
            moduleName: 'Services',
            subModules: [
                {
                    subModuleName: 'Service Category',
                    actions: fullRolesArr,
                    link: 'category'
                },
                // {
                //     subModuleName: 'Team Title',
                //     actions: fullRolesArr,
                //     link: 'team-title'
                // },
                // {
                //     subModuleName: 'Old Service',
                //     actions: fullRolesArr,
                //     link: 'old-services'
                // },
                {
                    subModuleName: 'Services',
                    actions: fullRolesArr,
                    link: 'services'
                },
                {
                    subModuleName: 'Assign Branch',
                    actions: fullRolesArr,
                    link: 'assign-services-to-branches'
                },
                {
                    subModuleName: 'Bundles',
                    actions: fullRolesArr,
                    link: 'Bundles'
                },

            ]
        },
        {
            moduleName: 'Teams',
            subModules: [
                {
                    subModuleName: 'Teams',
                    actions: fullRolesArr,
                    link: 'new-teams'
                },
                {
                    subModuleName: 'Team Title',
                    actions: fullRolesArr,
                    link: 'team-title'
                },

                {
                    subModuleName: 'Assign Services',
                    actions: fullRolesArr,
                    link: 'assign-services-to-teams'
                },
                {
                    subModuleName: 'WN APP Access',
                    actions: viewEditArr,
                    link: 'roles-list'
                },
            ]
        },
        {
            moduleName: 'Business Set Up',
            subModules: [
                {
                    subModuleName: 'Business Home',
                    actions: viewEditArr,
                    link: 'business-setting/basic'
                },
                // {
                //     menuName: 'Service Menu',
                //     menus: [
                //         {
                //             subModuleName: 'Service Category',
                //             link: 'category',
                //             actions: fullRolesArr,
                //         },
                //         {
                //             subModuleName: 'Services',
                //             link: 'services',
                //             actions: fullRolesArr,
                //         },
                //         {
                //             subModuleName: 'Team Title',
                //             actions: fullRolesArr,
                //             link: 'team-title'
                //         },
                //         {
                //             subModuleName: 'Bundles',
                //             actions: fullRolesArr,
                //             link: 'Bundles'
                //         },
                //         // {
                //         //     subModuleName: 'Packages',
                //         //     actions: fullRolesArr,
                //         //     link: 'packages'
                //         // },
                //         // {
                //         //     subModuleName: 'Memberships',
                //         //     actions: fullRolesArr,
                //         //     link: 'membership'
                //         // },
                //     ]
                // },
                {
                    subModuleName: 'Branch/Location',
                    actions: fullRolesArr,
                    link: 'branch'
                },
                // {
                //     menuName: 'Team Menu',
                //     menus: [
                //         {
                //             subModuleName: 'Team Title',
                //             actions: fullRolesArr,
                //             link: 'team-title'
                //         },
                //         {
                //             subModuleName: 'Team',
                //             actions: fullRolesArr,
                //             link: 'teams'
                //         },
                //     ]
                // },
                // {
                //     subModuleName: 'Assign Services to Branch',
                //     actions: viewEditArr,
                //     link: 'assign-services-to-branches'
                // },
                // {
                //     subModuleName: 'Assign Services to Team',
                //     actions: viewEditArr,
                //     link: 'assign-services-to-teams'
                // },
                // {
                //     subModuleName: 'Client',
                //     actions: fullRolesArr,
                //     link: 'clients'
                // },
                // {
                //     subModuleName: 'Sales',
                //     actions: fullRolesArr,
                //     link: 'sales'
                // },
                // {
                //     subModuleName: 'Promotion',
                //     actions: fullRolesArr,
                //     link: 'promotion'
                // },
                {
                    subModuleName: 'User Access',
                    link: 'roll-management',
                    actions: viewEditArr
                },
            ]
        },
        // {
        //     moduleName: 'Business Set Up',
        //     subModules: [
        //         {
        //             subModuleName: 'User Access',
        //             link: 'roll-management',
        //             actions: viewEditArr
        //         }
        //     ]
        // },
    ]
    return roleManagementList;
};


export const fullAccessRolesObj = () => {
    let fullActionObj = {
        Create: true,
        Delete: true,
        Edit: true,
        View: true,
        deactivate: true,
    }
    let viewEdit = {
        Edit: true,
        View: true,
    }
    // let businessActionObj = {
    //     View: true,
    //     EditBasicInfo: true,
    //     EditAbout: true,
    //     EditSetting: true,
    // }


    let reportActionObj = {
        Bookings: true,
        Payment: true,
        Team: true,
        Promotion: true,
        Branch: true,
    }
    let bookingActionObj = {
        ViewOwnBookings: true,
        ViewAllBookings: true,
        ChangeOwnBooking: true,
        ChangeAllBookings: true,
        CreateOwnAppointment: true,
        CreateAllAppointments: true,

    }

    let calendarActionObj = {
        "Call/ Message Client": true,
        "Add Appointment": true,
        "Edit/ Reschedule Appointment": true,
        "No Show Appointment": true,
        "Cancel Appointment": true,
        "View": true
    };

    let mainModuleRoles = {
        role: "SuperAdmin",
        Bookings: bookingActionObj,
        Calendar: calendarActionObj,
        Reports: reportActionObj,
        // Category: fullActionObj,
        Dashboard: fullActionObj,
        Services: fullActionObj,
        Team: fullActionObj,
        Customer: fullActionObj,
        Promotion: fullActionObj,
        Chat: fullActionObj,
        Publish: fullActionObj,
    };
    let subModuleRoles = {
        role: 'SuperAdmin',
        ['Business Set Up']: {
            ['Business Home']: viewEdit,
            ['Branch/Location']: fullActionObj,
            ['User Access']: fullActionObj,
        },
        ['Services']: {
            ['Service Category']: fullActionObj,
            ['Team Title']: fullActionObj,
            Services: fullActionObj,
            ['Assign Branch']: fullActionObj,
            Bundles: fullActionObj,
        },

        ['Teams']: {
            ['Team Title']: fullActionObj,
            Team: fullActionObj,
            ['Assign Services']: fullActionObj,
            ['WN APP Access']: viewEdit,
        },
        // ['Branch Set Up']: {
        //     Amenities: fullActionObj,
        //     ['Branch Home']: fullActionObj,
        //     Client: fullActionObj,
        //     // Packages: fullActionObj,
        //     Services: fullActionObj,
        //     Team: fullActionObj,
        // },
    }

    return { mainModuleRoles, subModuleRoles };
};
export function convertMinutesToTime(minutes: any) {
    if (!minutes) {
        return "00:00 PM"
    }
    // Calculate hours and minutes
    let hours = Math.floor(minutes / 60);
    let remainderMinutes = minutes % 60;

    // Determine AM or PM
    let ampm = hours >= 12 ? 'PM' : 'AM';

    // Adjust hours for 12-hour format
    hours = hours % 12 || 12;

    // Format the result
    let formattedTime = `${hours < 10 ? `0${hours}` : hours}:${remainderMinutes < 10 ? '0' : ''}${remainderMinutes} ${ampm}`;

    return formattedTime;
}