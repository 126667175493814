import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { httpRequest } from '../../../Apis/commonApis';
import BasicService from "./BasicService";
import PricingTab from "./PricingTab";
import TeamTab from "./TeamTab";
import AmenitiesTab from "./AmenitiesTab";
import { useNavigate } from "react-router-dom";
import { GlobalServiceContextProvider } from '../../../store/ServiceSGloabl';
import NewBranchPricing from './NewPricingTab';

export default function ServiceRoutes(props: any) {
    const { dynamicName, handleStepsFormData, loading } = props;
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const location = useLocation();
    const navigate = useNavigate();

    const [editServiceData, setEditServiceData] = useState<any>(null);
    const [businessPriceData, setBusinessPriceData] = useState<any>(null);
    const [userData, setUserData] = useState<any>(null);
    const [editTeamMemberm, seteditteamMembers] = useState<any>(null)
    const [serviceCategoryList, setServiceCategoryList] = useState<any>([]);

    useEffect(() => {
        const prevBId = localStorage.getItem("currentBranchId");
        if (branchId && branchId !== prevBId) {
            navigate(-1);
        }
    }, [branchId]);

    useEffect(() => {
        let udata = localStorage.getItem("user_data") || "";
        let userdata = JSON.parse(udata);
        setUserData(userdata);
    }, []);

    useEffect(() => {
        if (businessData) {
            getServiceCategory();
        }
    }, [businessData]);

    useEffect(() => {
        if (location.state && businessData) {
            getService();
            getBusinessPrice();
            getTeam()
        }
    }, [businessData, location.state]);

    const getService = async () => {
        const res = await httpRequest(`getBusinessService?_id=${location.state.serviceId}&businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setEditServiceData(res?.data[0]);
        }
    };

    const getBusinessPrice = async () => {
        const res = await httpRequest(`getBusinessPrice?serviceId=${location.state.serviceId}&businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setBusinessPriceData(res?.data);
        }
    };
    const getTeam = async () => {
        const res = await httpRequest(`getAllTeamMembers?serviceId=${location?.state?.serviceId}&businessId=${businessData._id}`, "get", null, "json");
        if (res.status) {
            seteditteamMembers(res.data);
        }
    };

    async function getServiceCategory() {
        const res = await httpRequest(
            `getServiceCatagory?businessId=${businessData?._id}`,
            "get",
            null,
            "json"
        );
        if (res.status) {
            setServiceCategoryList(res.data);
        }
    }
    return (
        <>
            {dynamicName === "info" &&
                <BasicService businessData={businessData} userData={userData}
                    handleStepsFormData={handleStepsFormData}
                    editServiceData={editServiceData}
                    serviceCategoryList={serviceCategoryList}
                />}

            {dynamicName === "pricing" &&
                <NewBranchPricing
                    handleStepsFormData={handleStepsFormData}
                    editServiceData={editServiceData}
                    businessPriceData={businessPriceData}
                    getBusinessPrice={getBusinessPrice}
                />
            }
            {/* {dynamicName === "pricing" &&
                <GlobalServiceContextProvider>
                    <PricingTab
                        handleStepsFormData={handleStepsFormData}
                        editServiceData={editServiceData}
                        businessPriceData={businessPriceData}
                    />
                </GlobalServiceContextProvider>
            } */}
            {dynamicName === "team" &&
                <TeamTab
                    handleStepsFormData={handleStepsFormData}
                    editBranchData={editServiceData}
                    editTeamMemberm={editTeamMemberm}
                />}
            {/* {dynamicName === "amenities" &&
                <AmenitiesTab
                    handleStepsFormData={handleStepsFormData}
                    editServiceData={editServiceData}
                />} */}
        </>
    )
}