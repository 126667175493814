import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
// import Select from 'react-select';

import { Accordion, Col, Nav, Row, Tab, Table } from "react-bootstrap";
import {
  basicInfo,
  basicInfoA,
  pricingTab,
  pricingTabA,
  plus,
  search,
  promotionSetting,
  promotionSettingA,
  deleteIcon,
  plusWhite,
} from "../../../assets/images";

import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import BundleRoute from "./BundleRoute";
import { getDynamicURIName } from "../../../Utils/Funcs";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";


const EditBundle = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const location = useLocation();
  const dynamicName = getDynamicURIName(location.pathname);
  const navigate = useNavigate();
  const [bundleData, setBundleData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false)

  const [message, setMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleSuccessModalClose = () => setShowSuccessModal(false);

  const [activeTab, setActiveTab] = useState(dynamicName);

  const handleStepsFormData = async (allData: any, step: any) => {
    if (!allData) {
      navigate(step, { state: { bundleId: location?.state?.bundleId }, replace: true });
      setActiveTab(step);
      return
    }
    const formData = new FormData();
    formData.append("businessId", businessData?._id)
    if (step === "pricing") {
      for (const key in allData) {
        formData.append(key, allData[key]);
      }
    }

    if (step === "assign-branch") {
      formData.append("priceId", allData?.priceId)
      formData.append("discount", allData?.discount)
      formData.append("finalPrice", allData?.finalPrice)
      formData.append("branchArr", allData?.branchArr)
    }
    // if (step === "completed") {
    //   for (const key in allData) {
    //     formData.append(key, allData[key]);
    //   }
    // }

    const path = location?.state?.bundleId ? `updateBundle/${location?.state?.bundleId}` : `createBundle`;
    const method = location?.state?.bundleId ? "PATCH" : "POST";

    setLoading(true);
    const res = await httpRequest(path, method, formData, "formdata",);
    if (res.status) {
      if (step === "assign-branch") {
        setShowSuccessModal(true)
        setMessage(res?.message)
        setTimeout(() => {
          navigate(-1)
        }, 2000)
        return
      }
      navigate(step, { state: { bundleId: res.data?._id }, replace: true });
      setActiveTab(step);
    }
    setLoading(false)
    // navigate(step, { state: { bundleId: srvId }, replace: true });
  };





  // const handleSave = (e: any) => {
  //   e.preventDefault();
  //   handleStepsFormData(selectedBranchId, 'step4');
  // }

  // const skipStep = (e: any) => {
  //   e.preventDefault();
  //   handleStepsFormData("prevStep", "additional");
  // }

  const handleTabSelect = (tab: any) => {
    if (location.pathname.includes('edit-bundle')) {
      setActiveTab(tab);
      navigate(`${tab}`, { state: { bundleId: location?.state?.bundleId }, replace: true });
    }
  };

  useEffect(() => {
    if (location.state?.bundleId && businessData) {
      getBundle();
    }
  }, [businessData, location?.state]);

  async function getBundle() {
    const res = await httpRequest(
      `getBundle?_id=${location?.state?.bundleId}`,
      "get",
      null,
      "json"
    );
    if (res.status) {
      setBundleData(res.data?.[0]);
    }
  }

  return (
    <>
      <SuccessPopup
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={handleSuccessModalClose}
        message={message}
      />
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {/* <BackArrrow /> */}
              <span>{bundleData?._id ? bundleData?.bundleName : "Add Bundle"}</span></h5>
          </div>
          <div className={`${st.rightInfo}`}>
            {location.pathname.includes('edit-bundle') && <div style={{ color: "#3e8575", padding: "5px 50px" }}>Edit Mode</div>}
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleTabSelect}>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="info">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pricing">
                  <img
                    src={pricingTab}
                    alt="info"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={pricingTabA}
                    alt="info"
                    className={`${st.active}`}
                  />
                  Pricing and Duration
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="assign-branch">
                  <img
                    src={promotionSetting}
                    alt="info"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={promotionSettingA}
                    alt="info"
                    className={`${st.active}`}
                  />
                  Assign Branch
                </Nav.Link>
              </Nav.Item> */}

            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                <Routes>
                  <Route path={activeTab}
                    element={<BundleRoute dynamicName={dynamicName} handleStepsFormData={handleStepsFormData} loading={loading}/>} />
                </Routes>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default EditBundle;
