import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import AddServiceModal from "../../../components/Business/Modals/AddServiceModal";
import { Col, Nav, Row, Tab, Table } from "react-bootstrap";
import { deleteBtn, deleteIcon, plusWhite, } from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import DurationServiceModal from "../../../components/Business/Modals/DurationServiceModal";

export default function PricingTab(props: any) {
    const { packageDatalist, editPackageData, handleStepsFormData, loading } = props;
    const navigate = useNavigate();
    const [durationHour, setDurationHour] = useState<string>("");

    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    let { branchId } = useSelector((state: any) => state.BranchReducer);

    const [servicelist, setServicelist] = useState<any[]>([]);
    // const [desc, setDesc] = useState<string>("");
    const [service, setService] = useState<any>([]);
    const [errorTrig, setErrorTrig] = useState(false);
    const [packagevalue, setPackagevalue] = useState<any>([]);
    const [showAddCategory, setAddCategoryShow] = useState(false);
    const [dataList, setDataList] = useState<any>([]);
    const [confirm, setConfirm] = useState(false);
    const [finalPrice, setFinalPrice] = useState("");
    const [totalPrice, setTotalPrice] = useState("");
    const [trigger, setTrigger] = useState(false);

    const handleAddCategoryClose = () => setAddCategoryShow(false);
    const handleAddCategoryShow = () => setAddCategoryShow(true);

    const [showError, setShowError] = useState<any>(false);
    const handleErrorClose = () => setShowError(false);
    const [confirmData, setConfirmData] = useState("");

    const [durationData, setDurationData] = useState<any>(() => {
        return {
            type: "",
            regularPrice: "",
            memberPrice: "",
        };
    });

    useEffect(() => {
        if (editPackageData) {
            const packType = editPackageData?.typeOfService?.type;
            if (packType === "duration" && editPackageData?.Services?.[0]?.noOfServices) {
                setDurationHour(editPackageData?.Services?.[0]?.noOfServices)
            } else {
                setDurationHour("")
            }
            setFinalPrice(editPackageData?.finalPrice);
            setTotalPrice(editPackageData?.totalPrice);
            setDurationData({
                ...durationData,
                type: editPackageData?.typeOfService?.type ? editPackageData?.typeOfService?.type : "",
                regularPrice: editPackageData?.typeOfService?.regularPrice
                    ? editPackageData?.typeOfService?.regularPrice
                    : "",
                memberPrice: editPackageData?.typeOfService?.memberPrice
                    ? editPackageData?.typeOfService?.memberPrice
                    : "",
            });

            const newServiceList = editPackageData?.Services?.reduce((acc: any, val: any) => {
                const exists = acc.some((item: any) => item?.serviceId?._id ? item.serviceId?._id === val.serviceId?._id : item.serviceId === val.serviceId);
                if (!exists) {
                    return [...acc, val];
                }

                return acc;
            }, []);

            setDataList(() => {
                return editPackageData?.Services && editPackageData?.typeOfService?.type === "duration"
                    ? newServiceList?.map((data: any) => {
                        return {
                            noOfServices: data?.noOfServices,
                            input: data?.input,
                            noOfPerson: data?.noOfPerson,
                            priceId: data?.priceId?._id,
                            serviceId: data?.priceId?.serviceId?._id ? data?.priceId?.serviceId?._id : null
                        };
                    })
                    : editPackageData?.Services && editPackageData?.typeOfService?.type !== "duration" ? editPackageData?.Services?.map((data: any) => {
                        return {
                            noOfServices: data?.noOfServices,
                            input: data?.input,
                            noOfPerson: data?.noOfPerson,
                            priceId: data?.priceId?._id,
                            serviceId: data?.priceId?.serviceId?._id ? data?.priceId?.serviceId?._id : null
                        };
                    }) : [];
            });
            setPackagevalue(() =>
                editPackageData?.Services && editPackageData?.typeOfService?.type === "duration"
                    ? newServiceList?.map((price: any) => price?.priceId)?.map(
                        (service: any) => {
                            return { ...service, BusinessService: service?.serviceId };
                        }
                    )
                    : editPackageData?.Services && editPackageData?.typeOfService?.type !== "duration" ? editPackageData?.Services?.map((price: any) => price?.priceId)?.map(
                        (service: any) => {
                            return { ...service, BusinessService: service?.serviceId };
                        }
                    ) : []
            )
        }

    }, [editPackageData]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getBusinessPrice();
    }, []);
    let Services: any = [...servicelist, ...dataList];

    let packageData = (e: any) => {
        setPackagevalue(e);
    };
    const handleNoOfServicesChange = (index: any, newValue: any) => {
        const updatedServiceList: any = [...servicelist];
        updatedServiceList[index].noOfServices = newValue;
        setServicelist(updatedServiceList);
    };

    const handleInputChange = (index: any, value: any, priceId: any, bundleServicePrice: any = 0, serviceId: any = null) => {
        const updatedDataList: any = [...dataList];
        updatedDataList[index] = {
            ...updatedDataList[index],
            noOfServices: value,
            input: `${!bundleServicePrice ? "0" : Number.parseInt(value) * bundleServicePrice}`,
            priceId: serviceId ? null : priceId?._id,
            serviceId: serviceId ? serviceId : null
        };
        // Update the state variable

        setDataList(updatedDataList);
        // setServicelist([...servicelist,...updatedDataList ]);
    };


    const handleChange = (index: any, field: any, value: any, bundleServicePrice: any = 0) => {
        const updatedInputValues: any = [...dataList];
        if (!updatedInputValues[index]) {
            updatedInputValues[index] = {};
        }
        updatedInputValues[index][field] = value;
        updatedInputValues[index]["noOfServices"] = `${!bundleServicePrice ? "0" : Number.parseInt(value) * bundleServicePrice}`;
        updatedInputValues[index].priceId = packagevalue[index]?._id;
        setDataList(updatedInputValues);
    };
    const handleInputChangebundle = (index: any, field: any, value: any) => {
        const updatedDataList: any = [...servicelist];
        updatedDataList[index][field] = value;
        setDataList(updatedDataList);
    };

    const getBusinessPrice = async () => {
        let res = await httpRequest(`getBusinessPrice?businessId=${businessData?._id}&type=package`, "get", null, "json");
        if (res.status) {
            setService(res?.data);
        }
    };

    const updatepackage = async (e: any) => {
        e.preventDefault();
        if (
            durationData?.type === "duration" && durationHour === ""
            // Services.length !== 0 &&
            // Services.some((data: any) => data?.noOfServices === "")
        ) {
            setErrorTrig(true)
            return;
        }
        if (
            durationData?.type === "bundle" &&
            Services.length !== 0 &&
            Services?.some(
                (data: any) =>
                    data?.noOfPerson === "" ||
                    data?.input === ""
            )
        ) {

            setErrorTrig(true)
            return;
        }
        if (
            durationData?.type === "noOfServices" &&
            Services?.length !== 0 &&
            Services?.some((data: any) => data?.noOfServices === "")
        ) {
            setErrorTrig(true)
            return;
        }

        if (!finalPrice && !durationData?.memberPrice) {
            setErrorTrig(true)
            return
        }

        let updateData: any = {
            typeOfService: durationData,
            Services: Services.some(
                (data: any) => data !== undefined && data !== null
            )
                ? Services
                : [],
            finalPrice: finalPrice,
            totalPrice: totalPrice,
        };


        let data: any = {
            businessId: businessData?._id,
            // branchId,
            finalPrice: finalPrice,
            totalPrice: totalPrice,
            typeOfService: durationData,
            Services: Services.some(
                (data: any) => data !== undefined && data !== null
            )
                ? Services
                : [],
        };
        if (Services.some((data: any) => data !== undefined && data === null)) {
            return;
        }
        handleStepsFormData(data, 'step2');
    };
    const handlePackageType = (e: any) => {
        const packageTypeValue = e.target.value;
        setErrorTrig(false);

        if (editPackageData?.typeOfService === null || editPackageData?.Services?.length === 0) {
            setDurationData({
                ...durationData,
                type: packageTypeValue,
                memberPrice: "",
                regularPrice: "",
            });
            setPackagevalue([]);
            setDataList([]);
            setServicelist([]);
            setFinalPrice("");
            setTotalPrice("");
            Services = []
            return;
        }

        if (trigger) {
            setFinalPrice("");
            setTotalPrice("");
            setDurationData({
                ...durationData,
                type: packageTypeValue,
                regularPrice: "",
                memberPrice: "",
            });
            setPackagevalue([]);
            setDataList([]);
            setServicelist([]);
            Services = [];
        }

        if (
            editPackageData?.typeOfService && packageTypeValue !== editPackageData?.typeOfService?.type &&
            !trigger
        ) {
            setShowError(true);
            setConfirmData(packageTypeValue);
        }
    };
    const deleteService = (index: any) => {
        const filterService = packagevalue?.filter((val: any, i: any) => i != index);
        const filterData = dataList?.filter((val: any, i: any) => i !== index)
        setPackagevalue(filterService)
        setDataList(filterData)
    }
    useEffect(() => {
        if (confirm === true) {
            setTrigger(confirm);
            setFinalPrice("");
            setTotalPrice("");
            setDurationData({
                ...durationData,
                type: confirmData,
                regularPrice: "",
                memberPrice: "",
            });
            setPackagevalue([]);
            setDataList([]);
            setConfirm(false);
            Services = [];
            setErrorTrig(false);
        }
    }, [confirm]);

    useEffect(() => {
        if (packagevalue?.length !== 0 && durationData?.type === "duration") {
            setDataList(packagevalue?.map((service: any) => ({
                priceId: service?._id,
                input: "0",
                noOfServices: durationHour?.toString(),
                serviceId: service?.BusinessService?._id,
            })))
        }
    }, [packagevalue, durationHour])

    useEffect(() => {
        if (dataList?.some((data: any) => data?.input === "0")) {
            return
        }
        if (dataList?.some((data: any) => data?.noOfPerson)) {
            const total = dataList?.reduce((acc: any, val: any) => {
                return Number(val?.noOfServices) + acc
            }, 0)

            setTotalPrice(total?.toString())
        }
        if (dataList?.some((data: any) => data?.input)) {
            const total = dataList?.reduce((acc: any, val: any) => {
                return Number(val?.input) + acc
            }, 0)
            setTotalPrice(total?.toString())
        }
    }, [dataList])

    return (
        <div>
            <SuccessPopup
                setConfirm={setConfirm}
                showSuccessModal={showError}
                handleSuccessModalClose={handleErrorClose}
                message={
                    "Do you want to change type of service, If yes then existing data will be deleted!!"
                }
                type={"Error"}
            />
            {durationData?.type === "" ? (
                <Row>
                    <Col lg={3} md={6}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                            <label className="form-label">Type of services</label>
                            <select
                                className={`${cx.duration} form-select`}
                                value={durationData?.type}
                                onChange={(e: any) => handlePackageType(e)}
                            >
                                <option value="">Select a Service Type</option>
                                <option value="duration">Duration</option>
                                {/* <option value="bundle">Bundle</option> */}
                                <option value="noOfServices">No. of services</option>
                            </select>
                            {errorTrig && durationData?.type === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </div>
                    </Col>
                </Row>
            ) : durationData?.type === "duration" ? (
                <>
                    <Row>
                        <Col lg={3} md={6}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Type of services</label>
                                <select
                                    className={`${cx.duration} form-select`}
                                    value={durationData?.type}
                                    onChange={(e: any) => handlePackageType(e)}
                                >
                                    <option value="">Select a Service Type</option>
                                    <option value="duration">Duration</option>
                                    {/* <option value="bundle">Bundle</option> */}
                                    <option value="noOfServices">
                                        No. of services
                                    </option>
                                </select>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div
                                className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                            >
                                <label className="form-label">Number Of Hours</label>
                                <input
                                    type="number"
                                    value={durationHour || ""}
                                    className={`${cx.center} form-control`}
                                    style={{
                                        border:
                                            errorTrig && dataList?.noOfServices === ""
                                                ? "1px solid red"
                                                : "",
                                        boxShadow:
                                            errorTrig && dataList?.noOfServices === ""
                                                ? "none"
                                                : "",
                                    }}
                                    onChange={(e: any) => setDurationHour(e.target.value)}
                                // placeholder="Number of Hours"
                                // onChange={(e: any) =>
                                //     handleInputChange(
                                //         index,
                                //         e.target.value,
                                //         list,
                                //         null,
                                //         list?.BusinessService?._id
                                //     )
                                // }
                                />
                            </div>
                        </Col>
                    </Row>
                    {packagevalue?.length !== 0 && <Row className={cx.listTitle}>
                        <Col md={4}>
                            <h5>Services</h5>
                        </Col>
                    </Row>}
                    {packagevalue?.map((list: any, index: any) => {
                        return (
                            <Row className={cx.listCardBox} key={list?._id}>
                                <Col md={4}>
                                    <article className={cx.teamBox} key={list?._id}>
                                        <div className={`${cx.left}`}>
                                            <span>
                                                {list?.BusinessService?.serviceName?.slice(0, 2).toUpperCase()}
                                            </span>
                                        </div>
                                        <div className={`${cx.right}`}>
                                            <h3 className={cx.memberName}>
                                                {list?.BusinessService?.serviceName}
                                            </h3>
                                        </div>
                                    </article>
                                </Col>
                                {/* <Col md={2}>
                                    <div
                                        className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                                    >
                                        <input
                                            type="number"
                                            value={dataList[index]?.noOfServices}
                                            className={`${cx.center} form-control`}
                                            style={{
                                                border:
                                                    errorTrig && dataList?.noOfServices === ""
                                                        ? "1px solid red"
                                                        : "",
                                                boxShadow:
                                                    errorTrig && dataList?.noOfServices === ""
                                                        ? "none"
                                                        : "",
                                            }}
                                            onChange={(e: any) =>
                                                handleInputChange(
                                                    index,
                                                    e.target.value,
                                                    list,
                                                    null,
                                                    list?.BusinessService?._id
                                                )
                                            }
                                        />
                                    </div>
                                </Col> */}
                                <Col>
                                    <img src={deleteIcon} alt="deleteBtn" style={{ cursor: "pointer" }} onClick={() => deleteService(index)} />
                                </Col>
                            </Row>
                        );
                    })}
                    <Row className="mt-4">
                        <Col lg={3} md={6}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                {showAddCategory && <DurationServiceModal
                                    service={service}
                                    packageData={packageData}
                                    showAddCategory={showAddCategory}
                                    handleAddCategoryClose={handleAddCategoryClose}
                                    existData={packagevalue}
                                />}
                                <button
                                    className={`${cx.addButton} singleBtn mt-4`}
                                    onClick={handleAddCategoryShow}
                                >
                                    <img src={plusWhite} alt="add" /> Add Service
                                </button>
                            </div>
                            {errorTrig && packagevalue.length === 0 && (
                                <p className={"errorMessage"}>
                                    Please select atleast one service
                                </p>
                            )}
                        </Col>
                        <Col lg={3} xl={3} md={3}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Total Price</label>
                                <input
                                    type="number"
                                    className={`${cx.center} form-control`}
                                    placeholder="0"
                                    disabled={packagevalue?.length === 0}
                                    onChange={(e: any) => setTotalPrice(e.target.value)}
                                    value={totalPrice}
                                />
                                <span className={cx.putInside}>$</span>
                            </div>
                            {errorTrig && totalPrice === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </Col>
                        <Col lg={3} xl={3} md={3}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Package Price</label>
                                <input
                                    type="number"
                                    disabled={packagevalue?.length === 0}
                                    className={`${cx.center} form-control`}
                                    placeholder="0"
                                    onChange={(e: any) => setFinalPrice(e.target.value)}
                                    value={finalPrice}
                                />
                                <span className={cx.putInside}>$</span>
                            </div>
                            {errorTrig && finalPrice === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </Col>
                        {/* <Col lg={3} xl={3} md={4}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Package Member Price</label>
                                <input
                                    type="number"
                                    value={durationData?.memberPrice}
                                    className={`${cx.center} form-control`}
                                    placeholder="0"
                                    disabled={packagevalue?.length === 0}
                                    onChange={(e: any) => {
                                        setDurationData({
                                            ...durationData,
                                            memberPrice: e?.target?.value,
                                        });
                                    }}
                                />
                                <span className={cx.putInside}>$</span>
                            </div>
                            {errorTrig && durationData?.memberPrice === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </Col>  */}
                    </Row>
                </>
            ) : durationData?.type === "bundle" ? (
                <>
                    <Row>
                        <Col lg={3} md={6}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Type of services</label>
                                <select
                                    className={`${cx.duration} form-select`}
                                    value={durationData?.type}
                                    onChange={(e: any) => handlePackageType(e)}
                                >
                                    <option value="">Select a Service Type</option>
                                    <option value="duration">Duration</option>
                                    {/* <option value="bundle">Bundle</option> */}
                                    <option value="noOfServices">
                                        No. of services
                                    </option>
                                </select>
                            </div>
                        </Col>


                    </Row>
                    {packagevalue?.length !== 0 && <Row className={cx.listTitle}>
                        <Col md={4}>
                            <h5>Services</h5>
                        </Col>
                        <Col md={2}>
                            <h5>No of Person</h5>
                        </Col>

                        <Col md={2}>
                            <h5>Amount</h5>
                        </Col>
                    </Row>}
                    <Row>
                    </Row>
                    {packagevalue?.map((list: any, index: any) => {
                        return (
                            <Row className={cx.listCardBox}>
                                <Col md={4}>
                                    <article className={cx.teamBox}>
                                        <div className={`${cx.left}`}>
                                            <span>
                                                {`${list?.BusinessService?.serviceName[0].toUpperCase()}${list?.BusinessService?.serviceName[1]?.toLocaleUpperCase()}`}
                                            </span>
                                        </div>
                                        <div className={`${cx.right}`}>
                                            <h3 className={cx.memberName}>
                                                {list?.BusinessService?.serviceName}
                                            </h3>
                                            <p className={cx.priceDesc}>
                                                {list?.duration ? list?.duration : ""}{" "}
                                                <span>
                                                    {` ${list?.seasonType} ${list?.seasonPrice ? `$${list?.seasonPrice}` : ""}`}
                                                </span>
                                            </p>
                                        </div>
                                    </article>
                                </Col>
                                <Col md={2}>
                                    <div
                                        className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                                    >
                                        <input
                                            type="number"
                                            className={`${cx.center} form-control`}
                                            value={dataList[index]?.noOfPerson}
                                            placeholder="0"
                                            onChange={(e: any) =>
                                                handleChange(
                                                    index,
                                                    "noOfPerson",
                                                    e?.target?.value,
                                                    Number.parseInt(list?.seasonPrice)
                                                )
                                            }
                                        />
                                    </div>
                                </Col>

                                <Col md={2}>
                                    <div
                                        className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                                    >
                                        <input
                                            type="number"
                                            className={`${cx.center} form-control`}
                                            placeholder="0"
                                            disabled
                                            value={dataList[index]?.noOfServices}
                                        />
                                    </div>

                                </Col>
                                <Col>
                                    <img src={deleteIcon} alt="deleteBtn" style={{ cursor: "pointer" }} onClick={() => deleteService(index)} />
                                </Col>
                            </Row>
                        );
                    })}

                    <Row className="mt-4">
                        <Col lg={3} md={6}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                {showAddCategory && <AddServiceModal
                                    service={service}
                                    packageData={packageData}
                                    showAddCategory={showAddCategory}
                                    handleAddCategoryClose={handleAddCategoryClose}
                                    existData={packagevalue}
                                    serviceType="service"
                                />}
                                <button
                                    className={`${cx.addButton} singleBtn mt-4`}
                                    onClick={handleAddCategoryShow}
                                >
                                    <img src={plusWhite} alt="add" /> Add Service
                                </button>
                            </div>
                            {errorTrig && packagevalue.length === 0 && (
                                <p className={"errorMessage"}>
                                    Please select atleast one service
                                </p>
                            )}
                        </Col>
                        <Col lg={3} xl={3} md={3}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Total Price</label>
                                <input
                                    type="number"
                                    className={`${cx.center} form-control`}
                                    placeholder="0"
                                    disabled={true}
                                    // onChange={(e: any) => setTotalPrice(e.target.value)}
                                    value={totalPrice}
                                />
                                <span className={cx.putInside}>$</span>
                            </div>
                            {errorTrig && totalPrice === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </Col>
                        <Col lg={3} xl={3} md={3}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Package Price</label>
                                <input
                                    type="number"
                                    className={`${cx.center} form-control`}
                                    placeholder="0"
                                    disabled={packagevalue?.length === 0}
                                    onChange={(e: any) => setFinalPrice(e.target.value)}
                                    value={finalPrice}
                                />
                                <span className={cx.putInside}>$</span>
                            </div>
                            {errorTrig && finalPrice === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </Col>
                        {/* <Col lg={3} xl={3} md={4}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Package Member Price</label>
                                <input
                                    type="number"
                                    value={durationData?.memberPrice}
                                    disabled={packagevalue?.length === 0}
                                    className={`${cx.center} form-control`}
                                    placeholder="0"
                                    onChange={(e: any) => {
                                        setDurationData({
                                            ...durationData,
                                            memberPrice: e?.target?.value,
                                        });
                                    }}
                                />
                                <span className={cx.putInside}>$</span>
                            </div>
                            {errorTrig && durationData?.memberPrice === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </Col>  */}
                    </Row>
                </>
            ) : (
                <>
                    <Row>
                        <Col lg={3} md={6}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Type of services</label>
                                <select
                                    className={`${cx.duration} form-select`}
                                    value={durationData?.type}
                                    onChange={(e: any) => handlePackageType(e)}
                                >
                                    <option value="">Select a Service Type</option>
                                    <option value="duration">Duration</option>
                                    {/* <option value="bundle">Bundle</option> */}
                                    <option value="noOfServices">
                                        No. of services
                                    </option>
                                </select>
                            </div>
                        </Col>

                    </Row>
                    {packagevalue?.length !== 0 && <Row className={cx.listTitle}>
                        <Col lg={4} md={6}>
                            <h5>Services</h5>
                        </Col>
                        <Col lg={2} md={4}>
                            <h5>Number of services</h5>
                        </Col>
                        <Col md={2}>
                            <h5>Amount</h5>
                        </Col>
                    </Row>}

                    {packagevalue?.map((list: any, index: any) => {
                        return (
                            <Row className={cx.listCardBox}>
                                <Col md={4}>
                                    <article className={cx.teamBox}>
                                        <div className={`${cx.left}`}>
                                            <span>
                                                {list?.BusinessService?.serviceName?.slice(0, 2)?.toLocaleUpperCase()}
                                            </span>
                                        </div>
                                        <div className={`${cx.right}`}>
                                            <h3 className={cx.memberName}>
                                                {list?.BusinessService?.serviceName}
                                            </h3>
                                            <p className={cx.priceDesc}>
                                                {list?.duration ? list?.duration : ""}{" "}
                                                {` ${list?.seasonType} ${list?.seasonPrice ? `$${list?.seasonPrice}` : ""}`}
                                            </p>
                                        </div>
                                    </article>
                                </Col>
                                <Col md={2}>
                                    <div
                                        className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                                    >
                                        <input
                                            type="number"
                                            value={dataList[index]?.noOfServices}
                                            className={`${cx.center} form-control`}
                                            // {...register("hours", { required: true })}
                                            placeholder="No. of Services"
                                            onChange={(e: any) =>
                                                handleInputChange(
                                                    index,
                                                    e.target.value,
                                                    list,
                                                    Number.parseInt(list?.seasonPrice),
                                                )
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div
                                        className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                                    >
                                        <input
                                            type="number"
                                            className={`${cx.center} form-control`}
                                            placeholder="0"
                                            disabled
                                            // {...register("service", { required: true })}
                                            value={dataList[index]?.input}
                                        />
                                    </div>

                                </Col>
                                <Col>
                                    <img src={deleteIcon} alt="deleteBtn" style={{ cursor: "pointer" }} onClick={() => deleteService(index)} />
                                </Col>
                            </Row>
                        );
                    })}
                    <Row className="mt-4">
                        <Col lg={3} md={6}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                {showAddCategory && <AddServiceModal
                                    service={service}
                                    packageData={packageData}
                                    showAddCategory={showAddCategory}
                                    handleAddCategoryClose={handleAddCategoryClose}
                                    existData={packagevalue}
                                    serviceType="service"
                                />}
                                <button
                                    className={`${cx.addButton} singleBtn mt-4`}
                                    onClick={handleAddCategoryShow}
                                >
                                    <img src={plusWhite} alt="add" /> Add Service
                                </button>
                            </div>
                            {errorTrig && packagevalue.length === 0 && (
                                <p className={"errorMessage"}>
                                    Please select atleast one service
                                </p>
                            )}
                        </Col>
                        <Col lg={3} xl={3} md={3}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Total Price</label>
                                <input
                                    type="number"
                                    className={`${cx.center} form-control`}
                                    placeholder="0"

                                    disabled={true}
                                    // onChange={(e: any) => setTotalPrice(e.target.value)}
                                    value={totalPrice}
                                />
                                <span className={cx.putInside}>$</span>
                            </div>
                            {errorTrig && totalPrice === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </Col>
                        <Col lg={3} xl={3} md={3}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Package Price</label>
                                <input
                                    type="number"
                                    className={`${cx.center} form-control`}
                                    placeholder="0"
                                    disabled={packagevalue?.length === 0}
                                    onChange={(e: any) => setFinalPrice(e.target.value)}
                                    value={finalPrice}
                                />
                                <span className={cx.putInside}>$</span>
                            </div>
                            {errorTrig && finalPrice === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </Col>
                        {/* <Col lg={3} xl={3} md={4}>
                            <div className={`${st.formBox} ${cx.priceOption}`}>
                                <label className="form-label">Package Member Price</label>
                                <input
                                    type="number"
                                    value={durationData?.memberPrice}
                                    disabled={packagevalue?.length === 0}
                                    className={`${cx.center} form-control`}
                                    placeholder="0"
                                    onChange={(e: any) => {
                                        setDurationData({
                                            ...durationData,
                                            memberPrice: e?.target?.value,
                                        });
                                    }}
                                />
                                <span className={cx.putInside}>$</span>
                            </div>
                            {errorTrig && durationData?.memberPrice === "" && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )}
                        </Col>  */}
                    </Row>
                </>
            )}

            <Row>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => handleStepsFormData('prevStep', 'basicInfo')}
                            >
                                Back
                            </button>
                            <button
                                className={`btn ${st.btn4}`}
                                onClick={updatepackage} disabled={loading}
                            >
                                {loading ? <LoadingBtn /> : 'Save'}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
