import React from "react";
import cx from "./index.module.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Col, Row } from "react-bootstrap";
import { location, menu } from "../../../assets/images";

export default function ViewInfo() {
    const datalocation: any = useLocation();
    let state: any = datalocation?.state;
    const navigate = useNavigate();

    const options = {
        dots: false,
        loop: false,
        margin: 10,
        items: 4,
        autoplay: false,
        nav: true,

        responsive: {
            0: {
                items: 1.7,
                nav: false
            },
            480: {
                items: 3,
            },
            768: {
                items: 3,
            },
            992: {
                items: 5,
            },
            1250: {
                items: 8,
            }
        }
    };

    return (
        <>
            <Row>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                        <div>
                            <h2>Branch</h2>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <div className={`${st.formBox} ${cx.iconPos}`}>
                                <label className="form-label">Business Country</label>
                                <div className="position-relative d-flex align-items-center gap-1">
                                    <img src={location} alt="budiness icon" />
                                    <p className="m-0"> {state?.businessId?.country}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={`${st.formBox} ${cx.iconPos}`}>
                                <label className="form-label">Business ID</label>
                                <div className="position-relative d-flex align-items-center gap-1">
                                    <p className="m-0"> #{state?.businessId?.businessAccountNo}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Branch Name</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <img className="me-1" src={menu} alt="business icon" />
                            <p className="m-0"> {state?.branchName}</p>
                        </div>
                    </div>
                </Col>

                <Col lg={8} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Business Category</label>

                        <div className={`${cx.busines_category_itmes}`} >
                            <ul className={`${st.iconSelectList} ${cx.icons}`}>
                                <div className="w-100">
                                    <OwlCarousel className="owl-theme " {...options}>
                                        {state.businessCatagoryId?.map((val: any) => {
                                            return (
                                                <div className={'item me-0'}>
                                                    <li className={`${st.active}`}>
                                                        <div className={`${st.iconImg}`}>
                                                            <img src={val?.Icon} alt="massage" />
                                                        </div>
                                                        <h6>{val?.BusinessCategoryName}</h6>
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </OwlCarousel>
                                </div>

                            </ul>
                        </div>
                    </div>
                </Col>

                <Col md={12} className="mb-2">
                    <h5 className="mb-1">Contact Details</h5>
                    {/* <p>Information visible to customer on web site</p> */}
                </Col>

                <Col md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Email</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <p className="m-0">{state?.email}</p>
                        </div>
                    </div>
                </Col>

                <Col md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Phone Number 1</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <p className="m-0">{state?.phoneNumber1 && state?.phoneNumber1 !== "undefined" ? state?.phoneNumber1 : ""}</p>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Phone number 2</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <p className="m-0"> {state?.phoneNumber2}</p>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">WhatsApp number</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <p className="m-0">  {state?.whatsAppNumber}</p>
                        </div>
                    </div>
                </Col>

                <Col md={12} className="mt-3 mb-2">
                    <h5>Address</h5>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">City</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <p className="m-0">{state?.city}</p>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Postal Code</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <p className="m-0">{state?.postalCode}</p>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Address 1</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <p className="m-0">{state?.address1}</p>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Address 2</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <p className="m-0"> {state?.address2}</p>
                        </div>
                    </div>
                </Col>

                <Col md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Landmark</label>
                        <div className="position-relative d-flex align-items-center gap-1">
                            <p className="m-0">{state?.landmark} </p>
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => navigate(-1)}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
