import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Controller, useForm } from "react-hook-form";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { basicInfo, basicInfoA } from "../../../assets/images";
// import { useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import { disablePastDate } from "../../../Utils/Funcs";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AddMemberShip = (props: any) => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const [businessData, setBusinessData] = useState<any>();
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<any>(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
  } = useForm();

  const location: any = useLocation();
  const handleSuccessModalClose = () => setShowSuccessModal(false);
  const submitHandler = async (data: any) => {
    const finalData = {
      businessId: businessData?._id,
      Membership: data?.membership,
      branchId:branchId,
      retailPrice: data?.retailPrice,
      Validity: data?.validity,
      startDate: data?.startDate,
      amountSpent: data?.amountSpent,
      hoursBooked: data?.hoursBooked,
      Points: data?.Points,
    };
    if (isUpdate) {
      const updatedData = {
        Membership: data?.membership,
        retailPrice: data?.retailPrice,
        Validity: data?.validity,
        branchId:branchId,
        startDate: data?.startDate,
        amountSpent: data?.amountSpent,
        hoursBooked: data?.hoursBooked,
        Points: data?.Points,
        
      };

      const res = await httpRequest(
        `updateMembership/${location?.state?._id}`,
        "PATCH",
        updatedData,
        "json",
      );
      if (res.status) {
        setMessage(res.message);
        setShowSuccessModal(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      }
    } else {
      const res = await httpRequest(
        "createMembership",
        "post",
        finalData,
        "json",
      );
      if (res.status) {
        setMessage(res.message);
        setShowSuccessModal(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      }
    }
  };

  useEffect(() => {
    if (location.state && location.state._id) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
    const businessData:any = localStorage.getItem("business_data");
    const data:any = JSON.parse(businessData || null);;
    if (data) {
      setBusinessData(data);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {isUpdate ? "Edit a New Membership" : "Add a New Membership"}
            </h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <form onSubmit={handleSubmit(submitHandler)}>
                  <Row>
                    <Col lg={3} md={4}>
                      {/* <div className={`${st.formBox} ${cx.priceOption}`}>
                        <label className="form-label">Membership</label>
                        <select
                          defaultValue={location?.state?.Membership}
                          className={`${cx.duration} form-select`}
                          {...register("membership", { required: true })}
                        >
                          <option >Select</option>
                          <option value="gold">Gold</option>
                          <option value="silver">Silver</option>
                        </select>
                        {errors?.membership?.type === "required" && (
                          <p className={"errorMessage"}>
                            This field is required
                          </p>
                        )}
                      </div> */}
                       <div className={`${st.formBox} ${cx.priceOption}`}>
                        <label className="form-label">Membership</label>
                        <Controller
                          name="membership"
                          control={control}
                          defaultValue={location?.state?.Membership}
                          rules={{ required: 'This field is required' }}
                          render={({ field }) => (
                            <select
                              {...field}
                              className={`${cx.duration} form-select`}
                            >
                              <option value="" selected>Select</option>
                              <option value="gold">Gold</option>
                              <option value="silver">Silver</option>
                            </select>)
                          }
                        />
                        {errors?.membership && (
                          <p className="errorMessage">this field is required</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={3} md={4}>
                      <div className={`${st.formBox} ${cx.priceOption}`}>
                        <label className="form-label">Retail Price</label>
                        <input
                          type="number"
                          className={`${cx.center} form-control`}
                          placeholder="Retail Price"
                          defaultValue={location?.state?.retailPrice}
                          {...register("retailPrice", { required: true })}
                        />
                        <span className={cx.putInside}>$</span>
                        {errors?.retailPrice?.type === "required" && (
                          <p className={"errorMessage"}>
                            This field is required
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} md={4}></Col>

                    <Col lg={3} md={4}>
                      <div className={`${st.formBox} ${cx.priceOption}`}>
                        <label className="form-label">Validity</label>
                        <select
                          defaultValue={location?.state?.Validity}
                          className={`${cx.duration} form-select`}
                          {...register("validity", { required: true })}
                        >
                          <option value="" selected>
                            Select
                          </option>

                          <option value="1 month">1 month</option>
                          <option value="2 month">2 month</option>
                        </select>
                        {errors?.validity?.type === "required" && (
                          <p className={"errorMessage"}>
                            This field is required
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={3} md={4}>
                      <div className={`${st.formBox} ${cx.priceOption}`}>
                        <label className="form-label">Start Date</label>
                        <input
                          type="date"
                          id="startDate"
                          className="form-control"
                          defaultValue={location?.state?.startDate}
                          min={disablePastDate()}
                          {...register("startDate", { required: true })}
                        />
                        {errors?.startDate?.type === "required" && (
                          <p className={"errorMessage"}>
                            This field is required
                          </p>
                        )}
                      </div>
                    </Col>
                    
                    <Col lg={6}></Col>

                    <Col lg={3} md={4}>
                      <div className={`${st.formBox}`}>
                        <label className="form-label">Amount Spent</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Amount Spent"
                          defaultValue={location?.state?.amountSpent}
                          {...register("amountSpent", { required: true })}
                        />
                        {errors?.amountSpent?.type === "required" && (
                          <p className={"errorMessage"}>
                            This field is required
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={3} md={4}>
                      <div className={`${st.formBox}`}>
                        <label className="form-label">Hours Booked</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Hours Booked"
                          defaultValue={location?.state?.hoursBooked}
                          {...register("hoursBooked", { required: true })}
                        />
                        {errors?.hoursBooked?.type === "required" && (
                          <p className={"errorMessage"}>
                            This field is required
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={3} md={4}>
                      <div className={`${st.formBox}`}>
                        <label className="form-label">Points</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Points"
                          {...register("Points", { required: true })}
                          defaultValue={location?.state?.Points}
                        />
                        {errors?.Points?.type === "required" && (
                          <p className={"errorMessage"}>
                            This field is required
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                          <button
                            className={`btn ${st.btn1}`}
                            onClick={(e: any) => {
                              e.preventDefault();
                              navigate(-1);
                            }}
                          >
                            Back
                          </button>
                          <button className={`btn ${st.btn4}`} type="submit">
                            {isUpdate ? "Update" : "Save"}
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
      <SuccessPopup
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={handleSuccessModalClose}
        message={message}
        path="/business/membership"
      />
    </>
  );
};



export default AddMemberShip;
