import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";

import { deleteIcon, editButton, openIcon, plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { httpRequest } from "../../../Apis/commonApis";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Status } from "../../../components/Business";
import BranchItemList from "./BranchListItem";
import StepperRow from "../../../components/Business/StepperBox/StepperRow";
import { getBusinessData, updateCounter } from "../../../redux_toolkit/reducer/shareDataReducer";
import { changeBranchIdState, changeCurrentBranchData, getAllBranch } from "../../../redux_toolkit/reducer/branchReducer";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";

const Branch = (props: any) => {
  const navigate = useNavigate();
  let { businessData, counter } = useSelector((state: any) => state.shareDataReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const dispatch = useDispatch()
  const [branchData, setBranchData] = useState<any>([]);

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (businessData) {
      getBranches();
    }
  }, [businessData]);

  const getBranches = async (type: string = '') => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}&type=list`, "get", null, 'json');
    if (res.status === true) {
      dispatch(getAllBranch({ branchData: res?.data }))
      setBranchData(res.data);

      if (type === 'delete') {
        dispatch(changeBranchIdState({ branchId: res?.data?.[0]?._id }));
        if (res?.data?.[0]) {
          dispatch(changeCurrentBranchData({ currentBranch: res?.data?.[0] }));
        }
      }
    }
  }

  const canCreateBranch = subModuleRoles?.['Business Set Up']?.['Branch/Location']?.Create;
  function handleAddBranch() {
    navigate('add-branch/info')
  }
  const isVisible = +businessData?.stepCompleted < 10 && branchData?.length > 0 && branchData?.some((item: any) => item?.workingHours?.length > 0 && item?.workingHours?.every((w: any) => w?.from !== undefined && w.from !== ''));

  async function goToService() {
    const formBusinessData = new FormData();
    formBusinessData.append('stepCompleted', JSON.stringify(7))
    let businessResponse = await httpRequest(
      `updateBusiness/${businessData?._id}`,
      "PATCH",
      formBusinessData,
      "formdata"
    );
    if (businessResponse?.status) {
      dispatch(getBusinessData({ id: `businessId=${businessData?._id}` }));
      navigate('/business/services')
    }
  }

  const handleNavigate = () => {
    if (branchData?.length > 0) {
      if (businessData?.stepCompleted < 7) {
        goToService()
      } else {
        navigate('/business/services')
      }
    }
  }

  useEffect(() => {
    if (businessData?.stepCompleted < 10) {
      dispatch(updateCounter({ ...counter, branch: branchData?.length }))
    }
  }, [branchData])

  return (
    <>
      <section className={`${st.pageWrapper} ${st.maxWidth_pageWrapper}`}>
        <StepperRow key='branch' />
        <div className={`${st.newRedesign_main}`}>
          <Col md={12}>
            <div className={`${st.head_paraBox} `}>
              <h2>Locations & branches</h2>
              <p>List of Locations and Branches.</p>
            </div>
          </Col>
          <Row>
            <Col md={1}></Col>
            {canCreateBranch && <Col md={4} className="mb-4 pb-2">
              <div className={`${st.branch_maintain_bg} `}>
                <p>Add your next location if your services are available in multiple branches.</p>
                <button className="btn" onClick={handleAddBranch}><FaPlus /> Add New Branch</button>
              </div>
            </Col>}
            {isVisible && <Col md={4} className="mb-4 pb-2">
              <div className={`${st.branch_maintain_bg} `}>
                <p>Once you've added all your branches and locations, be sure to add your service menu!</p>
                <button className="btn" onClick={handleNavigate}>Maintain Service Menu</button>
              </div>
            </Col>}
          </Row>

          {branchData?.map((branch: any) => {
            return (
              <BranchItemList branch={branch} getBranches={getBranches} />
            )
          })}
          {/* <Row className="align-items-center mb-3">
            <Col md={10}>
              <div className={`${st.edit_publishBranchMain}`}>
                <div className={`${st.topBox_withBtn}`}>
                  <h5>Bedok Branch</h5>
                  <ul className={`${st.btnList}`}>
                    <li>
                      <button> <img src={editButton} alt="edit" /></button>
                    </li>
                    <li>
                      <button> <img src={openIcon} alt="openIcon" /></button>
                    </li>
                    <li>
                      <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                    </li>
                    <li className="ms-2">
                      <Status />
                    </li>
                  </ul>
                </div>
                <ul className={`${st.bottomBox_details}`}>
                  <li className={`${st.leftWidth}`}>
                    <div className={`${st.infoSide}`}><span>Email:</span> test@gmail.com</div>
                    <div className={`${st.infoSide}`}><span>Contact No:</span> +65 8200 1372, +65 8200 1372, +65 8200 1372</div>
                  </li>
                  <li>
                    <div className={`${st.infoSide}`}><span>Address:</span> Lorem ipsum dolor sit amet consectetur, adipisicing elit.</div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={2}>
              <div className={`${st.edit_publish_checkBox}`}>
                <label className={`d-inline-flex ${st.checkbox}`}>
                  <input type="checkbox" />
                  <span className={`${st.checkmark}`}></span>
                  <span className={`${st.labelName} ms-2`}>Published On Wellnezza</span>
                </label>
              </div>
            </Col>
          </Row> */}
        </div>

        {/* <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Branch / Location</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.buttonsAction}`}>
          {canCreateBranch && <ul>
            <li>
              <ExportBtn />
            </li>
            <li>
              <ImportBtn />
            </li>
            <li>
              <NavLink
                to="/business/branch/add-branch/info"
                className={`btn ${st.darkBtn}`}
              >
                <img src={plus} alt="Create new Service" /> Add New Branch
              </NavLink>
            </li>
          </ul>}
        </div>

        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <DataGridTable branchData={branchData} getBranches={getBranches} />
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Branch;
