import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";

import { plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";

const Teams = (props: any) => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
);

const [teamMember, setteamMember] = useState<any[]>([])

useEffect(() => {
  window.scrollTo(0, 0);
  if (branchId) {
    getTeamMember();
  }
}, [branchId]);

const getTeamMember = async () => {
  const res: any = await httpRequest(`getAllTeamMembers?branchId=${branchId}&businessId=${businessData._id}`, "get", null, "json");
  if (res?.status == true) {
    setteamMember(res?.data)
  } else {
    setteamMember([])
  }
};

const canCreateTeam = subModuleRoles?.['Branch Set Up']?.Team?.Create;

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Team List</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.buttonsAction} mt-2`}>
         {canCreateTeam && <ul>
            <li>
              <ExportBtn />
            </li>
            <li>
              <ImportBtn />
            </li>
            <li>
              <NavLink to="addTeams/info" className={`btn ${st.darkBtn}`}>
                <img src={plus} alt="Create new Service" /> Add a New Team
              </NavLink>
            </li>
          </ul>}
        </div>

        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <DataGridTable teamMember={teamMember} getTeamMember={getTeamMember}/>
          </div>
        </div>
      </section>
    </>
  );
};

export default Teams;
