import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import { plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import AssignPage from "../../../components/Business/Modals/AssignPage";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

const Clients = () => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const [showAssignModal, setShowAssignModal] = useState(false);
  const handleAssignModalClose = () => setShowAssignModal(false);
  const handleAssignModalShow = () => setShowAssignModal(true);
  const [clientData, setClientData] = useState<any[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData && branchId) {
      getClientData();
    }
  }, [branchId]);

  const getClientData = async () => {
    let res = await httpRequest(`getClient?businessId=${businessData?._id}&baranchId=${branchId}`, "get", null, "json");
    if (res.status === true) {
      setClientData(res.data);
    }
    else {
      console.error('Request failed:', res.error);
    }
  }

  const canCreateClient = subModuleRoles?.['Branch Set Up']?.Client?.Create;

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Client List</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.buttonsAction} ${cx.actionBtn} mt-2`}>
          <ul>
            {canCreateClient && <><li>
              <ExportBtn />
            </li>
              <li>
                <ImportBtn />
              </li>
              <li>
                <NavLink to="addClient" className={`btn ${st.darkBtn}`}>
                  <img src={plus} alt="Create new Service" /> Add a new Client
                </NavLink>
              </li>
            </>}
            {/* <li>
              <NavLink
                to="#"
                className={`btn ${st.darkBtn}`}
                onClick={handleAssignModalShow}
              >
                <img src={plus} alt="Assign Team" /> Assign Client
              </NavLink>
            </li> */}
          </ul>
        </div>

        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <DataGridTable clientData={clientData} getClient={getClientData} />
          </div>
        </div>
      </section>
      <AssignPage
        showAssignModal={showAssignModal}
        handleAssignModalClose={handleAssignModalClose}
        page={"Client"}
      />
    </>
  );
};
export default Clients;
