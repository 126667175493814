import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../../Apis/commonApis';
import BasicInfo from './BacicInfo';
import Pricing from './Pricing';
import AssignBranch from './AssignBranch';
import { useLocation } from 'react-router-dom';

export default function ServiceRoutes(props: any) {
    const { dynamicName, handleStepsFormData,isExternal, serviceName,setErrorTrig,selectedServiceCat,serviceCategoryId, setApiError, setEditServiceData, editServiceData, submitForm } = props;
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const [businessPriceData, setBusinessPriceData] = useState<any>(null);
    const location = useLocation()

    useEffect(() => {
        if (location?.state?.serviceId && businessData) {
            getService();
            getBusinessPrice();
        }
    }, [businessData, location?.state?.serviceId, dynamicName]);

    const getService = async () => {
        const res = await httpRequest(`getBusinessService?_id=${location?.state?.serviceId}&businessId=${businessData?._id}&type=list`, "get", null, "json");
        if (res.status) {
            setEditServiceData(res?.data[0]);
        }
    };

    const getBusinessPrice = async () => {
        const res = await httpRequest(`getBusinessPrice?serviceId=${location?.state?.serviceId}&accessType=list`, "get", null, "json");
        if (res.status) {
            setBusinessPriceData(res?.data);
        }
    };
    return (
        <>
            {dynamicName === "info" &&
                <BasicInfo
                    isExternal={isExternal}
                    serviceName={serviceName}
                    editServiceData={editServiceData}
                    handleStepsFormData={handleStepsFormData}
                    submitForm={submitForm}
                    setApiError={setApiError}
                    setErrorTrig={setErrorTrig}
                    selectedServiceCat={selectedServiceCat}
                    serviceCategoryId={serviceCategoryId}
                />}
            {dynamicName === "pricing" &&
                <Pricing
                    handleStepsFormData={handleStepsFormData}
                    businessPriceData={businessPriceData}
                    getBusinessPrice={getBusinessPrice}
                    editServiceData={editServiceData}
                />
            }
            {dynamicName === "assign-branch" &&
                <AssignBranch
                    editServiceData={editServiceData}
                    handleStepsFormData={handleStepsFormData}
                />
            }
        </>
    )
}