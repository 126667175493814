import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import {
  Col,
  Modal, Row
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdClose } from 'react-icons/md';
import { business, upArrow } from "../../../assets/images";
import { AiFillCamera } from "react-icons/ai";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

const AddAmeneties = (props: any) => {
  let { showAmeneties, handleAmenetiesClose, amenitiesdata, existAmenities } = props;
  const [amenityImage, setAmenityImage] = useState("");
  const [profileImage, setProfileImage] = useState("");
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const [globalAmenities, setGlobalAmenities] = useState<any>([])
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    reset();
  }, [showAmeneties])

  let onFormSubmit = (postdata: any) => {
    amenitiesdata({ itemName: postdata.itemName?.split("#")[1], itemId: postdata.itemName?.split("#")[0], qty: postdata.itemQty });
    handleAmenetiesClose();
    reset();
  }

  const handleIcon = (e: any) => {
    const file = e.target.files[0];
    setAmenityImage(file);
    setProfileImage(URL.createObjectURL(file))
  }

  const getGlobbalAmenities = async () => {
    let res = await httpRequest(`getGlobalAmenities?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      if (existAmenities && existAmenities?.length !== 0) {
        const filterAmenities = res?.data?.filter((am: any) => !existAmenities?.some((oldAm: any) => oldAm?.itemId === am?._id))
        setGlobalAmenities(filterAmenities)
        return
      }
      setGlobalAmenities(res?.data);
    }
  };


  useEffect(() => {
    getGlobbalAmenities();
  }, [businessData, existAmenities])


  return (
    <>
      <Modal centered show={showAmeneties} onHide={handleAmenetiesClose} backdrop="static"
        keyboard={false} aria-labelledby="example-modal-sizes-title-sm" className={m.modalCts}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Modal.Header>
            <Modal.Title>Add Amenities</Modal.Title>
            <button className={`${m.closeIcon}`} onClick={(e: any) => {
              e.preventDefault();
              handleAmenetiesClose()
            }}><MdClose /></button>
          </Modal.Header>
          <Modal.Body>
            <Row>

              <Col md={12}>
                <div className={`${m.logoBox}`}>

                  <div className={m.box}>
                    {profileImage ? (
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "15px",
                          objectFit: "cover",
                        }}
                        src={profileImage}
                        alt="logo"
                      />
                    ) : (
                      <AiFillCamera className={m.boxImage} />
                    )}

                    <label
                      className={m.bottomLogo}
                      htmlFor="team-image-upload"
                    >
                      <input
                        type="file"
                        id="team-image-upload"
                        onChange={handleIcon}
                      />
                      <img src={upArrow} alt="logo" style={{ cursor: "pointer" }} />
                    </label>
                  </div>

                </div>
              </Col>
              <Col md={12}>
                <div className={`${st.formBox}`}>
                  <label className="form-label">Item Name</label>
                  <select className="form-select"
                    {...register("itemName", {
                      required: true,
                    })}
                  >
                    <option value="">Select Amenity</option>
                    {globalAmenities?.map((am: any) => {
                      return <option value={`${am?._id}#${am?.itemName}`} key={am?._id}>{am?.itemName}</option>
                    })}
                  </select>
                  {errors?.itemName?.type === "required" && (
                    <p className={"errorMessage"}>This field is required</p>
                  )}
                </div>
              </Col>
              <Col md={12}>
                <div className={`${st.formBox}`}>
                  <label className="form-label">Quantity</label>
                  <input type="number" className="form-control" placeholder="Enter Quantity"
                    {...register("itemQty", {
                      required: true,
                    })}
                  />
                  {errors?.itemQty?.type === "required" && (
                    <p className={"errorMessage"}>This field is required</p>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className={`btn ${m.cancelBtn}`} onClick={handleAmenetiesClose}>
              Close
            </button>
            <button type="submit" className={`btn ${m.actionBtn}`}>
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddAmeneties;
