import React from 'react';
import st from "../../../assets/stylesheet/style.module.scss";
import { Row, Col } from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

function BreadCrumbs(props: any) {
    const { main, current } = props;
    const navigate = useNavigate();
    return (
        <>
            <Row>
                <Col md={12}>
                    <div className={`${st.breadCrumb_All}`}>
                        <ul>
                            <li className={`${st.homeBtn}`}>
                                <button onClick={() => navigate('/business/dashboard/today', {replace:true})}>home</button>
                            </li>
                            <li className={`${st.mainPages}`}>
                                <IoIosArrowForward className={`${st.arrowForard}`} />
                                <button onClick={() => main?.link !== 'disabled' && navigate(main?.link, { replace: true })} style={main?.link === 'disabled'? {textDecoration:'none'} : {}}>
                                    {main?.menu}</button>
                            </li>
                           {current && <li className={`${st.currentPage}`}>
                                <IoIosArrowForward className={`${st.arrowForard}`} />
                                <span>{current?.menu}</span></li>}
                        </ul>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default BreadCrumbs