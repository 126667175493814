export const fetch2 = async (api: any, body: any) => {
  if (body) {
    let entries = Object.keys(body);
    let data = new FormData();
    for (let i = 0; i < entries.length; i++) {
      data.append(entries[i], body[entries[i]]);
    }
    const res = await fetch(api, {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: data,
    });
    return await res.json();
  } else {
    const res = await fetch(api, {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
    });
    return await res.json();
  }
};

export const fetch3 = async (api: any, type: any) => {
  const res = await fetch(api, {
    method: type,
    headers: {
      "content-type": "application/json",
    },
  });
  return await res.json();
};

export const fetch5 = async (api: any, method: any, formdata: any) => {
  let BASE_URL = process.env.REACT_APP_BASEURL;
  var myHeaders = new Headers();
  try {
    const res = await fetch(`${BASE_URL}/${api}`, {
      method: method,
      headers: myHeaders,
      body: formdata,
    });
    return await res.json();
  } catch (error) {
    return await error;
  }
};


export const fetch4 = async (api: any, body: any) => {
  if (body) {
    const res = await fetch(api, {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return await res.json();
  } else {
    const res = await fetch(api, {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
    });
    return await res.json();
  }
};



export const httpRequest = async (
  api: string,
  method: string,
  body: any,
  dataType: any
) => {
  let token = localStorage.getItem("user_token") || "";

  let BASE_URL = process.env.REACT_APP_BASEURL;
  // let BASE_URL = process.env.REACT_APP_LOCALURL;
  try {
    if (dataType === "json") {
      const res = await fetch(`${BASE_URL}/${api}`, {
        method: method.toUpperCase(),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: body ? JSON.stringify(body) : null,
      });
      return await res.json();
    } else if (dataType === "formdata") {
      const res = await fetch(`${BASE_URL}/${api}`, {
        method: method,
        headers: {
          Authorization: token,
        },
        body: body,
      });
      return await res.json();
    } else {
      const res = await fetch(`${BASE_URL}/${api}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: body ? JSON.stringify(body) : null,
      });
      return await res.json();
    }
  } catch (error) {
    return await error;
  }
};
