import React from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import "react-quill/dist/quill.snow.css";

import { Nav, Tab } from "react-bootstrap";
import {
  basicInfo,
  basicInfoA,
  about,
  clock,
  clockA,
  aboutA,
  teamV2,
  teamV2A,
  amenities,
  amenitiesActive,
} from "../../../assets/images";
import ViewInfo from "./ViewInfo";
import ViewAbout from "./ViewAbout";
import ViewOpHrs from "./ViewOpHrs";
import ViewTeam from "./ViewTeam";
import ViewAmenities from "./ViewAmenities";
import { useLocation } from "react-router-dom";
import ViewNotOpHrs from "./ViewNotOpHrs";

const ViewBranch = () => {
  const datalocation: any = useLocation();
  let state: any = datalocation?.state;
  console.log(state?.branchName , "statedata");
  

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo} w-auto`}>
            <h5>{state?.branchName}</h5>
          </div>
          <div className={`${st.rightInfo} w-auto`}>
            {<div style={{ color: "#3e8575", padding: "5px 50px" }}>Display Mode</div>}
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew2">
                  <img src={about} alt="about" className={`${st.defalut}`} />
                  <img src={aboutA} alt="about" className={`${st.active}`} />
                  About
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew3">
                  <img src={clock} alt="timing" className={`${st.defalut}`} />
                  <img src={clockA} alt="timimg" className={`${st.active}`} />
                  Operating Hours
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew4">
                  <img src={clock} alt="timing" className={`${st.defalut}`} />
                  <img src={clockA} alt="timimg" className={`${st.active}`} />
                  Not Operating Hours
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew5">
                  <img src={clock} alt="timing" className={`${st.defalut}`} />
                  <img src={clockA} alt="timimg" className={`${st.active}`} />
                  Off Peak Hours
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link eventKey="tabNew5">
                  <img
                    src={teamV2}
                    alt="teamIcon"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={teamV2A}
                    alt="team-icon"
                    className={`${st.active}`}
                  />
                  Team
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="tabNew6">
                  <img
                    src={amenities}
                    alt="Amenities"
                    className={`${st.defalut}`}
                    style={{ height: "17px" }}
                  />
                  <img
                    src={amenitiesActive}
                    alt="Amenities"
                    className={`${st.active}`}
                    style={{ height: "17px" }}
                  />
                  Amenities
                </Nav.Link>
              </Nav.Item> */}

            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <ViewInfo />
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew2">
                <ViewAbout />
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew3">
                <ViewOpHrs type="Working" />
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew4">
                <ViewNotOpHrs type="Working" />
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew5">
                <ViewOpHrs type="Off-Peak" />
              </Tab.Pane>

              {/* <Tab.Pane eventKey="tabNew5">
                <ViewTeam />
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew6">
                <ViewAmenities />
              </Tab.Pane> */}

            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default ViewBranch;
