import React from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  massage,
  hair,
  lashes,
  nails,
  facilities,
  waxfill,
  makeupfill,
} from "../../../assets/images";
import { MdClose } from "react-icons/md";
import { Switch } from "@mui/material";

const ViewPromotion = (props: any) => {
  let { showPromotion, handlePromotionClose, promotionData } = props;


  const dateFormat = (date: any, options: any) => {
    if (date) {
      const dateFormatter = new Intl.DateTimeFormat('en-US', options);
      const formattedDate = dateFormatter?.format(date);
      return formattedDate
    } else {
      return
    }
  }

  const selectedServices = promotionData?.priceId?.map((item: any) => ({ ...item?.id, BusinessService: item?.id?.serviceId, ServiceCategory: item?.id?.serviceId?.serviceCatagoryId }))
  console.log(selectedServices, "selectedServices");

  const groupedByCategory = selectedServices?.reduce((acc: any, item: any) => {
    const category = item?.ServiceCategory?.serviceCategoryName;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  const serviceArray = groupedByCategory ? Object.values(groupedByCategory) : [];
  const categoryArr = groupedByCategory ? Object.keys(groupedByCategory) : [];

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <Modal
        scrollable
        centered
        backdrop="static"
        keyboard={false}
        show={showPromotion}
        onHide={handlePromotionClose}
        size="lg"
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts}`}
      >
        <Modal.Header className="p-2">
          <Modal.Title>{promotionData?.promotionName}</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handlePromotionClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* <Row> */}
            {/* <Col md={12}>
              <div className={`${st.formBox} m-0 `}>
                <label className={`form-label`} style={{ color: "#7F8699" }}>
                  Apply to
                </label>
              </div>
            </Col>

            {promotionData && promotionData.priceId.map((data: any) => {
              return (
                <Col md={12} key={data?._id}>
                  <ul className={`${m.promotionServices}`}>
                    <li>{data?.id?.serviceId?.serviceName}</li>
                    <li>{data?.id?.duration}</li>
                    <li>{data?.discount && data?.discount?.length !== 0 ? `${data?.discount}` : `${data?.discountCriteria?.map((disc: any) => `${disc?.discount}%`)}`}</li>
                    {!data?.discountCriteria && data?.discountCriteria?.length !== 0 && <li><span className={`${m.regular}`}>{`${data?.id?.seasonPrice ? `USD ${data?.id?.seasonPrice}` : ""}`}</span>{`${data?.promotionalPrice ? `USD ${Number(data?.promotionalPrice)}` : `USD ${0}`}`}</li>}
                     </ul>
                </Col>
              )
            })} */}
            {/* <Col md={12}>
              <ul className={`${m.promotionServices}`}>
                <li>Makeup</li>
                <li>1 hour 30 mins</li>
                <li>USD 100</li>
              </ul>
            </Col>

            <Col md={12}>
              <ul className={`${m.promotionServices}`}>
                <li>Haircut</li>
                <li>1 hour 30 mins</li>
                <li>USD 100</li>
              </ul>
            </Col> */}
          {/* </Row> */}

          <Row>
            {(
              <>
                <div className={m.header}>
                  <ul className={m.headerList} style={{ borderBottom: '1px solid gainsboro', alignItems: 'flex-start' }}>
                    <li className={m.services}>
                      <div className={m.services_labeling}>
                        Services
                      </div>
                    </li>
                    <li className={m.duration}>
                      <div className={m.netDis_prices}>
                        Duration
                      </div>
                    </li>
                    <li>
                      <div className={m.netDis_prices}>
                        Regular Price
                      </div>
                    </li>
                    <li>
                      <div className={m.netDis_prices}>
                        Discount (%)
                      </div>
                    </li>
                    <li>
                      <div className={m.netDis_prices}>
                        Net Price
                      </div>
                    </li>
                    <li>
                      <div className={m.netDis_prices}>
                        Final Price
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}

          </Row>

          <Row>
            <div className={m.service_addAccordion}>
              <Accordion alwaysOpen>
                {serviceArray?.map((serviceItem: any, i: any) => {
                  const groupedByServiceName = serviceItem?.reduce((acc: any, item: any) => {

                    const serviceName = item?.BusinessService?.serviceName;
                    if (!acc[serviceName]) {
                      acc[serviceName] = [];
                    }
                    acc[serviceName].push(item);
                    return acc;
                  }, {});

                  return (
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>
                        <div className={m.catBox}>
                          <h5>{`${categoryArr?.[i]} (${serviceItem?.length})`}</h5>
                          {/* <div className={`${st.formBox} ${m.inputBox}`}>
                            <input
                              type="number"
                              id="discount percent"
                              min={0}
                              max={100}
                              className={`form-control`}

                            />
                          </div> */}

                        </div>


                      </Accordion.Header>
                      <Accordion.Body>
                        <div className={m.insideAccordian_promtion}>
                          <Accordion alwaysOpen>
                            {Object.values(groupedByServiceName)?.map((serviceItem: any, i: any) => {

                              return (
                                <Accordion.Item eventKey={i}>
                                  <Accordion.Header >
                                    <div className={m.catBox}>
                                      <h5 className={m.v2}>{`${Object.keys(groupedByServiceName)?.[i]} (${serviceItem?.length})`}</h5>
                                      {/* <div className={`${st.formBox} ${m.inputBox}`} style={{ marginTop: '8px' }}>
                                        <input
                                          type="number"
                                          id="discount percent"
                                          min={0}
                                          max={100}
                                          className={`form-control`}

                                        />
                                      </div> */}
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div>
                                      <ul>
                                        {serviceItem?.map((listItem: any, i: number) => {

                                          return (
                                            <div key={i} className={m.itemContainer}>
                                              <li>
                                                <div className={`${m.bodyLeft}`}>
                                                  {/* <p>{`Price : ${listItem?.seasonPrice} SGD / Duration : ${listItem?.duration}`}</p>
                                                                                                        <p>{`Team title : ${listItem?.TeamTitleData?.teamTitle || ""}`}</p> */}
                                                  <div className={`${m.category_selected} d-flex align-items-center gap-3`}>
                                                    <h5>{listItem?.TeamTitleData?.teamTitle ? listItem?.TeamTitleData?.teamTitle : "Any Stylist"}</h5>
                                                    <h5>{listItem?.priceText ? listItem?.priceText : ""}</h5>
                                                  </div>
                                                  <div className={`${m.price_time}`}>
                                                    <h5 style={{minWidth:'65px'}}>{listItem?.priceType === "from" ? "Fm" : ""} {listItem?.duration}</h5>
                                                    <h5>{listItem?.priceType === "from" ? "Fm" : ""} SGD {listItem?.seasonPrice}</h5>
                                                    <h5 style={{paddingLeft:'13px'}}>22</h5>
                                                    <h5>22 SGD</h5>
                                                    <h5>22 SGD</h5>
                                                  </div>
                                                </div>
                                              </li>

                                              {/* <div className={`${st.formBox} ${m.inputBox}`}>
                                                <input
                                                  type="number"
                                                  id="discount percent"
                                                  min={0}
                                                  max={100}
                                                  className={`form-control`}

                                                />
                                              </div>
                                              <div className={`${st.formBox} ${m.inputBox}`}>
                                                <input
                                                  type="number"
                                                  id="discount amount"
                                                  className={`form-control`}

                                                />
                                              </div>
                                              <div className={`${st.formBox} ${m.inputBox}`}>
                                                <input
                                                  type="number"
                                                  id="finalPrice"
                                                  className={`form-control`}
                                                  onClick={(e) => e.stopPropagation()}
                                                  disabled
                                                />
                                              </div> */}


                                            </div>
                                          )
                                        })}

                                      </ul>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              )
                            })}
                          </Accordion>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </div>
          </Row>

          <Row className="mt-3">
            {/* <Col md={12}>
              <div className={`${m.promoDesc}`}>
                <p>Promotional Discount</p>
                <span className={`${m.spanBox}`}>{promotionData?.discount}</span>
              </div>
            </Col> */}

            <Col md={12}>
              <div className={`${m.promoDesc}`}>
                <p>Promotion limits</p>
                <span>{promotionData?.minimumPurchaseAmount?.active ? `Minimum Purchase Amount : $${promotionData?.minimumPurchaseAmount?.minimumAmount}, ` : ""}{promotionData?.LimitNumberOfUses?.active ? `Max Uses : ${promotionData?.LimitNumberOfUses?.maxUser}, ` : ""} {promotionData?.LimitUsePerClient ? `Max uses per client : ${promotionData?.LimitUsePerClient}` : ""}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className={`${m.promoDesc}`}>
                <p>Created</p>
                <span>{promotionData?.createdAt ? dateFormat(new Date(promotionData?.createdAt), {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',

                }) : ""}</span>
              </div>
            </Col>
            {promotionData?.dateFrom && <Col md={6}>
              <div className={`${m.promoDesc}`}>
                <p>Start date</p>
                <span>{promotionData?.dateFrom ? dateFormat(new Date(promotionData?.dateFrom), {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }) : ""}</span>
              </div>
            </Col>}

            {promotionData?.dateTo && <Col md={6}>
              <div className={`${m.promoDesc}`}>
                <p>End date</p>
                <span>{promotionData?.dateFrom ? dateFormat(new Date(promotionData?.dateTo), {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }) : ""}</span>
              </div>
            </Col>}
            {promotionData?.discountCode && (
              <Col md={12}>
                <div className={`${m.promoDesc}`}>
                  <p>Discount code</p>
                  <span className={`${m.spanBox}`}>{promotionData?.discountCode}</span>
                </div>
              </Col>
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {/* <button type="button" className={`btn ${m.cancelBtn}`}>
            Change
          </button> */}
          <button
            type="button"
            className={`btn ${m.actionBtn}`}
            onClick={handlePromotionClose}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewPromotion;
