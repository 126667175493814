import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  massage,
  hair,
  lashes,
  nails,
  facilities,
  waxfill,
  makeupfill,
  userFrame,
} from "../../../assets/images";
import { FiSearch } from "react-icons/fi";
import AddBookingClientModal from "./AddBookingClientModal";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

const AddClientModal = (props: any) => {
  let { showClient, handleAddClientClose, clientData, setSelectedClient, selectedClient, type } = props;
  const [clientList, setClientList] = useState<any>([]);
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );

  const [showBookingClient, setShowClient] = useState(false);
  const handleBookingClientClose = () => setShowClient(false)
  const handleBookingClientShow = () => setShowClient(true);
  const [clients, setClients] = useState<any>([]);

  const path = window.location.pathname;
  const getClient = async () => {
    const path = type === "booking" ? `getClient?businessId=${businessData?._id}` : `getClient?branchId=${branchId}`;
    let res = await httpRequest(
      path,
      "get",
      null,
      "json"
    );

    if (res.status === true) {
      setClients(res?.data);
      setClientList(res?.data);
    } else {
      console.error("Request failed:", res.error);
    }
  };

  useEffect(() => {
    if (clientData?.length === 0) {
      getClient()
    }
  }, [branchId])

  useEffect(() => {
    if (clientData?.length !== 0) {
      setClientList(clientData);
    }
  }, [clientData])

  const [term, setTerm] = useState("");
  const [selectClientee, setSelectClienteee] = useState<any>([])

  useEffect(() => {
    if (selectedClient?.length !== 0) {
      setSelectClienteee(selectedClient)
    }
  }, [selectedClient])

  const handleClientSelect = (client: any) => {
    if (selectClientee?.length === 0) {
      setSelectClienteee([client])
      return
    }
    if (selectClientee?.some((data: any) => data?._id === client?._id)) {
      const oldClient = selectClientee?.filter((data: any) => data?._id !== client?._id)
      setSelectClienteee(oldClient)
      return
    }

    const filterClient = selectedClient?.filter((data: any) => data?._id !== client?._id)
    setSelectClienteee([client]);
  };

  const handleSave = () => {
    // setSelectedClient((prev: any) => [ ...selectClientee]);
    setSelectedClient(selectClientee);
    // let string = JSON.stringify([...selectClientee]);
    // localStorage.setItem('selectedClient', string);
    handleAddClientClose()

  };

  useEffect(() => {
    if (term !== "") {
      let filterClient: any = [];
      if (clientData?.length !== 0) {
        filterClient = clientData?.filter((data: any) => {
          return `${data?.firstName}${data?.lastName}${data?.email}${data?.mobile}`.toLocaleLowerCase()?.includes(term?.toLocaleLowerCase())
        })
      } else {
        filterClient = clients?.filter((data: any) => {
          return `${data?.firstName}${data?.lastName}${data?.email}${data?.mobile}`.toLocaleLowerCase()?.includes(term?.toLocaleLowerCase())
        })
      }
      setClientList(filterClient)
    } else {
      setClientList(clientData?.length !== 0 ? clientData : clients);
    }
  }, [term])
  return (
    <>
      {showBookingClient && <AddBookingClientModal getClientData={getClient} showBookingClient={showBookingClient} handleBookingClientClose={handleBookingClientClose} />}
      <Modal
        scrollable
        centered
        show={showClient}
        backdrop="static"
        keyboard={false}
        onHide={handleAddClientClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts} ${m.modalSm} ${m.ModalHeaderBG} ${showBookingClient ? m.hide : ""}`}
      >
        <Modal.Header>
          <Modal.Title>
            <NavLink to="#" onClick={handleAddClientClose}>
              <svg
                width="38"
                height="37"
                viewBox="0 0 38 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                  fill="#3C746D"
                ></path>
                <path
                  d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                  stroke="#ACD9D0"
                ></path>
                <path
                  d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                  stroke="#3C746D"
                ></path>
                <path
                  d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                  fill="#3C746D"
                ></path>
                <path
                  d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                  stroke="#ACD9D0"
                ></path>
                <path
                  d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                  stroke="#3C746D"
                ></path>
              </svg>
            </NavLink>
            Select Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div className={`${m.searchBox}`}>
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search by Client"
                  onChange={(e: any) => setTerm(e.target.value)}
                  value={term}
                />
                <FiSearch />
              </div>
            </Col>
            <Col md={12}>
              <h5 className={m.add}>
                Didn’t find a client?
                {path === "/business/bookings" ? (
                  <NavLink to="#">
                    <span onClick={() => handleBookingClientShow()}>Add another Client</span>
                  </NavLink>

                ) : (
                  <NavLink to="/business/clients/addClient">
                    <span>Add another Client</span>
                  </NavLink>
                )}

              </h5>
            </Col>

            <Col md={12}>
              <div className={`${m.makeupHeading}`}>
                <div className={`${m.titleMakeup}`}>
                  <div className={`${m.heading}`}>
                    {/* <h2>
                      Recent <span>(1)</span>
                    </h2> */}
                  </div>
                </div>
              </div>
            </Col>

            {clientList?.map((val: any, index: any) => {
              return (
                <Col md={12} key={val?._id}>
                  <div key={val?._id} className={`${m.addClient} ${m.noBorder}`} onClick={() => handleClientSelect(val)} style={{
                    background: selectClientee?.includes(val) ? "#e9f6f1" : ""
                  }}>
                    <div className={m.left}>
                      <img src={userFrame} alt="user" />
                      <div className={m.userInfo}>
                        <h3>{val?.firstName} {val?.lastName}</h3>
                        <p>{val?.email}</p>
                        <p>{val?.mobile}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })}

          </Row>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={handleAddClientClose}
          >
            Cancel
          </button>
          <button type="button" className={`btn ${m.actionBtn}`} onClick={handleSave}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddClientModal;
