import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";

import { plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";


const Amenities = (props: any) => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
);
  const [amenitiesData,setAmenitiesData] = useState<any[]>([])
  useEffect(() => {
    getAmenities()
  }, [branchId]);
  const getAmenities = async () => {
    let res = await httpRequest(`getAmenities?_id=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status === true) {
       setAmenitiesData(res.data);
    }
  } 

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle} `}>
          <div className={`${st.titleInfo}`}>
            <h5>Amenities</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.buttonsAction} ${st.buttonflexDiv} `}>
          <div className={`${st.threeButton} ${cx.threeBtn}`}>
            <ul>
              <li>
                <button className={`${st.btn} ${st.active}`}>All</button>
              </li>
              <li>
                <button className={`${st.btn}`}>Available</button>
              </li>
              <li>
                <button className={`${st.btn} ${st.active}`}>Occupied</button>
              </li>
            </ul>
          </div>

          <ul className={cx.btnGroup}>
            <li>
              <ExportBtn />
            </li>
            <li>
              <ImportBtn />
            </li>
            <li>
              <NavLink to="addAmenities" className={`btn ${st.darkBtn}`}>
                <img src={plus} alt="Create new Service" /> Add a New Item
              </NavLink>
            </li>
          </ul>
        </div>

        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <DataGridTable />
          </div>
        </div>
      </section>
    </>
  );
};

export default Amenities;
