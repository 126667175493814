import React from 'react'
import { teamPerson1 } from '../../../assets/images'
import { Col, Row, Table, Tooltip } from 'react-bootstrap'
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import cx from './index.module.scss';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

// line chart data
const data = [
    { name: "Fri, 21", USD: 0 },
    { name: "Sat, 22", USD: 220 },
    { name: "Sun, 23", USD: 200 },
    { name: "Mon, 24", USD: 170 },
    { name: "Tue, 25", USD: 300 },
    { name: "Wed, 26", USD: 400 },
    { name: "Thu, 27", USD: 350 },
    { name: "Fri, 28", USD: 260 },
];

// bar chart data
const barData = [
    { name: "Sat, 22", Appointment: 1 },
    { name: "Sun, 23", Appointment: 4 },
    { name: "Mon, 24", Appointment: 0 },
    { name: "Tue, 25", Appointment: 0 },
    { name: "Wed, 26", Appointment: 3 },
    { name: "Thu, 27", Appointment: 1 },
    { name: "Fri, 28", Appointment: 1 },
];

export default function WeeklyData() {
    return (
        <>
            <Row>
                <Col lg={3} md={6}>
                    <div className={`${cx.upDownSale_card} mb-3`}>
                        <h3>Total Booking revenue</h3>
                        <h2>$5,52,589</h2>
                        <div className={`${cx.upDown_percentMain}`}>
                            <div className={`${cx.upDownPercentBox} ${cx.upPercentBox}`}><GoArrowUp /> 12.5%</div>
                            <p>Compared to yesterday</p>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6}>
                    <div className={`${cx.upDownSale_card} mb-3`}>
                        <h3>Sales Completed</h3>
                        <h2>$5,52,589</h2>
                        <div className={`${cx.upDown_percentMain}`}>
                            <div className={`${cx.upDownPercentBox} ${cx.downPercentBox}`}><GoArrowDown /> 12.5%</div>
                            <p>Compared to yesterday</p>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6}>
                    <div className={`${cx.upDownSale_card} mb-3`}>
                        <h3>Available Slot </h3>
                        <h2>$5,52,589</h2>
                        <div className={`${cx.upDown_percentMain}`}>
                            <div className={`${cx.upDownPercentBox} ${cx.upPercentBox}`}><GoArrowUp /> 12.5%</div>
                            <p>Compared to yesterday</p>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6}>
                    <div className={`${cx.upDownSale_card} mb-3`}>
                        <h3>Booked slot</h3>
                        <h2>$5,52,589</h2>
                        <div className={`${cx.upDown_percentMain}`}>
                            <div className={`${cx.upDownPercentBox} ${cx.upPercentBox}`}><GoArrowUp /> 12.5%</div>
                            <p>Compared to yesterday</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="my-2">
                    <div className={`${cx.teamService_mainBox}`}>
                        <div className={`${cx.teamService_main_heading}`}>
                            <h3>Recent <span>(Last 7 days)</span></h3>
                            <h2 className={cx.usd}>USD 100</h2>
                            <p className={cx.appointment}>
                                Appointments: <span>4</span>
                            </p>
                            <p className={cx.appointment}>
                                Appointments value: <span>USD 282.00</span>
                            </p>
                        </div>
                        <div className={`${cx.ChartContainer}`}>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" angle={-45} textAnchor="end" interval={0} dy={3} />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="USD" stroke="#1AB192" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </Col>
                <Col lg={6} className="my-2">
                    <div className={`${cx.teamService_mainBox}`}>
                        <div className={`${cx.teamService_main_heading}`}>
                            <h3>Upcoming appointments <span>(Last 7 days)</span></h3>
                            <h2 className={cx.usd}>3 booked</h2>
                            <p className={cx.appointment}>
                                Appointments: <span>4</span>
                            </p>
                            <p className={cx.appointment}>
                                Appointments value: <span>USD 282.00</span>
                            </p>
                        </div>
                        <div className={`${cx.ChartContainer}`}>
                            <ResponsiveContainer width={"100%"} height={300}>
                                <BarChart data={barData} barSize={20}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" angle={-45} textAnchor="end" interval={0} dy={3} />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Appointment" fill="#B09B28" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </Col>
                <Col lg={6} className="my-2">
                    <div className={`${cx.teamService_mainBox}`}>
                        <div className={`${cx.teamService_main_heading} pb-1`}>
                            <h3>Top Services <span>(Last 7 days)</span></h3>
                        </div>

                        <div className={`${cx.serviceTable}`}>
                            <Table borderless>
                                <thead>
                                    <tr>
                                        <th>Service Name</th>
                                        <th>This Month</th>
                                        <th>Last Month</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Blow Dry</td>
                                        <td>100</td>
                                        <td>350</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Cut</td>
                                        <td>96</td>
                                        <td>250</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Cut</td>
                                        <td>96</td>
                                        <td>250</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Cut</td>
                                        <td>96</td>
                                        <td>250</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Cut</td>
                                        <td>96</td>
                                        <td>250</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Cut</td>
                                        <td>96</td>
                                        <td>250</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Cut</td>
                                        <td>96</td>
                                        <td>250</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Cut</td>
                                        <td>96</td>
                                        <td>250</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Cut</td>
                                        <td>96</td>
                                        <td>250</td>
                                    </tr>
                                    <tr>
                                        <td>Hair Cut</td>
                                        <td>96</td>
                                        <td>250</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col lg={6} className="my-2">
                    <div className={`${cx.teamService_mainBox}`}>
                        <div className={`${cx.teamService_main_heading} pb-1`}>
                            <h3>Top Team <span>(Last 7 days)</span></h3>
                        </div>

                        <div className={`${cx.teamTable}`}>
                            <Table borderless>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>This Month</th>
                                        <th>Total Sales</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className={`${cx.teamimgBox}`}>
                                                <div className={`${cx.avatar}`}>
                                                    <img src={teamPerson1} alt="person" />
                                                </div>
                                                <div className={`${cx.detail}`}>
                                                    <h5>Ryan Morrisson</h5>
                                                    <span>Hairdresser</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>100</td>
                                        <td>350</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${cx.teamimgBox}`}>
                                                <div className={`${cx.avatar}`}>
                                                    <img src={teamPerson1} alt="person" />
                                                </div>
                                                <div className={`${cx.detail}`}>
                                                    <h5>Ryan Morrisson</h5>
                                                    <span>Hairdresser</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>100</td>
                                        <td>350</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${cx.teamimgBox}`}>
                                                <div className={`${cx.avatar}`}>
                                                    <div className={`${cx.avatarAlt}`}>RM</div>
                                                </div>
                                                <div className={`${cx.detail}`}>
                                                    <h5>Ryan Morrisson</h5>
                                                    <span>Hairdresser</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>100</td>
                                        <td>350</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${cx.teamimgBox}`}>
                                                <div className={`${cx.avatar}`}>
                                                    <img src={teamPerson1} alt="person" />
                                                </div>
                                                <div className={`${cx.detail}`}>
                                                    <h5>Ryan Morrisson</h5>
                                                    <span>Hairdresser</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>100</td>
                                        <td>350</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${cx.teamimgBox}`}>
                                                <div className={`${cx.avatar}`}>
                                                    <img src={teamPerson1} alt="person" />
                                                </div>
                                                <div className={`${cx.detail}`}>
                                                    <h5>Ryan Morrisson</h5>
                                                    <span>Hairdresser</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>100</td>
                                        <td>350</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${cx.teamimgBox}`}>
                                                <div className={`${cx.avatar}`}>
                                                    <img src={teamPerson1} alt="person" />
                                                </div>
                                                <div className={`${cx.detail}`}>
                                                    <h5>Ryan Morrisson</h5>
                                                    <span>Hairdresser</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>100</td>
                                        <td>350</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
