import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Badge, Col, Dropdown, Row } from "react-bootstrap";
import { call, callWhite, img1 } from "../../../assets/images";
import { FiCamera, FiSearch } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../Firebase";
import { useSelector } from "react-redux";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { MdInsertPhoto } from "react-icons/md";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
const User = (props: any) => {
  const {
    chatUserList,
    handleChatSelection,
    chatHeadList: chatHead,
    chatmessage,
    loading,
    selectedChat,
    uid
  } = props;
  // let currentUserId = currentUser?._id;
  const [chatHeadList, setChatHeadList] = useState<any>([])
  useEffect(() => {
    setChatHeadList(chatHead)
  }, [chatHead])
  const [searchTxt, setSearchTxt] = useState("");
  const [filteredUsersList, setFilteredUsersList] = useState<any[]>([]);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [isActive, setIsActive] = useState<any>("");
  const location = useLocation();
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const [allUserIds, setAllUserIds] = useState([]);

  let currentId = selectedChat?.clientUid;
  let chatId = generateChatId(currentId, uid);

  useEffect(() => {
    if (uid) {
      const unSub = onSnapshot(
        doc(db, "chats", uid, "chatUsers", chatId),
        (doc: any) => {
          if (doc?.data()?.messages) {
            const selectedChatHead = chatHead?.find((item: any) => item?.id === chatId)
            const filterChatHead = chatHead?.filter((item: any) => item?.id !== chatId) || []
            setChatHeadList([...filterChatHead, { ...selectedChatHead, allmessage: doc?.data()?.messages?.map((item: any) => item?.text), lastMessage: doc?.data().messages?.[doc?.data().messages?.length - 1]?.text }])
          }
        }
      );
      return () => {
        unSub();
      };
    }
  }, [selectedChat]);


  // const { appointment } = location?.state;

  const [appointment, setAppointment] = useState<any>(null)

  useEffect(() => {
    if (location?.state?.appointment) {
      setAppointment(location?.state?.appointment)
    }
  }, [location?.state])

  useEffect(() => {
    if (chatHeadList && chatHeadList?.length !== 0) {
      let list: any = filterUserList(chatUserList, chatHeadList);
      list.sort((a: any, b: any) => {
        return b.updateMessage.seconds - a.updateMessage.seconds;
      });
      setFilteredUsersList(list)
      setUsersList(list);
    }
  }, [chatHeadList, chatUserList]);

  useEffect(() => {
    if (searchTxt !== "") {
      let messagesWithKeyword: any = usersList?.filter((item: any) => {
        if (Array.isArray(item.allMessage)) {
          return item.allMessage.some((message: any) => {
            if (typeof message === 'string' && message.toLowerCase().includes(searchTxt.toLowerCase())) {
              item.lastMsg = message;
              // setFilterMessage(message)
              chatmessage(message)
              return true;
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
      const itemsWithFirstName: any = usersList?.filter((item: any) => item?.Client?.firstName?.toLowerCase().includes(searchTxt.toLowerCase()));

      let combinedResults = [...messagesWithKeyword, ...itemsWithFirstName];
      combinedResults = combinedResults.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      setFilteredUsersList(combinedResults)

    } else {
      let list: any = filterUserList(chatUserList, chatHeadList);
      setFilteredUsersList(list);
      chatmessage("")
    }
  }, [searchTxt]);

  // useEffect(()=>{
  //     setIsActive(selectedChat);
  // },[])

  const handleItemClick = (item: any) => {
    const idValue = item?._id || '';
    const replacedIdValue = idValue.replace(/-/g, '')
    const modifiedData: any = { ...item, _id: replacedIdValue };
    setIsActive(item?._id);
    handleChatSelection(modifiedData);
  };

  useEffect(() => {
    if (appointment) {
      setIsActive(appointment)
    }
  }, [appointment])


  function filterUserList(userList: any, chatheads: any) {
    if (userList) {
      const sortedArray: any[] = userList.map((item: any) => {
        // const currentId: number = new Date(item?.clientUid).getTime();
        const stringWithoutHyphens: string = item?._id?.replace(/-/g, '') || '';
        const chatId: string = generateChatId(item?.clientUid, stringWithoutHyphens);
        const relatedItem: any = chatheads?.find((secondItem: any) => secondItem.id === chatId);

        const lastMsg: string = relatedItem && relatedItem.lastMessage ? relatedItem.lastMessage : '';
        const updateMessage: any = relatedItem && relatedItem.date ? relatedItem.date : '';
        const allMessage: any[] = relatedItem && relatedItem.allmessage ? relatedItem.allmessage : '';
        const countMap: any = relatedItem && relatedItem.counterMap ? relatedItem.counterMap : [];

        const Img = item
        return { ...item, lastMsg, allMessage, countMap, updateMessage, isChatted: relatedItem?.id !== undefined };
      })
      return sortedArray;
    }
    return []; // Return an empty array if userList is falsy
  }
  function generateChatId(user1Id: any, user2Id: any) {
    const sortedUserIds = [user1Id, user2Id].sort();
    return sortedUserIds.join("_");
  }

  function compareDates(date1: any, date2: any) {
    // Create Date objects for each input date
    var d1 = new Date(date1);
    var d2 = new Date(date2);

    // Extract year, month, and day values
    var year1 = d1.getFullYear();
    var month1 = d1.getMonth();
    var day1 = d1.getDate();

    var year2 = d2.getFullYear();
    var month2 = d2.getMonth();
    var day2 = d2.getDate();

    // Compare the dates
    if (year1 === year2 && month1 === month2 && day1 === day2) {
      return 0; // Dates are equal
    } else if (year1 < year2 || (year1 === year2 && month1 < month2) || (year1 === year2 && month1 === month2 && day1 < day2)) {
      return -1; // date1 is earlier than date2
    } else {
      return 1; // date1 is later than date2
    }
  }

  return (
    <>

      <Col className={`${cx.chatList} p-0 ${selectedChat?.Client?._id ? "col" : "col-4"}`}>
        <Col md={12}>
          <div className="text-end">
            {/* <HelpButon number={53} type={"help"} className={"btn"} /> */}
          </div>
        </Col>
        <div className={`${cx.chatHeading} justify-content-between gap-2`}>
          <span>Chats</span>
          <HelpButon number={53} type={"help"} className={"btn"} />
        </div>
        <div className={`${cx.chatSearchBox}`}>
          <FiSearch className={`${cx.searchBtn}`} />
          <input type="search" className="form-control" value={searchTxt}
            placeholder="Search"
            onChange={(e) => {
              setSearchTxt(e.target.value);
            }} />
        </div>
        <ul>
          {loading ? (<li><button className={`${cx.listMenu}`}>
            <div className={`${cx.chatListBody}`}>
              <h5 style={{ color: "#3e8575" }}>

                Fetching...
              </h5>
            </div>
          </button></li>)
            : filteredUsersList?.map((item: any) => {
              console.log(item, 'itemData');
              
              // if (item?.isChatted || item?._id === appointment) {
              if (true) {
                let timeStamp: any = item?.updateMessage
                let timeDate: any;

                if (timeStamp) {
                  timeDate = new Date(timeStamp?.seconds * 1000 + timeStamp?.nanoseconds / 1000000)
                }

                const options: any = {
                  month: '2-digit',
                  day: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                };

                const formatter = new Intl.DateTimeFormat('en-US', options);

                const formattedDate = timeDate ? formatter.format(timeDate) : ""
                const date = timeDate ? formattedDate?.split(",")?.[0] : ""
                const time = timeDate ? formattedDate?.split(",")?.[1] : ""
                console.log(time, "dynamicDate")
                const checkIfBookingToday = !formattedDate ? false : compareDates(date, new Date()) === 0;

                const dynamicDate = !formattedDate ? "" : checkIfBookingToday ? time : date
                console.log(item?.images, "dynamicDate")

                const firstNameLetter = item?.Client?.firstName?.charAt(0).toUpperCase();
                const firstLastNameLetter = item?.Client?.lastName?.charAt(0).toUpperCase();
                let elementWithId2 = item?.countMap.find((newitem: any) => newitem.id === item?._id);
                return (
                  <li>
                    <button
                      className={`${cx.listMenu} ${item?._id === isActive ? cx.active : ''}`}
                      onClick={() => handleItemClick(item)}>
                      {item?.Client?.image ?
                        <img src={item?.Client?.image ? item?.Client?.image : img1} className={`${cx.profileImg}`} />
                        : (
                          <div className={cx.businessIconBox}>
                            <span>{`${firstNameLetter}${firstLastNameLetter}`}</span>
                          </div>
                        )}
                      <div className={`${cx.chatListBody_mainBox}`}>
                        <div className={`${cx.chatListBody}`}>
                          <h5 title={`${item?.Client?.firstName || ""} ${item?.Client?.lastName || ""} (${item?.services?.map((item: any) => item?.serviceDetails?.serviceName).join(",")})`}>
                            {item?.Client?.firstName || ""} {item?.Client?.lastName || ""} ({item?.services?.map((item: any) => item?.serviceDetails?.serviceName).join(",")})

                          </h5>
                          <span>{dynamicDate}</span>
                          {/* <p>{item?.allMessage?.length !== 0 && item?.lastMsg === "" ? <MdInsertPhoto /> : item?.lastMsg}</p> */}

                        </div>
                        <p>
                          {item?.allMessage?.length !== 0 && item?.lastMsg === ""
                            ? <>
                              <MdInsertPhoto /> Image
                            </>
                            : item?.lastMsg}
                        </p>
                      </div>
                    </button>
                  </li>)
              }
            })}

        </ul>
      </Col>
      {!selectedChat?.Client?._id && (
        <Col lg={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
          <div className={`${cx.noAppoint_yet}`}>
            <div className={`${cx.icon}`}>
              <BiSolidMessageSquareDetail />
            </div>
            <h4>Select a conversation from the list to start chatting</h4>
          </div>
        </Col>
      )}
    </>
  );
};

export default User;
