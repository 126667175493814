import React from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import {
  Col,
  Modal, Row
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import MultiSelect from "../MultiSelect/MultiSelect";
import { MdClose } from 'react-icons/md';

const AssignTeam = (props: any) => {
  let { show, handleClose } = props;
  // const coupons = [1,2,3,4,5,6,7];
  return (
    <>
    <Modal
        centered show={show} onHide={handleClose} backdrop="static"
        keyboard={false} aria-labelledby="example-modal-sizes-title-sm" className={m.modalCts}>
          <Modal.Header>
            <Modal.Title>Assign Team</Modal.Title>
            <button className={`${m.closeIcon}`} onClick={handleClose}><MdClose /></button>
          </Modal.Header>
          
          <Modal.Body>

          <Row>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Team Member</label>
                <MultiSelect />
              </div>
            </Col>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Role</label>
                <MultiSelect />
              </div>
            </Col>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Add Acesss</label>
                <MultiSelect />
              </div>
            </Col>
          </Row>
          </Modal.Body>
          <Modal.Footer>
          <button type="button" className={`btn ${m.cancelBtn}`} onClick={handleClose}>
            Close
          </button>
          <button type="button" className={`btn ${m.actionBtn}`}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignTeam;
