import { Dropdown } from "react-bootstrap";
import { deleteIcon, editButton } from "../../../assets/images";
import { BsThreeDotsVertical } from "react-icons/bs";
import st from "../../../assets/stylesheet/style.module.scss";
import { DeletePopup } from "../../../components/Business/Modals";
import { useState } from "react";
import { httpRequest } from "../../../Apis/commonApis";
import AddServicesCategory from "../../../components/Business/Modals/AddServicesCategory";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ServiceCategoryItem({ cat, getServiceCatagory, filterCat, setFilterCat, handleServiceCanvasShow ,setEditServiceData}: any) {
    const [apiMessage, setApiMessage] = useState('')
    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showServicesCategory, setServicesCategoryShow] = useState(false);
    const handleServicesCategoryClose = () => setServicesCategoryShow(false);
    const handleServicesCategoryShow = () => setServicesCategoryShow(true);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setApiMessage("")
        setShow(true);
    };
    const deleteCategory = async () => {
        const res = await httpRequest(
            `deleteServiceCatagory?_id=${cat?._id}`,
            "DELETE",
            null,
            "json"
        );
        if (res?.status) {
            getServiceCatagory();
            handleClose();
            setApiMessage("")
        } else {
            setApiMessage(res?.message)
        }
    };
    const canCreateService = subModuleRoles?.['Services']?.Services?.Create;
    const canEditCategory = subModuleRoles?.['Services']?.['Service Category']?.Edit;
    const canDeleteCategory = subModuleRoles?.['Services']?.['Service Category']?.Delete;
    return (
        <>
            <DeletePopup
                show={show}
                handleClose={handleClose}
                deleteApi={deleteCategory}
                apiMessage={apiMessage}
            />
            {showServicesCategory && <AddServicesCategory
                show={showServicesCategory}
                handleServicesCategoryClose={handleServicesCategoryClose}
                getServiceCatagory={getServiceCatagory}
                cat={cat}
            />}
            <li key={cat?._id}>
                <span className={`${st.categoryName} ${filterCat?._id === cat?._id ? st.active : ''}`} onClick={() => setFilterCat(cat)}>{cat?.serviceCategoryName}</span>
                <span className={`${st.categoryAvail}`}>
                    <span className={`${st.value}`}>{cat?.count}</span>
                    {(canEditCategory || canCreateService || canDeleteCategory) && <span className={`${st.dotsDropDown}`}>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <BsThreeDotsVertical />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <span className='editDeleteBtn flex-column justify-content-left'>
                                    {canEditCategory && <button className="btn" onClick={handleServicesCategoryShow}>
                                        {/* <img src={editButton} alt="edit" /> */}
                                        Edit
                                    </button>}
                                    {canCreateService && <button className="btn" onClick={() => {
                                        setEditServiceData((prev:any) => ({ ...prev, serviceCategory: cat }))
                                        navigate('/business/services/addService/info')
                                        handleServiceCanvasShow()
                                    }}>
                                        Add Service
                                    </button>}
                                    {canDeleteCategory && <button className="btn" onClick={handleShow}>
                                        {/* <img src={deleteIcon} alt="delete" /> */}
                                        Delete
                                    </button>}
                                </span>
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>}
                </span>
            </li>
        </>
    )
}