import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import {
  bundles,
  clock,
  clockA,
  edit,
  packageActive,
  packageEmpty,
  packages,
  plus,
  rightIcon,
  schedule,
  serviceGreen,
  servicesActive,
  wrongIcon,
} from "../../../assets/images";
import cx from "./index.module.scss";
import { Col, Row } from "react-bootstrap";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

const Notification = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <Row className='align-items-center '>
        <Col md={12}>
        <div className="text-end">
          <HelpButon number={54} type={"help"} className={"btn"} />
        </div>
      </Col>
          <Col lg={4} md={12}>
          <div className={cx.pageTitle}>
          <h5>Notification</h5>
          </div>
          </Col>
          <Col lg={8} md={12}>
          
            <div className={`${cx.btnGroup}`}>
              <ul>
                <li>
                  <button className={`${cx.btn} ${cx.active}`}>Active bookings</button>
                </li>
                <li>
                  <button className={`${cx.btn} ${cx.active}`}>
                    Past Bookings
                  </button>
                </li>
                <li>
                  <button className={`${cx.btn} `}>Reviews</button>
                </li>
              </ul>
            </div>
          
          </Col>
        </Row>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            
          </div>
          
        </div>

        <div>
          <Row>
            <Col lg={12} md={12}>
              <div className={cx.customer}>
                <div
                  className={cx.border}
                  role="img"
                  
                ></div>
                <div className={cx.main}>
                  <div className={cx.top}>
                    <Row>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.date}>10 Jul, 2023</p>
                        <p className={cx.time}>10:20 am-11:20 am</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.service}>Freehand/Balayage Hair Cut</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={`${cx.status} ${cx.divider}`}>Stylist</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.status}>Client</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                        <p className={cx.status}>+380 96 63 10 000</p>
                      </Col>
                      <Col
                        lg={2}
                        md={3}
                        sm={3}
                        className="mb-3 d-flex align-items-center justify-content-between"
                      >
                        <p className={cx.name}>75USD</p>
                        <p className={cx.name}>Chair 7C</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={cx.bottom}>
                    <p>Kim accepted your reschedule</p>
                    {/* <button>View Message</button> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12} md={12}>
              <div className={cx.customer}>
                <div
                  className={cx.border}
                  role="img"
                  
                ></div>
                <div className={cx.main}>
                  <div className={cx.top}>
                    <Row>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.date}>10 Jul, 2023</p>
                        <p className={cx.time}>10:20 am-11:20 am</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.service}>Freehand/Balayage Hair Cut</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={`${cx.status} ${cx.divider}`}>Stylist</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.status}>Client</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                        <p className={cx.status}>+380 96 63 10 000</p>
                      </Col>
                      <Col
                        lg={2}
                        md={3}
                        sm={3}
                        className="mb-3 d-flex align-items-center justify-content-between"
                      >
                        <p className={cx.name}>75USD</p>
                        <p className={cx.name}>Chair 7C</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={cx.bottom}>
                    <div>
                      <p>Kim replyed to your message</p>
                    </div>
                    <button>View Message</button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12} md={12}>
              <div className={`${cx.customer} ${cx.cancel}`}>
                <div className={`${cx.border} ${cx.cancel}`} role="img"></div>
                <div className={cx.main}>
                  <div className={cx.top}>
                    <Row>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.date}>10 Jul, 2023</p>
                        <p className={cx.time}>10:20 am-11:20 am</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.service}>Freehand/Balayage Hair Cut</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={`${cx.status} ${cx.divider}`}>Stylist</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.status}>Client</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                        <p className={cx.status}>+380 96 63 10 000</p>
                      </Col>
                      <Col
                        lg={2}
                        md={3}
                        sm={3}
                        className="mb-3 d-flex align-items-center justify-content-between"
                      >
                        <p className={cx.name}>75USD</p>
                        <p className={cx.name}>Chair 7C</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${cx.bottom} ${cx.cancel}`}>
                    <p className={cx.cancel}>The customer canceled the booking</p>
                    <button>Reply</button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12} md={12}>
              <div className={`${cx.customer} ${cx.cancel}`}>
                <div className={`${cx.border} ${cx.cancel}`} role="img"></div>
                <div className={cx.main}>
                  <div className={cx.top}>
                    <Row>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.date}>10 Jul, 2023</p>
                        <p className={cx.time}>10:20 am-11:20 am</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.service}>Freehand/Balayage Hair Cut</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={`${cx.status} ${cx.divider}`}>Stylist</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.status}>Client</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                        <p className={cx.status}>+380 96 63 10 000</p>
                      </Col>
                      <Col
                        lg={2}
                        md={3}
                        sm={3}
                        className="mb-3 d-flex align-items-center justify-content-between"
                      >
                        <p className={cx.name}>75USD</p>
                        <p className={cx.name}>Chair 7C</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${cx.bottom} ${cx.cancel}`}>
                    <div>
                      <p className={cx.cancel}>The booking was canceled.</p>
                      <p className={cx.cancel}>
                        We do not have this stylist available
                      </p>
                    </div>
                    <button>Reschedule</button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Notification;
