import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab, Table } from "react-bootstrap";
import { plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import CreateSaleModal from "../../../components/Business/Modals/NewSale";
import { useForm, Controller } from "react-hook-form";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

const Sales = (props: any) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
 

  // const [clientId, setClientId] = useState();
  const [showSale, setShowSale] = useState(false);
  const handleSaleClose = () => setShowSale(false);
  const handleSaleShow = () => setShowSale(true);
  const [clientData, setClientData] = useState<any[]>([]);
  const [service, setService] = useState<any>([]);
  const [packageData, setPackageData] = useState<any>([]);
  const [memberData, setMemberData] = useState<any>([]);
  const [salesData, setSalesData] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (businessData) {
      fetchSales();
      fetchPakages(businessData._id);
      fetchMember(businessData._id);
      getClientData(businessData._id);
      fetchServices(businessData._id);
    }
  }, [businessData]);

  const getClientData = async (id: any) => {
    let res = await httpRequest(
      `getClient?businessId=${id}`,
      "get",
      null,
      "json"
    );
    if (res.status === true) {
      setClientData(res.data);
    }
  };

  const fetchServices = async (id: any) => {
    let res = await httpRequest(
      `getBusinessPrice?businessId=${id}&type=package`,
      "get",
      null,
      "json"
    );
    setService(res?.data);
  };

  const fetchPakages = async (id: any) => {
    let res = await httpRequest(
      `getPackages?businessId=${id}`,
      "get",
      null,
      "json"
    );
    setPackageData(res.data);
  };

  const fetchMember = async (id: any) => {
    let res = await httpRequest(
      `getMembership?businessId=${id}`,
      "get",
      null,
      "json"
    );
    setMemberData(res.data);
  };

  const fetchSales = async () => {
    let res = await httpRequest(
      `getSales?businessId=${businessData?._id}`,
      "get",
      null,
      "json"
    );
    setSalesData(res.data);
    reset();
  };

  const canCreateSale = subModuleRoles?.['Business Set Up']?.Sales?.Create;

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Sales</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>
        <div className={`${st.buttonsAction} ${cx.actionBtn}`}>
          <Row className="align-items-start">
            <Col md={3}>
              <button className={`${cx.todayBtn}`}>Today</button>
            </Col>
            <Col md={9}>
              {canCreateSale && <ul>
                <li>
                  <ExportBtn />
                </li>
                <li>
                  <ImportBtn />
                </li>
                <li>
                  <NavLink
                    to="#"
                    className={`btn ${st.darkBtn}`}
                    onClick={handleSaleShow}
                  >
                    <img src={plus} alt="Create new Sale" /> Create new Sale
                  </NavLink>
                </li>
              </ul>}
            </Col>
          </Row>
        </div>

        <div className={`${cx.saleStatistic}`}>
          <div className={`${cx.saleBox}`}>
            <h5>Sales today</h5>
            <h1>500</h1>
          </div>
          <div className={`${cx.saleBox}`}>
            <h5>Particular service</h5>
            <h1>500</h1>
          </div>
          <div className={`${cx.saleBox}`}>
            <h5>xyz</h5>
            <h1>500</h1>
          </div>
          <div className={`${cx.saleBox}`}>
            <h5>memberships</h5>
            <h1>500</h1>
          </div>
          <div className={`${cx.saleBox}`}>
            <h5>xyz</h5>
            <h1>500</h1>
          </div>
        </div>

        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <DataGridTable salesData={salesData} fetchSales={fetchSales} />
          </div>
        </div>
      </section>

      {showSale && <CreateSaleModal
        showSale={showSale}
        handleSaleClose={handleSaleClose}
        service={service}
        clientData={clientData}
        setServiceData1={setService}
        packageData={packageData}
        memberData={memberData}
        salesData={salesData}
        fetchSales={fetchSales}
      />}
    </>
  );
};

export default Sales;
