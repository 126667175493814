import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { upArrow } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { AiFillCamera } from "react-icons/ai";
import { accessBranchRoleValues, accessRoleValues } from "../../../constants/Constants";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import Select from 'react-select';
import { RxCross2 } from "react-icons/rx";
import MultiSelect from "../../../components/Business/MultiSelect/MultiSelect";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
const BasicInfo = (props: any) => {
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const { handleStepsFormData, loading, editTeamData } = props;
    const [errorTrig, setErrorTrig] = useState(false);

    const navigate = useNavigate();
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );

    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [profile, setProfile] = useState(null);
    const [isGlobal, setIsGlobal] = useState(false);
    const [branchData, setBranchData] = useState([]);
    const [selectedBrachRoles, setSelectedBrachRoles] = useState<any>([]);
    const [teamTitleList, setTeamTitleList] = useState([]);
    const [customErr, setCustomErr] = useState('');
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [serviceTag, setServiceTag] = useState<any>([]);
    const [serviceIds, setServiceIds] = useState<any>([]);
    const [error, setError] = useState("")
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control,
    } = useForm();
    const {
        register: registerRole,
        handleSubmit: handleSubmitRole,
        formState: { errors: errorsRole },
        setValue: setRoleValue,
        reset: resetRole,
    } = useForm();

    useEffect(() => {
        if (editTeamData) {
            reset();
            setProfileImage(editTeamData?.image);
            setPhone(`${editTeamData?.mobile?.includes("+") ? "" : "+"}${editTeamData?.mobile}`);
            setValue('isPublic', editTeamData?.isPublic)
            setValue('isBooking', editTeamData?.isBooking)
            setValue('isAvilableForBooking', editTeamData?.isAvilableForBooking)

            setServiceTag(defaultSelectedValues)
        }
    }, [editTeamData]);


    const getService = async () => {
        const res = await httpRequest(`getBusinessService?businessId=${businessData._id}&type=list`, "get", null, "json");
        if (res.status) {
          setServiceIds(res?.data?.map((item:any) => item?._id));
        }
      };

    useEffect(() => {
        if (businessData) {
            getBranches();
            getTitle();
            getService()
        };
    }, [businessData]);
    const submitHandler = async (data: any) => {
        // if (serviceTag?.length < 1) {
        //     setError("This field is required")
        //     return;
        // }

        const existRole = editTeamData?.Role ? editTeamData?.Role?.map((item: any) => ({ ...item, branch: item?.branch?._id, role: item?.role?.map((roleItem: any) => roleItem?._id) })) : []

        let basicinfo = { ...data };
        basicinfo.mobile = phone;
        basicinfo.Role = JSON.stringify(existRole);

        if(!editTeamData){
            basicinfo.serviceId = JSON.stringify(serviceIds)
          }

        if (profile) {
            basicinfo.image = profile;
        }
        handleStepsFormData(basicinfo, "step1");
    };

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
        if (res.status) {
            let allBranches = res.data;
            allBranches.reverse();
            setBranchData(allBranches);
        }
    }

    const getTitle = async () => {
        const res = await httpRequest(
            `getTeamTitle?businessId=${businessData._id}`, "get", null, "json");
        if (res.status) {
            let result = res?.data?.filter((item: any) => item.isActive === true);
            setTeamTitleList(result);
        }
    };

    const handleFileChange = (e: any) => {
        setCustomErr('');
        const file = e.target.files[0];
        setProfile(e.target.files[0]);
        if (file) {
            // Create a URL for the selected image file
            const imageUrl = URL.createObjectURL(file);
            setProfileImage(imageUrl);
        }
    };

    const addBranchHandler = (postdata: any) => {
        setCustomErr('');
        let selectedBranch: any = branchData.find((item: any) => item._id === postdata?.branch);
        let selectedRole: any = teamTitleList.find((item: any) => item._id === postdata?.branchRole);
        let newArray = [...selectedBrachRoles]
        let roledata = {
            branchName: selectedBranch.branchName,
            branch: postdata?.branch,
            role: postdata?.branchRole,
            roleName: selectedRole?.teamTitle,
            accessManager: postdata?.branchAccessManager,
        }
        newArray.push(roledata);
        setSelectedBrachRoles(newArray);
    };

    function handleRemoveBranch(id: string) {
        let newSelected: any = [...selectedBrachRoles];
        newSelected.splice(id, 1);
        setSelectedBrachRoles(newSelected);
    }

    const handlePhoneChange: any = (value: any) => {
        if (value) {
            setPhone(value);
        } else {
            setPhone("")
        }
        if (phoneError !== '') {
            setPhoneError('');
        }
    };
    const handleSelectChange = (selectedOptions: any) => {
        // Handle the selected options as needed
        const newSelectedIds: any = selectedOptions.map((option: any) => option.value);
        setServiceTag(newSelectedIds)
    };

    const options: any = teamTitleList.map((item: any) => ({
        value: item._id,
        label: item.teamTitle,
    }));

    let branchlist: any = branchData?.filter((item: any) => branchId === item?._id)
    const prefilledCountry = businessData?.countryCode ? businessData?.countryCode : 'sg';
    const defaultSelectedValues: any = editTeamData?.Role?.[0]?.role?.map((item: any) => {
        return { value: item._id, label: item.teamTitle }
    });

    return (
        <>
            <Row>
                <Col md={12}>
                    <div className="text-end">
                        <HelpButon number={10} type={"help"} className={"btn"} />
                    </div>
                </Col>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${cx.logoBox}`}>
                            <div className={cx.box}>
                                <button className={`${cx.upload_img_remove}`} >
                                    <RxCross2 />
                                </button>
                                {profileImage ? (
                                    <img
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: "15px",
                                            objectFit: "cover",
                                            boxShadow:'rgba(50, 50, 93, 0.25) 0px 2px 4px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                                            border: "1px solid gainsboro"
                                        }}
                                        src={profileImage}
                                        alt="logo"
                                    />
                                ) : (
                                    <AiFillCamera className={cx.boxImage} />
                                )}

                                <label
                                    className={cx.bottomLogo}
                                    htmlFor="team-image-upload"
                                >
                                    <input
                                        type="file"
                                        id="team-image-upload"
                                        onChange={handleFileChange}
                                    />
                                    <img src={upArrow} alt="logo" style={{ cursor: "pointer" }} />
                                </label>

                                {customErr.includes('profile') && (
                                    <p
                                        className={"errorMessage"}
                                        style={{
                                            position: "absolute",
                                            bottom: "0",
                                            left: "0",
                                            transform: "translate(-4%,135%)",
                                            width: "200px",
                                        }}
                                    >
                                        This field is required
                                    </p>
                                )}
                                {/* <span style={{ color: "#fff" }}>Upl.</span> */}
                            </div>
                            <label className={`${st.checkbox} ${cx.profileCheck}`}>
                                <input type="checkbox"
                                    {...register("isPublic")} />
                                <span className={`${st.checkmark}`}></span>
                                <span className="ms-2">Image Visible to public</span>
                            </label>
                        </div>
                    </div>
                </Col>

                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">First Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            defaultValue={editTeamData?.firstName}
                            {...register("firstName", {
                                required: true,
                            })}
                        />
                        {errors?.firstName?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Last Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            defaultValue={editTeamData?.lastName}
                            {...register("lastName", {
                                // required: true,
                            })}
                        />
                        {/* {errors?.lastName?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )} */}
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Nick Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nick Name"
                            defaultValue={editTeamData?.nickName}
                            {...register("nickName", {
                            })}
                        />
                        {/* {errors?.nickName?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )} */}
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            defaultValue={editTeamData?.email}
                            {...register("email", {
                                // required: true,
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}
                        />
                        {errors?.email?.type === "pattern" && (
                            <p className={"errorMessage"}>Invalid email address</p>
                        )}
                        {/* {errors?.email?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )} */}
                    </div>
                </Col>

                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Mobile Number</label>
                        <div className={`${st.phoneBox}`}>
                            <PhoneInput
                                defaultCountry="SG"
                                international
                                placeholder="Enter phone number"
                                value={phone}
                                onChange={handlePhoneChange}
                                error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />
                            {/* {phone && <p className={"errorMessage"}>{phone && isValidPhoneNumber(phone) ? '' : 'Invalid phone number'}</p>}
                            {errorTrig && !phone && (
                                <p className={"errorMessage"}>
                                    This field is required
                                </p>
                            )} */}
                            {/* <PhoneInput
                                country={prefilledCountry}
                                defaultMask={phone}
                                value={phone}
                                onChange={handlePhoneChange}
                                inputProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: false,
                                }}
                                defaultErrorMessage="It doesn't work, why?"
                            />

                            {phoneError.includes('phoneErr') && (
                                <p className={"errorMessage"}>Not a valid number</p>
                            )} */}
                        </div>
                    </div>
                </Col>
                {/* <Col lg={8}></Col> */}
                <Col md={8}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">About</label>
                        <textarea
                            className="form-control"
                            placeholder="About"
                            style={{ minHeight: "100px" }}
                            defaultValue={editTeamData?.about}
                            {...register("about", {
                                // required: true,
                            })}
                        ></textarea>
                        {/* {errors?.about?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )} */}
                    </div>
                </Col>

                <Col md={4}></Col>
                <Col md={12}>
                    <div className={`${st.formBox} `}>
                        <label className={`${st.checkbox} mt-1 me-4`}>
                            <input type="checkbox"
                                {...register("isAvilableForBooking")} />
                            <span className={`${st.checkmark}`}></span>
                            <span className="ms-2">Publish Profile on website</span>
                        </label>
                        <label className={`${st.checkbox} mt-1 me-2`}>
                            <input type="checkbox"
                                {...register("isBooking")} />
                            <span className={`${st.checkmark}`}></span>
                            <span className="ms-2">Profile Available for booking</span>
                        </label>
                    </div>
                </Col>

            </Row>


            <Row>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(-1);
                                }}
                            >
                                Back
                            </button>
                            <button className={`btn ${st.btn4}`} onClick={handleSubmit(submitHandler)} disabled={loading}>
                                {loading ? (<LoadingBtn />
                                ) : (
                                    'Next'
                                )}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default BasicInfo;
