import React from 'react'
import cx from './index.module.scss';
import { teamPerson1 } from '../../../assets/images';

export default function RecentNotification() {
    return (
        <div className={`${cx.notification_card}`}>
            <div className={`${cx.notification_header}`}>
                <div className={`${cx.notification_titleCount}`}>
                    <h2>Recent Notification</h2>
                    <span className={`${cx.count}`}>3</span>
                </div>
                <div className={`${cx.notification_action}`}>
                    <button>Mark all as read</button> |
                    <button>View All</button>
                </div>
            </div>

            <ul className={`${cx.notification_item}`}>
                <li>
                    <div className={`${cx.notification_ImgPara} align-items-center`}>
                        {/* <img src={teamPerson1} alt="avatar" /> */}
                        <div className={`${cx.avatarAlt}`}>AV</div>
                        <div className={`${cx.paraBox}`}>
                            <p className={`${cx.paraTop}`}>Ava <b>wants to edit</b> wellnezza design file.</p>
                        </div>
                    </div>
                    <div className={`${cx.notification_time}`}>
                        5 min ago
                    </div>
                </li>
                <li>
                    <div className={`${cx.notification_ImgPara} align-items-center`}>
                        <img src={teamPerson1} alt="avatar" />
                        <div className={`${cx.paraBox}`}>
                            <p className={`${cx.paraTop}`}>Ava <b>wants to edit</b> wellnezza design file.</p>
                        </div>
                    </div>
                    <div className={`${cx.notification_time}`}>
                        5 min ago
                    </div>
                </li>
                <li>
                    <div className={`${cx.notification_ImgPara} align-items-center`}>
                        <img src={teamPerson1} alt="avatar" />
                        <div className={`${cx.paraBox}`}>
                            <p className={`${cx.paraTop}`}>Ava <b>wants to edit</b> wellnezza design file.</p>
                        </div>
                    </div>
                    <div className={`${cx.notification_time}`}>
                        5 min ago
                    </div>
                </li>
            </ul>
        </div>
    )
}
