import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import { deleteIcon, editIcon, openIcon } from "../../../assets/images";
import { Status } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";
import { useDispatch, useSelector } from "react-redux";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import { Col } from "react-bootstrap";
import { getBusinessData } from "../../../redux_toolkit/reducer/shareDataReducer";

const StatusButton = (props: any) => {
  const { params, data, handleServiceCanvasShow, setEditServiceData } = props;
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [apiMessage, setApiMessage] = useState<any>("")
  const [show, setShow] = useState(false);

  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const dispatch = useDispatch(); 

  const handleClose = () => {
    setApiMessage("");
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const handleViewClick = () => {
    let dataToView = data?.find((val: any, index: any) => {
      return val._id == params?.row?.id;
    });
    navigate("view-service", { state: dataToView });
  }

  const handleEditClick = () => {
    let dataToEdit = data?.find((val: any) => {
      return val._id == params?.row?.id;
    });
    setEditServiceData({ isEdit: true, serviceCategory: dataToEdit?.BusinessService?.ServiceCategory, editService: dataToEdit?.BusinessService })
    handleServiceCanvasShow && handleServiceCanvasShow()
    navigate("/business/services/edit-service/info", { state: { serviceId: params?.row?.serviceId, priceId: params?.row?.id } });
    // navigate("edit-service", { state: dataToEdit });
  }



  const deleteApi = async () => {
    const res = await httpRequest(
      `deleteBusinessPrice?_id=${params?.row?.id}`,
      "DELETE",
      null,
      "json"
    );
    if (res.status) {
      props.getService();
      props?.getMainService();
      setApiMessage("")
      handleClose();
      if(res?.data?.length ===0){
        dispatch(getBusinessData({ id: `businessId=${businessData?._id}` }));
      }
    } else {
      setApiMessage(res?.message)
    }
  };

  const canEditService = subModuleRoles?.['Services']?.Services?.Edit;
  const canDeleteService = subModuleRoles?.['Services']?.Services?.Delete;
  const canDeactivate = subModuleRoles?.['Services']?.Services?.deactivate;
  
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {canEditService && <li>
            <p
              className={`btn ${tb.edit}`}
              title="Edit"
            >
              <img src={editIcon} alt="edit" onClick={() => {
                handleEditClick();
              }} />
            </p>
          </li>}
          {/* <li>
            <p
              className={`btn ${tb.edit}`}
              title="view"
            >
              <img src={openIcon} alt="view"
                onClick={() => {
                  handleViewClick();
                }}
              />
            </p>
          </li> */}
          {canDeleteService && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>}
          {canDeactivate && <li>
            <Status status={params.row.isActive}
              api='changePriceStatus' id={params?.id}
              apiMethod='json'
              getLatest={props.getMainService} />
          </li>}
        </ul>
      </div>

      <DeletePopup
        show={show}
        handleClose={handleClose}
        deleteApi={deleteApi}
        apiMessage={apiMessage}
      />
    </>
  );
};

export default function DataGridTable(props: any) {
  let data = props?.serviceList;
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [apiMessage, setApiMessage] = useState<any>("")
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const handleDeleteClose = () => {
    setApiMessage("");
    setShowDeletePopup(false);
  }

  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 220,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        if (v1 === v2) return 0;
        return v1 ? -1 : 1;
      },
      renderCell: (params: any) => (
        <StatusButton
          data={data}
          params={params}
          getService={props.getService}
          getMainService={props?.getMainService}
          handleServiceCanvasShow={props?.handleServiceCanvasShow}
          setEditServiceData={props?.setEditServiceData}
        />
      ),
    },
    { field: "category", headerName: "Service Category", flex: 2, minWidth: 150 },
    { field: "name", headerName: "Service Name", flex: 2, minWidth: 220 },
    { field: "duration", headerName: "duration", flex: 2, minWidth: 110 },
    { field: "priceType", headerName: "Price Type", flex: 2, minWidth: 140 },
    { field: "teamTitle", headerName: "Team Title", flex: 2, minWidth: 180 },
    { field: "priceText", headerName: "Price Text", flex: 2, minWidth: 180 },
    { field: "amount", headerName: "Amount ($)", flex: 2, minWidth: 140 },
    { field: "priceNote", headerName: "Price Note", flex: 2, minWidth: 150 },
    // { field: "time", headerName: "Time", flex: 2, minWidth: 150 },

  ];

  let rows: any[] = [];
  if (data) {
    rows = data?.map((val: any, index: any) => {
      console.log(val?.TeamTitleData, "valDAta");

      const priceType = `${val?.seasonType?.split("Price")[0][0]?.toLocaleUpperCase()}${val?.seasonType?.split("Price")[0]?.slice(1)} Price`;
      return {
        id: val?._id,
        serialNumber: index + 1,
        name: val?.BusinessService?.serviceName,
        priceType: priceType,
        priceText: val?.priceText || "-",
        priceNote: val?.notes || "-",
        serviceId: val?.BusinessService?._id,
        teamTitle: val?.TeamTitleData?.teamTitle || "All",
        category: val?.BusinessService?.ServiceCategory?.serviceCategoryName,
        amount: val?.seasonPrice ? `$${val?.seasonPrice}` : "$0",
        duration: val?.duration,
        isActive: val?.isActive,
        action: val?.isActive,
      };
    });
  }

  const handleSelectionChange = (data: any) => {
    setSelectedRows(data);
  }
  const deleteServices = async () => {
    const body = {
      businessPriceIds: selectedRows
    }
    const res = await httpRequest(
      `deleteBusinessPrice`,
      "DELETE",
      body,
      "json"
    );
    if (res?.status == true) {
      props.getServiceCatagory();
      setShowDeletePopup(false)
      setApiMessage("")
    } else {
      setApiMessage(res?.message)
    }
  };

  return (
    <div className={`${tb.dataTable}`}>
        <Col md={12}>
        <div className="text-end mb-2">
          <HelpButon number={27} type={"help"} className={"btn"} />
        </div>
      </Col>
     
     <div style={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          autoHeight
          hideFooterPagination={false}
          disableRowSelectionOnClick
          rowHeight={52}
          checkboxSelection={true}
          components={{ Toolbar: GridToolbar }}
          onRowSelectionModelChange={handleSelectionChange}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        {selectedRows.length > 1 && (
          <button
            className={`btn ${tb.delete} d-flex align-items-center gap-1`}
            onClick={() => setShowDeletePopup(true)}
          >
            <img src={deleteIcon} alt="Delete" />
            <span className="mt-1">Delete All</span>
          </button>
        )}
        <DeletePopup
          show={showDeletePopup}
          handleClose={handleDeleteClose}
          deleteApi={deleteServices}
          apiMessage={apiMessage}
        />
      </div>
    </div>
  );
}
