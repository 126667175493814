import React from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { importIcon } from "../../../assets/images";

const ImportBtn = (props: any) => {
  return (
    <>
     <button className={`btn ${st.whiteBtn}`}>
        <img src={importIcon} alt="import" /> Import CSV
        <input type="file" />
      </button>
    </>
  );
};

export default ImportBtn;
