import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import { deleteIcon, editIcon, openIcon } from "../../../assets/images";
import { Status } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

const StatusButton = (props: any) => {
  let { params, getPackages } = props;
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  let userID = props?.packageData.filter((p: any) => p._id === params?.row?.id)[0];
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, seterror] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const deletePackages = async (id: any) => {
    let res = await httpRequest(
      `deletePackages/${params?.id}`,
      "delete",
      null,
      "json"
    );
    if (res.status === true) {
      seterror(res?.message);
      props?.getPackages()
    } else {
      seterror(res?.message);
    }
  };

  const canEditPackage = subModuleRoles?.['Branch Set Up']?.Packages?.Edit;
  const canDeletePackage = subModuleRoles?.['Branch Set Up']?.Packages?.Delete;

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {canEditPackage && <li>
            <button className={`btn ${tb.edit}`} title="Edit" onClick={() => {
              navigate("/business/branch-packages/edit-package/basicInfo", {
                state: { packageId: params?.row?.id },
              });
            }}>
              <img src={editIcon} alt="edit" />
            </button>
          </li>}
          <li>
            <button
              className={`btn ${tb.edit}`}
              title="view"
              onClick={() => {
                navigate("/business/branch-packages/view-package", {
                  state: userID,
                });
              }}
            >
              <img src={openIcon} alt="view" />
            </button>
            {/* <NavLink className={`btn ${tb.edit}`} title="view" to="view-package">
              <img src={openIcon} alt="view" />
            </NavLink> */}
          </li>
          {canDeletePackage && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>}
          <li>
            <Status
              status={params.row.isActive}
              api="updatePackages"
              id={params?.id}
              apiMethod="json"
              getLatest={getPackages}
            />
          </li>
        </ul>
      </div>

      <DeletePopup
        show={show}
        deletePackages={deletePackages}
        handleClose={handleClose}
      />
    </>
  );
};

// check status if active then green color if not then red
export default function DataGridTable(props: any) {
  let row = props?.packageData?.map((val: any, index: any) => {

    const durationService = val?.Services?.reduce((acc: any, val: any) => {
      if (val?.serviceId === acc[acc.length - 1]?.serviceId) {
        // If the current serviceId is the same as the previous one, don't do calculation
        return acc;
      } else {
        return [...acc, val]; // Add the service to the accumulated array
      }
    }, []);

    let duration: any = 0;
    let person: any = 0;
    let serviceNumber : any = 0;
    if (val?.typeOfService?.type === "duration") {
      duration = durationService?.reduce((accumulator: number, item1: any) => {
        const itemDuration = parseInt(item1?.noOfServices);
        if (!isNaN(itemDuration)) {
          return accumulator + itemDuration;
        }
        return accumulator;
      }, duration);
    } else if (val?.typeOfService?.type === "bundle") {
      // duration= "",
      person = val?.Services?.reduce((accumulator: number, item1: any) => {
        const itemPerson = parseInt(item1?.noOfPerson);
        if (!isNaN(itemPerson)) {
          return accumulator + itemPerson;
        }
        return accumulator;
      }, duration);
    } else {
     serviceNumber = val?.Services?.reduce((accumulator: number, item1: any) => {

        const itemDuration = parseInt(item1?.noOfServices);
        if (!isNaN(itemDuration)) {
          return accumulator + itemDuration;
        }
        return accumulator;
      }, duration);
    }
    const serviceList = val?.typeOfService?.type === "duration" ? val?.Services?.reduce((acc: any, val: any) => {
      const exists = acc.some((item: any) => item?.serviceId?._id ? item.serviceId?._id === val.serviceId?._id : item.serviceId === val.serviceId);
      if (!exists) {
        return [...acc, val];
      }

      return acc;
    }, []) : val?.Services;


    return {
      id: val?._id,
      serialNumber: index + 1,
      packageName: val?.PackageName,
      amount: val?.finalPrice,
      isActive: val?.isActive,
      businessId: val?.businessId,
      TypeOfservice: val?.typeOfService?.type ? (val?.typeOfService?.type === "noOfServices" ? "No Of Service" : val?.typeOfService?.type == "duration" ? "Duration" : "Bundle") : "-",
      PackageAfterCareDescription: val?.PackageAfterCareDescription,
      PackageDescription: val?.PackageDescription,
      ServiceAvailableFor: val?.ServiceAvailableFor,
      TermsAndConditions: val?.TermsAndConditions,
      RegularPrice: val?.typeOfService?.regularPrice,
      memberPrice: val?.typeOfService?.memberPrice,
      newServiceData: val?.Services,
      memberAmount: val?.typeOfService?.memberPrice,
      service: val?.Services?.length === 0 ? "-" : serviceList?.map((item: any) => {
        if (item?.priceId?.serviceId === null) {
          return '-'
        }

        return `${item?.priceId?.serviceId?.serviceName}`;
      }),
      duration: duration ? duration : "-",
      person: person ? person : "-",
      serviceNumber : serviceNumber ? serviceNumber : "-",
      action: "",
    };
  });

  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 280,
      renderCell: (params: any) => (
        <StatusButton params={params} getPackages={props.getPackages} packageData={props?.packageData} />
      ),
    },
    {
      field: "packageName",
      headerName: "Package Name",
      flex: 1,
      minWidth: 200,
    },
    { field: "service", headerName: "Service", flex: 1, minWidth: 150 },
    {
      field: "TypeOfservice",
      headerName: "Type Of Service",
      flex: 1,
      minWidth: 120,
    },
    { field: "duration", headerName: "Duration", flex: 1, minWidth: 150 },
    { field: "serviceNumber", headerName: "No of Services", flex: 1, minWidth: 150 },
    { field: "person", headerName: "No of Persons", flex: 1, minWidth: 150 },
    { field: "amount", headerName: "Package Regular Price", flex: 1, minWidth: 200 },
    // { field: "memberAmount", headerName: "Package Member Price", flex: 1, minWidth: 200 },
    
  ];

  return (
    <div className={`${tb.dataTable}`}>
      <div style={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={row}
          autoHeight
          hideFooterPagination={false}
          rowHeight={52}
          checkboxSelection
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}
