import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { AiFillEye, AiFillEyeInvisible, AiOutlineCheck } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import {
  business,
  site,
  facebook,
  google,
  iconEmail,
  iconPassword,
  iconPhone,
  iconUser,
  loginBg,
  welllogo,
  otpMobile,
  logo,
  maleFemaleBoth, femaleOnly, maleOnly,
  logoutIcon,
  tick3,
  welllogo2
} from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import { countries } from "../../../constants/Constants";
import OTPInput from "react-otp-input";
import { IoArrowBackOutline } from "react-icons/io5";
import TermsCondition from "../../../components/Business/Modals/TermsCondition";
import { getHelpDocument } from "../../../redux_toolkit/reducer/HelpAndDocumentsReducer";
import { useDispatch } from "react-redux";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";
import EmailExist from "../../../components/Business/Modals/EmailExist";

const Signup = () => {
  const [signUpState, setSignUpState] = useState<number>(1);
  const [show, setShow] = useState(true);
  const [conPwd, setConpwd] = useState(true);
  const [mobileNo, setMobileno] = useState("");
  const [countryData, setCountryData] = useState<any>(null);
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState('');
  const [userData, setUserData] = useState<any>(null);
  const [businessData, setBusinessData] = useState<any>(null);
  const [selectedCatIds, setSelectedCatIds] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [device, setDevice] = useState("");
  const [ipData, setIpData] = useState<any>(null);
  const [showErrorModal, setShowErrorModal] = useState("");
  const [otpCode, setOtpCode] = useState('');
  const [pwdError, setPwdError] = useState("");
  const [serviceProvides, setServiceProvides] = useState<string>('')
  const [showEmailExist, setEmailExistShow] = useState<boolean>(false);
  const handleEmailExistClose = () => setEmailExistShow(false);
  const [errorTrig, setErrorTrig] = useState<boolean>(false);
  const [updatedData, setUpdatedData] = useState<any>(null)

  const [email, setEmail] = useState("");
  const handleAlertModalClose = () => setShowErrorModal("");
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
  const dispatch = useDispatch();

  const [showTermsCondition, setTermsConditionShow] = useState(false);
  const handleTermsConditionClose = () => setTermsConditionShow(false);
  const handleTermsConditionShow = () => setTermsConditionShow(true);

  const ref5: any = useRef(null);
  const navigate = useNavigate();
  const previousStep = localStorage.getItem('stepCompleted')
  const previousBusinessData = localStorage.getItem('business_data')
  const user_data = localStorage.getItem('user_data');
  const sendOtp = async (user: any) => {
    let data = {
      mobile: user?.mobile,
    };
    const res = await httpRequest(`resendOTP/${user?._id}`, "post", data, "withoutToken");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategories();
    getIp();

    const user = user_data ? JSON.parse(user_data) : null
    const business = previousBusinessData ? JSON.parse(previousBusinessData) : null
    const step = previousStep ? +previousStep : ''
    if (previousStep) {
      setSignUpState(+previousStep + 1)
    }
    if (user && step == 2 && !user?.mobile_OTP_verify) {
      sendOtp(user)
    }

    if (user) {
      setUpdatedData(user)
      setUserData(user)
      setEmail(user?.email);
    }
    if (business) {
      setBusinessData(business)
      setSelectedCatIds(business?.businessCatagoryId || [])
      setServiceProvides(business?.serviceProvides || '')
    }
  }, []);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleOnChange = (value: any) => {
    const formattedPhoneNumber = formatPhoneNumberIntl(value);
    const countryCode = formattedPhoneNumber.split(' ')[0];

    const data: any = value ? parsePhoneNumber(value) : "";

    const country = value ? value.split(' ')[1] : '';
    data && setCountryData(data);
    // setCountryData(countryCode);
    setMobileno(value);
    if (phoneError !== '') {
      setPhoneError('');
    }
  };


  const getIp = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      setIpData(data);
    } catch (e: any) {
      console.log(e.message);

    }
  };

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile =
        /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
      const isTablet =
        /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

      if (isMobile) {
        setDevice("Mobile");
      } else if (isTablet) {
        setDevice("Tablet");
      } else {
        setDevice("Desktop");
      }
    };


    handleDeviceDetection();
    window.addEventListener("resize", handleDeviceDetection);

    return () => {
      window.removeEventListener("resize", handleDeviceDetection);
    };
  }, []);

  useEffect(() => {
    dispatch(getHelpDocument());
  }, [])


  const userRegister = async (postData: any) => {
    let data = {
      email: postData.email,
      stepCompleted: 1,
    };
    setEmail(postData.email);
    const res = await httpRequest("checkmail", "post", data, "withoutToken");
    if (res?.status) {
      setSignUpState(2);
    } else {
      setEmailExistShow(true)
    }
    // if (signUpState === 1) {
    // setUserDataFunc(resdata, 2);
    // handleAlertModalShow(
    //   "Success, A link is sent to your email. Please verify your email"
    // );
    // }
    // else if (resdata?.stepCompleted === 2) {
    //   setUserDataFunc(resdata, 3);
    // } else if (resdata?.stepCompleted === 3) {
    //   setUserDataFunc(resdata, 4);
    // } else if (resdata?.stepCompleted === 4) {
    //   getUserBusinessData(resdata._id, false);
    //   setUserDataFunc(resdata, 5);
    // }

  };

  function setUserDataFunc(data: any, step: any) {
    setUserData(data);
    setSignUpState(step);
  }

  const getUserBusinessData = async (uid: any, isSignupCompleted: boolean) => {
    let res = await httpRequest(`getprofile?id=${uid}`, "get", null, "withoutlogin");
    if (res.status) {
      setBusinessData(res.data[0]);
      let token = localStorage.getItem("bl_token");
      if (isSignupCompleted && token) {
        localStorage.setItem("user_data", JSON.stringify(res.data[0]));
        localStorage.setItem('user_token', token);
        localStorage.removeItem('bl_token');
        navigate(`/business/business-setting/basic/info`, { replace: true });
      }
    }
  };

  const createUser = async (postData: any) => {
    setError("");
    let mobVal = '';
    if (countryData) {
      mobVal = mobileNo?.substring(countryData?.length);
    }
    if (postData.password !== postData.confirmPassword) {
      setPwdError("password does not match");
      return;
    }
    if (mobVal.length === 0) {
      setPhoneError('Enter a phone number');
      return;
    } else if (mobVal.length < 8) {
      setPhoneError('Invalid phone number');
    }
    let deviceLocation = `${ipData?.city},${ipData?.region},${ipData?.country_name},${ipData?.postal}`;
    // let mobVal = removeStringOccurrences(mobileNo, countryData.dialCode);
    const country = countries?.find((country: any) => country?.code === countryData?.country);

    let dataObj: any = {
      firstName: postData.firstName,
      lastName: postData.lastName,
      mobile: mobVal,
      email: email,
      countryCode: countryData?.country,
      dialCode: `+${countryData?.countryCallingCode}`,
      country: country?.name,
      stepCompleted: 2,
      userIp: ipData?.ip,
      deviceId: '',
      sessionId: '',
      location: deviceLocation,
      deviceType: device,
    };
    if (!userData?._id) {
      dataObj = { ...dataObj, password: postData.password, }
    }
    // const res = await httpRequest(`updateprofile/${userData?._id}`, "PATCH", dataObj, "withoutToken");
    const path = userData ? `updateUser/${userData?._id}` : 'signup'
    const res = await httpRequest(path, "POST", dataObj, "withoutToken");
    if (res.status) {
      setError("");
      if (userData) {
        setUserData(res.updatedBusinessUser);
        localStorage.setItem("user_data", JSON.stringify(res?.updatedBusinessUser));
        localStorage.setItem('stepCompleted', JSON.stringify(res?.updatedBusinessUser?.stepCompleted));
      } else {
        setUserData(res.data);
        localStorage.setItem("user_data", JSON.stringify(res?.data));
        localStorage.setItem('stepCompleted', JSON.stringify(res?.data?.stepCompleted));
      }
      setSignUpState(3);
      localStorage.setItem('user_token', res?.token);
      dispatch(getHelpDocument());
      localStorage.setItem("bl_token", res?.token);
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
  };

  const otpVerify = async () => {
    if (otpCode.length < 4) {
      handleAlertModalShow(`Error, please enter valid otp`);
      return;
    }
    let data = {
      enteredOTP: parseInt(otpCode),
      stepCompleted: 3,
    };
    const res = await httpRequest(`verifyotp/${userData?._id}`, "POST", data, "withoutToken");
    if (res.status) {
      localStorage.setItem('stepCompleted', JSON.stringify(3));
      setSignUpState(4);
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
  };

  const resendOtp = async () => {
    let data = {
      mobile: userData.mobile,
    };
    const res = await httpRequest(`resendOTP/${userData?._id}`, "post", data, "withoutToken");
    if (res.status) {
      handleAlertModalShow(`Success, ${res.message}`);
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
  };

  const businessSignup = async (postData: any) => {
    setError("");
    let data = {
      businessName: postData.Business,
      userId: userData?._id,
      country: userData?.country,
      countryCode: userData?.countryCode,
      dialCode: userData?.dialCode,
      stepCompleted: 4,
    };
    const formData = new FormData();
    formData.append('businessName', postData?.Business)
    formData.append('userId', userData?._id)
    formData.append('country', userData?.country)
    formData.append('countryCode', userData?.countryCode)
    formData.append('dialCode', userData?.dialCode)
    formData.append('stepCompleted', JSON.stringify(4))

    let res: any = null
    if (businessData) {
      res = await httpRequest(`updateBusiness/${businessData?._id}`, 'PATCH', formData, "formdata");
    } else {
      res = await httpRequest('createBusiness', 'POST', data, "withoutToken");
    }
    if (res.status) {
      localStorage.setItem('stepCompleted', JSON.stringify(res?.data?.stepCompleted));
      localStorage.setItem("business_data", JSON.stringify(res?.data));
      setBusinessData(res.data);
      setSignUpState(res?.data?.stepCompleted + 1);
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
  };

  const updateBusinessCategory = async () => {
    if (selectedCatIds.length < 1) {
      handleAlertModalShow(`Error, Please select atleast one category`);
      return;
    }
    let body = {
      businessCatagoryId: selectedCatIds,
      userId: userData?._id,
      stepCompleted: 5,
    }
    let res = await httpRequest(`updateBusinessCategory/${businessData?._id}`, "PATCH", body, "withoutToken");

    if (res.status) {
      localStorage.setItem('stepCompleted', JSON.stringify(res?.updatedBusiness?.stepCompleted));
      localStorage.setItem("business_data", JSON.stringify(res?.updatedBusiness));
      setSignUpState(6)
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
  };
  const updateBusiness = async (e: any) => {
    e.preventDefault()
    if (serviceProvides === '') {
      setErrorTrig(true)
      handleAlertModalShow(`Error, Please select gender`);
      return;
    }
    const formdata = new FormData();
    formdata.append('serviceProvides', serviceProvides);
    formdata.append('stepCompleted', JSON.stringify(6));
    let res = await httpRequest(`updateBusiness/${businessData?._id}`, "PATCH", formdata, "formdata");

    if (res.status) {
      localStorage.setItem("business_data", JSON.stringify(res?.data));
      localStorage.setItem('stepCompleted', JSON.stringify(6));
      navigate("/business/branch/add-branch/info", { replace: false });
      setErrorTrig(false)
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
  };

  const getCategories = async () => {
    let res = await httpRequest("getCatagory", "get", null, "withoutToken");
    if (res.status) {
      setCategoryList(res.data);
    }
  };

  function handleCatSelect(id: string) {
    let newSelected: any = [...selectedCatIds];
    const selectedIndex = newSelected.indexOf(id);
    if (selectedIndex >= 0) {
      newSelected.splice(selectedIndex, 1);
    } else {
      newSelected.push(id);
    }
    setSelectedCatIds(newSelected);
    setError("");
  }

  let handleChangeMob = (step: any) => {
    setSignUpState(step)
  }

  const handleLogout = () => {
    localStorage.setItem('user_token', '')
    localStorage.setItem('user_data', '');
    localStorage.setItem('business_data', '');
    localStorage.setItem('stepCompleted', '');
    navigate('/business/login')
  }

  const handleGender = (gender: string) => {
    setServiceProvides(gender)
  }

  return (
    <>
      <section className={`${cx.loginSection}`}>
        {/* <div className={`${cx.emailName_main}`}>
          <div className={`${cx.name_box}`}>
            <div className={`${cx.mail_name}`}>
              <div className={`${cx.leftSide}`}>B</div>
              <div className={`${cx.rightSide}`}>
                <div className={`${cx.mailPerson}`}>bizsupport123@gmail.com</div>
              </div>
            </div>
          </div>
        </div> */}
        {signUpState > 1 && <div className={`${cx.emailName_main}`}>
          <div className={`${cx.name_box}`}>
            <div className={`${cx.mail_name}`}>
              <div className={`${cx.leftSide}`}>{businessData?.businessName ? businessData?.businessName?.[0]?.toLocaleUpperCase() : email?.[0]?.toLocaleUpperCase()}</div>
              <div className={`${cx.rightSide}`}>
                {businessData?.businessName && <div className={`${cx.namePerson}`}>{businessData?.businessName}</div>}
                <div className={`${cx.mailPerson}`}>{email}</div>
              </div>
            </div>
            {signUpState > 2 && <div className={`${cx.logout_btn}`} onClick={handleLogout}>
              <button> <img src={logoutIcon} alt="img" /> Log Out</button>
            </div>}
          </div>
        </div>}
        <div className={`${cx.signUpBgClr}`}></div>
        <img src={loginBg} alt="login background" className={`${cx.loginBg}`} />
        <div className={`${cx.modalContent}`}>
          <div className={`${cx.modalCenter}`}>
            {signUpState === 1 ? (
              <>
                <div className={`${cx.createAccount_box}`}>
                  <div>
                    <div className={`${cx.formBody} ${cx.anotherBody}`}>
                      <div
                        className={`${cx.top} ${signUpState === 1 && cx.first}`}
                      ></div>
                      <Row>
                        <Col md={12}>
                          <h2 className={`${cx.title}`}>
                            {/* <span className={`${cx.stepsNo}`}>Step 1/5:</span> */}
                            Create free Wellnezza Business Account! </h2>
                          <p className={cx.desc}>
                            It only takes a few minutes to setup your account with Wellnezza
                          </p>
                        </Col>

                        <form onSubmit={handleSubmit(userRegister)}>
                          <Col md={12}>
                            <div className={`${st.formBox} ${st.iconFormBox}`}>
                              <label className="form-label mb-1">Provide your Email
                                {/* <HoverEyeContent number={9001} /> */}
                              </label>
                              <p className={`${cx.desc2} mt-0 `}>
                                Signup with your email. This email will be used to manage your  business on  Wellnezza
                              </p>
                              <div className="position-relative">
                                {/* <img
                                  src={iconEmail}
                                  className={`${st.icon}`}
                                  alt="email icon"
                                /> */}
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Work email"
                                  {...register("email", {
                                    required: true,
                                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  })}
                                />
                              </div>
                              {errors?.email?.type === "pattern" && (
                                <p className={"errorMessage"}>Invalid email address</p>
                              )}
                              {errors?.email?.type === "required" && (
                                <p className={"errorMessage"}>This field is required</p>
                              )}
                            </div>
                          </Col>

                          <Col md={12}>
                            <div className={`${st.formBox} ${cx.formSign}`}>
                              <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-2`}>
                                <button
                                  className={`btn ${st.btn3} ${cx.btnGray}`} type="submit"
                                >
                                  Sign Up
                                </button>
                              </div>
                            </div>
                          </Col>
                        </form>

                        <Col md={12}>
                          <NavLink
                            className={`${cx.alreadyBox}`}
                            to="/business/login"
                          >
                            <span>Already have an account?</span> Sign in
                          </NavLink>
                        </Col>
                      </Row>
                    </div>
                    <div className={`${cx.signUpPolicy_box}`}>
                      <span>By signing in, you agree to our <NavLink to={"#"}>Terms of service</NavLink> & <NavLink to={"#"}>Privacy Policy</NavLink></span>
                    </div>
                  </div>
                  <div className={`${cx.create_rightSide}`}>
                    <div className={`${cx.imgSide}`}>
                      <img src={welllogo2} alt="logo" />
                    </div>
                    <div className={`${cx.contentBox}`}>
                      <p>Lorem Ipsum is simply dummy text of the printing.</p>
                      <ul>
                        <li> <img src={tick3} alt="img" /> Lorem Ipsum is simply dummy text of the printing .</li>
                        <li><img src={tick3} alt="img" /> Lorem Ipsum is simply dummy text of the printing .</li>
                        <li><img src={tick3} alt="img" /> Lorem Ipsum is simply dummy text of the printing .</li>
                        <li><img src={tick3} alt="img" /> Lorem Ipsum is simply dummy text of the printing .</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </>
            ) : signUpState === 2 ? (
              <>
                <div className={`${cx.logoApply}`}>
                  <img src={welllogo2} alt="logo" />
                </div>
                <div className={`${cx.formBody}`}>
                  <div
                    className={`${cx.top} ${signUpState === 2 && cx.second
                      }`}
                  ></div>

                  {/* <button className={ }></button> */}
                  <form onSubmit={handleSubmit(createUser)}>
                    <Row>
                      {/* <div className={`${cx.backSignUp_box}`}>
                      <NavLink className={`${cx.backBtn}`} to={"#"}>
                        <IoArrowBackOutline onClick={() => handleChangeMob(1)} />
                      </NavLink>
                    </div> */}
                      <Col md={12}>
                        <h2 className={`${cx.title}`}>
                          {/* <span className={`${cx.stepsNo}`}>Step 2/5:</span> */}
                          Lets Start with basics </h2>
                        <p className={cx.desc}>
                          We recommend signing up with the business owner's name and the business's contact .
                          Rest assured, the details provided below will remain private and won't be visible to the public
                        </p>
                      </Col>
                      <Col md={12}>
                        <div className={`${st.formBox} ${st.iconFormBox}`}>
                          <label className="form-label">First Name</label>
                          <div className="position-relative">
                            {/* <img
                              src={iconUser}
                              className={`${st.icon}`}
                              alt="user icon"
                            /> */}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              {...register("firstName", {
                                required: true,
                                pattern: /^[a-zA-Z ]+$/,
                                min: 1,
                              })}
                            />
                          </div>
                          {errors?.firstName?.type === "required" && (
                            <p className={"errorMessage"}>
                              This field is required
                            </p>
                          )}
                          {errors?.firstName?.type === "pattern" && (
                            <p className={"errorMessage"}> Alphabets only</p>
                          )}
                          {errors?.firstName?.type === "min" && (
                            <p className={"errorMessage"}>
                              {" "}
                              minimum one character is necessary .
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className={`${st.formBox} ${st.iconFormBox}`}>
                          <label className="form-label">Last Name </label>
                          <div className="position-relative">
                            {/* <img
                              src={iconUser}
                              className={`${st.icon}`}
                              alt="user icon"
                            /> */}
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={''}
                              placeholder="Last Name"
                              id="lastName"
                              {...register("lastName", {
                                required: true,
                                pattern: /^[a-zA-Z ]+$/,
                                min: 1,
                              })}
                            />
                          </div>
                          {errors?.lastName?.type === "required" && (
                            <p className={"errorMessage"}>
                              This field is required
                            </p>
                          )}

                          {errors?.lastName?.type === "pattern" && (
                            <p className={"errorMessage"}> Alphabets only</p>
                          )}
                          {errors?.lastName?.type === "min" && (
                            <p className={"errorMessage"}>
                              {" "}
                              minimum one character is necessary .
                            </p>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className={`${st.formBox} ${st.iconFormBox}`}>
                          <label className="form-label">Password</label>
                          <div className="position-relative">
                            {/* <img
                              src={iconPassword}
                              className={`${st.icon}`}
                              alt="password icon"
                            /> */}
                            <input
                              className="form-control"
                              disabled={userData}
                              placeholder="Your password"
                              type={show ? "password" : "text"}
                              {...register("password", {
                                required: "This password is required",
                                minLength: {
                                  value: 6,
                                  message: "Please enter minimum 6 characters",
                                },
                              })}
                            />
                            {show && !userData?._id ? (
                              <AiFillEye
                                className={`${st.eyeIcon}`}
                                onClick={() => {
                                  setShow(false);
                                }}
                              />
                            ) : (
                              <AiFillEyeInvisible
                                className={`${st.eyeIcon}`}
                                onClick={() => {
                                  setShow(true);
                                }}
                              />
                            )}
                          </div>
                          {errors?.password?.type === "minLength" && (
                            <p className={"errorMessage"}>
                              Please enter minimum 6 characters
                            </p>
                          )}
                          {errors?.password?.type === "required" && (
                            <p className={"errorMessage"}>
                              This field is required
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={`${st.formBox} ${st.iconFormBox}`}>
                          <label className="form-label">Confirm Password</label>
                          <div className="position-relative">
                            {/* <img
                              src={iconPassword}
                              className={`${st.icon}`}
                              alt="password icon"
                            /> */}
                            <input
                              className="form-control"
                              placeholder="Your confirm password"
                              disabled={userData}
                              type={conPwd ? "password" : "text"}
                              {...register("confirmPassword", {
                                required: "This password is required",
                                minLength: {
                                  value: 6,
                                  message: "Please enter minimum 6 characters",
                                },
                              })}
                            />
                            {conPwd && !userData?._id ? (
                              <AiFillEye
                                className={`${st.eyeIcon}`}
                                onClick={() => {
                                  setConpwd(false);
                                }}
                              />
                            ) : (
                              <AiFillEyeInvisible
                                className={`${st.eyeIcon}`}
                                onClick={() => {
                                  setConpwd(true);
                                }}
                              />
                            )}
                          </div>
                          {errors?.confirmPassword?.type === "minLength" && (
                            <p className={"errorMessage"}>
                              Please enter minimum 6 characters
                            </p>
                          )}
                          {errors?.confirmPassword?.type === "required" && (
                            <p className={"errorMessage"}>
                              This field is required
                            </p>
                          )}
                          <p className={"errorMessage"}>
                            {pwdError}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className={`${st.formBox} ${st.iconFormBox}`}>
                          <label className="form-label">Phone number</label>
                          <div className="position-relative">
                            {/* <PhoneInput
                            country={"uk"}
                            onChange={handleOnChange}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: false,
                            }}
                            defaultErrorMessage="It doesn't work, why?"
                          /> */}
                            <div className={`${st.telBox}`}>
                              <PhoneInput
                                defaultCountry="SG"
                                international
                                placeholder="Enter phone number"
                                value={mobileNo}
                                onChange={(e: any) => handleOnChange(e)}
                                error={mobileNo ? (isValidPhoneNumber(mobileNo) ? undefined : 'Invalid phone number') : 'Phone number required'}
                              />
                            </div>
                          </div>
                          {mobileNo && <p className={"errorMessage out"}>{mobileNo && isValidPhoneNumber(mobileNo) ? '' : 'Invalid phone number'}</p>}
                          {phoneError !== '' && (
                            <p className="errorMessage">{phoneError}</p>
                          )}
                        </div>
                      </Col>
                      <Col md={12} className="mt-2 mb-2">
                        <div>
                          <label className={`d-block ${st.checkbox}`}>
                            <input
                              type="checkbox"
                              {...register("terms", {
                                required: true,
                              })}
                            />
                            <span className={`${st.checkmark}`}></span>
                            <p className="ms-2 mb-0">
                              Do you agree to the <span style={{ display: 'inline' }} onClick={handleTermsConditionShow}>terms and conditions  </span>  &  <span style={{ display: 'inline' }} onClick={handleTermsConditionShow}>Privacy Policy </span>
                            </p>
                          </label>
                          {errors?.terms?.type === "required" && <p className="errorMessage mt-2 mb-2">This field is required
                          </p>}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.formSign}`}>
                          <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                            <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit">
                              Next
                            </button>
                            {/* <button onClick={() => handleChangeMob(1)} className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit">
                              Back
                            </button> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </>
            ) : signUpState === 3 ? (
              <>
                <div className={`${cx.logoApply}`}>
                  <img src={welllogo2} alt="logo" />
                </div>
                <div className={`${cx.formBody} `}>
                  <div
                    className={`${cx.top} ${signUpState === 3 && cx.third}`}
                  ></div>
                  <Row>
                    {/* <div className={`${cx.backSignUp_box}`}>
                    <NavLink className={`${cx.backBtn}`} to={"#"}>
                      <IoArrowBackOutline onClick={() => handleChangeMob(2)} />
                    </NavLink>
                  </div> */}
                    <Col md={12}>
                      <div>
                        <div className={`text-center`}>
                          <img className="mb-3 pb-1" src={otpMobile} alt="otp" />
                          <h2 className={`${cx.title} text-center`}>
                            {/* <span className={`${cx.stepsNo}`}>Step 3/5:</span>  */}
                            Mobile OTP Verification </h2>
                        </div>

                        <p className={`${cx.desc} text-center mb-2`}>
                          Enter the One-Time Verification Code
                        </p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <h5 className={`${cx.numberTitle}`}>
                        An OTP has been sent to the register number:{" "}
                        <span> {userData?.mobile}</span> <figure style={{ marginBottom: '4px' }}>&nbsp;<HoverEyeContent number={9006} /></figure>
                      </h5>
                    </Col>
                    <Col md={12} sm={12} xs={12}>
                      <div className={`${st.formBox} ${cx.otpScreen} mt-4`}>
                        <OTPInput
                          value={otpCode}
                          onChange={setOtpCode}
                          numInputs={4}
                          renderSeparator={<span> </span>}
                          inputType="number"
                          renderInput={(props: any) => <input {...props} />}
                        />

                        {/* <input
                            type="number"
                            className="form-control inputDiv"
                            ref={ref1}
                            value={otp[0]}
                            onChange={(e: any) => {
                              Checkotp(e.target.value, 0);
                            }}
                          /> */}
                      </div>
                    </Col>
                    <Col md={12}>
                      <p className={`${cx.resend} justify-content-center`}>
                        Didn't come the OTP?{" "}
                        <span onClick={resendOtp}>Resend</span>
                      </p>
                    </Col>

                    <Col md={12} sm={12} xs={12} className="mx-auto">
                      <div className={`${st.formBox} ${cx.formSign}`}>
                        <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-2`}>
                          <button
                            className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`}
                            onClick={() => {
                              setValue('firstName', userData?.firstName)
                              setValue('lastName', userData?.lastName)
                              setValue('terms', userData?.userData !== null)
                              setValue('password', '123456')
                              setValue('confirmPassword', '123456')
                              const formattedPhoneNumber = formatPhoneNumberIntl(userData?.mobile);
                              const countryCode = formattedPhoneNumber.split(' ')[0];
                              const data: any = userData?.mobile ? parsePhoneNumber(userData?.mobile) : "";

                              const country = userData?.mobile ? userData?.mobile.split(' ')[1] : '';
                              data && setCountryData(data);
                              setMobileno(userData?.mobile);
                              handleChangeMob(2)
                            }}
                          >
                            Back
                          </button>
                          <button
                            className={`btn ${st.btn3} ${cx.btnGray}`}
                            disabled={error !== ""}
                            ref={ref5}
                            onClick={otpVerify}
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>

            ) : signUpState === 4 ? (
              <>
                {/* <div className={`${cx.emailName_main}`}>
                  <div className={`${cx.name_box}`}>
                    <div className={`${cx.mail_name}`}>
                      <div className={`${cx.leftSide}`}>B</div>
                      <div className={`${cx.rightSide}`}>
                        <div className={`${cx.namePerson}`}>Biz Support</div>
                        <div className={`${cx.mailPerson}`}>bizsupport123@gmail.com</div>
                      </div>
                    </div>
                    <div className={`${cx.logout_btn}`}>
                      <button> <img src={logoutIcon} alt="img" /> Log Out</button>
                    </div>
                  </div>
                </div> */}
                <div className={`${cx.logoApply}`}>
                  <img src={welllogo2} alt="logo" />
                </div>
                <div className={`${cx.formBody}`}>
                  <div
                    className={`${cx.top} ${signUpState === 4 && cx.fourth
                      }`}
                  ></div>
                  <form>
                    <Row>
                      {/* <div className={`${cx.backSignUp_box}`}>
                        <NavLink className={`${cx.backBtn}`} to={"#"}>
                          <IoArrowBackOutline onClick={() => setSignUpState(3)} />
                        </NavLink>
                      </div> */}
                      <Col md={12}>
                        <h2 className={`${cx.title} text-center mb-2`}>
                          {/* <span className={`${cx.stepsNo}`}>Step 4/5:</span> */}
                          Provide Your Business Name  </h2>
                        <p className={`${cx.desc} text-center`}>This is  business name visible to customer , name of the shop or brand name. Legal name
                          and billing name of your business can be added later.
                        </p>

                      </Col>
                      <Col md={12}>
                        <div className={`${st.formBox} ${st.iconFormBox}`}>
                          <label className="form-label">Business Name <HoverEyeContent number={9007} /></label>
                          <div className="position-relative">
                            {/* <img
                              src={business}
                              className={`${st.icon}`}
                              alt="user icon"
                            /> */}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Business Name"
                              {...register("Business", {
                                required: true,
                                // min: 1,
                              })}
                            />
                          </div>
                          {errors?.Business?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                          )}
                          {errors?.Business?.type === "min" && (
                            <p className={"errorMessage"}>
                              {" "}
                              minimum one character is necessary .
                            </p>
                          )}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.formSign} `} >
                          <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-2`} onClick={handleSubmit(businessSignup)}>
                            <NavLink
                              to="#" type="submit"
                              className={`btn ${st.btn3} ${cx.btnGray}`}

                            >
                              Next
                            </NavLink>
                            {/* <NavLink
                              to="#" type="submit"
                              className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`}
                              onClick={() => setSignUpState(3)}
                            >
                              Back
                            </NavLink> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </>
            ) : signUpState === 5 ? (
              <>
                {/* <div className={`${cx.emailName_main}`}>
                  <div className={`${cx.name_box}`}>
                    <div className={`${cx.mail_name}`}>
                      <div className={`${cx.leftSide}`}>B</div>
                      <div className={`${cx.rightSide}`}>
                        <div className={`${cx.namePerson}`}>Biz Support</div>
                        <div className={`${cx.mailPerson}`}>bizsupport123@gmail.com</div>
                      </div>
                    </div>
                    <div className={`${cx.logout_btn}`}>
                      <button> <img src={logoutIcon} alt="img" /> Log Out</button>
                    </div>
                  </div>
                </div> */}
                <div className={`${cx.logoApply}`}>
                  <img src={welllogo2} alt="logo" />
                </div>
                <div className={`${cx.formBody} ${cx.emailBody}`}>
                  <div
                    className={`${cx.top} ${cx.fifth}`} ></div>
                  <Row>
                    {/* <div className={`${cx.backSignUp_box}`}>
                      <NavLink className={`${cx.backBtn}`} to={"#"}>
                        <IoArrowBackOutline onClick={() => setSignUpState(4)} />
                      </NavLink>
                    </div> */}
                    <Col md={12}>
                      <h2 className={`${cx.title} text-center`}>
                        {/* <span className={`${cx.stepsNo}`}>Step 5/5:</span> */}
                        Choose Services You Provide
                      </h2>
                      <p className={`${cx.desc} text-center`}>
                        Select Relevant Services. This will help your customer to find your business on Wellnezza.com.
                        You can add/Remove service category later as well.
                      </p>
                    </Col>
                    <Col lg={10} className="m-auto">
                      <Row>
                        {categoryList?.map((item: any) => {
                          return (
                            <Col lg={4} md={6} className="mb-3 pb-1" key={item._id}>
                              <div
                                className={`${cx.serviceCategory} ${selectedCatIds.includes(item._id) ? cx.active : ""
                                  }`}
                                onClick={() => handleCatSelect(item._id)}
                              >
                                {item.Icon ? <img
                                  src={item.Icon}
                                  alt="catagory_images"
                                // className={`${cx.select}`}
                                /> : (
                                  <div className={cx.iconBox}>
                                    <span className={cx.fakeLogo}>{item.BusinessCategoryName?.slice(0, 2)}</span>
                                  </div>
                                )}
                                <span className={`${cx.select}`}>
                                  {item.BusinessCategoryName}
                                </span>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col md={12}>
                      <div className={`${st.formBox} ${cx.formSign}`}>
                        <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-2`}>
                          <NavLink
                            to="#"
                            className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`}
                            onClick={() => {
                              setSignUpState(4);
                              setValue('Business', businessData?.businessName)
                            }}
                          >
                            Back
                          </NavLink>
                          <NavLink
                            to="#"
                            className={`btn ${st.btn3} ${cx.btnGray}`}
                            onClick={updateBusinessCategory}
                          >
                            Next
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                  </Row>

                </div>
              </>
            ) : (
              <>
                {/* <div className={`${cx.emailName_main}`}>
                  <div className={`${cx.name_box}`}>
                    <div className={`${cx.mail_name}`}>
                      <div className={`${cx.leftSide}`}>B</div>
                      <div className={`${cx.rightSide}`}>
                        <div className={`${cx.namePerson}`}>Biz Support</div>
                        <div className={`${cx.mailPerson}`}>bizsupport123@gmail.com</div>
                      </div>
                    </div>
                    <div className={`${cx.logout_btn}`}>
                      <button> <img src={logoutIcon} alt="img" /> Log Out</button>
                    </div>
                  </div>
                </div> */}
                <div className={`${cx.logoApply}`}>
                  <img src={welllogo2} alt="logo" />
                </div>
                <div className={`${cx.formBody} ${cx.maleFemale_width} `}>
                  <div
                    className={`${cx.top} ${signUpState === 6 && cx.sixth
                      }`}
                  ></div>
                  <Row>
                    {/* <div className={`${cx.backSignUp_box}`}>
                      <NavLink className={`${cx.backBtn}`} to={"#"}>
                        <IoArrowBackOutline onClick={() => setSignUpState(5)} />
                      </NavLink>
                    </div> */}
                    <Col md={12}>
                      <h2 className={`${cx.title} text-center mb-3`}>
                        {/* <span className={`${cx.stepsNo}`}>Step 4/5:</span> */}
                        Your Customer Type </h2>
                    </Col>
                    <Col md={12}>
                      <div className={`${st.formBox} ${st.iconFormBox}`}>
                        <label className="form-label mb-0">Client Gender
                          {/* <HoverEyeContent number={9007} /> */}
                        </label>
                        <p className={`${cx.desc} mb-3 mt-0`}> Indicate the gender of the clients you serve. This setting can be changed
                          later. </p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Row className="mb-2 align-items-center g-2">
                        <Col xs={4}>
                          <div
                            className={`${cx.serviceCategory} ${serviceProvides === 'female' ? cx.active : ''}`} onClick={() => handleGender('female')}>
                            <img
                              src={femaleOnly}
                              alt="catagory_images"
                            />
                            <span className={`${cx.select}`}>
                              Female Only
                            </span>
                          </div>
                          {/* <div className={`${cx.customer_typeSelect}`}>
                            <div className="form-control">Female Only</div>
                          </div> */}
                        </Col>
                        <Col xs={4}>
                          <div
                            className={`${cx.serviceCategory} ${serviceProvides === 'male' ? cx.active : ''}`} onClick={() => handleGender('male')}>
                            <img
                              src={maleOnly}
                              alt="catagory_images"
                            // className={`${cx.select}`}
                            />
                            <span className={`${cx.select}`}>
                              Male Only
                            </span>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div
                            className={`${cx.serviceCategory} ${serviceProvides === 'unisex' ? cx.active : ''}`} onClick={() => handleGender('unisex')}>
                            <img
                              src={maleFemaleBoth}
                              alt="catagory_images"
                            // className={`${cx.select}`}
                            />
                            <span className={`${cx.select}`}>
                              Every one is Welcome
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          {errorTrig && serviceProvides === '' && (
                            <p className={"errorMessage"}>
                              {" "}
                              minimum one character is necessary .
                            </p>
                          )}
                        </Col>
                      </Row>
                      {/* <Row className="mb-2 align-items-center">
                        <Col xs={8}>
                          <div className={`${cx.customer_typeSelect}`}>
                            <div className="form-control">Male Only</div>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className={`${st.formBox}`}>
                            <ul className={`${st.listUl}`}>
                              <li>
                                <label className={`${st.radio}`}>
                                  <input
                                    type="radio"
                                    {...register("serviceProviders")}
                                  />
                                  <span className={`${st.checkmark}`}></span> &nbsp;
                                </label>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row> */}
                    </Col>

                    <Col md={12}>
                      <div className={`${st.formBox} ${cx.formSign}`}>
                        <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-4 gap-3`}>
                          <NavLink
                            to="javascript:void(0)"
                            className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`}
                            onClick={() => setSignUpState(5)}
                          >
                            Back
                          </NavLink>
                          <NavLink
                            to="javascript:void(0)" type="submit"
                            className={`btn ${st.btn3} ${cx.btnGray}`}
                            onClick={updateBusiness}
                          >
                            Next
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </div>
        <ErrorPopup
          showErrorModal={showErrorModal}
          handleErrorModalClose={handleAlertModalClose}
        />
        <EmailExist show={showEmailExist} handleEmailExistClose={handleEmailExistClose} />
        <TermsCondition show={showTermsCondition} handleTermsConditionClose={handleTermsConditionClose} />
      </section>
    </>
  );
};

export default Signup;


