import { AiFillCamera } from "react-icons/ai";
import { deleteIcon, editButton, upArrow } from "../../../assets/images";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Offcanvas, Row, Tab } from "react-bootstrap";
import AddWorkingTeams from "../Modals/AddWorkingTeams";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AddTeamRouts from "../../../pages/Business/Teams/AddTeamRouts";
import { httpRequest } from "../../../Apis/commonApis";
import { getDynamicURIName } from "../../../Utils/Funcs";
import { useSelector } from "react-redux";
import TeamRoutes from "./TeamComponent/TeamRoutes";
import { RxCross2 } from "react-icons/rx";
import MultiSelect2 from "../MultiSelect/MultiSelect2";
import { FiPlus } from "react-icons/fi";
import HoverEyeContent from "../HoverEyeContent/HoverEyeContent";
import { AddTeamTitle } from "../Modals";

export default function TeamInsideBody({ cx, handleAddNewTeamClose, getTeamMember }: any) {
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [showTitle, setShowTitle] = useState(false);
    const handleTitleClose = () => {
        setShowTitle(false);
        getTitle();
    };
    const handleTitleShow = () => setShowTitle(true);
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [topLevelTeamData, setTopLevelTeamData] = useState<any>({ firstName: '', lastName: "", nickName: '', teamTitle: [], isPublic: false })
    const [profile, setProfile] = useState<any>("");
    const navigate = useNavigate();
    const Location = useLocation();
    const [selectedTeamTitles, setSelectedTeamTitles] = useState<any>([]);
    const dynamicName = getDynamicURIName(Location.pathname);
    const [errorTrig, setErrorTrig] = useState<boolean>(false)
    const [loading, setLoading] = useState(false);
    const [editTeamData, setEditTeamData] = useState<any>(null);
    const [removeImage, setRemoveImage] = useState<any>(null)
    const [apiError, setApiError] = useState<any>('')
    const [teamTitleList, setTeamTitleList] = useState([]);
    const [activeTab, setActiveTab] = useState(dynamicName);

    const [showErrorModal, setShowErrorModal] = useState('');
    const handleAlertModalClose = () => setShowErrorModal('');
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [businessData])

    const handleStepsFormData = (formdata: any, step: any) => {
        if (formdata === null && step === 'cancel') {
            handleAddNewTeamClose()
            return;
        }
        if (formdata === 'prevStep') {
            setActiveTab(step);
            navigate(step, { state: { teamId: Location.state.teamId }, replace: true });
            return;
        }
        if (step === 'step1') {
            if (Location.state) {
                updateTeamMember(formdata, 'branch');
            } else {
                createNewTeamMember(formdata);
            }
        } else if (step === 'step2') {
            updateTeamMember(formdata, 'leaves');
        } else if (step === 'step3') {
            updateTeamMember(formdata, 'leaves');
        } else if (step === 'step4') {
            // if (formdata.length > 0) {
            createTeamMemberLeaves(formdata, step);
        } else if (step === "step5") {
            updateTeamMember(formdata, 'complete');
        }
    };
    const role = editTeamData ? editTeamData?.Role?.map((role: any) => ({ ...role, branch: role?.branch?._id, role: selectedTeamTitles?.map((item: any) => item?.value) }))
        :
        [{
            accessManager: [],
            accessManagerId: [],
            branch: null,
            branchName: '',
            role: selectedTeamTitles?.map((item: any) => item?.value)
        }]
    const createNewTeamMember = async (allData: any) => {
        if (!topLevelTeamData?.firstName) {
            setErrorTrig(true)
            return
        }
        if (selectedTeamTitles?.length === 0) {
            setErrorTrig(true)
            return
        }
        const formData = new FormData();
        for (const key in allData) {
            if (allData.hasOwnProperty(key)) {
                formData.append(key, allData[key]);
            }
        }
        formData.append('Role', JSON.stringify(role))
        formData.append('image', profile || '')
        formData.append('removeImage', removeImage)
        formData.append('firstName', topLevelTeamData?.firstName)
        formData.append('lastName', topLevelTeamData?.lastName)
        formData.append('nickName', topLevelTeamData?.nickName)
        formData.append('isPublic', JSON.stringify(topLevelTeamData?.isPublic))
        // formData.append('isPublish', JSON.stringify(true))
        formData.append("businessId", businessData?._id);
        setLoading(true);
        const res = await httpRequest("addTeamMember", "post", formData, "formdata");
        if (res.status) {
            setApiError("")
            setActiveTab('branch');
            navigate('branch', { state: { teamId: res.data._id }, replace: true });
        } else {
            setApiError(res.message)
            setTimeout(() => {
                setApiError('')
            }, 5000)
            handleAlertModalShow(`Error, ${res.message}`);
        }
        setLoading(false);
    }

    const updateTeamMember = async (allData: any, step: string) => {
        if (!topLevelTeamData?.firstName) {
            setErrorTrig(true)
            return
        }
        const formdata = new FormData();
        for (const key in allData) {
            if (allData.hasOwnProperty(key)) {
                formdata.append(key, allData[key]);
            }
        }
        if (step === 'branch') {
            if (selectedTeamTitles?.length === 0) {
                setErrorTrig(true)
                return
            }
            formdata.append('Role', JSON.stringify(role))
        }
        // if(step === 'leaves'){
        //     formdata.append('isActive', JSON.stringify(allData?.isActive))
        // }
        formdata.append('image', profile || '')
        formdata.append('removeImage', removeImage)
        formdata.append('firstName', topLevelTeamData?.firstName)
        formdata.append('lastName', topLevelTeamData?.lastName)
        formdata.append('nickName', topLevelTeamData?.nickName)
        formdata.append('isPublic', JSON.stringify(topLevelTeamData?.isPublic))
        if (allData.hoursDetail) {
            let hrsDetail = JSON.parse(allData.hoursDetail);
            let selectedBranchId = hrsDetail?.map((item: any) => item.branchId);
            formdata.append("branchId", JSON.stringify(selectedBranchId));
        }


        setLoading(true);
        const res = await httpRequest(`updateTeamMember/${Location.state.teamId}`, 'PATCH', formdata, "formdata");
        if (res.status) {
            if (step === "complete") {
                handleAlertModalShow(`Success, Team member details updated successfully`);
                getTeamMember && getTeamMember()
                handleAlertModalClose();
                navigate(-1);
                handleAddNewTeamClose && handleAddNewTeamClose()
                return

            }
            setActiveTab(step);
            navigate(step, { state: { teamId: res.data._id }, replace: true });
        } else {
            handleAlertModalShow(`Error, ${res.message}`);
        }
        setLoading(false);
    }

    const createTeamMemberLeaves = async (amdata: any, step: any = "") => {
        let selectedBranchId = amdata?.map((item: any) => item.location);
        setLoading(true);
        let body = {
            businessId: businessData._id,
            // countryId: "",
            branchId: selectedBranchId,
            teamMemberId: Location.state?.teamId,
            leaves: amdata,
        }
        const res = await httpRequest("createLeaves", "post", body, "json");

        if (res.status) {
            if (step === "step4") {
                setActiveTab("accesscontrol");
                navigate("accesscontrol", { state: { teamId: Location?.state?.teamId }, replace: true });
            } else {
                handleAlertModalShow(`Success, Team member details updated successfully`);
                setTimeout(() => {
                    handleAlertModalClose();
                    navigate(-1);
                }, 2000);
            }
        } else {
            handleAlertModalShow(`Error, ${res.message}`);
        }
        setLoading(false);
    }

    // basic setting 
    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        setProfile(e.target.files[0]);
        if (file) {
            // Create a URL for the selected image file
            const imageUrl = URL.createObjectURL(file);
            setProfileImage(imageUrl);
        }
    };
    const removeLogo = () => {
        setProfile("");
        setRemoveImage(profileImage)
        setProfileImage("")
        if (profile) {
        }
    }
    const getTitle = async () => {
        const res = await httpRequest(
            `getTeamTitle?businessId=${businessData._id}`, "get", null, "json");
        if (res.status) {
            const options: any = res?.data?.map((item: any) => ({
                value: item._id,
                label: item.teamTitle,
                isActive: item.isActive,
            })).filter((elm: any) => elm.isActive === true);
            setTeamTitleList(options);
        }
    };
    useEffect(() => {
        if (businessData) {
            getTitle();
        };
    }, [businessData]);
    const isBasic = dynamicName === 'info';

    const handleTabSelect = (tab: any) => {
        if (editTeamData) {
            setActiveTab(tab);
            navigate(`${tab}`, { state: { teamId: Location?.state?.teamId ? Location?.state?.teamId : '' }, replace: true });
        }
    };
    return (
        <>
        {showTitle && <AddTeamTitle showTitle={showTitle} handleTitleClose={handleTitleClose}
                getTitle={getTitle} businessData={businessData} />}
            <div className={`${st.teamInsideBody}`}>
                <Offcanvas.Header closeButton>
                    <div className={`${st.offHeader_main}`}>
                        <h3>{Location?.state?.teamId ? `${topLevelTeamData?.firstName} ${topLevelTeamData?.lastName || ''}` : "Add a New Team"}</h3>
                        <p className={`${st.offcanvas_para} mb-0`}>Add team member details</p>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body >
                    <Row className='g-0'>
                        <Col md={4}>
                            <div className={`${cx.logoBox}`}>
                                <div className={cx.box}>
                                    {isBasic && <button className={`${cx.upload_img_remove}`} onClick={removeLogo}>
                                        {profileImage ? (<RxCross2 />) : ""}
                                    </button>}

                                    {profileImage ? (
                                        <img
                                            style={{
                                                height: "130px",
                                                width: "138px",
                                                borderRadius: "6px",
                                                objectFit: "cover",
                                                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 4px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                                                border: "1px solid gainsboro"
                                            }}
                                            src={profileImage || ''}
                                            alt="logo"
                                        />
                                    ) : (
                                        <AiFillCamera className={cx.boxImage} />
                                    )}

                                    {isBasic && <label
                                        className={cx.bottomLogo}
                                        htmlFor="team-image-upload"
                                    >
                                        <input
                                            type="file"
                                            id="team-image-upload"
                                            onChange={handleFileChange}
                                        />
                                        <img src={upArrow} alt="logo" style={{ cursor: "pointer" }} />
                                    </label>}
                                </div>
                                <label className={`${st.checkbox} ${cx.profileCheck} d-inline-flex`}>
                                    <input type="checkbox" checked={topLevelTeamData?.isPublic} disabled={!isBasic} onChange={(e: any) => setTopLevelTeamData({ ...topLevelTeamData, isPublic: e.target.checked })} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className="ms-1" style={{ fontSize: '13px', marginTop: '1px' }}>Image Visible to public
                                        {/* <HoverEyeContent number={9088} /> */}
                                    </span>
                                </label>
                            </div>
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={6}>
                                    <div className={`${st.reFormBox}`}>
                                        <label htmlFor="">First Name</label>
                                        <input type="text" className='form-control' disabled={!isBasic} placeholder='Enter First Name' value={topLevelTeamData?.firstName} onChange={(e: any) => setTopLevelTeamData({ ...topLevelTeamData, firstName: e.target.value })} />
                                        {errorTrig && !topLevelTeamData?.firstName && (
                                            <p className={"errorMessage"}>this field is required</p>
                                        )}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className={`${st.reFormBox}`}>
                                        <label htmlFor="">Last Name</label>
                                        <input type="text" className='form-control' disabled={!isBasic} placeholder='Enter Last Name' value={topLevelTeamData?.lastName} onChange={(e: any) => setTopLevelTeamData({ ...topLevelTeamData, lastName: e.target.value })} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className={`${st.reFormBox}`}>
                                        <label htmlFor="">Nick Name</label>
                                        <input type="text" className='form-control' disabled={!isBasic} placeholder='Enter Nick Name' value={topLevelTeamData?.nickName} onChange={(e: any) => setTopLevelTeamData({ ...topLevelTeamData, nickName: e.target.value })} />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className={`${st.reFormBox}`}>
                                        <div className={`${st.teamTitleInfo}`}>
                                            <label htmlFor="">Team title</label>
                                            <button className={`${st.addServe}`} onClick={handleTitleShow}><FiPlus /> Add Team Title <HoverEyeContent number={9068} /></button>
                                        </div>
                                        <div className={`${st.formBox} position-relative`}>
                                            <span className={`${st.multiSelbookingect_whiteBg} mt-2`}>
                                                <MultiSelect2 list={teamTitleList} value={selectedTeamTitles} disabled={!isBasic} onChange={(data: any) => setSelectedTeamTitles(data)} />
                                            </span>
                                        </div>
                                        {errorTrig && selectedTeamTitles?.length === 0 && (
                                            <p className={"errorMessage"}>this field is required</p>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div key={activeTab}>
                        <Col md={12} className="mt-3">
                            <div >
                                <div className={`${st.tabbingBox} mt-0`}>
                                    <Tab.Container
                                        id="left-tabs-example"
                                        activeKey={activeTab}
                                        onSelect={handleTabSelect}
                                    >
                                        <div className={`${st.tabbing_pillBox}`}>
                                            <Nav variant="pills" className="mb-0">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="info">
                                                        Basic Info
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <span className={`${st.linespan}`}></span>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="branch">
                                                        Branch Assignment
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <span className={`${st.linespan}`}></span>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="leaves">
                                                        Leaves
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <span className={`${st.linespan}`}></span>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="accesscontrol">
                                                        Access Control
                                                    </Nav.Link>
                                                </Nav.Item>

                                            </Nav>
                                        </div>
                                        <Tab.Content>
                                            <Tab.Pane eventKey={activeTab}>
                                                <Routes>
                                                    <Route path={activeTab}
                                                        element={<TeamRoutes
                                                            dynamicName={dynamicName}
                                                            handleStepsFormData={handleStepsFormData}
                                                            loading={loading}
                                                            setTopLevelTeamData={setTopLevelTeamData}
                                                            setProfileImage={setProfileImage}
                                                            apiError={apiError}
                                                            setSelectedTeamTitles={setSelectedTeamTitles}
                                                            editTeamData={editTeamData}
                                                            setEditTeamData={setEditTeamData}
                                                        />}

                                                    />
                                                </Routes>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </Col>

                    </div>
                </Offcanvas.Body>
            </div>
        </>
    )
}