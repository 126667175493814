import React, { useEffect, useState } from 'react';
import { Row, Col, Accordion } from 'react-bootstrap';
import st from "../../../assets/stylesheet/style.module.scss";
import { deleteIcon, editButton } from '../../../assets/images';
import Status from '../../../components/Business/Form/Status';
import { IoSearchSharp } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa';
import ServiceCard from '../AllServices/ServiceCard';
import PriceTable from '../AllServices/PriceTable';
import { useDispatch, useSelector } from 'react-redux';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { updateCounter } from '../../../redux_toolkit/reducer/shareDataReducer';
import ServicesCategoryCanvas from '../../../components/Business/OffCanvas/ServicesCategoryCanvas';
import ServiceInside from '../../../components/Business/OffCanvas/ServiceInside';
import { httpRequest } from '../../../Apis/commonApis';

const initialState = { isEdit: false, serviceCategory: null, editService: null }
export default function ServiceInfo(props: any) {
  const { serviceList, getService, getPrices, setActiveTab } = props;
  let { businessData} = useSelector((state: any) => state.shareDataReducer);
  const [serviceCategoryList, setServiceCategoryList] = useState<any>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedCat, setSelectedCat] = useState<any>('')
  const navigate = useNavigate();
  const [published, setPublished] = useState<any>('')
  const handleServiceCanvasShow = () => setServiceCanvasShow(true);
  function handleRefresh() {
    getService();
    getPrices();
  }
  const handleServiceCanvasClose = () => {
    handleRefresh()
    navigate('/business/publish')
    setEditServiceData(initialState)
    setServiceCanvasShow(false)
  };

  const getServiceCatagory = async () => {
    const res = await httpRequest(`getServiceCatagory?businessId=${businessData._id}`, "get", null, "json");
    if (res.status === true) {
      setServiceCategoryList(res?.data);
    }
  };

  useEffect(() =>{
    if(businessData?._id){
      getServiceCatagory()
    }
  },[businessData]);

  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [showServiceCanvas, setServiceCanvasShow] = useState(false);
  const canCreateService = subModuleRoles?.['Services']?.Services?.Create;
  const [editServiceData, setEditServiceData] = useState<any>(initialState);

  let publishServices = 0;
  const filterService = serviceList?.filter((item: any) => {
    if (item?.prices?.length > 0 && item?.prices?.some((price: any) => price?.isPublish)) {
      publishServices++
    }
    const catCheck = !selectedCat || item?.ServiceCategory?._id === selectedCat
    const publishedCheck = !published || (item?.prices?.some((price: any) => published === 'publish' ? price?.isPublish : !price?.isPublish))
    const queryCheck = !searchQuery || item?.serviceName?.toLocaleLowerCase()?.replaceAll(' ', '')?.includes(searchQuery?.toLocaleLowerCase()?.replaceAll(' ', ''))
    return queryCheck && publishedCheck && catCheck
  })

  const groupedServicesArray = Object.values(
    filterService?.reduce((acc: any, service: any) => {
      const category = service.ServiceCategory.serviceCategoryName;
      if (!acc[category]) {
        acc[category] = { serviceCategoryName: category, categoryObj: service.ServiceCategory, services: [] };
      }
      acc[category].services.push(service);
      return acc;
    }, {})
  );

  const numbersArray = Array.from({ length: groupedServicesArray?.length }, (_, i) => `${i}`);
  return (
    <>
      <ServicesCategoryCanvas show={showServiceCanvas} handleServiceCanvasClose={handleServiceCanvasClose} >
        {showServiceCanvas && <ServiceInside editServiceData={editServiceData} handleServiceCanvasClose={handleServiceCanvasClose} />}
      </ServicesCategoryCanvas>
      <Row>
        <Col md={12}>
          <div className='d-flex gap-3 mb-3 justify-content-end'>
            <div className={`${st.reFormBox} mb-0`} style={{ width: '155px' }}>
              <select className="form-select" value={published} onChange={(e: any) => setPublished(e.target.value)}>
                <option value="">All</option>
                <option value="publish">List on Wellnezza</option>
                <option value="unpublish">Unpublish</option>
              </select>
            </div>
            {/* {publishServices !== 0 && <div className='text-end'>
              <button className={`${st.addGrey} btn`} onClick={() => setActiveTab('team')}>
                Next
              </button>
            </div>} */}
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={7} className="mb-4 pb-2">
          <div className={`${st.publish_pagesHeading}`}>
            <p className='mb-0'>{publishServices} Services will be published on your Wellnezza business listing</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={9}>
          <Row className="g-3 mb-3">

            <Col md={3}>
              <Row className="g-3">
                <Col md={12}>
                  <div className={`${st.reFormBox} mb-0`}>
                    <select className="form-select" value={selectedCat} onChange={(e:any) => setSelectedCat(e.target.value)}>
                      <option value="">Service Category</option>
                      {serviceCategoryList?.map((cat:any) =>{
                        return (
                          <option value={cat?._id} key={cat?._id}>{cat?.serviceCategoryName}</option>
                        )
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={9}>
              <Row className="g-3">
                <Col md={12}>
                  <div className='d-flex gap-3'>
                    <div className={`position-relative ${st.reFormBox} ${st.searchBoxIcon} mb-0 w-100`}>
                      <input type="search" placeholder="Search for Services" className="form-control" value={searchQuery} onChange={(e: any) => setSearchQuery(e.target.value)} />
                      <IoSearchSharp />
                    </div>
                    {canCreateService && <button className={`btn ${st.addBlue}`} style={{ whiteSpace: 'nowrap' }} onClick={() => {
                      setEditServiceData({ ...editServiceData, serviceCategory: null })
                      navigate('/business/publish/addService/info')
                      handleServiceCanvasShow();
                    }}><FaPlus /> Add Services</button>}
                  </div>
                </Col>
              </Row>
            </Col>

            {/* <Col md={7}>
              <Row className="g-3">
                <Col md={12} className='text-end'>

                </Col>
              </Row>
            </Col> */}
          </Row>
          <Row>
            {groupedServicesArray?.length > 0 && <Col md={12}>
              <div className={`${st.newAccordionList} mt-4 pt-2`}>
                <Accordion defaultActiveKey={numbersArray} flush alwaysOpen>
                  {groupedServicesArray?.map((cat: any, c: number) => {
                    return (
                      <Accordion.Item eventKey={`${c}`} key={c}>
                        <div className={`${st.newAccordionFlexBtn}`}>
                          <Accordion.Header>
                            <span className="categoryTypes">{cat?.serviceCategoryName}</span>
                          </Accordion.Header>
                          {canCreateService && <button onClick={() => {
                            setEditServiceData({ ...editServiceData, serviceCategory: cat?.categoryObj })
                            navigate('/business/publish/addService/info')
                            handleServiceCanvasShow();
                          }} className={`${st.addServe}`}><FiPlus /> Add New Services</button>}
                        </div>
                        <Accordion.Body>
                          {cat?.services?.map((service: any) => {
                            return (
                              <ServiceCard service={service} key={service?._id}
                                handleServiceCanvasShow={handleServiceCanvasShow}
                                getService={getService}
                                getPrices={getPrices}
                                priceList={service.prices}
                                setEditServiceData={setEditServiceData}
                                type='publish'
                              >
                                <PriceTable prices={service.prices} getBusinessPrice={getPrices} key={service?._id} />
                              </ServiceCard>
                            )
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })}
                </Accordion>
              </div>
            </Col>}
          </Row>
        </Col>
      </Row>
    </>
  )
}
