import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import { Col, Row } from "react-bootstrap";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { getRoleListArr } from "../../../Utils/Funcs";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";

const Services = (props: any) => {
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const [loading, setLoading] = useState<boolean>(false);
  const [roleList, setRoleList] = useState<any>([]);
  const [accessRole, setAccessRole] = useState<any>([]);
  const [roleManagementList, setRoleManagementList] = useState<any>([]);

  const getAccessRole = async () => {
    let res = await httpRequest(`getSuperRoles`, "get", null, 'json');
    if (res.status) {
      setAccessRole(res?.data?.map((item: any) => ({ role: item?.role, access: false, _id: item?._id })));
    }
  };

  useEffect(() => {
    if (accessRole) {
      const roleMList = getRoleListArr(accessRole);
      setRoleManagementList(roleMList);
    }
  }, [accessRole]);

  useEffect(() => {
    getAccessRole();
  }, [businessData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData) {
      getRole();
    }
  }, [businessData, roleManagementList]);

  const getRole = async () => {
    const res = await httpRequest(`getAssignRole?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      let roledata = res?.data[0]?.roles;
      if (Array.isArray(roledata) && roledata.length > 0 && res?.data?.length > 0) {
        const updateActionFor = (actionFor: any[]) =>
          accessRole.map((accessRoleItem: any) =>
            actionFor.find(existRoleObj => existRoleObj._id === accessRoleItem._id) || accessRoleItem
          );

        const modifyRoles = (roles: any[]) =>
          roles.map(role => ({
            ...role,
            actionFor: updateActionFor(role.actionFor || [])
          }));

        const modifiedRoles = roledata.map(roleItem => {
          if (roleItem.roles) {
            return {
              ...roleItem,
              roles: modifyRoles(roleItem.roles)
            };
          }

          const subModule = roleItem.subModule?.map((subMod: any) => ({
            ...subMod,
            roles: modifyRoles(subMod.roles || [])
          }));

          return { ...roleItem, subModule };
        });

        const hasRoleChange = roledata[0]?.roles?.[0]?.actionFor?.length !== accessRole.length;

        setRoleList(hasRoleChange ? modifiedRoles : roledata);
      } else {
        setRoleList(roleManagementList);
      }
    }
  };

  const handleRole = async () => {
    setLoading(true);
    const finalObj = {
      businessId: businessData._id,
      roles: roleList
    };
    let res = await httpRequest(`createAssignRole`, "post", finalObj, "json");
    if (res.status) {
    }
    setLoading(false);
  };

  const handleRoleSelect = useCallback((event: any, modName: string, subModeName: string, actName: string, roleId: string) => {
    setRoleList((prevRoleList: any) =>
      prevRoleList.map((mods: any) => {
        if (mods.module === modName) {
          if (subModeName === '') {
            return {
              ...mods,
              roles: mods.roles.map((elem: any) => {
                if (elem.action === actName) {
                  return {
                    ...elem,
                    actionFor: elem.actionFor.map((item: any) => {
                      if (item._id === roleId) {
                        return { ...item, access: event.target.checked };
                      }
                      return item;
                    }),
                  };
                }
                return elem;
              }),
            };
          } else {
            return {
              ...mods,
              subModule: mods.subModule.map((subMode: any) => {
                if (subMode.name === subModeName) {
                  return {
                    ...subMode,
                    roles: subMode.roles.map((elem: any) => {
                      if (elem.action === actName) {
                        return {
                          ...elem,
                          actionFor: elem.actionFor.map((item: any) => {
                            if (item._id === roleId) {
                              return { ...item, access: event.target.checked };
                            }
                            return item;
                          }),
                        };
                      }
                      return elem;
                    }),
                  };
                }
                return subMode;
              }),
            };
          }
        }
        return mods;
      })
    );
  }, []);

  const canAssignRole = subModuleRoles?.['Business Set Up']?.['User Access']?.Edit;

  const [moduleActive, setModuleActive] = useState<any>({});
  const [subModuleActive, setSubModuleActive] = useState<any>({});

  const handleActive = useCallback((module: any) => {
    setModuleActive((prev: any) => ({
      ...prev,
      [module]: { status: !prev[module]?.status },
    }));
  }, []);

  const handleSubActive = useCallback((subModule: any) => {
    setSubModuleActive((prev: any) => ({
      ...prev,
      [subModule]: { status: !prev[subModule]?.status },
    }));
  }, []);

  const handleTopCatRole = useCallback((e: any, menu: any, roleId: any) => {
    const checked = e.target.checked;
    setRoleList((prevRoleList: any) =>
      prevRoleList.map((item: any) => {
        if (item?.module === menu?.module) {
          if (!menu?.subModule) {
            return {
              ...item,
              roles: item.roles.map((role: any) => ({
                ...role,
                actionFor: role.actionFor.map((action: any) => action._id === roleId ? { ...action, access: checked } : action)
              }))
            };
          } else {
            return {
              ...item,
              subModule: item.subModule.map((sub: any) => ({
                ...sub,
                roles: sub.roles.map((role: any) => ({
                  ...role,
                  actionFor: role.actionFor.map((action: any) => action._id === roleId ? { ...action, access: checked } : action)
                }))
              }))
            };
          }
        }
        return item;
      })
    );
  }, [roleList]);

  const handleSubCatRole = useCallback((e: any, mods: any, subMods: any, roleId: any) => {
    const checked = e.target.checked;
    setRoleList((prevRoleList: any) =>
      prevRoleList.map((item: any) => {
        if (item?.module === mods?.module) {
          return {
            ...item,
            subModule: item.subModule.map((sub: any) => {
              if (sub?.name === subMods?.name) {
                return {
                  ...sub,
                  roles: sub.roles.map((role: any) => ({
                    ...role,
                    actionFor: role.actionFor.map((action: any) => action._id === roleId ? { ...action, access: checked } : action)
                  }))
                };
              }
              return sub;
            })
          };
        }
        return item;
      })
    );
  }, []);

  const RolesArray = useMemo(() => accessRole, [accessRole]);


  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>

          <div className={`${st.titleInfo} w-auto`}>
            <h5>Role Management   <HoverEyeContent number={1023} /> </h5>
          </div>
          <div className="text-end">
            <HelpButon number={52} type={"help"} className={"btn"} />
          </div>
          {/* <div className={`${st.rightInfo}`}></div> */}
        </div>

        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn} ${cx.rollTable}`}>
            <div className={`${cx.tableContainer}`}>
              <Table hover borderless className={cx.table}>
                <thead>
                  <tr>
                    <th>Roles</th>
                    {RolesArray.map((item: any) => {
                      return <th key={item?._id}>{item?.role}</th>
                    })}
                  </tr>
                </thead>
                {roleList?.map((mods: any, index: number) => {
                  return (
                    <tbody key={index}>
                      <tr onClick={() => handleActive(mods?.module)} style={{ verticalAlign: "middle", cursor: 'pointer' }}>
                        <th>
                          <h5 style={{ margin: '0px' }} ><span style={{ padding: '0px', fontSize: "18px", marginRight: "4px" }} >{moduleActive?.[mods?.module]?.status ? "-" : "+"}</span> {mods.module}</h5>
                        </th>
                        {RolesArray.map((item: any) => {
                          const checkIfCatLevelCheck = mods.roles ? mods?.roles?.every((role: any) => {
                            const roleObj = role?.actionFor?.find((action: any) => action?._id === item?._id);
                            return roleObj?.access;
                          }) : mods.subModule?.every((subModuleRole: any) => {
                            return subModuleRole?.roles?.every((role: any) => {
                              const roleObj = role?.actionFor?.find((action: any) => action?._id === item?._id);
                              return roleObj?.access;
                            })
                          })

                          const checkNotAllButSomeCheck = checkIfCatLevelCheck ? false : mods.roles ? mods?.roles?.some((role: any) => {
                            const roleObj = role?.actionFor?.find((action: any) => action?._id === item?._id);
                            return roleObj?.access;
                          }) : mods.subModule?.some((subModuleRole: any) => {
                            return subModuleRole?.roles?.some((role: any) => {
                              const roleObj = role?.actionFor?.find((action: any) => action?._id === item?._id);
                              return roleObj?.access;
                            })
                          })

                          return <th key={item?._id}>
                            <label className={`d-block ${st.checkbox} mb-4`}>
                              <input type="checkbox" onChange={(e: any) => handleTopCatRole(e, mods, item?._id)} checked={checkNotAllButSomeCheck || checkIfCatLevelCheck} />
                              <span className={`${st.checkmark} ${checkNotAllButSomeCheck ? st.grayCheck : ""}`}></span>
                            </label>
                          </th>
                        })}
                      </tr>
                      {/* <tr>
                        <td>{mods.module}</td>
                        {RolesArray?.map((item: any) => {
                          return (<td key={item}>
                            <label className={`d-block ${st.checkbox} mb-4`}>
                              <input type="checkbox" />
                              <span className={`${st.checkmark}`}></span>
                            </label>
                          </td>)
                        })}
                      </tr> */}
                      {moduleActive?.[mods?.module]?.status && (
                        <>
                          {mods.roles && mods.roles?.map((elem: any, i: number) => {

                            return (<tr key={elem.action}>
                              <td style={{ backgroundColor: "#eff0f5", paddingLeft: '30px' }}>{elem.action}</td>
                              {elem.actionFor?.map((item: any) => {
                                return (<td key={item.role} style={{ backgroundColor: "#eff0f5" }}>
                                  <label className={`d-block ${st.checkbox} mb-4`}>
                                    <input type="checkbox" checked={item.access} onChange={(e) => handleRoleSelect(e, mods.module, '', elem.action, item._id)} />
                                    <span className={`${st.checkmark}`}></span>
                                  </label>
                                </td>)
                              })}
                            </tr>)
                          })}
                          {mods.subModule &&
                            mods.subModule?.map((subMode: any, i: number) => {
                              return (
                                <Fragment key={subMode?.name}>
                                  <tr>
                                    <th style={{ paddingLeft: '20px', verticalAlign: "middle", cursor: 'pointer' }} onClick={() => handleSubActive(subMode?.name)}>
                                      <h5 style={{ margin: '0px' }} ><span style={{ padding: '0px', fontSize: "18px", marginRight: "4px" }} >{subModuleActive?.[subMode?.name]?.status ? "-" : "+"}</span> {subMode.name}</h5>
                                    </th>
                                    {RolesArray.map((item: any) => {
                                      const checkSubCatChecked = subMode?.roles?.every((role: any) => {
                                        const roleObj = role?.actionFor?.find((action: any) => action?._id === item?._id);
                                        return roleObj?.access;
                                      })

                                      const checkNotAllButSomeCheck = checkSubCatChecked ? false : subMode?.roles?.some((role: any) => {
                                        const roleObj = role?.actionFor?.find((action: any) => action?._id === item?._id);
                                        return roleObj?.access;
                                      })

                                      return <th key={item?._id}>
                                        <label className={`d-block ${st.checkbox} mb-4`}>
                                          <input type="checkbox" onChange={(e: any) => handleSubCatRole(e, mods, subMode, item?._id)} checked={checkNotAllButSomeCheck || checkSubCatChecked} />
                                          <span className={`${st.checkmark} ${checkNotAllButSomeCheck ? st.grayCheck : ""}`}></span>
                                        </label>
                                      </th>
                                    })}
                                  </tr>
                                  {subModuleActive?.[subMode?.name]?.status && subMode.roles?.map((elem: any, i: number) => {
                                    return (
                                      <tr key={i}>
                                        <td style={{ paddingLeft: '40px' }}>{elem.action === "EditBasicInfo" ? "Edit Basic Info" : elem.action === "EditAbout" ? "Edit About" : elem?.action === "EditSetting" ? "Edit Setting" : elem?.action}</td>
                                        {elem.actionFor?.map((item: any) => {
                                          return (
                                            <td key={item.role}>
                                              <label className={`d-block ${st.checkbox} mb-4`}>
                                                <input type="checkbox" checked={item.access} onChange={(e) => handleRoleSelect(e, mods.module, subMode.name, elem.action, item._id)} />
                                                <span className={`${st.checkmark}`}></span>
                                              </label>
                                            </td>)
                                        })}
                                      </tr>
                                    )
                                  })}
                                </Fragment>
                              )
                            })}
                        </>
                      )}
                    </tbody>
                  )
                })}
              </Table>
            </div>
            <Row>
              <Col md={12}>
                <div className={`${st.formBox} mb-0`}>
                  <div className={`${st.btnsGroup}`}>
                    {canAssignRole && <button className={`btn ${st.btn4}`} onClick={handleRole} disabled={loading}>
                      {loading ? <LoadingBtn /> : 'Save'}
                    </button>}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

      </section>
    </>
  );
};

export default Services;
