import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

import { Accordion, Button, Col, Nav, Row, Tab, Table } from "react-bootstrap";
import {
  basicInfo,
  basicInfoA,
  pricingTab,
  pricingTabA,
  search,
  tick,
} from "../../../assets/images";
import { NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

import { getDynamicURIName } from "../../../Utils/Funcs";
import BranchPackageRoutes from "./BranchPackageRoutes";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";

const EditPackage = (props: any) => {
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let state = useSelector((state: any) => state.BranchReducer);
  const location = useLocation();
  const dynamicName = getDynamicURIName(location.pathname);
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<any>("")

  const datalocation: any = useLocation();
  let packageDatalist: any = datalocation?.state;

  const [packageId, setPackageId] = useState("");
  const [activeTab, setActiveTab] = useState(dynamicName);
  const [loading, setLoading] = useState(false);

  const [servicelist, setServicelist] = useState<any>(packageDatalist?.newServiceData);
  // const [desc, setDesc] = useState<string>("");
  const [service, setService] = useState<any>([])
  const [packagevalue, setPackagevalue] = useState<any>([])
  const [activeKey, setActiveKey] = useState("tabNew1");
  const [packageName, setpackageName] = useState(packageDatalist?.packageName);
  const [serviceAvailabe, setServiceAvailabe] = useState(packageDatalist?.ServiceAvailableFor);
  const [packDesc, setpackDesc] = useState(packageDatalist?.PackageDescription);
  const [afetrDesc, setafetrDesc] = useState(packageDatalist?.PackageAfterCareDescription);
  const [terms, setTerms] = useState(packageDatalist?.TermsAndConditions);
  const [showAddCategory, setAddCategoryShow] = useState(false);
  const [dataList, setDataList] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState('');
  const handleAlertModalClose = () => setShowErrorModal('');
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

  const handleAddCategoryClose = () => setAddCategoryShow(false);
  const handleAddCategoryShow = () => setAddCategoryShow(true);

  const [showNumberServce, setShowNumberServce] = useState(false);
  const handleNumberServceClose = () => setShowNumberServce(false);
  const handleNumberServceShow = () => setShowNumberServce(true);

  const [packageData, setPackageData] = useState<any>(null)

  useEffect(() => {
    if (location.state && businessData) {
      getPackages();
    }
  }, [businessData, location.state]);

  const getPackages = async () => {
    let res = await httpRequest(`getPackages?_id=${location.state?.packageId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setPackageData(res?.data[0]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // getBusinessPrice()
  }, []);
  const [durationData, setDurationData] = useState<any>({
    type: packageDatalist?.TypeOfservice,
    regularPrice: packageDatalist?.RegularPrice,
    memberPrice: packageDatalist?.memberPrice,
  });
  // const Services:any = [...servicelist, ...dataList];

  const handleStepsFormData = (formdata: any, step: any) => {
    if (formdata === 'prevStep') {
      setActiveTab(step);
      navigate(step, { state: { packageId: location.state.packageId }, replace: true });
      return;
    }
    if (step === 'step1') {
      createEditBasicPackage(formdata, 'pricing');
    } else if (step === 'step2') {
      createEditPackage(formdata, 'back');
    }
  };

  const createEditBasicPackage = async (data: any, step: string) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)

    formData.append("PackageAfterCareDescription", data?.PackageAfterCareDescription)
    formData.append("PackageDescription", data?.PackageDescription)
    formData.append("TermsAndConditions", data?.TermsAndConditions)

    formData.append("PackageName", data?.PackageName)
    formData.append("ServiceAvailableFor", data?.ServiceAvailableFor)
    formData.append("branchId", JSON.stringify(data?.branchId))

    for (let i = 0; i < data?.image.length; i++) {
      formData.append('image', data?.image[i]);
    }

    if (data?.existImage) {
      formData.append('oldImages', JSON.stringify(data?.existImage));
    }

    let path = 'createPackages';
    let method = 'post';
    if (location.state && location?.state?.packageId) {
      path = `updatePackages/${location?.state?.packageId}`
      method = "PATCH";
    }
    let res = await httpRequest(path, method, formData, "formdata");

    if (res?.status) {
      if (step === 'back') {
        navigate(-1)
      } else {
        setActiveTab(step);
        navigate(step, { state: { packageId: res.data?._id }, replace: true });
      }
    } else {
      setApiError(res?.message)

      setTimeout(() => {
        setApiError("")
      }, 2000)
    }
    setLoading(false);
  }

  const createEditPackage = async (data: any, step: string) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("businessId", businessData?._id)
    formData.append("finalPrice", data?.finalPrice)
    formData.append("totalPrice", data?.totalPrice)
    formData.append("typeOfService", data?.typeOfService);
    formData.append("Services", JSON.stringify(data?.Services))

    let path = 'createPackages';
    let method = 'post';
    if (location.state && location?.state?.packageId) {
      path = `updatePackages/${location?.state?.packageId}`
      method = "PATCH";
    }
    let res = await httpRequest(path, method, data, "json");

    if (res?.status) {
      if (step === 'back') {
        navigate(-1)
      } else {
        setActiveTab(step);
        navigate(step, { state: { packageId: res.data?._id }, replace: true });
      }
    }
    setLoading(false);
  }

  const handleTabSelect = (tab: any) => {
    if (location.pathname.includes('edit-package')) {
      setActiveTab(tab);
      navigate(`${tab}`, { state: { packageId: location?.state?.packageId }, replace: true });
    }
  };

  return (
    <>

      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {packageData?.PackageName
                ? packageData?.PackageName
                : "Add Package"}
            </h5>
          </div>
          <div className={`${st.rightInfo}`}>
            {location.pathname.includes('edit-package') && <div style={{ color: "#3e8575" }}>Edit Mode</div>}
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="basicInfo">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pricing">
                  <img
                    src={pricingTab}
                    alt="info"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={pricingTabA}
                    alt="info"
                    className={`${st.active}`}
                  />
                  Pricing and Duration
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                <Routes>
                  <Route path={activeTab}
                    element={<BranchPackageRoutes apiError={apiError} dynamicName={dynamicName} handleStepsFormData={handleStepsFormData} loading={loading} />} />
                </Routes>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
      <ErrorPopup
        showErrorModal={showErrorModal}
        handleErrorModalClose={handleAlertModalClose}
      />
    </>
  );
};

export default EditPackage;
