import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import cx from "./index.module.scss";
import { brand1, deals } from "../../../assets/images";
import { BsStarFill } from "react-icons/bs";

const Brands = (props: any) => {
  return (
    <>
      <div className={`${cx.brandBox}`}>
        <div className={`${cx.brandBoxImg}`}>
          <NavLink to="#"><img src={deals} alt="deals" className={`${cx.image}`} /></NavLink>
          <div className={`${cx.tag}`}>
          <BsStarFill className={`${cx.icon}`}/> <b>5.0</b> <span>(673)</span>
          </div>
        </div>
        <div className={`${cx.brandBoxBody}`}>
          <h3><NavLink to="#">James Spa</NavLink></h3>
          <p>west-coast, Singapore </p>
          <div className={`${cx.brandLogo}`}>
             <img src={brand1} alt="brand1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
