import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import st from '../../../../assets/stylesheet/style.module.scss';
import cx from '../index.module.scss'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../../Apis/commonApis';
import LoadingBtn from '../../Buttons/LoadingBtn';

export default function BasicTab({ editBundleData, loading, handleStepsFormData }: any) {
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const navigate = useNavigate()
    const [branchData, setBranchData] = useState<any>([]);

    const {
        register,
        handleSubmit,
        watch: watchNonOpHrs,
        formState: { errors },
        setValue,
        reset,
        watch
    } = useForm();

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
        if (res.status === true) {
            setBranchData(res.data?.map((item: any) => item?._id));
        }
    }

    useEffect(() => {
        // window.scrollTo(0, 0);
        if (businessData) {
            getBranches();
        }
    }, [businessData]);

    useEffect(() => {
        if (editBundleData) {
            // setValue("bundleName", editBundleData?.bundleName)
            setValue("bundleAvail", editBundleData?.bundleAvail?.split(","))
            setValue("details", editBundleData?.details || "")
            setValue("bundleAfterCare", editBundleData?.bundleAfterCare || "")
        }
    }, [editBundleData])

    const basicSubmit = async (data: any) => {
        let branchArr = editBundleData?.branchArr ? editBundleData?.branchArr : branchData
        const finalData = { ...data, branchArr: JSON.stringify(branchArr) }
        handleStepsFormData(finalData, "pricing")
    }
    return (
        <>
            <Row>
                <Col md={10}>
                    <div className={`${st.reFormBox}`}>
                        <label htmlFor="">Service Available For</label>
                        <div>
                            <div className={`${st.edit_publish_checkBox} `}>
                                <label className={`d-inline-flex ${st.checkbox}`}>
                                    <input type="checkbox" value="female" {...register("bundleAvail", {
                                        required: true
                                    })} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1`}>Female</span>
                                </label>
                                <label className={`d-inline-flex ${st.checkbox}`}>
                                    <input type="checkbox" value={"male"} {...register("bundleAvail", {
                                        required: true
                                    })} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1`} >Male</span>
                                </label>
                                <label className={`d-inline-flex ${st.checkbox}`}>
                                    <input type="checkbox" value="anyone" {...register("bundleAvail", {
                                        required: true
                                    })} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1`}>Everyone</span>
                                </label>
                            </div>
                            {errors?.bundleAvail?.type === "required" && (
                                <p className={"errorMessage"}>This field is required</p>
                            )}
                        </div>
                    </div>
                </Col>
                <Col md={10}>
                    <div className={`${st.reFormBox}`}>
                        <label >Bundle Description</label>
                        <textarea placeholder="Enter Your Bundle Description" style={{ height: '80px' }} className="form-control" {...register("details")} />
                    </div>
                </Col>
                <Col md={10}>
                    <div className={`${st.reFormBox}`}>
                        <label >Bundle Aftercare Description</label>
                        <textarea placeholder="Enter Your bundle Aftercare Description" style={{ height: '80px' }} className="form-control" {...register("bundleAfterCare")} />
                    </div>
                </Col>
            </Row>
            <div className={`${cx.offcanvas_footer}`}>
                <Row>
                    <Col md={12}>
                        <div className={`${st.formBox} ${cx.formSign}`}>
                            <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                                <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={handleSubmit(basicSubmit)} disabled={loading}>
                                    {loading ? <LoadingBtn /> : 'Save'}
                                </button>
                                <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit" onClick={(e: any) => {
                                    e.preventDefault();
                                    handleStepsFormData(null, 'info');
                                }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
