import React, { useEffect, useState } from 'react'
import st from "../../../assets/stylesheet/style.module.scss";
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { helloHand } from '../../../assets/images';
import { httpRequest } from '../../../Apis/commonApis';
import { getBusinessData } from '../../../redux_toolkit/reducer/shareDataReducer';
import ErrorPopup from '../Modals/ErrorPopup';

function StepperRow({ type = '', ispublishable = true }: any) {
    let { businessData, counter } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(1)
    const dispatch = useDispatch()

    const [showErrorModal, setShowErrorModal] = useState("");

    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
    const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
        ({ theme, ownerState }) => ({
            color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
            display: 'flex',
            height: 22,
            alignItems: 'center',
            ...(ownerState.active && {
                color: '#784af4',
            }),
            '& .QontoStepIcon-completedIcon': {
                color: '#784af4',
                zIndex: 1,
                fontSize: 18,
            },
            '& .QontoStepIcon-circle': {
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: 'currentColor',
            },
        }),
    );

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 8,
            left: 'calc(-50% + 4px)',
            right: 'calc(50% + 4px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(var(--Main2),var(--Main3))',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(var(--Main2),var(--Main3))',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 5,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean, icon: any };
    }>(({ theme, ownerState }) => {
        console.log(ownerState, 'ownerState');

        return ({
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
            zIndex: 1,
            color: '#fff',
            width: 20,
            height: 20,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            ...(ownerState.active && {
                backgroundImage:
                    'linear-gradient(270deg, rgb(204 204 204) 50%, rgb(74 150 134) 50%)',
                // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
            }),
            ...(((ownerState.completed || (ownerState?.icon === 6 && counter?.promotion > 0) || ownerState?.icon === 5 && counter?.bundles > 0)) && {
                backgroundImage:
                    'linear-gradient(var(--Main2),var(--Main3))',
            }),
        }
        )
    });

    function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className, icon } = props;

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active, icon }} className={className}>
                {/* {icons[String(props.icon)]} */}
            </ColorlibStepIconRoot>
        );
    }
    let steps = [`Business Details`];
    if (type === 'publish' || type === 'dashboard') {
        const extraItem = [`Branch / Location(${counter?.branch || 0})`, `Service(${counter?.service || 0})`, `Team(${counter?.team || 0})`, `Bundles(${counter?.bundles || 0})`, `Promotion(${counter?.promotion || 0})`];
        steps.push(...extraItem)
    } else {
        const extraItem = [`Branch / Location(${counter?.branch || 0})`, `Service(${counter?.service || 0})`, `Team(${counter?.team || 0})`, `Wellnezza`];
        steps.push(...extraItem)
    }
    useEffect(() => {
        if (businessData) {
            if (type === 'publish') {
                setActiveStep(4)
            } else {
                setActiveStep(+businessData?.stepCompleted - 5)
            }
        }
    }, [businessData])
    if (businessData?.stepCompleted > 9 && type !== 'publish' && type !== 'dashboard') {
        return (
            <></>
        )
    }

    const navigateArr = [
        { index: 0, link: '/business/business-setting/basic/info' },
        { index: 1, link: '/business/branch' },
        { index: 2, link: '/business/services' },
        { index: 3, link: '/business/new-teams' },
        { index: 4, link: '/business/services' },
        { index: 5, link: '/business/promotion' }
    ]
    function handleStepClick(step: number) {
        if (type === 'publish') {
            return
        }
        if (type === 'dashboard') {
            navigateArr?.forEach((item: any) => {
                if (item.index === step) {
                    navigate(item?.link)
                }
            })
        } else {
            const totalStep = step + 5;
            if (totalStep <= +businessData?.stepCompleted) {
                // setActiveStep(step)
                if (step === 0) {
                    navigate('/business/business-setting/basic/info')
                }
                if (step === 1) {
                    navigate('/business/branch')
                }
                if (step === 2) {
                    navigate('/business/services')
                }
                if (step === 3) {
                    navigate('/business/new-teams')
                }
                if (step === 4) {
                    navigate('/business/wellnezza/business')
                }
            }

        }
    }

    async function handlePublish() {
        let res = await httpRequest(`updateBusiness/${businessData?._id}`, "PATCH", { isPublished: !businessData?.isPublished ? 'inProgress' : businessData?.isPublished === 'unPublished' ? 'inProgress' : 'unPublished' }, "json");
        if (res?.status) {
            dispatch(getBusinessData({ id: `businessId=${res?.data?._id}` }));
            handleAlertModalShow(`Success: The business has been ${(!businessData?.isPublished || businessData?.isPublished === 'unPublished') ? 'successfully added for publication' : 'Unpublished'}`)
        } else {
            handleAlertModalShow(`Error: ${res?.message}`)

        }
    }

    return (
        <>
            <ErrorPopup
                // handlePasswordModalClose={handlePasswordModalClose}
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            <div className={`${st.stepperMain} `}>
                {type !== 'publish' || type !== 'dashboard' && <h4 className={`${st.stepper_heading} `}>Welcome to Wellnezza! You're just a few steps away from having your services listed on our website..</h4>}
                {type === 'publish' && <h4 className={`${st.stepper_heading} `}> <img src={helloHand} alt="hello" />  You're just a Click away from showcasing your business on the Wellnezza platform</h4>}
                {type === 'dashboard' && <h4 className={`${st.stepper_heading} `}>  Your Wellnezza Setup Progress</h4>}
                <Stack sx={{ width: '100%' }} spacing={2}>
                    {/* <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                   </Stepper> */}
                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label, index: any) => (
                            <Step key={label} onClick={() => handleStepClick(index)} style={{ cursor: 'pointer' }}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>

                {type === 'publish' && (ispublishable || businessData?.isPublished) && <div className='text-end mb-0 pb-1 mt-2 mx-4'>
                    <button className={`${st.addGrey} btn`} style={{ minWidth: '250px' }} onClick={() => handlePublish()}>{(businessData?.isPublished === 'published' || businessData?.isPublished === 'inProgress') ? "Unpublish " : "Publish on wellnezza"}
                    </button>
                </div>}
            </div>
        </>
    )
}

export default StepperRow