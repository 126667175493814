import React, { Fragment, useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import Table from "react-bootstrap/Table";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { changeAlertCountState } from "../../../redux_toolkit/reducer/AlertMsgReducer";
import { getBusinessData } from "../../../redux_toolkit/reducer/shareDataReducer";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import MultiSelect from "../../../components/Business/MultiSelect/MultiSelect";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";

const AssignServicesToTeam = (props: any) => {

  let { businessData } = useSelector((state: any) => state.shareDataReducer);

  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [selectedBranch, setSelectedBranch] = useState<any>([]);
  const [selectedCat, setSelectedCat] = useState<any>([]);
  const [selectedTeam, setSelectedTeam] = useState<any>([]);

  const [serviceList, setServiceList] = useState<any>([]);
  const [teamData, setTeamData] = useState<any>([]);
  const [branchData, setBranchData] = useState([]);

  const [loading, setLoading] = useState<any>(false);


  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData) {
      getService();
      getTeamMemberData();
      getBranches()
    }
  }, [businessData]);

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
    if (res.status) {
      setBranchData(res.data);
    }
  }

  const getTeamMemberData = async () => {
    const res: any = await httpRequest(`getAllTeamMembers?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res?.status) {
      let customerServiceTeam: any = res?.data?.filter((item: any) => {        
        return item?.Role?.some((roleItem: any) => roleItem?.role?.some((teamTitle: any) => teamTitle?.customerService))
      })

      setTeamData(customerServiceTeam);
      const formattedTeamArr = customerServiceTeam?.map((team: any) => ({ teamMemberId: team?._id, serviceId: team?.serviceId?.map((ser: any) => ser?._id) }))
      setSelectedServiceTeam(formattedTeamArr)
    }
  };

  function makeCustomServiceArr(allServices: any) {
    const restructuredPricesArray: any = {};
    allServices?.forEach((priceObj: any) => {
      const { _id, seasonType, duration, seasonPrice, BusinessService } = priceObj;
      if (BusinessService?.serviceCatagoryId) {
        const { _id: service_id, serviceName: serviceName, ServiceCategory: { _id: serviceCatagoryId, serviceCategoryName: serviceCategoryName } } = BusinessService;

        if (!restructuredPricesArray[serviceCatagoryId]) {
          restructuredPricesArray[serviceCatagoryId] = {
            serviceCategoryName,
            serviceCatagoryId,
            services: {}
          };
        }

        if (!restructuredPricesArray[serviceCatagoryId]?.services[service_id]) {
          restructuredPricesArray[serviceCatagoryId].services[service_id] = {
            serviceName,
            service_id,
            prices: []
          };
        }
        restructuredPricesArray[serviceCatagoryId].services[service_id].prices.push({ seasonType, duration, seasonPrice, _id });
      }
    });
    const finalResult = Object.values(restructuredPricesArray).map((category: any) => ({
      serviceCategoryName: category.serviceCategoryName,
      _id: category.serviceCatagoryId,
      services: Object.values(category.services).map((service: any) => ({
        serviceName: service.serviceName,
        _id: service.service_id,
        prices: service.prices
      }))
    }));
    return finalResult;
  }
  const getService = async () => {
    const res = await httpRequest(`getBusinessPrice?businessId=${businessData._id}&accessType=list`, "get", null, "json");
    if (res.status) {
      let serviceArr = makeCustomServiceArr(res.data);
      setServiceList(serviceArr);
    }
  };

  const canAssignService = subModuleRoles?.['Teams']?.['Assign Services']?.Edit; 

  const [activeService, setActiveService] = useState<any>({});
  const [selectedServiceTeam, setSelectedServiceTeam] = useState<any>([]);

  const handleActive = (id: any) => {
    let updatedData = { ...activeService };

    updatedData[id] = {
      status: !updatedData[id]?.status
    }
    setActiveService(updatedData)
  }

  function handleSingleTeamMemberToAllCat(servCat: any, updatedData: any, team: any, type: any, checked: any) {
    let services: any = [];
    servCat?.forEach((item: any) => {
      item?.services?.forEach((ser: any) => {
        services.push((ser?._id))
      })
    })
    const teamIndex = selectedServiceTeam?.findIndex((item: any) => item?.teamMemberId === team?._id)
    if (teamIndex != -1) {
      let serviceIds: any = updatedData?.[teamIndex]?.serviceId || [];

      if (type === "category") {
        if (checked) {
          serviceIds = [...serviceIds, ...services]
        } else {
          serviceIds = serviceIds?.filter((item: any) => !services?.some((serItem: any) => serItem === item))
        }
      }
      updatedData[teamIndex] = {
        ...updatedData[teamIndex],
        serviceId: serviceIds
      }
    } else {
      let serviceIds: any = [];
      if (type === "category") {
        serviceIds = [...serviceIds, ...services]
      }
      updatedData.push({ teamMemberId: team?._id, serviceId: serviceIds })
    }
    setSelectedServiceTeam(updatedData)
  }

  function handleAssignTeam(updatedData: any, team: any, type: any, checked: boolean, servCat: any,) {
    const teamIndex = selectedServiceTeam?.findIndex((item: any) => item?.teamMemberId === team?._id)
    if (teamIndex != -1) {
      let serviceIds: any = updatedData?.[teamIndex]?.serviceId || [];

      if (type === "category") {
        if (checked) {
          serviceIds = [...serviceIds, ...servCat?.services?.map((item: any) => item?._id)]
        } else {
          serviceIds = serviceIds?.filter((item: any) => !servCat?.services?.some((serItem: any) => serItem?._id === item))
        }
      } else {
        if (checked) {
          serviceIds = [...serviceIds, servCat?._id]
        } else {
          serviceIds = serviceIds?.filter((item: any) => servCat?._id !== item)
        }
      }
      updatedData[teamIndex] = {
        ...updatedData[teamIndex],
        serviceId: serviceIds
      }
    } else {
      let serviceIds: any = [];
      if (type === "category") {
        serviceIds = [...serviceIds, ...servCat?.services?.map((item: any) => item?._id)]
      } else {
        serviceIds = [...serviceIds, servCat?._id]
      }
      updatedData.push({ teamMemberId: team?._id, serviceId: serviceIds })
    }
    setSelectedServiceTeam(updatedData)
  }

  const handleServiceCheck = (e: any, servCat: any, team: any, type: string, superType: string = "") => {
    const checked = e.target.checked;
    let updatedData = [...selectedServiceTeam];

    if (superType === "assignAllCatToAllTeam") {
      teamData?.forEach((team: any) => {
        handleSingleTeamMemberToAllCat(servCat, updatedData, team, type, checked)
      })
      return
    }

    if (superType === "singleTeamMemberToAllCat") {
      handleSingleTeamMemberToAllCat(servCat, updatedData, team, type, checked)
      return
    }
    if (superType === "globalAssignTeam") {
      teamData?.forEach((team: any) => {
        handleAssignTeam(updatedData, team, type, checked, servCat)
      })
      return
    }
    handleAssignTeam(updatedData, team, type, checked, servCat)
  }

  const assignTeam = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append("serviceArr", JSON.stringify(selectedServiceTeam))
    const res = await httpRequest(`updateTeamMember/123456`, "PATCH", formData, "formdata");
    if (res.status) {
      dispatch(changeAlertCountState({ msg: "Services assign successfully" }));
      getTeamMemberData()
    }
    setLoading(false)
  }

  let globalCheck: any = false;
  const checkIfAllTeamAssigned = teamData?.every((team: any) => selectedServiceTeam?.some((selectedTeam: any) => selectedTeam?.teamMemberId === team?._id));

  if (checkIfAllTeamAssigned) {
    let allServices: any = [];
    serviceList?.forEach((item: any) => {
      allServices.push(...item?.services)
    })
    globalCheck = selectedServiceTeam?.every((selectedItem: any) => {
      return allServices?.every((ser: any) => selectedItem?.serviceId?.includes(ser?._id))
    })
  }

  const teamHandler = (data: any) => {
    setSelectedTeam(data)
  }
  const catHandler = (data: any) => {
    setSelectedCat(data)
  }

  const branchHandler = (data: any) => {
    setSelectedBranch(data)
  }

  const teamList = selectedBranch?.length === 0 ? teamData : teamData?.filter((team:any) => {
    return team?.Role?.some((item:any) => selectedBranch?.some((branch:any) => branch?.value === item?.branch?._id ))
  })

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle} row mt-1`}>
          <Col md={9}>
            <div className={`${st.titleInfo}`}>
              <h5>Assign Services to Teams</h5>
            </div>
          </Col>
          <Col md={3}>
            <div className="text-end">
              <HelpButon number={43} type={"help"} className={"btn"} />
            </div>
          </Col>
          {/* <div className={`${st.rightInfo}`}></div> */}
        </div>

        <div className={`${st.tableBody} ${cx.tableShadowNone}`}>
          <div className={`${st.tableBodyIn} ${cx.rollTable} mt-2`}>
            <Row className="align-items-end">

              <Col md={9}>
                <Row style={{ paddingLeft: '18px' }}>
                  <Col md={3}>
                    <div className={`${st.formBox} mt-2`}>
                      <label className="form-label">Branch
                      <HoverEyeContent number={1010} />
                      </label>
                      <MultiSelect
                        value={selectedBranch}
                        onChange={branchHandler}
                        list={branchData?.map((item: any) => ({ label: item?.branchName, value: item?._id }))}
                        style={{ zIndex: "20" }}
                      />

                    </div>
                  </Col>
                  <Col md={3}>
                    <div className={`${st.formBox} mt-2`}>
                      <label className="form-label">Team
                      <HoverEyeContent number={1011} />
                      </label>
                      <MultiSelect
                        value={selectedTeam}
                        onChange={teamHandler}
                        list={teamData?.map((item: any) => ({ label: `${item?.firstName} ${item?.lastName || ""}`, value: item?._id }))}
                        style={{ zIndex: "20" }}
                      />

                    </div>
                  </Col>
                  <Col md={3}>
                    <div className={`${st.formBox} mt-2`}>
                      <label className="form-label">Service Category
                      <HoverEyeContent number={1012} />
                      </label>
                      <MultiSelect
                        value={selectedCat}
                        onChange={catHandler}
                        list={serviceList?.map((item: any) => ({ label: item?.serviceCategoryName, value: item?._id }))}
                        style={{ zIndex: "20" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={3}>
                <div className={`${st.formBox}`}>

                  <div className={`${st.btnsGroup} justify-content-end`} style={{ paddingRight: '12px' }}>
                    {canAssignService && <button className={`btn ${st.btn4} w-100`} onClick={assignTeam} disabled={loading}>
                      {loading ? <LoadingBtn /> : 'Save'}
                    </button>}
                  </div>
                </div>
              </Col>
            </Row>
            <div className={cx.tableContainer}>
              <Table hover borderless className={cx.table}>
                <thead>
                  <tr>
                    <th>Services </th>
                    {selectedTeam?.length === 0 && <th>All Teams</th>}
                    {teamList?.map((item: any) => {
                      if (selectedTeam?.length !== 0 && !selectedTeam?.some((team: any) => team?.value === item?._id)) {
                        return
                      }
                      return (
                        <th key={item._id}>{item.firstName}</th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody className="mb-3">
                  <tr>
                    {selectedCat?.length === 0 && <td style={{ fontWeight: "500", background: "#eff0f5" }}>
                      All Team
                    </td>}
                    {selectedTeam?.length === 0 && selectedCat?.length === 0 && <td style={{ background: "#eff0f5" }}>
                      <label className={`d-block ${st.checkbox} mb-4`}>
                        <input type="checkbox"
                          onChange={(e: any) => handleServiceCheck(e, serviceList, teamList, "category", "assignAllCatToAllTeam")}
                          checked={globalCheck}
                        />
                        <span className={`${st.checkmark}`}></span>
                      </label>
                    </td>}
                    {teamList?.map((item: any) => {
                      if (selectedTeam?.length !== 0 && !selectedTeam?.some((team: any) => team?.value === item?._id)) {
                        return
                      }
                      if (selectedCat?.length !== 0) {
                        return
                      }

                      let checkIfOneTeamMemberInAllCat: any = false
                      const selectedOneTeamMember = selectedServiceTeam?.find((team: any) => team?.teamMemberId === item?._id);
                      if (selectedOneTeamMember) {
                        checkIfOneTeamMemberInAllCat = serviceList?.every((cat: any) => {
                          return cat?.services?.every((ser: any) => selectedOneTeamMember?.serviceId?.includes(ser?._id))
                        })
                      }

                      return (
                        <td key={item._id} style={{ background: "#eff0f5" }}>
                          <label className={`d-block ${st.checkbox} mb-4`}>
                            <input type="checkbox"
                              onChange={(e: any) => handleServiceCheck(e, serviceList, item, "category", "singleTeamMemberToAllCat")}
                              checked={checkIfOneTeamMemberInAllCat}
                            />
                            <span className={`${st.checkmark}`}></span>
                          </label>
                        </td>
                      )
                    })}
                  </tr>
                  {serviceList?.map((item: any, index: number) => {
                    if (selectedCat?.length !== 0 && !selectedCat?.some((catItem: any) => catItem?.value === item?._id)) {
                      return
                    }

                    let allCatTeam = false;
                    const checkIfallTeamMemberOccupied = teamList?.every((teamItem: any) => selectedServiceTeam.some((selectedTeam: any) => selectedTeam?.teamMemberId === teamItem?._id))
                    if (checkIfallTeamMemberOccupied) {
                      const checkIfCurrCatInAllTeamMember = selectedServiceTeam?.every((teamItem: any) => item?.services?.every((srvs: any) => teamItem?.serviceId?.includes(srvs?._id)))
                      if (checkIfCurrCatInAllTeamMember) {
                        allCatTeam = true
                      } else {
                        allCatTeam = false
                      }
                    }


                    return (
                      <Fragment key={index}>
                        <tr className={`${cx.add_plusMinus}`} style={{ cursor: 'pointer' }} onClick={() => handleActive(item?._id)}>
                          <td style={{ fontWeight: "500", paddingLeft: "34px" }}>{item.serviceCategoryName}</td>
                          {selectedTeam?.length === 0 && <td>
                            <label className={`d-block ${st.checkbox} mb-4`}>
                              <input type="checkbox"
                                onChange={(e: any) => handleServiceCheck(e, item, teamList, "category", "globalAssignTeam")}
                                checked={allCatTeam}
                              />
                              <span className={`${st.checkmark}`}></span>
                            </label>
                          </td>}
                          {teamList?.map((elem: any) => {
                            if (selectedTeam?.length !== 0 && !selectedTeam?.some((team: any) => team?.value === elem?._id)) {
                              return
                            }
                            const teamServices = selectedServiceTeam?.find((teamItem: any) => teamItem?.teamMemberId === elem?._id);
                            let check = false;
                            if (teamServices && item?.services?.every((ser: any) => teamServices?.serviceId?.includes(ser?._id))) {
                              check = true
                            }
                            return (
                              <td key={elem._id}>
                                <label className={`d-block ${st.checkbox} mb-4`}>
                                  <input type="checkbox"
                                    onChange={(e: any) => handleServiceCheck(e, item, elem, "category")}
                                    checked={check}
                                  />
                                  <span className={`${st.checkmark}`}></span>
                                </label>
                              </td>
                            )
                          })}
                          <span className={`${cx.show_icons}`}>{activeService?.[item?._id]?.status ? "-" : "+"}</span>
                        </tr>
                        {activeService?.[item?._id]?.status && item.services?.map((srvs: any) => {
                          let allSerTeam = false;
                          const checkIfallTeamMemberOccupied = teamList?.every((teamItem: any) => selectedServiceTeam.some((selectedTeam: any) => selectedTeam?.teamMemberId === teamItem?._id))
                          if (checkIfallTeamMemberOccupied) {
                            const checkIfCurrServiceInAllTeamMember = selectedServiceTeam?.every((teamItem: any) => teamItem?.serviceId?.includes(srvs?._id))
                            if (checkIfCurrServiceInAllTeamMember) {
                              allSerTeam = true
                            } else {
                              allSerTeam = false
                            }
                          }

                          return (
                            <Fragment key={srvs._id}>
                              <tr className={cx.nested} style={{ opacity: activeService?.[item?._id]?.status ? "1" : "0" }}>
                                <td style={{ background: "#eff0f5" }}>{srvs.serviceName}</td>
                                {selectedTeam?.length === 0 && <td style={{ background: "#eff0f5" }}>
                                  <label className={`d-block ${st.checkbox} mb-4`}>
                                    <input type="checkbox"
                                      onChange={(e: any) => handleServiceCheck(e, srvs, teamList, "service", "globalAssignTeam")}
                                      checked={allSerTeam}
                                    />
                                    <span className={`${st.checkmark}`}></span>
                                  </label>
                                </td>}
                                {teamList?.map((elem: any) => {
                                  if (selectedTeam?.length !== 0 && !selectedTeam?.some((team: any) => team?.value === elem?._id)) {
                                    return
                                  }
                                  const teamServices = selectedServiceTeam?.find((teamItem: any) => teamItem?.teamMemberId === elem?._id);
                                  let check = false;
                                  if (teamServices) {
                                    check = teamServices?.serviceId?.includes(srvs?._id)
                                  }

                                  return (
                                    <td key={elem._id} style={{ background: "#eff0f5" }}>
                                      <label className={`d-block ${st.checkbox} mb-4`}>
                                        <input type="checkbox"
                                          onChange={(e: any) => handleServiceCheck(e, srvs, elem, "services")}
                                          checked={check}
                                        />

                                        <span className={`${st.checkmark}`}></span>
                                      </label>
                                    </td>
                                  )
                                })}
                              </tr>
                              {/* {srvs?.prices?.map((price: any) => {
                                let priceAndType = price.seasonPrice ? `${price.seasonType[0]?.toLocaleUpperCase() + price.seasonType?.slice(1)?.split("P")[0] + " " + "Price"} - $${price.seasonPrice}` : 'Complementary Price : $0';
                                return (
                                  <tr key={price._id} className={cx.inside}>
                                    <td>
                                      {price.duration}: <span className={cx.bold}>{priceAndType}</span>
                                    </td>
                                    <td>
                                      <label className={`d-block ${st.checkbox} mb-4`}>
                                        <input type="checkbox"
                                          checked={customAllSrvboxes?.[item?._id]?.[srvs?._id]?.[price?._id] || allServicesChecked}
                                          onChange={(e) => handleOnePriceToAllBranch(e, item._id, srvs?._id, price?._id)} />
                                        <span className={`${st.checkmark}`}></span>
                                      </label>
                                    </td>
                                    {branchData?.map((elem: any) => {
                                      return (
                                        <td key={elem._id}>
                                          <label className={`d-block ${st.checkbox} mb-4`}>
                                            <input type="checkbox"
                                              checked={selectedCheckboxes?.[elem?._id]?.[item?._id]?.[srvs?._id]?.[price?._id] || false}
                                              onChange={(e) => handleOnePriceToOneBranch(e, elem?._id, item._id, srvs?._id, price?._id)} />
                                            <span className={`${st.checkmark}`}></span>
                                          </label>
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })} */}
                            </Fragment>
                          )
                        })}
                      </Fragment>
                    )
                  })}
                </tbody>
              </Table>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default AssignServicesToTeam;