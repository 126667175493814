import React, { useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { disablePastDate } from "../../../Utils/Funcs";
import PhoneInput from "react-phone-input-2";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { cross, iconEmail, iconPassword, iconUser } from "../../../assets/images";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import ErrorPopup from "./ErrorPopup";


const ChangePasswordModal = (props: any) => {
    let { showPasswordModal, handlePasswordModalClose } = props;
    const [showReNew, setShowRenew] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showOld, setShowOld] = useState(false);
    const [error, setError] = useState();
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [oldPasswordMatch, setOldPasswordMatch] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState('');
    const handleAlertModalClose = () => setShowErrorModal('');
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
    const userData: any = localStorage.getItem('user_data');
    let userId = JSON.parse(userData)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        getValues
    } = useForm();

    const resetPassword = async (postdata: any) => {
        // if(postdata.newPassword === postdata.confirmPassword){
        let data = {
            userId: userId?._id,
            oldPassword: postdata?.oldPassoword,
            password: postdata?.confirmPassword
        }
        const res = await httpRequest('editSetting', 'post', data, 'json');
        if (res.status) {
            handleAlertModalShow(`Success, ${res.message}`);
        } else {

            setError(res.message)
        }

    }
    let oldPassword = watch("oldPassoword");
    let newPassword = watch("newPassword");
    let confirmPassword = watch("confirmPassword")

    return (
        <>
            <Modal
                centered
                show={showPasswordModal}
                backdrop="static"
                keyboard={false}
                onHide={handlePasswordModalClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`${m.modalCts} ${showErrorModal === "" ? "" : m.hide}`}
            >
                <Modal.Header>
                    <Modal.Title>Change Password</Modal.Title>
                    <button className={`${m.closeIcon}`} onClick={handlePasswordModalClose}>
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className={`${m.change}`}>
                        <Row>
                            <Col md={12}>
                                <div className={`${m.entry} ${st.formBox} ${st.iconFormBox} mb-3`}>
                                    <label className="form-label">Old Password</label>
                                    <img
                                        src={iconPassword}
                                        className={`${st.icon}`}
                                        alt="password icon"
                                    />
                                    <input
                                        type={!showOld ? "password" : "text"}
                                        className="form-control"
                                        placeholder="Old Passoword"
                                        {...register("oldPassoword", {
                                            required: true,
                                            minLength: {
                                                value: 6,
                                                message: "Please enter minimum 6 characters",
                                            },
                                        })}
                                    />
                                    {showOld ? (
                                        <AiFillEye
                                            className={`${st.eyeIcon} ${m.eye}`}
                                            onClick={() => {
                                                setShowOld(false);
                                            }}
                                        />
                                    ) : (
                                        <AiFillEyeInvisible
                                            className={`${st.eyeIcon} ${m.eye}`}
                                            onClick={() => {
                                                setShowOld(true);
                                            }}
                                        />
                                    )}
                                </div>
                                {errors?.oldPassoword?.type === "minLength" && (
                                    <p className={"errorMessage v2"}>
                                        Please enter minimum 6 characters
                                    </p>
                                )}
                                {errors?.oldPassoword?.type === "required" && (
                                    <p className="errorMessage v2">This field is required</p>
                                )}

                                {error !== "" ? <p className="errorMessage v2">{error}</p> : ""}
                            </Col>
                            <Col md={12}>
                                <div className={`${m.entry} ${st.formBox} ${st.iconFormBox} mb-3`}>
                                    <label className="form-label">New Password</label>
                                    <img
                                        src={iconPassword}
                                        className={`${st.icon}`}
                                        alt="password icon"
                                    />
                                    <input
                                        type={!showNew ? "password" : "text"}
                                        className="form-control"
                                        placeholder="New Password"
                                        {...register("newPassword", {
                                            required: true,
                                            minLength: {
                                                value: 6,
                                                message: "Please enter minimum 6 characters",
                                            },
                                        })}
                                    />
                                    {showNew ? (
                                        <AiFillEye
                                            className={`${st.eyeIcon} ${m.eye}`}
                                            onClick={() => {
                                                setShowNew(false);
                                            }}
                                        />
                                    ) : (
                                        <AiFillEyeInvisible
                                            className={`${st.eyeIcon} ${m.eye}`}
                                            onClick={() => {
                                                setShowNew(true);
                                            }}
                                        />
                                    )}
                                </div>
                                {errors?.newPassword?.type === "minLength" && (
                                    <p className={"errorMessage v2"}>
                                        Please enter minimum 6 characters
                                    </p>
                                )}
                                {errors?.newPassword?.type === "required" && (
                                    <p className="errorMessage v2">This field is required</p>
                                )}

                                {oldPassword === undefined || oldPassword === "" || newPassword === undefined || newPassword === "" || oldPassword !== newPassword ? (
                                    ''
                                ) : (
                                    <p className="errorMessage v2">Old password and new password are the same</p>

                                )}
                            </Col>
                            <Col md={12}>
                                <div className={`${m.entry} ${st.formBox} ${st.iconFormBox} mb-3`}>
                                    <label className="form-label">Re-enter Your New Password</label>
                                    <img
                                        src={iconPassword}
                                        className={`${st.icon}`}
                                        alt="password icon"
                                    />
                                    <input
                                        type={!showReNew ? "password" : "text"}
                                        className="form-control"
                                        placeholder="Re-enter Your New Password"
                                        {...register("confirmPassword", {
                                            required: true,
                                            minLength: {
                                                value: 6,
                                                message: "Please enter minimum 6 characters",
                                            },
                                        })}

                                    />
                                    {showReNew ? (
                                        <AiFillEye
                                            className={`${st.eyeIcon} ${m.eye}`}
                                            onClick={() => {
                                                setShowRenew(false);
                                            }}
                                        />
                                    ) : (
                                        <AiFillEyeInvisible
                                            className={`${st.eyeIcon} ${m.eye}`}
                                            onClick={() => {
                                                setShowRenew(true);
                                            }}

                                        />
                                    )}
                                </div>
                                {errors?.confirmPassword?.type === "minLength" && (
                                    <p className={"errorMessage v2"}>
                                        Please enter minimum 6 characters
                                    </p>
                                )}
                                {errors?.confirmPassword?.type === "required" && (
                                    <p className="errorMessage v2">This field is required</p>
                                )}
                                {confirmPassword !== undefined && confirmPassword !== "" && confirmPassword !== newPassword ? (<p className="errorMessage v2"> New password and confirm password does't match</p>) : ""}

                            </Col>
                            <Col md={12}>
                                {confirmPassword !== newPassword ? (<button className={`${m.changeBtn} singleBtn`} >Change Password</button>) : (
                                    <button className={`${m.changeBtn} singleBtn`} onClick={handleSubmit(resetPassword)}>Change Password</button>
                                )}

                            </Col>
                        </Row>
                    </div>

                </Modal.Body>
            </Modal>
            <ErrorPopup
                handlePasswordModalClose={handlePasswordModalClose}
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
        </>
    );
};

export default ChangePasswordModal
