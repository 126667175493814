import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import TeamInsideBody from './TeamInsideBody';

function AddNewTeam(props: any) {
    let { show, handleAddNewTeamClose , getTeamMember} = props;
    return (
        <>
            <div className={`${st.offcanvas_newPages1}`}>
                <Offcanvas show={show} onHide={handleAddNewTeamClose} placement="end" className="newOffcanvas_width">
                    {show && (
                        <TeamInsideBody cx={cx} handleAddNewTeamClose={handleAddNewTeamClose} getTeamMember={getTeamMember}/>
                    )}
                </Offcanvas>
            </div>
        </>
    )
}

export default AddNewTeam