import React, { useEffect, useState } from 'react'
import { httpRequest } from '../../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BasicInfo from './BasicInfo';
import BranchTab from './BranchTab';
import LeavesTab from './LeavesTab';
import AccessControl from './AccessControl';

export default function TeamRoutes({ dynamicName, handleStepsFormData, loading,setSelectedTeamTitles,setEditTeamData, editTeamData, setTopLevelTeamData, setProfileImage, apiError }: any) {
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const location = useLocation();

    // const [editTeamData, setEditTeamData] = useState<any>(null);
    const [editLeaveData, setEditLeaveData] = useState<any>(null);
    const [branchList, setBranchList] = useState([]);

    useEffect(() => {
        if (businessData?._id) {
            getBranch();
        }
    }, [businessData]);

    useEffect(() => {
        if (location?.state && businessData?._id) {
            getTeamMemberData();
        }
    }, [location?.state, businessData]);

    const getTeamMemberData = async () => {
        let res = await httpRequest(`getAllTeamMembers?_id=${location?.state?.teamId}&businessId=${businessData?._id}&type=list`, "get", null, "json");
        if (res.status) {
            const teamData = res.data[0]
            setEditTeamData(teamData);
            const role = teamData?.Role?.[0]?.role ? teamData?.Role?.[0]?.role?.map((item:any) => ({label:item?.teamTitle, value: item?._id})) : []
            setSelectedTeamTitles(role)
            setEditLeaveData(res.leaveData[0])
            setProfileImage(teamData?.image || '');
            setTopLevelTeamData((prev:any) => ({...prev, firstName: teamData?.firstName, lastName: teamData?.lastName || '', nickName: teamData?.nickName || '', isPublic: teamData?.isPublic}))
        }
    };

    const getBranch = async () => {
        const res = await httpRequest(`getBranch?businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setBranchList(res?.data)
        }
    };

    // filtering branch for leave
    const branchsId = editTeamData?.Role?.map((role: any) => {
        return role?.branch?._id
    })

    let filterBranchList: any = []

    if (!editTeamData?.Role?.some((role: any) => role?.isGlobal)) {

        filterBranchList = branchList?.filter(((branch: any) => {
            return branchsId?.includes(branch?._id)
        }))
    } else {
        filterBranchList = branchList
    }

    return (
        <>

            {dynamicName === "info" &&
                <BasicInfo businessData={businessData}
                    handleStepsFormData={handleStepsFormData}
                    editTeamData={editTeamData}
                    loading={loading}
                    apiError={apiError}
                />}
            {
                dynamicName === "branch" &&
                <BranchTab
                    handleStepsFormData={handleStepsFormData}
                    editTeamData={editTeamData}
                    // branchList={filterBranchList}
                    branchList={branchList}
                    loading={loading} />
            }
            {
                dynamicName === "leaves" &&
                <LeavesTab
                    handleStepsFormData={handleStepsFormData}
                    editLeaveData={editLeaveData}
                    branchList={filterBranchList}
                    loading={loading}
                    editTeamData={editTeamData}
                />
            }
            {
                dynamicName === "accesscontrol" &&
                <AccessControl
                    handleStepsFormData={handleStepsFormData}
                    editLeaveData={editLeaveData}
                    branchList={branchList}
                    loading={loading}
                    editTeamData={editTeamData}
                />
            }
        </>
    )
}
