import React, { Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import Header from "../Header";
import Footer from "../Footer";

export default function Navigation() {

  // const location = useLocation();
  // const ShowNavs = location.pathname.includes("/signup") || location.pathname.includes("/login");

  return (
    <>
      <div className={`${st.font} ${cx.websiteWrapper}`}>
        <Header />
        <Outlet />
        <Footer />
      </div>
    </>
  );
}