import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab, Table } from "react-bootstrap";
import { business, plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

const Packages = (props: any) => {
  const [membershipData, setMemberShipData] = useState<any>([]);
  const [businessId, setBusinessId] = useState<any>("");
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  useEffect(() => {
    if(branchId){
    getMemberShip()
  }
  }, [branchId]);
  const getMemberShip = async function () {
      const res = await httpRequest(`getMembership?branchId=${branchId}`,"get",null,"json");
      if (res.status) {
        setMemberShipData(res.data);
      }
  };
  useEffect(() => {
    const businessData:any = localStorage.getItem("business_data");
    const business:any = JSON.parse(businessData || null);;
    
    if (business) {
      setBusinessId(business?._id);
    }
  }, []);
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Memberships</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>
        <div className={`${st.buttonsAction} ${cx.actionBtn}`}>
          <ul>
            <li>
              <ExportBtn />
            </li>
            <li>
              <ImportBtn />
            </li>
            <li>
              <NavLink to="add-membership" className={`btn ${st.darkBtn}`}>
                <img src={plus} alt="Create new Package" /> Add a new
                Memberships
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <DataGridTable
              membershipData={membershipData}
              getMemberShip={getMemberShip}
            />
          </div>
        </div>
      </section>
    </>
  );
};
export default Packages;
