import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { deleteIcon } from '../../../assets/images';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../Apis/commonApis';
import Select from "react-select";

function ListViewService(props: any) {
    const { service, selectedClient, deleteService, totalDuration, errorTrig, data, oldData, addService, index, selectedServices, time, date, amenitiesList, promotionList } = props;
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const hours = Math.floor(service?.startTime / 60);
    const minutes = service?.startTime % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    useEffect(() => {
        if (service && date && time) {
            getTeam()
        }
    }, [date, time, service])
    const getTeam = async () => {
        const duration = Number.parseInt(service?.duration)
        setLoading(true)
        if (totalDuration !== 0 && formattedTime) {
            const res = await httpRequest(`getAvailableTeamMember?businessId=${businessData?._id}&branchId=${branchId}&bookingTime=${time}&bookingDate=${date}&newBookingDuration=${duration}&startTime=${formattedTime}&bookingId=${oldData?._id ? oldData?._id : ""}`, "get", null, "json");
            if (res?.status) {
                setTeam(res?.data)
            }
        }
        setLoading(false);
    };
    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState<any>([])
    const promo = promotionList?.filter(((pro: any) => {
        const result = pro?.priceId?.some((price: any) => {
            if (price?.id?._id === service?._id) {
                return true;
            } else {
                return false;
            }
        })
        return result
    }))

    let newTeamList = team?.filter((teamData: any) => {
        const teams = teamData?.serviceId?.some((data: any) => {
            return data?.serviceName === service?.BusinessService?.serviceName
        });
        return teams
    });

    const existService = data?.services?.find((item: any) => item?.serviceId?._id === service?._id);
    const oldTeam = oldData?.services?.find((item: any) => item?.serviceId?._id === service?._id)?.TeamMemberId[0];
    if (oldTeam && oldData?.salectDate === date && oldData?.salectTime === time && !newTeamList?.some((team: any) => team?._id === existService?.TeamMemberId[0]?._id)) {
        newTeamList?.push(oldTeam)
    }

    const promotionServiceIndex = selectedServices?.findIndex((ser: any) => ser?.serviceId === service?._id);
    const promoServices = promo?.flatMap((data: any) => data?.priceId)?.filter((data: any) => {
        return data?.id?._id === service._id
    })
    // const selectedPromoServices = promoServices?.find((data: any) => {
    //     return data?.id?._id === selectedServices?.find((data: any) => data?.promotionId !== null && data?.serviceId === service?._id)?.serviceId
    // })
    const currService = selectedServices?.find((ser: any) => ser?.serviceId === service?._id);
    const appliedPromotion = promotionList?.find((promo: any) => promo?._id === currService?.promotionId)
    const selectedPromoServices = appliedPromotion?.priceId?.find((price: any) => price?.id?._id === currService?.serviceId);

    // selected amenities 
    const selectedAmenities = amenitiesList?.filter((am: any) => currService?.amenitiesId?.includes(am?._id));
    const amList = service?.BusinessService?.amenitiesId?.filter((am: any) => amenitiesList?.some((availableAmenity: any) => availableAmenity?.AmenitiesGlobalList?._id === am))?.map((amenity: any) => amenitiesList?.find((data: any) => data !== null && data?.available > 0 && data?.AmenitiesGlobalList?._id === amenity));
    
    // for package implementation
    const packServices = selectedClient?.packageService && selectedClient?.packageService?.length !== 0 ? selectedClient?.packageService?.find((packServices: any) => packServices?.serviceId === service?._id) : null;
    const remainingDuration = Number(packServices?.noOfServices) * 60 - packServices?.duration;

    const isAvailableInPack = packServices?.type === "duration" ? Number.parseInt(service?.duration) <= remainingDuration && packServices?.duration < Number(packServices?.noOfServices) * 60 : packServices?.type === "noOfServices" ? packServices?.usedCounter < Number(packServices?.noOfServices)  : false;
    const radeemedCount = packServices?.type === "duration" ? remainingDuration : packServices?.type === "noOfServices" ?  Number(packServices?.noOfServices) - packServices?.usedCounter : 0;        
        
    return (
        <Col md={12}>
            <div className={`${m.selectedServices}`}>
                <div className={m.top}>
                    <div className={m.left}>
                        <p className={m.makup}>{service?.BusinessService?.serviceName}</p>
                        <p className={m.makeupTime}>{`${service?.duration} ${`${!service?.seasonType ? "" : service?.seasonType[0]?.toLocaleUpperCase()}${!service?.seasonType ? "" : service?.seasonType?.split("Price")?.[0]?.slice(1)} Price`}`}</p>
                    </div>
                    <div className={m.middle}>
                        {currService?.radeemPackage && <p className={m.usd}>0 USD</p>}
                        {!currService?.radeemPackage && selectedPromoServices?.id?._id && <p className={m.cancel}>{service?.seasonPrice ? service?.seasonPrice : 0}USD</p>}
                        {!currService?.radeemPackage && <p className={m.usd}>{selectedPromoServices?.id?._id && service?.seasonPrice ? `${Number.parseInt(selectedPromoServices?.promotionalPrice)}` : service?.seasonPrice ? service?.seasonPrice : 0} USD</p>}
                    </div>
                    <div className={m.right}>
                        <img src={deleteIcon} style={{ cursor: "pointer" }} alt="delete" onClick={() => deleteService(service?._id, index)} />
                    </div>
                </div>
                <div className={m.bottom}>
                    <div className={`${st.formBox} ${m.options}`}>
                        {newTeamList?.length === 0 && !loading ? (
                            <p className={"errorMessage"}>
                                Team member is not available
                            </p>
                        ) : !errorTrig ? "" : !currService?.TeamMemberId?.[0] || currService?.TeamMemberId?.[0] === null && !loading ? (
                            <p className={"errorMessage"}>
                                Please select a team member
                            </p>
                        ) : ""}
                        <select className="form-select" value={currService?.TeamMemberId[0]} disabled={loading || newTeamList?.length === 0} onChange={(e: any) => addService("team", e.target.value, index, service?._id)}>
                            {loading ? (
                                <option value="">
                                    Checking Team Member's Availability
                                </option>
                            ) : (
                                <option value="">
                                    {newTeamList?.length === 0 ? "Team member is not available" : "Add a Team Member"}
                                </option>
                            )}

                            {newTeamList?.map((team: any) => {
                                return <option value={team?._id} key={team?._id}>{`${team?.firstName} ${team?.lastName}`}</option>
                            })}
                        </select>

                    </div>
                    <div className={`${st.formBox} ${m.options}`}>
                        <div className={`${st.booking}`}>
                            <Select
                                options={amList?.map((data: any) => {
                                    return { value: data?._id, label: data?.AmenitiesGlobalList?.itemName }
                                })}
                                value={amList?.filter((am: any) => selectedAmenities?.some((selectedAm: any) => selectedAm?._id === am?._id))?.map((data: any) => {
                                    return { value: data?._id, label: data?.AmenitiesGlobalList?.itemName }
                                })}
                                isMulti
                                placeholder="Select Amenities"
                                onChange={(e: any) => addService("amenity", e, index, service?._id)}
                            />
                        </div>
                    </div>

                    {!currService?.radeemPackage && <div className={`${st.formBox} ${m.options}`}>
                        <select className="form-select" disabled={promo?.length === 0} value={selectedServices?.[promotionServiceIndex]?.promotionId} onChange={(e: any) => addService("promotion", e.target.value, index, service?._id)}>
                            {promo?.length === 0 ? <option value="">Promotion is not available</option> : <option value="">Apply a promotion</option>}

                            {promo?.map((prmotion: any, index: any) => {
                                return (
                                    <option key={prmotion?._id} value={prmotion?._id}>{prmotion?.promotionName}</option>
                                )
                            })}
                        </select>
                    </div>}
                    {isAvailableInPack && <div className={`${m.packRadeem}`}>
                        <label className={`${st.checkbox} ${m.checkPlace}`}>
                            <input type="checkbox" checked={currService?.radeemPackage} onChange={(e: any) => addService("radeemedPackage", e.target.checked, index, service?._id, packServices?.packageId?.packageId)} />
                            <span className={`${st.checkmark} ${m.check}`}></span>
                            <span className={`${m.text}`}>This service is available in your package. {packServices?.type === "duration" ? `You can use it for (${radeemedCount} mins duration)` :`You can redeem it (${radeemedCount}) times`} Do you want to redeem it ?</span>
                        </label>
                    </div>}
                </div>
            </div>
        </Col>
    )
}

export default ListViewService
