import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "./SuccessPopup";
import HelpButon from "../HelpButon.tsx/HelpButton";
import HoverEyeContent from "../HoverEyeContent/HoverEyeContent";

const AddTeamTitle = (props: any) => {
  const { showTitle, handleTitleClose, userData, businessData, getTitle } = props;
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const handleSuccessModalClose = () => setShowSuccessModal(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [customerService, setCustomerService] = useState<any>(false);


  useEffect(() => {
    if (userData) {
      setTitle(userData?.teamTitle);
      setCustomerService(userData?.customerService)
    }
  }, [userData]);
  
  const submitHandler = async () => {
    if (title.trim() === '') {
      setError('This field is requered');
      return;
    }
    const body = {
      businessId: businessData?._id,
      teamTitle: title,
      customerService
    };
    const path = userData
      ? `updateTeamTitle/${userData?._id}`
      : "addTeamTitle";
    const method = userData ? "PATCH" : "post";
    const res = await httpRequest(path, method, body, "json");
    if (res.status) {
      getTitle();
      setMessage(res.message);
      setShowSuccessModal(true);
      titleCloseHandler()
    } else {
      setError(res.message)
    }
  };

  function titleChangeHandler(e: any) {
    setTitle(e.target.value);
    if (error) setError('');
  }

  function titleCloseHandler() {
    setError('');
    setTitle("");
    handleTitleClose();
  }

  return (
    <>
      <Modal
        centered
        show={showTitle}
        backdrop="static"
        keyboard={false}
        onHide={handleTitleClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts} ${m.redesign_modalCts}`}
      >
        <Modal.Header>
          <Modal.Title>
            {!userData ? "Add New Team Title" : ` Edit Team Title`}
          </Modal.Title>
          <button
            className={`${m.closeIcon}`}
            onClick={handleTitleClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body className="py-2">
          <Row>
            {/* <div>
              <Col md={12}>
                <div className="text-end">
                  <HelpButon number={31} type={"help"} className={"btn"} />
                </div>
              </Col>
            </div> */}
            <Col md={8}>
              <div className={` ${st.reFormBox}`}>
                <label className="form-label">Team Title <HoverEyeContent number={9068} /></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Team Title Name"
                  value={title}
                  onChange={titleChangeHandler}
                />
                {error !== '' && (
                  <p className={"errorMessage"}>{error}</p>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className={`${m.modal_para} mb-3`}>
                Select whether this is customer service staff or support staff. Note that support staff working hours do not apply to customer bookings.
              </div>
            </Col>
            <Col md={12}>
              <div className={`${st.formBox}`}>
                <label className={`${st.checkbox} mt-1 me-2`}>
                  <input type="checkbox" checked={customerService} onChange={(e: any) => setCustomerService(e.target.checked)} />
                  <span className={`${st.checkmark}`}></span>
                  <span className="ms-2">Provides Customer Service</span>
                </label>
                <HoverEyeContent number={9069} />
                {error !== '' && (
                  <p className={"errorMessage"}>{error}</p>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {/* <button className={`btn ${m.actionBtn}`} onClick={submitHandler}>
            Add Team Title
          </button>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={(e) => {
              titleCloseHandler();
            }}
          >
            Cancel
          </button> */}
          <div className={`${st.formBox} ${m.formSign} mt-1 pt-1 mb-1`}>
            <div className={`${st.btnsGroup} ${m.formSignGroup} mt-0`}>
              <button
                className={`btn w-100 ${st.btn4} ${m.btnGray}`}
                style={{ minWidth: 'auto' }}
                onClick={submitHandler}
              >
                {userData ? 'Update Team Title' : 'Add Team Title'}
              </button>
              <button
                className={`btn w-100 ${st.btn1} ${m.btnGray} ${m.btnGrayLight}`}
                style={{ minWidth: 'auto' }}
                onClick={(e) => {
                  titleCloseHandler();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <SuccessPopup
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={handleSuccessModalClose}
        message={message}
      />
    </>
  );
};

export default AddTeamTitle;
