import { useState } from "react";
import ServicesNotes from "../Modals/ServicesNotes"
import { useSelector } from "react-redux";

export default function HelpButon(props: any) {
    const {data, style = {},number = null, type = null, className = null,} = props;
    const [showServicesNotes , setShowServicesNotes] = useState(false);
    const handleServicesNotesClose = () => setShowServicesNotes(false);

    const help = useSelector((state: any) => state?.helpAndDocuments)
    const screenHelp:any = help?.helpDocuments?.find((item: any) => Number(item?.screenNumber) === number)


    // const screenHelp = number !== null ? help?.helpDocuments?.find((item: any) => Number(item?.screenNumber) === number) : null;
    // const helpData = screenHelp || data;
    console.log(screenHelp,"screenHelp")

    return (
        <>
            <ServicesNotes type={type} show={showServicesNotes} handleServicesNotesClose={handleServicesNotesClose} data={screenHelp} />
            {screenHelp?.details && screenHelp?.isActive && <button onClick={() => setShowServicesNotes(true)} className={`${className} btn`} style={{border:"1px solid #00000038", padding:"2px 10px", fontSize:"14px", color:"#212529"}}>
                Help
            </button>
            }
        </>

    )
}