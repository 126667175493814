import React, { useEffect, useRef, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Row } from "react-bootstrap";
import { Switch as CustomSwitch } from "../../../components/Website/Forms";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import CustomTimeSelect from "../../../constants/CustomTimeSelect";
import { timeValues, weekValues } from "../../../constants/Constants";
import { disablePastDate } from "../../../Utils/Funcs";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { deleteIcon, editButton } from "../../../assets/images";
import { v4 as uuidv4 } from 'uuid';
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

interface FormValues {
    [key: string]: string;
}

export default function OffPeakHours(props: any) {
    const { handleStepsFormData, editBranchData, loading } = props;
    const offPeakRef: any = useRef()
    const navigate = useNavigate();
    const { register, setValue, control, handleSubmit, watch, formState: { errors }, reset } = useForm<FormValues>();
    const [editId, setEditId] = useState<string | null>(null);


    const [isSameEveryDay, setIsSameEveryDay] = useState(false);
    const [dayWiseOff, setDayWiseOff] = useState<any>([]);

    const [offPeakList, setOffPeakList] = useState<any>([])

    useEffect(() => {
        if (editBranchData) {
            reset();
            setOffPeakList(editBranchData?.offPeakHours)
        }
    }, [editBranchData]);

    useEffect(() => {
        if (dayWiseOff?.length !== 0) {
            dayWiseOff?.map((item: any, index: number) => {
                item?.value && setValue(`${item.day}from`, "");
                item?.value && setValue(`${item.day}to`, "");
            })
        }
    }, [dayWiseOff])

    const handleSwitchChange = (e: any) => {
        let isChecked = e.target.checked
        let fromVal = isChecked ? watch('from', '') : '';
        let toVal = isChecked ? watch('to', '') : '';
        weekValues.forEach((item: any, index: number) => {
            if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
                return
            }
            setValue(`${item.label}from`, fromVal);
            setValue(`${item.label}to`, toVal);
        });
        setIsSameEveryDay(e.target.checked);

    };

    function handleTimeChange() {
        if (isSameEveryDay) {
            let fromVal = watch('from', '');
            let toVal = watch('to', '');
            weekValues.forEach((item: any, index: number) => {
                if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
                    return
                }
                if (fromVal) setValue(`${item.label}from`, fromVal);
                if (toVal) setValue(`${item.label}to`, toVal);
            });
        }
    }


    const onSubmit: SubmitHandler<FormValues> = (data, index) => {
        const id = editId || uuidv4();
        let workingHours: any = [];
        weekValues.forEach((elem: any, index: any) => {
            workingHours.push({
                dayName: elem.label,
                from: dayWiseOff?.[index]?.value ? "Not applicable" : data[`${elem.label}from`],
                to: dayWiseOff?.[index]?.value ? "Not applicable" : data[`${elem.label}to`],
            });
        });

        const offPeakData = { isSameEveryDay: isSameEveryDay, from: data.from, to: data.to, shift: workingHours, _id: id };

        const filterOffPeakList = offPeakList?.filter((item: any) => item?._id !== id);

        setOffPeakList([...filterOffPeakList, offPeakData]);
        setIsSameEveryDay(false);
        setValue(`from`, "");
        setValue(`to`, "");

        setDayWiseOff([]);
        reset();
        weekValues.forEach((elem: any, index: any) => {
            let updateDayWiseOff: any = [...dayWiseOff];
            updateDayWiseOff[index] = {
                day: elem?.label,
                value: false
            };
            setDayWiseOff(updateDayWiseOff);
            setValue(`${elem?.label}from`, "");
            setValue(`${elem?.label}to`, "");
        });

        setEditId(null);
        offPeakRef?.current?.scrollIntoView({ behaviour: "smooth" });
    };

    const skipStep = (e: any) => {
        e.preventDefault();
        // handleStepsFormData("prevStep", "team")
        navigate(-1)
    }

    const resetOffPeak = () => {
        let allData: any = []
        handleStepsFormData(allData, 'step5', true);
    }

    const handleSave = () => {
        handleStepsFormData(offPeakList, 'step5');
    }



    const handleEditTime = (index: any) => {
        const item = offPeakList[index];
        setEditId(item._id);

        item.shift.forEach((shiftItem: any) => {
            setValue(`${shiftItem.dayName}from`, shiftItem.from === "Not applicable" ? "" : shiftItem.from);
            setValue(`${shiftItem.dayName}to`, shiftItem.to === "Not applicable" ? "" : shiftItem.to);
        });
        setDayWiseOff(item.shift.map((shiftItem: any) => ({ day: shiftItem.dayName, value: shiftItem.from === "Not applicable" })));

        if (item.isSameEveryDay) {
            setIsSameEveryDay(item.isSameEveryDay);
            setValue(`from`, item.from);
            setValue(`to`, item.to);
        }
    };

    function handleDeleteTime(i: number) {
        const filterItem = offPeakList?.filter((item: any, index: number) => index !== i);
        setOffPeakList(filterItem)
    }


    return (
        <>
            <Row>
            <Col md={12}>
                  <div className="text-end">
                    <HelpButon number={35} type={"help"} className={"btn"} />
                  </div>
                </Col>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                        <h2>Off Peak Hours</h2>
                        <button className={`${cx.resetButton} btn`} onClick={resetOffPeak}>Reset Off Peak Hours</button>
                    </div>
                </Col>

            </Row>
            <Row>
                <Col md={12}>
                    <div className={`${cx.everyDay}`}>
                        <div className={`${cx.everyDaySwith} align-items-center`} >
                            <label>Same every day</label>
                            <Switch
                                checked={isSameEveryDay}
                                onChange={handleSwitchChange}
                            />
                        </div>
                        <div
                            className={`${cx.everyDaySelector} d-flex gap-3 mt-2`}
                        >
                            <div className={`${st.formBox}`} onClick={handleTimeChange}>
                                <Controller
                                    name="from"
                                    control={control}
                                    rules={{ required: isSameEveryDay }}
                                    render={({ field }) => {
                                        let filteredTimeValues: any;
                                        if (isSameEveryDay) {
                                            // filteredTimeValues = timeValues?.filter((time) => time.value >= editBranchData?.sameEveryDay?.from && time.value <= editBranchData?.sameEveryDay?.to);
                                            filteredTimeValues = timeValues
                                        }
                                        return (
                                            <CustomTimeSelect value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                                        )
                                    }}
                                />
                                {errors?.from?.type === "required" && (
                                    <p className="errorMessage">required*</p>
                                )}
                            </div>

                            <div className={`${st.formBox}`} onClick={handleTimeChange}>
                                <Controller
                                    name="to"
                                    control={control}
                                    rules={{ required: isSameEveryDay }}
                                    render={({ field }) => {
                                        let filteredTimeValues;
                                        if (isSameEveryDay) {
                                            filteredTimeValues = timeValues?.filter((item: any) => item.value >= watch('from', ''))
                                        }
                                        return (
                                            <CustomTimeSelect value={field.value} onChange={field.onChange}
                                                timeValues={filteredTimeValues}
                                                required={true} />
                                        )
                                    }}
                                />
                                {errors?.to?.type === "required" && (
                                    <p className={"errorMessage"}>required*</p>
                                )}
                            </div>
                            <button type='submit' className={`btn ${cx.saveBtn}`} onClick={handleSubmit(onSubmit)}>
                                Save
                            </button>
                        </div>
                    </div>
                </Col>

                {weekValues.map((item: any, index: number) => {
                    const notApplicableDay = dayWiseOff?.find((notItem: any) => notItem?.day === item?.label)
                    return (
                        <Col xl={3} lg={4} md={6} key={item.value}>
                            <div className={cx.timeSelect}>
                                <div className={`${st.formBox}`}>
                                    <label className="form-label">{item.label}</label>
                                    <Controller
                                        name={`${item.label}from`}
                                        control={control}
                                        rules={{ required: notApplicableDay ? false : true }}
                                        render={({ field }) => {
                                            let filteredTimeValues : any = [];
                                            
                                                filteredTimeValues = timeValues
                                            
                                            return (
                                                <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px", minWidth: "84px" }} disabled={dayWiseOff?.[index]?.value} value={field?.value} onChange={field.onChange}
                                                    timeValues={filteredTimeValues} required={true} />
                                            )
                                        }}
                                    />
                                    {errors[`${item.label}from`]?.type === "required" ? (
                                        <span className="errorMessage">required*</span>
                                    ) : <span>&nbsp;</span>
                                    }
                                </div>
                                <div className={`${st.formBox}`}>
                                    {/* <label className={cx.emptyLabel} role="img"></label> */}
                                    <div className="d-flex align-items-center justify-content-end" style={{ height: '30px' }}>
                                        <label className="form-label ms-2 text-nowrap">N/A</label>
                                        <div style={{ transform: "translate(5%, -11%)" }}>
                                            {/* <label className={`${st.checkbox}`}>
                                                <input type="checkbox"
                                                    checked={dayWiseOff?.[index]?.value}
                                                    onChange={(e: any) => {
                                                        let updateDayWiseOff: any = [...dayWiseOff];
                                                        updateDayWiseOff[index] = {
                                                            day: item?.label,
                                                            value: e.target.checked
                                                        }
                                                        setDayWiseOff(updateDayWiseOff)
                                                    }} />
                                                <span className={`${st.checkmark}`}></span>
                                            </label> */}

                                            <Switch
                                                checked={dayWiseOff?.[index]?.value}
                                                onChange={(e: any) => {
                                                    let updateDayWiseOff: any = [...dayWiseOff];
                                                    updateDayWiseOff[index] = {
                                                        day: item?.label,
                                                        value: e.target.checked
                                                    }
                                                    setDayWiseOff(updateDayWiseOff)
                                                }}
                                            // inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                        {/* <Switch

                                            checked={dayWiseOff?.[index]?.value}
                                            onChange={(e: any) => {
                                                let updateDayWiseOff: any = [...dayWiseOff];
                                                updateDayWiseOff[index] = {
                                                    day: item?.label,
                                                    value: e.target.checked
                                                }
                                                setDayWiseOff(updateDayWiseOff)
                                            }}
                                            color={!dayWiseOff?.[index]?.value ? "#7F8699" : "#1AB192"}
                                            backgroundColor="#fff"
                                            key={index}
                                            id={`same switch-${index}`}
                                        /> */}
                                    </div>
                                    <Controller
                                        name={`${item.label}to`}  // This should match your form data structure
                                        control={control}
                                        rules={{ required: notApplicableDay ? false : true }}
                                        render={({ field }) => {
                                            let filteredTimeValues:any = []
                                            
                                                filteredTimeValues = timeValues.filter((time) => time.value >= watch(`${item.label}from`, ''));
                                            
                                            // const filteredTimeValues = timeValues.filter((time) => time.value >= branchStartEndTime?.from && time.value <= branchStartEndTime?.to);
                                            return (
                                                <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px" }} disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                                            );
                                        }}
                                    />
                                    {errors[`${item.label}to`]?.type === "required" ? (
                                        <span className="errorMessage">required*</span>
                                    ) : <span>&nbsp;</span>
                                    }
                                </div>
                            </div>
                        </Col>
                    )
                })
                }
            </Row >

            <div className={cx.offList} ref={offPeakRef}>
                {offPeakList?.map((item: any, i: any) => {
                    return (
                        <Row key={i}>
                            <Col md={12} >
                                <div className={`${cx.everyDay}`}>
                                    <div className={`${cx.everyDaySwith} align-items-center`} >
                                        <label>Same every day</label>
                                        <Switch
                                            checked={item?.isSameEveryDay}
                                            disabled={true}
                                        />
                                    </div>
                                    {item?.isSameEveryDay && <div className={cx.displayTime}>
                                        <div className={`${st.formBox}`}>
                                            {/* <label className="form-label">{shiftItem?.dayName}</label> */}
                                            <div
                                                className={`position-relative d-flex align-items-center gap-5 ${cx.inputBackground}`}
                                            >
                                                <div className={cx.editContainer}>
                                                    <p>
                                                        {item?.from || "not set"}
                                                    </p>
                                                </div>
                                                <div className={cx.editContainer}>
                                                    <p>
                                                        {item?.to || "not set"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </Col>

                            <Col md={12}>
                                <Row>
                                    {item?.shift?.map((shiftItem: any, i: number) => {

                                        return (
                                            <Col lg={3} md={6} key={i}>
                                                <div className={cx.displayTime}>
                                                    <div className={`${st.formBox}`}>
                                                        <label className="form-label">{shiftItem?.dayName}</label>
                                                        <div
                                                            className={`position-relative d-flex align-items-center gap-5 ${cx.inputBackground}`}
                                                        >
                                                            <div className={cx.editContainer}>
                                                                <p>
                                                                    {shiftItem?.from === "Not applicable" ? "N/A" : shiftItem?.from}
                                                                </p>
                                                            </div>
                                                            <div className={cx.editContainer}>
                                                                <p>
                                                                    {shiftItem?.to === "Not applicable" ? "N/A" : shiftItem?.to}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                    <Col>

                                        <div className={cx.action}>
                                            <div className={`${cx.btn} mt-3`}>
                                                <span><img src={editButton} onClick={() => handleEditTime(i)} alt="edit"
                                                /></span>
                                                <span><img src={deleteIcon} alt="delete" onClick={() => handleDeleteTime(i)}
                                                /></span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <div style={{borderBottom:'2px solid #eff0f5'}}></div>
                        </Row>
                    )
                })}
            </div>


            <Row>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => handleStepsFormData('prevStep', 'non_opHrs')}
                            >
                                Back
                            </button>
                            <button type='submit' onClick={skipStep} className={`btn ${st.btn1} ${cx.twoBtn}`} >
                                Skip
                            </button>
                            <button type='submit' className={`btn ${st.btn4}`} onClick={handleSave} disabled={loading} >
                                {loading ? <LoadingBtn /> : "Next"}
                            </button>

                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
