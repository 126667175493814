import React, { useEffect } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import cx from "./index.module.scss";
import { footerMobile, iconFacebook, wellnezza, welllogo, iconInstagram, iconTwiter, iconYoutube, logofooter, logow, singapore } from "../../../assets/images";
import { FaAngleDown, FaApple, FaGoogle } from "react-icons/fa";

const Footer = (props: any) => {
  return (
    <>
      <footer className={`${cx.footerSection}`}>
        <Container>
          <Row>
            <Col md={5} lg={5}>
              <Row>
                <Col md={12} lg={12} xl={6} className={`${cx.widget1}`}>
                  <img src={welllogo} alt="logo" className={`${cx.footerLogo}`} />
                  <p>One solution, one software. Trusted by the best in the beauty and wellness industry</p>
                  <NavLink to="#" className={`btn ${cx.appBtns}`}>
                    Get the App <FaApple style={{ fontSize: '20px' }} />
                  </NavLink>
                  <NavLink to="#" className={`btn ${cx.appBtns}`}>
                    Get the App <FaGoogle />
                  </NavLink>
                </Col>
                <Col md={6} lg={6} className={`${cx.widget2}`}>
                  <img src={wellnezza} alt="footerMobile" className={`${cx.footerMobile}`} />
                </Col>
              </Row>
            </Col>

            <Col md={7} lg={7} className={`${cx.rightFt}`}>
              <Row>
                <Col md={12} lg={6} className={`${cx.widget3}`}>
                  <h5 className={`${cx.titleFt}`}>SIGN UP FOR OURNEWSLETTER</h5>
                  <div className={`${cx.newsLetter}`}>
                    <input type="text" className="form-control" placeholder="Enter your email" />
                    <button className={`btn ${cx.submitBtn}`}>Sign Up</button>
                  </div>
                </Col>
                <Col md={6} lg={3} className={`${cx.widget4}`}>
                  <h5 className={`${cx.titleFt}`}>Useful links </h5>
                  <ul className={`${cx.menuList}`}>
                    <li><NavLink to="#">Careers at Salon</NavLink></li>
                    <li><NavLink to="#">Blogs</NavLink></li>
                    <li><NavLink to="#">Support</NavLink></li>
                    <li><NavLink to="#">Privacy Policy</NavLink></li>
                    <li><NavLink to="#">Terms of service</NavLink></li>
                  </ul>
                </Col>
                <Col md={6} lg={3} className={`${cx.widget4}`}>
                  <h5 className={`${cx.titleFt}`}>For business</h5>
                  <ul className={`${cx.menuList}`}>
                    <li><NavLink to="#">Pricing</NavLink></li>
                    <li><NavLink to="#">Card terminals</NavLink></li>
                  </ul>
                </Col>
              </Row>
              <div className={`${cx.socailBox}`}>
                <h5>Connect with us</h5>
                <ul className={`${cx.socailUl}`}>
                  <li>
                    <NavLink to="#">
                      <img src={iconFacebook} alt="facebook" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <img src={iconInstagram} alt="instagram" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <img src={iconTwiter} alt="twiter" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <img src={iconYoutube} alt="youtube" />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <div className={`${cx.copyright}`}>
          <Container>
            <Row>
              <Col md={6}>
                <Dropdown className={`${cx.currencyChange}`}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                    <FaAngleDown className={`${cx.arrow}`} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul>
                      <li>
                        <button className={`${cx.actionBtn}`}>
                          <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                        </button>
                      </li>
                      <li>
                        <button className={`${cx.actionBtn}`}>
                          <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                        </button>
                      </li>
                      <li>
                        <button className={`${cx.actionBtn}`}>
                          <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                        </button>
                      </li>
                      <li>
                        <button className={`${cx.actionBtn}`}>
                          <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                        </button>
                      </li>
                      <li>
                        <button className={`${cx.actionBtn}`}>
                          <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                        </button>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={6} className="text-end">
                <p>Salon 2023 all rights reserved</p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
};

export default Footer;
