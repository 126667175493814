import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { BsArrowDown } from "react-icons/bs";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { Switch } from "../../../components/Website/Forms";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { shiftValues, timeValues, weekValues } from "../../../constants/Constants";
import CustomTimeSelect from "../../../constants/CustomTimeSelect";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { useSelector } from "react-redux";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import Select from 'react-select';
import { httpRequest } from "../../../Apis/commonApis";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

interface FormValues {
  [key: string]: string;
}

export default function BranchTab(props: any) {
  const { handleStepsFormData, branchList, loading, editTeamData } = props;
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const { register, control, setValue, watch, handleSubmit, formState: { errors }, reset, clearErrors } = useForm<FormValues>();
  // const [sameWorkingh, setWorkingHrs] = useState<any>({})
  const [showErrorModal, setShowErrorModal] = useState("");


  const handleAlertModalClose = () => setShowErrorModal("");
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
  
  // useEffect(() => {
  //   const selectedBranch = branchList.find((item: any) => item._id === branchId);
  //   // const selectedBranchOpHrs: any = selectedBranch?.workingHours;
  //   const sameWorkingh = selectedBranch?.sameEveryDay;
  //   setWorkingHrs(sameWorkingh)

  // }, [branchId])

  const [everyDaySwitch, setEveryDaySwitch] = useState(false);
  const [teamTitleList, setTeamTitleList] = useState([]);
  const [selectedTeamTitles, setSelectedTeamTitles] = useState<any>([]);

  const [selectedRole, setSelectedRole] = useState<any>([]);

  useEffect(() => {
    if (editTeamData) {
      reset();

      const filterHours = editTeamData.hoursDetail?.find((item:any) => item?.branchId?._id === branchId)
      filterHours && filterHours?.shift?.forEach((elem: any) => {
        elem.workingHours?.forEach((item: any) => {
          setValue(`${item.day}${elem.shiftNo}From`, item.from);
          setValue(`${item.day}${elem.shiftNo}to`, item.to);
        })
      });

      const roles = editTeamData?.Role?.map((item: any) => ({ ...item, branchId: item?.branch?._id, role: item?.role?.map((roleItem: any) => roleItem?._id) }))
      setSelectedRole(roles)
      const selectedRole = editTeamData?.Role?.find((item: any) => item?.branch?._id === branchId);
      if (selectedRole?.branch?._id) {
        setSelectedTeamTitles(selectedRole?.role?.map((item: any) => ({ label: item?.teamTitle, value: item?._id, isActive: true })))
      }
    }
  }, [editTeamData]);
  

  const existHour = editTeamData?.hoursDetail?.find((item:any) => item?.branchId?._id === branchId)


  const getTitle = async () => {
    const res = await httpRequest(
      `getTeamTitle?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      const options: any = res?.data?.map((item: any) => ({
        value: item._id,
        label: item.teamTitle,
        isActive: item.isActive,
      })).filter((elm: any) => elm.isActive === true);
      setTeamTitleList(options);
    }
  };

  useEffect(() => {
    if (businessData) {
      getTitle();
    };
  }, [businessData]);

  const [roleError, setRoleError] = useState<any>(false)

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    let shiftData: any = [];
    let sameEvDayShifts: any = []
    shiftValues.forEach(element => {
      sameEvDayShifts.push(
        {
          shiftNo: element.label,
          from: data[`${element.label}From`],
          to: data[`${element.label}to`],
        });
      let workingHrs: any = [];
      weekValues.forEach((elem) => {
        workingHrs.push({
          day: elem.label,
          from: data[`${elem.label}${element.label}From`],
          to: data[`${elem.label}${element.label}to`],
        })
      });
      shiftData.push(
        {
          shiftNo: element.label,
          workingHours: workingHrs,
        })
    });
    let selectedBranch = branchList.find((item: any) => item._id === branchId);
    let assignedArr = [];

    const filterSelectedRole = selectedRole?.find((item: any) => item?.branchId === selectedBranch?._id)
    const filterExistRole = editTeamData?.Role?.find((item: any) => item?.branch?._id === selectedBranch?._id);

    const filterRole = { branchName: selectedBranch?.branchName, accessManager: filterExistRole?.accessManager ? filterExistRole?.accessManager : filterSelectedRole?.accessManager ? filterSelectedRole?.accessManager : "", branchId: selectedBranch?._id, role: selectedTeamTitles?.map((item: any) => item?.value) }

    const filterUnSelectedRole = selectedRole?.filter((item: any) => item?.branchId !== selectedBranch?._id)

    setSelectedRole([...filterUnSelectedRole, filterRole])
    assignedArr.push({
      branchName: selectedBranch.branchName,
      branchId: branchId,
      isSameEveryday: {
        status: everyDaySwitch,
        shift: sameEvDayShifts
      },
      shift: shiftData,
    });
    if (selectedTeamTitles?.length === 0) {
      setRoleError(true)
      return
    }
    submitAsignBranchData(assignedArr);
  };

  function submitAsignBranchData(assignedArr: any) {
    let filterWorkingHours = editTeamData?.hoursDetail ? editTeamData?.hoursDetail?.filter((item: any) => item?.branchId?._id !== branchId) : []
    
    if (filterWorkingHours?.length !== 0) {
      filterWorkingHours = filterWorkingHours?.map((item: any) => ({ ...item, branchId: item?.branchId?._id }))
    }
    const newAssignArr = [...assignedArr, ...filterWorkingHours]
    const filterRole = selectedRole?.filter((item: any) => item?.branchId !== branchId)
    const selectedRoleData = selectedRole?.find((item: any) => item?.branchId === branchId)
    const Role = [...filterRole?.map((item: any) => {
      return { branchName: item?.branchName, branch: item?.branchId, role: item?.role, accessManager: item?.accessManager ? item?.accessManager : ""  }
    }), { branchName: selectedRoleData?.branchName, branch: branchId, role: selectedTeamTitles?.map((item: any) => item?.value), accessManager: selectedRoleData?.accessManager }]
    let hoursDetail = { hoursDetail: JSON.stringify(newAssignArr), Role: JSON.stringify(Role) };

    handleStepsFormData(hoursDetail, 'step2');
  }

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedTeamTitles(selectedOptions);
    clearErrors("role");
  };

  const handleShiftSwitchChange = (event: any) => {
    let isChecked = event.target.checked;
    shiftValues?.forEach(elem => {
      let fromVal = isChecked ? watch(`${elem.label}From`, '') : '';
      let toVal = isChecked ? watch(`${elem.label}to`, '') : '';
      weekValues.forEach(item => {
        setValue(`${item.label}${elem.label}From`, fromVal);
        setValue(`${item.label}${elem.label}to`, toVal);
      });
    });

    setEveryDaySwitch(isChecked);
    // if (sameWorkingh?.isSameEveryDay == true) {
    // } else {
    //   handleAlertModalShow(`Error, The branch is not open at the same time every day`);
    // }
  };

  function handleshiftValChange() {
    if (everyDaySwitch) {
      shiftValues?.forEach(elem => {
        let fromVal = watch(`${elem.label}From`, '');
        let toVal = watch(`${elem.label}to`, '');
        weekValues.forEach(item => {
          if (fromVal) setValue(`${item.label}${elem.label}From`, fromVal);
          if (toVal) setValue(`${item.label}${elem.label}to`, toVal);
        });
      });
    }
  }

  let branchlist: any = branchList.filter((item: any) => branchId === item?._id);

  const selectedBranchOpHrs = branchlist?.find((item: any) => item._id)?.workingHours;

  

  return (
    <>
      <ErrorPopup
        showErrorModal={showErrorModal}
        handleErrorModalClose={handleAlertModalClose}
      />
      <Row>
      <Col md={12}>
        <div className="text-end">
          <HelpButon number={11} type={"help"} className={"btn"} />
        </div>
      </Col>
        <Col md={12}>
          <div className={`${st.formBox} ${cx.locationSelect}`}>
            <h2>{existHour?.shift?.length && existHour?.shift?.length !==0 ? "Maintained hours" : "Working hours"}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <div className={`${st.formBox}`}>
            <label className="form-label">Assign Location</label>
            <select
              className="form-select"
              disabled
            >
              <option value={branchlist[0]?._id} key={branchlist[0]?._id}>{branchlist[0]?.branchName}</option>
            </select>
            {selectedBranchOpHrs?.length < 1 ? (<span className="errorMessage">Please add branch working hours first</span>) : ""}
          </div>

        </Col>

        <Col md={3}>
          <div className={`${st.formBox}`}>
            <label className="form-label">Role</label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={teamTitleList}
              value={selectedTeamTitles}
              onChange={handleSelectChange}
            />
            {roleError && selectedTeamTitles?.length === 0 && (
              <p className={"errorMessage"}>Please add roles</p>
            )}
          </div>
        </Col>


      </Row>
      <div className={cx.containerBox}>
        <>
          <div className={`${cx.workingRow} mt-4`}>
            <div className={`${cx.colms} ${cx.first} ${cx.firstcolm_minwidth}`}>
              <h2 className={cx.shifHeading}>Working Hours</h2>
              <div className={`${st.formBox} ${cx.everyDaySelector} mt-3`}>
                <label className="form-label">Same everyday</label>
                <Switch
                  checked={everyDaySwitch}
                  onChange={handleShiftSwitchChange}
                  color={!everyDaySwitch ? "#7F8699" : "#1AB192"}
                  backgroundColor="#fff"
                  key={2}
                  id={"amenities"}
                />
              </div>
            </div>
            {<div className={cx.shiftBox}>
              {shiftValues.map((item: any) => {
                return (<div className={`${cx.colms} ${cx.colms_minwidth}`} key={item.value}>
                  <h2 className={cx.shifHeading}>{item.value}</h2>
                  <div className={cx.shift}>
                    <div className={`${st.formBox} mb-0`} onClick={handleshiftValChange}>
                      <Controller
                        name={`${item.label}From`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => {
                          let filteredTimeValues: any = [];
                          if (everyDaySwitch == true) {
                            filteredTimeValues = timeValues
                          }
                          return (
                            <CustomTimeSelect  style={{paddingRight:"31px"}} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                          )
                        }
                        }
                      />
                      {errors[`${item.label}From`]?.type === "required" ? (
                        <span className="errorMessage">required*</span>
                      ) : <span>&nbsp;</span>
                      }
                    </div>
                    <div className={`${st.formBox} mb-0`} onClick={handleshiftValChange}>
                      <Controller
                        name={`${item.label}to`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => {
                          let filteredTimeValues: any = [];
                          if (everyDaySwitch == true) {
                            filteredTimeValues = timeValues
                          }
                          return (
                            <CustomTimeSelect style={{paddingRight:"31px"}} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                          );
                        }}
                      />
                      {errors[`${item.label}to`]?.type === "required" ? (
                        <span className="errorMessage">required*</span>
                      ) : <span>&nbsp;</span>
                      }
                    </div>
                  </div>
                </div>
                )
              })}
            </div>}
          </div>
        </>
        {<>
          {weekValues.map((item: any) => {
            const branchStartEndTime = selectedBranchOpHrs?.find((timeVal: any) => timeVal.dayName === `${item.label}`);
            return (<div className={`${cx.workingRow}`} key={item.value}>
              <div className={`${cx.colms} ${cx.first} ${cx.firstcolm_minwidth}`}>
                <div
                  className={`${st.formBox} ${cx.everyDaySelector} ${cx.days} align-items-start`}
                >
                  <label className="form-label">{item.label}</label>

                </div>
              </div>
              <div className={cx.shiftBox}>
                {shiftValues.map((elem: any) => {
                  return (<div className={`${cx.colms} ${cx.colms_minwidth}`} key={elem.value}>
                    <div className={cx.shift}>
                      <div className={`${st.formBox} mb-0`}>
                        <Controller
                          name={`${item.label}${elem.label}From`} // This should match your form data structure
                          control={control}
                          defaultValue="" // Initial value
                          rules={{ required: elem.label === 'shift1' }}
                          render={({ field }) => {
                            const filteredTimeValues = timeValues.filter((time) => time.value >= branchStartEndTime?.from && time.value <= branchStartEndTime?.to);
                            return (
                              <CustomTimeSelect extraField={"Not applicable"} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                            );
                          }}
                        />
                        {errors[`${item.label}${elem.label}From`]?.type === "required" ? (
                          <span className="errorMessage">required*</span>
                        ) : <span>&nbsp;</span>
                        }
                      </div>
                      <div className={`${st.formBox} mb-0`}>
                        <Controller
                          name={`${item.label}${elem.label}to`} // This should match your form data structure
                          control={control}
                          defaultValue="" // Initial value
                          rules={{ required: elem.label === 'shift1' }}
                          render={({ field }) => {
                            const filteredTimeValues = timeValues.filter((time) => time.value >= watch(`${item.label}${elem.label}From`, ''));
                            return (
                              <CustomTimeSelect extraField={"Not applicable"} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                            );
                          }}

                        />
                        {errors[`${item.label}${elem.label}to`]?.type === "required" ? (
                          <span className="errorMessage">required*</span>
                        ) : <span>&nbsp;</span> 
                        }
                      </div>
                    </div>
                  </div>
                  )
                })}
              </div>
            </div>
            )
          })}
        </>}
      </div>
      <Row>
        <Col md={12}>
          <div className={`${st.formBox}`}>
            <div className={`${st.btnsGroup}`}>
              <button
                className={`btn ${st.btn1}`}
                onClick={() => handleStepsFormData('prevStep', 'info')}
              >
                Back
              </button>
              <button className={`btn ${st.btn4}`} onClick={handleSubmit(onFormSubmit)} disabled={loading}>
                {loading ? (<LoadingBtn />
                ) : (
                  'Next'
                )}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
