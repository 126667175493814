import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import cx from "./index.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Logo, singapore, welllogo } from "../../../assets/images";
import { FiMenu } from "react-icons/fi";
import { FaAngleDown } from "react-icons/fa";

interface headerMain {
  showHeaderClass?: string
}

export default function Header(props: headerMain) {


  let { showHeaderClass } = props
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <>
      <header className={`${cx.main_header}  ${scroll ? cx.affix : ""} ${showHeaderClass}`} >
        <Navbar className={`navbar navbar-expand-lg ${cx.ak_menu}`}>
          <div className={`${cx.mobile_topbar}`}></div>
          <Container>
            <NavLink className="navbar-brand" to="/">
              <img src={welllogo} className={` ${cx.logo}`} />
            </NavLink>

            <button className={`navbar-toggler ${cx.mobile_menu}`} onClick={handleShow}>
              <FiMenu />
            </button>
            <Navbar.Collapse id="basic-navbar-nav"
              className={` ${show && cx.slide_effect}`}
            >
              <div className={`${cx.menu_box}`}>
                <Nav className="navbar-nav float-start">
                  <Dropdown className={`${cx.currencyChange}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                      <FaAngleDown className={`${cx.arrow}`} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <ul>
                        <li>
                          <button className={`${cx.actionBtn}`}>
                            <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                          </button>
                        </li>
                        <li>
                          <button className={`${cx.actionBtn}`}>
                            <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                          </button>
                        </li>
                        <li>
                          <button className={`${cx.actionBtn}`}>
                            <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                          </button>
                        </li>
                        <li>
                          <button className={`${cx.actionBtn}`}>
                            <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                          </button>
                        </li>
                        <li>
                          <button className={`${cx.actionBtn}`}>
                            <img src={singapore} className={`${cx.icon}`} alt="singapore" />  Singapore | USD
                          </button>
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
                <Nav className="navbar-nav float-end">
                  <li className="nav-item">
                    <NavLink to="#" onClick={handleClose}>Product</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="#" onClick={handleClose}>Contact Us</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/business/login" onClick={handleClose}>Log In / Sign In</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/business/signup" className={`btn ${cx.listBtn}`} onClick={handleClose}>LIST YOUR BUSINESS</NavLink>
                  </li>
                </Nav>
              </div>

              <div className={`${cx.hide_box} ${cx.mobile_close}`} onClick={handleClose}></div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>


    </>
  );
}

