import React from 'react'
import cx from './index.module.scss';
import { formatDate, formatDuration } from '../../../constants/Constants';
export default function SingleBooking({booking}: any) {
    const services = booking?.services?.map((service:any) => service?.serviceDetails?.serviceName);
    const totalDuration = booking?.services?.reduce((acc:any, service:any) => acc + Number.parseInt(service?.serviceId?.duration) ,0);
    const teamMember = booking?.services?.map((service:any) => service?.TeamMemberId?.length > 0 ? service?.TeamMemberId[0] : '')
    return (
        <div className={`${cx.dashboard_FlexBox}`}>
            <div className={`${cx.boxLeft}`}>
                <h4>{services?.join(', ')}</h4>
                <span className={`${cx.timing}`}>{formatDate(`${booking?.salectDate}T${booking?.salectTime}`, true)}</span>
            </div>
            <div className={`${cx.boxRight}`}>
                <p>{formatDuration(totalDuration)} {teamMember?.join() ? `with ${teamMember?.join(', ')}` : ''}</p>
                <span className={`${cx.price}`}>${booking?.finalPrice}</span>
            </div>
        </div>
    )
}
