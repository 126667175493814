import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import { deleteIcon, editIcon, openIcon } from "../../../assets/images";
import { Status } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";


const StatusButton = (props: any) => {
  let { params, getTeamMember } = props;
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [error, seterror] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isAnotherLocation =
    window.location.pathname.includes("business-setting");

  const deleteTeamMember = async () => {
    const res = await httpRequest(`deleteTeamMember/${params?.id}`, "DELETE", null, "json");
    if (res?.status) {
      props.getTeamMember();
    }
    else {
      seterror(res?.messaeg)
    }
  };

  const handleViewClick = () => {
    let redUrl = isAnotherLocation ? "/business/branch-teams/view-team" : "view-team";
    navigate(redUrl, { state: { teamId: params?.row?.id } });
  };

  const handleEditClick = () => {
    navigate("/business/branch-teams/edit-team/info", { state: { teamId: params?.row?.id } });
  };

  const canEditTeam = subModuleRoles?.['Branch Set Up']?.Team?.Edit;
  const canDeleteTeam = subModuleRoles?.['Branch Set Up']?.Team?.Delete;

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            {canEditTeam && <p
              className={`btn ${tb.edit}`}
              title="Edit"
            >
              <img src={editIcon} alt="edit" onClick={handleEditClick} />
            </p>}
            {/* <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to={isAnotherLocation ? "/business/teams/edit-team/info" : "edit-team/info"}
            >
              <img src={editIcon} alt="edit" />
            </NavLink> */}
          </li>
          <li>
            <p
              className={`btn ${tb.edit}`}
              title="view"
            >
              <img src={openIcon} alt="view" onClick={handleViewClick} />
            </p>
          </li>
          {canDeleteTeam && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>}
          <li>
            <Status status={params.row.isActive}
              api='updateTeamMember' id={params?.id}
              apiMethod='formdata'
              getLatest={getTeamMember} />
          </li>
        </ul>
      </div>

      <DeletePopup show={show} deleteTeamMember={deleteTeamMember}
        handleClose={handleClose} />
    </>
  );
};


export default function DataGridTable(props: any) {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let row: any = props?.teamMember?.map((val: any, index: any) => {
    let services = val.serviceId?.map((item: any) => item.serviceName);
    let branches = val.Role?.map((item: any) => item.branchName);
    const teamArr = val?.Role?.find((item:any) => item?.branch?._id === branchId)
    
    const teamTitle = teamArr ? teamArr?.role?.map((roleItem:any) => roleItem?.teamTitle) : []
    return {
      Sr_No: index + 1,
      id: val?._id,
      employeeId: val?.teamMemberIdNo,
      name: `${val?.firstName} ${val?.lastName}`,
      service: services.join(', '),
      teamTitle:teamTitle?.join(","),
      joiningDate: val.createdAt?.split('T')[0],
      branch: branches.join(', '),
      nickName: val?.nickName ? val?.nickName : "",
      createdBy: `${val?.createdBy?.firstName} ${val?.createdBy?.lastName}`,
      updatedBy: `${val?.updatedBy?.firstName} ${val?.updatedBy?.lastName}`,
      action: "",
      isActive: val.isActive,
    };
  });

  const columns = [
    { field: "Sr_No", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 220,
      renderCell: (params: any) => <StatusButton params={params} getTeamMember={props.getTeamMember} />,
    },
    { field: "employeeId", headerName: "Employee ID", flex: 1, minWidth: 110 },
    { field: "name", headerName: "Name", flex: 2, minWidth: 130 },
    { field: "nickName", headerName: "Nickname", flex: 2, minWidth: 120 },
    { field: "teamTitle", headerName: "Team Title", flex: 2, minWidth: 170 },
    { field: "service", headerName: "Service", flex: 2, minWidth: 130 },
    { field: "joiningDate", headerName: "Joining Date", flex: 2, minWidth: 120 },
    { field: "branch", headerName: "Branch", flex: 2, minWidth: 120 },
    // { field: "nickName", headerName: "Nickname", flex: 2, minWidth: 120 },

  ];

  return (
    <div className={`${tb.dataTable}`}>
      
      <div style={{ width: "100%" }}>
      <Col md={12}>
        <div className="text-end mb-2">
          <HelpButon number={9} type={"help"} className={"btn"} />
        </div>
      </Col>
      
        <DataGrid
          columns={columns}
          rows={row}
          autoHeight
          hideFooterPagination={false}
          rowHeight={52}
          checkboxSelection
          disableRowSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}
