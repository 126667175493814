import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { editBtn, editBtnBox, editIcon, plusV2 } from "../../../assets/images";
import m from "../Modals/Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import {
  Col,
  Modal, Row
} from "react-bootstrap";
import { MdClose } from 'react-icons/md';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const AddNewCategoryBtn = (props: any) => {
  const { handleCatSelect, selectedCatIds, style } = props;

  const location = useLocation();

  let { allBusCategories, businessData } = useSelector((state: any) => state.shareDataReducer);

  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showAddCategory, setAddCategoryShow] = useState(false);
  const handleAddCategoryClose = () => setAddCategoryShow(false);
  const handleAddCategoryShow = () => setAddCategoryShow(true);

  
  useEffect(() => {
    if (businessData) {
      if (location.pathname?.includes('business-setting')) {
        setFilteredCategories(allBusCategories);
      } else {
        let filteredArr = allBusCategories?.filter((item: any) => businessData?.businessCatagoryId?.includes(item._id));
        setFilteredCategories(filteredArr);
      }
    }
  }, [businessData, allBusCategories]);

  return (
    <>
      <li className={cx.addCat} style={style ? style : {}}>
      <img
            src={editIcon}
            alt="edit"
            onClick={handleAddCategoryShow}
            style={{
              cursor: "pointer",
              height: "20px",
              width: "20px",
              margin: "20px 10px"
              // marginBottom: "5px",
            }} />
        {/* <div
          className={`${st.iconImg} ${cx.add}`}
          onClick={handleAddCategoryShow}
        >
          <img src={editBtnBox} alt="add" />
          
          <img
            src={editIcon}
            alt="edit"
            onClick={handleAddCategoryShow}
            style={{
              cursor: "pointer",
              height: "20px",
              width: "20px",
              margin: "20px 10px"
              // marginBottom: "5px",
            }} />
        </div> */}
        {/* <h6>Edit</h6> */}
      </li>

      <Modal
        centered show={showAddCategory} scrollable onHide={handleAddCategoryClose} aria-labelledby="example-modal-sizes-title-sm" className={m.modalCts}>
        <Modal.Header>
          <Modal.Title>Select Business Category</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleAddCategoryClose}><MdClose /></button>
        </Modal.Header>
        <Modal.Body>

          <Row>
            {filteredCategories?.map((item: any) => {
              return (
                <Col md={6} key={item._id}>
                  <div
                    className={`${m.serviceCategory} ${selectedCatIds?.includes(item._id) ? m.active : ""
                      }`}
                    onClick={() => handleCatSelect(item._id)}
                  >
                    <img
                      src={item.Icon}
                      alt="massage"
                      className={`${m.select}`}
                    />
                    <span className={`${m.select}`}>
                      {item.BusinessCategoryName}
                    </span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Modal.Body>

        {/* <Modal.Footer>
          <button type="button" className={`btn ${m.cancelBtn}`}
            onClick={handleAddCategoryClose}>
            Close
          </button>
          <button type="button" className={`btn ${m.actionBtn}`}
            onClick={handleAddCategoryClose}>
            Submit
          </button>
        </Modal.Footer> */}
      </Modal>

      {/* <AddCategory showAddCategory={showAddCategory} handleAddCategoryClose={handleAddCategoryClose} /> */}
    </>
  );
};

export default AddNewCategoryBtn;
