import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

import { Col, Nav, Row, Tab } from "react-bootstrap";
import {
  hair,
  basicInfo,
  basicInfoA,
  massage,
  lashes,
  makeupfill,
  nails,
  waxfill,
  facilities,
  location,
  upArrow,
  cross,
  calendar,
  loacationA,
  ok,
  okA,
  alarmA,
  alarm,
  teamTab,
  teamTabA,
  leftArrow,
  rightArrow,
} from "../../../assets/images";
import BackArrrow from "../../../components/Business/Buttons/BackArrow";
import PhoneNumber from "../../../components/Website/Phonenumber/Phonenumber";
import { Switch } from "../../../components/Website/Forms";
import { BsArrowDown } from "react-icons/bs";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import AddTeamRouts from "./AddTeamRouts";
import { httpRequest } from "../../../Apis/commonApis";
import { getDynamicURIName } from "../../../Utils/Funcs";
import { useSelector } from "react-redux";

const AddBranchTeams = (props: any) => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );

  const [editTeamData, setEditTeamData] = useState<any>(null)
  const navigate = useNavigate();
  const Location = useLocation();
  const dynamicName = getDynamicURIName(Location.pathname);

  const [branchList, setBranchList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(dynamicName);

  const [showErrorModal, setShowErrorModal] = useState('');
  const handleAlertModalClose = () => setShowErrorModal('');
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData) {
      getBranch(businessData?._id);
    }
  }, [businessData]);

  const handleStepsFormData = (formdata: any, step: any) => {
    if (formdata === 'prevStep') {
      setActiveTab(step);
      navigate(step, { state: { teamId: Location.state.teamId }, replace: true });
      return;
    }
    if (step === 'step1') {
      if (Location.state) {
        updateTeamMember(formdata, 'branch');
      } else {
        createNewTeamMember(formdata);
      }
    } else if (step === 'step2') {
      updateTeamMember(formdata, 'services');
    } else if (step === 'step3') {
      updateTeamMember(formdata, 'leaves');
    } else if (step === 'step4') {
      createTeamMemberLeaves(formdata, step);
      // if (formdata.length > 0) {
      // } else {
      //   handleAlertModalShow(`Success, 'Team member details updated successfully`);
      //   setTimeout(() => {
      //     handleAlertModalClose();
      //     navigate(-1);
      //   }, 2000);
      // }
    }
    else if (step === "step5") {
      updateTeamMember(formdata, 'complete');
    }
  };

  const getTeamMemberData = async () => {
    let res = await httpRequest(`getAllTeamMembers?_id=${Location?.state?.teamId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setEditTeamData(res.data[0]);

    }
  };

  useEffect(() => {
    if (Location?.state?.teamId) {
      getTeamMemberData()
    }
  }, [Location?.state?.teamId, activeTab])

  const createNewTeamMember = async (allData: any) => {
    const formData = new FormData();
    for (const key in allData) {
      if (allData.hasOwnProperty(key)) {
        formData.append(key, allData[key]);
      }
    }
    formData.append("businessId", businessData?._id);
    formData.append("branchId", branchId)
    setLoading(true);
    const res = await httpRequest("addTeamMember", "post", formData, "formdata");
    if (res.status) {
      setActiveTab('branch');
      navigate('branch', { state: { teamId: res.data._id }, replace: true });
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
    setLoading(false);
  }

  const updateTeamMember = async (allData: any, step: string) => {
    const formdata = new FormData();
    for (const key in allData) {
      if (allData.hasOwnProperty(key)) {
        formdata.append(key, allData[key]);
      }
    }
    if (allData.hoursDetail) {
      let hrsDetail = JSON.parse(allData?.hoursDetail);
      let selectedBranchId = hrsDetail?.map((item: any) => item.branchId);
      formdata.append("branchId", JSON.stringify(selectedBranchId));
    }
    setLoading(true);
    const res = await httpRequest(`updateTeamMember/${Location.state.teamId}`, 'PATCH', formdata, "formdata");
    if (res.status) {
      if (step === "complete") {
        handleAlertModalShow(`Success, Team member details updated successfully`);
        setTimeout(() => {
          handleAlertModalClose();
          navigate(-1);
          return
        }, 1000);
      }
      setActiveTab(step);
      navigate(step, { state: { teamId: res.data._id }, replace: true });
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
    setLoading(false);
  }

  const createTeamMemberLeaves = async (amdata: any, step: any = "") => {
    setLoading(true);
    let body = {
      businessId: businessData._id,
      branchId: [branchId],
      teamMemberId: Location?.state?.teamId,
      leaves: amdata,
    }
    const res = await httpRequest("createLeaves", "post", body, "json");
    if (res.status) {
      if (step === "step4") {
        setActiveTab("accesscontrol");
        navigate("accesscontrol", { state: { teamId: Location?.state?.teamId }, replace: true });
      } else {
        handleAlertModalShow(`Success, Team member details updated successfully`);
        setTimeout(() => {
          handleAlertModalClose();
          navigate(-1);
        }, 2000);
      }
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
    setLoading(false);
  }

  const getBranch = async (bId: any) => {
    const res = await httpRequest(`getBranch?businessId=${bId}`, "get", null, "json");
    if (res.status) {
      setBranchList(res?.data);
    }
  };

  const handleTabSelect = (tab: any) => {
    if (Location.pathname.includes('edit-team')) {
      setActiveTab(tab);
      navigate(`${tab}`, { state: { teamId: Location?.state?.teamId }, replace: true });
    }
  };

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            {!Location.pathname.includes('edit-team') ? <h5>
              {/* <BackArrrow /> */}
              {Location.pathname.includes('edit-team') ? 'Edit' : 'Add'} team member
            </h5> : (
              <h5>{`${editTeamData?.firstName || ""} ${editTeamData?.lastName || ""}`}</h5>
            )}
          </div>
          <div className={`${st.rightInfo}`}>
            {Location.pathname.includes('edit-team') && <div style={{ color: "#3e8575", borderRadius: "20px 20px 20px 20px", padding: "5px 50px"}}>Edit Mode</div>}
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="info">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="branch">
                  <img src={location} alt="info" className={`${st.defalut}`} />
                  <img src={loacationA} alt="info" className={`${st.active}`} />
                  Branch Assignment
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="services">
                  <img src={teamTab} alt="info" className={`${st.defalut}`} />
                  <img src={teamTabA} alt="info" className={`${st.active}`} />
                  Services
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="tabNew4">
                  <img src={ok} alt="info" className={`${st.defalut}`} />
                  <img src={okA} alt="info" className={`${st.active}`} />
                  Working hours
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="leaves">
                  <img src={alarm} alt="info" className={`${st.defalut}`} />
                  <img src={alarmA} alt="info" className={`${st.active}`} />
                  Leaves
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="accesscontrol">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Access Control
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              
              <Tab.Pane eventKey={activeTab}>
                <Routes>
                  <Route path={activeTab}
                    element={<AddTeamRouts dynamicName={dynamicName} handleStepsFormData={handleStepsFormData} loading={loading} />} />
                </Routes>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <ErrorPopup
          showErrorModal={showErrorModal}
          handleErrorModalClose={handleAlertModalClose}
        />
      </section>
    </>
  );
};

export default AddBranchTeams;
