import React from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap';
import cx from './index.module.scss';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import DataCard from './DataCard';
import BookingCard from './BookingCard';
import SingleBooking from './SingleBooking';
import { convertDecimalTimeTotalMinutes, formatDuration } from '../../../constants/Constants';

export default function TodayData({ homepageData, loading }: { homepageData: any, loading: boolean }) {
    const bookedMinutes = convertDecimalTimeTotalMinutes(homepageData?.count?.workedHours)
    const availableMinutes = (homepageData?.count?.totalHours * 60) - bookedMinutes;
    const totalSales = homepageData?.booking?.reduce((acc:any, val:any) => {
        if(val?.bookingStatus === 'Completed') {
            return acc + +val?.finalPrice
        }
        return acc
    }, 0)
    return (
        <>
            <Row>
                <Col lg={3} md={6}>
                    <DataCard title='Total Booking revenue' value={`SGD ${homepageData?.bookingReport?.allTotalBookingAmount}`} statusPercentage={'20'} />
                </Col>
                <Col lg={3} md={6}>
                    <DataCard title='Sales Completed' value={`SGD ${totalSales}`} statusPercentage={'12.5'} />
                </Col>
                <Col lg={3} md={6}>
                    <DataCard title='Available Slot' value={`${formatDuration(availableMinutes)}`} statusPercentage={'12.5'} />
                </Col>
                <Col lg={3} md={6}>
                    <DataCard title='Booked slot' value={`${formatDuration(bookedMinutes)}`} statusPercentage={'12.5'} />
                </Col>
            </Row>
            <Row>
                <Col lg={4} className="my-2">
                    <BookingCard title='Appointment activity'>
                        {homepageData?.booking?.map((booking: any) => {
                            return (
                                <SingleBooking key={booking?._id} booking={booking} />
                            )
                        })}
                    </BookingCard>
                </Col>
                <Col lg={4} className="my-2">
                    <BookingCard title='New Bookings'>
                        {homepageData?.booking?.map((booking: any) => {
                            return (
                                <SingleBooking key={booking?._id} booking={booking} />
                            )
                        })}
                    </BookingCard>
                </Col>

                {/* team member card*/}
                <Col lg={4} className="my-2">
                    <div className={`${cx.activity_mainBox}`}>
                        <h3>Team Member Availibity</h3>

                        <div className={`${cx.dashboard_FlexBox}`}>
                            <div className={`${cx.boxLeft}`}>
                                <h4>Fenil</h4>
                                <span className={`${cx.availableProgress}`}> Available for <span className={`${cx.availability}`}>12/8</span> Hours</span>
                            </div>
                            <div className={`${cx.boxRight}`}>
                                <div className={`${cx.progressBox}`}>
                                    <ProgressBar now={60} />
                                </div>
                            </div>
                        </div>
                        <div className={`${cx.dashboard_FlexBox}`}>
                            <div className={`${cx.boxLeft}`}>
                                <h4>Fenil</h4>
                                <span className={`${cx.availableProgress}`}> Available for <span className={`${cx.availability}`}>12/8</span> Hours</span>
                            </div>
                            <div className={`${cx.boxRight}`}>
                                <div className={`${cx.progressBox}`}>
                                    <ProgressBar now={60} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
