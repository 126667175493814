
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { httpRequest } from '../../../Apis/commonApis';
import Basic from "./Basic";
import PricingTab from "./PricingTab";
import { useNavigate } from "react-router-dom";
export default function BranchPackageRoutes(props: any) {
    const navigate = useNavigate();
    const { dynamicName, handleStepsFormData, loading ,apiError} = props;
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const location = useLocation();

    const [editServiceData, setEditServiceData] = useState<any>(null);
    const [packageData, setPackageData] = useState<any>(null)

    useEffect(() => {
        if (businessData) {
            // getServiceCategory();
        }
    }, [businessData]);

    useEffect(() => {
        if (location.state && businessData) {
            getPackages();
        }
    }, [businessData, location.state]);
      
    useEffect(() => {
        const prevBId = localStorage.getItem("currentBranchId");
        if (branchId && branchId !== prevBId) {
            localStorage.setItem('currentBranchId',branchId);
            navigate(-1);
        }
    }, [branchId]);
    const getPackages = async () => {
        let res = await httpRequest(`getPackages?_id=${location.state?.packageId}&businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setPackageData(res?.data[0]);
        }
    };

    return (
        <>
            {dynamicName === "basicInfo" &&
                <Basic
                    handleStepsFormData={handleStepsFormData}
                    editPackageData={packageData}
                    loading={loading}
                    apiError={apiError}
                />
            }
            {dynamicName === "pricing" &&
                <PricingTab
                    handleStepsFormData={handleStepsFormData}
                    editPackageData={packageData}
                    loading={loading}
                />
            }
        </>
    )
}