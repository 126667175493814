import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { deleteIcon, editButton, upArrow } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { AiFillCamera } from "react-icons/ai";
import { accessRoleValues } from "../../../constants/Constants";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import Select from 'react-select';
import InfoModal from "../../../components/Business/Modals/InfoModal";
import { el } from "@fullcalendar/core/internal-common";
import { RxCross2 } from "react-icons/rx";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

const AccessBranchControl = (props: any) => {
  const { handleStepsFormData, loading, editTeamData } = props;
  const [showInfoModal, setShowInfoModal] = useState(false);

  const handleInfoModalClose = () => setShowInfoModal(false);

  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  let { branchId } = useSelector((state: any) => state.BranchReducer);

  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [errorTrig, setErrorTrig] = useState(false);
  const [profile, setProfile] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [allBranchList, setAllBranchList] = useState([]);
  const [selectedBrachRoles, setSelectedBrachRoles] = useState<any>([]);
  const [teamTitleList, setTeamTitleList] = useState([]);
  const [customErr, setCustomErr] = useState('');
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [editRoleIndex, setEditRoleIndex] = useState<number>(-1);
  const [isGlobalEdit, setIsGlobalEdit] = useState<boolean>(false);
  const [selectedTeamTitles, setSelectedTeamTitles] = useState<any>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const {
    register: registerRole,
    handleSubmit: handleSubmitRole,
    formState: { errors: errorsRole },
    setValue: setRoleValue,
    setError,
    clearErrors,
    watch,
    reset: resetRole,
  } = useForm();

  useEffect(() => {
    if (editTeamData) {
      reset();
      setProfileImage(editTeamData?.image);
      setPhone(`${editTeamData?.mobile?.includes("+") ? "" : "+"}${editTeamData?.mobile}`);
      setValue('isPublic', editTeamData?.isPublic)
      setValue('isBooking', editTeamData?.isBooking)
      setValue('isAvilableForBooking', editTeamData?.isAvilableForBooking)
      let branchArr: any = [];
      // if (!editTeamData.Role[0]?.branch) {
      //   setValue('globalRole', editTeamData.Role[0]?.role?._id);
      //   setValue('accessManager', editTeamData.Role[0]?.accessManager);
      //   setIsGlobal(true);
      // }
      editTeamData.Role?.forEach((elem: any) => {

        let roleArr = elem?.role[0]?._id ? elem?.role.map((item: any) => item._id) : elem?.role;
        branchArr.push({
          branchName: elem.isGlobal ? 'Global' : elem.branch?.branchName,
          branch: elem.isGlobal ? 'global' : elem?.branch?._id,
          role: roleArr,
          accessManager: elem?.accessManager,
        });
      });
      setSelectedBrachRoles(branchArr);

    }
  }, [editTeamData]);


  useEffect(() => {
    if (businessData) {
      getBranches();
      getTitle();
    };
  }, [businessData]);


  const selectedBranch = watch("branch");


  const selectedBranchIds = selectedBrachRoles?.filter((item: any) => item?.accessManager !== "").map((item: any) => item?.branch);
  const filteredBranchList = allBranchList.filter((item: any) => item?._id === branchId && !selectedBranchIds?.includes(item._id) || item?._id === watch('branch', ''));

  const filterRole = selectedBrachRoles?.filter((item: any) => item.accessManager !== "" && item?.branch === branchId)


  // if selected branch global then open a info popup 
  useEffect(() => {
    if (selectedBranch === "global") {
      setShowInfoModal(true)
    }
  }, [selectedBranch])

  const submitHandler = async (data: any) => {
    let finalRoleArr = [];
    if (isGlobal) {
      finalRoleArr.push({
        allBranch: true,
        role: selectedTeamTitles,
        accessManager: data?.accessManager
      })
    } else {
      if (selectedBrachRoles.length < 1) {
        setCustomErr('addbranch');
        return;
      }
      finalRoleArr = selectedBrachRoles;
    }
    let basicinfo: any = {};
    basicinfo.Role = JSON.stringify(finalRoleArr);
    handleStepsFormData(basicinfo, "step5");

  };

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
    if (res.status) {
      let allBranches = res.data;
      allBranches.reverse();
      setAllBranchList(allBranches);
    }
  }

  const getTitle = async () => {
    const res = await httpRequest(
      `getTeamTitle?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      const options: any = res?.data?.map((item: any) => ({
        value: item._id,
        label: item.teamTitle,
        isActive: item.isActive,
      })).filter((elm: any) => elm.isActive === true);
      setTeamTitleList(options);
    }
  };

  const addBranchHandler = (postdata: any) => {
    setCustomErr('');
    // if (selectedTeamTitles.length < 1) setError('role', { type: 'required' });

    // let selectedBranch: any = allBranchList.find((item: any) => item._id === postdata?.branch);

    let newArray = [...selectedBrachRoles]
    const branchIndex = newArray?.findIndex((item: any) => item?.branch === postdata?.branch)
    newArray[branchIndex] = {
      ...newArray?.[branchIndex],
      accessManager: postdata?.accessManager
    }
    // let roledata = {
    //   branchName: selectedBranch ? selectedBranch?.branchName : 'Global',
    //   branch: postdata?.branch,
    //   role: selectedTeamTitles.map((item: any) => item.value),
    //   accessManager: postdata?.accessManager,
    // }
    // if (editRoleIndex >= 0) {
    //   newArray.splice(editRoleIndex, 1);
    //   newArray.splice(editRoleIndex, 0, roledata);
    // } else {
    //   newArray.push(roledata);
    // }

    setEditRoleIndex(-1);
    setIsGlobalEdit(false);
    setSelectedBrachRoles(newArray);
    setSelectedTeamTitles([]);
    setRoleValue('branch', '');
    clearErrors("role");
    setRoleValue('accessManager', '');
  };

  const skipStep = (e: any) => {
    e.preventDefault();
    navigate(-1)
  }

  function handleRemoveBranch(branchId: string) {
    let newSelected: any = [...selectedBrachRoles];
    newSelected = newSelected.map((item: any) => {
      if (item?.branch === branchId) {
        return {
          ...item,
          accessManager: ""
        }
      }
      return item
    })

    setSelectedBrachRoles(newSelected);
  }

  function handleEditBranchRole(values: any, id: number) {
    resetRole();
    if (values.branch === 'global') {
      setIsGlobalEdit(true);
    } else {
      setIsGlobalEdit(false);
    }
    // let filteredTitles: any = teamTitleList.filter((titles: any) => values.role?.includes(titles.value));
    // setSelectedTeamTitles(filteredTitles);
    setRoleValue('branch', values.branch);
    setRoleValue('accessManager', values.accessManager);
    setEditRoleIndex(id);
  }


  // const selectedBranchIds = selectedBrachRoles?.filter((item: any) => item?.accessManager !== "").map((item: any) => item?.branch);  
  // const filteredBranchList = allBranchList.filter((item: any) => item?._id === branchId && !selectedBranchIds?.includes(item._id) || item?._id === watch('branch', ''));

  // const filterRole = selectedBrachRoles?.filter((item: any) => item.accessManager !== "" && item?.branch === branchId)
  return (
    <>
      {/* <InfoModal showInfoModal={showInfoModal} handleInfoModalClose={handleInfoModalClose} message="Do not forget to maintain working hours for each assigned branch" /> */}
      <Row>
        <Col md={12}>
          <div className="text-end">
            <HelpButon number={14} type={"help"} className={"btn"} />
          </div>
        </Col>
        <Col md={12}>
          <div className={`${st.formBox} m-0 `}>
            <label
              className="form-label"
              style={{
                fontWeight: "600",
                fontSize: "16px",
                margin: "0",
              }}
            >
              Access Control
            </label>
          </div>
        </Col>
      </Row>
      {(
        <>
          <Row>
            <Col lg={3} md={6}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Branch</label>
                <select
                  className="form-select"
                  {...registerRole("branch", {
                    required: true,
                  })}
                >
                  <option value="">--Select--</option>
                  {/* {(!isGlobalAdded || isGlobalEdit) && <option value="global">Global</option>} */}
                  {filteredBranchList?.map((item: any) => {
                    return (
                      <option value={item._id} key={item._id}>
                        {item.branchName}
                      </option>
                    );
                  })}
                </select>
                {errorsRole?.branch?.type === "required" && (
                  <p className={"errorMessage"}>This field is required</p>
                )}
              </div>
            </Col>

            <Col lg={3} md={6}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Access Manager</label>
                <select
                  className="form-select"
                  {...registerRole("accessManager", {
                    required: true,
                  })}
                >
                  {accessRoleValues.map((item: any, index: number) => {
                    return (
                      <option key={item.value} value={item.value}>{item.label}</option>
                    )
                  })}
                </select>
                {errorsRole?.accessManager?.type === "required" && (
                  <p className={"errorMessage"}>This field is required</p>
                )}
              </div>
            </Col>
            <Col lg={3} md={4}>
              <div className={`${st.formBox}`}>
                <label htmlFor="" className="form-label opcaity-0"></label>
                <button
                  className={`${cx.addButton} singleBtn w-auto`}
                  style={{ marginTop: "4px" }}
                  onClick={handleSubmitRole(addBranchHandler)}
                >
                  {editRoleIndex >= 0 ? 'Edit' : 'Save'}
                </button>
                {customErr?.includes('addbranch') && (
                  <p className={"errorMessage"}>Please add atleast one branch</p>
                )}
              </div>
            </Col>
          </Row>


          {filterRole?.length > 0 && <Row>
            <Col md={12}>
              <div className={`${cx.divider}`}></div>
            </Col>
            <Col lg={3} md={6}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Branch</label>
              </div>
            </Col>

            <Col lg={3} md={6}>
              <div className={`${st.formBox}`}>
                <label className="form-label">Access Manager</label>
              </div>
            </Col>
            <Col lg={3}>
              <div className={cx.action}>
              </div>
            </Col>
          </Row>}

          {filterRole?.map((item: any, index: any) => {
            let newValue;
            let filteredTitles: any = teamTitleList.filter((titles: any) => item.role?.includes(titles.value));

            if (filteredTitles?.length < 1) {
              filteredTitles = editTeamData?.Role?.filter((titles: any) => item.role?.includes(titles?.role?.[0]?._id)
              );
              newValue = filteredTitles[0]?.role?.map((item: any) => item?.teamTitle).join(',')
            } else {
              newValue = filteredTitles.map((item: any) => item?.label).join(',')
            }



            return (
              <div key={index}>
                <Row>
                  <Col lg={3} md={6}>
                    <div className={`${st.formBox}`}>
                      <input type="text" disabled className="form-control" value={item.branchName} />
                    </div>
                  </Col>

                  <Col lg={3} md={6}>
                    <div className={`${st.formBox}`}>
                      <input type="text" disabled className="form-control" value={item.accessManager} />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className={cx.action}>
                      <div className={`${cx.btn}`}>
                        <span><img src={editButton} alt="edit"
                          onClick={() => handleEditBranchRole(item, index)} /></span>
                        <span><img src={deleteIcon} alt="delete"
                          onClick={() => handleRemoveBranch(item?.branch)} /></span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })
          }
        </>
      )}

      <Row>
        <Col md={12}>
          <div className={`${st.formBox}`}>
            <div className={`${st.btnsGroup}`}>
              <button
                className={`btn ${st.btn1}`}
                onClick={() => handleStepsFormData('prevStep', 'leaves')}
              >
                Back
              </button>
              <button type='submit' onClick={skipStep} className={`btn ${st.btn1} ${cx.twoBtn}`} >
                Skip
              </button>
              <button className={`btn ${st.btn4}`} onClick={handleSubmit(submitHandler)} disabled={loading}>
                {loading ? (<LoadingBtn />
                ) : (
                  'Next'
                )}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AccessBranchControl;
