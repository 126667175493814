import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { NavLink } from "react-router-dom";
import { Accordion, Col, Modal, Row, Table } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import moment from 'moment';
import {
  call,
  clockA,
  creditCardPlus,
  dateIcon,
  emailGreen,
  starBox,
  test,
  testBig,
} from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import SuccessPopup from "./SuccessPopup";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input';
import { convertTimeToMinute, formatDateFormat } from "../../../constants/Constants";
import { convertMinutesToTime } from "../../../Utils/Funcs";

const PaymentModal = (props: any, type: any = "") => {
  let { show, handlePaymentBookingClose, data, getBooking, handleCustomerClose, bundleGroup = null, serviceType = "" } = props;
  const initialState: any = {
    otherChargeMsg: "",
    otherChargeAmt: "",
    discountType: "",
    discount: "",
    paymentStatus: "Success",
    paymentType: "",
    paymentRef: "",
  }
  const [errorTrig, setErrorTrig] = useState(false);
  const [otherCharges, setOtherCharges] = useState<any>(initialState);
  const [message, setMessage] = useState<any>("")
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false)
  const handleSuccessModalClose = () => {
    setMessage("")
    setShowSuccessModal(false)
  };

  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const stylistId: any = [];
  data?.services?.forEach((data: any) => {
    data?.TeamMemberId?.forEach((team: any) => {
      stylistId?.push(team?._id)
    })
  })

  const [team, setTeam] = useState<any>([]);

  const getTeam = async (id: any) => {
    let res = await httpRequest(`getAllTeamMembers?branchId=${id}&businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      const filterTeam = res?.data?.filter((team: any) => stylistId?.includes(team?._id));
      setTeam(filterTeam)
    }
  }

  useEffect(() => {
    if (branchId) {
      getTeam(branchId)
    }
  }, [branchId, data]);

  const totalDuration = data?.services?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.serviceId?.duration), 0)


  let withoutDeductedPrice: any = serviceType === "service" ? data?.services?.reduce((acc: any, service: any) => acc + Number(service?.serviceId?.seasonPrice), 0)
    :
    bundleGroup?.reduce((acc: any, bundle: any) => {
      const bundlePrice = bundle?.[Object.keys(bundle)?.[0]][0]?.bundleId?.finalPrice;
      return acc + Number.parseFloat(bundlePrice)
    }, 0)

  let totalDeductedPrice: any = serviceType === "service" ? data?.services?.reduce((acc: any, service: any) => {
    if (service?.promotionId?._id) {
      return acc + Number.parseFloat(service?.promotionId?.priceId?.find((item: any) => item?.id === service?.serviceId?._id)?.promotionalPrice)
    } else {
      return acc + Number(service?.serviceId?.seasonPrice)
    }

  }, 0) : bundleGroup?.reduce((acc: any, bundle: any) => {
    const bundlePromotionalPrice = bundle?.[Object.keys(bundle)?.[0]][0]?.bundlePromotionalPrice;
    const bundlePrice = bundle?.[Object.keys(bundle)?.[0]][0]?.bundleId?.finalPrice;
    if (bundlePromotionalPrice) {
      return acc + Number.parseFloat(bundlePromotionalPrice)
    } else {
      return acc + Number.parseFloat(bundlePrice)
    }
  }, 0)

  const noPromotion = totalDeductedPrice === withoutDeductedPrice
  const totalPrice: number = !noPromotion ? totalDeductedPrice : withoutDeductedPrice

  let otherChargePrice: number = totalPrice;
  if (otherCharges?.otherChargeAmt) {
    otherChargePrice += +otherCharges?.otherChargeAmt;
  }


  let finalPrice: any = +otherChargePrice;

  if (otherCharges?.discount) {
    if (otherCharges?.discountType === "%") {
      finalPrice = finalPrice - (finalPrice * (+otherCharges?.discount / 100))
    } else {
      finalPrice = finalPrice - +otherCharges?.discount
    }
  }

  finalPrice = finalPrice?.toFixed(2)


  useEffect(() => {
    if (data) {
      setOtherCharges({
        otherChargeMsg: data?.otherChargeMsg,
        otherChargeAmt: data?.otherChargeAmt,
        discountType: data?.discountType,
        discount: data?.discount,
        paymentStatus: data?.paymentStatus,
        paymentType: data?.paymentType,
        paymentRef: data?.paymentRef,
      })
    }
  }, [data]);


  const onsubmit = async (e: any) => {
    e.preventDefault();
    if (otherCharges?.otherChargeMsg !== "" && otherCharges?.otherChargeAmt === "") {
      setErrorTrig(true)
      return
    }



    if (otherCharges?.paymentStatus === "Success" && (otherCharges?.paymentType === "" || otherCharges?.paymentRef === "")) {
      setErrorTrig(true)
      return
    }
    const finalData = {
      ...otherCharges,
      businessId: businessData?._id,
      clientId: data?.Client?._id,
      clientUid: data?.Client?.cuId,
      branchId,
      finalPrice
    }
    setLoading(true)
    const res = await httpRequest(`updateBooking/${data?._id}`, "Patch", finalData, "json");
    if (res.status) {
      getBooking && getBooking();
      setShowSuccessModal(true)
      setMessage("Payment status updated successfully")
      setTimeout(() => {
        handlePaymentBookingClose();
        handleCustomerClose()
      }, 1000)
    }
    setLoading(false);
  }


  const handleInputChange = (e: any, discountType: any) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (!isNaN(numericValue) && numericValue <= 100 && discountType === "%") {
      setOtherCharges({ ...otherCharges, discount: value });
    } else if (!isNaN(numericValue) && numericValue <= +totalPrice && discountType !== "%") {
      setOtherCharges({ ...otherCharges, discount: value });
    }
    else if (value === '') {
      setOtherCharges({ ...otherCharges, discount: '' }); // Allow empty value
    }
  };



  const bookingId = data ? `${data?.bookedBy === "online" ? data?.booking_Id || "" : data?.businessBookingId || ""}` : ""

  const startArr: any = data?.services?.[0]?.startTime?.split(":");

  const starthours: any = Number?.parseInt(startArr?.[0]);
  const startMinutes: any = Number?.parseInt(startArr?.[1]);

  // const totalDuration: any = bookingDetils?.services?.reduce((acc: any, val: any) => Number(acc) + Number?.parseInt(val?.serviceId?.duration), 0)
  let newMinutes = startMinutes + totalDuration
  let endHour = starthours + Math.floor(newMinutes / 60);
  let endMinute = newMinutes % 60;

  const isBundle: any = data?.services?.some((item: any) => item?.bundleId);

  

  return (
    <>
      <SuccessPopup
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={handleSuccessModalClose}
        message={message}
      />
      <Modal
        centered
        scrollable
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handlePaymentBookingClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts} ${m.profile_serviceDiscount_coupon} ${showSuccessModal ? m.hide : ""}`}
      >
        <Modal.Header className="p-2">
          <Modal.Title>Payment {`(booking #${bookingId})`}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: '19px' }}>
          <div className={m.slot}>
            <div className={m.bottom}>
              <div className={`${m.content} ${m.v2} px-0`}>
                <div className={`${m.contactInfo}`}>
                  <div className={`${m.left}`}>
                    <div className={m.imageBox}>
                      <span>{`${data?.Client?.firstName?.[0]?.toLocaleUpperCase()}${data?.Client?.lastName ? data?.Client?.lastName?.[0]?.toLocaleUpperCase() : ""}`}</span>
                    </div>
                    <div className={`${m.content}`}>
                      <h3>{`${data?.Client?.firstName} ${data?.Client?.lastName || ""}`} {data?.guestname ? ` (${data?.guestname})` : ""}</h3>
                      <p>{data?.Client?.country || ""}</p>
                    </div>
                  </div>
                </div>

                <div className={`${m.by_services_flex}  ${m.card_phoneMain}`}>
                    <div className={`${m.service} m-0`}>
                      <div className={m.serviceEntry}>
                        <img src={creditCardPlus} alt="card" />
                        <span>{data?.Client?.clientAccountNo || ""}</span>
                      </div>
                      {data?.Client?.mobile && <div className={m.serviceEntry}>
                        <div className={`${m.servicePhoneInput}`}>
                          <PhoneInput
                            defaultCountry="SG"
                            disabled
                            value={`+${data?.Client?.mobile}` || ""}
                            international
                            placeholder="Enter phone number" onChange={function (value?: Value): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        </div>
                      </div>}
                    </div>
                  </div>

                <div className={`${m.by_services_ClrBorder} mb-3`}>
                  <div className={`${m.by_services_flex}`}>
                    <div className={`${m.service} m-0`}>
                      <div className={m.serviceEntry}>
                        <img style={{ height: "30px", width: "30px" }} src={dateIcon} alt="schedule" />
                        <span>{formatDateFormat(data?.salectDate || "")}</span>
                      </div>
                      <div className={m.serviceEntry}>
                        <img style={{ height: "24px", width: "24px" }} src={clockA} className={`${m.v2}`} alt="time" />
                        <span>{data?.services?.[0]?.startTime}  - {endHour < 10 ? `0${endHour}` : endHour} : {endMinute < 10 ? `0${endMinute}` : endMinute}</span>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {isBundle && (
            <>
              <div className={`${m.newBundle_Main} mt-0`}>
                {bundleGroup?.map((item: any, i: number) => {
                  const bundlePromotionalPrice = item?.[Object.keys(item)?.[0]][0]?.bundlePromotionalPrice || ""

                  return (
                    <React.Fragment key={i}>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>{Object.keys(item)?.[0] || ""}</h5>
                        <p className="d-flex align-items-center gap-2 m-0">
                          <span style={bundlePromotionalPrice ? { color: "red", textDecoration: "line-through" } : { color: "#1e8c76" }}>SGD{item?.[Object.keys(item)?.[0]][0]?.bundleId?.finalPrice}</span>
                          {bundlePromotionalPrice && <span style={{ color: "#1e8c76" }}>{bundlePromotionalPrice}</span>}
                        </p>
                      </div>
                      {item?.[Object.keys(item)?.[0]]?.map((service: any, s: any) => {
                        const startMinutes = convertTimeToMinute(service?.startTime)
                        const endMinutes: any = startMinutes + Number.parseInt(service?.serviceId?.duration);
                        const toTime: any = convertMinutesToTime(endMinutes)
                        const firstName = service?.TeamMemberId?.[0]?.firstName || ""
                        const lastName = service?.TeamMemberId?.[0]?.lastName || "";
                        const nickName = service?.TeamMemberId?.[0]?.nickName || "";


                        return (
                          <div key={s}>
                            <div className={`${m.boxes}`}>
                              <div className={`${m.newBundleServices}`}>
                                <div className={`${m.tops}`}>
                                  <p className={`${m.specialy}`}>{service?.serviceCategoryDetails?.serviceCategoryName} : {service?.serviceDetails?.serviceName}</p>
                                  <p className={`${m.times}`}>{service?.serviceId?.priceText || ""} {service?.serviceId?.duration} - {`SGD ${service?.serviceId?.seasonPrice}`} </p>
                                </div>

                                <div className={`${m.by_services_imgBox}`}>
                                  <div className={`${m.by_services_main} justify-content-between`}>
                                    {/* <div className={`${m.img_text}`}>
                                                {service?.TeamMemberId?.length !== 0 ? service?.TeamMemberId?.[0]?.firstName?.[0]?.toLocaleUpperCase() : "UA"}
                                              </div> */}
                                    <div className={`${m.details} ms-0`}>
                                      <h3>{firstName ? "Served By" : "Unassigned"} {firstName} {lastName} {nickName}</h3>

                                    </div>
                                    <div className={`${m.details}`}>
                                      <h3 style={{ color: '#212529' }}> {convertMinutesToTime(startMinutes)} - {toTime} ({service?.serviceId?.duration})</h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </React.Fragment>
                  )
                })}

              </div>
            </>
          )}
          {!isBundle && (
            <>
              <div className={`${m.newBundle_Main} mt-0`}>
                {data?.services?.map((service: any, s: any) => {
                  const startMinutes = convertTimeToMinute(service?.startTime)
                  const endMinutes: any = startMinutes + Number.parseInt(service?.serviceId?.duration);
                  const toTime: any = convertMinutesToTime(endMinutes)
                  const firstName = service?.TeamMemberId?.[0]?.firstName || ""
                  const lastName = service?.TeamMemberId?.[0]?.lastName || "";
                  const nickName = service?.TeamMemberId?.[0]?.nickName || "";

                  const promotion = service?.promotionId?.promotionName || ""
                  const promotionalPriceObj = service?.promotionId ? service?.promotionId?.priceId?.find((item: any) => item?.id === service?.serviceId?._id) : null

                  return (
                    <div key={s}>
                      <div className={`${m.boxes}`}>
                        <div className={`${m.newBundleServices}`}>
                          <div className={`${m.tops}`}>
                            <p className={`${m.specialy}`}>{service?.serviceCategoryDetails?.serviceCategoryName} : {service?.serviceDetails?.serviceName}</p>
                            <p className={`${m.times}`}>{service?.serviceId?.priceText || ""} {service?.serviceId?.duration} - {`SGD ${service?.serviceId?.seasonPrice}`} </p>
                          </div>

                          <div className={`${m.by_services_imgBox}`}>
                            <div className={`${m.by_services_main} justify-content-between`}>
                              {promotion && <div className={`${m.details} ms-0`}>
                                <h3>Applied Promotion : {promotion}</h3>
                                <h3>Discount Price : {`SGD ${promotionalPriceObj?.promotionalPrice}` || ""}</h3>
                              </div>}

                            </div>
                            <div className={`${m.by_services_main} justify-content-between`}>
                              <div className={`${m.details} ms-0`}>
                                <h3>{firstName ? "Served By" : "Unassigned"} {firstName} {lastName} {nickName}</h3>
                              </div>
                              <div className={`${m.details}`}>
                                <h3 style={{ color: '#212529' }}> {convertMinutesToTime(startMinutes)} - {toTime} ({service?.serviceId?.duration})</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  )
                })}


              </div>
            </>
          )}

          {/* {serviceType === "service" && <div className={`${m.serviceSection}`}>
            <table>
              <thead>
                <tr>
                  <th>Services</th>
                  <th>Duration</th>
                  <th>Promotion</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {data?.services?.map((service: any) => {
                  const promotionPrice = service?.promotionId?.priceId?.find((item: any) => item?.id === service?.serviceId?._id)
                  return (
                    <React.Fragment key={service?.serviceId?._id}>
                      <tr className={`${m.active}`} >
                        <td style={service?.promotionId?._id ? {} : { minWidth: "140px" }}>{service?.serviceDetails?.serviceName}</td>
                        <td>{service?.serviceId?.duration}</td>
                        <td>{service?.promotionId?.promotionName || "not applied"}</td>
                        <td><span style={service?.promotionId?._id ? { color: "red", textDecoration: "line-through" } : {}}>{`${service?.serviceId?.seasonPrice} `}</span><span>{service?.promotionId?._id ? `${Number.parseInt(promotionPrice?.promotionalPrice)}` : ""} SGD</span></td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>}

          {serviceType === "bundle" && bundleGroup?.map((item: any, b: number) => {
            const bundlePromotionalPrice = item?.[Object.keys(item)?.[0]][0]?.bundlePromotionalPrice;
            const bundlePrice = item?.[Object.keys(item)?.[0]][0]?.bundleId?.finalPrice;


            return (
              <div className={m.bundleScreen} key={b}>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 style={{ fontSize: "16px" }}>{Object.keys(item)?.[0] || ""}</h5>
                  <span style={bundlePromotionalPrice ? { color: "red", textDecoration: "line-through" } : { color: "#1e8c76", }}>SGD{bundlePrice}</span> {bundlePromotionalPrice && <span>SGD {bundlePromotionalPrice}</span>}

                </div>
                <div className={`${m.serviceSection} ${m.bundle} mt-2`}>
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th>Services</th>
                        <th>Duration</th>

                        <th>Price</th>
                      </tr>
                    </thead>

                    <tbody>
                      {item?.[Object.keys(item)?.[0]]?.map((service: any, s: any) => {
                        return (

                          <React.Fragment key={service?.serviceId?._id}>
                            <tr className={`${m.active}`} >
                              <td style={service?.promotionId?._id ? {} : { minWidth: "140px" }}>{service?.serviceDetails?.serviceName}</td>
                              <td>{service?.serviceId?.duration}</td>

                              <td><span>{`SGD ${service?.serviceId?.seasonPrice} `}</span></td>
                            </tr>
                          </React.Fragment>
                        )
                      })}


                    </tbody>
                  </table>

                </div>
              </div>
            )
          })} */}

          <div className={`${m.discount_charges_accordian} mt-2`}>
            <div>
              <Col md={12}>
                <div className={m.period} style={{ marginTop: '0px', borderTop: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3', padding: '9px 0px', background: '#effdee' }}>
                  <div className={m.left}>
                    <h3>Duration</h3>
                    <p className="mb-0">{totalDuration} mins</p>
                  </div>
                  <div className={m.left}>
                    <h3>Price</h3>
                    <p className="mb-0">  {!noPromotion && <span style={{ color: "red", textDecoration: "line-through" }}> {!noPromotion ? withoutDeductedPrice?.toFixed(2) : ""}</span>} <span>{!noPromotion ? totalDeductedPrice?.toFixed(2) : withoutDeductedPrice?.toFixed(2)} SGD</span></p>
                  </div>
                </div>
              </Col>
            </div>


            <div>
              <Col md={12}>
                <div className={`${m.new_appoint_discount_box}`}>
                  <div className={`${m.new_appoint_discountDiv}`}>
                    <h4>Other Charges</h4>
                    <Row>
                      <Col md={6}>
                        <div className={`${m.input_left}`}>
                          <label>Details</label>
                          <input type="text" className='form-control' value={otherCharges?.otherChargeMsg} onChange={(e: any) => setOtherCharges({ ...otherCharges, otherChargeMsg: e.target.value })} />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={`${m.right_left}`}>
                          <label>Amount</label>
                          <input type="number" className='form-control' value={otherCharges?.otherChargeAmt} onChange={(e: any) => setOtherCharges({ ...otherCharges, otherChargeAmt: e.target.value })} />
                        </div>
                      </Col>
                      {errorTrig && otherCharges?.otherChargeMsg !== "" && otherCharges?.otherChargeAmt === "" && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add other charge amount</p>}


                      <div className={`${m.line_withTotal_amt}`}>
                        <span className={`${m.line}`}></span>
                        <div className={`${m.total_amt}`}> SGD {otherChargePrice}</div>
                      </div>
                    </Row>
                  </div>
                  <div className={`${m.new_appoint_discountDiv}`}>
                    <h4>Additional Discount</h4>
                    <Row>
                      <Col md={6}>
                        <div className={`${m.input_left}`}>
                          <label>Discount Type</label>
                          <select className='form-select' value={otherCharges?.discountType} onChange={(e: any) => setOtherCharges({ ...otherCharges, discountType: e.target.value })}>
                            <option value="">--select--</option>
                            <option value="%">discount percentage</option>
                            <option value="discount amount">discount amount</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={`${m.right_left}`}>
                          <label>Amount</label>
                          <input type="text" className='form-control'  disabled={otherCharges?.discountType === ""} min={otherCharges?.discountType === "%" ? 100 : +totalPrice} value={otherCharges?.discount} onChange={(e: any) => handleInputChange(e, otherCharges?.discountType)} />
                        </div>
                      </Col>
                      {/* {errorTrig && otherCharges?.discountType !== "" && otherCharges?.discount === "" && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add discount amount</p>} */}

                      <div className={`${m.line_withTotal_amt}`}>
                        <span className={`${m.line}`}></span>
                        <div className={`${m.total_amt}`}>Final Price: SGD {finalPrice}</div>
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
            </div>
            <div>
              <Col md={12}>
                <div className={`${m.new_appoint_discount_box}`}>
                  <div className={`${m.new_appoint_discountDiv}`}>
                    <h4>Payment</h4>
                    <Row>
                      <Col md={12}>
                        <div className={`${st.formBox} mb-2 mt-2`}>
                          <label className={`${st.checkbox} mt-1`}>
                            <input type="checkbox" value="Pending" checked={otherCharges?.paymentStatus === "Pending"} onChange={(e: any) => setOtherCharges({ ...otherCharges, paymentType: e.target?.checked ? "" : otherCharges?.paymentType, paymentRef: e.target?.checked ? "" : otherCharges?.paymentRef, paymentStatus: e.target?.checked ? e.target.value : "Success" })} />
                            <span className={`${st.checkmark}`}></span>
                            <span className="ms-2">Pending</span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    {otherCharges?.paymentStatus !== "Pending" && <Row>
                      <Col md={6}>
                        <div className={`${m.input_left}`}>
                          <label>Payment Method</label>
                          <select className='form-select' value={otherCharges?.paymentType} onChange={(e: any) => setOtherCharges({ ...otherCharges, paymentType: e.target.value })}>
                            <option value="">--select--</option>
                            <option value="Credit card">Credit card</option>
                            <option value="Cash">Cash</option>
                            <option value="Nets">Nets</option>
                            <option value="Paynow">Paynow</option>
                            <option value="Voucher">Voucher</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className={`${m.right_left}`}>
                          <label>Reference Number</label>
                          <input type="text" className='form-control' value={otherCharges?.paymentRef} onChange={(e: any) => setOtherCharges({ ...otherCharges, paymentRef: e.target.value })} />
                        </div>
                      </Col>
                      {errorTrig && otherCharges?.paymentStatus === "Success" && (otherCharges?.paymentType === "" || otherCharges?.paymentRef === "") && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add Payment method</p>}


                    </Row>}
                  </div>
                </div>
              </Col>
            </div>

          </div>

          <div className={`${m.divider} mt-4`} role="img"></div>
          {/* <div className={`${m.stylist}`}>
            <h5>Stylist</h5>
            <Row className="">
              {team?.length === 0 ? <p>No Stylist assigned for this booking </p> : team?.map((team: any) => {
                return (
                  <Col md={12} key={team?._id}>
                    <div className={`${m.stylistBox} mb-2`} key={team?._id}>
                      {team?.image ? (
                        <img src={team?.image} className={`${m.image}`} alt="team" />
                      ) : (
                        <div className={m.emptyImage}>
                          <span>{team?.firstName?.[0] || ""}</span>
                        </div>
                      )}
                      <div className={`${m.content}`}>
                        <h5>{`${team?.firstName} ${team?.lastName || ""} ${team?.nickName ? `(${team?.nickName})` : ""}`}</h5>
                        <ul className={m.roleList}>
                          {team?.Role?.map((role: any) => {
                            if (role?.branch?._id !== branchId) {
                              return
                            }
                            return (
                              <li>{`${role?.role?.map((r: any, i: any) => `${r?.teamTitle}${i < role?.length ? "," : ""}`)}`}</li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </Col>
                )
              })}

            </Row>
          </div>
          <div className={`${m.divider}`} role="img"></div> */}

        </Modal.Body>
        <Modal.Footer>
          <Col md={12}>
            <div className={`${m.new_appoint_discount_box}`}>
              <div className={`${m.new_appoint_discountDiv}`}>
                <Row>
                  <div className={`${m.line_withTotal_amt} px-4 mt-0`}>
                    <span className={`${m.line}`}></span>
                    <div className={`${m.total_amt}`}>Total: SGD {finalPrice} </div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
          <div className={`${m.btnsAlignments} d-flex w-100`}>
            <NavLink
              className={`btn ${m.cancelBtn} w-100 mx-2 `}
              to="#"
              onClick={handlePaymentBookingClose}
            >
              Cancel
            </NavLink>
            <button
              type="button"
              className={`btn ${m.actionBtn} w-100 mx-2 `}
              onClick={onsubmit}
            >
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                ></div>
              ) : (
                'Pay Now'
              )}

            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentModal;
