// import firebase from "firebase";
// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const db = firebase.firestore();
// export default db;

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration

// const firebaseConfig = {
//   apiKey: "AIzaSyDPomCjlFKQjgqX_zzLViFx_ULJTuhDt8Q",
//   authDomain: "spachat-9ba12.firebaseapp.com",
//   projectId: "spachat-9ba12",
//   storageBucket: "spachat-9ba12.appspot.com",
//   messagingSenderId: "167756063621",
//   appId: "1:167756063621:web:5cc4d43394a770fa739aec"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDHQwbvNuTud607fB_wM7uMkQtw484-z-0",
  authDomain: "spa-chat-5199e.firebaseapp.com",
  projectId: "spa-chat-5199e",
  storageBucket: "spa-chat-5199e.appspot.com",
  messagingSenderId: "1006168981733",
  appId: "1:1006168981733:web:a6aac069f04599ae93940c"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
