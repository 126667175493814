import React, { useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { disablePastDate } from "../../../Utils/Funcs";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { iconEmail, iconPassword, iconUser } from "../../../assets/images";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";

const AddBookingClientModal = (props: any) => {
    let { showBookingClient, handleBookingClientClose, getClientData } = props;
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const [phone, setPhone] = useState("");
    const [errorTrig, setErrorTrig] = useState(false);
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [show, setShow] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const submitHandler = async (data: any) => {

        let body = {
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: phone ? phone : undefined,
            email: data.email,
            country: data.country ? data.country : "",
            state: data.state ? data.state : "",
            city: data.city ? data.city : "",
            branchId: branchId,
            businessId: businessData?._id,
            postalCode: data.postalCode ? data.postalCode : '',
            adderss1: data.address ? data.address : "",
        };

        const res = await httpRequest("createClient", "post", body, "json");
        if (res?.status) {
            getClientData();
            setTimeout(() => {
                handleBookingClientClose()
            }, 1000)
        }
    }
    const handlePhoneChange: any = (value: any) => {
        setPhone(value);
    };

    return (
        <>
            <Modal
                centered
                show={showBookingClient}
                onHide={handleBookingClientClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={m.modalCts}
            >
                <Modal.Header className="p-2">
                    <Modal.Title>Add a New Client</Modal.Title>
                    <button
                        className={`${m.closeIcon} ${m.v2}`}
                        onClick={handleBookingClientClose}
                    >
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className={`${m.clientAdd}`}>
                        <Row>
                            <Col md={12}>
                                <h2>Main Info</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className={`${st.formBox}`}>
                                    <label className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"

                                        {...register("firstName", {
                                            required: true,
                                        })}
                                    />
                                    {errors?.firstName?.type === "required" && (
                                        <p className={"errorMessage"}>
                                            This field is required
                                        </p>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.formBox}`}>
                                    <label className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"

                                        {...register("lastName", {

                                        })}
                                    />

                                </div>
                            </Col>

                            <Col md={6} >
                                <div className={`${st.formBox}`}>
                                    <label className="form-label">
                                        Phone Number
                                    </label>
                                    <div className={`${st.phoneBox}`}>
                                        <PhoneInput
                                            defaultCountry="SG"
                                            international
                                            placeholder="Enter phone number"
                                            value={phone}
                                            onChange={handlePhoneChange}
                                            error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                        />
                                        {phone && <p className={"errorMessage"}>{phone && isValidPhoneNumber(phone) ? '' : 'Invalid phone number'}</p>}

                                    </div>
                                    {/* <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                            required: "Phone number is required",
                                            pattern: /[0-9a-zA-Z]{10,}/,
                                        }}

                                        render={({ field }) => (
                                            <div className={`${st.phoneBox}`}>
                                                <PhoneInput
                                                    country={"in"}

                                                    {...field}
                                                    inputProps={{
                                                        name: "phone",
                                                        autoFocus: false,
                                                        value: field.value || '',
                                                    }}

                                                    defaultErrorMessage="It doesn't work, why?"
                                                // value={typeof field.value === 'string' ? field.value : ''}
                                                />
                                                {errors?.phone?.type === "pattern" && (
                                                    <p className={"errorMessage"}>
                                                        Not a valid number .
                                                    </p>
                                                )}
                                                {errors?.phone?.type === "required" && (
                                                    <p className={"errorMessage"}>
                                                        This field is required
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    /> */}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.formBox}`}>
                                    <label className="form-label">Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        {...register("email", {
                                            required: true,
                                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        })}
                                    />
                                    {errors?.email?.type === "pattern" && (
                                        <p className={"errorMessage"}>
                                            Invalid email address
                                        </p>
                                    )}
                                    {errors?.email?.type === "required" && (
                                        <p className={"errorMessage"}>
                                            This field is required
                                        </p>
                                    )}
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={m.addMore}>
                                    <p onClick={() => setShow(true)}>+ <span>Add Location</span></p>
                                </div>
                            </Col>
                        </Row>
                        {show && (
                            <>
                                <Row className="mt-1">
                                    <Col md={12}>
                                        <h2>Location</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div className={`${st.formBox}`}>
                                            <label className="form-label">Country</label>

                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Country"

                                                {...register("country", {
                                                    // required: true,
                                                    pattern: /^[A-Za-z\s]+$/,
                                                })}
                                            />
                                            {errors?.country?.type === "required" && (
                                                <p className={"errorMessage"}>
                                                    This field is required
                                                </p>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className={`${st.formBox}`}>
                                            <label className="form-label">State</label>

                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="State"

                                                {...register("state", {
                                                    // required: true,
                                                    pattern: /^[A-Za-z\s]+$/,
                                                })}
                                            />
                                            {errors?.state?.type === "required" && (
                                                <p className={"errorMessage"}>
                                                    This field is required
                                                </p>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className={`${st.formBox}`}>
                                            <label className="form-label">City</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="City"

                                                {...register("city", {
                                                    // required: true,
                                                    pattern: /^[A-Za-z\s]+$/,
                                                })}
                                            />

                                            {errors?.city?.type === "required" && (
                                                <p className={"errorMessage"}>
                                                    This field is required
                                                </p>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className={`${st.formBox}`}>
                                            <label className="form-label">Postal Code</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Postal Code"

                                                {...register("postalCode", {
                                                    // required: true,
                                                    pattern: /[0-9a-zA-Z]{6,}/,
                                                })}
                                            />
                                            {errors?.postalCode?.type === "pattern" && (
                                                <p className={"errorMessage"}>
                                                    minimum six character required .
                                                </p>
                                            )}
                                            {errors?.postalCode?.type === "required" && (
                                                <p className={"errorMessage"}>
                                                    This field is required
                                                </p>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className={`${st.formBox}`}>
                                            <label className="form-label">Address</label>
                                            <input
                                                type="text"
                                                className={`form-control`}
                                                placeholder="Address"

                                                {...register("address1", {
                                                    // required: true,
                                                })}
                                            />
                                            {errors?.address?.type === "required" && (
                                                <p className={"errorMessage"}>
                                                    This field is required
                                                </p>
                                            )}

                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                    <div className={`${m.logoutPopup}`}>
                        <div className={`${m.btnsAlignments}`}>
                            <NavLink
                                className={`btn ${m.cancelBtn}`}
                                to="#"
                                onClick={handleBookingClientClose}
                            >
                                Cancel
                            </NavLink>
                            <button
                                type="button"
                                className={`btn ${m.actionBtn}`}
                                onClick={handleSubmit(submitHandler)}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddBookingClientModal;
