import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Switch } from "../../../components/Website/Forms";

import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import {
  hair,
  basicInfo,
  basicInfoA,
  pricingTab,
  pricingTabA,
  teamTab,
  teamTabA,
  amenitiesTab,
  amenitiesTabA,
  massage,
  lashes,
  makeupfill,
  nails,
  waxfill,
  facilities,
  chair,
  table,
  massageTable,
  deleteIcon,
  plus,
  plusWhite,
} from "../../../assets/images";
import BackArrrow from "../../../components/Business/Buttons/BackArrow";
import PriceGrid from "./PriceGrid";
import MultiSelect from "../../../components/Business/MultiSelect/MultiSelect";
import NewPrice from "../../../components/Business/Modals/NewPrice";
import { useSelector } from "react-redux";
import BasicService from "../Services/BasicService";
import PricingTab from "../Services/PricingTab";
import TeamTab from "../Services/TeamTab";
import AmenitiesTab from "../Services/AmenitiesTab";
import { GlobalServiceContextProvider } from "../../../store/ServiceSGloabl";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { getDynamicURIName } from "../../../Utils/Funcs";
import AddServiceRoutes from "../Services/AddServiceRoutes";
import ServiceRoutes from "./ServiceRoutes";
import { httpRequest } from "../../../Apis/commonApis";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

const AddService = (props: any) => {

  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );

  const location = useLocation();
  const dynamicName = getDynamicURIName(location.pathname);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(dynamicName);
  const [selectedService, setSelectedService] = useState<any>(null)

  const handleStepsFormData = (formdata: any, step: any) => {
    setActiveTab(step);
    let srvId = location.state ? location.state?.serviceId : formdata._id;
    if (step === "amenities") {
      navigate("/business/branch-services")
      return
    }
    navigate(step, { state: { serviceId: srvId }, replace: true });
  };

  const getService = async () => {
    const res = await httpRequest(`getBusinessService?_id=${location?.state?.serviceId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setSelectedService(res?.data?.[0])
    }
  };

  useEffect(() => {
    if (businessData?._id && location?.state?.serviceId) {
      getService();
    }
  }, [businessData?._id, activeTab])

  const handleTabSelect = (tab: any) => {
    if (location.pathname.includes('edit-service')) {
      setActiveTab(tab);
      navigate(`${tab}`, { state: { serviceId: location?.state?.serviceId }, replace: true });
    }
  };

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {/* <BackArrrow />  */}
              {location?.state?.serviceId ? `${selectedService?.ServiceCategory?.serviceCategoryName || ""} > ${selectedService?.serviceName || ""}` : 'Add Service'}
            </h5>
          </div>
          <div className={`${st.rightInfo}`}>
            {location.pathname.includes('edit-service') && <div style={{ color: "#3e8575"}}>Edit Mode</div>}
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Nav variant="pills">
              <Nav.Item>
            
                <Nav.Link eventKey="info">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pricing">
                  <img
                    src={pricingTab}
                    alt="info"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={pricingTabA}
                    alt="info"
                    className={`${st.active}`}
                  />
                  Pricing and Duration
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="team">
                  <img src={teamTab} alt="info" className={`${st.defalut}`} />
                  <img src={teamTabA} alt="info" className={`${st.active}`} />
                  Team
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item>
                <Nav.Link eventKey="amenities">
                  <img
                    src={amenitiesTab}
                    alt="info"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={amenitiesTabA}
                    alt="info"
                    className={`${st.active}`}
                  />
                  Amenities
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                <Routes>
                  <Route path={activeTab}
                    element={<ServiceRoutes dynamicName={dynamicName} handleStepsFormData={handleStepsFormData} />} />
                </Routes>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section >
    </>
  );
};
export default AddService;
