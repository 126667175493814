import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { useForm } from "react-hook-form";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import MultiSelect from "../../../components/Business/MultiSelect/MultiSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

const List = ["hair", "wax", "spa"];

export default function BadicService(props: any) {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const { handleStepsFormData, editServiceData, serviceCategoryList } = props;

  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [serviceTag, setServiceTag] = useState<any>([]);
  const [tagError, setTagError] = useState(false);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  // const [serviceTagList,setServiceTagList] = useState<any[]>([])

  // const serviceTaglist = async () => {
  //   const res = await httpRequest(`get_ServiceTag`,"get",null,"auth");
  //   if (res.status === true) {
  //     setServiceTagList(res.data)
  //   } 
  // };


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();


  useEffect(() => {
    if (editServiceData) {
      reset();
      let srvCatId = editServiceData.serviceCatagoryId?._id ? editServiceData.serviceCatagoryId?._id : editServiceData.serviceCatagoryId;
      const defaultSelectedTags = editServiceData.serviceTag?.map((tag: any) => {
        return { value: tag, label: tag };
      });
      setServiceTag(defaultSelectedTags);
      setValue('serviceAvilableFor', editServiceData.serviceAvilableFor?.split(","));
      setValue('serviceCatagoryId', srvCatId);
    }
  }, [editServiceData]);

  console.log(watch("serviceCatagoryId"), "serviceCatagoryId");


  const submitHandler = async (postdata: any) => {
    if (serviceTag.length < 1) {
      return;
    }
    const tagList = serviceTag?.map((tag: any) => tag.value);
    const basicData = { ...postdata };
    basicData.serviceAvilableFor = postdata.serviceAvilableFor?.join(",")
    basicData.serviceTag = tagList;
    basicData.businessId = businessData?._id;
    basicData.branchId = editServiceData?.branchId && editServiceData?.branchId?.length !== 0 ? editServiceData?.branchId?.map((item: any) => item?._id) : [branchId];
    setLoading(true);
    let apiUrl = 'createBusinessService';
    let apiMethod = 'post';
    if (editServiceData && location.state) {
      apiUrl = `updateBusinessService/${location.state.serviceId}`;
      apiMethod = 'patch';
      if (editServiceData.serviceName === basicData.serviceName) {
        delete basicData['serviceName'];
      }
    }
    const res = await httpRequest(apiUrl, apiMethod, basicData, "json");
    if (res.status) {
      handleStepsFormData(res.data, 'pricing');
      localStorage.setItem("serviceId", res.data._id);
      setTagError(false);
      setApiError("")
    } else {
      setApiError(res.message)
    }
    setLoading(false);
  };

  const errorHandler = (e: any) => {
    if (serviceTag.length === 0) {
      setTagError(true);
    }
  };

  function selectHandler(e: any) {
    setServiceTag(e);
  }

  let serviceCatagoryId = watch("serviceCatagoryId");

  useEffect(() => {
    if (serviceCatagoryId !== editServiceData?.serviceCatagoryId) {
      setServiceTag("")
    }
  }, [serviceCatagoryId])

  let tags: any = serviceCategoryList?.filter((item: any) => {
    return serviceCatagoryId == item?._id
  })
  let serviceCategoryTag: any = tags?.[0]?.serviceCategoryTag;

  useEffect(() => {
    if (serviceCategoryTag && !editServiceData) {
      setServiceTag(serviceCategoryTag?.map((item: any) => ({ label: item, value: item })))
    }
  }, [serviceCategoryTag])

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <Col md={12}>
            <div className="text-end">
              <HelpButon number={16} type={"help"} className={"btn"} />
            </div>
          </Col>
          <Col lg={6}>
            <div className={`${st.formBox}`}>
              <label className="form-label">Service name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Service name"
                defaultValue={editServiceData?.serviceName}
                {...register("serviceName", {
                  required: true,
                  pattern: {
                    value: /\S/,
                    message: "This field is required",
                  },
                })}
              />
              {errors?.serviceName?.type === "required" && (
                <p className={"errorMessage"}>This field is required</p>
              )}
              {errors?.serviceName?.type === "pattern" && (
                <p className={"errorMessage"}>This field is required</p>
              )}
              {apiError !== "" && (
                <p className={"errorMessage"}>{apiError}</p>
              )}
            </div>
          </Col>

          <Col lg={6} className="ms-auto">
            <div className={`${st.formBox}`}>
              <label className="form-label">Service available for</label>
              <ul className={`${st.listUl}`}>
                <li>
                  <label className={`${st.checkbox}`}>
                    <input
                      type="checkbox"
                      value={"male"}
                      {...register("serviceAvilableFor", {
                        required: true,
                      })}
                    />
                    <span className={`${st.checkmark}`}></span> &nbsp; Male
                  </label>
                </li>
                <li>
                  <label className={`${st.checkbox}`}>
                    <input
                      type="checkbox"
                      value={"female"}
                      {...register("serviceAvilableFor", {
                        required: true,
                      })}
                    />
                    <span className={`${st.checkmark}`}></span> &nbsp; Female
                  </label>
                </li>
                <li>
                  <label className={`${st.checkbox}`}>
                    <input
                      type="checkbox"
                      value={"anyone"}
                      {...register("serviceAvilableFor", {
                        required: true,
                      })}
                    />
                    <span className={`${st.checkmark}`}></span> &nbsp; Others
                  </label>
                </li>
              </ul>
            </div>
            {errors?.serviceAvilableFor?.type === "required" && (
              <p className={"errorMessage"}>
                Please select a gender
              </p>
            )}
          </Col>

          <Col md={6}>
            <div className={`${st.formBox}`}>
              <label className="form-label">Service category</label>
              <select
                className="form-select"
                // defaultValue={editServiceData?.serviceCatagoryId?.serviceCategoryName}
                {...register("serviceCatagoryId", {
                  required: true,
                })}
              >
                <option value="">--Select--</option>
                {serviceCategoryList.map((sc: any) => {
                  return (
                    <option value={sc._id} key={sc._id}>
                      {sc?.serviceCategoryName}
                    </option>
                  );
                })}
              </select>
              {errors?.serviceCatagoryId?.type === "required" && (
                <p className={"errorMessage"}>This field is required</p>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className={`${st.formBox}`}>
              <label className="form-label">Service Tag</label>
              <MultiSelect
                onChange={selectHandler}
                value={serviceTag}
                list={serviceCategoryTag || [""]}
              />
              {tagError && serviceTag.length <= 0 && (
                <p className={"errorMessage"}>This field is required</p>
              )}
            </div>
          </Col>

          <Col md={6}>
            <div className={`${st.formBox}`}>
              <label className="form-label">Service description</label>
              <textarea
                className="form-control"
                placeholder="Service description"
                style={{ minHeight: "100px" }}
                defaultValue={editServiceData?.serviceDescription}
                {...register("serviceDescription", {
                  required: false,
                })}
              ></textarea>
              {errors?.serviceDescription?.type === "required" && (
                <p className={"errorMessage"}>This field is required</p>
              )}
            </div>
          </Col>

          <Col md={6}>
            <div className={`${st.formBox}`}>
              <label className="form-label">
                Service Aftercare description
              </label>
              <textarea
                className="form-control"
                placeholder="Service Aftercare description"
                style={{ minHeight: "100px" }}
                defaultValue={editServiceData?.ServiceAftercareDescription}
                {...register("ServiceAftercareDescription", {
                  required: false,
                })}
              ></textarea>
              {errors?.ServiceAftercareDescription?.type === "required" && (
                <p className={"errorMessage"}>This field is required</p>
              )}
            </div>
          </Col>

          <Col md={12}>
            <div className={`${st.formBox}`}>
              <div className={`${st.btnsGroup}`}>
                <button
                  className={`btn ${st.btn1}`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  Back
                </button>
                <button className={`btn ${st.btn4}`} onClick={errorHandler} disabled={loading}>
                  {loading ? <LoadingBtn /> : 'Save'}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
}
