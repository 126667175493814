import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, ProgressBar, Row, Tab, Table } from "react-bootstrap";
import cx from "./index.module.scss";
import { bulb, teamPerson1 } from "../../../assets/images";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import StepperRow from "../../../components/Business/StepperBox/StepperRow";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import RecentNotification from "./RecentNotification";
import RecentChat from "./RecentChat";
import TipsOfTheDay from "./TipsOfDay";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import DashboardRoutes from "./DashboardRoutes";
import { getDynamicURIName } from "../../../Utils/Funcs";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";

const Dashboard = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate()
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const dynamicName = getDynamicURIName(location.pathname);
  const [activeTab, setActiveTab] = useState('today');
  const [homepageData, setHomePageData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false)

  const handleTabSelect = (tab: any) => {
    setActiveTab(tab);
    navigate(`${tab}`, { replace: true });
  };
  const tabItemList = ['today', 'week', 'month'];

  async function getHomePageData () {
    setLoading(true)
    const response = await httpRequest(`getdashboard/${businessData?._id}`, 'GET', null, 'json');
    if(response?.status) {
      setHomePageData(response)
    }
    setLoading(false)
  }
  useEffect(() => {
    if (businessData) {
      getHomePageData()
    }
  }, [businessData])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <Row>
          <Col lg={6}>
            <StepperRow type='dashboard' />
          </Col>
          <Col lg={6}>
            <div className={`${cx.promoLine_gap}`}>
              <div className={`${cx.promoLine_card}`}>
                <h2>New promotion, New feature</h2>
                <p>Provide detailed information about your brand story, missi</p>
                <button className="btn">View More</button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={4} className="mb-4">
            <RecentNotification />
          </Col>
          <Col lg={4} className="mb-4">
            <RecentChat />
          </Col>
          <Col lg={4} className="mb-4">
            <TipsOfTheDay />
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <div className={`${st.tabbingBox} mt-0`}>
              <Tab.Container id="left-tabs-example" onSelect={handleTabSelect} activeKey={activeTab}>
                <Nav variant="pills">
                  {tabItemList?.map((tab: string, i: number) => {
                    return (
                      <>
                        <Nav.Item key={tab}>
                          <Nav.Link eventKey={tab}>
                            {tab} at Glance
                          </Nav.Link>
                        </Nav.Item>
                        {i !== (tabItemList?.length - 1) && <span className={`${st.linespan}`}></span>}
                      </>
                    )
                  })}
                </Nav>
                <Tab.Content>
                  <Routes>
                    <Route path={activeTab} element={<DashboardRoutes dynamicName={dynamicName} loading={loading} homepageData={homepageData}/>} />
                  </Routes>
                </Tab.Content>
              </Tab.Container>

            </div>
          </Col>
        </Row>


      </section>
    </>
  );
};

export default Dashboard;
