import React, { useState, Fragment, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import { clients, deleteIcon, editIcon, openIcon } from "../../../assets/images";
import { useSelector } from "react-redux";
import ViewSale from "../../../components/Business/Modals/ViewSale";
import CreateSaleModal from "../../../components/Business/Modals/NewSale";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showProfile, setShowProfile] = useState(false);
    const handleProfileClose = () => setShowProfile(false);
    const handleProfileShow = () => setShowProfile(true);

    // edit Sale 
    const [showSale, setShowSale] = useState(false);
    const handleSaleClose = () => setShowSale(false);
    const handleSaleShow = () => setShowSale(true);
    const [clientData, setClientData] = useState<any[]>([]);
    const [service, setService] = useState<any>([]);
    const [packageData, setPackageData] = useState<any>([]);
    const [memberData, setMemberData] = useState<any>([]);
    const [salesData, setSalesData] = useState<any>([]);

    useEffect(() => {
        if (businessData) {
            fetchPakages(businessData._id);
            fetchMember(businessData._id);
            getClientData(businessData._id);
            fetchServices(businessData._id);
        }
    }, [businessData]);

    const getClientData = async (id: any) => {
        let res = await httpRequest(
            `getClient?businessId=${id}`,
            "get",
            null,
            "json"
        );
        if (res.status === true) {
            setClientData(res.data);
        } else {
            console.error("Request failed:", res.error);
        }
    };

    const fetchServices = async (id: any) => {
        let res = await httpRequest(
            `getBusinessPrice?businessId=${id}&type=package`,
            "get",
            null,
            "json"
        );

        setService(res?.data);
    };

    const fetchPakages = async (id: any) => {
        let res = await httpRequest(
            `getPackages?businessId=${id}`,
            "get",
            null,
            "json"
        );

        setPackageData(res.data);
    };

    const fetchMember = async (id: any) => {
        let res = await httpRequest(
            `getMembership?businessId=${id}`,
            "get",
            null,
            "json"
        );
        setMemberData(res.data);
    };


    const filterSale = props?.data?.find((data: any) => {
        return data?._id === props?.params?.row?.id
    })

    const canEditSale = subModuleRoles?.['Business Set Up']?.Sales?.Edit;
    const canDeleteSale = subModuleRoles?.['Business Set Up']?.Sales?.Delete;

    const deleteApi = async () => {
        const res = await httpRequest(
            `deleteSales/${props?.params?.row?.id}`,
            "DELETE",
            null,
            "json"
        );
        if (res.status === true) {
            props.fetchSales()
            handleClose();
        } else {
        }
    };

    return (
        <>
            <div>
                <ul className={`${tb.actionTable}`}>
                    {canEditSale && <li>
                        <NavLink
                            className={`btn ${tb.edit}`}
                            title="Edit"
                            to="#"
                            onClick={() => setShowSale(true)}
                        >
                            <img src={editIcon} alt="edit" />
                        </NavLink>
                    </li>}
                    <li onClick={() => handleProfileShow()}>
                        <NavLink
                            className={`btn ${tb.edit}`}
                            title="view"
                            to="#"
                        >
                            <img src={openIcon} alt="view" />
                        </NavLink>
                    </li>
                    {/* <li>
                        <button
                            type="button"
                            className={`btn ${tb.delete}`}
                            title="Delete"
                            onClick={handleShow}
                        >
                            <img src={deleteIcon} alt="Delete" />
                        </button>
                    </li> */}
                </ul>
            </div>

            <DeletePopup
                show={show}
                handleClose={handleClose}
                deleteApi={deleteApi}
            />

            {showSale && <CreateSaleModal
                showSale={showSale}
                handleSaleClose={handleSaleClose}
                service={service}
                clientData={clientData}
                setServiceData1={setService}
                packageData={packageData}
                memberData={memberData}
                salesData={salesData}
                editSale={filterSale}
                fetchSales={props?.fetchSales}
            />}
            {showProfile && <ViewSale handleProfileClose={handleProfileClose} showProfile={showProfile} data={[filterSale]} />}
        </>
    );
};

// check status if active then green color if not then red







export default function DataGridTable(props: any) {
    let { allDetails } = props

    console.log(allDetails, "allDetails")

    allDetails = Array.isArray(allDetails) ? allDetails : [];





    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const columns = [
        { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 40 },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     flex: 1,
        //     minWidth: 65,
        //     renderCell: (params: any) => <StatusButton params={params} data={props?.salesData} fetchSales={props?.fetchSales} />,
        // },
        { field: "clientName", headerName: "Client Name", flex: 1, minWidth: 165 },
        { field: "servicesCategory", headerName: "Services Category", flex: 1, minWidth: 200 },
        { field: "services", headerName: "Services", flex: 1, minWidth: 200 },
        { field: "teamMember", headerName: "Team Member", flex: 1, minWidth: 200 },
        { field: "salectDate", headerName: "Date", flex: 1, minWidth: 200 },
        { field: "salectTime", headerName: "Time", flex: 1, minWidth: 100 },
        { field: "duration", headerName: "Duration", flex: 1, minWidth: 100 },
        { field: "finalPrice", headerName: "Amount", flex: 1, minWidth: 100 },
        { field: "promotion", headerName: "Promotion", flex: 1, minWidth: 100 },
        { field: "bookingStatus", headerName: "Booking Status", flex: 1, minWidth: 140 },
        { field: "paymentStatus", headerName: "Payment Status", flex: 1, minWidth: 140 },
        // { field: "packages", headerName: "Packages", flex: 1, minWidth: 200 },
        // { field: "memberships", headerName: "Memberships", flex: 1, minWidth: 200 },
        // { field: "createdBy", headerName: "Created by", flex: 1, minWidth: 150 },
        // { field: "createdDated", headerName: "Created Date", flex: 1, minWidth: 120 },
        // { field: "duration", headerName: "Duration", flex: 1, minWidth: 140 },
        // { field: "price", headerName: "Price", flex: 1, minWidth: 120 },
        // { field: "status", headerName: "Status", flex: 1, minWidth: 120 },
    ];
    const convertTo12HourFormat = (time24: string) => {
        if (!time24) return '';
        let [hours, minutes] = time24.split(':').map(Number);
        let period = 'AM';
        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) hours -= 12;
        } else if (hours === 0) {
            hours = 12;
        }
        return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
    };
    let Row = allDetails?.map((item: any, i: number) => {

        console.log(item?.services?.[0]?.promotionId?.
            promotionName

            , "item")
        const serviceCategoryNames = item?.services?.map((data: any, ind: any) => {
            console.log(data?.serviceId?.serviceId?.serviceName, "data")

            const serviceName = data?.serviceId?.serviceId?.serviceName
            // console.log(serviceCategoryName, "data");
            return serviceName;
        });
        //   const servicesCategory = serviceCategoryNames?.join(", ");
        const data = serviceCategoryNames;

        const servicesCategoryName = item?.services?.map((data: any, ind: any) => {
            const serviceNamecate = data?.serviceId?.serviceId?.serviceCatagoryId?.serviceCategoryName
            return serviceNamecate;
        });
        const servicesCategory = servicesCategoryName;
        // const servicesCategory = servicesCategoryName?.join(", ");


        let teamMembers: any[] = [];
        const TeamName = item?.services?.map((data: any, ind: any) => {
            const firstName = data?.TeamMemberId?.[0]?.firstName;
            const lastName = data?.TeamMemberId?.[0]?.lastName;
            const nickName = data?.TeamMemberId?.[0]?.nickName;
            // const formattedName = `${firstName} ${lastName} (${nickName})`;
            // teamMembers.push(formattedName ? formattedName : '');
            // console.log(formattedName, "formattedName");
            const formattedName = `${firstName || ''} ${lastName || ''}${nickName ? ` (${nickName})` : ''}`.trim();
            teamMembers.push(formattedName ? formattedName : '');
            console.log(formattedName, "formattedName");
        });
        console.log(teamMembers, "teamMembers")



        const durations = item?.services?.map((service: any) => {
            console.log(service?.serviceId?.duration, "kjjj");
            return service?.serviceId?.duration;
        });



        const ProData = item?.services?.map((service: any) => {
            return service?.promotionId?.promotionName
        })?.join(",")

        console.log(ProData, "ProData")


        return {
            id: item?.id || i,
            serialNumber: i + 1,
            clientName: item?.clientId?.firstName + " " + item?.clientId?.lastName || '',
            servicesCategory: servicesCategory || '',
            services: data || '',
            // services: item?.services,
            teamMember: teamMembers || '',
            salectDate: item?.salectDate || '',
            salectTime: item?.salectTime || '',
            duration: durations || '',
            finalPrice: item?.finalPrice || '',
            promotion: ProData,
            paymentStatus: item?.paymentStatus || '',
            bookingStatus: item?.bookingStatus || ''
        }
    })




    return (
        <div className={`${tb.dataTable}`}>
            <div style={{ width: "100%" }}>
                <DataGrid
                    columns={columns}
                    rows={Row || []}
                    autoHeight
                    hideFooterPagination={false}
                    rowHeight={53}
                    checkboxSelection
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </div>
        </div>
    );
}
