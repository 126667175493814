import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import st from "../../../assets/stylesheet/style.module.scss";
import ServiceInside from './ServiceInside';

function ServicesCategoryCanvas(props: any) {
    let { show, handleServiceCanvasClose, children } = props;
    return (
        <>
            <div className={`${st.offcanvas_newPages1}`}>
                <Offcanvas show={show} onHide={handleServiceCanvasClose} placement="end" className="newOffcanvas_width">
                    {children}
                </Offcanvas>
            </div>
        </>
    )
}

export default ServicesCategoryCanvas