import React from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";

const ViewTeamTitle = (props: any) => {
  let { showTitleView, handleTitleViewClose, userData } = props;
  // const coupons = [1,2,3,4,5,6,7];
  return (
    <>
      <Modal
        centered
        show={showTitleView}
        backdrop="static"
        keyboard={false}
        onHide={handleTitleViewClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>View Team Title</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleTitleViewClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div className={`${st.formBox} mb-0`}>
                <label className="form-label">Team Title</label>
                <p className="m-0" style={{ color: "gray", fontSize: "14px" }}>
                  {userData?.row?.teamtitle}
                </p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={handleTitleViewClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewTeamTitle;
