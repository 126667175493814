import React from 'react';
import cx from './index.module.scss';
import { GoArrowUp } from 'react-icons/go';

export default function DataCard({statusPercentage,title, value } : {statusPercentage: string, title: string, value: string}) {
    return (
        <div className={`${cx.upDownSale_card} mb-3`}>
            <h3>{title}</h3>
            <h2>{value}</h2>
            <div className={`${cx.upDown_percentMain}`}>
                <div className={`${cx.upDownPercentBox} ${cx.upPercentBox}`}><GoArrowUp />{statusPercentage}%</div>
                <p>Compared to yesterday</p>
            </div>
        </div>
    )
}
