import React from "react";
import cx from "./index.module.scss";

const BusinessFooter = (props: any) => {
  return (
    <>
      <footer className={`${cx.businessFooter}`}></footer>
    </>
  );
};

export default BusinessFooter;
