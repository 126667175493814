import React from 'react';
import st from "../../../assets/stylesheet/style.module.scss";

export default function BundleServiceTable({ bundle }: any) {
    const priceList = bundle?.priceId
    const totalPrice = priceList?.reduce((acc:any, val:any) => acc + Number.parseFloat(val?.id?.seasonPrice) ,0);
    const totalDuration = priceList?.reduce((acc:any, val:any) => acc + Number.parseFloat(val?.id?.duration) ,0);
    const discount = bundle?.discount?.includes('sgd') ? `${(((totalPrice - Number.parseFloat(bundle?.finalPrice)) / totalPrice) * 100)?.toFixed(2) } %` : bundle?.discount
    
    return (
        <tbody>
            {priceList?.map((item: any, p:number) => {
                const price = item?.id;
                return (
                    <tr key={p}>
                        <td>{price?.TeamTitleData?.teamTitle || "All"}</td>
                        <td>{price?.priceText || "--"}</td>
                        <td>{price?.notes || "--"}</td>
                        <td>SGD {price?.seasonPrice?.replaceAll('sgd', '')}</td>
                        <td>{price?.duration}</td>
                    </tr>
                )
            })}
            <tr>
                <td></td>
                <td>{discount} Discount</td>
                <td><span className={`${st.textGreenTable}`} style={{color:'red' , textDecoration:"line-through"}}>{totalPrice}</span> <span>{bundle?.finalPrice} SGD</span></td>
                <td><span className={`${st.textGreenTable}`}>{totalDuration} Min</span></td>
            </tr>
        </tbody>
    )
}
