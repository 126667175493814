import React from 'react';
import cx from './index.module.scss';
import { bulb } from '../../../assets/images';

function TipsOfTheDay() {
    return (
        <div className={`${cx.notification_card} ${cx.notification_TipCard}`}>
            <img className={`${cx.tipImg}`} src={bulb} alt="tip" />
            <div className={`${cx.notification_header}`}>
                <div className={`${cx.notification_titleCount}`}>
                    <h2>Tip of the day</h2>
                </div>
            </div>

            <ul className={`${cx.notification_item}`}>
                <li>
                    <div className={`${cx.notification_ImgPara}`}>
                        <div className={`${cx.paraBox}`}>
                            <p className={`${cx.paraTop}`}>Hey! just be regular on instagram to verify your business. Have a god day</p>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    )
}

export default TipsOfTheDay