import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  list,
  rightIcon,
  call,
  editBtnBox,
  email,
  teamPerson1,
  cross,
  menuList,
} from "../../../assets/images";
import cx from "./index.module.scss";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import MonthCalendar from "./MonthCalendar";
import { DeletePopup } from "../../../components/Business/Modals";
import NewBookingModal from "../../../components/Business/Modals/BookingModal";
import MarkCompletedModal from "../../../components/Business/Modals/MarkCompleteModal";
import CalendarContactDetails from "../../../components/Business/Modals/CalendarContactDetails";
import convertHoursToHoursAndMinutes from "../../../constants/Constants";

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const Calendar = (props: any) => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const navigate = useNavigate();

  const getMonthAgoDate = () => {
    let monthAgoDate = new Date();
    let monthAgo = monthAgoDate.getMonth() - 1;
    monthAgoDate.setMonth(monthAgo);
    return monthAgoDate;
  };
  let monthAgoDate = getMonthAgoDate();
  const [listView, setListView] = useState<boolean>(false);
  const [teamHistory, setTeamHistory] = useState<any>({});
  const [temaMemberlist, setTeamMemberList] = useState<any[]>([])
  const [teamMemberId, setTeammemberId] = useState("");
  const [bookingData, setBookingData] = useState<any>([])
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [days, setDays] = useState("Month")
  const [bookinglist, setBookingList] = useState<any[]>([])
  const [bookingDetils, setBookingDetils] = useState<any>(null)
  const [show, setShow] = useState(false);
  const [serviceData, setServiceData] = useState<any>([])
  const [stylishData, setStylish] = useState<any>({})
  const [showComplete, setShowComplete] = useState(false);
  const [showBooking, setShowBooking] = useState(false);

  const handleBooking = () => setShowBooking(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleBookingShow = () => setShowBooking(true);
  const handleCompleteClose = () => setShowComplete(false);
  const handleCompleteShow = () => setShowComplete(true);
  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startCalDate, endCalDate] = dateRange;

  const [showCalendarContactDetails, setCalendarContactDetailsShow] = useState(false);
  const handleCalendarContactDetailsClose = () => setCalendarContactDetailsShow(false);
  const handleCalendarContactDetailsShow = () => setCalendarContactDetailsShow(true);

  let { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);
  
  const [userData, setUserData] = useState<any>(null)

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user_data") || "{}");
    if (userInfo?.userType === "teamMember") {
      setUserData(userInfo);
    }
  }, [])

  const canViewOwnBooking = mainModuleRoles?.Bookings?.ViewOwnBookings;
  const canViewAllBooking = mainModuleRoles?.Bookings?.ViewAllBookings;

  const canCreateOwnBooking = mainModuleRoles?.Bookings?.CreateOwnAppointment;
  const canCreateAllBooking = mainModuleRoles?.Bookings?.CreateAllAppointments;

  const bookingObj = {
    canCreateAllBooking, canCreateOwnBooking
  }

  useEffect(() => {
    if (branchId) {
      getTeam()
    }
  }, [branchId]);
  const redirectToChangePage = (client: any, appointment: any) => {
    navigate(`/business/chat`, { state: { client, appointment } });
  };

  const getTeam = async () => {
    let res = await httpRequest(`getAllTeamMembers?branchId=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      const filterTeam = res?.data?.filter((team: any) => team?.Role?.length !== 0 && team?.Role?.some((item: any) => {
        if (item?.branch?._id === branchId) {
          return item?.role?.some((roleItem: any) => roleItem?.customerService === true)
        }
      }))
      setTeamMemberList(filterTeam);
    }
  }

  useEffect(() =>{
    if(userData && !canViewAllBooking && canViewOwnBooking){
      setTeammemberId(userData?._id)
    }
  },[userData, mainModuleRoles?.Bookings])

  const handleTeamChange = (e: any) => {
    setTeammemberId(e)
  }

  let bookingDate = (e: any) => {
    setBookingData(e)
  }
  let handleClick = (e: any) => {
    setStylish(e)
    handleCalendarContactDetailsShow()
  }
  const changeFormatDate = (selectDate: any) => {
    if (!selectDate) {
      return "0000-00-00"
    }
    var date = new Date(selectDate);

    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);  // Adding 1 to the month because months are zero-indexed
    var day = ('0' + date.getDate()).slice(-2);
    var localISOString = `${year}-${month}-${day}`;
    return localISOString
  }

  let getCalenderListView = async () => {
    const formattedStartDate = changeFormatDate(startCalDate);
    const formattedEndDate = changeFormatDate(endCalDate);

    let res = await httpRequest(`getTeamMembersCalenderListView?branchId=${branchId}&businessId=${businessData?._id}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&teamMemberId=${teamMemberId}`, "get", null, "json");
    if (res.status) {      
      let compareDates = (a: { bookingDate: string }, b: { bookingDate: string }) => {
        return new Date(a.bookingDate).getTime() - new Date(b.bookingDate).getTime();
      };
      let sortData = res.filteredSubBooking.sort(compareDates);
      setBookingList(res.filteredSubBooking.reverse());
      setTeamHistory(res?.count)
    }
  }


  let deleteService = async () => {
    let data = {
      serviceObj: serviceData
    }
    let res = await httpRequest(`deleteSubBooking?bookingId=${serviceData?.bookingId}&serviceId=${serviceData?._id}`, "PATCH", data, "json");
    if (res.status) {
      handleClose()
      getCalenderListView()
    }
  }
  let updateService = async () => {
    serviceData.bookingStatus = "Completed"
    let data = {
      serviceObj: serviceData
    }
    let res = await httpRequest(`updateSubBooking/${serviceData?.bookingId}`, "PATCH", data, "json");
    if (res.status) {
      handleClose()
      getCalenderListView()
    }
  }

  const handleCurrentMonth = (type: any) => {
    if (days === "Month") {
      if (type == "Prev") {
        setCurrentMonth((prevMonth) => (prevMonth - 1 + 12) % 12);
        if (currentMonth === 0) {
          setCurrentYear((prevYear) => prevYear - 1);
        }
      } else if (type == "Next") {
        setCurrentMonth((prevMonth) => (prevMonth + 1) % 12);
        if (currentMonth === 11) {
          setCurrentYear((prevYear) => prevYear + 1);
        }
      } else {
        setCurrentYear(new Date().getFullYear())
        setCurrentMonth(new Date().getMonth());
        setDateRange([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)])
      }
    } else if (days === "Day") {
      if (type == "Prev") {
        const currentDate = new Date(startCalDate);
        const oneDayBefore = new Date(currentDate);
        oneDayBefore.setDate(currentDate.getDate() - 1);
        setDateRange([oneDayBefore, oneDayBefore])
      } else if (type == "Next") {
        const currentDate = new Date(startCalDate);
        const oneDayBefore = new Date(currentDate);
        oneDayBefore.setDate(currentDate.getDate() + 1);
        setDateRange([oneDayBefore, oneDayBefore])
      } else {
        setDateRange([new Date(), new Date()])
      }
    } else if (days === "3Days") {
      if (type == "Prev") {
        const oneDayBefore = new Date(startCalDate);
        oneDayBefore.setDate(startCalDate.getDate() - 2);
        setDateRange([oneDayBefore, startCalDate])
      } else if (type === "Next") {
        const currentDate = new Date(endCalDate);
        const oneDayBefore = new Date(currentDate);
        oneDayBefore.setDate(currentDate.getDate() + 2);
        setDateRange([endCalDate, oneDayBefore])
      } else {
        const oneDayBefore = new Date();
        oneDayBefore.setDate(new Date().getDate() + 2);
        setDateRange([new Date(), oneDayBefore])
      }
    } else {
      if (type == "Prev") {
        const oneDayBefore = new Date(startCalDate);
        oneDayBefore.setDate(startCalDate.getDate() - 7);
        setDateRange([oneDayBefore, startCalDate])
      } else if (type === "Next") {
        const currentDate = moment(new Date()).startOf('week');
        const weekStartDate = currentDate.format('YYYY-MM-DD');
        const weekEndDate = currentDate.add(6, 'days').format('YYYY-MM-DD');

        const next7Days = new Date(weekStartDate);
        next7Days.setDate(new Date().getDate() + 7);
        setDateRange([endCalDate, next7Days])
      } else {
        const currentDate = moment(new Date()).startOf('week');
        const weekStartDate = currentDate.format('YYYY-MM-DD');
        const weekEndDate = currentDate.add(6, 'days').format('YYYY-MM-DD');
        setDateRange([new Date(weekStartDate), new Date(weekEndDate)])
      }
    }
  }
  const handleSubmit = (item: any) => {
    setShow(true)
    setServiceData(item)
  }
  const handleUpdateStatus = (item: any) => {
    setServiceData(item)
    handleCompleteShow()
  }
  useEffect(() => {
    if (branchId) {
      getCalenderListView();
    }
    if (teamMemberId === "") {
      setListView(false)
    }
  }, [teamMemberId, branchId, dateRange]);

  useEffect(() => {
    if (days === "Day") {
      setDateRange([new Date(), new Date()])
    } else if (days === "3Days") {
      const ThreeDays = moment(new Date()).format('YYYY-MM-DD');
      let currentDate = new Date();
      let afterday = currentDate.setDate(currentDate.getDate() + 2);
      let newarr: any = [new Date(), new Date(afterday)]
      setDateRange(newarr)
    } else if (days === "Week") {
      const currentDate = moment(new Date()).startOf('week');
      const weekStartDate = currentDate.format('YYYY-MM-DD');
      const weekEndDate = currentDate.add(6, 'days').format('YYYY-MM-DD');
      setDateRange([new Date(weekStartDate), new Date(weekEndDate)])
    } else if (days === "Month") {
      const firstDay = moment(new Date(currentYear, currentMonth, 1)).format('YYYY-MM-DD');
      const lastDay = moment(new Date(currentYear, currentMonth + 1, 0)).format('YYYY-MM-DD');
      setDateRange([new Date(currentYear, currentMonth, 1), new Date(currentYear, currentMonth + 1, 0)])
    }
  }, [currentMonth, days]);

  let groupByDate = (bookings: any) => {
    const grouped: any[] = [];
    bookings.forEach((event: any) => {
      const date: any = event?.bookingDate;
      const existingGroup = grouped.find(group => group?.date === date);
      if (existingGroup) {
        existingGroup?.events?.push(event);
      } else {
        grouped?.push({ date, events: [event] });
      }
    });
    return grouped;
  }
  const groupedByDateList = groupByDate(bookinglist);

  const getBooking = async (bookId: string) => {
    let res = await httpRequest(`getBooking?branchId=${branchId}&businessId=${businessData?._id}&_id=${bookId}`, "get", null, "json");
    if (res.status) {
      setBookingDetils(res?.data[0]);
    }
  }
  function customDateChangeHandler(daterange: any) {
    setDateRange(daterange);
    setDays("Select")
  }
  const handleEditBooking = (id: string) => {
    getBooking(id);
    handleBookingShow();
  }
  let showButton = (id: any) => {
    setTeammemberId(id)
  }


  const branchTeamList: any = !canViewAllBooking && canViewOwnBooking && userData !== null ? temaMemberlist?.filter((item: any) => item?._id === userData?._id) : temaMemberlist  

  return (
    <>
      {(showBooking && bookingDetils) && <NewBookingModal
        showBooking={showBooking}
        handleBooking={handleBooking}
        data={bookingDetils}
        getCalenderListView={getCalenderListView}
      />}
      <MarkCompletedModal
        showComplete={showComplete}
        handleCompleteClose={handleCompleteClose}
        updateService={updateService}
        usertype={"Calendar"}
      />
      <DeletePopup show={show} deleteApi={deleteService}
        handleClose={handleClose} />
      <section className={`${st.pageWrapper} ${cx.section}`}>
        <div className={`${st.pageTitle} ${cx.topTitle} mb-3`}>
          <div
            className={`${st.titleInfo} ${cx.top} w-120`}
          >
            <h5>Summary</h5>
            <div>
              <p className={cx.hours}>Total number of hours</p>
              <p className={cx.number}>{listView ? convertHoursToHoursAndMinutes(teamHistory?.totalHours) : convertHoursToHoursAndMinutes(bookingData?.totalHours)} </p>
            </div>
            <div>
              <p className={cx.hours}>Free hours</p>
              <p className={cx.number}>{listView ? convertHoursToHoursAndMinutes(teamHistory?.freeHours) : convertHoursToHoursAndMinutes(bookingData?.freeHours)} </p>
            </div>
            <div>
              <p className={cx.hours}>Total Number of appointments served </p>
              <p className={cx.number}>{listView ? teamHistory?.totalBooking : bookingData?.totalBooking} </p>
            </div>
            <div className={`${st.rightInfo} ${cx.calendyFlex}`} >
              <div className={`${st.buttonsAction} ${cx.buttonSwitch}`}>
                <ul>
                  <li>
                    <div className="filterDropdown">
                    </div>
                  </li>
                  {/* <li onClick={() => setListView(!listView)}>
                    {teamMemberId?.length > 1 ? (
                      <NavLink to="#" className={`btn ${st.darkBtn}`}>
                        <img src={!listView ? list : menuList} alt="view" />{" "}
                        {!listView ? "List View" : "Calendar View"}
                      </NavLink>) : ""}
                  </li> */}
                  <li onClick={() => navigate("/business/bookings")}>
                    <NavLink to="#" className={`btn ${st.darkBtn}`}>
                      Go back to Booking
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {listView && (
          <Row className="align-items-center">
            <Col lg={2} md={5} sm={6}>
              <div>
                {/* <p className={cx.hours}>Team List</p> */}
                <p className={cx.number}>
                  <select
                    className="form-select" onChange={(e) => handleTeamChange(e.target.value)} value={teamMemberId}>
                    {branchTeamList?.map((item: any) => {
                      return <option key={item?._id} value={item?._id}>{item?.firstName} {item?.lastName}</option>;
                    })}

                  </select>
                </p>
              </div>
            </Col>
            <Col lg={2} md={5} sm={6}>
              <div>
                {/* <p className={cx.hours}>Team List</p> */}
                <p className={cx.number}>
                  <select
                    className="form-select" onChange={(e) => setDays(e.target.value)} value={days} >
                    <option value="">Select</option>
                    <option value="Month"> Month</option>
                    <option value="Day"> Day </option>
                    <option value="3Days"> 3 Days</option>
                    <option value="Week"> Week</option>
                  </select>
                </p>
              </div>
            </Col>
            <Col lg={3} md={5} sm={6}>
              <div className="col-xl-12">
                <div className="date_withClose_width">
                  <DatePicker
                    selectsRange={true}
                    startDate={startCalDate}
                    endDate={endCalDate}
                    onChange={(update) => {
                      customDateChangeHandler(update);
                    }}
                    isClearable={true}

                  />
                </div>
              </div>
            </Col>

            <Col lg={2} md={5} sm={6} >
              <div className="col-xl-11">
                {days === "Month" && <div className={`${st.formBox} ${cx.changeDateBox} `}>
                  <h5 style={{ backgroundColor: '#fff', lineHeight: '37px', borderRadius: '4px', position: 'relative' }}>{months[currentMonth]}</h5>
                </div>}
              </div>
            </Col>
            <Col lg={3} md={5} sm={6}>
              <div className={`${st.formBox} ${cx.changeDateBox} `}>
                <div className={`${cx.btnBox}`}>
                  <button onClick={(e) => handleCurrentMonth("Current")} className={cx.today}>Today</button>
                  <button onClick={(e) => handleCurrentMonth("Prev")} className={cx.leftBtn}><MdOutlineChevronLeft className={`${cx.left}`} /></button>
                  <button onClick={(e) => handleCurrentMonth("Next")} className={cx.rightBtn}><MdOutlineChevronRight className={`${cx.left} ${cx.right}`} /></button>
                </div>

              </div>
            </Col>
          </Row>
        )}

        <div>
          {!listView ? (
            <Row>
              <Col lg={12} md={12}>
                <MonthCalendar teamMemberid={teamMemberId} bookingObj={bookingObj} bookingDate={bookingDate} temaMemberData={branchTeamList} showButton={showButton} />
                {/* <CalendarData2 /> */}
              </Col>

            </Row>
          ) : bookinglist?.length === 0 ? (
            <div className={cx.emptyBooking}>
              <h5>No Booking Found</h5>
            </div>
          ) : (
            <>
              <div className={cx.view_Slot_Div}>
                {groupedByDateList?.map((item: any) => {

                  return (
                    <><ul>
                      <li>
                        <div className={cx.view_Slot_day}>
                          {moment(item?.date).format('dddd Do MMM, YYYY')}
                        </div>
                      </li>
                    </ul>
                      {item?.events?.map((item: any) => {
                        return (
                          <div className={cx.detailSlot_Box}>
                            <div className={cx.slotTime} style={{ borderColor: "#63A1E9" }}>
                              <h4>{item?.startTime} - {item?.endTime} </h4>
                              <p className={cx.slotCategory}>{item?.serviceDetails?.serviceName}</p>

                              <div className={cx.slotIMg}>
                                <img
                                  className={cx.imgperson}
                                  src={item?.TeamMemberId?.[0]?.image || teamPerson1}
                                  alt="person" />
                                <div className={cx.name}>
                                  <h5>{item?.TeamMemberId?.[0]?.firstName} {item?.TeamMemberId?.[0]?.lastName}</h5>

                                  <div className={cx.call_mail}>
                                    <NavLink to={"#"} onClick={() => handleClick(item?.TeamMemberId?.[0])}>
                                      <img src={call} alt="call" />
                                    </NavLink>
                                    <NavLink to="#" onClick={(e) => {
                                      e.preventDefault();
                                      redirectToChangePage(item?._id, item?.bookingId);
                                    }}>
                                      <img src={email} alt="e-mail" />
                                    </NavLink>

                                  </div>
                                </div>
                              </div>

                              <div className={cx.slotPrice}>
                                {/* <p>75USD</p> */}
                                <span>|</span>
                                {item?.amenitiesId?.map((amenit: any) => amenit?.itemName?.itemName).join(",")}
                              </div>
                              <ul className={cx.slotAccecpt}>
                                <li>
                                  <Button onClick={(e) => handleUpdateStatus(item)} disabled={item?.bookingStatus === "Completed" || Date.now() <= Date.parse(`${item?.bookingDate} ${item?.startTime}`)}>
                                    {<img src={rightIcon} alt="Complete" style={{ filter: item?.bookingStatus === "Completed" ? "grayscale(1)" : "", opacity: item?.bookingStatus === "Completed" ? "0.5" : "1" }} />}
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    onClick={(e) => handleEditBooking(item?.bookingId)}
                                    disabled={Date.now() >= Date.parse(`${item?.bookingDate} ${item?.startTime}`)}
                                  >
                                    <img src={editBtnBox} alt="edit" />
                                  </Button>
                                </li>
                                <li>
                                  <Button onClick={(e) => { handleSubmit(item) }}>
                                    <img src={cross} alt="edit" />
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </section>

      <CalendarContactDetails show={showCalendarContactDetails} data={stylishData} handleCalendarContactDetailsClose={handleCalendarContactDetailsClose} />
    </>
  );
};

export default Calendar;
