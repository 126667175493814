import React, { Fragment, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import BusinessHeader from "../BusinessHeader";
import BusinessSidebar from "../BusinessSidebar";
import BusinessFooter from "../BusinessFooter";
import { getBusinessCategory, getBusinessData, getBusinessUserRoles } from "../../../redux_toolkit/reducer/shareDataReducer";
import { useDispatch } from "react-redux";
import { changeAccessRolesState } from "../../../redux_toolkit/reducer/branchReducer";
import { fullAccessRolesObj } from "../../../Utils/Funcs";
import { BusinessDesktopSideBar } from "..";
import { getHelpDocument } from "../../../redux_toolkit/reducer/HelpAndDocumentsReducer";

export default function BusinessNavigation() {
  const [screenSize, setScreenSize] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    let udata = localStorage.getItem('user_data');
    let userdata = udata ? JSON.parse(udata) : '';
    if (userdata?._id) {
      let bid = '';
      if (userdata?.userType === "teamMember") {
        bid = `businessId=${userdata?.businessId}`;
        dispatch(getBusinessUserRoles({ id: userdata?.businessId }));
      } else {
        bid = `id=${userdata?._id}`;
        let fullAccessRoles = fullAccessRolesObj();
        dispatch(changeAccessRolesState({ mainModuleRoles: fullAccessRoles.mainModuleRoles, subModuleRoles: fullAccessRoles.subModuleRoles }));
      }
      dispatch(getBusinessData({ id: bid }));
      dispatch(getBusinessCategory());
    }
  }, []);

  const admin_token = localStorage.getItem("user_token");
  const handleResize = () => {
    const screenWidth = window.innerWidth;
    setScreenSize(screenWidth?.toString())
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    if (screenSize === "") {
      const screenWidth = window.innerWidth;
      setScreenSize(screenWidth?.toString())
    }

    dispatch(getHelpDocument());
  }, [])

  return (
    <>
      {admin_token ? (
        <div className={`${st.font} ${cx.businessWrapper}`}>
          <BusinessHeader />
          <BusinessDesktopSideBar />
          {/* {Number(screenSize) > 767 ? <BusinessDesktopSideBar /> : <BusinessSidebar />} */}
          <Outlet />
          <BusinessFooter />
        </div>
      ) : (
        <Navigate to="/business/login"></Navigate>
      )}
    </>
  );
}
