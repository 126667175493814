import React from "react";
import cx from "./index.module.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Row } from "react-bootstrap";
import { union, facebookIcon, instagram } from "../../../assets/images";

export default function ViewAbout() {
    const navigate = useNavigate();
    const datalocation: any = useLocation();
    let state: any = datalocation?.state;

    console.log(state, "state?.briefAbout");
    

    return (
        <>
            <Row>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                        <div>
                            <h2>Social Media</h2>
                            {/* <span
                                className={`${cx.smallText}`}
                                style={{ color: "#808080" }}
                            >
                                Loram buipsim tipsum gipsum some more sum, since it’s
                                sum lets say loram{" "}
                            </span> */}
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={4} md={6}>
                    <div className={cx.aboutInfo}>
                        <img src={union} alt="website" />
                        <span> {state?.website ? state?.website : "No website url to show"}</span>
                    </div>
                </Col>

                <Col lg={4} md={6}>
                    <div className={cx.aboutInfo}>
                        <img src={facebookIcon} alt="facebook-icon" />
                        <span>{state?.facebook ? state?.facebook : "No facebook url to show"}</span>
                    </div>
                </Col>
                <Col lg={4}></Col>

                <Col lg={4} md={6}>
                    <div className={cx.aboutInfo}>
                        <img src={instagram} alt="insta-icon" />
                        <span>{state?.instagram ? state?.instagram : "No instagram url to show"}</span>
                    </div>
                </Col>
                <Col lg={4}></Col>

                <Col md={12}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Brief Summary about your business</label>
                        <div className="position-relative" dangerouslySetInnerHTML={{ __html: state?.briefAbout }} />
                    </div>
                </Col>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">About</label>
                        <div className="position-relative" dangerouslySetInnerHTML={{ __html: state?.about }} />
                    </div>
                </Col>

                <Col md={12} className="mt-3">
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                        <div>
                            <h2>Gallery</h2>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                {state?.image?.map((item: any, index: number) => (
                    <Col xl={2} lg={3} md={4} sm={4} xs={6} key={index}>
                        <div className={`${cx.uploadImageBox} `}>
                            <img src={item} className={cx.bgImage} alt="img" />
                        </div>
                    </Col>
                ))}
                {state?.image?.length < 1 && <p>No Images to show</p>}
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => navigate(-1)}
                            >
                                Back
                            </button>

                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
