import React, { useEffect, useRef, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import background from "../../../assets/images/background.jpg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { disablePastDate } from "../../../Utils/Funcs";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Button, Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import {
  editIcon,
  basicInfo,
  basicInfoA,
  plus,
  location,
  upArrow,
  calendar,
  about,
  clock,
  clockA,
  aboutA,
  iconPhone,
  iconEmail,
  menu,
  crossDelete,
  union,
  facebookIcon,
  instagram,
  deleteCross,
  editButton,
  deleteIcon,
} from "../../../assets/images";
import { v4 as uuidv4 } from 'uuid';
import { AiFillCamera, AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AssignTeam } from "../../../components/Business/Modals";
import { Switch } from "@mui/material";
import AddAmeneties from "../../../components/Business/Modals/AddAmeneties";
import { AddNewCategoryBtn } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
// import PhoneInput from "react-phone-input-2";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

import CustomTimeSelect from "../../../constants/CustomTimeSelect";
import { timeValues, weekValues } from "../../../constants/Constants";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import { getBusinessCategory, getBusinessData } from "../../../redux_toolkit/reducer/shareDataReducer";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
const label = { inputProps: { "aria-label": "Switch demo" } };

interface showEditTypes {
  basicInfoEdit: boolean;
  aboutEdit: boolean;
  workingHoursEdit: boolean;
}
interface FormValues {
  [key: string]: string;
}
const BranchHome = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const options = {
    dots: false,
    loop: false,
    margin: 10,
    items: 4,
    autoplay: false,
    nav: true,

    responsive: {
      0: {
        items: 1.7,
        nav: false
      },
      480: {
        items: 3,
      },
      768: {
        items: 3,
      },
      992: {
        items: 5,
      },
      1250: {
        items: 7,
      }
    }
  };

  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [errorTrig, setErrorTrig] = useState(false);
  const [fullDayCheck, setFullDayCheck] = useState(false);
  const [tab, settab] = useState("tabNew1");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    getValues,
    setValue
  } = useForm({
    mode: "onTouched",
  });

  const [branchData, setBranchData] = useState<any>(null);

  useEffect(() => {
    if (branchData?.workingHours[0]?.sameEveryDay) {
      setIsSameEveryDay(true)
    } else {
      setIsSameEveryDay(false)
    }
  }, [branchData])


  const [editWorkHrsData, setEditWorkHrsData] = useState<any>(null);
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  let { allBusCategories } = useSelector((state: any) => state.shareDataReducer);
  const [dayWiseOff, setDayWiseOff] = useState<any>([]);
  const [dayWisePeakOff, setDayWisePeakOff] = useState<any>([]);
  const [showEdit, setShowEdit] = useState<showEditTypes | any>({
    basicInfoEdit: false,
    aboutEdit: false,
    workingHoursEdit: false,
    offPeakEdit: false,
  });


  useEffect(() => {
    if (branchData?.workingHours?.length !== 0) {
      setEditWorkHrsData(branchData?.workingHours)
    }
  }, [branchData])
  useEffect(() => {
    const prevBId = localStorage.getItem("currentBranchId");
    if (branchId && branchId !== prevBId) {
      settab("tabNew1")
    }
  }, [branchId]);

  const [businessNameEdit, setBusinessNameEdit] = useState<{
    businessName: boolean;
    taxRate: boolean;
    employeeId: boolean;
  }>({
    businessName: false,
    taxRate: false,
    employeeId: false,
  });
  let initialVals = {
    website: '',
    facebook: '',
    instagram: '',
    about: '',
    briefAbout: "",
  }

  useEffect(() => {
    dispatch(getBusinessCategory());
  }, [businessData])

  const [content, setContent] = useState<string>("");
  const [showContactDetail, setShowContactDetail] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<any>("");
  const [selectedCatIds, setSelectedCatIds] = useState<any>([]);
  const [phone1, setphone1] = useState(branchData?.phoneNumber1 || "");
  const [phone2, setphone2] = useState(branchData?.phoneNumber2 || "");
  const [phone3, setphone3] = useState(branchData?.phoneNumber3 || "");
  const [brieferr, setBrieferr] = useState("")
  const [error, setError] = useState("");
  const [images, setImages] = useState<any>([]);
  const [aboutData, setAboutData] = useState<any>(initialVals);
  const [previewImage, setPreviewImage] = useState<any>([]);
  const [branchName, setBranchName] = useState<any>("");
  const [abouterror, setAbouterror] = useState<any>(false)
  const [caterror, setCaterror] = useState<any>(false)
  const [oldImage, setoldImage] = useState<any>([]);
  // const [tab,settab] = useState(tabNew1)
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState('');
  const handleAlertModalClose = () => setShowErrorModal('');
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

  useEffect(() => {
    const prevBId = localStorage.getItem("currentBranchId");
    if (branchId && branchId !== prevBId) {

    }
  }, [branchId]);

  useEffect(() => {
    if (branchData) {
      setAboutData({
        website: branchData?.website ? branchData?.website : businessData?.website ? businessData?.website : "",
        facebook: branchData?.facebook ? branchData?.facebook : businessData?.facebook ? businessData?.facebook : '',
        instagram: branchData?.instagram ? branchData?.instagram : businessData?.instagram ? businessData?.instagram : '',
        about: branchData?.about ? branchData?.about : businessData?.about ? businessData?.about : '',
        briefAbout: branchData?.briefAbout ? branchData?.briefAbout : businessData?.briefAbout ? businessData?.briefAbout : '',
      });
      let imagelist: any = branchData?.image ? branchData?.image : businessData?.image;
      if (branchData?.image?.length > 0) {

        setoldImage(branchData?.image)
      } else {
        setoldImage(businessData?.image);
      }
      setphone1(() => {
        return branchData?.phoneNumber1 ? `${branchData?.phoneNumber1?.includes("+") ? "" : "+"}${branchData?.phoneNumber1}` : ""
      })
      setphone2(() => {
        return branchData?.phoneNumber2 ? `${branchData?.phoneNumber2?.includes("+") ? "" : "+"}${branchData?.phoneNumber2}` : ""
      })
      setphone3(() => {
        return branchData?.whatsAppNumber ? `${branchData?.whatsAppNumber?.includes("+") ? "" : "+"}${branchData?.whatsAppNumber}` : ""
      })
    }
  }, [branchData]);
  useEffect(() => {
    if (branchId) {
      getBranches();
    }
  }, [branchId]);

  const handleAbout: any = (newContent: string, type: string) => {
    if (type === "brief") {
      if (newContent.length <= 900) {
        const prevStr = aboutData?.briefAbout || ""
        setAboutData({ ...aboutData, briefAbout: newContent })
        setBrieferr("")
      } else {
        setAboutData({ ...aboutData, briefAbout: newContent })
        setBrieferr("Maximum character limit (900) exceeded!");
      }
    } else {
      setAboutData({ ...aboutData, about: newContent })
    }
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["link"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["clean"],
    ],
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showAmeneties, setAmenetiesShow] = useState(false);
  const handleAmenetiesClose = () => setAmenetiesShow(false);
  const [offPeakList, setOffPeakList] = useState<any>([])
  const handleAmenetiesShow = () => setAmenetiesShow(true);
  const [editId, setEditId] = useState<string | null>(null);
  function handleCatSelect(id: string) {
    if (phoneError !== "") {
      setPhoneError("");
    }
    let newSelected: any = [...selectedCatIds];
    const selectedIndex = newSelected.indexOf(id);
    if (selectedIndex >= 0) {
      newSelected.splice(selectedIndex, 1);
    } else {
      newSelected.push(id);
    }
    setSelectedCatIds(newSelected);
  }
  const getBranches = async () => {
    let res = await httpRequest(
      `getBranch?_id=${branchId}`,
      "get",
      null,
      "json"
    );
    if (res.status) {
      let branchlist = res?.data[0];
      setBranchData(branchlist);
      let selectedCats = branchlist?.businessCatagoryId?.map((item: any) => item?._id)
      setSelectedCatIds(selectedCats);
      setBranchName(res?.data[0]?.branchName)
    }
  };
  useEffect(() => {
    if (branchData && branchData?.notWorkingHours?.length !== 0) {
      setSelectedNonOpHrs(branchData?.notWorkingHours?.map((data: any, i: any) => {
        return {
          fromDate: data?.fromDate,
          toDate: data?.toDate,
          isFullDay: data?.isFullDay === "true",
          from: fullDayCheck ? '' : data.from,
          to: fullDayCheck ? '' : data.to,
        }
      }))
    }
  }, [branchData])
  const updateBranch = async (postdata: any) => {
    // if (!phone1) {
    //   setErrorTrig(true);
    //   return
    // }
    // if (phone1 && !isValidPhoneNumber(phone1)) {
    //   setErrorTrig(true);
    //   return
    // }
    if (selectedCatIds?.length < 1) {
      setCaterror(true)
      return;
    } else {
      const formdata = new FormData();
      formdata.append("email", postdata?.email);
      formdata.append("phoneNumber1", phone1 === "+" && phone1?.includes("undefined") ? "" : phone1);
      formdata.append("phoneNumber2", phone2);
      formdata.append("whatsAppNumber", phone3);
      formdata.append("city", postdata?.city);
      formdata.append("postalCode", postdata?.postalCode);
      formdata.append("address1", postdata?.address1);
      formdata.append("address2", postdata?.address2);
      formdata.append("landmark", postdata?.landmark);
      formdata.append('oldImages', JSON.stringify(oldImage));
      formdata.append("businessCatagoryId", JSON.stringify(selectedCatIds));
      if (postdata?.newbranchName && postdata.newbranchName !== branchData?.branchName) {
        formdata.append("branchName", postdata?.newbranchName);
      }
      setLoading(true)
      const res = await httpRequest(
        `updatebranch/${branchId}`,
        "PATCH",
        formdata,
        "formdata"
      );
      if (res.status) {
        setShowEdit({
          ...showEdit,
          basicInfoEdit: false,
        });
        getBranches()
        let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
        if (userdata !== '' && userdata?._id) {
          let bid = '';
          if (userdata?.userType === "teamMember") {
            bid = `businessId=${userdata?.businessId}`;
          } else {
            bid = `id=${userdata?._id}`;
          }
          dispatch(getBusinessData({ id: bid }));
          dispatch(getBusinessCategory());
        }

      } else {
        handleAlertModalShow(`Error, ${res.message}`);
      }
      setLoading(false);
    }
  };
  async function handleBranchUpdate() {

    if (aboutData?.briefAbout?.length >= 900) {
      setBrieferr("Input too long: exceeds 900-character limit.");
      return;
    }
    // if (aboutData?.website.length < 5) {
    //   setAbouterror(true)
    // } else if (aboutData?.facebook?.length < 5) {
    //   setAbouterror(true)
    // } else if (aboutData?.instagram.length < 5) {
    //   setAbouterror(true)
    // } 
    // else {
    let formdata = new FormData();
    // if (aboutData?.website && aboutData.website !== branchData?.website) {
    //   formdata.append('website', aboutData.website);
    // } 
    if (aboutData?.facebook && aboutData?.facebook !== branchData?.facebook) {
      formdata.append('facebook', aboutData?.facebook);
    } if (aboutData?.instagram && aboutData.instagram !== branchData?.instagram) {
      formdata.append('instagram', aboutData.instagram);
    } if (aboutData?.about && aboutData.about !== branchData?.about) {
      formdata.append('about', aboutData.about);
    }
    if (aboutData?.briefAbout && aboutData?.briefAbout !== branchData?.briefAbout) {
      formdata.append('briefAbout', aboutData?.briefAbout);
    }
    formdata.append('oldImages', JSON.stringify(oldImage));
    formdata.append('website', aboutData.website);
    if (images.length > 0) {
      for (var i = 0; i < images.length; i++) {
        formdata.append("image", images[i]);
      }
    }

    setLoading(true);
    let res = await httpRequest(`updatebranch/${branchId}`, "PATCH", formdata, "formdata");
    if (res.status === true) {
      setShowEdit({
        ...showEdit,
        aboutEdit: false,
      });
      getBranches()
      setPreviewImage([]);
      setImages([]);

    } else {

    }
    setLoading(false)
    // }



    // let alertMsg = res.status ? `Success, ${res.message}` : `Error, ${res.message}`;
    // dispatch(changeAlertCountState({msg: alertMsg}));
    // setShowEdit(false);
  }


  const prefilledCountry = businessData?.countryCode
    ? businessData?.countryCode
    : "sg";

  const handlePhone1Change: any = (value: any, selectedCountry: any) => {
    setphone1(value || "");
    if (phoneError !== "") {
      setPhoneError("");
    }
  };


  const handlePhone2Change: any = (value: any, selectedCountry: any) => {
    setphone2(value);
    if (phoneError !== "") {
      setPhoneError("");
    }
  };

  const handlePhone3Change: any = (value: any, selectedCountry: any) => {
    setphone3(value);
    if (phoneError !== "") {
      setPhoneError("");
    }
  };
  const filteredCats = allBusCategories?.filter((item: any) => selectedCatIds?.includes(item?._id));

  function onFileChange(event: any) {
    let files = event.target.files;
    let profileImg = [...images];
    for (let i = 0; i < files.length; i++) {
      let image: File = files[i];
      profileImg.push(image);
      if (files[i]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && e.target.result) {
            let img = e.target.result as string;
            setPreviewImage((prevImages: any) => [...prevImages, img]);
          }
        };
        reader.readAsDataURL(files[i]);
      }
    };
    setImages(profileImg);
  }

  function handleRemoveImg(id: string) {
    let newSelected: any = [...previewImage];
    newSelected.splice(id, 1);
    setPreviewImage(newSelected);
    let imgs: any = [...images];
    imgs.splice(id, 1);
    setImages(imgs);
  }
  function handleRemoveOldImg(id: string) {
    let newSelected: any = [...oldImage];
    newSelected.splice(id, 1);
    setoldImage(newSelected);
  }
  let BranchName: any = getValues('newbranchName');



  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const finalNotWorkingHour = selectedNonOpHrs?.map((item: any) => {
      if (item?.isFullDay) {
        return { ...item, from: "00:00", to: "23:59" }
      } else {
        return item
      }
    })

    let workingHours: any = [];
    let timeCounts: any = {}


    weekValues.forEach((elem, index) => {
      workingHours.push({
        dayName: elem.label,
        from: dayWiseOff?.[index]?.value ? "Closed" : data[`${elem.label}from`],
        to: dayWiseOff?.[index]?.value ? "Closed" : data[`${elem.label}to`],
      })
    });

    for (const entry of workingHours) {
      const combinationKey = `${entry.from}-${entry.to}`;
      if (combinationKey in timeCounts) {
        timeCounts[combinationKey]++;
      } else {
        timeCounts[combinationKey] = 1;
      }
    }
    const uniqueTimeCombinations = workingHours.filter((entry: any) => {
      const combinationKey = `${entry.from}-${entry.to}`;
      return timeCounts[combinationKey] === 1;
    });
    let sameEveryDayObj;

    if (uniqueTimeCombinations && uniqueTimeCombinations.length > 0) {

      sameEveryDayObj = { isSameEveryDay: false, from: "N/A", to: "N/A" };
    } else {
      sameEveryDayObj = { isSameEveryDay: isSameEveryDay, from: data.from, to: data.to };
    }
    let allData = {
      workingHours: JSON.stringify(workingHours),
      sameEveryDay: JSON.stringify(sameEveryDayObj),
      notWorkingHours: JSON.stringify(finalNotWorkingHour),
    }

    const formData = new FormData();
    formData.append("workingHours", allData.workingHours);
    formData.append("sameEveryDay", allData.sameEveryDay);
    formData.append("notWorkingHours", allData.notWorkingHours);
    formData.append('oldImages', JSON.stringify(oldImage));
    // formData.append("businessCatagoryId", JSON.stringify(selectedCatIds));

    setLoading(true)
    const res = await httpRequest(
      `updatebranch/${branchId}`,
      "PATCH",
      formData,
      "formdata"
    );

    if (res?.status) {
      getBranches();
      resetNonOpHrs()
      setoldImage([])
      setTimeout(() => {

        setShowEdit({ ...showEdit, workingHoursEdit: false });
      }, 1000)
      // opSetValue()
    }
    setLoading(false)
    // handleStepsFormData(allData, 'step3');
  };
  const onSubmitOffPeak: SubmitHandler<FormValues> = async (data) => {
    let OffPeakHrs: any = [];
    weekValues.forEach((elem, index) => {
      OffPeakHrs.push({
        dayName: elem.label,
        from: dayWisePeakOff?.[index]?.value ? "Not applicable" : data[`${elem.label}fromOff`],
        to: dayWisePeakOff?.[index]?.value ? "Not applicable" : data[`${elem.label}toOff`],
      })
    });
    const id = editId || uuidv4();
    const offPeakData = { isSameEveryDay: isSameOffPeak, from: data.fromOff, to: data.toOff, shift: OffPeakHrs, _id: id }
    const filterOffPEakList = offPeakList?.filter((item: any, i: any) => item?._id !== id)

    setOffPeakList([...filterOffPEakList, offPeakData])
    setIsSameOffPeak(false)
    setValue(`fromOff`, "");
    setValue(`toOff`, "");

    reset()
    setDayWisePeakOff([])
    weekValues.forEach((elem: any, index: any) => {
      let updateDayWiseOff: any = [...dayWiseOff];
      updateDayWiseOff[index] = {
        day: elem?.label,
        value: false
      }
      setValue(`${elem?.label}fromOff`, "");
      setValue(`${elem?.label}toOff`, "");
    });



    offPeakRef?.current?.scrollIntoView({ behaviour: "smooth" })
  };
  /// new data




  const handleSave = async () => {
    const formData = new FormData();
    formData.append('oldImages', JSON.stringify(oldImage));
    formData.append("offPeakHours", JSON.stringify(offPeakList));
    // formData.append("businessCatagoryId", JSON.stringify(selectedCatIds));

    setLoading(true)
    const res = await httpRequest(
      `updatebranch/${branchId}`,
      "PATCH",
      formData,
      "formdata"
    );

    if (res?.status) {
      getBranches();
      resetNonOpHrs()
      setoldImage([])
      setDayWiseOff([])
      setTimeout(() => {
        setShowEdit({ ...showEdit, offPeakEdit: false });
      }, 1000)
      // opSetValue()
    }
    setLoading(false)
  }

  const {
    register: regNonOpHrs,
    control: controlNonOpHrs,
    handleSubmit: handleSubmitNonOpHrs,
    getValues: getNonOpVals,
    setValue: setNonOpHrsValue,
    watch: watchNonOpHrs,
    formState: { errors: NonOpHrsErr },
    reset: resetNonOpHrs,
  } = useForm<FormValues>();

  const [selectedNonOpHrs, setSelectedNonOpHrs] = useState<any>([]);
  const [isSameEveryDay, setIsSameEveryDay] = useState(false);
  const [isSameOffPeak, setIsSameOffPeak] = useState(false);

  useEffect(() => {
    if (branchData) {
      reset();
      resetNonOpHrs();
      branchData?.workingHours?.map((item: any) => {
        setValue(`${item.dayName}from`, item.from === "Closed" ? "" : item.from);
        setValue(`${item.dayName}to`, item.to === "Closed" ? "" : item.to);
      })

      setDayWiseOff(branchData?.workingHours?.map((item: any) => ({ day: item.dayName, value: item.from === "Closed" })))
      // setDayWisePeakOff(branchData?.offPeakHours?.map((item: any) => ({ day: item.dayName, value: item.from === "Not applicable" })))

      if (branchData?.sameEveryDay) {
        setIsSameEveryDay(branchData?.sameEveryDay?.isSameEveryDay)
        setValue(`from`, branchData?.sameEveryDay?.from);
        setValue(`to`, branchData?.sameEveryDay?.to);
      }

      setSelectedNonOpHrs(branchData?.notWorkingHours);

      setOffPeakList(branchData?.offPeakHours)
    }
  }, [branchData]);

  const handleSwitchChange = (e: any) => {
    let isChecked = e.target.checked;
    let fromVal = isChecked ? watch('from', '') : '';
    let toVal = isChecked ? watch('to', '') : '';
    weekValues.forEach((item, index) => {
      if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
        return
      }
      setValue(`${item.label}from`, fromVal);
      setValue(`${item.label}to`, toVal);
    });
    setIsSameEveryDay(e.target.checked);
  };

  useEffect(() => {
    if (dayWiseOff?.length !== 0) {
      dayWiseOff?.map((item: any, index: number) => {

        item?.value && setValue(`${item.day}from`, "");
        item?.value && setValue(`${item.day}to`, "");
      })
    }
  }, [dayWiseOff])

  useEffect(() => {
    if (dayWisePeakOff?.length !== 0) {
      dayWisePeakOff?.map((item: any, index: number) => {
        item?.value && setValue(`${item.day}fromOff`, "");
        item?.value && setValue(`${item.day}toOff`, "");
      })
    }
  }, [dayWisePeakOff])

  function handleTimeChange() {
    if (isSameEveryDay) {
      let fromVal = watch('from', '');
      let toVal = watch('to', '');
      weekValues.forEach((item: any, index: number) => {
        if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
          return
        }
        if (fromVal) setValue(`${item.label}from`, fromVal);
        if (toVal) setValue(`${item.label}to`, toVal);
      });
    }
  }
  const handleSwitchChangeOffpeak = (e: any) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      let fromVal = isChecked ? watch('fromOff', '') : '';
      let toVal = isChecked ? watch('toOff', '') : '';
      weekValues.forEach((item: any, index: number) => {
        if (dayWisePeakOff?.[index] && dayWisePeakOff?.[index]?.value) {
          return
        }
        setValue(`${item.label}fromOff`, fromVal);
        setValue(`${item.label}toOff`, toVal);
      });
    }
    setIsSameOffPeak(e.target.checked);
  };

  function handleTimeChangeOffpeak() {
    if (isSameOffPeak) {
      let fromVal = watch('fromOff', '');
      let toVal = watch('toOff', '');

      weekValues.forEach((item: any, index: number) => {
        if (dayWisePeakOff?.[index] && dayWisePeakOff?.[index]?.value) {
          return
        }
        if (fromVal) setValue(`${item.label}fromOff`, fromVal);
        if (toVal) setValue(`${item.label}toOff`, toVal);
      });
    }
  }

  // -------------- multi start time and end time selection ----------------- //

  const onNonOpHrsSubmit: SubmitHandler<FormValues> = (data) => {
    setSelectedNonOpHrs((prev: any) => [
      ...prev,
      {
        fromDate: data.fromDate,
        toDate: data.toDate,
        isFullDay: fullDayCheck,
        from: fullDayCheck ? '' : data.from,
        to: fullDayCheck ? '' : data.to,
      }
    ]);
    setFullDayCheck(false);
    resetNonOpHrs();
    setNonOpHrsValue('fromDate', '');
    setNonOpHrsValue('toDate', '');

    setNonOpHrsValue('from', '');
    setNonOpHrsValue('to', '');
  };

  const nonOpHrsRemoveChangeHandler = (e: any, indexToRemove: any) => {
    e.preventDefault();
    const clonefield = selectedNonOpHrs.slice();
    clonefield.splice(indexToRemove, 1);
    setSelectedNonOpHrs(clonefield);
  };

  function handleCheckBoxSelection(e: any) {
    resetNonOpHrs();
    setFullDayCheck(e.target.checked);
  }

  function handleDeleteTime(i: number) {
    const filterItem = offPeakList?.filter((item: any, index: number) => index !== i);
    setOffPeakList(filterItem)
  }

  const handleEditTime = (index: any) => {
    const item = offPeakList[index];
    setEditId(item._id);

    item?.shift?.map((item: any) => {
      setValue(`${item?.dayName}fromOff`, item?.from === "Not applicable" ? "" : item?.from);
      setValue(`${item?.dayName}toOff`, item?.to === "Not applicable" ? "" : item?.to);
    })
    setDayWisePeakOff(item?.shift?.map((item: any) => ({ day: item?.dayName, value: item?.from === "Not applicable" })))

    if (item?.isSameEveryDay) {
      setIsSameOffPeak(item?.isSameEveryDay)
      setValue(`fromOff`, item?.from);
      setValue(`toOff`, item?.to);
    }
  }

  const fromOff: any = watch("fromOff")

  const resetOffPeak = async () => {
    const formData = new FormData();
    formData.append('oldImages', JSON.stringify(oldImage));
    formData.append("offPeakHours", JSON.stringify([]));
    formData.append("sameEveryDayOffPeakHours", JSON.stringify({}));
    // formData.append("businessCatagoryId", JSON.stringify(selectedCatIds));

    const res = await httpRequest(
      `updatebranch/${branchId}`,
      "PATCH",
      formData,
      "formdata"
    );

    if (res?.status) {
      resetNonOpHrs();
      setoldImage([])
      setTimeout(() => {
        getBranches();
        setShowEdit({ ...showEdit, offPeakEdit: false });
      }, 1000)
    }
  }

  const offPeakRef: any = useRef()
  const canEditBranch = subModuleRoles?.['Branch Set Up']?.['Branch Home']?.Edit;

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        {/* <AddPopup /> */}
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo} w-auto`}>
            <h5>{branchData?.branchName}</h5>
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" activeKey={tab} defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1" onClick={() => settab("tabNew1")}>
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew2" onClick={() => settab("tabNew2")}>
                  <img src={about} alt="about" className={`${st.defalut}`} />
                  <img src={aboutA} alt="about" className={`${st.active}`} />
                  About
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew3" onClick={() => settab("tabNew3")}>
                  <img src={clock} alt="timing" className={`${st.defalut}`} />
                  <img src={clockA} alt="timimg" className={`${st.active}`} />
                  Operating Hours
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew4" onClick={() => settab("tabNew4")}>
                  <img src={clock} alt="timing" className={`${st.defalut}`} />
                  <img src={clockA} alt="timimg" className={`${st.active}`} />
                  Off Peak Hours
                </Nav.Link>
              </Nav.Item>


            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1" onClick={() => settab("tabNew1")}>
                {!showEdit.basicInfoEdit ? (
                  <>
                    <Row>
                    <Col md={12}>
                  <div className="text-end">
                    <HelpButon number={5} type={"help"} className={"btn"} />
                  </div>
                </Col>
                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                          <div>
                            <h2>Branch</h2>
                          </div>
                          {canEditBranch && <div>
                            <Button
                              className={`${cx.editBtn}`}
                              onClick={() =>
                                setShowEdit({
                                  ...showEdit,
                                  basicInfoEdit: true,
                                })
                              }
                            >
                              <img src={editButton} alt="edit" />
                            </Button>
                          </div>}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={4}>
                            <div className={`${st.formBox} ${cx.iconPos}`}>
                              <label className="form-label">
                                Business Country
                              </label>
                              <div className="position-relative d-flex align-items-center gap-1">
                                <img src={location} alt="budiness icon" />
                                <p className="m-0">
                                  {" "}
                                  {branchData?.businessId?.country}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className={`${st.formBox} ${cx.iconPos}`}>
                              <label className="form-label">Business ID</label>
                              <div className="position-relative d-flex align-items-center gap-1">
                                <p className="m-0">
                                  {" "}
                                  #
                                  {
                                    branchData?.businessId
                                      ?.businessAccountNo
                                  }
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Branch Name</label>
                          <div className="position-relative d-flex align-items-center gap-2">
                            <div className={cx.editContainer}>
                              <p>{branchData?.branchName} </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={8} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">
                            Business Category
                          </label>
                          <div className={`${cx.busines_category_itmes}`} >
                            <ul className={`${st.iconSelectList} ${cx.icons}`}>
                              <OwlCarousel className="owl-theme " {...options}>
                                {branchData?.businessCatagoryId?.map(
                                  (item: any) => {
                                    return (
                                      <div className={'item me-0'}>
                                        <li className={`${st.active} me-0 mb-0`}>
                                          <div className={`${st.iconImg}`}>
                                            <img src={item?.Icon} alt="massage" />
                                          </div>
                                          <h6 title={item?.BusinessCategoryName}>{item?.BusinessCategoryName}</h6>
                                        </li>
                                      </div>
                                    );
                                  }
                                )}
                              </OwlCarousel>


                            </ul>
                          </div>

                        </div>
                      </Col>

                      <Col md={12} className="mb-2">
                        <h5 className="mb-1">Contact Details</h5>

                      </Col>

                      <Col lg={6} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Email</label>
                          <div
                            className={`position-relative d-flex align-items-center gap-2 ${cx.colorP}`}
                          >
                            <div className={cx.editContainer}>
                              <p>{branchData?.email}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Phone number 1</label>
                          <div
                            className={`position-relative d-flex align-items-center gap-2 ${cx.colorP}`}
                          >
                            <div className={cx.editContainer}>
                              <p>{branchData?.phoneNumber1 && branchData?.phoneNumber1 !== "undefined" ? branchData?.phoneNumber1 : ""}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Phone number 2</label>
                          <div
                            className={`position-relative d-flex align-items-center gap-2 ${cx.colorP}`}
                          >
                            <div className={cx.editContainer}>
                              <p>{branchData?.phoneNumber2?.length < 4 ? "" : branchData?.phoneNumber2}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">WhatsApp number</label>
                          <div
                            className={`position-relative d-flex align-items-center gap-2 ${cx.colorP}`}
                          >
                            <div className={cx.editContainer}>
                              <p>{branchData?.whatsAppNumber}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={12} className="mt-3 mb-2">
                        <h5>Address</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">City</label>
                          <div
                            className={`position-relative d-flex align-items-center gap-2 ${cx.colorP}`}
                          >
                            <div className={cx.editContainer}>
                              <p>{branchData?.city}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Postal Code</label>
                          <div
                            className={`position-relative d-flex align-items-center gap-2 ${cx.colorP}`}
                          >
                            <div className={cx.editContainer}>
                              <p>{branchData?.postalCode}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Address 1</label>
                          <div className="position-relative">
                            <div
                              className={`position-relative d-flex align-items-center gap-2 ${cx.colorP}`}
                            >
                              <div className={cx.editContainer}>
                                <p>{branchData?.address1}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Address 2</label>
                          <div className="position-relative">
                            <div
                              className={`position-relative d-flex align-items-center gap-2 ${cx.colorP}`}
                            >
                              <div className={cx.editContainer}>
                                <p>{branchData?.address2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Landmark</label>
                          <div
                            className={`position-relative d-flex align-items-center gap-2 ${cx.colorP}`}
                          >
                            <div className={cx.editContainer}>
                              <p>{branchData?.landmark}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                          <div>
                            <h2>Branch</h2>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={4}>
                            <div className={`${st.formBox} ${cx.iconPos}`}>
                              <label className="form-label">
                                Business Country
                              </label>
                              <div className="position-relative d-flex align-items-center gap-1">
                                <img src={location} alt="budiness icon" />
                                <p className="m-0">
                                  {" "}
                                  {branchData?.businessId?.country}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className={`${st.formBox} ${cx.iconPos}`}>
                              <label className="form-label">Business ID</label>
                              <div className="position-relative d-flex align-items-center gap-1">
                                <p className="m-0"> # {
                                  branchData?.businessId
                                    ?.businessAccountNo
                                }</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={4} md={4}>

                        {!businessNameEdit.businessName ? (
                          <div className={`${st.formBox} ${cx.iconPos}`}>
                            <label className={`${cx.businessName} form-label`}>
                              Branch Name
                            </label>
                            <div className="position-relative d-flex align-items-center gap-2">
                              <p className={`${cx.busenessDesc} mb-1`}>
                                {BranchName ? BranchName : branchData?.branchName}
                              </p>

                              <img
                                src={editIcon}
                                alt="edit"
                                style={{
                                  cursor: "pointer",
                                  height: "16px",
                                  width: "16px",
                                  marginBottom: "5px",
                                }}
                                onClick={() =>
                                  setBusinessNameEdit({
                                    ...businessNameEdit,
                                    businessName:
                                      !businessNameEdit.businessName,
                                  })
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`${st.formBox} ${cx.setting} flex-column align-items-start`}
                          >
                            <label className={`${cx.businessName} form-label`}>
                              Branch Name
                            </label>

                            <div
                              className={`${cx.editSetting} ${cx.v1} position-relative`}
                            >
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={branchData?.branchName}
                                {...register("newbranchName", {
                                  required: true,
                                })}
                              />
                              {errors?.newbranchName?.type === "required" && (
                                <p className={"errorMessage"}>
                                  This field is required
                                </p>
                              )}

                              <button
                                // onClick={() =>
                                //   setBusinessNameEdit({
                                //     ...businessNameEdit,
                                //     businessName:
                                //       !businessNameEdit.businessName,
                                //   })
                                // }
                                onClick={handleSubmit(updateBranch)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        )}
                      </Col>

                      {/* <Col lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Branch Name</label>
                          <div className="position-relative">
                            <img
                              src={menu}
                              className={`${cx.icon}`}
                              alt="budiness icon"
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Branch Name"
                            />
                            <div className={`${cx.editIcon}`}>
                              <img src={editIcon} alt="" />
                            </div>
                          </div>
                        </div>
                      </Col> */}
                      <Col lg={8} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">
                            Business Category
                          </label>

                          <div className={`${cx.busines_category_itmes}`} >
                            <ul className={`${st.iconSelectList} ${cx.icons} justify-content-between`}>
                              <AddNewCategoryBtn selectedCatIds={selectedCatIds}
                                style={{ border: "1px solid gainsboro", height: "61px", borderRadius: "9px" }}
                                handleCatSelect={handleCatSelect}
                              />
                              <div style={{ width: '87%' }}>
                                <OwlCarousel className="owl-theme " {...options}>
                                  {filteredCats?.map((item: any) => {

                                    return (
                                      <div className={'item me-0'}>
                                        <li key={item?._id} className={`${st.active}`}>
                                          <div className={`${st.iconImg}`}>
                                            {/* <img
                                          src={deleteCross}
                                           alt="delete"
                                          className={cx.deleteCross}
                                             onClick={() => handleCatSelect(item?._id)}
                                           /> */}
                                            <img src={item.Icon} alt="massage" />
                                          </div>
                                          <h6 title={item.BusinessCategoryName}>{item.BusinessCategoryName}</h6>
                                        </li>
                                      </div>

                                    );
                                  })}
                                </OwlCarousel>
                              </div>

                            </ul>
                          </div>

                          {caterror && selectedCatIds == "" && (
                            <p className={"errorMessage"}>This field is required</p>
                          )}
                        </div>
                      </Col>

                      <Col md={5} className="mb-2">
                        <h5 className="mb-1">Contact Details</h5>

                        {/* {!showContactDetail ? (
                          <p>Information visible to customer on web site</p>
                        ) : (
                          <div className={`${st.formBox}`}>
                            <textarea
                              className="form-control"
                              placeholder="Contact Details"
                              style={{ minHeight: "70px" }}
                            ></textarea>
                          </div>
                        )} */}
                      </Col>

                      <Col md={5} className="mb-2 d-flex align-items-center">
                        {/* <div
                          className={`${cx.editIcon1}`}
                          onClick={() =>
                            setShowContactDetail(!showContactDetail)
                          }
                        >
                          <img src={editIcon} alt="" />
                        </div> */}
                      </Col>

                      <Col lg={6} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Email</label>
                          <div className="position-relative">
                            <img
                              src={iconEmail}
                              className={cx.icon}
                              alt="email icon"
                            />
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              defaultValue={branchData?.email}
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              })}
                            />

                            {errors?.email?.type === "pattern" && (
                              <p className={"errorMessage"}>
                                Invalid email address
                              </p>
                            )}
                            {errors?.email?.type === "required" && (
                              <p className={"errorMessage"}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Phone number 1</label>

                          <div className={`${st.phoneBox}`}>
                            <PhoneInput
                              defaultCountry="SG"
                              // defaultCountry={`${prefilledCountry ? prefilledCountry?.toLocaleUpperCase() : "IN"}`}
                              international
                              placeholder="Enter phone number"
                              value={phone1}
                              onChange={handlePhone1Change}
                              error={phone1 ? (isValidPhoneNumber(phone1) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />
                            {/* <PhoneInput
                              country={prefilledCountry}
                              defaultMask={phone1}
                              value={phone1}
                              onChange={handlePhone1Change}
                              inputProps={{
                                name: "phone1",
                                required: true,
                                autoFocus: false,
                              }}
                              defaultErrorMessage="It doesn't work, why?"
                            /> */}
                          </div>
                          {/* {phone1 && <p className={"errorMessage"}>{phone1 && isValidPhoneNumber(phone1) ? '' : 'Invalid phone number'}</p>}
                          {errorTrig && !phone1 && (
                            <p className={"errorMessage"}>
                              This field is required
                            </p>
                          )} */}
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Phone number 2</label>
                          <div className={`${st.phoneBox}`}>
                            <PhoneInput
                              // defaultCountry={`${prefilledCountry ? prefilledCountry?.toLocaleUpperCase() : "IN"}`}
                              international
                              placeholder="Enter phone number"
                              value={phone2}
                              onChange={handlePhone2Change}
                            />
                          </div>
                          {/* {phoneError.includes("phone2") && (
                            <p className="errorMessage">Invalid phone number</p>
                          )} */}
                        </div>
                      </Col>

                      <Col lg={4} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">WhatsApp number</label>
                          <div className={`${st.phoneBox}`}>
                            <PhoneInput
                              // defaultCountry={`${prefilledCountry ? prefilledCountry?.toLocaleUpperCase() : "IN"}`}
                              international
                              placeholder="Enter whatsapp number"
                              value={phone3}
                              onChange={handlePhone3Change}
                            />
                          </div>
                          {/* {phoneError.includes("phone3") && (
                            <p className="errorMessage">Invalid phone number</p>
                          )} */}
                        </div>
                      </Col>

                      <Col md={12} className="mt-3 mb-2">
                        <h5>Address</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">City</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            defaultValue={branchData?.city}
                            {...register("city", {
                              required: true,
                            })}
                          />
                          {errors?.city?.type === "required" && (
                            <p className={"errorMessage"}>
                              This field is required
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Postal Code</label>
                          <input
                            type="number"
                            className="form-control"
                            defaultValue={branchData?.postalCode}
                            placeholder="Postal Code"
                            {...register("postalCode", {
                              required: true,
                              pattern: /[0-9a-zA-Z]{6,}/,
                            })}
                          />
                          {errors?.postalCode?.type === "pattern" && (
                            <p className={"errorMessage"}>
                              minimum six character required .
                            </p>
                          )}
                          {errors?.postalCode?.type === "required" && (
                            <p className={"errorMessage"}>
                              This field is required
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Address 1</label>
                          <div className="position-relative">
                            <img
                              src={location}
                              alt="location icon"
                              className={`${cx.icon} ${cx.v2} ${cx.v4} `}
                            />
                            <input
                              type="text"
                              defaultValue={branchData?.address1}
                              className="form-control"
                              placeholder="Address 1"
                              {...register("address1", {
                                required: true,
                              })}
                            />
                            {errors?.address1?.type === "required" && (
                              <p className={"errorMessage"}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Address 2</label>
                          <div className="position-relative">
                            <img
                              src={location}
                              alt="location icon"
                              className={`${cx.icon} ${cx.v2} ${cx.v4} `}
                            />
                            <input
                              type="text"
                              defaultValue={branchData?.address2}
                              className="form-control"
                              placeholder="Address 2"
                              {...register("address2", {
                                required: false,
                              })}
                            />
                            {errors?.address2?.type === "required" && (
                              <p className={"errorMessage"}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={6}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Landmark</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Landmark"
                            defaultValue={branchData?.landmark}
                            {...register("landmark", {
                              required: false,
                            })}
                          />
                          {errors?.landmark?.type === "required" && (
                            <p className={"errorMessage"}>
                              This field is required
                            </p>
                          )}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className={`${st.formBox}`}>
                          <div className={`${st.btnsGroup}`}>
                            <button className={`btn ${st.btn1}`} onClick={() => {
                              // reset()
                              setShowEdit({
                                ...showEdit,
                                basicInfoEdit: false,
                              })
                            }
                            }>Back</button>
                            <button
                              className={`btn ${st.btn4}`} onClick={handleSubmit(updateBranch)}
                            // onClick={() => {
                            //   setShowEdit({
                            //     ...showEdit,
                            //     basicInfoEdit: false,
                            //   });
                            // handleSubmit(updateBranch)();
                            // }}
                            >
                              {loading ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                ></div>
                              ) : (
                                'Save'
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew2" onClick={() => settab("tabNew2")}>
              <Col md={12}>
                  <div className="text-end">
                    <HelpButon number={6} type={"help"} className={"btn"} />
                  </div>
                </Col>
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                      <div>
                        <h2>
                          {!showEdit.aboutEdit ? "About" : "Social Media"}
                        </h2>
                        <span
                          className={`${cx.smallText}`}
                          style={{ color: "#808080" }}
                        >
                          {/* Loram buipsim tipsum gipsum some more sum, since it’s
                          sum lets say loram{" "} */}
                        </span>
                      </div>
                      {(canEditBranch && !showEdit.aboutEdit) && (
                        <div>
                          <Button
                            className={`${cx.editBtn}`}
                            onClick={() =>
                              setShowEdit({ ...showEdit, aboutEdit: true })
                            }
                          >
                            <img src={editButton} alt="edit" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                {showEdit.aboutEdit ? (
                  <>
                    <Row>
                      <Col md={4}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Website</label>
                          <div className="position-relative">
                            <img
                              src={union}
                              className={`${cx.icon} ${cx.v3} `}
                              alt="budiness icon"
                            />
                            <input
                              type="text"
                              value={aboutData?.website}
                              className="form-control"
                              placeholder="Website"
                              onChange={(e) => setAboutData({ ...aboutData, website: e.target.value })}
                            />
                          </div>
                          {abouterror && aboutData.website === "" && (
                            <p className={"errorMessage"}>This field is required</p>
                          )}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Instagram</label>
                          <div className="position-relative">
                            <img
                              src={instagram}
                              className={`${cx.icon} ${cx.v3}`}
                              alt="email icon"
                            />
                            <input
                              type="text"
                              value={aboutData?.instagram}
                              className="form-control"
                              placeholder="Instagram"
                              onChange={(e) => setAboutData({ ...aboutData, instagram: e.target.value })}
                            />
                          </div>
                          {abouterror && aboutData.instagram === "" && (
                            <p className={"errorMessage"}>This field is required</p>
                          )}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Facebook</label>
                          <div className="position-relative">
                            <img
                              src={facebookIcon}
                              className={`${cx.icon} ${cx.v3}`}
                              alt="Facebook icon"
                            />
                            <input
                              type="text"
                              className="form-control"
                              value={aboutData?.facebook}
                              placeholder="Facebook"
                              onChange={(e) => setAboutData({ ...aboutData, facebook: e.target.value })}
                            />
                          </div>
                          {abouterror && aboutData?.facebook === "" && (
                            <p className={"errorMessage"}>This field is required</p>
                          )}
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Brief Summary about your branch</label>
                          <div className={cx.editorContainer}>
                            <ReactQuill
                              defaultValue={branchData?.briefAbout}
                              value={aboutData?.briefAbout}
                              onChange={(e: any) => handleAbout(e, "brief")}
                              modules={modules}
                            />
                          </div>
                          {/* <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Tell more about your business"
                          />
                        </div> */}
                          <p className={"errorMessage"}>{brieferr}</p>
                        </div>
                      </Col>

                      <Col md={8}>
                        

                        <div className={`${st.formBox}`}>
                          <label className="form-label">about</label>
                          <div className={cx.editorContainer}>
                            <ReactQuill
                              defaultValue={branchData?.about}
                              value={aboutData?.about}
                              onChange={(e: any) => handleAbout(e, "about")}
                              modules={modules}
                            />
                          </div>
                          {/* <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Tell more about your business"
                          />
                        </div> */}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div
                          className={`${st.formBox} ${cx.locationSelect} ${cx.gallery}`}
                        >
                          <div>
                            <h2>Gallery</h2>
                            <span
                              className={`${cx.smallText}`}
                              style={{ color: "#808080" }}
                            >
                              {/* Loram buipsim tipsum gipsum some more sum, since
                              it’s sum lets say loram{" "} */}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={2} lg={3} md={4} sm={4} xs={6}>
                        <div className={`${cx.uploadImageBox} `}>
                          <label htmlFor="img-upload">
                            <AiFillCamera className={`${cx.boxImage}`} />
                            <input type="file" id="img-upload" onChange={onFileChange} multiple />
                          </label>
                          <h2>
                            Upload Photos{" "}
                            <span style={{ color: "red" }}>
                              <sup>*</sup>
                            </span>
                          </h2>
                        </div>
                      </Col>
                      {oldImage?.map((item: any, index: any) => {
                        return (
                          <Col xl={2} lg={3} md={4} sm={4} xs={6} key={index}>
                            <div className={`${cx.uploadImageBox} `}>
                              <img
                                src={deleteCross}
                                alt="delete"
                                className={cx.cross}
                                onClick={() => handleRemoveOldImg(index)}
                              />
                              <img
                                src={item}
                                className={cx.bgImage}
                                alt="img"
                              />
                            </div>
                          </Col>)
                      })}
                      {previewImage?.map((item: any, index: any) => {

                        return (
                          <Col xl={2} lg={3} md={4} sm={4} xs={6} key={index}>
                            <div className={`${cx.uploadImageBox} `}>
                              <img
                                src={deleteCross}
                                alt="delete"
                                className={cx.cross}
                                onClick={() => handleRemoveImg(index)}
                              />
                              <img
                                src={item}
                                className={cx.bgImage}
                                alt="img"
                              />
                            </div>
                          </Col>)
                      })}


                      {/* <Col lg={2} md={3}>
                        <div className={`${cx.uploadImageBox} `}>
                          <img
                            src={deleteCross}
                            alt="delete"
                            className={cx.cross}
                          />
                          <img
                            src={background}
                            className={cx.bgImage}
                            alt="img"
                          />
                        </div>
                      </Col>
                      <Col lg={2} md={3}>
                        <div className={`${cx.uploadImageBox} `}>
                          <img
                            src={deleteCross}
                            alt="delete"
                            className={cx.cross}
                          />
                          <img
                            src={background}
                            className={cx.bgImage}
                            alt="img"
                          />
                        </div>
                      </Col>
                      <Col lg={2} md={3}>
                        <div className={`${cx.uploadImageBox} `}>
                          <img
                            src={deleteCross}
                            alt="delete"
                            className={cx.cross}
                          />
                          <img
                            src={background}
                            className={cx.bgImage}
                            alt="img"
                          />
                        </div>
                      </Col> */}
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className={`${st.formBox}`}>
                          <div className={`${st.btnsGroup}`}>
                            <button
                              className={`btn ${st.btn1}`}
                              onClick={() => {
                                // reset();
                                setShowEdit({ ...showEdit, aboutEdit: false })
                              }}
                            >
                              Back
                            </button>
                            <button className={`btn ${st.btn4}`} onClick={handleBranchUpdate}>
                              {loading ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                ></div>
                              ) : (
                                'Save'
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col lg={4} md={6}>
                        <div className={cx.aboutInfo}>
                          <img src={union} alt="website" />
                          <span>{branchData?.website ? branchData?.website : aboutData?.website}</span>
                        </div>
                      </Col>

                      <Col lg={4} md={6}>
                        <div className={cx.aboutInfo}>
                          <img src={facebookIcon} alt="facebook-icon" />
                          <span>{branchData?.facebook ? branchData?.facebook : aboutData?.facebook}</span>
                        </div>
                      </Col>
                      <Col lg={4}></Col>

                      <Col lg={4} md={6}>
                        <div className={cx.aboutInfo}>
                          <img src={instagram} alt="insta-icon" />
                          <span>{branchData?.instagram ? branchData?.instagram : aboutData?.instagram}</span>
                        </div>
                      </Col>
                      <Col lg={4}></Col>

                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">Brief Summary about your branch</label>
                          <div className="position-relative">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: branchData?.briefAbout ? branchData?.briefAbout : aboutData?.briefAbout,
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.iconPos}`}>
                          <label className="form-label">About</label>
                          <div className="position-relative">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: branchData?.about ? branchData?.about : aboutData?.about,
                              }}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md={12} className="mt-3">
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                          <div>
                            <h2>Gallery</h2>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {oldImage?.map((img: any) => {
                        return (
                          <Col xl={2} lg={3} md={4} sm={4} xs={6}>
                            <div className={`${cx.uploadImageBox} `}>
                              <img src={img} className={cx.bgImage} alt="img" />
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                )}
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew3" onClick={() => settab("tabNew3")}>
              <Col md={12}>
                          <div className="text-end">
                            <HelpButon number={7} type={"help"} className={"btn"} />
                          </div>
                        </Col>

                {!showEdit.workingHoursEdit ? (
                  <>
                    <Row>
                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                          <h2>Working Hours</h2>

                          <div>
                            {canEditBranch && <Button
                              className={`${cx.editBtn}`}
                              onClick={() =>
                                setShowEdit({
                                  ...showEdit,
                                  workingHoursEdit: true,
                                })
                              }
                            >
                              <img src={editButton} alt="edit" />
                            </Button>}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {branchData?.sameEveryDay?.isSameEveryDay ? (
                      <Row>
                        <Col md={6}>
                          <div
                            className={`${cx.everyDaySelector} d-flex gap-3 mt-2`}
                          >
                            <div className={`${st.formBox}`}>
                              <select className="form-select" disabled defaultValue={branchData?.sameEveryDay?.from}>
                                <option value="00:00">
                                  {branchData?.sameEveryDay?.from ? branchData?.sameEveryDay?.from : "not selected"}
                                </option>
                              </select>

                            </div>

                            <div className={`${st.formBox}`}>
                              <select className="form-select" disabled defaultValue={branchData?.sameEveryDay?.to}>
                                <option value="00:00">
                                  {branchData?.sameEveryDay?.to ? branchData?.sameEveryDay?.to : "not selected"}
                                </option>
                              </select>

                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : ""}

                    {branchData?.workingHours?.length !== 0 && (
                      <Row>

                        {branchData?.workingHours?.map((item: any, index: number) => {
                          return (
                            <Col lg={3} md={6} key={index}>
                              <div className={cx.timeSelect}>
                                <div className={`${st.formBox}`}>
                                  <label className="form-label">{item.dayName}</label>
                                  <div
                                    className={`position-relative d-flex align-items-center gap-3 ${cx.inputBackground}`}
                                  >
                                    <div className={cx.editContainer}>
                                      <p>
                                        {item?.from || "not set"}
                                      </p>
                                    </div>
                                    <div className={cx.editContainer}>
                                      <p>
                                        {item?.to || "not set"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )
                        })}
                      </Row>
                    )}

                    {branchData?.workingHours?.length === 0 && (
                      <p style={{ fontSize: "17px", textAlign: "center" }}>Please enter workingHours (if applicable)</p>
                    )}

                    <Row>
                      <Col md={12} className="mt-2">
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                          <h2>Non Operating Hours </h2>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {branchData?.notWorkingHours?.length !== 0 && branchData?.notWorkingHours?.map((item: any, i: number) => {
                        return (
                          <Row key={i}>
                            <Col xl={3} lg={4} md={6}>
                              <div className={`${st.formBox} ${cx.dateBox} `}>
                                <label htmlFor="from" className="form-label">
                                  From
                                </label>
                                <div className="position-relative">
                                  <input
                                    type="date"
                                    id="from"
                                    value={item?.fromDate}
                                    disabled
                                    className={`${cx.dobInput} form-control`} />
                                  <img
                                    src={calendar}
                                    alt="shedule"
                                    className={cx.scheduleIcon} />
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={6}>
                              <div className={`${st.formBox} ${cx.dateBox} `}>
                                <label htmlFor="from" className="form-label">
                                  To
                                </label>
                                <div className="position-relative">
                                  <input
                                    type="date"
                                    id="from"
                                    disabled
                                    value={item?.toDate}
                                    className={`${cx.dobInput} form-control`} />
                                  <img
                                    src={calendar}
                                    alt="shedule"
                                    className={cx.scheduleIcon} />
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={6}>
                              <div className={`${st.formBox}`}>
                                <label className="form-label">Full Day</label>
                                <br />
                                <div className={` ${cx.timeCheckbox}`}>
                                  <label className={`${st.checkbox}`}>
                                    <input type="checkbox" disabled checked={item?.isFullDay} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className="ms-2"></span>
                                  </label>
                                  {
                                    !item?.isFullDay && (
                                      <>
                                        <select className="form-select" disabled>
                                          <option>{item?.from}</option>
                                        </select>
                                        <select className="form-select" disabled>
                                          <option>{item?.to}</option>
                                        </select>
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                            </Col>
                            {/* <Col lg={1}>
                                  <button className={`${cx.plusBtn} btn`}>+</button>
                                </Col> */}
                          </Row>
                        )
                      })}


                      {branchData?.notWorkingHours?.length === 0 && (
                        <p style={{ fontSize: "17px", textAlign: "center" }}>Please enter non operating hours (if applicable)</p>
                      )}
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                          <h2>Working Hours</h2>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className={`${cx.everyDay}`}>
                          <label>Same every day</label>
                          <Switch
                            checked={isSameEveryDay}
                            onChange={handleSwitchChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          {<div
                            className={`${cx.everyDaySelector} d-flex gap-3 mt-2`}
                          >
                            <div className={`${st.formBox}`} onClick={handleTimeChange}>
                              <Controller
                                name="from"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => {
                                  let value: any = [];
                                  if (isSameEveryDay == true && isSameEveryDay !== undefined) {
                                    value = timeValues
                                  }
                                  return (
                                    <CustomTimeSelect value={field.value} onChange={field.onChange} timeValues={value} required={false} />
                                  )
                                }
                                }
                              />
                              {errors?.from?.type === "required" && (
                                <p className="errorMessage">required*</p>
                              )}
                            </div>

                            <div className={`${st.formBox}`} onClick={handleTimeChange}>
                              <Controller
                                name="to"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => {
                                  let value: any = [];
                                  if (isSameEveryDay == true && isSameEveryDay !== undefined) {
                                    value = timeValues.filter((item: any) => item.value >= watch('from', ''))
                                  }
                                  return (
                                    <CustomTimeSelect value={field.value} onChange={field.onChange}
                                      timeValues={value}
                                      required={false} />
                                  )
                                }}
                              />
                              {errors?.to?.type === "required" && (
                                <p className={"errorMessage"}>required*</p>
                              )}
                            </div>
                          </div>}
                        </div>
                      </Col>

                      {<>
                        {weekValues.map((item: any, index: number) => {
                          return (
                            <Col xl={3} lg={4} md={6} key={item.value}>
                              <div className={cx.timeSelect}>
                                <div className={`${st.formBox}`}>
                                  <label className="form-label">{item.label}</label>
                                  <Controller
                                    name={`${item.label}from`}
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                      <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px", minWidth: "85px" }} disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange}
                                        timeValues={timeValues} required={false} />
                                    )}
                                  />
                                  {errors[`${item.label}from`]?.type === "required" ? (
                                    <span className="errorMessage">required*</span>
                                  ) : <span>&nbsp;</span>
                                  }
                                </div>
                                <div className={`${st.formBox}`} >
                                  <div className="d-flex align-items-center justify-content-end" style={{ height: '30px' }}>
                                    <label className="form-label  text-nowrap">Closed :</label>
                                    <div style={{ transform: 'translate(5%, -11%)' }}>
                                      {/* <label className={`${st.checkbox}`}>
                                        <input type="checkbox"
                                          checked={dayWiseOff?.[index]?.value}
                                          onChange={(e: any) => {
                                            let updateDayWiseOff: any = [...dayWiseOff];
                                            updateDayWiseOff[index] = {
                                              day: item?.label,
                                              value: e.target.checked
                                            }
                                            setDayWiseOff(updateDayWiseOff)
                                          }} />
                                        <span className={`${st.checkmark}`}></span>
                                          </label> */}
                                      <Switch
                                        checked={dayWiseOff?.[index]?.value}
                                        disabled={isSameEveryDay}
                                        onChange={(e: any) => {
                                          let updateDayWiseOff: any = [...dayWiseOff];
                                          updateDayWiseOff[index] = {
                                            day: item?.label,
                                            value: e.target.checked
                                          }
                                          setDayWiseOff(updateDayWiseOff)
                                        }}
                                      // inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    </div>

                                  </div>
                                  <Controller
                                    name={`${item.label}to`}  // This should match your form data structure
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => {
                                      const filteredTimeValues = timeValues.filter((time) => time.value >= watch(`${item.label}from`, ''));
                                      return (
                                        <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px" }} disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={false} />
                                      );
                                    }}
                                  />
                                  {errors[`${item.label}to`]?.type === "required" ? (
                                    <span className="errorMessage">required*</span>
                                  ) : <span>&nbsp;</span>
                                  }
                                </div>
                              </div>
                            </Col>
                          )
                        })
                        }
                      </>}
                    </Row>
                    <Row>
                      <Col md={12} className="mt-2">
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                          <h2>Non Operating Hours </h2>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={3} lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.dateBox} `}>
                          <label htmlFor="from" className="form-label">
                            From
                          </label>
                          <div className="position-relative">
                            <input
                              type="date"
                              id="from"
                              className={`${cx.dobInput} form-control`}
                              min={disablePastDate()}
                              {...regNonOpHrs("fromDate", {
                                required: true,
                              })}
                            />
                            {/* <img
                                src={calendar}
                                alt="shedule"
                                className={cx.scheduleIcon}
                            /> */}
                          </div>
                          {NonOpHrsErr?.fromDate?.type === "required" && (
                            <p className="errorMessage">required*</p>
                          )}
                        </div>
                      </Col>

                      <Col xl={3} lg={4} md={6}>
                        <div className={`${st.formBox} ${cx.dateBox} `}>
                          <label htmlFor="to" className="form-label">
                            To
                          </label>
                          <div className="position-relative">
                            <input
                              type="date"
                              id="from"
                              min={watchNonOpHrs('fromDate', disablePastDate())}
                              className={`${cx.dobInput} form-control`}
                              {...regNonOpHrs("toDate", {
                                required: true,
                              })}
                            />
                            {/* <img
                                src={calendar}
                                alt="shedule"
                                className={cx.scheduleIcon}
                            /> */}
                          </div>
                          {NonOpHrsErr?.toDate?.type === "required" && (
                            <p className="errorMessage">required*</p>
                          )}
                        </div>
                      </Col>

                      <Col lg={4} md={12}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Full Day</label>
                          <br />
                          <div className={` ${cx.timeCheckbox}`}>
                            <label className={`${st.checkbox}`}>
                              <input type="checkbox" checked={fullDayCheck} onChange={handleCheckBoxSelection} />
                              <span className={`${st.checkmark}`}></span>
                              <span className="ms-2"></span>
                            </label>
                            {!fullDayCheck && <><Controller
                              name="from"
                              control={controlNonOpHrs}
                              rules={{ required: !fullDayCheck }}
                              render={({ field }) => (
                                <CustomTimeSelect value={field.value} onChange={field.onChange}
                                  timeValues={timeValues}
                                  required={!fullDayCheck} />
                              )} />
                              <Controller
                                name="to"
                                control={controlNonOpHrs}
                                rules={{ required: !fullDayCheck }}
                                render={({ field }) => (
                                  <>
                                    <CustomTimeSelect value={field.value} onChange={field.onChange}
                                      timeValues={timeValues.filter((item: any) => item.value >= watchNonOpHrs('from', ''))}
                                      required={!fullDayCheck} />
                                  </>
                                )} /></>}
                            {/* <CustomTimeSelect value={''} onChange={handleInputChange1} timeValues={timeValues} required={true} />

                            <CustomTimeSelect value={''} onChange={handleInputChange2} timeValues={timeValues} required={true} /> */}
                          </div>
                          {(NonOpHrsErr?.from?.type === "required" || NonOpHrsErr?.to?.type === "required") && (
                            <p className="errorMessage">required*</p>
                          )}
                        </div>
                      </Col>

                      <Col lg={1}>
                        <button className={`${cx.plusBtn} btn`} onClick={handleSubmitNonOpHrs(onNonOpHrsSubmit)}>Save</button>
                      </Col>
                    </Row>
                    {selectedNonOpHrs?.map((item: any, i: any) => {
                      return (
                        <Row key={i}>
                          <Col xl={3} lg={4} md={6}>
                            <div className={`${st.formBox} ${cx.dateBox} `}>
                              <div className="position-relative">
                                <input
                                  type="date"
                                  id="from"
                                  className={`${cx.dobInput} form-control`}
                                  disabled
                                  defaultValue={item.fromDate}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl={3} lg={4} md={6}>
                            <div className={`${st.formBox} ${cx.dateBox} `}>
                              <div className="position-relative">
                                <input
                                  type="date"
                                  id="from"
                                  className={`${cx.dobInput} form-control`}
                                  disabled
                                  defaultValue={item.toDate}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col lg={4} md={12}>
                            <div className={`${st.formBox}`}>
                              <div className={` ${cx.timeCheckbox}`}>
                                <label className={`${st.checkbox}`}>
                                  <input type="checkbox" checked={item.isFullDay === 'true' || item.isFullDay} disabled />
                                  <span className={`${st.checkmark}`}></span>
                                  <span className="ms-2"></span>
                                </label>
                                {item.from !== '' && <select className="form-select" disabled>
                                  <option>{item.from}</option>
                                </select>}
                                {item.from !== '' && <select className="form-select" disabled>
                                  <option>{item.to}</option>
                                </select>}
                              </div>
                            </div>
                          </Col>

                          <Col lg={1}>
                            <button className={`${cx.plusBtn} btn mt-0`} onClick={(e) => nonOpHrsRemoveChangeHandler(e, i)}>-</button>
                          </Col>
                        </Row>
                      )
                    })}
                    <Row>
                      <Col md={12}>
                        <div className={`${st.formBox}`}>
                          <div className={`${st.btnsGroup}`}>
                            <button
                              className={`btn ${st.btn1}`}
                              onClick={() => {
                                // reset();
                                resetNonOpHrs();
                                setShowEdit({ ...showEdit, workingHoursEdit: false })
                              }}
                            >
                              Back
                            </button>
                            <button type='submit' className={`btn ${st.btn4}`} disabled={loading} onClick={handleSubmit(onSubmit)}>
                              {loading ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                ></div>
                              ) : (
                                'Save'
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew4" onClick={() => settab("tabNew4")}>
              <Col md={12}>
                  <div className="text-end">
                    <HelpButon number={8} type={"help"} className={"btn"} />
                  </div>
                </Col>
                {!showEdit.offPeakEdit ? (
                  <>
                    <Row>
                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                          <h2>Off Peak Hours</h2>
                          <div>
                            {canEditBranch && <Button
                              className={`${cx.editBtn}`}
                              onClick={() =>
                                setShowEdit({
                                  ...showEdit,
                                  offPeakEdit: true,
                                })
                              }
                            >
                              <img src={editButton} alt="edit" />
                            </Button>}
                          </div>
                        </div>
                      </Col>
                    </Row>


                    {branchData?.offPeakHours?.length !== 0 && (
                      <Row>
                        {branchData?.offPeakHours?.map((item: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              {item?.isSameEveryDay && <Row>
                                <Col md={6}>
                                  <div
                                    className={`${cx.everyDaySelector} d-flex gap-3 mt-2`}
                                  >
                                    <div className={`${st.formBox}`}>
                                      <select className="form-select" disabled defaultValue={item?.from}>
                                        <option value="00:00">
                                          {item?.from ? item?.from : "not selected"}
                                        </option>
                                      </select>
                                    </div>

                                    <div className={`${st.formBox}`}>
                                      <select className="form-select" disabled defaultValue={item?.to}>
                                        <option value="00:00">
                                          {item?.to ? item?.to : "not selected"}
                                        </option>
                                      </select>

                                    </div>
                                  </div>
                                </Col>
                              </Row>}
                              <Row>
                                {item?.shift?.map((data: any, i: number) => {
                                  return (
                                    <Col lg={3} md={6} key={i}>
                                      <div className={cx.timeSelect}>
                                        <div className={`${st.formBox}`}>
                                          <label className="form-label">{data?.dayName}</label>
                                          <div
                                            className={`position-relative d-flex align-items-center gap-3 ${cx.inputBackground}`}
                                          >
                                            <div className={cx.editContainer}>
                                              <p>{data.from ? (data?.from == "Not applicable" ? "N/A" : data.from) : "not set"}</p>
                                            </div>
                                            <div className={cx.editContainer}>
                                              <p>{data.to ? (data?.to == "Not applicable" ? "N/A" : data.to) : 'not set'}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  )
                                })}

                              </Row>
                            </React.Fragment>
                          )
                        })}
                      </Row>

                    )}
                    {branchData?.offPeakHours?.length === 0 && (
                      <p style={{ fontSize: "17px", textAlign: "center" }}> No off-peak hours </p>
                    )}
                  </>
                ) : (
                  <>
                    <Row>
                      <Col md={12}>
                        <div className={`${st.formBox} ${cx.locationSelect}`}>
                          <h2>Off Peak Hours</h2>
                          <button className={`${cx.resetButton} btn`} onClick={resetOffPeak}>Reset Off Peak Hours</button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className={`${cx.everyDay}`}>
                          <label>Same every day</label>
                          <Switch
                            checked={isSameOffPeak}
                            onChange={handleSwitchChangeOffpeak}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          {<div
                            className={`${cx.everyDaySelector} d-flex gap-3 mt-2`}
                          >
                            <div className={`${st.formBox}`} onClick={handleTimeChangeOffpeak}>
                              <Controller
                                name="fromOff"
                                control={control}
                                rules={{ required: isSameOffPeak }}
                                render={({ field }) => {
                                  let filteredTimeValues: any;
                                  if (isSameOffPeak) {
                                    filteredTimeValues = timeValues
                                  }
                                  return (
                                    <CustomTimeSelect value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                                  )
                                }}
                              />
                              {errors?.fromOff?.type === "required" && (
                                <p className="errorMessage">required*</p>
                              )}
                            </div>


                            <div className={`${st.formBox}`} onClick={handleTimeChangeOffpeak}>
                              <Controller
                                name="toOff"
                                control={control}
                                rules={{ required: isSameOffPeak }}
                                render={({ field }) => {
                                  let filteredTimeValues: any;
                                  if (isSameOffPeak) {
                                    filteredTimeValues = timeValues?.filter((item: any) => item.value >= watch('fromOff', ''))
                                  }
                                  return (
                                    <CustomTimeSelect value={field.value} onChange={field.onChange}
                                      timeValues={filteredTimeValues}
                                      required={true} />
                                  )
                                }}
                              />
                              {errors?.toOff?.type === "required" && (
                                <p className={"errorMessage"}>required*</p>
                              )}
                            </div>
                            <button type='submit' className={`btn ${cx.saveBtn}`} onClick={handleSubmit(onSubmitOffPeak)}>
                              Save
                            </button>
                          </div>}
                        </div>
                      </Col>

                      {<>
                        {weekValues.map((item: any, index: number) => {
                          const notApplicableDay = dayWisePeakOff?.find((notItem: any) => notItem?.day === item?.label)

                          const branchStartEndTime = branchData?.workingHours?.find((timeVal: any) => timeVal.dayName === `${item.label}`);
                          return (
                            <Col xl={3} lg={4} md={6} key={item.value}>
                              <div className={cx.timeSelect}>
                                <div className={`${st.formBox}`}>
                                  <label className="form-label">{item.label}</label>
                                  <Controller
                                    name={`${item.label}fromOff`}
                                    control={control}
                                    rules={{ required: notApplicableDay ? false : true }}
                                    render={({ field }) => {
                                      const filteredTimeValues = timeValues
                                      return (
                                        <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px" }} disabled={dayWisePeakOff?.[index]?.value} value={field.value} onChange={field.onChange}
                                          timeValues={filteredTimeValues} required={true} />
                                      )
                                    }}
                                  />
                                  {errors[`${item.label}fromOff`]?.type === "required" ? (
                                    <span className="errorMessage">required*</span>
                                  ) : <span>&nbsp;</span>
                                  }
                                </div>
                                <div className={`${st.formBox}`}>
                                  <div className="d-flex align-items-center justify-content-end" style={{ height: '27px' }}>
                                    <label className="form-label" style={{
                                      display: "inlineBlock",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    }}>N/A</label>
                                    <div style={{ transform: 'translate(5%, -10%)' }}>
                                      {/* <label className={`${st.checkbox}`}>
                                        <input type="checkbox"
                                          checked={dayWisePeakOff?.[index]?.value}
                                          onChange={(e: any) => {
                                            let updateDayWisePeakOff: any = [...dayWisePeakOff];
                                            updateDayWisePeakOff[index] = {
                                              day: item?.label,
                                              value: e.target.checked
                                            }
                                            setDayWisePeakOff(updateDayWisePeakOff)
                                          }} />
                                        <span className={`${st.checkmark}`}></span>
                                      </label> */}

                                      <Switch
                                        checked={dayWisePeakOff?.[index]?.value}
                                        onChange={(e: any) => {
                                          let updateDayWisePeakOff: any = [...dayWisePeakOff];
                                          updateDayWisePeakOff[index] = {
                                            day: item?.label,
                                            value: e.target.checked
                                          }
                                          setDayWisePeakOff(updateDayWisePeakOff)
                                        }}
                                      // inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    </div>

                                  </div>
                                  <Controller

                                    name={`${item.label}toOff`}  // This should match your form data structure
                                    control={control}
                                    rules={{ required: notApplicableDay ? false : true }}
                                    render={({ field }) => {
                                      // const filteredTimeValues = timeValues.filter((time) => time.value >= watch(`${item.label}fromOff`, ''));
                                      const filteredTimeValues = timeValues
                                      return (
                                        <CustomTimeSelect style={{ marginTop: "4px", fontSize: "13px", padding: "10px 11px", backgroundSize: "11px" }} disabled={dayWisePeakOff?.[index]?.value} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                                      );
                                    }}
                                  />
                                  {errors[`${item.label}toOff`]?.type === "required" ? (
                                    <span className="errorMessage">required*</span>
                                  ) : <span>&nbsp;</span>
                                  }
                                </div>
                              </div>
                            </Col>
                          )
                        })
                        }
                      </>}
                    </Row>

                    <div className={cx.offList} ref={offPeakRef}>
                      {offPeakList?.map((item: any, i: any) => {
                        return (
                          <Row key={i}>
                            <Col md={12} >
                              <div className={`${cx.everyDay}`}>
                                <div className={`${cx.everyDaySwith} d-flex gap-2 align-items-center`} >
                                  <label>Same every day</label>
                                  <Switch
                                    checked={item?.isSameEveryDay}
                                    disabled={true}
                                  />
                                </div>
                                {item?.isSameEveryDay && <div className={cx.displayTime}>
                                  <div className={`${st.formBox}`}>
                                    {/* <label className="form-label">{shiftItem?.dayName}</label> */}
                                    <div
                                      className={`position-relative d-flex align-items-center gap-3 ${cx.inputBackground}`}
                                    >
                                      <div className={cx.editContainer}>
                                        <p>
                                          {item?.from || "not set"}
                                        </p>
                                      </div>
                                      <div className={cx.editContainer}>
                                        <p>
                                          {item?.to || "not set"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>}
                              </div>
                            </Col>

                            <Col md={12}>
                              <Row>
                                {item?.shift?.map((shiftItem: any, i: number) => {

                                  return (
                                    <Col xl={2} lg={3} md={6} key={i}>
                                      <div className={cx.displayTime}>
                                        <div className={`${st.formBox}`}>
                                          <label className="form-label">{shiftItem?.dayName}</label>
                                          <div
                                            className={`position-relative d-flex align-items-center gap-2 ${cx.inputBackground}`}
                                          >
                                            <div className={cx.editContainer}>
                                              <p>
                                                {shiftItem?.from === "Not applicable" ? "N/A" : shiftItem?.from}
                                              </p>
                                            </div>
                                            <div className={cx.editContainer}>
                                              <p>
                                                {shiftItem?.to === "Not applicable" ? "N/A" : shiftItem?.to}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  )
                                })}
                                <Col>

                                  <div className={cx.action}>
                                    <div className={`${cx.btn} mt-3`}>
                                      <span><img src={editButton} onClick={() => handleEditTime(i)} alt="edit"
                                      /></span>
                                      <span><img src={deleteIcon} alt="delete" onClick={() => handleDeleteTime(i)}
                                      /></span>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <div style={{ borderBottom: '2px solid #eff0f5' }}></div>
                          </Row>
                        )
                      })}
                    </div>
                    <Row>
                      <Col md={12}>
                        <div className={`${st.formBox}`}>
                          <div className={`${st.btnsGroup}`}>
                            <button
                              className={`btn ${st.btn1}`}
                              onClick={() => {
                                // reset()
                                setShowEdit({ ...showEdit, offPeakEdit: false })
                              }}
                            >
                              Back
                            </button>
                            <button type='submit' className={`btn ${st.btn4}`} disabled={loading} onClick={handleSave}>
                              {loading ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                ></div>
                              ) : (
                                'Save'
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Tab.Pane>

            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
      <AssignTeam show={show} handleClose={handleClose} />
      <AddAmeneties
        showAmeneties={showAmeneties}
        handleAmenetiesClose={handleAmenetiesClose}
      />
      <ErrorPopup
        showErrorModal={showErrorModal}
        handleErrorModalClose={handleAlertModalClose}
      />
    </>
  );
};

export default BranchHome;

