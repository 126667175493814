import React from "react";
import m from "./Modal.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { successLarge, crossRed, successTick } from "../../../assets/images";

const SuccessPopup = (props: any) => {
  let {
    showSuccessModal,
    handleSuccessModalClose,
    message,
    type,
    setConfirm,
    changeButton,
    resendOtp
  } = props;

  const handleOk = () => {
    if (type === "Error") {
      setConfirm(true);
    }
    resendOtp && resendOtp()
    handleSuccessModalClose();
  };

  return (
    <>
      <Modal
        centered
        show={showSuccessModal}
        backdrop="static"
        keyboard={false}
        onHide={handleSuccessModalClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Body className="p-5">
          <Row>
            <Col md={12}>
              {type !== "Error" && <img
                src={successTick}
                alt="success-img"
                className={`${m.successImage}`}
              />}
            </Col>
            <Col md={12}>
              <h5 className={`${m.successHeading}`}>{type === "Error" ? "" : `Success`}</h5>

              <p className={`${m.desc2}`}>
                {message ? message : "Your Item added successfully"}
              </p>
            </Col>

            <Col md={12}>
              <div className={`${m.buttonGroup}`}>
                <button className={`${m.ok}`} onClick={handleOk}>
                  {changeButton ? changeButton : "Ok"}
                </button>
                {type === "Error" && <button className={`${m.cancel}`} onClick={() => {
                  setConfirm(false)
                  handleSuccessModalClose();
                }}>
                  Cancel
                </button>}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessPopup;
