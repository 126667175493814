import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Switch } from "../../../components/Website/Forms";
import { Col, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { calendar, deleteIcon, editButton } from "../../../assets/images";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import CustomTimeSelect from "../../../constants/CustomTimeSelect";
import { timeValues } from "../../../constants/Constants";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { disablePastDate } from "../../../Utils/Funcs";
import { useSelector } from "react-redux/es/hooks/useSelector";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

export default function LeavesTab(props: any) {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const { handleStepsFormData, branchList, loading, editLeaveData, editTeamData } = props;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleSuccessModalClose = () => setShowSuccessModal(false);
  const handleSuccessModalShow = () => setShowSuccessModal(true);
  const [message, setMessage] = useState("");
  const [customHrsSwitch, setCustomHrsSwitch] = useState(false);
  const [addedLeaves, setAddedLeaves] = useState([]);
  const [editLeaveIndex, setEditLeaveIndex] = useState(-1);

  const fromDate = watch("fromDate");
  const toDate = watch("toDate");

  // Convert the date strings to Date objects
  let date1: any = fromDate ? new Date(fromDate) : new Date();
  let date2: any = toDate ? new Date(toDate) : new Date();

  // Calculate the difference in milliseconds
  let difference = date2 - date1;

  // Convert milliseconds to days
  let millisecondsPerDay = 1000 * 60 * 60 * 24;
  let differenceInDays = Math.floor(difference / millisecondsPerDay);

  const teamHoursArr = editTeamData?.hoursDetail;
  const selectedBranchWH: any = branchId ? teamHoursArr?.find((item: any) => item?.branchId?._id === branchId) || null : null
  const shiftsArr = selectedBranchWH ? selectedBranchWH?.shift : []


  let availableHrsArr: any = [];
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  for (let i = 0; i <= differenceInDays; i++) {
    const startDate = fromDate ? new Date(fromDate) : new Date();
    startDate?.setDate(startDate?.getDate() + i);

    const dayOfWeek = startDate ? new Date(startDate)?.getDay() : new Date()?.getDay();
    const fullDayName = dayNames[dayOfWeek];
    shiftsArr?.length !== 0 && shiftsArr?.forEach((element: any) => {
      let hrs = element.workingHours?.find((event: any) => event?.day == fullDayName);
      availableHrsArr.push(hrs);
    });
  }


  function groupNotAvailableHours(timeArr: any, shifts: any) {
    const notAvailableGroups: any = [];
    let currentGroup: any = null;
    timeArr?.forEach((time: any) => {
      const isAvailable = shifts?.some((shift: any) => {
        const shiftStart = shift.from;
        const shiftEnd = shift.to;
        return time >= shiftStart && time <= shiftEnd;
      });

      if (!isAvailable) {
        if (!currentGroup) {
          currentGroup = { from: time, to: time };
        } else {
          currentGroup.to = time;
        }
      } else {
        if (currentGroup) {
          notAvailableGroups.push(currentGroup);
          currentGroup = null;
        }
      }
    });
    if (currentGroup) {
      notAvailableGroups.push(currentGroup);
    }
    return notAvailableGroups;
  }

  function generateTimeArray() {
    const times = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes++) {
        const hourFormatted = hours.toString().padStart(2, '0');
        const minuteFormatted = minutes.toString().padStart(2, '0');
        const time = `${hourFormatted}:${minuteFormatted}`;
        times.push(time);
      }
    }
    return times;
  }

  let fullDayTimeArray = generateTimeArray();

  let notAvailableHrs = groupNotAvailableHours(fullDayTimeArray, availableHrsArr);


  let brancdata = branchList.filter((item: any) => branchId === item._id);
  useEffect(() => {
    if (editLeaveData) {
      reset();
      let leavesArr: any = [];
      editLeaveData.leaves?.forEach((elem: any) => {
        console.log(elem, "eleData");

        let isTimeAv = elem.fromTime ? true : false;
        leavesArr.push({
          branchName: elem.branchId?.branchName,
          branchId: elem.branchId?._id,
          isChecked: isTimeAv,
          fromDate: elem.fromDate,
          toDate: elem.toDate,
          fromTime: isTimeAv ? elem.fromTime : '',
          toTime: isTimeAv ? elem.toTime : ''
        });
      });
      setAddedLeaves(leavesArr);
    }
  }, [editLeaveData]);

  const handleShiftSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomHrsSwitch(event.target.checked);
  };

  const submitHandler = async () => {
    handleStepsFormData(addedLeaves, 'step4');
  }



  function handleAddLeaves(leaveData: any) {
    let selectedBranch = branchList.find((item: any) => item._id === leaveData.branchId);
    let leaveObj: any = {};
    if (!customHrsSwitch) {
      leaveObj = { ...leaveData, fromTime: "00:00", toTime: "23:59" }
    }else{
      leaveObj = {...leaveData}
    }
    leaveObj.branchName = brancdata[0]?.branchName;
    leaveObj.branchId = branchId;
    leaveObj.isChecked = customHrsSwitch;
    let addedLeaveArr: any = [...addedLeaves];
    if (editLeaveIndex >= 0) {
      addedLeaveArr.splice(editLeaveIndex, 1);
      addedLeaveArr.splice(editLeaveIndex, 0, leaveObj);
    } else {
      addedLeaveArr.push(leaveObj);
    }
    
    setAddedLeaves(addedLeaveArr);
    setEditLeaveIndex(-1);
    reset();
    setCustomHrsSwitch(false);
  }

  function handleRemoveLeaves(obj: any) {
    let newSelected: any = [...addedLeaves];
    const index = newSelected.findIndex((item: any) => (
      JSON.stringify(item) === JSON.stringify(obj)
    ));
    newSelected.splice(index, 1);
    setAddedLeaves(newSelected);
  }

  function handleEditLeaves(obj: any) {
    const index = addedLeaves.findIndex((item: any) => (
      JSON.stringify(item) === JSON.stringify(obj)
    ));
    setEditLeaveIndex(index);
    setCustomHrsSwitch(obj.isChecked);
    setValue('branchId', obj.branchId);
    setValue('fromDate', obj.fromDate);
    setValue('toDate', obj.toDate);
    setValue('fromTime', obj.fromTime);
    setValue('toTime', obj.toTime);
    window.scrollTo(0, 0);
  }

  function mapSameRelatedValuesInArray(valsArr: any) {
    const sameValuesMap: any = {};
    valsArr.forEach((item: any) => {
      const { branchId, branchName } = item;
      if (!sameValuesMap[branchId]) {
        sameValuesMap[branchId] = { branchId, branchName, leaves: [] };
      }
      sameValuesMap[branchId].leaves.push(item);
    });
    const resultArray = Object.values(sameValuesMap);
    return resultArray;
  }

  console.log(addedLeaves , "addedLeaves");
  
  let leavesBranchWise = mapSameRelatedValuesInArray(addedLeaves?.filter((item: any) => item?.branchId === branchId));



  function filterTimeArray(timeArray: any, rangeObjects: any) {
    return timeArray.filter((time: any) => {
      for (const range of rangeObjects) {
        const fromTime = new Date(`2000-01-01T${range.from}`);
        const toTime = new Date(`2000-01-01T${range.to}`);

        const currentTime = new Date(`2000-01-01T${time.value}`);

        if (currentTime >= fromTime && currentTime <= toTime) {
          return false; // exclude this time
        }
      }
      return true; // include this time
    });
  }

  const filteredTimeArray = filterTimeArray(timeValues, notAvailableHrs);

  return (
    <>
      <SuccessPopup showSuccessModal={showSuccessModal} path="/business/teams" handleSuccessModalClose={handleSuccessModalClose} message={message} />
      <Row>
      <Col md={12}>
        <div className="text-end">
          <HelpButon number={13} type={"help"} className={"btn"} />
        </div>
      </Col>
        <Col md={12}>
          <div className={`${st.formBox} ${cx.locationSelect}`}>
            <h2>Mark Leaves</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className={`${st.formBox}`}>
            <label className="form-label">Assign Location</label>
            <select className="form-select"
            // {...register("branchId", {
            //   required: true,
            // })}
            >
              <option value="brancdata[0]?._id" selected>{brancdata[0]?.branchName}</option>
            </select>
            {errors?.branchId?.type === "required" && (
              <p className={"errorMessage"}>This field is required</p>
            )}
          </div>

        </Col>

      </Row>
      {
        // leaves &&
        (
          <>
            <form onSubmit={handleSubmit(handleAddLeaves)}>
              <Row>
                <Col lg={3} md={6}>
                  <div className={`${st.formBox} ${cx.dateBox} `}>
                    <label htmlFor="from" className="form-label">
                      From
                    </label>
                    <input
                      type="date"
                      id="from"
                      min={disablePastDate()}
                      className={`${cx.dobInput} form-control`}
                      {...register("fromDate", {
                        required: true,
                      })}
                    />
                    {/* <img src={calendar} alt="shedule" className={cx.scheduleIcon} /> */}
                    {errors?.fromDate?.type === "required" && (
                      <p className={"errorMessage"}>required</p>
                    )}
                  </div>
                </Col>
                <Col lg={3} md={6}>
                  <div className={`${st.formBox} ${cx.dateBox} `}>
                    <label htmlFor="to" className="form-label">
                      To
                    </label>

                    <input
                      type="date"
                      id="to"
                      className={`${cx.dobInput} form-control`}
                      min={watch('fromDate', disablePastDate())}
                      {...register("toDate", {
                        required: true,
                      })}
                    />
                    {/* <img src={calendar} alt="shedule" className={cx.scheduleIcon} /> */}
                    {errors?.toDate?.type === "required" && (
                      <p className={"errorMessage"}>required</p>
                    )}
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className={cx.customHour}>
                    <div className={`${st.formBox} ${cx.toggle} w-auto`}>
                      <label className="form-label mb-2 text-nowrap">
                        Custom Hours
                      </label>
                      <Switch
                        checked={customHrsSwitch}
                        onChange={handleShiftSwitchChange}
                        color={!customHrsSwitch ? "#7F8699" : "#1AB192"}
                        backgroundColor="#fff"
                        key={2}
                        id={"team"}
                        className={"mt-2"}
                      />
                    </div>

                    {customHrsSwitch && <>
                      <div className={`${st.formBox} ${cx.customInput} `}>
                        <label htmlFor="from" className="form-label">
                          From
                        </label>
                        <Controller
                          name={`fromTime`} // This should match your form data structure
                          control={control}
                          defaultValue="" // Initial value
                          rules={{ required: true }}
                          render={({ field }) => (
                            <CustomTimeSelect value={field.value} onChange={field.onChange} timeValues={filteredTimeArray} required={false} />
                          )}
                        />
                        {errors[`fromTime`]?.type === "required" ? (
                          <span className="errorMessage">required*</span>
                        ) : <span></span>
                        }
                      </div>
                      <div className={`${st.formBox} ${cx.customInput} `}>
                        <label htmlFor="from" className="form-label">
                          To
                        </label>
                        <Controller
                          name={`toTime`} // This should match your form data structure
                          control={control}
                          defaultValue="" // Initial value
                          rules={{ required: true }}
                          render={({ field }) => {
                            const filteredTimeValues = filteredTimeArray?.filter((time: any) => time.value >= watch(`fromTime`, ''));
                            return (
                              <CustomTimeSelect value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={false} />
                            );
                          }}
                        />
                        {errors[`toTime`]?.type === "required" ? (
                          <span className="errorMessage">required*</span>
                        ) : <span></span>
                        }
                      </div>
                    </>}
                    <div className={`${st.formBox} mt-1 `}>
                    <label htmlFor="d" className="opacity-0">d</label>
                      <button type="submit" className={`${cx.addButton} singleBtn mt-0 w-auto`}>
                        {editLeaveIndex >= 0 ? 'Edit Leave Day' : 'Save'} 
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
             
            </form>
          </>
        )}

      {leavesBranchWise.map((elem: any, i: number) => {
        return (
          <div key={elem.branchId} className="mt-3">
            <Row>
              <Col md={6}>
                <div className={`${st.formBox}`}>
                  <div>
                    <label className="form-label">Assigned Location</label>
                    <span> - </span>
                    <label className={cx.branchDisplay}>{elem.branchName}</label>
                  </div>
                </div>
              </Col>
            </Row>
            {i < 1 && <Row>
              <Col lg={4} md={6}>
                <div className={`${st.formBox} ${cx.displayDate} `}>
                  <div className={cx.entry}>
                    <label htmlFor="from" className="form-label">
                      From
                    </label>
                  </div>
                  <div className={cx.entry}>
                    <label htmlFor="from" className="form-label">
                      To
                    </label>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className={cx.customHour}>
                  <div className={`${st.formBox} ${cx.toggle} `}>
                    <label className="form-label mb-2">
                      Custom Hours
                    </label>

                  </div>
                  <div className={`${st.formBox} ${cx.displayDate} `}>
                    <div className={cx.entry}>
                      <label htmlFor="from" className="form-label">
                        From
                      </label>
                    </div>
                    <div className={cx.entry}>
                      <label htmlFor="from" className="form-label">
                        To
                      </label>
                    </div>
                  </div>
                </div>
              </Col>

            </Row>}
            {elem.leaves?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <Row>
                    <Col lg={4} md={6}>
                      <div className={`${st.formBox} ${cx.displayDate} `}>
                        <div className={cx.entry}>
                          <p>{item?.fromDate}</p>
                        </div>
                        <div className={cx.entry}>
                          <p>{item.toDate}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} md={12}>
                      <div className={cx.customHour}>
                        <div className={`${st.formBox} ${cx.toggle} `}>
                          <div
                            className={st.greenSwitch}
                            style={{ background: '#fff' }}
                          >
                            <input
                              type="checkbox"
                              className={`${st.toggleInput} mt-2`}
                              checked={item.isChecked}
                              disabled
                            />
                            <label className={st.toggleLabel}></label>
                            <div className={st.toggleThumb} style={{ background: `${!item.isChecked ? "#7F8699" : "#1AB192"}` }}></div>
                          </div>
                        </div>

                        <div className={`${st.formBox} ${cx.displayDate} `}>
                          <div className={cx.entry}>
                            {item.isChecked && <p>{item.fromTime}</p>}
                          </div>
                          <div className={cx.entry}>
                            {item.isChecked && <p>{item.toTime}</p>}
                          </div>
                        </div>

                      </div>
                    </Col>

                    <Col lg={2} md={4}>
                      <div className={`${cx.action} ${cx.v2}`}>
                        <div className={`${cx.btn}`}>
                          <span><img src={editButton} alt="edit"
                            onClick={() => handleEditLeaves(item)} /></span>
                          <span><img src={deleteIcon} alt="delete"
                            onClick={() => handleRemoveLeaves(item)} /></span>
                        </div>
                      </div>
                    </Col>

                  </Row>
                </div>
              )
            })}
          </div>
        )
      })}

      <Row>
        <Col md={12}>
          <div className={`${st.formBox}`}>
            <div className={`${st.btnsGroup}`}>
              <button className={`btn ${st.btn1}`}
                onClick={() => handleStepsFormData('prevStep', 'services')}>
                Back
              </button>
              <button className={`btn ${st.btn4}`} onClick={submitHandler} disabled={loading}>
                {loading ? (<LoadingBtn />
                ) : (
                  'Next'
                )}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
