import React, { useEffect, useState } from 'react';
import st from '../../../assets/stylesheet/style.module.scss';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from "react-redux";
import ErrorPopup from '../../../components/Business/Modals/ErrorPopup';

export default function SuggestedCategory({ cat, getServiceCatagory, catData }: any) {
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const [serviceTags, setServiceTags] = useState<any>([])
    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const addedCat = catData?.find((item: any) => {
        return item?.serviceCategoryName === cat?.serviceCategoryName
    })

    const serviceTaglist = async () => {
        const res = await httpRequest(`get_ServiceTag`, "get", null, "auth");
        if (res.status === true) {
            // let servTags = res?.data?.map((item: any) => item?.tags)      
            let servTags = res?.data

            setServiceTags(servTags);
        }
    };

    useEffect(() => {
        if (cat?._id) {
            serviceTaglist()
        }
    }, [cat])

    const filterTags = serviceTags?.filter((tag: any) => tag?.businessCategoryId && tag?.businessCategoryId?.some((businessCat: any) => cat?.businessCategoryId?.some((bCat: any) => bCat?._id === businessCat?._id)))
    const handleAddCat = async () => {
        const data = {
            businessId: businessData?._id,
            serviceCategoryName: cat?.serviceCategoryName,
            serviceCategoryDetails: cat?.serviceCategoryDetails || '',
            serviceCategoryTag: filterTags?.map((item: any) => item?.tags),
            businessCategoryId: cat?.businessCategoryId?.map((item: any) => item?._id),
            superServiceCategoryId: cat?._id
        }
        const res = await httpRequest("createServiceCatagory", 'POST', data, 'json');
        if (res.status) {
            getServiceCatagory && getServiceCatagory()
            handleAlertModalShow("Success, Service category added successfully")
        } else {
            handleAlertModalShow(`Error, ${res?.message}`)
        }
    }
    const handleDeleteCat = async () => {
        const res = await httpRequest(
            `deleteServiceCatagory?_id=${addedCat?._id}&businessId=${businessData?._id}`,
            "DELETE",
            null,
            "json"
        );
        if (res.status) {
            getServiceCatagory && getServiceCatagory()
        } else {
            handleAlertModalShow(`Error, ${res?.message}`)
        }
    }
    return (
        <>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            {!addedCat?._id && <li>
                <span className={`${st.categoryName}`}>{cat?.serviceCategoryName}</span>
                <span className={`${st.categoryAvail}`}>
                    <span className={`${st.value}`} style={{ fontSize: '14px', borderRadius: '54px', color: '#fff', fontWeight: '500', textAlign: 'center', minWidth: '80px', padding: '2px 0', maxWidth: '80px', background: `${addedCat ? '#cf4956' : '#508AAA'}`, margin: '0', cursor: 'pointer' }} onClick={() => addedCat?._id ? handleDeleteCat() : handleAddCat()}>{addedCat ? <span>Remove</span> : <span>Add</span>}</span>
                </span>
            </li>}
        </>
    )
}
