import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { basicAmenity, basicInfo, basicInfoA } from "../../../assets/images";
import BackArrrow from "../../../components/Business/Buttons/BackArrow";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const EditAmenities = (props: any) => {
  const datalocation: any = useLocation();
  let amenities: any = datalocation?.state;

  const [globalAmenities, setGlobalAmenities] = useState<any>([]);
  const navigate = useNavigate();
  let backButton = () => {
    navigate(-1);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const [qty, setqty] = useState<string>("");
  const [itemName, setitemName] = useState("");

  useEffect(() => {
    if (amenities) {
      setqty(amenities?.quantity);
      setitemName(amenities?.gId);
    }
  }, [amenities])
  const [error, seterror] = useState(false)
  let editAmenities = async () => {
    if (qty.length < 1) {
      seterror(true)
      return;
    } else if (itemName.length < 1) {
      seterror(true)
      return;
    } else {

      if (amenities?.global) {
        let data = {
          itemName: amenities?.gId,
          qty: qty,
          type: true,
          businessId: businessData?._id,
          branchId: branchId,
        }
        let res = await httpRequest(`createAmenities`, "Post", data, "json");
        if (res.status === true) {
          setTimeout(() => {
            navigate(-1);
          }, 1000)
        } else if (res.message === "Item name already exist") {
          setTimeout(() => {
            navigate(-1);
          }, 1000)
        }
        return
      }
      let data = {
        qty: qty,
        itemName: amenities?.itemName !== itemName ? itemName : undefined,
        businessId: businessData?._id,
      }
      const res = await httpRequest(`updateAmenities?branchId=${branchId}&businessId=${businessData?._id}&id=${amenities.id}`, "patch", data, "json");
      if (res.status === true) {
        setTimeout(() => {
          navigate(-1);
        }, 1000)
      } else if (res.message === "Item name already exist") {
        setTimeout(() => {
          navigate(-1);
        }, 1000)
      } else {
        seterror(res.message)
      }
    }
  };
  const getGlobbalAmenities = async () => {
    let res = await httpRequest(`getGlobalAmenities?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setGlobalAmenities(res?.data);
    }
  };

  useEffect(() => {
    getGlobbalAmenities();
  }, [businessData])
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {/* <BackArrrow />  */}
              Edit Amenity
            </h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img
                    src={basicInfo}
                    alt="info"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={basicInfoA}
                    alt="info"
                    className={`${st.active}`}
                  />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <Row className="align-items-center justify-content-between">
                  <Col md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Item Name</label>
                      <input type="text"
                        className="form-control"
                        placeholder="Enter Amenity Name"
                        value={amenities?.itemName}
                        readOnly
                        disabled
                      />
                    </div>
                    {error && itemName === "" && (
                      <p className={"errorMessage"}>This field is required</p>
                    )}
                  </Col>

                  <Col md={6}></Col>

                  <Col md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Quantity</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Quantity"
                        defaultValue={amenities?.quantity}
                        onChange={((e: any) => {
                          setqty(e.target.value)
                        })}
                      />
                    </div>
                    {error && qty === "" && (
                      <p className={"errorMessage"}>This field is required</p>
                    )}
                  </Col>
                  <Col md={6}></Col>

                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button className={`btn ${st.btn1}`} onClick={backButton}>Back</button>
                        <button className={`btn ${st.btn4}`} onClick={editAmenities}>Save</button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default EditAmenities;
