import React from "react";
import m from "./Modal.module.scss";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const DeletePopup = (props: any) => {
  let { show, handleClose, deleteApi,deleteServiceCategory,deleteTeamMember,apiMessage = "",deletePackages,deleteAmenities,deleteClient, message } = props;
  return (
    <>
      <Modal
        centered
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className={`${m.logoutPopup}`}>
            <h3 style={{marginBottom: apiMessage ? "7px" : "24px"}}>
              Do you want to delete this{" "}
              <span className={m.deleteItem}>{message ? message : "item"}</span>?
            </h3>
            {apiMessage && <h3>
              <span className={m.deleteItem} style={{color:"red"}}>{apiMessage ? apiMessage : ""}</span>?
            </h3>}
            <div className={`${m.btnsAlignments}`}>
              <NavLink
                className={`btn ${m.cancelBtn}`}
                to=""
                onClick={handleClose}
              >
                Cancel
              </NavLink>
              {deleteServiceCategory !== undefined?(
                 <button type="button" className={`btn ${m.actionBtn}`} onClick={deleteServiceCategory} > Delete </button>
              ):deleteTeamMember !== undefined?(
                <button type="button" className={`btn ${m.actionBtn}`} onClick={deleteTeamMember} > Delete </button>
              ):deletePackages !==undefined?(
                <button type="button" className={`btn ${m.actionBtn}`} onClick={deletePackages} > Delete </button>
              ):deleteAmenities !== undefined?(
                <button type="button" className={`btn ${m.actionBtn}`} onClick={deleteAmenities} > Delete </button>
              ):deleteClient !== undefined?(
                <button type="button" className={`btn ${m.actionBtn}`} onClick={deleteClient} > Delete </button>
              ):(
              <button type="button" className={`btn ${m.actionBtn}`} onClick={deleteApi} > Delete </button>
              )} </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeletePopup;
