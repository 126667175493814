import * as React from 'react';
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { backArrowBlk, backArrowGredient } from '../../../assets/images';

interface IBackArrrowProps {
}

const BackArrrow: React.FunctionComponent<IBackArrrowProps> = (props) => {
    const navigate = useNavigate();
  return (
    <img src={backArrowGredient} alt="arrow" style={{cursor:"pointer", width:"29px", marginTop:"4px", marginBottom:"25px"}} className="me-1" onClick={() => navigate(-1)}/>
    // <BsArrowLeft />
  );
};

export default BackArrrow;
