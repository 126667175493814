import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import { FaTelegramPlane } from "react-icons/fa";
import { Badge, Col, Dropdown, Row } from "react-bootstrap";
import { call, callWhite, img1 } from "../../../assets/images";
import { FiCamera, FiSearch } from "react-icons/fi";
import { GrAttachment } from "react-icons/gr";
import { FaUserPlus } from "react-icons/fa";
import { MdCall, MdKeyboardVoice, MdOutlineMoreVert } from "react-icons/md";
import User from "./User";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import { auth, db, storage } from "../../../Firebase";
import { doc, setDoc, getDocs, getDoc, onSnapshot, collection, updateDoc } from "firebase/firestore";
import Chats from "./Chats";
import { convertMinutesToTime } from "../../../Utils/Funcs";
import moment from 'moment';
import { ContactDetails } from "../../../components/Business/Modals";

const ChatBox = (props: any) => {
    const [showContactDetails, setContactDetailsShow] = useState(false);
    const handleContactDetailsClose = () => setContactDetailsShow(false);
    const handleContactDetailsShow = () => setContactDetailsShow(true);

    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const [clientData, setClientData] = useState<any>([]);
    const [selectedChat, setSelectedChat] = useState<any>(null);

    const [currentUser, setCurrentUser] = useState<any>([]);
    const [bookingDetils, setBookingDetils] = useState<any>([])

    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [chatHeadList, setChatHeadList] = useState(null);
    const [clientChatHeadList, setClientChatHeadList] = useState(null);
    const [filterMessaeg, setFilterMessaeg] = useState("")
    const [bookingData, setBookingData] = useState<any>();
    const [activeUsers, setActiveUsers] = useState<any[]>([]);
    const location = useLocation();

    const [appointment, setAppointment] = useState<any>({})
    useEffect(() => {
        if (bookingDetils?.length !== 0 && !location?.state?.appointment) {
            setAppointment(bookingDetils?.[0])
        }
        if (location?.state?.appointment) {
            setAppointment(location?.state?.appointment)
        }
    }, [location?.state, bookingDetils])
    // const { appointment } = location?.state;

    let currentId: any = new Date(selectedChat?.Client?.createdAt).getTime()
    const stringWithoutHyphens = selectedChat?._id;

    useEffect(() => {
        let userData: any = localStorage?.getItem("user_data");
        if (businessData) {
            userData = JSON.parse(userData);
        }
        setCurrentUser(businessData);
    }, [businessData]);

    useEffect(() => {
        if (businessData) {   
            const unSub = onSnapshot(
                collection(db, "chatHeads", businessData?._id, 'chatBusiness'),
                (querySnapshot) => {
                    let headdata: any = [];
                    querySnapshot.forEach((doc) => {
                        headdata.push(doc.data())
                    });
                    setChatHeadList(headdata);
                }
            );
            return () => {
                unSub();
                onlineStatusToFalse();
            };
        }
    }, [businessData,selectedChat]);    

    useEffect(() => {
        if (businessData) {
            getClientData();
            getAllBookings();
        }
    }, [businessData]);

    useEffect(() => {
        addOnlineStatus(currentUser._id)
    }, [])

    useEffect(() => {
        const totalDuration = selectedChat?.services?.reduce((acc: any, val: any) => Number(acc) + Number.parseInt(val?.serviceId?.duration), 0)
        const startArr = selectedChat?.salectTime?.split(":");
        const startHours = Number.parseInt(startArr?.[0]);
        const startMinutes = Number.parseInt(startArr?.[1]);
        const totalStartMinutes = startHours * 60 + startMinutes
        const totalEndMinutes = totalStartMinutes + totalDuration
        let formattedStartTime = convertMinutesToTime(totalStartMinutes);
        let formattedEndTime = convertMinutesToTime(totalEndMinutes);
        setStartTime(formattedStartTime)
        setEndTime(formattedEndTime)
    }, [selectedChat])

    useEffect(() => {
        if (bookingDetils) {
            let filterData = bookingDetils.filter((item: any) => {
                return item?._id === appointment;
            });
            if (filterData.length > 0) {
                const idValue = filterData[0]?._id || '';
                const replacedIdValue = idValue.replace(/-/g, '')
                const modifiedData = { ...filterData[0], _id: replacedIdValue };
                setSelectedChat(modifiedData);
            }
        } else {
            console.log("Booking details or appointment ID not available.");
        }
    }, [bookingDetils, appointment]);

    useEffect(() => {
        if (selectedChat) {
            const unSub = onSnapshot(doc(db, "onlineStatus", selectedChat?.clientId),
                (docSnapshot: any) => {
                    let statusdata: any = [];

                    if (docSnapshot.exists()) {
                        let docdata = docSnapshot.data()
                        if (docdata?.status) {
                            statusdata.push(docdata.id);
                        }
                        setActiveUsers([...statusdata]);

                    } else {
                        console.log("Document does not exist.");
                    }
                },
                (error: any) => {
                    console.error("Error fetching onlineStatus document:", error);
                }
            );

            return () => {
                unSub();
            }
        }
    }, [selectedChat]);

    const firstNameLetter = selectedChat?.Client?.firstName?.charAt(0).toUpperCase();
    const firstLastNameLetter = selectedChat?.Client?.lastName?.charAt(0).toUpperCase();

    const getClientData = async () => {
        let res = await httpRequest(`getClient?businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setClientData(res.data);
        }
    };
    const [loading, setLoading] = useState<any>(false)
    const getAllBookings = async () => {
        setLoading(true)
        let res = await httpRequest(`getBooking?businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setBookingDetils(res?.data)
            setLoading(false)
        }
    }


    function handleChatSelection(chat: any) {
        if (chat) {
            setSelectedChat(chat)
            setBookingData(chat?.Client?._id)
        }
    }

    
    const onlineStatusToFalse = async () => {
        try {
            if (stringWithoutHyphens) {
                await updateDoc(doc(db, "onlineStatus", currentUser?._id), {
                    status: false,
                    id: currentUser?._id,
                    time: Date.now()
                });
            }
        } catch (err) {
            throw err
        }
    };
    const addOnlineStatus = async (csid: any) => {
        try {
            if (currentId && csid) {
                const res: any = await getDoc(doc(db, "onlineStatus", currentUser._id));
                if (!res.exists()) {
                    await setDoc(doc(db, "onlineStatus", currentUser._id), {
                        status: true,
                        id: currentUser._id,
                        time: Date.now()
                    });
                } else {
                    await updateDoc(doc(db, "onlineStatus", currentUser._id), {
                        status: true,
                        id: currentUser._id,
                        time: Date.now()
                    });
                }
            }
        } catch (err) {
            throw err
        }
    };
    const chatmessage = async (e: any) => {
        if (e) {
            setFilterMessaeg(e)
        } else {
            setFilterMessaeg("")
        }
    }
    const formattedDate = new Date(selectedChat?.salectDate).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
    const isActive = activeUsers.includes(selectedChat?.clientId);

    return (
        <>
            <section className={`${st.pageWrapper} pb-0 pe-0 ps-1`} style={{paddingTop:'59px !important'}}>
                <Row className="m-0">
                    <User
                        chatUserList={bookingDetils}
                        handleChatSelection={handleChatSelection}
                        currentUser={currentUser}
                        selectedChat={selectedChat}
                        chatHeadList={chatHeadList}
                        chatmessage={chatmessage}
                        loading={loading}
                        uid={stringWithoutHyphens}
                    />
                    {selectedChat?.Client?._id && <Col lg={5} className={`${cx.detailBox} ${cx.chatBoxHide} p-0 `}>

                        <div className={`${cx.chatbody}`}>
                            <Row className={`${cx.chatHead}`}>
                                <Col md={10}>

                                    <div className={`${cx.userDetails}`}>
                                        {!selectedChat?.Client?.image ? (
                                            <div className={cx.emptyImageBox}>
                                                <span>{selectedChat?.Client?.firstName?.[0]?.toLocaleUpperCase()}{selectedChat?.Client?.lastName?.[0]?.toLocaleUpperCase()}</span>
                                            </div>
                                        ) : <img src={selectedChat?.Client?.image ? selectedChat?.Client?.image : img1} className={`${cx.profileImg}`} />}
                                        <div className={`${cx.chatListBody}`}>
                                            {/* <h5>{selectedChat?.Client?.firstName} {selectedChat?.Client?.lastName} ({selectedChat?.services?.map((item: any) => item?.serviceDetails?.serviceName).join(",")})</h5> */}
                                            {/* <h5>{selectedChat?.Client?.firstName} {selectedChat?.Client?.lastName}</h5> */}
                                            <h5>{`${selectedChat?.Client?.firstName} ${selectedChat?.Client?.lastName}`}</h5>

                                            <p className={`${cx.status}`}>
                                                {/* <span style={{ background: "#15a620" }}></span> */}
                                                {isActive ? (
                                                    <span> Online</span>
                                                ) : (
                                                    <span>Offline</span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <ul className={`${cx.rightTopList}`}>

                                    </ul>
                                </Col>
                            </Row>
                            {selectedChat?._id && <Chats selectedChat={selectedChat} uid={stringWithoutHyphens}
                                currentUserId={selectedChat?._id}
                                chatUserList={bookingDetils}
                                currentUser={currentUser}
                                chatHeadList={chatHeadList}
                                filterMessaeg={filterMessaeg}
                                clientChatHeadList={clientChatHeadList}
                                loading={loading}
                            />}
                        </div>
                    </Col>}
                    {selectedChat?._id && <Col className={`${cx.detailBox} col p-0 `}>

                        <div className={`${cx.detailBody}`}>
                            <div className={`${cx.heading}`}>
                                <h5>Details</h5>
                            </div>
                            <div className={`${cx.connection}`}>

                                <div className={`${cx.connectionBox}`}>
                                    {selectedChat?.Client?.image ? <div className={`${cx.imageBox}`}>
                                        {/* <span className={`${cx.role}`}>Saloon</span> */}
                                        <img src={selectedChat?.Client?.image ?? ""} className={`${cx.connectImage}`} />
                                    </div> : (
                                        <div className={`${cx.imageLogoBox}`}>
                                            <span>{firstNameLetter}{firstLastNameLetter}</span>
                                        </div>
                                    )}
                                    <h3>{selectedChat?.Client?.firstName} {selectedChat?.Client?.lastName}</h3>
                                    <div className={`${cx.callBox}`} >
                                        <img src={callWhite} alt="call-icon" onClick={handleContactDetailsShow} />
                                    </div>
                                </div>
                            </div>
                            <div className={`${cx.dateHeading}`}>
                                <h5>Appointment date</h5>
                            </div>
                            <div className={`${cx.appointment}`}>

                                <h2> {formattedDate}</h2>
                                <p>{startTime} -{endTime}</p>
                                <div className={`${cx.services}`}>
                                    {selectedChat?.services?.map((item: any) => {
                                        return (
                                            <span> {item?.serviceDetails?.serviceName} ({item?.TeamMemberId?.[0]?.firstName ? `${item.TeamMemberId[0].firstName} ${item.TeamMemberId[0].lastName}` : 'Any professional'})</span>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>
                        <div className={`${cx.price}`}>
                            <span>Price</span>
                            <span className={`${cx.amount}`}>{selectedChat?.finalPrice}USD</span>
                        </div>
                    </Col>}
                </Row>
            </section>
            {/* </Col> */}

            <ContactDetails show={showContactDetails} clientDetails={selectedChat?.Client} handleContactDetailsClose={handleContactDetailsClose} />

        </>


    );
};

export default ChatBox;
