import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import {
  deleteIcon,
  editIcon,
  openIcon,

} from "../../../assets/images";
import { Status } from "../../../components/Business";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  const { params, data } = props;
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [apiMessage, setApiMessage] = useState<any>("")
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setApiMessage("");
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const handleViewClick = () => {
    let dataToView = data?.find((val: any, index: any) => {
      return val._id == params?.row?.id;
    });
    navigate("view-bundle", { state: dataToView });
  }

  const handleEditClick = () => {
    let dataToEdit = data?.find((val: any) => {
      return val._id == params?.row?.id;
    });
    navigate("edit-bundle/info", { state: { bundleId: params?.row?.id } });
    // navigate("edit-service", { state: dataToEdit });
  }

  const deleteApi = async () => {
    const res = await httpRequest(
      `deleteBundle/${params?.row?.id}`,
      "DELETE",
      null,
      "json"
    );
    if (res.status === true) {
      props.getBundle();
      setApiMessage("")
      handleClose();
    } else {
      setApiMessage(res?.message)
    }
  };

  const canEditBundle = subModuleRoles?.['Services']?.Bundles?.Edit;
  const canDeleteBundle = subModuleRoles?.['Services']?.Bundles?.Delete;
  const canDeactivate = subModuleRoles?.['Services']?.Bundles?.deactivate;
 

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {canEditBundle && <li>
            <p
              className={`btn ${tb.edit}`}
              title="Edit"
            >
              <img src={editIcon} alt="edit" onClick={() => {
                handleEditClick();
              }} />
            </p>
          </li>}
          <li>
            <p
              className={`btn ${tb.edit}`}
              title="view"
            >
              <img src={openIcon} alt="view"
                onClick={() => {
                  handleViewClick();
                }}
              />
            </p>
          </li>
          {canDeleteBundle && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>}
          {canDeactivate && <li>
            <Status status={params.row.isActive}
              api='updateBundle'
              id={params?.id}
              apiMethod='json'
              getLatest={props.getBundle} />
          </li>}
        </ul>
      </div>

      <DeletePopup
        show={show}
        handleClose={handleClose}
        deleteApi={deleteApi}
      />
    </>
  )
};


export default function DataGridTable({ bundleList, getBundle }: any) {
  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 280,
      renderCell: (params: any) => <StatusButton params={params} getBundle={getBundle} data={bundleList} />,
    },
    { field: "bundleName", headerName: "Bundle Name", flex: 1, minWidth: 200 },
    { field: "services", headerName: "Services", flex: 1, minWidth: 120 },
    { field: "amount", headerName: "Amount", flex: 1, minWidth: 150 },
    { field: "time", headerName: "Time", flex: 1, minWidth: 150 },
    { field: "saleStatus", headerName: "Sale Status", flex: 1, minWidth: 150 },

  ];

  let rows: any[] = [];
  if (bundleList?.length !== 0) {
    rows = bundleList?.map((val: any, index: any) => {
      const totalDuration = val?.priceId?.reduce((acc:any, val:any) => acc + Number.parseInt(val?.id?.duration) , 0)
      return {
        id: val?._id,
        serialNumber: index + 1,
        bundleName: val?.bundleName,
        services: val?.priceId?.map((service:any) => service?.id?.serviceId?.serviceName)?.join(", ") ,
        amount: val?.priceId?.length !==0 ? `SGD ${val?.finalPrice}` : "--",
        time: val?.priceId?.length !==0 ? `${totalDuration} mins` : "--",
        saleStatus: "",
        action: "action",
        isActive: val?.isActive
      };
    });
  }

  return (
    <div className={`${tb.dataTable}`}>
      <div style={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          autoHeight
          hideFooterPagination={false}
          rowHeight={52}
          checkboxSelection
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}
