import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import { plus, } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";

const Bundles = (props: any) => {
  const [bundleList, setBundleList] = useState<any>([]);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );

  const getBundle = async () => {
    const res = await httpRequest(`getBundle?businessId=${businessData?._id}&type=list`, "get", null, "json");
    if (res.status) {
      setBundleList(res?.data?.filter((item:any) => item?.businessId === businessData?._id));
    }
  };
  useEffect(() => {
    if(businessData?._id){
      getBundle()
    }
  },[businessData])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const canCreateService = subModuleRoles?.['Services']?.Bundles?.Create;

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Bundle List</h5>
          </div>
          <div className={`${st.rightInfo}`}>

          </div>
        </div>

        {canCreateService && <div className={`${st.buttonsAction}`}>
          <ul>
            <li>
              <ExportBtn />
            </li>
            <li>
              <ImportBtn />
            </li>
            <li>
              <NavLink to="addBundles/info" className={`btn ${st.darkBtn}`}>
                <img src={plus} alt="Create new bundle" /> Create new Bundle
              </NavLink>
            </li>
          </ul>
        </div>}

        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <DataGridTable bundleList={bundleList} getBundle={getBundle}/>
          </div>
        </div>
      </section>
    </>
  );
};

export default Bundles;
