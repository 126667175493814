import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import st from "../../../assets/stylesheet/style.module.scss";
import "react-quill/dist/quill.snow.css";
import { Col, Row } from "react-bootstrap";

export default function ViewNotOpHrs(props: any) {
    const { type } = props
    const datalocation: any = useLocation();
    let state: any = datalocation?.state;
    const navigate = useNavigate();

    const [hoursArr, setHrsArr] = useState<any>([]);

    useEffect(() => {
        setHrsArr(type === 'Working' ? state?.workingHours : state?.offPeakHours);
    }, []);


    return (
        <>
            {type === 'Working' && <Row>
                <Col md={12} className="mt-2">
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                        <h2>Non Operating Hours </h2>
                    </div>
                </Col>
            </Row>
            }

            {
                type === 'Working' && state?.notWorkingHours?.map((item: any, index: number) => {
                    return (
                        <Row key={index}>
                            <Col lg={3} md={3}>
                                <div className={`${st.formBox} ${cx.dateBox} `}>
                                    {index === 0 && <label htmlFor="from" className="form-label">
                                        From
                                    </label>}

                                    <div
                                        className={`position-relative d-flex align-items-center gap-5 ${cx.inputBackground}`}
                                    >
                                        <div className={cx.editContainer}>
                                            <p>{item?.fromDate}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={3} md={3}>
                                <div className={`${st.formBox} ${cx.dateBox} `}>
                                    {index === 0 &&
                                        <label htmlFor="from" className="form-label">To
                                        </label>}
                                    <div
                                        className={`position-relative d-flex align-items-center gap-5 ${cx.inputBackground}`}
                                    >
                                        <div className={cx.editContainer}>
                                            <p>{item?.toDate}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={12}>
                                <div className={`${st.formBox}`}>
                                    {index === 0 &&
                                        <><label className="form-label">Full Day</label><br /></>
                                    }
                                    <div className={` ${cx.timeCheckbox}`}>
                                        <label className={`${st.checkbox}`}>
                                            <input type="checkbox" checked={item.isFullDay} />
                                            <span className={`${st.checkmark}`}></span>
                                            <span className="ms-2"></span>
                                        </label>
                                        {item.from !== '' && <select className="form-select" disabled>
                                            <option>{item.from ? item.from : "not set"}</option>
                                        </select>}
                                        {item.from !== '' && <select className="form-select" disabled>
                                            <option>{item.to ? item.to : "not set"}</option>
                                        </select>}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    )
                })
            }
            {
                type === 'Working' && state?.notWorkingHours?.length === 0 && (
                    <p style={{ fontSize: "17px", textAlign: "center" }}>Please enter non operating hours (if applicable)</p>
                )
            }
            <Row>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => navigate(-1)}
                            >
                                Back
                            </button>

                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
