import React, { useEffect } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
  basicInfo,
  basicInfoA,
  favourite,
  hair,
  hairdresser,
  locationGreen,
  profile,
  rightArrowIcon,
  star,
  testBig,
  tik2,
  wax,
  waxfill,
} from "../../../assets/images";
import { NavLink } from "react-router-dom";
import { Brands, Deals, Testimonials } from "../../../components/Website";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BsStarFill } from "react-icons/bs";

interface CustomSliderProps {
  images: string[];
}
const Salon = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const images = [
    testBig,
    testBig,
    testBig,
    testBig,
    testBig,
    testBig,
    testBig,
    testBig,
    testBig,
    testBig,
    testBig,
    testBig,
  ];

  
  const options = {
    loop: true,
    items: 4,
    dots: false,
    nav:true,
    navText:[`<svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z" fill="#3C746D"/>
    <path d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z" stroke="#ACD9D0"/>
    <path d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z" stroke="#3C746D"/>
    <path d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z" fill="#3C746D"/>
    <path d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z" stroke="#ACD9D0"/>
    <path d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z" stroke="#3C746D"/>
    </svg>
    `,`<svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9173 19.6484H22.4173V19.1484V17.6484V17.1484H21.9173H1.35359H0.853586V17.6484V19.1484V19.6484H1.35359H21.9173Z" fill="#3C746D"/>
    <path d="M21.9173 19.6484H22.4173V19.1484V17.6484V17.1484H21.9173H1.35359H0.853586V17.6484V19.1484V19.6484H1.35359H21.9173Z" stroke="#ACD9D0"/>
    <path d="M21.9173 19.6484H22.4173V19.1484V17.6484V17.1484H21.9173H1.35359H0.853586V17.6484V19.1484V19.6484H1.35359H21.9173Z" stroke="#3C746D"/>
    <path d="M13.9631 29.8577L13.9238 30.3946L14.4621 30.3942L15.3567 30.3936L15.8169 30.3933L15.8547 29.9346C15.8564 29.9149 16.0167 28.0946 17.1646 25.9007L17.1646 25.9007C18.2302 23.8637 20.4015 21.0565 24.7011 19.3307L24.7011 19.3307L25.9902 18.8131L27.1462 18.349L25.9902 17.8851L24.7006 17.3676C20.4014 15.6417 18.2297 12.8352 17.1641 10.7976L17.1641 10.7976C16.0163 8.60314 15.8559 6.78348 15.8542 6.76368C15.8542 6.76363 15.8542 6.76359 15.8542 6.76357L15.8163 6.30403L15.3551 6.30469L14.9095 6.30533L14.9088 6.30533L14.9081 6.30533L14.4625 6.30469L13.9205 6.30391L13.9633 6.84421L13.9634 6.84495C13.9734 6.97187 14.1499 9.19771 15.4895 11.7863L15.4896 11.7864C16.2723 13.2984 17.2948 14.6629 18.5283 15.8407L18.5283 15.8407C19.5392 16.8059 20.6921 17.6448 21.9705 18.3492C20.6921 19.0538 19.5391 19.893 18.5278 20.8581L18.5278 20.8582C17.2943 22.036 16.2718 23.4005 15.489 24.9125L15.489 24.9126C14.1468 27.506 13.972 29.7356 13.9631 29.8577Z" fill="#3C746D"/>
    <path d="M13.9631 29.8577L13.9238 30.3946L14.4621 30.3942L15.3567 30.3936L15.8169 30.3933L15.8547 29.9346C15.8564 29.9149 16.0167 28.0946 17.1646 25.9007L17.1646 25.9007C18.2302 23.8637 20.4015 21.0565 24.7011 19.3307L24.7011 19.3307L25.9902 18.8131L27.1462 18.349L25.9902 17.8851L24.7006 17.3676C20.4014 15.6417 18.2297 12.8352 17.1641 10.7976L17.1641 10.7976C16.0163 8.60314 15.8559 6.78348 15.8542 6.76368C15.8542 6.76363 15.8542 6.76359 15.8542 6.76357L15.8163 6.30403L15.3551 6.30469L14.9095 6.30533L14.9088 6.30533L14.9081 6.30533L14.4625 6.30469L13.9205 6.30391L13.9633 6.84421L13.9634 6.84495C13.9734 6.97187 14.1499 9.19771 15.4895 11.7863L15.4896 11.7864C16.2723 13.2984 17.2948 14.6629 18.5283 15.8407L18.5283 15.8407C19.5392 16.8059 20.6921 17.6448 21.9705 18.3492C20.6921 19.0538 19.5391 19.893 18.5278 20.8581L18.5278 20.8582C17.2943 22.036 16.2718 23.4005 15.489 24.9125L15.489 24.9126C14.1468 27.506 13.972 29.7356 13.9631 29.8577Z" stroke="#ACD9D0"/>
    <path d="M13.9631 29.8577L13.9238 30.3946L14.4621 30.3942L15.3567 30.3936L15.8169 30.3933L15.8547 29.9346C15.8564 29.9149 16.0167 28.0946 17.1646 25.9007L17.1646 25.9007C18.2302 23.8637 20.4015 21.0565 24.7011 19.3307L24.7011 19.3307L25.9902 18.8131L27.1462 18.349L25.9902 17.8851L24.7006 17.3676C20.4014 15.6417 18.2297 12.8352 17.1641 10.7976L17.1641 10.7976C16.0163 8.60314 15.8559 6.78348 15.8542 6.76368C15.8542 6.76363 15.8542 6.76359 15.8542 6.76357L15.8163 6.30403L15.3551 6.30469L14.9095 6.30533L14.9088 6.30533L14.9081 6.30533L14.4625 6.30469L13.9205 6.30391L13.9633 6.84421L13.9634 6.84495C13.9734 6.97187 14.1499 9.19771 15.4895 11.7863L15.4896 11.7864C16.2723 13.2984 17.2948 14.6629 18.5283 15.8407L18.5283 15.8407C19.5392 16.8059 20.6921 17.6448 21.9705 18.3492C20.6921 19.0538 19.5391 19.893 18.5278 20.8581L18.5278 20.8582C17.2943 22.036 16.2718 23.4005 15.489 24.9125L15.489 24.9126C14.1468 27.506 13.972 29.7356 13.9631 29.8577Z" stroke="#3C746D"/>
    </svg>
    `],
    autoplay: false,
    responsive : {
        0 : {
          items: 1,
        },
        480 : {
          items: 1,
        },
        768 : {
          items: 3,
        },
        992 : {
          items: 4,
        }
    }
  };

  const options2 = {
    loop: true,
    items: 3,
    dots: false,
    nav:true,
    navText:[`<svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z" fill="#3C746D"/>
    <path d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z" stroke="#ACD9D0"/>
    <path d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z" stroke="#3C746D"/>
    <path d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z" fill="#3C746D"/>
    <path d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z" stroke="#ACD9D0"/>
    <path d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z" stroke="#3C746D"/>
    </svg>
    `,`<svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9173 19.6484H22.4173V19.1484V17.6484V17.1484H21.9173H1.35359H0.853586V17.6484V19.1484V19.6484H1.35359H21.9173Z" fill="#3C746D"/>
    <path d="M21.9173 19.6484H22.4173V19.1484V17.6484V17.1484H21.9173H1.35359H0.853586V17.6484V19.1484V19.6484H1.35359H21.9173Z" stroke="#ACD9D0"/>
    <path d="M21.9173 19.6484H22.4173V19.1484V17.6484V17.1484H21.9173H1.35359H0.853586V17.6484V19.1484V19.6484H1.35359H21.9173Z" stroke="#3C746D"/>
    <path d="M13.9631 29.8577L13.9238 30.3946L14.4621 30.3942L15.3567 30.3936L15.8169 30.3933L15.8547 29.9346C15.8564 29.9149 16.0167 28.0946 17.1646 25.9007L17.1646 25.9007C18.2302 23.8637 20.4015 21.0565 24.7011 19.3307L24.7011 19.3307L25.9902 18.8131L27.1462 18.349L25.9902 17.8851L24.7006 17.3676C20.4014 15.6417 18.2297 12.8352 17.1641 10.7976L17.1641 10.7976C16.0163 8.60314 15.8559 6.78348 15.8542 6.76368C15.8542 6.76363 15.8542 6.76359 15.8542 6.76357L15.8163 6.30403L15.3551 6.30469L14.9095 6.30533L14.9088 6.30533L14.9081 6.30533L14.4625 6.30469L13.9205 6.30391L13.9633 6.84421L13.9634 6.84495C13.9734 6.97187 14.1499 9.19771 15.4895 11.7863L15.4896 11.7864C16.2723 13.2984 17.2948 14.6629 18.5283 15.8407L18.5283 15.8407C19.5392 16.8059 20.6921 17.6448 21.9705 18.3492C20.6921 19.0538 19.5391 19.893 18.5278 20.8581L18.5278 20.8582C17.2943 22.036 16.2718 23.4005 15.489 24.9125L15.489 24.9126C14.1468 27.506 13.972 29.7356 13.9631 29.8577Z" fill="#3C746D"/>
    <path d="M13.9631 29.8577L13.9238 30.3946L14.4621 30.3942L15.3567 30.3936L15.8169 30.3933L15.8547 29.9346C15.8564 29.9149 16.0167 28.0946 17.1646 25.9007L17.1646 25.9007C18.2302 23.8637 20.4015 21.0565 24.7011 19.3307L24.7011 19.3307L25.9902 18.8131L27.1462 18.349L25.9902 17.8851L24.7006 17.3676C20.4014 15.6417 18.2297 12.8352 17.1641 10.7976L17.1641 10.7976C16.0163 8.60314 15.8559 6.78348 15.8542 6.76368C15.8542 6.76363 15.8542 6.76359 15.8542 6.76357L15.8163 6.30403L15.3551 6.30469L14.9095 6.30533L14.9088 6.30533L14.9081 6.30533L14.4625 6.30469L13.9205 6.30391L13.9633 6.84421L13.9634 6.84495C13.9734 6.97187 14.1499 9.19771 15.4895 11.7863L15.4896 11.7864C16.2723 13.2984 17.2948 14.6629 18.5283 15.8407L18.5283 15.8407C19.5392 16.8059 20.6921 17.6448 21.9705 18.3492C20.6921 19.0538 19.5391 19.893 18.5278 20.8581L18.5278 20.8582C17.2943 22.036 16.2718 23.4005 15.489 24.9125L15.489 24.9126C14.1468 27.506 13.972 29.7356 13.9631 29.8577Z" stroke="#ACD9D0"/>
    <path d="M13.9631 29.8577L13.9238 30.3946L14.4621 30.3942L15.3567 30.3936L15.8169 30.3933L15.8547 29.9346C15.8564 29.9149 16.0167 28.0946 17.1646 25.9007L17.1646 25.9007C18.2302 23.8637 20.4015 21.0565 24.7011 19.3307L24.7011 19.3307L25.9902 18.8131L27.1462 18.349L25.9902 17.8851L24.7006 17.3676C20.4014 15.6417 18.2297 12.8352 17.1641 10.7976L17.1641 10.7976C16.0163 8.60314 15.8559 6.78348 15.8542 6.76368C15.8542 6.76363 15.8542 6.76359 15.8542 6.76357L15.8163 6.30403L15.3551 6.30469L14.9095 6.30533L14.9088 6.30533L14.9081 6.30533L14.4625 6.30469L13.9205 6.30391L13.9633 6.84421L13.9634 6.84495C13.9734 6.97187 14.1499 9.19771 15.4895 11.7863L15.4896 11.7864C16.2723 13.2984 17.2948 14.6629 18.5283 15.8407L18.5283 15.8407C19.5392 16.8059 20.6921 17.6448 21.9705 18.3492C20.6921 19.0538 19.5391 19.893 18.5278 20.8581L18.5278 20.8582C17.2943 22.036 16.2718 23.4005 15.489 24.9125L15.489 24.9126C14.1468 27.506 13.972 29.7356 13.9631 29.8577Z" stroke="#3C746D"/>
    </svg>
    `],
    autoplay: false,
    responsive : {
        0 : {
          items: 1,
        },
        480 : {
          items: 1,
        },
        768 : {
          items: 3,
        },
        992 : {
          items: 3,
        }
    }
  };
  return (
    <>
      <section className={cx.heroSection}>
        <Container>
          <Row>
            <Col lg={6} md={12}>
              <div className={`${cx.left}`}>
                <div className={`${cx.top}`}>
                  <h2 className={`${cx.heading}`}>
                    Balance Massage & Wellness
                  </h2>
                  <p className={`${cx.shortDesc}`}>
                    <span>Closed: </span>opens at 9:00am
                  </p>
                  <div className={`${st.formBox} ${cx.search}`}>
                    <img
                      src={locationGreen}
                      className={cx.locationIcon}
                      alt="locationIcon"
                    />
                    <input
                      type="search"
                      className={`${cx.searchInput} form-control`}
                      placeholder="Select location"
                    />
                    <img
                      src={rightArrowIcon}
                      className={cx.rightArrowIcon}
                      alt="rightArrow"
                    />
                  </div>
                  <div className={`${cx.rating}`}>
                    <img src={star} alt="star" />
                    <p>
                      <span className={cx.rate}>5.0</span> (673 reviews)
                    </p>
                  </div>
                </div>

                <div className={`${cx.description}`}>
                  <p className={`${cx.desc}`}>
                    Our beauty specialists offer a wide range of services, from
                    glamorous hairstyles and makeup to spa treatments that can
                    be performed both at yoProduct and at their place
                  </p>

                  <p className={cx.favourite}>
                    <img src={favourite} alt="favourite-icon" />{" "}
                    <span> Add to Favourites</span>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className={`${cx.right}`}>
                <div className="custom-slider-container">
                  <Carousel>
                    {images.map((imageUrl, index) => (
                      <div key={index} className={`${cx.sliderImage}`}>
                        <img src={imageUrl} alt={`Slide ${index}`} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={cx.tabbingSection}>
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabbingN1">
            <div className={cx.webTab}>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabbingN1">Services & Prices</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabbingN2">About Salon</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabbingN3">Our Team</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabbingN4">Reviews</Nav.Link>
              </Nav.Item>
            </Nav>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="tabbingN1">
                <Row className="mt-5">
                  <Col md={8}>
                    <div className={`${st.pageWrapper} ${cx.webTabBody}`}>
                    <div className={`${st.tabbingBox}`}>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="tabNew1"
                      >
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="tabNew1">
                              <img
                                src={hair}
                                alt="hair"
                                className={`${st.defalut}`}
                              />
                              <img
                                src={hairdresser}
                                alt="hairdresser"
                                className={`${st.active}`}
                              />
                              Hair
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tabNew2">
                              <img
                                src={waxfill}
                                alt="wax"
                                className={`${st.defalut}`}
                              />
                              <img
                                src={wax}
                                alt="wax"
                                className={`${st.active}`}
                              />
                              Wax
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="tabNew1">
                            <div className={`${cx.alertBox}`}>
                              <h5>Hair Cut </h5>
                              <p>A Salon root colour using permanent colour. It creates shine, is suitable for any hair type & white hair coverage.</p>
                              <span className={`${cx.usd}`}>USD 100</span>
                              <img src={tik2} alt="tik2" className={`${cx.tik2}`} />
                              <span className={`${cx.time}`}>35 mins</span>
                            </div>

                            
                            <div className={`${cx.serviceBox}`}>
                              <div className={`${cx.serviceBoxBody}`}>
                                <h5>Massage</h5>
                                <p>A Salon root colour using permanent colour. It creates shine, is suitable for any hair type & white hair coverage.</p>
                                <span className={`${cx.usd}`}>Starting From <b>USD 300</b></span>
                                <img src={tik2} alt="tik2" className={`${cx.tik2}`} />
                              </div>
                              <hr/>
                              <div className={`${cx.serviceCard}`}>
                                 <h5>Services</h5>
                                 <ul>
                                  <li>
                                    <label className={`${st.radio}`}>
                                      <input
                                        type="radio"
                                        name="serviceAvailabe"
                                      />
                                      <span
                                        className={`${st.checkmark}`}
                                      ></span>
                                      <div className={`${cx.nameText}`}>Normal Massage</div>
                                      <div className={`${cx.rightList}`}>
                                        <div className={`${cx.list1}`}>1 h 30 mins</div>
                                        <div className={`${cx.list2}`}>USD 300</div>
                                      </div>
                                    </label>
                                  </li>
                                  <li>
                                    <label className={`${st.radio}`}>
                                      <input
                                        type="radio"
                                        name="serviceAvailabe"
                                      />
                                      <span
                                        className={`${st.checkmark}`}
                                      ></span>
                                      <div className={`${cx.nameText}`}>Pressure point Massage</div>
                                      <div className={`${cx.rightList}`}>
                                        <div className={`${cx.list1}`}>2 h 30 mins</div>
                                        <div className={`${cx.list2}`}>USD 300</div>
                                      </div>
                                    </label>
                                  </li>
                                  <li>
                                    <label className={`${st.radio}`}>
                                      <input
                                        type="radio"
                                        name="serviceAvailabe"
                                      />
                                      <span
                                        className={`${st.checkmark}`}
                                      ></span>
                                      <div className={`${cx.nameText}`}>Deep Tissue Massage</div>
                                      <div className={`${cx.rightList}`}>
                                        <div className={`${cx.list1}`}>1 h 30 mins</div>
                                        <div className={`${cx.list2}`}>USD 300</div>
                                      </div>
                                    </label>
                                  </li>
                                 </ul>
                              </div>
                              <hr/>
                              
                              <div className={`${cx.stylistBox}`}>
                                 <h5>Select Stylist
                                  <span>Oksana Lavrentivna</span>
                                 </h5>
                                 <Row>
                                  <Col md={3}>
                                    <button className={`${cx.stylistCard}`}>
                                      <img src={profile} alt="profile" className={`${cx.profile}`} />
                                      <h6>Oksana Dydka</h6>
                                    </button>
                                  </Col>
                                  <Col md={3}>
                                    <button className={`${cx.stylistCard}`}>
                                      <img src={profile} alt="profile" className={`${cx.profile}`} />
                                      <h6>Oksana Dydka</h6>
                                    </button>
                                  </Col>
                                  <Col md={3}>
                                    <button className={`${cx.stylistCard}`}>
                                      <img src={profile} alt="profile" className={`${cx.profile}`} />
                                      <h6>Oksana Dydka</h6>
                                    </button>
                                  </Col>
                                  <Col md={3}>
                                    <button className={`${cx.stylistCard}`}>
                                      <img src={profile} alt="profile" className={`${cx.profile}`} />
                                      <h6>Oksana Dydka</h6>
                                    </button>
                                  </Col>
                                 </Row>
                              </div>

                              
                            <div className={`${cx.alertBox} mt-4`}>
                              <h5>Hair Cut </h5>
                              <p>A Salon root colour using permanent colour. It creates shine, is suitable for any hair type & white hair coverage.</p>
                              <span className={`${cx.usd}`}>USD 100</span>
                              <img src={tik2} alt="tik2" className={`${cx.tik2}`} />
                              <span className={`${cx.time}`}>35 mins</span>
                            </div>

                            </div>

                            
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${cx.bookingCard}`}>
                      <div className={`${cx.bookingCardBody}`}>
                        <span>west-coast, Singapore </span>
                        <h4>Balance Massage & Wellness</h4>
                        <b>1 m away</b>
                        <NavLink className={`btn ${st.bookingBtn}`} to="#">
                          Book Now
                        </NavLink>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="tabbingN2">Second tab content</Tab.Pane>
              <Tab.Pane eventKey="tabbingN3">Second tab content</Tab.Pane>
              <Tab.Pane eventKey="tabbingN4">Second tab content</Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </section>

      <section className={`${cx.dealsMonthSection}`}>
          <Container>
            <Col md={12} className={cx.titleBox}>
              <h2>Meet our Amazing team</h2>
            </Col>
            <OwlCarousel className={`owl-theme ${st.sliderRow}`} {...options}>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
          </OwlCarousel>
          </Container>
        </section>

        <section className={`${cx.teamSection}`}>
          <Container>
            <Col md={12} className={cx.titleBox}>
              <h2>Meet our Amazing team</h2>
            </Col>
            <Row>
              <Col md={4}>
                <div className={cx.teamBox}>
                  <img src={profile} alt="profile" />
                  <div className={cx.teamBoxBody}>
                    <h5>Oksana Lavrentivna</h5>
                    <p>General Manager, Stylist</p>
                    <div className={cx.review}>
                    <BsStarFill className={`${cx.icon}`}/> <b>5.0</b> (673 reviews)
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className={cx.teamBox}>
                  <img src={profile} alt="profile" />
                  <div className={cx.teamBoxBody}>
                    <h5>Oksana Lavrentivna</h5>
                    <p>General Manager, Stylist</p>
                    <div className={cx.review}>
                    <BsStarFill className={`${cx.icon}`}/> <b>5.0</b> (673 reviews)
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className={cx.teamBox}>
                  <img src={profile} alt="profile" />
                  <div className={cx.teamBoxBody}>
                    <h5>Oksana Lavrentivna</h5>
                    <p>General Manager, Stylist</p>
                    <div className={cx.review}>
                    <BsStarFill className={`${cx.icon}`}/> <b>5.0</b> (673 reviews)
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className={cx.teamBox}>
                  <img src={profile} alt="profile" />
                  <div className={cx.teamBoxBody}>
                    <h5>Oksana Lavrentivna</h5>
                    <p>General Manager, Stylist</p>
                    <div className={cx.review}>
                    <BsStarFill className={`${cx.icon}`}/> <b>5.0</b> (673 reviews)
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className={cx.teamBox}>
                  <img src={profile} alt="profile" />
                  <div className={cx.teamBoxBody}>
                    <h5>Oksana Lavrentivna</h5>
                    <p>General Manager, Stylist</p>
                    <div className={cx.review}>
                    <BsStarFill className={`${cx.icon}`}/> <b>5.0</b> (673 reviews)
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        
      <section className={`${cx.testimonialSection}`}>
          <Container>
            <Col md={12} className={cx.titleBox}>
              <h2 className="mb-2">Customer’s Reviews </h2>
              <p>More then 300 users on 12 countries</p>
            </Col>
            <OwlCarousel className={`owl-theme ${st.sliderRow}`} {...options2}>
              <div className='item'><Testimonials /></div>
              <div className='item'><Testimonials /></div>
              <div className='item'><Testimonials /></div>
              <div className='item'><Testimonials /></div>
              <div className='item'><Testimonials /></div>
          </OwlCarousel>
          </Container>
        </section>
      
      <section className={`${cx.dealsMonthSection} pb-0`}>
          <Container>
            <Col md={12} className={cx.titleBox}>
              <h2>You may also like</h2>
            </Col>
            <OwlCarousel className={`owl-theme ${st.sliderRow}`} {...options}>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
          </OwlCarousel>
          </Container>
        </section>

        
        <section className={cx.dealsMonthSection}>
          <Container>
            <Col md={12} className={cx.titleBox}>
              <h2>Other customers also checked</h2>
            </Col>
            <OwlCarousel className={`owl-theme ${st.sliderRow}`} {...options}>
              <div className='item'><Deals /></div>
              <div className='item'><Deals /></div>
              <div className='item'><Deals /></div>
              <div className='item'><Deals /></div>
              <div className='item'><Deals /></div>
          </OwlCarousel>
          </Container>
        </section>
    </>
  );
};

export default Salon;
