import React, { useState } from "react";
import Select from "react-select";
import st from "../../../assets/stylesheet/style.module.scss";

interface Option {
  value: string;
  label: string;
}
interface MultiSelect2 {
  onChange?: void | any;
  value?: Option[] | any;
  options?: Option[] | any;
  defaultVal?: {};
  list?: any;
  disabled?: any;
}

const MultiSelect2: React.FC<MultiSelect2> = ({
  onChange,
  value,
  list,
  defaultVal,
  disabled = false,
}) => {
  

  
  const option = [
    { value: "option", label: "option" },
    { value: "option", label: "option" },
    { value: "option", label: "option" },
    { value: "option", label: "option" },
    { value: "option", label: "option" },
    { value: "option", label: "option" },
  ];
  const [isMenuOpen, setIsMenuOpen] = useState(true);


  return (
    <>
      {/* <div className={`${st.formBox}`}> */}
        <Select
          options={list}
          closeMenuOnSelect={false}
          value={value}
          isMulti
          onChange={onChange}
          placeholder="---Select---"
          isDisabled={disabled}
          // menuIsOpen={isMenuOpen}
        />
        {/* <div>
        {selectedOptions.map((option) => (
          <span key={option.value}>{option.label}, </span>
        ))}
      </div> */}
      {/* </div> */}
    </>
  );
};

export default MultiSelect2;
