import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { useForm, Controller } from "react-hook-form";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import {
  cross,
  deleteBtn,
  deleteCross,
} from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { useSelector } from "react-redux";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import MultiSelect from "../../../components/Business/MultiSelect/MultiSelect";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

export default function ServiceTab(props: any) {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const { editTeamData, businessData, loading, handleStepsFormData } = props;

  const [catData, setCatData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [selectedServices, setSelectedServices] = useState<any>([]);
  const [showErrorModal, setShowErrorModal] = useState('');
  const handleAlertModalClose = () => setShowErrorModal('');
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
  const [triggered, setTriggered] = useState(false);
  const [selectedCategoriesId, setSelectedCategoriesId] = useState<any>([])
  const [selectedServicesList, setSelectedServicesList] = useState<any>([])
  const [customErr, setCustomErr] = useState<any>('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
    control,
  } = useForm();

  let serviceCategory = getValues('serviceCategory')

  // useEffect(() => {
  //   if (editTeamData) {
  //     reset();
  //     let selectedArr = editTeamData.serviceId?.map((item: any) => item._id)
  //     setSelectedServices(selectedArr);
  //   }
  // }, [editTeamData]);

  useEffect(() => {
    setSelectedServicesList(editTeamData?.serviceId);
    const existCat = catData?.filter((item: any) => editTeamData?.serviceId?.some((serviceItem: any) => serviceItem?.serviceCatagoryId?._id === item?._id))
    setSelectedCategoriesId(existCat?.map((catItem: any) => ({ value: catItem?._id, label: catItem?.serviceCategoryName })))
  }, [editTeamData, catData, serviceList]);

  console.log(editTeamData , "editTeamData");
  

  useEffect(() => {
    getService()
  }, [branchId])
  // const getCategory = async () => {
  //   const res = await httpRequest(`getServiceCatagory?businessId=${businessData._id}`, "get", null, "json");
  //   if (res.status) {
  //     setCatData(res?.data);
  //   }
  // }

  const getService = async () => {
    const res = await httpRequest(`getBusinessService?branchId=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setServiceList(res?.data);
      let serviceCategory = res?.data?.map((item: any) => {
        return (item?.ServiceCategory)
      })
      const uniqueArray = removeDuplicateIds(serviceCategory);
      setCatData(uniqueArray);
      if (res?.data < 1) {
        handleAlertModalShow("Error, Please maintain services for this branch ")
      }
    }
  };

  // function handleFormSubmit() {
  //   let selectedCats: any = [];
  //   selectedArray.forEach((elem: any) => {
  //     selectedCats = [...selectedCats, ...elem.selectedServiceList];
  //   });
  //   let servObj = {
  //     serviceId: JSON.stringify(selectedCats)
  //   };
  //   handleStepsFormData(servObj, 'step3');
  // }
  // const submitHandler = (e: any) => {
  //   if (serviceList?.length === 0) {
  //     handleStepsFormData("", 'step3');

  //     skipStep(e)
  //   } else {
  //     let servObj = {
  //       serviceId: JSON.stringify(selectedServices)
  //     };
  //     handleStepsFormData(servObj, 'step3');
  //   }
  // }

  function handleSelectService(e: any) {
    let selectedArr = [...selectedServices];
    selectedArr.push(e.target.value);
    setSelectedServices(selectedArr);
    setCustomErr('')
  }

  // function handleRemoveService(id: string) {
  //   let filteredArr: any = selectedServices.filter((item: any) => item !== id);
  //   setSelectedServices(filteredArr);
  // }

  // const [selectedArray, setSelectedArray] = useState<any>([]);
  // function handleAddAnotherService(data: any) {

  //   let catName: any = catData.find((item: any) => item._id === data.serviceCategory);
  //   setSelectedArray((prev: any) => [
  //     ...prev,
  //     {
  //       serviceCategory: data.serviceCategory,
  //       categoryName: catName.serviceCategoryName,
  //       selectedServiceList: selectedServices,
  //     }
  //   ]);
  //   reset();
  //   setSelectedServices([]);
  // }

  const skipStep = (e: any) => {
    e.preventDefault();
    handleStepsFormData("prevStep", "leaves")
  }
  // function handleAnotherRemove(id: string) {
  //   let newSelected: any = [...selectedArray];
  //   newSelected.splice(id, 1);
  //   setSelectedArray(newSelected);
  // }

  let selectedServiceList = serviceList?.filter((item: any) => selectedServices.includes(item?._id));
  let filterServiceList = serviceList?.filter((item: any) => {
    let selectedSrvCat = watch('serviceCategory', '');
    return (item?.ServiceCategory?._id === selectedSrvCat && !selectedServices.includes(item?._id));
  });

  console.log(serviceList , "serviceList");
  


  function removeDuplicateIds(arr: any) {
    // Create an object to store unique IDs
    const uniqueIds: any = {};
    const uniqueArray: any = [];
    for (const obj of arr) {
      // Check if the ID of the object is already in the uniqueIds object
      if (!uniqueIds[obj._id]) {
        // If not, add the ID to the uniqueIds object and push the object to the new array
        uniqueIds[obj._id] = true;
        uniqueArray.push(obj);
      }
    }
    return uniqueArray;
  }

  /// latest 
  const filterCat = catData?.filter((item: any) => serviceList?.some((serviceItem: any) => serviceItem?.ServiceCategory?._id === item?._id))

  const selectHandler = (data: any) => {
    console.log(data,serviceList, "serviceList");
    
    setSelectedCategoriesId(data);
    const filterServices = serviceList?.filter((serviceItem: any) => data?.some((catData: any) => catData?.value === serviceItem?.ServiceCategory?._id))
    setSelectedServicesList(filterServices);
    if (data?.length === 0) {
      setTriggered(true)
    } else {
      setTriggered(false)

    }
  }

  console.log(selectedServicesList, "selectedServiceList");
  
  function handleRemoveService(id: string) {
    let filteredArr: any = selectedCategoriesId?.filter((item: any) => item?.value !== id);

    let filteredServices: any = selectedServicesList?.filter((item: any) => item?.serviceCatagoryId?._id !== id);
        
    setSelectedServicesList(filteredServices);
    setSelectedCategoriesId(filteredArr)
    if (filteredArr?.length === 0) {
      setTriggered(true)
    } else {
      setTriggered(false)

    }
  }

  const submitHandler = () => {
    // if (selectedCategoriesId?.length === 0) {
    //   setTriggered(true)
    //   return
    // }

    let servObj = {
      // serviceCategoryId: data?.serviceCategory,
      serviceId: JSON.stringify(selectedServicesList?.map((item: any) => item?._id))
    };
    
    handleStepsFormData(servObj, 'step3');
  }

  
  return (
    <>
      <Row>
      <Col md={12}>
        <div className="text-end">
          <HelpButon number={12} type={"help"} className={"btn"} />
        </div>
      </Col>
        <Col md={12}>
          <div className={`${st.formBox} ${cx.locationSelect}`}>
            <h2>Services Member Can Offer</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={3} md={6}>
          <div className={`${st.formBox}`}>
            <label className="form-label">Service Category</label>
            <MultiSelect
           
              value={selectedCategoriesId}
              onChange={selectHandler}
              list={filterCat?.map((catItem: any) => ({ label: catItem?.serviceCategoryName, value: catItem?._id }))}
            />
            {/* <select className="form-select"  {...register("serviceCategory", {
              required: true,

            })}>
              <option value="" selected>--Select--</option>
              {catData?.map((cat: any) => {
                return (
                  <option value={cat?._id} key={cat?._id}>{cat?.serviceCategoryName}</option>
                )
              })}
            </select> */}
            {/* {triggered && selectedCategoriesId?.length === 0 && (
              <p className={"errorMessage"}>This field is required</p>
            )} */}

          </div>
        </Col>
        
        {/* <Col lg={3} md={6}>
          <div className={`${st.formBox}`}>
            <label className="form-label">Service Category</label>
            <select className="form-select" {...register("serviceCategory", {
              required: false,
            })}>
              <option value="" selected>--Select--</option>
              {catData?.map((cat: any) => {
                return (
                  <option value={cat?._id} key={cat?._id}>{cat?.serviceCategoryName}</option>
                )
              })}
            </select>
            {errors?.serviceCategory?.type === "required" && (
              <p className={"errorMessage"}>This field is required</p>
            )}
          </div>
        </Col> */}

        {/* <Col lg={3} md={6}>
          <div className={`${st.formBox}`}>
            <label className="form-label">Services</label>
            {serviceCategory !== undefined ? (
              <select
                className="form-select"
                onChange={handleSelectService}
                value={''}>
                <option value="" selected>--Select--</option>
                {filterServiceList?.map((service: any, index: number) => {
                  return (
                    <option value={service._id} key={index}>{service?.serviceName}</option>
                  )
                })}
              </select>
            ) : (
              <><select
                className="form-select"
                onChange={handleSelectService}
                value={''}>
                <option value="" selected>--Select--</option>
                
              </select>
              </>
            )}
            {customErr.includes('selectService') && (
              <p className={"errorMessage"}>Select atleast one service</p>
            )}
          </div>
        </Col>
        <Col lg={6}></Col> */}
      </Row>
      <Row>
        <Col md={12}>
          <div className={cx.serviceBox}>
            {selectedCategoriesId?.map((service: any, i: any) => {

              return (

                <article className={cx.teamBox}>
                  <div className={cx.left}>
                    <span>{service?.label?.slice(0, 2)?.toLocaleUpperCase()}</span>
                  </div>
                  <div className={`${cx.right}`}>
                    <h2 className={cx.memberName}><span className={cx.head}>{service?.label}</span></h2>
                  </div>
                  <img
                    src={deleteCross}
                    alt="delete-icon"
                    onClick={() => handleRemoveService(service?.value)}
                    style={{
                      height: '30px',
                      width: '30px',
                      marginTop: '7px'
                    }}
                  />
                </article>
              )
            })}
          </div>
        </Col>

        {/* {selectedServiceList?.map((service: any, i: any) => {
          return (
            <Col lg={2} md={4} key={i}>
              <div className={cx.service}>
                <span>{service?.serviceCatagoryId?.serviceCategoryName}</span>
                <span>{service?.serviceName}</span>
                <img src={cross} alt="delete-icon" onClick={() => handleRemoveService(service._id)} />
              </div>
            </Col>
          )
        })} */}
      </Row>

      {/* {selectedArray?.map((item: any, i: any) => {
        return (
          <Row key={i} className="mt-3">
            <Col lg={3} md={6}>
              <div className={`${st.formBox}`}>
                <select className="form-select" disabled>
                  <option value="" selected>{item?.categoryName}</option>
                </select>
              </div>
            </Col>
            {serviceList?.map((service: any, index: any) => {
              if (item.selectedServiceList.includes(service?._id)) {
                return (
                  <Col lg={2} md={4} key={index}>
                    <div className={`${cx.service} mt-1`}>
                      <span>{service?.serviceName}</span>
                    </div>
                  </Col>
                )
              }
            })}
            <Col md={3}>
              <img
                src={deleteBtn}
                alt="delete-icon"
                style={{
                  height: '30px',
                  width: '30px',
                  marginTop: '7px'
                }}
                onClick={() => handleAnotherRemove(i)}
              />
            </Col>
          </Row>
        )
      })} */}

      <Row>
        <Col md={12}>
          <div className={`${st.formBox}`}>
            <div className={`${st.btnsGroup}`}>
              <button
                className={`btn ${st.btn1}`}
                onClick={() => handleStepsFormData('prevStep', 'branch')}
              >
                Back
              </button>

              <button type='submit' onClick={skipStep} className={`btn ${st.btn1} ${cx.twoBtn}`} >
                Skip
              </button>

              <button className={`btn ${st.btn4} ${cx.btn2Clr}`} disabled={loading} onClick={submitHandler}>

                {loading ? <LoadingBtn /> : "Next"}
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <ErrorPopup
        showErrorModal={showErrorModal}
        handleErrorModalClose={handleAlertModalClose}
      />
    </>
  );
}
