import React, { useEffect, useState } from 'react'
import { deleteIcon } from '../../../assets/images'
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';

export default function BundleDesc({ styles, bundle, index, addServiceHandler,setSelectedServices, deleteBundleHandler, selectedClient, formattedDate, formatStartTime, selectedServices }: any) {
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    )
    const [promotionList, setPromotionList] = useState<any>([])

    const getPromotion = async () => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (businessData) {
            let res = await httpRequest(`getPromotion?branchId=${branchId}&businessId=${businessData?._id}&timeZone=${userTimeZone}&bookingTime=${formatStartTime?.replaceAll(" ", "")}&bookingDate=${formattedDate}&clientId=${selectedClient?.[0]?._id}`, "get", null, "json");
            if (res.status) {
                setPromotionList(res?.data?.filter((promo: any) => promo?.type === "bundle"));
            }
        }
    };


    let promo: any = [];
    promotionList?.forEach(((pro: any) => {
        const propmotionInBundleCheck = pro?.type === "bundle" ? pro?.bundleId?.some((item: any) => item?.id?._id === bundle?._id) : false;
        if (!propmotionInBundleCheck) {
            return
        }


        let radeemedCount = +pro?.redeemCount;
        let clientUses = +pro?.clientUses;
        selectedServices?.forEach((serviceItem: any) => {
            if (serviceItem?.bundlePromotionId === pro?._id && serviceItem?.bundleId !== bundle?._id) {
                radeemedCount += 1
                clientUses += 1
            }
        });

        const checkMaxUserCritiria: any = !pro?.LimitNumberOfUses?.active ? true : radeemedCount < pro?.LimitNumberOfUses?.maxUser;
        const isclientInLimit: any = !pro?.LimitUsePerClient ? true : clientUses < pro?.LimitUsePerClient
        if (!isclientInLimit) {
            return
        }

        if (!checkMaxUserCritiria) {
            return
        }
        promo.push(pro)
    }))
    const existBundle = selectedServices?.find((item: any) => item?.bundleId === bundle?._id);

    useEffect(() => {
        if (promo?.length === 0) {
            if (existBundle && existBundle?.bundlePromotionId && !promo?.some((item: any) => existBundle?.bundlePromotionId === item?._id)) {
                setSelectedServices((prev: any) => {
                    let updatedService = [...prev];
                    let existService = updatedService.find((item: any) => item?.bundleId === bundle?._id)

                    if (existService) {
                        existService.bundlePromotionId = null
                        existService.bundlePromotionalPrice = ""
                    }
                    return updatedService
                })
            }
        }
    }, [promo])



    let selectedPromotionBundlePrice: any = ""
    if (existBundle?.bundlePromotionId) {
        const promotionObj = promo?.find((item: any) => item?._id === existBundle?.bundlePromotionId);
        if (promotionObj) {
            const bundleObj = promotionObj?.bundleId?.find((item: any) => item?.id?._id === bundle?._id);
            selectedPromotionBundlePrice = bundleObj?.promotionalPrice || ""
        }
    }

    useEffect(() => {
        getPromotion();
    }, [formatStartTime, selectedClient]);


    return (

        <div className={`${styles.bundleScreen}`}>
            <h5>{bundle?.bundleName}</h5>
            {promo?.length > 0 && <div className={styles.bundlePromotion}>
                <select className="form-select" style={{ fontSize: "14px" }} onChange={(e: any) => {
                    const selectedPrmotion = promo?.find((item: any) => item?._id === e.target.value);
                    if (selectedPrmotion) {
                        const bundleWiseObj = selectedPrmotion?.bundleId?.find((item: any) => item?.id?._id === bundle?._id);
                        addServiceHandler("bundle", e.target.value, index, bundle?._id, null, bundleWiseObj?.promotionalPrice)
                    } else {
                        addServiceHandler("bundle", e.target.value, index, bundle?._id, null, "")
                    }
                    return
                }} value={existBundle?.bundlePromotionId}>
                    <option value="">Apply Promotion</option>
                    {promo?.map((pro: any) => {
                        return <option value={pro?._id} key={pro?._id}>{pro?.promotionName}</option>
                    })}
                </select>
            </div>}
            <div className={styles.deleteAction}>
                <span>{`SGD ${selectedPromotionBundlePrice ? selectedPromotionBundlePrice : bundle?.finalPrice}`}</span>
                <img src={deleteIcon} alt="delete" onClick={() => deleteBundleHandler(bundle?._id)} />
            </div>
        </div>
    )
}
