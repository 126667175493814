import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment';
import cx from './index.module.scss';
import st from "../../../assets/stylesheet/style.module.scss";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { clock, clockA, schedularA, schedule, menuList } from '../../../assets/images';
import AppointmentModal from '../../../components/Business/Modals/AppointmentModal';
import CustomarModal from '../../../components/Business/Modals/CustomerModal';
import { getDpName, toTitleCase } from '../../../Utils/Funcs';
import ListViewRebook from '../../../components/Business/Modals/ListViewRebook';
import { useSelector } from 'react-redux';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';
import { FadeLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertTimeToMinute, convertTimetoTotalMinutes, convertToHoursMinutes, formatSlashDate, mergeTimeRanges } from '../../../constants/Constants';
import { httpRequest } from '../../../Apis/commonApis';
import { GoDotFill } from "react-icons/go";
import ErrorPopup from '../../../components/Business/Modals/ErrorPopup';
import { TbReload } from 'react-icons/tb';
import HelpButon from '../../../components/Business/HelpButon.tsx/HelpButton';

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});
const localizer = momentLocalizer(moment);

let fullDayTimeArray = generateTimeArray();
const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const dayStart = new Date(2023, 10, 12, 0, 0)
const dayEnd = new Date(2023, 10, 12, 24, 0);

const MyCalendar = (props: any) => {
    const { getBooking, bookingData, selectedDate, listData, roleObj, handleTeamBooking, handleCreateSlotEvent, handleToolbarChange, branchTeamList, selectedViewDateTeam, loading, handleDateSelector, branchId } = props;
    const { currentBranchData } = useSelector((state: any) => state.BranchReducer);

    let canCreateBooking = true
    let canEditBooking = true

    const [resources, setResources] = useState<any[]>([]);
    const [eventData, setEventData] = useState<any[]>([]);
    const [showAppointment, setShowAppointment] = useState<boolean>(false);
    const [showCustomer, setShowCustomer] = useState(false);
    const [eventBookingData, setEventBookingData] = useState<any>();
    const [viewBookingData, setViewBookingData] = useState<any>();
    const [updateBooking, setupdateBooking] = useState<any[]>([]);
    const [assineData, setAssineData] = useState<any>();
    const [showRebook, setShowRebook] = useState<any>(false);
    const handleAppointmentClose = () => setShowAppointment(false);
    const handleCustomerClose = () => setShowCustomer(false);
    const handleRebookClose = () => setShowRebook(false);

    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const location = useLocation();
    const dateParam = location.state && location.state.date;

    useEffect(() => {
        if (currentBranchData) {
            handleEventsResources();
        }
    }, [bookingData, selectedDate, currentBranchData]);


    function getWeekStartAndEndDates(inputDate: any) {
        const date = new Date(inputDate);
        const dayOfWeek = date.getDay();
        const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when the day is Sunday
        const weekStartDate = new Date(date.setDate(diff));
        const weekEndDate = new Date(weekStartDate);
        weekEndDate.setDate(weekStartDate.getDate() + 6);

        return { weekStartDate, weekEndDate };
    }

    function getDates(startDate: any, endDate: any) {
        let dates = [];
        let currentDate = new Date(startDate);
        endDate = new Date(endDate);

        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    }

    function pushTimeIfInRange(obj: any, date: any) {
        let arr: any = []
        obj?.forEach((item: any) => {
            const fromDate = new Date(item?.fromDate);
            const toDate = new Date(item?.toDate);
            const checkDate = new Date(date);
            // Check if date falls within the range
            if (checkDate >= fromDate && checkDate <= toDate) {
                const timeRange = {
                    from: item?.fromTime,
                    to: item?.toTime
                };
                arr.push(timeRange)
                // return timeRange;
            }
        })
        return arr;
    }

    function splitIntoSlots(from: any, to: any, slotSize: any) {
        const slots = [];
        const fromTime = new Date(`2000-01-01 ${from}`);
        const toTime = new Date(`2000-01-01 ${to}`);


        while (fromTime <= toTime) {
            const slotEndTime = new Date(fromTime);
            slotEndTime.setMinutes(fromTime.getMinutes() + slotSize);
            if (Number(slotEndTime.toTimeString().slice(0, 5).split(":")[1]) % 15 == 0) {
                const endTime = slotEndTime.toTimeString().slice(0, 5)
                slots.push({
                    from: fromTime.toTimeString().slice(0, 5),
                    to: endTime === "00:00" ? "23:59" : endTime
                });
            }
            fromTime.setMinutes(fromTime.getMinutes() + 1);
        }
        return slots;


    };

    function convertToTime(minutes: any) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    }

    function removeConflictingSlots(slots: any, bookings: any) {
        if (slots && bookings) {
            const parsedSlots = slots.map((slot: any) => ({
                start: convertTimeToMinute(slot.from),
                end: convertTimeToMinute(slot.to)
            }));

            const parsedBookings = bookings.map((booking: any) => ({
                start: convertTimeToMinute(booking.startTime),
                end: convertTimeToMinute(booking.startTime) + Number.parseInt(booking?.serviceId?.duration)
            }));

            const updatedSlots = [];

            for (const slot of parsedSlots) {
                let conflicts = parsedBookings.filter((booking: any) =>
                    (booking.start < slot.end && booking.start >= slot.start) ||
                    (booking.end > slot.start && booking.end <= slot.end) ||
                    (booking.start <= slot.start && booking.end >= slot.end)
                );


                if (conflicts.length === 0) {
                    updatedSlots.push(slot);
                } else {
                    conflicts.sort((a: any, b: any) => a?.start - b?.start);

                    let currentStart = slot.start;

                    for (const conflict of conflicts) {
                        if (currentStart < conflict.start) {
                            updatedSlots.push({ start: currentStart, end: conflict.start });
                        }
                        currentStart = Math.max(currentStart, conflict.end);
                    }

                    if (currentStart < slot.end) {
                        updatedSlots.push({ start: currentStart, end: slot?.end });
                    }
                }
            }

            return updatedSlots.map(slot => ({
                from: convertToTime(slot?.start),
                to: convertToTime(slot?.end)
            }));
        }

    }

    function notAvailableBoxes(hoursDetail: any, fullDayName: any, branchOpHrs: any, item: any, notWorkingBranch: any, eventDataArr: any, timeArray = [], type = 'day') {
        // repeated point

    }

    function handleEventsResources() {
        let newresources: any = []
        let eventDataArr: any = [];

        const dayOfWeek = selectedDate.getDay();
        const fullDayName = dayNames[dayOfWeek];
        let branchOpHrs = currentBranchData?.workingHours?.find((item: any) => item?.dayName === fullDayName);
        const year = selectedDate?.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
        const day = String(selectedDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const formattedToday = formattedDate ? new Date(formattedDate) : new Date();

        const notWorkingBranch: any = [];
        currentBranchData?.notWorkingHours?.forEach((item: any) => {
            const from = new Date(item?.fromDate)
            const to = new Date(item?.toDate)
            if (+formattedToday >= +from && +formattedToday <= +to) {
                notWorkingBranch.push({ from: item?.from, to: item?.to })
            }
        })


        let branchStart = branchOpHrs?.from !== "Closed" ? branchOpHrs?.from?.split(':') : ["00", "00"]
        let branchEnd = branchOpHrs?.to !== "Closed" ? branchOpHrs?.to?.split(':') : ["00", "00"]
        if (!branchOpHrs) {
            setResources(newresources);
            setEventData(eventDataArr);
            return;
        }
        if (branchStart) {
            // dayStart.setHours(branchStart[0], branchStart[1], 0);
            dayStart.setHours(branchStart[0], 0, 0);
            dayEnd.setHours(branchEnd[0], branchEnd[1], 0);
        }

        let allUnBooking: any = []


        if (selectedViewDateTeam?.view === 'day') {
            if (!selectedViewDateTeam.team && (!roleObj?.userData || roleObj?.canViewAllBooking)) {
                let resourceObj = {
                    id: `0`,
                    title: "Total Available Slots",
                    teamTitle: "",
                    availablity: "",
                    image: "",
                    type: "allTeamAvailblityView",
                    teamId: selectedViewDateTeam?.team
                }

                if (!roleObj?.userData || roleObj?.canCreateAllBooking) {
                    newresources.push(resourceObj);
                }
                const Upcoming = {
                    id: 1,
                    title: "Unassigned appointments",
                    isUnassign: true,
                    teamId: selectedViewDateTeam?.team
                    // availablity: `(${bookingData?.unAssigned?.length} NEW)`,
                }
                newresources.push(Upcoming);
            }

            let filterUnassignBooking: any = [];

            bookingData?.unAssigned?.forEach((unAssignBooking: any) => {
                const serviceCount = unAssignBooking?.services?.reduce((acc: any, item: any) => {
                    const serviceId = item?.serviceId?._id;
                    const bundleId = item?.bundleId?._id; // maybe null or undefined
                    const startTime = item?.startTime
                    if (serviceId) {
                        const key = bundleId ? `${serviceId}-${bundleId}` : serviceId;

                        if (!acc[key]) {
                            acc[key] = { count: 0, serviceId, bundleId, startTime };
                        }
                        acc[key].count += 1;
                    }

                    return acc;
                }, {});

                // Convert the object to an array if needed
                const serviceCountArray = Object.keys(serviceCount).map(key => ({
                    serviceId: serviceCount?.[key]?.serviceId,
                    bundleId: serviceCount?.[key]?.bundleId,
                    count: serviceCount?.[key]?.count,
                    startTime: serviceCount?.[key]?.startTime
                }));

                if (unAssignBooking?.bookingStatus !== "Cancelled" && unAssignBooking?.bookingStatus !== "Client Cancelled") {
                    let filterServices: any = unAssignBooking?.services?.filter((item: any) => {
                        return item?.TeamMemberId?.length === 0
                    })

                    const arrayWithSameTime = serviceCountArray?.filter((item: any) => item?.count > 1);
                    filterServices?.forEach((service: any, i: number, arr: any) => {
                        const currentEndTime = convertTimeToMinute(service?.startTime)
                        const nextStartTime = arr?.[i + 1] ? convertTimeToMinute(arr?.[i + 1]?.startTime) + Number.parseInt(arr?.[i + 1]?.serviceId?.duration) : 0
                        if (currentEndTime !== nextStartTime) {
                            filterServices = filterServices?.filter((unassign: any) => unassign?._id !== service?._id)
                            filterUnassignBooking.push({ ...unAssignBooking, salectTime: service?.startTime, services: [service] });
                        }
                    })



                    if (arrayWithSameTime?.length > 0) {
                        arrayWithSameTime?.forEach((serviceWithCount: any) => {
                            for (let i = 0; i < serviceWithCount?.count; i++) {
                                filterUnassignBooking.push({ ...unAssignBooking, services: filterServices, salectTime: filterServices?.[0]?.startTime })
                            }
                        })
                    } else {
                        filterUnassignBooking.push({ ...unAssignBooking, services: filterServices, salectTime: filterServices?.[0]?.startTime })
                    }
                }
            })

            filterUnassignBooking?.forEach((item: any) => {
                const [hours, minutes] = item?.salectTime ? item?.salectTime?.split(':').map(Number) : ["00", "00"];
                const [year, month, day] = item?.salectDate ? item?.salectDate?.split('-').map(Number) : ["00", "00", "00"];
                const selectedDate = new Date(year, month - 1, day, hours, minutes);
                let duration: any = [];
                let serviceItem: any = [];


                item?.services?.forEach((service: any, i: any, arr: any) => {
                    if (!service?.TeamMemberId?.[0] && !serviceItem?.some((serviceInside: any) => service?.bundleId?._id ? serviceInside?.serviceId === service?.serviceId?._id && serviceInside?.bundleId === service?.bundleId?._id : serviceInside?.serviceId === service?.serviceId?._id)) {
                        duration.push(Number.parseInt(service?.serviceId?.duration));
                        serviceItem?.push({ serviceId: service?.serviceId?._id, bundleId: service?.bundleId?._id })
                        allUnBooking.push({ ...service, bookingStatus: item?.bookingStatus })
                    }
                });

                const services = item?.services?.map((prices: any) => {
                    return { ...prices, serviceId: { ...prices?.serviceId, serviceId: { ...prices?.serviceDetails, serviceCatagoryId: prices?.serviceCategoryDetails } } }
                })?.filter((item: any) => !item?.TeamMemberId?.[0])

                const total = duration.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);

                let unassval = {
                    title: `${item?.Client?.firstName} ${item?.guestname ? `(${item?.guestname})` : ""}`,
                    bookingId: `${item?.bookedBy === "online" ? item?.booking_Id || "" : item?.businessBookingId || ""}`,
                    start: selectedDate,
                    end: new Date(selectedDate.getTime() + total * 60 * 1000),
                    service: services,
                    resourceId: 1,
                    updateBooking: item,
                    allUnassignedBooking: filterUnassignBooking,
                    eventType: 'Upcoming',
                    booking: item,
                    teamId: selectedViewDateTeam?.team,
                    _id: item?._id
                }
                eventDataArr.push(unassval);
            });


            // const result = [{ from: "00:00", to: `${branchEnd?.[0]}:${branchEnd?.[1]}` }].reduce((acc: any, entry: any, i: any) => {
            //     const timeDifferences = splitIntoSlots(entry.from, entry.to, 15);
            //     return acc.concat(timeDifferences);
            // }, []);
            const result = [{ from: "00:00", to: '23:59' }].reduce((acc: any, entry: any, i: any) => {
                const timeDifferences = splitIntoSlots(entry.from, entry.to, 15);
                return acc.concat(timeDifferences);
            }, []);
            const deductedSlots = allUnBooking?.length !== 0 ? removeConflictingSlots(result, allUnBooking) : result

            deductedSlots.forEach((slot: any) => {
                const originalDate = new Date(selectedDate);
                const year = originalDate.getFullYear();
                const month = originalDate.getMonth();
                const day = originalDate.getDate();
                const [startHrs, startMins] = slot.from.split(':').map(Number);
                const start = new Date(year, month, day, startHrs, startMins);
                const end = new Date(start.getTime() + 15 * 60 * 999); // 15 minutes later
                eventDataArr.push({
                    title: 'emptyBoxes',
                    start,
                    end,
                    resourceId: 1,
                    eventType: 'emptyBoxes',
                });
            });
        }

        let allBooking: any = []
        bookingData?.assigned?.forEach((item: any) => {
            const checkTeam = roleObj?.singleTeamViewCheck ? roleObj?.userData?._id !== item?._id : false;
            if (checkTeam) {
                return
            }

            let resHours = item?.hoursDetail?.filter((shifts: any) => shifts.branchId == branchId)
            let hoursDetail = resHours?.length ? resHours[0]?.shift : [];


            if (selectedViewDateTeam?.view === 'day') {
                // repeated point
                let availableHrsArr: any = [];
                hoursDetail?.forEach((element: any) => {
                    let hrs = element.workingHours?.find((event: any) => event?.day == fullDayName);
                    availableHrsArr.push(hrs);
                });
                // Update array if condition is met
                const updateArray = (arr: any, obj: any) => {
                    const objToMinutes = convertTimeToMinute(obj.to);

                    arr.forEach((item: any) => {
                        if (convertTimeToMinute(item.to) > objToMinutes) {
                            item.to = obj.to;
                        }
                    });
                };

                updateArray(availableHrsArr, branchOpHrs);
                let notAvailableHrs = groupNotAvailableHours(fullDayTimeArray, availableHrsArr);
                // if (branchOpHrs?.from !== 'Closed' && notAvailableHrs?.some((hrs: any) => !Number.isNaN(+hrs?.to?.split(':')?.[1] + 1) &&  `${branchOpHrs?.from?.split(":")?.[0]}:${(+hrs?.to?.split(':')?.[1] + 1)}` !== branchOpHrs?.from)) {
                if (branchOpHrs?.from !== 'Closed' && +branchOpHrs?.from?.split(":")?.[1] !== 0 ) {
                    const fromPartEnd = +branchOpHrs?.from?.split(":")?.[1];
                    notAvailableHrs?.push({ from: `${branchOpHrs?.from?.split(":")?.[0]}:00`, to: `${branchOpHrs?.from?.split(":")?.[0]}:${fromPartEnd - 1}` })
                }
                // notAvailableHrs = notAvailableHrs.filter((item:any) => convertTimeToMinute(item?.to) > convertTimeToMinute(branchOpHrs?.from))
                if (branchOpHrs?.from !== 'Closed') {
                    notAvailableHrs = [...notAvailableHrs, ...item.leaves?.map((item: any) => ({ ...item, type: "leaves" })), ...notWorkingBranch?.map((item: any) => ({ ...item, type: "leaves" }))];
                } else {
                    notAvailableHrs = [
                        { from: '00:00', to: '23:59' }]
                }
                notAvailableHrs = mergeTimeRanges(notAvailableHrs);
                const isOnLeave = item?.availablity?.toLowerCase() === 'not available';
                let brokenDownRanges = [];
                if (isOnLeave) {
                    brokenDownRanges = divideTimeRangeToHourIntervals(branchOpHrs);
                } else {
                    brokenDownRanges = breakTimeRangesToHourIntervals(notAvailableHrs);
                }

                const uniqueIntervals: any = {};
                brokenDownRanges = brokenDownRanges?.filter((range: any) => {
                    return branchOpHrs?.from === 'Closed' ? true : range?.from?.split(':')?.[0] >= branchOpHrs?.from?.split(":")?.[0]
                })
                // Iterate over the array and store each object as a key in the uniqueIntervals object
                brokenDownRanges.forEach((interval: any) => {
                    uniqueIntervals[JSON.stringify(interval)] = interval;
                });

                // Extract the values from the uniqueIntervals object to get unique intervals
                const uniqueTimeIntervals = Object.values(uniqueIntervals);
                uniqueTimeIntervals?.forEach((elem: any) => {
                    const originalDate = new Date(selectedDate);
                    const year = originalDate.getFullYear();
                    const month = (originalDate.getMonth()).toString().padStart(2, '0');
                    const day = originalDate.getDate().toString().padStart(2, '0');
                    const [startHrs, startMins] = elem?.from?.split(':').map(Number);
                    const [endHrs, endMins] = elem?.to?.split(':').map(Number);

                    eventDataArr.push({
                        title: 'Not Available',
                        start: new Date(year, +month, +day, +startHrs, +startMins),
                        end: new Date(year, +month, +day, +endHrs, +endMins),
                        resourceId: item?._id,
                        availablity: item?.availablity,
                        hoursDetail: item?.hoursDetail,
                        bookingData: item,
                        eventType: 'Not Available',
                        // updateBooking: val,
                    });
                });

            } else {
                const { weekStartDate, weekEndDate } = getWeekStartAndEndDates(selectedViewDateTeam?.date);
                let datesArray = getDates(weekStartDate, weekEndDate);
                eventDataArr = []
                datesArray?.map((dateItem: any) => {
                    const branchTeamLeave = item?.allLeave?.filter((leaveItem: any) => leaveItem?.branchId === branchId);
                    const selectedDate = new Date(dateItem)
                    const dayOfWeek = selectedDate.getDay();
                    const fullDayName = dayNames[dayOfWeek];
                    let branchOpHrs = currentBranchData?.workingHours?.find((item: any) => item?.dayName === fullDayName);
                    const dateToCheck = new Date(formatSlashDate(selectedDate))
                    const result = branchTeamLeave?.find((obj: any) => {
                        const fromDate = new Date(obj.fromDate);
                        const toDate = new Date(obj.toDate);
                        return dateToCheck >= fromDate && dateToCheck <= toDate;
                    });
                    const fromTimes = currentBranchData?.workingHours?.filter((day:any) => day.from !== 'Closed')?.map((day:any) => day.from);

                    // Find the minimum 'from' time
                    const minFromTime = fromTimes.reduce((min:any, current:any) => current < min ? current : min);
                    const timeArray: any = result?.length !== 0 ? pushTimeIfInRange(branchTeamLeave, formatSlashDate(selectedDate)) : []
                    // if (timeRange) {
                    //     timeArray.push(timeRange);
                    // }
                    if (minFromTime) {
                        // dayStart.setHours(branchStart[0], branchStart[1], 0);
                        dayStart.setHours(minFromTime?.split(':')?.[0], 0, 0);
                        dayEnd.setHours(branchEnd?.[0], branchEnd[1], 0);
                    }

                    const year = selectedDate?.getFullYear();
                    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
                    const day = String(selectedDate.getDate()).padStart(2, '0');
                    const formattedDate = `${year}-${month}-${day}`;
                    const formattedToday = formattedDate ? new Date(formattedDate) : new Date();

                    const notWorkingBranch: any = [];
                    currentBranchData?.notWorkingHours?.forEach((item: any) => {
                        const from = new Date(item?.fromDate)
                        const to = new Date(item?.toDate)

                        if (+formattedToday >= +from && +formattedToday <= +to) {
                            notWorkingBranch.push({ from: item?.from, to: item?.to })
                        }
                    })

                    // repeated point
                    let availableHrsArr: any = [];
                    hoursDetail?.forEach((element: any) => {
                        let hrs = element.workingHours?.find((event: any) => event?.day == fullDayName);
                        availableHrsArr.push(hrs);
                    });
                    // Update array if condition is met
                    const updateArray = (arr: any, obj: any) => {
                        const objToMinutes = convertTimeToMinute(obj.to);
                        arr.forEach((item: any) => {
                            if (convertTimeToMinute(item.to) > objToMinutes) {
                                item.to = obj.to;
                            }
                        });
                    };

                    updateArray(availableHrsArr, branchOpHrs);
                    let notAvailableHrs = groupNotAvailableHours(fullDayTimeArray, availableHrsArr);
                    if (branchOpHrs?.from !== 'Closed') {
                        const fromPartEnd = +branchOpHrs?.from?.split(":")?.[1];
                        notAvailableHrs?.push({ from: `${minFromTime?.split(":")?.[0]}:00`, to: `${branchOpHrs?.from?.split(":")?.[0]}:${fromPartEnd === 0 ? '00' : fromPartEnd - 1}` })
                    }
                    if (branchOpHrs?.from !== 'Closed') {
                        notAvailableHrs = [...notAvailableHrs, ...item.leaves?.map((item: any) => ({ ...item, type: "leaves" })), ...notWorkingBranch?.map((item: any) => ({ ...item, type: "leaves" })), ...timeArray?.map((item: any) => ({ ...item, type: "leaves" }))];

                    } else {
                        notAvailableHrs = [
                            { from: `${minFromTime?.split(':')?.[0]}:00`, to: '23:59' }]
                    }
                    notAvailableHrs = mergeTimeRanges(notAvailableHrs);
                    const isOnLeave = item?.availablity?.toLowerCase() === 'not available';
                    let brokenDownRanges = [];
                    if (isOnLeave) {
                        brokenDownRanges = divideTimeRangeToHourIntervals(branchOpHrs);
                    } else {
                        brokenDownRanges = breakTimeRangesToHourIntervals(notAvailableHrs);
                    }

                    const uniqueIntervals: any = {};
                    brokenDownRanges = brokenDownRanges?.filter((range: any) => {
                        return branchOpHrs?.from === 'Closed' ? true : range?.from?.split(':')?.[0] >= minFromTime?.split(":")?.[0]
                    })
                    // Iterate over the array and store each object as a key in the uniqueIntervals object
                    brokenDownRanges.forEach((interval: any) => {
                        uniqueIntervals[JSON.stringify(interval)] = interval;
                    });

                    // Extract the values from the uniqueIntervals object to get unique intervals
                    const uniqueTimeIntervals = Object.values(uniqueIntervals);
                    uniqueTimeIntervals?.forEach((elem: any) => {
                        const originalDate = new Date(selectedDate);
                        const year = originalDate.getFullYear();
                        const month = (originalDate.getMonth()).toString().padStart(2, '0');
                        const day = originalDate.getDate().toString().padStart(2, '0');
                        const [startHrs, startMins] = elem?.from?.split(':').map(Number);
                        const [endHrs, endMins] = elem?.to?.split(':').map(Number);

                        eventDataArr.push({
                            title: 'Not Available',
                            start: new Date(year, +month, +day, +startHrs, +startMins),
                            end: new Date(year, +month, +day, +endHrs, +endMins),
                            resourceId: item?._id,
                            availablity: item?.availablity,
                            hoursDetail: item?.hoursDetail,
                            bookingData: item,
                            eventType: 'Not Available',
                            // updateBooking: val,
                        });
                    });
                });
            }

            const totalMinutes = item?.availablity?.split(":")?.[1] ? convertTimetoTotalMinutes(item?.availablity?.split(":")?.[1]) : 0
            const bookedMinutes = item?.booked?.split(":")?.[1] ? convertTimetoTotalMinutes(item?.booked?.split(":")?.[1]) : 0;
            const availableHrs = totalMinutes - bookedMinutes;

            const formattedString = convertToHoursMinutes(availableHrs)
            let resourceObj = {
                id: item?._id,
                title: toTitleCase(`${item?.firstName} ${`${item?.nickName ? `(${item?.nickName})` : ""}`}`),
                teamTitle: item?.Role?.find((item: any) => item?.branch === branchId),
                availablity: formattedString,
                image: item?.image ?? "",
            }
            newresources.push(resourceObj);

            let selectedDayEvents: any = [];

            const modifiedBookingDetails: any = [];

            item?.bookingDetails?.forEach((booking: any) => {
                const services = booking?.services?.filter((service: any, i: number, arr: any) => {
                    const currentEndTime = convertTimeToMinute(service?.startTime)
                    const nextStartTime = arr?.[i + 1] ? convertTimeToMinute(arr?.[i + 1]?.startTime) + Number.parseInt(arr?.[i + 1]?.serviceId?.duration) : "00:00"
                    if (service?.bookingStatus === "Cancelled" || service?.bookingStatus === "Client Cancelled") {
                        return
                    }
                    if (arr?.[i + 1] && currentEndTime !== nextStartTime) {
                        modifiedBookingDetails.push({ ...booking, salectTime: service?.startTime, services: [service] });
                        return false
                        // filterServices = filterServices?.filter((unassign: any) => unassign?._id !== service?._id)
                    } else {
                        return true
                    }

                })
                modifiedBookingDetails.push({ ...booking, services })
            })

            modifiedBookingDetails?.forEach((val: any) => {
                val?.services?.forEach((service: any) => {
                    allBooking.push({ ...service, bookingStatus: val?.bookingStatus })

                })
                if (val?.bookingStatus === "Cancelled" || val?.bookingStatus === "Client Cancelled") {
                    return
                }
                const services = val?.services?.map((prices: any) => {
                    return { ...prices, serviceId: { ...prices?.serviceId, serviceId: { ...prices?.serviceDetails, serviceCatagoryId: prices?.serviceCategoryDetails } } }
                })

                const [hours, minutes] = val?.services?.[0]?.startTime ? val?.services?.[0]?.startTime?.split(':') : ["0", "0"];
                const [year, month, day] = val?.salectDate?.split('-').map(Number);
                const eventDate = new Date(year, month - 1, day, +hours, +minutes);

                let duration: any = []
                let filterTeamService = val?.services.filter((filterId: any) => {
                    return filterId?.TeamMemberId?.[0]?._id === item?._id
                });
                filterTeamService?.forEach((item: any) => {
                    duration.push(parseInt(item?.serviceId?.duration))
                });
                const total = duration.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);

                let endtime = new Date(eventDate.getTime() + total * 60 * 1000);
                const endDateformate = (endtime.getHours() < 10 ? `0${endtime.getHours()}` : `${endtime.getHours()}`) + ':' + (endtime.getMinutes() < 10 ? `0${endtime.getMinutes()}` : `${endtime.getMinutes()}`);
                const dayOfEventWeek = eventDate.getDay();
                const eventDayName = dayNames[dayOfEventWeek];

                let eventObj = {
                    title: `${val?.Client?.firstName || ""} ${val?.guestname ? `(${val?.guestname})` : ""}`,
                    bookingId: `${val?.bookedBy === "online" ? val?.booking_Id || "" : val?.businessBookingId || ""}`,
                    start: eventDate,
                    service: services,
                    booking: val,
                    end: new Date(eventDate.getTime() + total * 60 * 1000),
                    resourceId: item?._id,
                    availablity: item?.availablity,
                    hoursDetail: item?.hoursDetail,
                    bookingData: item,
                    eventType: 'Upcoming',
                    globalEventType: val?.bookingStatus,
                    updateBooking: val,
                }

                eventDataArr.push(eventObj);
                const originalDate = new Date(selectedDate)
                const compyear = originalDate.getFullYear();
                const compmonth = originalDate.getMonth();
                const compday = originalDate.getDate();
                if (year === compyear && (month - 1) === compmonth && day === compday) {
                    selectedDayEvents.push({ day: `${eventDayName}`, from: `${val?.salectTime}`, to: `${endDateformate}`, resourceId: `${item?._id}` })
                }
            });
        })

        if (selectedViewDateTeam?.view === 'day') {
            const totalBooking: any = [...allUnBooking, ...allBooking]
            const slots = bookingData?.availSlots ? bookingData?.availSlots : []
            const result = [{ from: `${branchStart[0]}:00`, to: "23:59" }].reduce((acc: any, entry: any, i: any) => {
                const timeDifferences = splitIntoSlots(entry.from, entry.to, 15);
                return acc.concat(timeDifferences);
            }, []);

            result.forEach((slot: any) => {
                const originalDate = new Date(selectedDate);
                const year = originalDate.getFullYear();
                const month = originalDate.getMonth();
                const day = originalDate.getDate();
                const [startHrs, startMins] = slot.from.split(':').map(Number);
                const start = new Date(year, month, day, startHrs, startMins);
                const end = new Date(start.getTime() + 15 * 60 * 999); // 15 minutes later

                const teamSlots = slots?.[slot.from];

                let occupiedTime = 0;

                totalBooking?.forEach((item: any) => {
                    if (item?.bookingStatus !== "Upcoming") {
                        return
                    }
                    const totalStartMinute = convertTimeToMinute(item?.startTime);
                    let totalDuration = Number.parseInt(item?.serviceId?.duration);

                    totalDuration = totalDuration % 15 !== 0 ? totalDuration + 15 - totalDuration % 15 : totalDuration;

                    const totalEndMinute = totalStartMinute + totalDuration;
                    const slotTotalMinute = convertTimeToMinute(slot.from);
                    // Check if the slot falls within any booking time range
                    if (slotTotalMinute >= totalStartMinute && slotTotalMinute < totalEndMinute) {
                        occupiedTime += 1; // increment occupied time
                    }
                });

                eventDataArr.push({
                    title: 'teamCount',
                    start,
                    end,
                    resourceId: `0`,
                    eventType: 'teamCount',
                    teamCount: teamSlots ? teamSlots : 0,
                    bookedCount: occupiedTime,
                    teamId: selectedViewDateTeam?.team
                });
            });
        }

        const filterEvents = eventDataArr.filter((item: any) => {
            const formattedFrom = item.start.getHours() === 0 && item.start.getMinutes() === 0
                ? '00:00'
                : item.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

            const formattedTo = item.end.getHours() === 0 && item.end.getMinutes() === 0
                ? '00:00'
                : item.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            return branchOpHrs.from === 'Closed' ? true : formattedFrom >= branchOpHrs.from;
            // return formattedFrom >= branchOpHrs.from && formattedTo <= branchOpHrs.to;
        });
        setResources(newresources);
        setEventData(eventDataArr);
    }

    function groupNotAvailableHours(timeArr: any, shifts: any) {
        const notAvailableGroups: any = [];
        let currentGroup: any = null;
        timeArr?.forEach((time: any) => {
            const isAvailable = shifts?.some((shift: any) => {
                const shiftStart = shift.from;
                const shiftEnd = shift.to;
                return time >= shiftStart && time < shiftEnd;
            });

            if (!isAvailable) {
                if (!currentGroup) {
                    currentGroup = { from: time, to: time };
                } else {
                    currentGroup.to = time;
                }
            } else {
                if (currentGroup) {
                    notAvailableGroups.push(currentGroup);
                    currentGroup = null;
                }
            }
        });
        console.log(currentGroup, 'currentGroup');

        if (currentGroup) {
            notAvailableGroups.push(currentGroup);
        }
        return notAvailableGroups;
    }

    let handleAssine = (event: any) => {
        setAssineData(event?.event?.updateBooking)
        setShowAppointment(true);
    }
    const handleShowCustomerClick = (events: any) => {
        // if (events?.event?.bookingData === undefined) {
        //     setShowCustomer(false)
        // } else {

        setShowCustomer(true)
        const bookingDetails = events?.event?.bookingData?.bookingDetails?.map((data: any) => {
            const services = data?.services?.map((prices: any) => {
                return { ...prices, serviceId: { ...prices?.serviceId, serviceId: { ...prices?.serviceDetails, serviceCatagoryId: prices?.serviceCategoryDetails } } }
            })
            return { ...data, services }
        });
        const data = { ...events?.event?.bookingData, bookingDetails };
        setEventBookingData(data)

        setViewBookingData(events)
        // }
    };

    const handleRebook = (events: any) => {
        setupdateBooking(events?.event?.updateBooking);
    }

    const MyCustomEvents = (events: any) => {
        const ref: any = useRef();
        const startTime = useMemo(() => moment(events?.event?.start).format('hh:mm a'), [events?.event?.start]);
        const endTime = useMemo(() => moment(new Date(events?.event?.end)).format('hh:mm a'), [events?.event?.end]);
        const bookingId = events?.event?.bookingId?.includes("-") ? events?.event?.bookingId?.split("-")[1] : events?.event?.bookingId;

        const unassignEvent = events?.event?.resourceId === 1;

        let service: any = events.event?.service?.map((item: any) => item?.serviceId?.serviceId?.serviceName).join(',');
        const bookedCount = events?.event?.bookedCount || 0;
        const availableCount = events?.event?.teamCount || 0;


        const existBundleList = useMemo(() => {
            const bundles: any[] = [];
            events.event?.service?.forEach((item: any) => {
                if (item.bundleId?._id) {
                    const existBundle = bundles.find((bundle) => bundle?.bundleId === item.bundleId?._id);
                    if (existBundle) {
                        existBundle.services.push(item);
                    } else {
                        bundles.push({ bundleName: item.bundleId?.bundleName, bundleId: item.bundleId?._id, finalPrice: item?.bundleId?.finalPrice, services: [item] });
                    }
                }
            });
            return bundles;
        }, [events]);

        if (events?.event?.eventType === 'emptyBoxes') {
            return (
                <div className={`${cx.events} ${cx.emptyBoxes} justify-content-center d-flex`} role='img'>

                </div>
            );
        }
        if (events?.event?.eventType === 'Not Available') {
            return (
                <div className={`${cx.events} ${cx.unavailableBox} justify-content-center d-flex`}>
                    <h2 className={`${cx.teamTitle} ${cx.unavailable}`}>{""}</h2>
                </div>
            );
        }

        if (events?.event?.eventType === "teamCount") {
            const selectedDateInMm = new Date(events?.event?.start)?.getTime();
            const currDateInMm = new Date()?.getTime();
            const pastDate = selectedDateInMm < currDateInMm

            return (
                <div className={`${cx.events} ${cx.noPadding} ${cx.unavailableBox} justify-content-center d-flex`}>
                    {<div className={cx.compareBox}>
                        {<div className={cx.allTeamClount} style={{ width: `${(bookedCount / (bookedCount + availableCount)) * 100}%` }}>{bookedCount !== 0 ? bookedCount : ""}</div>}
                        {availableCount !== 0 && <div className={`${cx.teamCount} ${pastDate ? cx.allTeamClount : ""}`} style={{ cursor: canCreateBooking ? "pointer" : "auto", width: bookedCount === 0 ? "100%" : `${(availableCount / (bookedCount + availableCount)) * 100}%` }} onClick={() => handleTeamBooking(events?.event?.start, events)}>{availableCount > 0 ? availableCount : ""}</div>}
                    </div>}
                </div>
            );
        }
        if (events?.event?.eventType === 'Not Available') {
            return (
                <div className={`${cx.events} ${cx.unavailableBox} justify-content-center d-flex`}>
                    <h2 className={`${cx.teamTitle} ${cx.unavailable}`}>{""}</h2>
                    {/* <h2 className={`${cx.teamTitle} ${cx.unavailable}`}>{events?.title}</h2> */}
                </div>
            );
        }
        const completedCheck = events?.event?.service?.[0]?.bookingStatus === "Completed";
        const globalEvent = events?.event?.globalEventType;

        return (
            <>
                {events?.event?.eventType === 'Upcoming' ?
                    <div style={unassignEvent ? { transform: `` } : {}} className={`${cx.events} ${unassignEvent ? cx.unassignBorder : cx.bookingBorder} ${events?.event?.booking?.bookedBy !== "offline" ? cx.events_b2c : ""} ${completedCheck || globalEvent === "Completed" ? cx.completed : ""} ${globalEvent === "Cancelled" ? cx.cancelled : globalEvent === "No Show" ? cx.noShow : ""}`} ref={ref} onClick={(e: any) => {
                        e.stopPropagation();
                        handleShowCustomerClick(events)
                    }}>
                        <h2 className={cx.teamTitle}>{events?.title} <span className={cx.teamperson_id} title={events?.event?.bookingId}>{bookingId ? `(${bookingId})` : ""}</span></h2>
                        {existBundleList?.length > 0 ? (
                            <>
                                {existBundleList?.map((bundle: any, b: any) => {
                                    const bundleServices = bundle?.services?.map((service: any) => service?.serviceDetails?.serviceName)?.join(", ")
                                    return (
                                        <h6 className={cx.services} key={b}><img src={schedularA} alt="service" /><span>{bundle?.bundleName} - ({bundleServices})</span></h6>
                                    )
                                })}
                            </>
                        ) : (<h6 className={cx.services}><img src={schedularA} alt="service" /><span>{service}</span></h6>)}

                        < h6 className={cx.services}><img src={clockA} alt="time" /> <span>{startTime} - {endTime}</span></h6>
                        {/* {unassignEvent ? (
                            <div className={cx.assignContainer}>
                                <button className={cx.assignBtn} onClick={(e: any) => {
                                    e.stopPropagation();
                                    setClicked(true)
                                    handleAssine(events);
                                }}>Assign</button>
                            </div>
                        ) : ""} */}
                    </div > :
                    <div className={cx.events} onClick={() => handleRebook(events)}>
                        <h2 className={cx.teamTitle}>{events?.title}</h2>
                        <h6 className={cx.services}><img src={schedule} alt="service" /><span>{service}</span></h6>
                        <h6 className={cx.services}><img src={clock} alt="time" /> <span>{startTime} - {endTime}</span></h6>
                        <div className={cx.assignContainer}>
                            <button className={cx.assignBtn} onClick={() => {
                                setShowRebook(true)
                            }}>Rebook</button>
                        </div>
                    </div>
                }
            </>

        );
    };

    const handleSelectSlot = (eventVal: any) => {
        if (!roleObj?.canCreateAllBooking && !roleObj?.canCreateOwnBooking) {
            return
        }

        if (roleObj?.canCreateOwnBooking && !roleObj?.canCreateAllBooking) {
            if (roleObj?.userData?._id !== eventVal?.resourceId) {
                return
            }
        }
        handleCreateSlotEvent(eventVal, bookingData);
    };

    const customSlotPropGetter = (date: Date) => {
        const currentTime = moment();
        const cellTime = moment(date);
        // Check if cell time is before current time
        if (cellTime.isBefore(currentTime)) {
            return {
                className: cx.grayColor, // Add custom class to grey out
            };
        }
        return {
            className: cx.specialDay,
        }
    }


    const CalendarToolbar = (actions: any) => {
        const { onView, label, views, view, onNavigate } = actions;
        const navigate = useNavigate();
        let caleDate = formatSlashDate(actions?.date)
        const [selectedTeam, setSelectedTeam] = useState(selectedViewDateTeam?.team);
        const [selectView, setSelectView] = useState(selectedViewDateTeam?.view);
        const [calendarDate, setCalendarDate] = useState(caleDate);

        useEffect(() => {
            setSelectedTeam(selectedViewDateTeam?.team)
        }, [selectedViewDateTeam])

        useEffect(() => {
            setCalendarDate(caleDate);
        }, [caleDate]);

        function handleNavigate(type: string) {
            onNavigate(type);
            handleToolbarChange('navigate', { to: type, date: actions.date, view: view });
        }

        function handleViewChange(type: string) {
            if (type === "month") {
                navigate("/business/calendar")
                return
            }

            onView(type);
            setSelectView(type);
            if (type === 'week' && !selectedTeam) {
                setSelectedTeam(branchTeamList[0]?.id);
            }
            handleToolbarChange('changeView', { to: type, date: actions.date });
        }

        function handleTeamChange(value: string) {
            if (value === '' && selectView === 'week') {
                setSelectView('day');
                onView('day');
            }
            setSelectedTeam(value);
            handleToolbarChange('changeTeam', value);
        }
        function handleReload() {
            window.location.reload()
        }

        const handleDateNavigate = (dateVal: any, type: any = null) => {
            let date: any;

            if (type === "today") {
                date = new Date()
            } else {
                date = new Date(dateVal)
            }

            const todayFormated = formatSlashDate(date);
            const parsedDate = new Date(todayFormated);

            onNavigate('DATE', parsedDate);
            handleToolbarChange('navigateDate', { date: dateVal, view: view });
            handleDateSelector(dateVal)
        };

        useEffect(() => {
            if (dateParam) {
                const inputDate = new Date(location.state.date);
                const formattedDate = moment(inputDate).format('YYYY-MM-DD');
                handleDateNavigate(formattedDate)
            }
        }, [dateParam])

        return (
            <>
                <div className={`${cx.calendartoolbox}`}>
                    <div className={`${cx.firstBox} ${cx.calendar}`}>
                        {/* <div className={`${st.formBox} ${cx.availabilityBox}`}>
                            <select className="form-select">
                                <option value="Upcoming">All Bookings</option>
                            </select>
                        </div> */}
                        <ul className={cx.view} onClick={() => handleToolbarChange('listview', null)}>
                            <li>
                                <img src={menuList} alt="view" />
                            </li>
                        </ul>
                        <div className={`${cx.dayBox} ${cx.firstBox} justify-content-end`}>
                            <div className={`${st.formBox} ${cx.availabilityBox}`}>
                                <select style={{ paddingLeft: '6px' }} className={`form-select`} value={selectView}
                                    onChange={(e) => handleViewChange(e.target.value)}>
                                    {views.map((view: any) => (
                                        <option key={view} value={view}>{`${toTitleCase(view)} View`}</option>
                                    ))}
                                </select>
                            </div>
                            {/* <button
                            className={`singleBtn`}
                            onClick={() => handleToolbarChange('createNew', null)}
                        >
                            <img src={plusWhite} alt="add" className={cx.addIcon} /> Create
                        </button> */}
                        </div>
                        <div className={`${st.formBox} ${cx.availabilityBox}`}>
                            <select className={`form-select`} value={roleObj?.userData && !roleObj?.canViewAllBooking ? roleObj?.userData?._id : selectedTeam} disabled={roleObj?.userData && !roleObj?.canViewAllBooking ? branchTeamList?.length < 2 : branchTeamList?.length === 0} onChange={(e) => handleTeamChange(e.target.value)}>
                                <option value="" >{branchTeamList?.length === 0 && !loading ? "team unavailable" : "All Team"}</option>
                                {branchTeamList?.map((item: any) => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>

                    </div>
                    <div className={`${cx.right} ${cx.calendarBox}`}>
                        <button className={`${cx.todayButton} ${cx.circle} ${isDateToday(calendarDate) ? cx.active : cx.inActive}`} onClick={() => handleDateNavigate(formatSlashDate(new Date()), "today")}>Today</button>

                        <button onClick={() => handleNavigate("PREV")}><MdOutlineChevronLeft className={cx.left} /></button>
                        <div className={cx.buttonBox}>
                            <button className={`${cx.todayButton} ${isDateToday(calendarDate) ? '' : cx.inActive}`}>{getDayName(calendarDate)}</button>
                            {view === 'day' ? <input type="date" className={`${cx.dateSelector} ${!isDateToday(calendarDate) ? cx.active : ""} form-control`}
                                value={calendarDate}
                                onChange={(e) => handleDateNavigate(e.target.value)} /> :
                                <span>{label}</span>
                            }
                        </div>
                        <button onClick={() => handleNavigate("NEXT")}><MdOutlineChevronRight className={cx.left} /></button>
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                        <div className={`${cx.reloadBooking_btn}`}>
                            <button className="btn" onClick={() => handleReload()}>
                                <TbReload />
                            </button>
                        </div>
                        <div className="text-end">
                            <HelpButon number={2} type={"help"} className={"btn"} />
                        </div>
                    </div>
                    {/* <div className={`${cx.add_reportingMain_sec}`}>
                        <div className={`${st.formBox} ${cx.add_reporting_box}`}>
                            <label className={'form-label'}>Day's Booking</label>
                            <div className={`form-control`}>8 Compelete/5 Pending </div>
                        </div>
                        <div className={`${st.formBox} ${cx.add_reporting_box}`}>
                            <label className={'form-label'}>Hours Available</label>
                            <div className={`form-control`}>20/25 </div>
                        </div>
                        <div className={`${st.formBox} ${cx.add_reporting_box}`}>
                            <label className={'form-label'}>Today Booking Hours</label>
                            <div className={`form-control`}>25/30</div>
                        </div>
                    </div> */}


                </div>

                <div className={`${cx.calendy_todayBookHrs_main}`}>
                    <ul>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Upcoming Booking:</span> <span className={`${cx.right}`}>20</span></li>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Completed Booking:</span> <span className={`${cx.right}`}>20</span></li>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Time Available:</span> <span className={`${cx.right}`}>20 hours 10 mins</span></li>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Total:</span> <span className={`${cx.right}`}>21 hours 10 mins</span></li>
                        <li><GoDotFill /> <span className={`${cx.left}`}>Completed:</span> <span className={`${cx.right}`}>20 hours 10 mins</span></li>
                    </ul>
                </div>
            </>
        )
    };

    const onEventDrop = async (data: any) => {
        if (data?.event?.title === "emptyBoxes" || data?.event?.title === "teamCount") {
            return
        }

        if (canEditBooking) {
            const { start, end, event, resourceId } = data;
            const booking = listData?.find((item: any) => item?._id === event?.booking?._id)

            const date = new Date(start);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
            let day = date.getDate().toString().padStart(2, '0');


            if (roleObj?.singleTeamChangeCheck) {
                const changeCondition = !event?.service?.every((item: any) => item?.TeamMemberId?.length > 0 ? item?.TeamMemberId?.[0]?._id === roleObj?.userData?._id : true)
                if (changeCondition) {
                    return
                }
            }

            const currentBookingDAteInFormat = new Date(`${booking?.salectDate}T${data?.event?.service?.[0]?.startTime}:00`)

            const currentHour = currentBookingDAteInFormat?.getHours()
            const currentMinutes = currentBookingDAteInFormat?.getMinutes();

            const newHours = date?.getHours()
            const newMinutes = date?.getMinutes()

            const timeDifference = (currentHour * 60 + currentMinutes) - (newHours * 60 + newMinutes);
            const timeDiffereceMinutes = timeDifference

            // if(timeDiffereceMinutes ===0 ){
            //     return
            // }

            let formattedDate = `${year}-${month}-${day}`;

            const existStartTime = convertTimeToMinute(booking?.salectTime);
            const updatedMinutes = existStartTime - timeDiffereceMinutes;
            const formattedTime = convertToTime(updatedMinutes)

            let salectDateInFormat = `${formattedDate}T${formattedTime}:00`;

            if (+new Date(salectDateInFormat) < +new Date()) {
                handleAlertModalShow("Error, appointment can't be rescheduled on past time")
                return
            }

            const bookingServices = booking?.services?.sort((a: any, b: any) => {
                return a.startTime.localeCompare(b.startTime);
            })?.map((item: any) => {
                return {
                    ...item,
                    TeamMemberId: data?.event?.service?.some((book: any) => book?._id === item?._id) ? resourceId !== 1 ? [resourceId] : [] : item?.TeamMemberId?.length > 0 ? [item?.TeamMemberId?.[0]?._id] : []
                }
            });

            const services = bookingServices?.map((item: any, index: number) => {
                const existStartTime = convertTimeToMinute(item?.startTime);
                const updatedMinutes = existStartTime - timeDiffereceMinutes;
                const formattedTime = convertToTime(updatedMinutes)

                return {
                    ...item,
                    serviceId: item?.serviceId?._id,
                    TeamMemberId: item?.TeamMemberId,
                    bundleId: item?.bundleId?._id || null,
                    promotionId: item?.promotionId?._id || null,
                    bundlePromotionId: item?.bundlePromotionId || null,
                    bundlePromotionalPrice: item?.bundlePromotionalPrice || "",
                    _id: item?._id,
                    startTime: formattedTime,
                }
            })

            const serviceId = services?.map((item: any) => item?.serviceDetails?._id);
            const totalDuration: any = bookingServices?.reduce((acc: any, item: any) => acc + Number.parseInt(item?.serviceId?.duration), 0);;
            let queryArr: any = bookingServices?.map((item: any, i: any) => {

                return (
                    {
                        serviceId: item?.serviceDetails?._id,
                        duration: Number.parseInt(item?.serviceId?.duration),
                        teamMemberId: item?.TeamMemberId?.[0] || null,
                        teamTitleId: item?.serviceId?.teamTitleId,
                    }
                )
            })

            queryArr?.sort((a: any, b: any) => {
                if (a.serviceId === b.serviceId) {
                    if (a.teamMemberId === null && b.teamMemberId !== null) {
                        return 1;  // a comes after b
                    } else if (a.teamMemberId !== null && b.teamMemberId === null) {
                        return -1; // a comes before b
                    } else {
                        return 0; // no change in order
                    }
                }
            });



            const teamId: any = []
            bookingServices?.forEach((item: any) => {
                if (item?.TeamMemberId?.length > 0) {
                    teamId.push(item?.TeamMemberId?.[0])
                }
            });

            let params = `businessId=${booking?.Business?._id}&bookingId=${booking?._id}&branchId=${booking?.BranchLocation?._id}&startDate=${formattedDate}&endDate=${formattedDate}&serviceArray=${JSON.stringify(queryArr)}&serviceId=${JSON.stringify(serviceId)}&teamMemberId=${JSON.stringify(teamId)}&serviceDuration=${Number.parseInt(totalDuration)}`

            const response: any = await httpRequest(`getAvailableReschedulingSlots?${params}`, "GET", null, "json");

            if (response?.status) {
                const bookingSlots = response?.data?.[0]?.result;
                if (bookingSlots?.length > 0) {
                    const fromArray = bookingSlots?.map((item: any) => item?.from);
                    if (fromArray.includes(formattedTime)) {
                        let finalData = {
                            ...booking,
                            salectDateInFormat,
                            salectDate: formattedDate,
                            salectTime: formattedTime,
                            services,
                        }



                        const res = await httpRequest(`updateBooking/${booking?._id}`, "PATCH", finalData, "json");
                        if (res.status) {
                            getBooking && getBooking()
                        }
                    } else {
                        handleAlertModalShow("Error, team member is not available for this service at this time!")
                        return
                    }

                } else {
                    handleAlertModalShow("Error, team member is not available for this service at this time!")
                    return
                }

                // let finalData = {
                //     ...booking,
                //     salectDateInFormat,
                //     salectDate: formattedDate,
                //     salectTime: formattedTime,
                //     services,
                // }

                // const res = await httpRequest(`updateBooking/${booking?._id}`, "PATCH", finalData, "json");
                // if (res.status) {
                //     getBooking && getBooking()

                // }
            }
        }

    };

    const eventPropGetter = (event: any, start: any, end: any, isSelected: any) => {
        if (event.resourceId === "0" || event.eventType === "emptyBoxes") {
            return {
                className: 'custom-event', // Use your custom class here
            }
        } else {
            return {
                className: ""
            }
        }
    }

    return (
        <React.Fragment key={branchId}>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            {<div>
                {showAppointment && <AppointmentModal
                    showAppointment={showAppointment}
                    bookingData={assineData}
                    getBooking={getBooking}
                    handleAppointmentClose={handleAppointmentClose}
                />}

                {showCustomer && <CustomarModal
                    showCustomer={showCustomer}
                    bookingData={eventBookingData}
                    viewBookingData={viewBookingData}
                    getBooking={getBooking}
                    handleCustomerClose={handleCustomerClose}
                />}
                {showRebook && <ListViewRebook showRebook={showRebook} handleRebookClose={handleRebookClose}
                    data={updateBooking} getBooking={getBooking}
                />}

                <div className={`${cx.DnDCalendar} ${loading ? cx.filring : null}`}>
                    <FadeLoader loading={loading} margin={2} width={5} color="#36d7b7" style={{ position: "absolute", top: "55%", left: "50%", zIndex: '1000' }} />
                    <DnDCalendar
                        defaultView={selectedViewDateTeam?.view}
                        key={branchTeamList}
                        events={eventData}
                        localizer={localizer}
                        resources={resources}
                        style={{ height: 'calc(100vh - 90px)' }}
                        components={{
                            resourceHeader: MyCustomResourceHeader,
                            event: MyCustomEvents,
                            toolbar: CalendarToolbar,
                        }}
                        // date={selectedDate}
                        onEventDrop={onEventDrop}
                        resizable={false}
                        onSelectSlot={handleSelectSlot}
                        eventPropGetter={eventPropGetter}
                        selectable
                        step={15}
                        timeslots={4}
                        slotPropGetter={customSlotPropGetter}
                        // onView={handleVewChange}
                        views={['day', 'week', "month"]}
                        min={dayStart} // Set the minimum time
                        // max={dayEnd} // set the maximum time
                        max={new Date(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate(), 23, 59)} // Explicitly set to 23:59
                        popup
                    />
                </div>
            </div>}
        </React.Fragment>
    )
}
const DnDCalendar = withDragAndDrop(Calendar)
export default MyCalendar

const MyCustomResourceHeader: any = (resource: any) => {
    const isTeamAvailable = resource?.resource?.availablity?.toLowerCase() !== 'not available';
    const role = resource?.resource?.teamTitle ? resource?.resource?.teamTitle?.role?.map((title: any) => title?.teamTitle) : []


    return (
        // <div className={`${cx.teamBox} ${resource?.resource?.isUnassign ? cx.Unassigned : ""}`}>
        <>
            {resource?.label === "Total Available Slots" ? (
                <div className={`${cx.teamBox} ${cx.teamAvailablityBox}`}>
                    <div className={cx.contentBox}>
                        <p className={cx.label}>{resource?.label}</p>
                    </div>
                </div>
            ) : resource?.label === "Unassigned appointments" ? <div className={`${cx.teamBox} ${cx.Unassigned}`}>
                <div className={cx.contentBox}>

                    {/* <p className={cx.label}> <img src={unionLine} className={`${cx.icon}`} alt="union" /> {resource?.label}</p> */}
                    <p className={cx.label}>Unassigned <br /> appointments</p>
                </div>
            </div> : <div className={`${cx.teamBox}`}>
                {resource.resource.image ?
                    <div className={`${cx.imageBox}`}>
                        <img src={resource.resource.image} alt={resource.resource?.label} />
                    </div> :
                    <div className={`${cx.imageBox} ${cx.emotyBox}`} role='img'>
                        <span className={cx.logoFake}>{getDpName(resource?.label)}</span>
                    </div>
                }
                <div className={cx.contentBox}>
                    <p className={cx.label} title={resource?.label}>{resource?.label}</p>
                    <p className={`${cx.label} ${cx.title}`} title={role.join(", ")}>{role.join(", ")}</p>
                    <p className={isTeamAvailable ? cx.availablity : cx.notAvailable}>{resource?.resource?.availablity}</p>
                </div>
            </div>}
        </>
    );
};

const CustomTimeSlot = ({ value, resource, eventsData }: any) => {
    const resourceEvents = eventsData.filter((event: any) => event.resourceId === resource);

    const isEvent = resourceEvents.some((event: any) => value >= event.start && value < event.end);

    const isAvailable = !isEvent;

    return (
        <div
            style={{
                backgroundColor: isAvailable ? 'green' : 'red',
                height: '100%',
                width: '100%',
                textAlign: 'center', // Center text within the div
                color: 'white', // Text color
            }}
        >
            {(resource && resource !== 1) && (
                <span>
                    {/* {value.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                    {isAvailable ? ' Available' : ' Not Available'}
                </span>
            )}
        </div>
    );
};


function generateTimeArray() {
    const times = [];
    for (let hours = 0; hours < 24; hours++) {
        for (let minutes = 0; minutes < 60; minutes++) {
            const hourFormatted = hours.toString().padStart(2, '0');
            const minuteFormatted = minutes.toString().padStart(2, '0');
            const time = `${hourFormatted}:${minuteFormatted}`;
            times.push(time);
        }
    }
    return times;
}

function isDateGreaterOrEqual(selectedDate: Date) {
    const today = new Date();
    const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const selectedDateObject = new Date(selectedDate);
    return selectedDateObject >= todayWithoutTime;
}

function divideTimeRangeToHourIntervals(timeRange: any, type: any = "") {
    const result: any = [];
    const { from, to } = timeRange;
    if (from === to) {
        return result;
    }

    const fromTime = new Date(`2000-01-01T${from}`);
    const toTime = new Date(`2000-01-01T${to}`);

    let current = fromTime;
    let timeDifferece = 0;

    let roundHrs: any;
    if (type === "leaves") {
        roundHrs = roundUpToNextHour(from, type);
    } else {
        roundHrs = roundUpToNextHour(from, "");
    }

    if (roundHrs === "24:00") {
        roundHrs = "23:59"
    }


    if (from !== roundHrs) {
        const timeDifferenceInMinutes = getTimeDifferenceInMinutes(from, roundHrs);
        timeDifferece = timeDifferenceInMinutes;
        current.setMinutes(current.getMinutes() + timeDifferece);

        result.push({
            from: from,
            to: roundHrs,
        });
    }


    while (current < toTime) {
        const nextHour = new Date(current);
        nextHour.setHours(nextHour.getHours() + 1);

        let slotEnd = toTime;

        if (nextHour < toTime) {
            slotEnd = nextHour;
        }

        const formattedFrom = current.getHours() === 0 && current.getMinutes() === 0
            ? '00:00'
            : current.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });


        // slotEnd.setMinutes(slotEnd)
        const formattedTo = slotEnd.getHours() === 0 && slotEnd.getMinutes() === 0
            ? '00:00'
            : slotEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        const adjustedTime = formattedTo >= '24:00' ? `00:${formattedTo?.split(':')[1]}` : formattedTo;

        result.push({
            from: formattedFrom,
            to: adjustedTime === "24:00" ? "23:59" : adjustedTime,
        });
        current = nextHour;
    }

    return removeDuplicates(result);
}

function stringToMinutes(item: any) {
    const [h, m] = item?.split(":");
    return +h * 60 + +m
}
function removeDuplicates(array: any) {
    return array?.filter((item: any) => stringToMinutes(item?.from) !== stringToMinutes(item?.to))
}


function breakTimeRangesToHourIntervals(timeRanges: any) {
    const brokenDownRanges = [];
    for (const range of timeRanges) {
        const dividedRanges = divideTimeRangeToHourIntervals(range, range?.type);
        brokenDownRanges.push(...dividedRanges);
    }
    return brokenDownRanges;
}

function generateHourlyArray() {
    const hoursArray = [];

    for (let i = 0; i < 24; i++) {
        const hourString = i.toString().padStart(2, '0');
        hoursArray.push(`${hourString}:00`);
    }

    return hoursArray;
}

function roundUpToNextHour(inputTime: string, type: any) {
    const [inputHours, inputMinutes] = inputTime.split(':').map(Number);

    const resultHours = inputMinutes > 0 ? inputHours + 1 : inputHours;
    let formattedResult: any = "";
    if (type === "leaves") {
        formattedResult = `${inputHours}:${inputMinutes}`;
    } else {
        formattedResult = `${String(resultHours).padStart(2, '0')}:00`
    }

    return formattedResult;
}

function getTimeDifferenceInMinutes(time1: string, time2: string) {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    // Convert hours and minutes to total minutes
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    // Calculate the time difference in minutes
    const timeDifferenceInMinutes = totalMinutes2 - totalMinutes1;

    return timeDifferenceInMinutes;
}

function isDateToday(calendarDate: string) {
    let todayDate = formatSlashDate(new Date());
    return calendarDate === todayDate;
}

function getDayName(dateString: string) {
    // Create a Date object from the date string
    const dateObject = new Date(dateString);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = dateObject.getDay();

    // Define an array of day names
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get the day name using the day of the week
    const dayName = days[dayOfWeek];
    return dayName
}
