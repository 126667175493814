import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

const TeamTab = (props: any) => {
  const { handleStepsFormData,editTeamMemberm,editServiceData } = props;
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  let arr:any = [];
 {editTeamMemberm?.map((item:any)=>{
    arr.push(item?._id)
 })}

 

  const location = useLocation();

  const [teamTitle, setTeamTitle] = useState([]);
  const [team, setTeam] = useState([]);
  
  const [selectedTeam, setSelectedTeam] = useState<any>([...arr]);
  const [loading, setLoading] = useState(false);
  let deleteData:any = [];
    editTeamMemberm?.map((team:any) => team?._id).forEach((team:any) =>{
      if(!selectedTeam.includes(team) ){
        deleteData.push(team)
      }
    })
  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData) {
      getTitle();
      
    }
  }, [businessData]);
  useEffect(()=>{
    if(branchId){
      getTeam();
    }
  },[branchId])
  const getTitle = async () => {
    const res = await httpRequest(`getTeamTitle?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      setTeamTitle(res.data);
    }
  };
  const getTeam = async () => {
    const res = await httpRequest(`getAllTeamMembers?branchId=${branchId}&businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      setTeam(res.data);
    }
  };
  const handleTeam = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (selectedTeam.length > 0) {
      const data = {
        serviceId: location.state.serviceId,
        teamMembers: selectedTeam,
        deleteTeamMembers:deleteData,
      }
      const res = await httpRequest(`assignServiceToTeam?businessId=${businessData?._id} `, "PATCH", data, "json");
      
      if (res.status) {
        handleStepsFormData(res, "amenities");
      }
      // setLoading(true);
    }
    handleStepsFormData("","amenities");
  };
  return (
    <>
      <Row>
      <Col md={12}>
                  <div className="text-end">
                    <HelpButon number={18} type={"help"} className={"btn"} />
                  </div>
                </Col>
        {team.length === 0 ? <p>No Team Member Found</p> : team?.map((team: any) => {
          return (
            <Col lg={4} md={6} key={team._id}>
              <article
                className={`${cx.teamBox} ${selectedTeam.some((a: any) => a === team._id) ? cx.active : ""
                  }`}
                onClick={(e) => {
                  if (selectedTeam?.some((a: any) => a === team._id)) {
                    const filterTeam = selectedTeam.filter((t: any) => {
                      return t !== team._id
                    })
                    setSelectedTeam(filterTeam);
                    return;
                  } else {
                    setSelectedTeam([...selectedTeam, team._id]);
                  }
                }}
              >
                <div className={`${cx.left}`}>
                  <span>
                    {`${team?.firstName[0]?.toUpperCase()}
                      ${team?.lastName[0]?.toUpperCase() || ""}`
                      }
                  </span>
                </div>
                <div className={`${cx.right}`}>
                  <h3 className={cx.memberName}>
                    {`${team?.firstName} ${team?.lastName || ""}`}
                  </h3>
                </div>
              </article>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col md={12}>
          <div className={`${st.formBox}`}>
            <div className={`${st.btnsGroup}`}>
              <button className={`btn ${st.btn1}`} onClick={(e: any) => {e.preventDefault(); handleStepsFormData("prevStep", "pricing")}}>
                <span
                  className={`${cx.btn1Clr}`}
                  
                >
                  Back
                </span>
              </button>
              <button
                className={`btn ${st.btn4} ${cx.btn2Clr}`}
                onClick={handleTeam} disabled={loading}
              >
              {
                selectedTeam.length < 1 ? "Skip" : (loading ? <LoadingBtn /> : "Save")
              }
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TeamTab;
