import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import {
  deleteIcon,
  editIcon,
} from "../../../assets/images";


const StatusButton = (props: any) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink className={`btn ${tb.edit}`} title="Edit" to="edit-amenity">
              <img src={editIcon} alt="edit" />
            </NavLink>
          </li>
          {/* <li>
            <NavLink className={`btn ${tb.edit}`} title="Edit" to="view-amenity">
              <img src={openIcon} alt="view" />
            </NavLink>
          </li> */}
          <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>
          {/* <li>
            <Status />
          </li> */}
        </ul>
      </div>

      <DeletePopup show={show} handleClose={handleClose} />
    </>
  );
};

const columns = [
  { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    minWidth: 280,
    renderCell: (params: any) => <StatusButton params={params} />,
  },
  { field: "itemName", headerName: "Item Name", flex: 1, minWidth: 300 },
  { field: "quantity", headerName: "Quantity", flex: 1, minWidth: 120 },
  { field: "occupied", headerName: "Occupied", flex: 1, minWidth: 130 },
  { field: "available", headerName: "Available", flex: 1, minWidth: 120 },
];

const row = [
  {
    id: 1,
    serialNumber:1,
    itemName: "Room",
    quantity: 10,
    occupied: 8,
    available: 2,
    action: "action",
  },
  {
    id: 2,
    serialNumber:2,
    itemName: "Table",
    quantity: 12,
    occupied: 12,
    available: 0,
    action: "action",
  },
  {
    id: 3,
    serialNumber:3,
    itemName: "Chair",
    quantity: 15,
    occupied: 10,
    available: 5,
    action: "action",
  },
];

export default function DataGridTable() {
  return (
    <div className={`${tb.dataTable}`}>
      <div style={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={row}
          autoHeight
          hideFooterPagination={false}
          rowHeight={52}
          checkboxSelection
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}
