import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { deleteIcon } from '../../../assets/images';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../Apis/commonApis';
import Select from "react-select";

function BookingTeam(props: any) {
    const { selectedClient, service, counterServices, setCounterServices, setRemovePromotion, totalDuration, errorTrig, data, oldData, addService, index, selectedServices, time, date, amenitiesList, formattedDate, type = "", bundleId = null } = props;
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [promotionList, setPromotionList] = useState<any>([]);
    const [load, setLoad] = useState<any>(false);
    const [counter, setCounter] = useState<any>({})

    let { branchId } = useSelector((state: any) => state.BranchReducer);
    let totalMinute = service?.startTime;

    if(totalMinute % 60 !==0){
        totalMinute = totalMinute % 15 !== 0 ? totalMinute + 15 - totalMinute % 15 : totalMinute;
    }

    const hours = Math.floor(totalMinute / 60);
    const minutes = totalMinute % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    const getPromotion = async () => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let link = `getPromotion?branchId=${branchId}&businessId=${businessData?._id}&timeZone=${userTimeZone}&bookingTime=${formattedTime}&bookingDate=${formattedDate}&clientId=${selectedClient?._id}`;
        if (data?._id) {
            link = `getPromotion?branchId=${branchId}&businessId=${businessData?._id}&timeZone=${userTimeZone}&bookingTime=${formattedTime}&bookingDate=${formattedDate}&bookingId=${data?._id}&clientId=${selectedClient?._id}`
        }
        if (businessData) {
            let res = await httpRequest(link, "get", null, "json");
            if (res.status) {
                setPromotionList(res?.data?.filter((item: any) => item?.type === "service"));
                setLoad(true)
            }
        }
    };

    useEffect(() => {
        if (type !== "bundle") {
            getPromotion();
        }
    }, [formattedTime, formattedDate, selectedClient]);

    useEffect(() => {
        if (service && date && time) {
            getTeam()
        }
    }, [formattedTime, formattedDate])

    const getTeam = async () => {
        const duration = Number.parseInt(service?.duration)
        setLoading(true)
        if (totalDuration !== 0 && formattedTime) {
            const res = await httpRequest(`getAvailableTeamMember?businessId=${businessData?._id}&branchId=${branchId}&bookingTime=${time}&bookingDate=${date}&newBookingDuration=${duration}&startTime=${formattedTime}&bookingId=${oldData?._id ? oldData?._id : ""}&serviceId=${service?.BusinessService?._id}&teamTitleId=${service?.teamTitleId || ""}&bookingType=${oldData ? "editBooking" : "createBooking"}`, "get", null, "json");
            if (res?.status) {
                let newTeamList = res?.data
                const oldTeam = oldData?.services?.find((item: any, i: any) => item?._id === service?.id)?.TeamMemberId[0];

                if (oldTeam && oldData?.salectDate === date && oldData?.salectTime === time && !newTeamList?.some((team: any) => team?._id === existService?.TeamMemberId[0]?._id)) {
                    newTeamList?.push(oldTeam)
                }
                const totalLength = newTeamList?.length;
                setCounter({ bundleId, serviceId: service?._id, teamCount: totalLength })
                setTeam(res?.data);
            }
        }
        setLoading(false);
    };    

    useEffect(() => {
        if (counter?.serviceId) {
            let updatedCounterService = counterServices.map((item: any) => {
                const shouldUpdate = item?.bundleId
                    ? item?.serviceId === counter?.serviceId && item?.bundleId === bundleId
                    : item?.serviceId === counter?.serviceId;
                if (shouldUpdate) {
                    return {
                        ...item,
                        teamCount: counter?.teamCount
                    };
                } else {
                    return item;
                }
            });
            setCounterServices(updatedCounterService);
        }

    }, [counter])

    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState<any>([])

    let promo: any = [];
    promotionList?.forEach(((pro: any) => {
        const promotionInServieCheck = pro?.priceId?.some((price: any) => price?.id?._id === service?._id);
        if (!promotionInServieCheck) {
            return
        }

        

        let radeemedCount = +pro?.redeemCount;
        let clientUses = +pro?.clientUses;
        selectedServices?.forEach((serviceItem: any) => {
            if (serviceItem?.promotionId === pro?._id && serviceItem?.serviceId !== service?._id) {
                radeemedCount += 1
                clientUses += 1
            }
        })

        const checkMaxUserCritiria: any = !pro?.LimitNumberOfUses?.maxUser ? true : radeemedCount < pro?.LimitNumberOfUses?.maxUser;
        const isclientInLimit: any = !pro?.LimitUsePerClient ? true : clientUses < pro?.LimitUsePerClient
        if (!isclientInLimit) {
            return
        }

        if (!checkMaxUserCritiria) {
            return
        }
        promo.push(pro)
    }));




    let newTeamList = team?.filter((teamData: any) => {
        const teams = teamData?.serviceId?.some((data: any) => {
            return data?.serviceName === service?.BusinessService?.serviceName
        });
        return teams
    });

    const existService = data?.services?.find((item: any) => item?._id === service?.id);
    const oldTeam = oldData?.services?.find((item: any, i: any) => item?._id === service?.id)?.TeamMemberId[0];

    if (oldTeam && oldData?.salectDate === date && oldData?.salectTime === time && !newTeamList?.some((team: any) => team?._id === existService?.TeamMemberId[0]?._id)) {
        newTeamList?.push(oldTeam)
    }

    const promotionServiceIndex = selectedServices?.findIndex((ser: any) => ser?.serviceId === service?._id);

    const currService = selectedServices?.find((ser: any, i: number) => {
        if (type === "bundle") {
            return ser?._id === service?.id && ser?.bundleId === bundleId
        } else {
            return ser?._id === service?.id
        }
    });

    // if (!newTeamList?.some((team: any) => team?._id === currService?.TeamMemberId?.[0])) {
    //     getTeamCheck(true);
    // } else {
    //     getTeamCheck(false);
    // }

    useEffect(() => {
        if (load && !promo?.some((item: any) => item?._id === currService?.promotionId)) {
            setRemovePromotion((prev: any) => prev?.some((item: any) => item?.promotionId === currService?.promotionId) ? [...prev] : [...prev, { serviceId: currService?.serviceId, promotionId: currService?.promotionId }]);
            setLoad(false)
        } else {
            if (load) {
                setRemovePromotion((prev: any) => prev?.filter((item: any) => item?.promotionId !== currService?.promotionId));
                setLoad(false)
            }
        }
    }, [promo])


    const sameServices = selectedServices?.filter((ser: any, i: number) => ser?.serviceId === service?._id);

    const appliedPromotion = promotionList?.find((promo: any) => promo?._id === currService?.promotionId)
    const selectedPromoServices = appliedPromotion?.priceId?.find((price: any) => price?.id?._id === currService?.serviceId);

    // selected amenities 
    // const selectedAmenities = amenitiesList?.filter((am: any) => currService?.amenitiesId?.includes(am?._id));
    // const amList = service?.BusinessService?.amenitiesId?.filter((am: any) => amenitiesList?.some((availableAmenity: any) => availableAmenity?.AmenitiesGlobalList?._id === am))?.map((amenity: any) => amenitiesList?.find((data: any) => data !== null && data?.available > 0 && data?.AmenitiesGlobalList?._id === amenity));

    // for package implementation
    // const packServices = selectedClient?.packageService && selectedClient?.packageService?.length !== 0 ? selectedClient?.packageService?.find((packServices: any) => packServices?.serviceId === service?._id) : null;
    // const remainingDuration = Number(packServices?.noOfServices) * 60 - packServices?.duration;

    // const isAvailableInPack = packServices?.type === "duration" ? Number.parseInt(service?.duration) <= remainingDuration && packServices?.duration < Number(packServices?.noOfServices) * 60 : packServices?.type === "noOfServices" ? packServices?.usedCounter < Number(packServices?.noOfServices) : false;
    // const radeemedCount = packServices?.type === "duration" ? remainingDuration : packServices?.type === "noOfServices" ? Number(packServices?.noOfServices) - packServices?.usedCounter : 0;

    // let packService: any = [];
    // if (selectedClient?.packageService) {
    //     selectedClient.packageService.forEach((packServiceItem: any) => {
    //         console.log(packServiceItem, "packServiceITem");

    //         const remainingDuration = Number(packServiceItem?.noOfServices) * 60 - +packServiceItem?.duration;
    //         const isAvailableInPack = packServiceItem?.type === "duration" ? Number.parseInt(service?.duration) <= remainingDuration && packServiceItem?.duration < Number(packServiceItem?.noOfServices) * 60 : packServiceItem?.type === "noOfServices" ? packServiceItem?.usedCounter < Number(packServiceItem?.noOfServices) : false;
    //         const radeemedCount = packServiceItem?.type === "duration" ? remainingDuration : packServiceItem?.type === "noOfServices" ? Number(packServiceItem?.noOfServices) - +packServiceItem?.usedCounter : 0;

    //         if (packServiceItem?.serviceId?._id === service?._id && isAvailableInPack) {
    //             packService.push({ ...packServiceItem, radeemedCount });
    //         }
    //     });
    // }




    return (
        <Col md={12}>
            <div className={`${m.selectedServices}`}>
                <div className={m.top}>
                    <div className={m.left}>
                        <p className={m.makup}>{service?.BusinessService?.serviceName}</p>
                        <p className={m.makeupTime}>{`${service?.duration} ${`${!service?.seasonType ? "" : service?.seasonType[0]?.toLocaleUpperCase()}${!service?.seasonType ? "" : service?.seasonType?.split("Price")?.[0]?.slice(1)} Price`} - $${service?.seasonPrice ? service?.seasonPrice : 0}`}</p>
                    </div>
                    {type !== "bundle" && (
                        <>
                            <div className={m.middle}>
                                {currService?.radeemPackage && <p className={m.usd}>0 USD</p>}
                                {!currService?.radeemPackage && selectedPromoServices?.id?._id && <p className={m.cancel}>{service?.seasonPrice ? service?.seasonPrice : 0}USD</p>}
                                {!currService?.radeemPackage && <p className={m.usd}>{selectedPromoServices?.id?._id && service?.seasonPrice ? `${Number.parseInt(selectedPromoServices?.promotionalPrice)}` : service?.seasonPrice ? service?.seasonPrice : 0} USD</p>}
                            </div>
                            {/* <div className={m.right}>
                                <img src={deleteIcon} style={{ cursor: "pointer" }} alt="delete" onClick={() => deleteService(currService?._id, index, service?._id)} />
                            </div> */}
                        </>
                    )}
                </div>
                <div className={m.bottom}>
                    <div className={`${st.formBox} ${m.options}`}>
                        {newTeamList?.length === 0 && !loading ? (
                            <p className={"errorMessage"}>
                                Team member is not available
                            </p>
                        ) : !errorTrig ? "" : !currService?.TeamMemberId?.[0] || currService?.TeamMemberId?.[0] === null && !loading ? (
                            <p className={"errorMessage"}>
                                Please select a team member
                            </p>
                        ) : ""}
                        <select className="form-select" value={currService?.TeamMemberId[0]} disabled={loading || newTeamList?.length === 0} onChange={(e: any) => addService("team", e.target.value, index, currService?._id, null, bundleId)}>
                            {loading ? (
                                <option value="">
                                    Checking Team Member's Availability
                                </option>
                            ) : (
                                <option value="">
                                    {newTeamList?.length === 0 ? "Team member is not available" : "Add a Team Member"}
                                </option>
                            )}
                            {newTeamList?.map((team: any) => {
                                if (sameServices?.some((item: any) => item?.TeamMemberId?.[0] === team?._id && item?._id !== service?.id)) {
                                    return
                                }
                                return <option value={team?._id} key={team?._id}>{`${team?.firstName} ${team?.lastName}`}</option>
                            })}
                        </select>
                    </div>

                    {/* <div className={`${st.formBox} ${m.options}`}>
                        <div className={`${st.booking}`}>
                            <Select
                                options={amList?.map((data: any) => {
                                    return { value: data?._id, label: data?.AmenitiesGlobalList?.itemName }
                                })}
                                value={amList?.filter((am: any) => selectedAmenities?.some((selectedAm: any) => selectedAm?._id === am?._id))?.map((data: any) => {
                                    return { value: data?._id, label: data?.AmenitiesGlobalList?.itemName }
                                })}
                                isMulti
                                placeholder="Select Amenities"
                                onChange={(e: any) => addService("amenity", e, index, service?._id)}
                            />
                            {currService?.amenitiesId?.length === 0 && errorTrig && <p className={"errorMessage"}>
                                Please select a amenity
                            </p>}
                        </div>
                    </div> */}

                    {!currService?.radeemPackage && type !== "bundle" && <div className={`${st.formBox} ${m.options}`}>
                        <select className="form-select" disabled={promo?.length === 0} value={currService?.promotionId} onChange={(e: any) => addService("promotion", e.target.value, index, currService?._id)}>
                            {promo?.length === 0 ? <option value="">Promotion is not available</option> : <option value="">Apply a promotion</option>}
                            {promo?.map((prmotion: any, index: any) => {
                                return (
                                    <option key={prmotion?._id} value={prmotion?._id}>{prmotion?.promotionName}</option>
                                )
                            })}
                        </select>
                    </div>}

                    {/* {isAvailableInPack && <div className={`${m.packRadeem}`}>
                        <label className={`${st.checkbox} ${m.checkPlace}`}>
                            <input type="checkbox" checked={currService?.radeemPackage} onChange={(e: any) => addService("radeemedPackage", e.target.checked, index, currService?._id, packServices?.packageId?.packageId)} />
                            <span className={`${st.checkmark} ${m.check}`}></span>
                            <span className={`${m.text}`}>This service is available in your package. {packServices?.type === "duration" ? `You can use it for (${radeemedCount} mins duration)` : `You can redeem it (${radeemedCount}) times`} Do you want to redeem it ?</span>
                        </label>
                    </div>}  */}
                    {/* {packService?.length !==0 && <div className={`${st.formBox} ${m.options}`}>
                        <select className="form-select"  value={currService?.packageId} onChange={(e: any) => addService("radeemedPackage", e.target.value !=="", index, currService?._id, e.target.value)}>
                            <option value="">Apply Package</option>
                            {packService?.map((packageItem: any, index: any) => {
                                return (
                                    <option key={index} value={packageItem?.packageId?._id}>{`${packageItem?.packageId?.PackageName} - remaining ${packageItem?.type === "duration" ? "uses" : "uses"} ${packageItem?.radeemedCount} ${packageItem?.type === "duration" ? "mins duration" : "uses"}`}</option>
                                )
                            })}
                        </select>
                    </div>} */}
                </div>
            </div>
        </Col>
    )
}

export default BookingTeam
