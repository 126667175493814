import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
//import { useNavigate } from "react-router-dom";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import { deleteIcon, editIcon, openIcon, } from "../../../assets/images";
import { Status } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";


const StatusButton = (props: any) => {
  let { params, clientData } = props;
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditClick = () => {
    let dataToEdit = clientData?.find((client: any) => {
      return client?._id === params?.row?.id;
    });
    navigate("/business/branch-clients/edit-client", { state: dataToEdit });
  };
  const handleViewClick = () => {
    let dataToView = clientData?.find((client: any) => {
      return client?._id === params?.row?.id;
    });
    navigate("/business/clients/view-client", { state: dataToView });
  };

  const deleteApi = async () => {
    const res = await httpRequest(
      `deleteClient/${params?.row?.id}`,
      "DELETE",
      null,
      "json"
    );
    if (res?.status === true) {
      handleClose();
      props.getClient();
    }
  };

  const canEditClient = subModuleRoles?.['Branch Set Up']?.Client?.Edit;
  const canDeleteClient = subModuleRoles?.['Branch Set Up']?.Client?.Delete;

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {canEditClient && <li>
            <a className={`btn ${tb.edit}`} title="Edit">
              <img
                src={editIcon}
                alt="edit"
                onClick={() => {
                  handleEditClick();
                }}
              />
            </a>
          </li>}
          <li>
            <a
              className={`btn ${tb.edit}`}
              title="view"
            // to="view-client"
            >
              <img
                src={openIcon}
                alt="view"
                onClick={() => {
                  handleViewClick();
                }}
              />
            </a>
          </li>
          {canDeleteClient && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>}
          <li>
            <Status
              status={params.row.isActive}
              api="updateClient"
              id={params?.id}
              apiMethod="json"
              getLatest={props.getClient}
            />
          </li>
        </ul>
      </div>
      <DeletePopup
        show={show}
        deleteApi={deleteApi}
        handleClose={handleClose}
      />
    </>
  );
};

export default function DataGridTable(props: any) {
  let Row = props?.clientData?.map((val: any, index: any) => {
    const lastVisit = val?.bookingData?.bookingData?.[0]?.createdAt ? new Intl.DateTimeFormat("en-US", { year: "numeric", month: "short", day: "2-digit" }).format(new Date(val?.bookingData?.bookingData?.[0]?.createdAt)) : ""

    return {
      serialNumber: index + 1,
      id: val?._id,
      clientName: val?.firstName,
      source: val?.source ? val?.source === "Business" ? "Direct" : val?.source : '--',
      number: val?.mobile,
      email: val?.email,
      walkIn: val?.guestclient,
      isActive: val.isActive,
      memberSince: val?.createdAt
        ? new Date(val.createdAt).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).replace(/\//g, '-')
        : null,
      lastVisit: lastVisit,
      action: "",
    };
  });

  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 220,
      renderCell: (params: any) => (
        <StatusButton
          params={params}
          getClient={props.getClient}
          clientData={props.clientData}
        />
      ),
    },
    { field: "clientName", headerName: "Client Name", flex: 1, minWidth: 150 },
    { field: "source", headerName: "Source", flex: 1, minWidth: 180 },
    { field: "number", headerName: "Number", flex: 1, minWidth: 140 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    {
      field: "memberSince",
      headerName: "Member Since",
      flex: 1,
      minWidth: 145,
    },
    { field: "lastVisit", headerName: "Last Visit", flex: 1, minWidth: 145 },

  ];

  return (
    <div className={`${tb.dataTable}`}>
      <div style={{ width: "100%" }}>
        <Col md={12}>
          <div className="text-end mb-2">
            <HelpButon number={19} type={"help"} className={"btn"} />
          </div>
        </Col>
        <DataGrid
          columns={columns}
          rows={Row}
          autoHeight
          hideFooterPagination={false}
          rowHeight={52}
          checkboxSelection
          disableRowSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}

