import React, { useEffect, useState } from 'react';
import st from '../../../assets/stylesheet/style.module.scss';
import cx from './index.module.scss'
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import { call, cancelled, checkTick, dotThree, editBooking, emailGreen, test } from '../../../assets/images';
import MultiSelect from '../../../components/Business/MultiSelect/MultiSelect';
import HelpButon from '../../../components/Business/HelpButon.tsx/HelpButton';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import ChangeAccessManager from '../../../components/Business/Modals/ChangeAccessManager';
import ChangeTeamContactModal from '../../../components/Business/Modals/ChangeTeamContactModal';
import ErrorPopup from '../../../components/Business/Modals/ErrorPopup';


export default function RolesList() {
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
    const [teamMember, setteamMember] = useState<any[]>([])
    const [branchData, setBranchData] = useState<any>([]);
    const [verificationStatus, setVerificationStatus] = useState<any>("")

    const [accessRole, setAccessRole] = useState<any>([]);

    const [selectedBranch, setSelectedBranch] = useState<any>([])
    const [selectedTeam, setSelectedTeam] = useState<any>([])
    const [selectedRole, setSelectedRole] = useState<any>([]);

    const [showErrorModal, setShowErrorModal] = useState<String>("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const [data, setData] = useState<any>(null);

    const [showAccessManagerModal, setShowAccessManager] = useState<boolean>(false);

    const [showContactChangeModal, setShowContactChangeModal] = useState<boolean>(false)

    function handleContactModal() {
        setData(null)
        setShowContactChangeModal(false)
    }
    const handleAccessManagerModalClose = () => {
        setData(null)
        setShowAccessManager(false)
    };

    const getRoles = async () => {
        let res = await httpRequest(`getSuperRoles`, "get", null, 'json');
        if (res.status) {
            setAccessRole(res?.data)
        }
    }
    useEffect(() => {
        if (businessData) {
            getTeamMember();
            getBranches();
            getRoles();
        }
    }, [businessData]);

    const getTeamMember = async () => {
        const res: any = await httpRequest(`getAllTeamMembers?businessId=${businessData._id}&type=list`, "get", null, "json");
        if (res?.status) {
            setteamMember(res?.data?.filter((team: any) => team?.Role?.some((role: any) => role?.role?.some((item: any) => item?.customerService))))
        }
    };
    

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
        if (res.status === true) {
            setBranchData(res.data);
        }
    }


    const branchwiseTeam = teamMember?.flatMap((person: any) =>
        person.Role.map((role: any) => ({
            ...person,
            branch: role?.branch
        }))
    );    

    const handleMultiSelect = (data: any, type: string) => {
        switch (type) {
            case "branch":
                setSelectedBranch(data)
                break
            case "team":
                setSelectedTeam(data)
                break
            case "roles":
                setSelectedRole(data)
                break
            default:
                break
        }
    }   

    const filterTeams = branchwiseTeam?.filter((item: any) => {
        const branchWiseRoleObj = item?.Role?.find((branchWiseRole: any) => branchWiseRole?.branch?._id === item?.branch?._id);
        const branchMatch = selectedBranch?.length === 0 || selectedBranch?.some((branch: any) => branch?.value === item?.branch?._id);
        const teamMatch = selectedTeam?.length === 0 || selectedTeam?.some((team: any) => team?.value === item?._id);
        const roleMatch = selectedRole?.length === 0 || selectedRole?.some((role: any) => branchWiseRoleObj ? Array.isArray(branchWiseRoleObj?.accessManagerId) ? branchWiseRoleObj?.accessManagerId?.some((accessRoleId:any) => role?.value === accessRoleId) : branchWiseRoleObj?.accessManagerId === role?.value : true);
        const statusMatch = verificationStatus === "" || item?.isVerified === (verificationStatus === "active")
        return branchMatch && teamMatch && roleMatch && statusMatch
    });

    const verifiedHandler = async (value: any, id: any) => {
        const formdata = new FormData();
        formdata.append("isVerified", JSON.stringify(value))
        const res = await httpRequest(`updateTeamMember/${id}`, 'PATCH', formdata, "formdata");
        if (res?.status) {
            handleAlertModalShow(`Success, ${res?.message}`)
            getTeamMember && getTeamMember()
        } else {
            handleAlertModalShow(`Error, ${res?.message}`)
        }
    }

    async function handleVerifyEmail(id:any) {
        const formdata = new FormData();
        formdata.append("emailVerify", JSON.stringify(true))
        const res = await httpRequest(`updateTeamMember/${id}`, 'PATCH', formdata, "formdata");

        if (res?.status) {
            handleAlertModalShow(`Success, ${res?.message}`)
        } else {
            handleAlertModalShow(`Error, ${res?.message}`)
        }
    }


    const canEdit = subModuleRoles?.['Teams']?.['WN APP Access']?.Edit

    const renderList = filterTeams?.map((data: any, i: any) => {
        const branchWiseRole = data?.Role?.find((item: any) => item?.branch?._id === data?.branch?._id);
        return (
            <tr key={`${data?.id}${i}`} >
                <td style={{ paddingLeft: "20px" }}>{i + 1}</td>
                <td>{data?.branch?.branchName || ""}</td>

                <td>
                    <div className={cx.teamContainer}>
                        {data?.image ? <div className={cx.teamImage}>
                            <img src={data?.image} alt={data?.firstName} />
                        </div> : (
                            <div className={cx.logoBox}>
                                <span>{`${data?.firstName?.[0]?.toLocaleUpperCase()}`}</span>
                            </div>
                        )}

                        <div>
                            <span className={`${cx.teamPerson}`}>{`${data?.firstName} ${data?.lastName || ""} ${data?.nickName ? `(${data?.nickName})` : ""}`}</span>
                            <span className={`${cx.emp_id}`}>Empl ID : {data?.teamMemberIdNo || ""}</span>
                        </div>

                    </div>
                </td>
                {canEdit && <td style={{ width: '100px' }}>
                    <div className={cx.dotDropdown}>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic-6">
                                <img src={dotThree} alt="dot" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={() => {
                                    setData(data)
                                    setShowAccessManager(true)
                                }}>
                                    <p className={cx.dotDropInside}>
                                        <img src={editBooking} alt="edit" /> <span>Change role</span>
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => {
                                    setData(data)
                                    setShowContactChangeModal(true)
                                }}>
                                    <p className={cx.dotDropInside}>
                                        <img src={editBooking} alt="edit" /> <span>Change contact info</span>
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => {
                                    handleVerifyEmail(data?._id)
                                }}>
                                    <p className={cx.dotDropInside}>
                                        <img src={editBooking} alt="edit" /> <span>Send verification Email</span>
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => {
                                    setData(data)
                                    setShowContactChangeModal(true)
                                }}>
                                    <p className={cx.dotDropInside}>
                                        <img src={editBooking} alt="edit" /> <span>Send Password Reset Email</span>
                                    </p>
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </td>}
                <td>
                    <button disabled={!canEdit} className={`${cx.activeButton} ${!data?.isVerified ? cx.inactive : ""}`} onClick={() => verifiedHandler(!data?.isVerified, data?._id)}>
                        {data?.isVerified ? "Active Access" : "Access Inactive"}
                    </button>
                </td>
                <td>
                    <div className={cx.action}>
                        <p className={cx.contactBox}>
                            <span className={`${cx.imageBox} ${cx.tickImg}`}> <img src={data?.emailVerified ? checkTick : cancelled} alt="checkTick" /></span> <span className={cx.content}>{data?.emailVerified ? "Email Verified" : "Email Not Verified"}</span>
                        </p>
                        <p className={cx.contactBox}>
                            <span className={`${cx.imageBox} ${cx.tickImg}`}> <img src={data?.mobileVerified ? checkTick : cancelled} alt="cancelled" /></span> <span className={cx.content}>{data?.mobileVerified ? "Mobile Verified" : "Mobile Not Verified"}</span>
                        </p>
                    </div>
                </td>

                <td>
                    <ul>
                        <li>{Array.isArray(branchWiseRole?.accessManager) ? branchWiseRole?.accessManager?.join(", ") : branchWiseRole?.accessManager || "not assigned"}</li>
                    </ul>
                </td>
                <td >
                    <div className={cx.action}>
                        {data?.email && <p className={cx.contactBox}>
                            <span className={cx.imageBox}> <img src={emailGreen} alt="email" /></span> <span className={cx.content}>{data?.email}</span>
                        </p>}
                        {data?.mobile && <p className={cx.contactBox}>
                            <span className={cx.imageBox}><img src={call} alt="call" /></span> <span className={cx.content}>{data?.mobile}</span>
                        </p>}
                    </div>
                </td>
                <td>
                    <ul>
                        <li>{branchWiseRole?.role?.filter((item: any) => item?.customerService)?.map((role: any) => role?.teamTitle)?.join(", ")}</li>
                    </ul>
                </td>
            </tr>
        )
    })

    return (
        <>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            {showAccessManagerModal && <ChangeAccessManager
                handleAccessManagerModalClose={handleAccessManagerModalClose}
                showAccessManagerModal={showAccessManagerModal}
                data={data}
                getTeamMember={getTeamMember}
                accessRole={accessRole}
            />}
            {showContactChangeModal && <ChangeTeamContactModal
                showContactChangeModal={showContactChangeModal}
                handleContactModal={handleContactModal}
                data={data}
                getTeamMember={getTeamMember}
            />}
            <section className={`${st.pageWrapper}`}>
                <div className={`${st.pageTitle}`}>
                    <div className={`${st.titleInfo} w-auto`}>
                        <h5>Role Assignment</h5>
                    </div>
                    <div className="text-end">
                        <HelpButon number={43} type={"help"} className={"btn"} />
                    </div>
                </div>
                <Row>
                    <Col md={3}>
                        <div className={`${st.formBox} mt-2`}>
                            <label className="form-label">Branch</label>
                            <span className={`${st.multiSelbookingect_whiteBg} mt-2`}>
                                <MultiSelect
                                    value={selectedBranch}
                                    onChange={(data: any) => handleMultiSelect(data, "branch")}
                                    list={branchData?.map((item: any) => ({ label: `${item?.branchName}`, value: item?._id }))}
                                />
                            </span>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={`${st.formBox} mt-2`}>
                            <label className="form-label">Team Member</label>
                            <span className={`${st.multiSelbookingect_whiteBg} mt-2`}>
                                <MultiSelect
                                    list={teamMember?.map((item: any) => ({ label: `${item?.firstName} ${item?.lastName || ""}`, value: item?._id }))}
                                    onChange={(data: any) => handleMultiSelect(data, "team")}
                                    value={selectedTeam}
                                />
                            </span>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={`${st.formBox} mt-2`}>
                            <label className="form-label">Role</label>
                            <span className={`${st.multiSelbookingect_whiteBg} mt-2`}>
                                <MultiSelect
                                    list={accessRole?.map((role: any) => ({ label: role?.role, value: role?._id }))}
                                    onChange={(data: any) => handleMultiSelect(data, "roles")}
                                    value={selectedRole}
                                />
                            </span>
                        </div>
                    </Col>
                    <Col md={2}>
                        <Col lg={10}>
                            <div className={`${st.formBox} ${st.selectbooking_whiteBg} mt-2`}>
                                <label className="form-label">Verification Status</label>
                                <select className={`form-select`} value={verificationStatus} onChange={(e: any) => setVerificationStatus(e.target.value)}>
                                    <option value="">---Select---</option>
                                    <option value="active">active</option>
                                    <option value="inactive">inactive</option>
                                </select>
                            </div>
                        </Col>
                    </Col>
                </Row>

                <div className={`${st.tableBody}`}>
                    <div className={`${st.tableBodyIn} ${cx.rollTable} ${cx.listViewMargin} mt-1`}>
                        <div className={cx.tableContainer}>
                            <Table hover borderless striped className={`${cx.table}`}>
                                <thead>
                                    <tr>
                                        <th style={{ maxWidth: '30px', minWidth: "30px", paddingLeft: "20px" }}>S.N.</th>
                                        <th style={{ minWidth: '150px' }}>Branch</th>
                                        <th style={{ minWidth: '180px' }}>Team Member</th>

                                        {canEdit && <th style={{ width: '100px' }}>Action</th>}
                                        <th>Status</th>
                                        <th style={{ minWidth: '210px' }}>Verification</th>
                                        <th style={{ minWidth: '150px' }}>Access Role</th>
                                        <th>Contact</th>
                                        <th>Team Title  </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {renderList}
                                </tbody>
                            </Table>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
