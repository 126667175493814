import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

import { Col, Dropdown, Nav, NavLink, Row, Tab, Table } from "react-bootstrap";
import {
  asDefalut,
  basicInfo,
  basicInfoA,
  emailIcon,
  location,
  membership,
  phoneIcon,
  profile,
  profileImg,
  teamPerson1,
} from "../../../assets/images";
import { MdModeEditOutline, MdOutlineMoreVert } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import EditClient from "../../../components/Business/Modals/EditClient";
import { useLocation, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { DeletePopup } from "../../../components/Business/Modals";
import { convertMinutesToTime } from "../../../Utils/Funcs";
import { formatDateFormat } from "../../../constants/Constants";

const ViewClient = (props: any) => {
  const navigate = useNavigate();
  const [clientData, setClientData] = useState<any>({});
  const [teamList, setTeamList] = useState<any>([]);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const [activeButton, setActiveButton] = useState("Upcoming");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  const getClientProfile = async () => {
    const res = await httpRequest(`getClientProfile?businessId=${businessData?._id}&id=${location?.state?._id}`, "get", null, "json");
    setClientData(res?.data)
  }
  const getAllTeamMember = async () => {
    const res = await httpRequest(`getAllTeamMembers?businessId=${businessData?._id}&type=list`, "get", null, "json");
    setTeamList(res?.data)
  }
  useEffect(() => {
    if (location?.state?._id) {
      getClientProfile()
      getAllTeamMember()
    }
  }, [location?.state])

  const [showEditClient, setShowEditClient] = useState(false);
  const handleEditClientClose = () => setShowEditClient(false);
  const handleEditClientShow = () => setShowEditClient(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteApi = async () => {
    const res = await httpRequest(
      `deleteClient/${clientData?._id}`,
      "DELETE",
      null,
      "json"
    );
    if (res?.status === true) {
      handleClose();
      navigate("/business/clients", { replace: true })
    }
  };

  let groupByDate = (bookings: any) => {
    if (!bookings) {
      return
    }
    const grouped: any[] = [];
    bookings.forEach((event: any) => {
      const date: any = event?.salectDate;
      const existingGroup = grouped.find(group => group?.date === date);
      if (existingGroup) {
        existingGroup?.events?.push(event);
      } else {
        grouped?.push({ date, events: [event] });
      }
    });
    return grouped;
  }

  const bookingInGroup: any = groupByDate(clientData?.bookingData);
  return (
    <>
      <DeletePopup
        show={show}
        deleteApi={deleteApi}
        handleClose={handleClose}
        message="client"
      />
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {" "}
              {/* <BackArrrow />  */}
              {`${location?.state?.firstName || ""} ${location?.state?.lastName || ""}`}
            </h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew2">
                  <img
                    src={basicInfo}
                    alt="booking"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={basicInfoA}
                    alt="booking"
                    className={`${st.active}`}
                  />
                  Bookings
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew3">
                  <img
                    src={basicInfo}
                    alt="Review"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={basicInfoA}
                    alt="Review"
                    className={`${st.active}`}
                  />
                  Review
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <Row>
                  <Col md={4}>
                    <div className={`${cx.profileCard}`}>
                      <div className={`${cx.profileCardImg}`}>
                        <img src={asDefalut} alt="profile" />
                        <button className={`${cx.editBtn}`}>
                          <MdModeEditOutline />
                        </button>
                      </div>
                      <h5>{location?.state?.firstName}</h5>
                      <p>{location?.state?.country}</p>
                      <p>{location?.state?.updatedAt?.split('T')[0]}</p>
                    </div>

                    <div className={`${cx.informationCard}`}>
                      <h5>
                        {/* Arnold Shakold */}
                        {location?.state?.firstName}
                        {/* <NavLink
                          className={`${cx.editIcon}`}
                          href="#"
                          onClick={handleEditClientShow}
                        >
                          <MdModeEditOutline />
                        </NavLink> */}
                      </h5>
                      <ul>
                        <li>
                          <span className={`${cx.informationHeading}`}>
                            DOB
                          </span>{" "}
                          {/* 25.03.1997 */}
                          {formatDateFormat(location?.state?.dateOfBirth)}
                        </li>
                        <li>
                          {/* <span>Email</span> bewanewa@gmail.com */}
                          <span>Email</span> {location?.state?.email}
                        </li>
                        <li>
                          <span>Phone</span> {location?.state?.mobile}
                        </li>
                        <li>
                          <span>Location</span> {location?.state?.adderss1} {location?.state?.adderss2}
                          {/* 70800 */}
                        </li>
                      </ul>
                      <p className={`${cx.deleteAccount}`} onClick={handleShow}>
                        Delete Account
                      </p>
                    </div>
                  </Col>

                  <Col md={8}>
                    <Row>
                      <Col lg={3} md={4}>
                        <div className={`${cx.infoCard}`}>
                          <h5>{clientData?.noShow}</h5>
                          <p>No Show</p>
                        </div>
                      </Col>
                      <Col lg={3} md={4}>
                        <div className={`${cx.infoCard}`}>
                          <h5>{clientData?.completed}</h5>
                          <p>Completed</p>
                        </div>
                      </Col>
                      <Col lg={3} md={4}>
                        <div className={`${cx.infoCard}`}>
                          <h5>{clientData?.visitsBooked}</h5>
                          <p>Visits Booked</p>
                        </div>
                      </Col>
                      <Col lg={3} md={4}>
                        <div className={`${cx.infoCard}`}>
                          <h5>{clientData?.canceled}</h5>
                          <p>Canceled</p>
                        </div>
                      </Col>
                    </Row>
                    <div className={`${cx.informationCard}`}>
                      <h5>
                        Membership
                        <NavLink className={`${cx.editIcon}`} href="#">
                          <MdModeEditOutline />
                        </NavLink>
                      </h5>
                      <Row className="mt-3">
                        <Col md={4} className={`${cx.membershipImg}`}>
                          <img src={membership} alt="membership" />
                        </Col>
                        <Col md={8} className={`${cx.membershipBody}`}>
                          <h4>Membership number</h4>
                          <h6>4950 4554 4456 3235</h6>
                          <p>Start date: 09/12/2019 - Ending on: 08/12/2029</p>
                          <button className={`btn ${cx.cancelMembership}`}>
                            Cancel membership
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew2">
                <div
                  className={`${st.buttonsAction} ${st.buttonflexDiv} justify-content-end`}
                >
                  <div className={`${st.threeButton} ${cx.threeBtn}`}>
                    <ul className={`${cx.bookingFilter}`}>
                      <li>
                        <button className={`${st.btn} ${activeButton === "Upcoming" ? st.active : ""}`} onClick={() => setActiveButton("Upcoming")}>
                          Upcoming
                        </button>
                      </li>
                      <li>
                        <button className={`${st.btn} ${activeButton === "Completed" ? st.active : ""}`} onClick={() => setActiveButton("Completed")}>Completed</button>
                      </li>
                      <li>
                        <button className={`${st.btn} ${activeButton === "Cancelled" ? st.active : ""}`} onClick={() => setActiveButton("Cancelled")}>Cancelled</button>
                      </li>
                      <li>
                        <button className={`${st.btn} ${activeButton === "No Show" ? st.active : ""}`} onClick={() => setActiveButton("No Show")}>No Show</button>
                      </li>
                    </ul>
                  </div>
                </div>


                {bookingInGroup && bookingInGroup?.map((booking: any, i: any) => {
                  const bookingDate = booking?.date ? new Date(booking?.date)?.toDateString() : "";
                  const isToday = bookingDate === new Date().toDateString();

                  const filterBookings = activeButton === "Cancelled" ? booking?.events?.filter((data: any) => {
                    if(data?.bookingStatus === "Cancelled" || data?.bookingStatus === "Client Cancelled"){
                      return data
                    }
                  }) : booking?.events?.filter((data: any) => data?.bookingStatus === activeButton);
                  if (filterBookings?.length === 0) {
                    if (i === 0) {
                      return <h5 className={cx.bookingStatus}>No {activeButton} Booking Found</h5>
                    } else {
                      return
                    }
                  }


                  return (
                    <React.Fragment key={booking?.date}>
                      <h5 className={`${cx.titleBox}`}>
                        {bookingDate}
                        {isToday && <span className={`${cx.tagBox}`}>Today</span>}
                      </h5>
                      {filterBookings?.map((client: any) => {
                        const totalDuration = client?.services?.reduce((acc: any, val: any) => {
                          return Number.parseInt(val?.serviceId?.duration) + acc
                        }, 0)

                        const timeArr = client?.salectTime?.split(":");
                        const startMinutes = Number(timeArr?.[0]) * 60 + Number(timeArr?.[1])
                        const endMinutes = startMinutes + totalDuration

                        let formattedStartTime = convertMinutesToTime(startMinutes);
                        let formattedEndTime = convertMinutesToTime(endMinutes);

                        let assignTeam: any = [];
                        client?.services?.forEach((service: any) => {
                          if (Array.isArray(service?.TeamMemberId)) {
                            service?.TeamMemberId?.forEach((team: any) => {
                              if (assignTeam?.some((teams: any) => teams?._id === team)) {
                                return
                              }
                              const teamObj = teamList?.find((teamData: any) => teamData?._id === team)
                              assignTeam?.push(teamObj)
                            })
                          }
                        })

                        return (
                          <div
                            className={`${cx.bookingTable}`}
                            style={{ borderLeft: "15px solid #63A1E9" }}
                            key={client?._id}
                          >
                            <div className={`${cx.tableCell}`}>
                              <b>{formattedStartTime} - {formattedEndTime}</b>
                            </div>
                            <div className={`${cx.tableCell} ${cx.serviceList}`}>
                              {client?.services?.map((ser: any) => ser?.serviceDetails?.serviceName)?.join(", ")}
                            </div>
                            <div className={`${cx.tableCell} ${cx.teamList}`}>
                              {assignTeam?.map((team: any) => ` ${team?.firstName ? team?.firstName : ""} ${team?.lastName ? team?.lastName : ""}`)?.join(",")}
                            </div>
                            <div className={`${cx.tableCell}`}>{client?.finalPrice} USD</div>
                            {/* <div className={`${cx.tableCell}`}>
                          <Dropdown className={`${cx.dropDownList}`}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <MdOutlineMoreVert />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <ul>
                                <li>
                                  <NavLink href="#">Edit</NavLink>
                                </li>
                                <li>
                                  <NavLink href="#">Delete</NavLink>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}
                          </div>
                        )
                      })}
                    </React.Fragment>
                  )
                })}




                {/* <div
                  className={`${cx.bookingTable}`}
                  style={{ borderLeft: "15px solid #B842FF" }}
                >
                  <div className={`${cx.tableCell}`}>
                    <b>10:20 AM-11:20 AM</b>
                  </div>
                  <div className={`${cx.tableCell}`}>Freehand/Balayage</div>
                  <div className={`${cx.tableCell}`}>
                    <div className={`${cx.stylistCard}`}>
                      <img src={profile} alt="Profile" />
                      <div className={`${cx.stylistCardBody}`}>
                        <h5>Khrystyna Chepis</h5>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={phoneIcon} alt="phone" />
                        </button>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={emailIcon} alt="email" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={`${cx.tableCell}`}>75USD</div>
                  <div className={`${cx.tableCell}`}>
                    <Dropdown className={`${cx.dropDownList}`}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <MdOutlineMoreVert />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul>
                          <li>
                            <NavLink href="#">Edit</NavLink>
                          </li>
                          <li>
                            <NavLink href="#">Delete</NavLink>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <div
                  className={`${cx.bookingTable}`}
                  style={{ borderLeft: "15px solid #3E8575" }}
                >
                  <div className={`${cx.tableCell}`}>
                    <b>10:20 AM-11:20 AM</b>
                  </div>
                  <div className={`${cx.tableCell}`}>Freehand/Balayage</div>
                  <div className={`${cx.tableCell}`}>
                    <div className={`${cx.stylistCard}`}>
                      <img src={profile} alt="Profile" />
                      <div className={`${cx.stylistCardBody}`}>
                        <h5>Khrystyna Chepis</h5>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={phoneIcon} alt="phone" />
                        </button>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={emailIcon} alt="email" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={`${cx.tableCell}`}>75USD</div>
                  <div className={`${cx.tableCell}`}>
                    <Dropdown className={`${cx.dropDownList}`}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <MdOutlineMoreVert />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul>
                          <li>
                            <NavLink href="#">Edit</NavLink>
                          </li>
                          <li>
                            <NavLink href="#">Delete</NavLink>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <h5 className={`${cx.titleBox}`}>Friday 21st Mar, 2023</h5>
                <div
                  className={`${cx.bookingTable}`}
                  style={{ borderLeft: "15px solid #63A1E9" }}
                >
                  <div className={`${cx.tableCell}`}>
                    <b>10:20 AM-11:20 AM</b>
                  </div>
                  <div className={`${cx.tableCell}`}>Freehand/Balayage</div>
                  <div className={`${cx.tableCell}`}>
                    <div className={`${cx.stylistCard}`}>
                      <img src={profile} alt="Profile" />
                      <div className={`${cx.stylistCardBody}`}>
                        <h5>Khrystyna Chepis</h5>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={phoneIcon} alt="phone" />
                        </button>
                        <button className={`btn ${cx.btnAction}`}>
                          <img src={emailIcon} alt="email" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={`${cx.tableCell}`}>75USD</div>
                  <div className={`${cx.tableCell}`}>
                    <Dropdown className={`${cx.dropDownList}`}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <MdOutlineMoreVert />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul>
                          <li>
                            <NavLink href="#">Edit</NavLink>
                          </li>
                          <li>
                            <NavLink href="#">Delete</NavLink>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div> */}
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew3">
                <div className={`${cx.reviewCard}`}>
                  <h6 className="mb-3">24.03.2023, 10.00 AM-11.30 AM</h6>
                  <div className={`${cx.reviewCardBody}`}>
                    <img
                      src={teamPerson1}
                      alt="team"
                      className={`${cx.teamProfile}`}
                    />
                    <div className={`${cx.reviewCardBodyRight}`}>
                      <ul className={`${cx.contentUl}`}>
                        <li>
                          <span>Stylist:</span> Andrey Spark
                        </li>
                        <li>
                          <span>Total Spent:</span> $100
                        </li>
                        <li>
                          <span>Services:</span>{" "}
                          <b style={{ color: "#1AB192" }}>
                            Botox, French Nails
                          </b>
                        </li>
                      </ul>
                      <ul className={`${cx.startUl}`}>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                      </ul>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.
                      </p>
                      <div className={`${cx.author}`}>
                        <h5>
                          Anna Faust, <span>Manager Spa & Salon</span>
                        </h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>

      <EditClient
        showEditClient={showEditClient}
        handleEditClientClose={handleEditClientClose}
        page={"Package"}
      />
    </>
  );
};

export default ViewClient;
