import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { deleteIcon, services, userFrame } from "../../../assets/images";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { formatDate, formatTime } from "../../../constants/Constants";
import RescheduleService from "./rescheduleService";

const RescheduleBooking = (props: any) => {
  let { show, handleRescheduleBookingClose, data, getBooking, handleCustomerClose } = props;
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const [servicesArr, setServiceArr] = useState<any>([]);
  const [removePromotion, setRemovePromotion] = useState<any>([]);

  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [selectedSlot, setSelectedSlot] = useState<any>("")
  const [availableSlots, setAvailableSlots] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);


  let year = selectedDate?.getFullYear();
  let month = String(selectedDate?.getMonth() + 1).padStart(2, '0');
  let day = String(selectedDate?.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleButtonClick = (index: any, from: any) => {
    if (from === selectedSlot) {
      setSelectedSlot(null)
    } else {
      setSelectedSlot(from);
    }
  };

  let updateTime: any = Number(selectedSlot?.split(":")[0]) * 60 ? Number(selectedSlot?.split(":")[0]) * 60 + Number(selectedSlot?.split(":")[1]) : 0;

  const serviceData = servicesArr?.map((serviceItem: any) => {
    let time = Number(updateTime);
    const currService = data?.services?.find((ser: any) => ser?.serviceId?._id === serviceItem?.serviceId);
    updateTime += Number.parseInt(currService?.serviceId?.duration);
    const hours = Math.floor(time / 60);
    const minutes = Number(time % 60);
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    return {
      ...serviceItem,
      startTime: formattedTime,
    }
  })

  console.log(serviceData, "servcieDAta");


  const [promotionList, setPromotionList] = useState<any>([])

  const getPromotion = async () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (businessData) {
      let res = await httpRequest(`getPromotion?branchId=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
      if (res.status) {
        setPromotionList(res?.data);
      }
    }
  };

  useEffect(() => {
    getPromotion();
  }, [businessData?._id]);

  const appliedPromotion = promotionList
    ?.filter((data: any) => servicesArr?.find((service: any) => {

      return service?.promotionId && service?.promotionId === data?._id

    }))
    ?.flatMap((data: any) => data?.priceId)
    ?.filter((promo: any) => servicesArr?.some((selected: any) => selected?.promotionId && selected?.serviceId === promo?.id?._id));

  const selectedPromotions = servicesArr?.map((ser: any) => promotionList?.find((promo: any) => {

    return promo?._id === ser?.promotionId

  })?.priceId?.find((data: any) => data?.id?._id === ser?.serviceId));

  const promoServicePrice = selectedPromotions?.reduce((acc: any, data: any) => {
    if (data) {
      return Number(acc) + Number?.parseInt(data?.promotionalPrice)
    } else {
      return acc
    }
  }, 0)

  let ServicePrice = data?.services?.filter((data: any) => {
    return appliedPromotion?.length === 0 ? true : !appliedPromotion?.some((pro: any) => pro?.id?._id === data?.serviceId?._id)
  })
    ?.map((service: any) => {
      const freeServiceCheck = servicesArr?.some((selectedService: any) => selectedService?.serviceId === service?.serviceId?._id && selectedService?.radeemPackage)
      if (freeServiceCheck) {
        return "0"
      }
      if (service?.serviceId?.seasonPrice) {
        return service?.serviceId?.seasonPrice
      } else {
        return "0"
      }
    })?.reduce((acc: any, val: any) => {
      return Number(acc) + Number(val)
    }, 0)

  let totalPrice: any = `${ServicePrice + promoServicePrice}`;

  if (data?.otherChargeAmt) {
    totalPrice = Number.parseInt(totalPrice) + +data?.otherChargeAmt
  }

  let finalPrice: any = "0"
  if (data?.discount) {
    if (data?.discountType === "%") {
      finalPrice = `${Number.parseInt(totalPrice) - (Number.parseInt(totalPrice) * (+data?.discount / 100))}`
    } else {
      finalPrice = `${Number.parseInt(totalPrice) - +data?.discount}`
    }
  } else {
    finalPrice = totalPrice
  }


  let existPromotionArray: any = [];
  data?.services?.forEach((item: any) => {
    if (item?.promotionId?._id) {
      existPromotionArray.push(item?.promotionId?._id)
    }
  })

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const body = {
      businessId: businessData?._id,
      branchId: branchId,
      clientId: data?.Client?._id,
      clientUid: data?.clientUid,
      salectDate: formattedDate,
      salectTime: selectedSlot,
      services: serviceData,
      existPromotionArray,
      finalPrice: finalPrice,
      total: totalPrice,
      isPromotionCheck: true
    }
    setSubmitLoading(true)
    const res = await httpRequest(`updateBooking/${data?._id}`, 'PATCH', body, "json");
    if (res?.status) {
      getBooking && getBooking()
      handleRescheduleBookingClose && handleRescheduleBookingClose()
      handleCustomerClose && handleCustomerClose()
    }
    setSubmitLoading(false);
  }


  const fetchAvailableSlots = async () => {
    const serviceId = servicesArr?.map((item: any) => item?.serviceDetails?._id);
    const teamId = servicesArr?.map((item: any) => item?.TeamMemberId?.[0]);

    console.log(servicesArr , "servicesArr");
    
    const totalDuration: any = servicesArr?.reduce((acc: any, item: any) => acc + Number.parseInt(item?.serviceDuration), 0);
    let queryArr: any = servicesArr?.map((item: any) => {
      const sevicesDuration = data?.services?.find((ser: any) => ser?.serviceId?._id === item?.serviceId)
      return (
        {
          serviceId: item?.serviceDetails?._id,
          duration: Number.parseInt(sevicesDuration?.serviceId?.duration),
          teamMemberId: item?.TeamMemberId?.[0] || null,
          teamTitleId: sevicesDuration?.serviceId?.teamTitleId,
        }
      )
    })
    queryArr?.sort((a: any, b: any) => {
      if (a.serviceId === b.serviceId) {
        if (a.teamMemberId === null && b.teamMemberId !== null) {
          return 1;  // a comes after b
        } else if (a.teamMemberId !== null && b.teamMemberId === null) {
          return -1; // a comes before b
        } else {
          return 0; // no change in order
        }
      }
    });
    setLoading(true)
    let params = `businessId=${data?.Business?._id}&bookingId=${data?._id}&branchId=${data?.BranchLocation?._id}&teamMemberId=${JSON.stringify(teamId)}&startDate=${formattedDate}&endDate=${formattedDate}&serviceArray=${JSON.stringify(queryArr)}&serviceId=${JSON.stringify(serviceId)}&serviceDuration=${Number.parseInt(totalDuration)}`
    const response: any = await httpRequest(`getAvailableReschedulingSlots?${params}`, "GET", null, "json");

    if (response?.status) {
      setAvailableSlots(response?.data?.[0]?.result)
    }
    setLoading(false);
  }

  useEffect(() => {
    if (selectedDate && services?.length !== 0) {
      fetchAvailableSlots()
    }
  }, [selectedDate]);

  useEffect(() => {
    setServiceArr(servicesArr?.map((item: any) => {
      const removablePromo = removePromotion?.find((promo: any) => promo?.serviceId === item?.serviceId);
      return {
        ...item,
        promotionId: removablePromo?.promotionId === item?.promotionId ? null : item?.promotionId
      }
    }))

  }, [removePromotion])


  useEffect(() => {
    setSelectedDate(new Date(data?.salectDateInFormat))
    setSelectedSlot(data?.salectTime)
    const services = data?.services?.map((item: any) => {
      return (
        {
          ...item,
          TeamMemberId: [item?.TeamMemberId?.[0]?._id],
          promotionId: item?.promotionId ? item?.promotionId?._id : null,
          serviceId: item?.serviceId?._id,
          serviceDuration : item?.serviceId?.duration,
          bundleId: item?.bundleId?._id
        }
      )
    });
    setServiceArr(services)
  }, [data]);



  const addService = (type: any, value: any, index: any, _id: any, packageId: any = null) => {
    let updateService = [...servicesArr];
    const serviceIndex = updateService?.findIndex((service: any, i: any) => service?.serviceId === _id);

    if (type === "promotion") {
      updateService[serviceIndex] = {
        ...updateService[serviceIndex],
        promotionId: value === "" ? null : value
      }
    }
    setServiceArr(updateService)
  }

  const checkValidBookingTime = (slot: any) => {
    if (!slot?.from) {
      return
    }
    const [hours, minutes] = slot?.from?.split(":")
    const selectedYear = selectedDate?.getFullYear();
    const selectedMonth = selectedDate?.getMonth();
    const selectedDay = selectedDate?.getDate();
    const customDate = new Date(selectedYear, selectedMonth, selectedDay, hours, minutes)
    const today = new Date();

    return +customDate < +today
  }
  const filterAvailableSlot = availableSlots?.filter((item: any) => {
    if (checkValidBookingTime(item)) {
      return
    }
    return item
  })

  const bookingId = data ? `${data?.bookedBy === "online" ? data?.booking_Id || "" : data?.businessBookingId || ""}` : ""
  const formattedBookingId = bookingId ? bookingId?.includes("-") ? bookingId?.split("-")[1] : bookingId : ""


  return (
    <>
      <Modal
        centered
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleRescheduleBookingClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts} ${m.rescheduleBooking_pop}`}
      >
        <Modal.Header className="p-2">
          <Modal.Title>
            Reschedule Booking {`(${formattedBookingId})`}
          </Modal.Title>
          <button
            className={`${m.closeIcon} ${m.v2}`}
            onClick={handleRescheduleBookingClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body style={{ height: 'calc(100vh - 125px)', overflowY: 'auto', padding: 'auto' }} >
          <Row>
            <Col md={12}>
              <div className={`${m.addClient} ${m.noBorder}`}>
                <div className={m.left}>
                  <img src={userFrame} alt="user" />
                  <div className={m.userInfo}>
                    <h3>{data?.Client?.firstName || ""} {data?.Client?.lastName || ""} {data?.guestname ? `(${data?.guestname})` : ""}</h3>
                    {data?.Client?.email && <p>{data?.Client?.email}</p>}
                    {data?.Client?.mobile && <p>{data?.Client?.mobile}</p>}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {servicesArr?.map((item: any, i: number) => {
              const updatedITem = serviceData?.find((upItem: any) => upItem?.serviceId === item?.serviceId)
              const startTime = item?.startTime;
              const [hours, minutes] = startTime ? startTime?.split(":") : "00:00";
              const serviceItem = data?.services?.find((ser: any) => ser?.serviceId?._id === item?.serviceId)
              const endTime = (+hours * 60 + +minutes) + Number.parseInt(serviceItem?.serviceId?.duration)

              return (
                <RescheduleService setRemovePromotion={setRemovePromotion} servicesArr={servicesArr} formattedTime={updatedITem?.startTime} addService={addService} formattedDate={formattedDate} i={i} item={item} serviceItem={serviceItem} data={data} startTime={startTime} endTime={endTime} />
              )
            })}
          </Row>
          <Row>
            <Col sm={9} className="m-auto">
              <div className={`${m.date_timeBox_modal} `}>
                <h3>Select Date and Time</h3>
                <Calendar onChange={handleDateChange} value={selectedDate} minDate={new Date()} />
              </div>
            </Col>
            <Col sm={9} className="m-auto">
              <div className={`${m.avail_time} mt-4`}>
                <h4 className='text-center'>Available Time</h4>

                <div className={`${m.slotContainer} `}>
                  <Row>
                    {loading ? (<h5>Fetching time availability...</h5>) : filterAvailableSlot?.map((slot: any, i: number) => {
                      return (
                        <Col xl={4} lg={4} md={3} sm={6} xs={6} key={i} className="px-lg-2 pe-lg-1">
                          <button
                            type="button"
                            className={`btn ${selectedSlot === slot?.from ? 'active' : ''}`}
                            onClick={() => handleButtonClick(i, slot?.from)}
                          >
                            {slot?.from}
                          </button>
                        </Col>
                      )
                    })}

                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <div className={`${m.signUpButton} mt-lg-3 pb-sm-2 pb-lg-0`}>
            <Row>
              <Col md={12}>
                <button className="btn" onClick={handleSubmit} disabled={submitLoading}>Continue</button>
              </Col>
            </Row>
          </div>

          {/* <div className={`${m.logoutPopup}`}>
            <h3 className={`${m.completedConfirmation}`}>
              Appointment set to no-show
            </h3>
            <div className={`${m.btnsAlignments}`}>
              <NavLink
                className={`btn ${m.cancelBtn}`}
                to="#"
                onClick={handleRescheduleBookingClose}
              >
                Cancel
              </NavLink>
              <button
                type="button"
                className={`btn ${m.actionBtn}`}
                onClick={handleSubmit}
              >
                Ok
              </button>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer className="border-white">

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RescheduleBooking;
