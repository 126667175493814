import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import {
    call,
    deleteIcon,
    emailGreen,
    plusWhite,

} from "../../../assets/images";
import { disablePastDate } from "../../../Utils/Funcs";
import TimeField from "../../../constants/TimeField";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { arrayBuffer } from "stream/consumers";
import Confirm from "./Confirm";
import AddPackageModal from "./AddPackageModal";
import AddServiceModal from "./AddServiceModal";
import ListViewService from "./ListViewService";
import { quarterlyTimeValues } from "../../../constants/Constants";
import { v4 as uuidv4 } from 'uuid';

const ListViewRebook = (props: any) => {
    let { showRebook, handleRebookClose, getBookings, data: oldData } = props;
    const [couponShow, setCouponShow] = useState<boolean>(false);

    const [emptyError, setEmptyError] = useState(false);
    let { branchId } = useSelector((state: any) => state.BranchReducer);

    const [date, setDate] = useState("");
    const [time, setTime] = useState("");

    const { currentBranchData } = useSelector((state: any) => state.BranchReducer);
    const timestamp = new Date();
    const timeOnly = timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const currHour = Number(timeOnly?.split(":")[0])
    const currMinute = Number(timeOnly?.split(":")[1])

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const dateItem = new Date();
    const formatDate = `${dateItem?.getFullYear().toString()}-${dateItem?.getMonth() + 1 < 10 ? `0${dateItem?.getMonth() + 1}` : dateItem?.getMonth() + 1}-${dateItem?.getDate() < 10 ? `0${dateItem?.getDate()}` : dateItem?.getDate()}`
    const [selectDate, setSelectDate] = useState(formatDate);
    const dayOfWeek = date ? new Date(date)?.getDay() : new Date()?.getDay();
    const fullDayName = dayNames[dayOfWeek];
    let branchOpHrs = currentBranchData?.workingHours?.find((item: any) => item?.dayName === fullDayName);
    let branchStart = branchOpHrs?.from?.split(':');
    let branchEnd = branchOpHrs?.to?.split(':');

    let startTime: any = Number(time?.split(":")[0]) * 60 ? Number(time?.split(":")[0]) * 60 + Number(time?.split(":")[1]) : 0;
    const availableMiliSecond = currHour * currMinute;
    const [hide, setHide] = useState(false);
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [data, setData] = useState(() => {
        return oldData
    })
    const [notes, setNotes] = useState("");
    const [showPackage, setShowPackage] = useState(false);
    const handleAddPackageClose = () => setShowPackage(false);

    const [packageData, setPackageData] = useState<any>([]);
    const [packageeData, setPackageeData] = useState<any>([]);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [message, setMessage] = useState("")
    const handleSuccessModalClose = () => setShowSuccessModal(false);

    const [errorTrig, setErrorTrig] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [clientList, setClientList] = useState<any>([]);

    const [services, setServices] = useState<any>([]);
    const [serviceList, setServiceList] = useState<any>([])

    const [selectedServices, setSelectedServices] = useState<any>([])

    const [amenitiesList, setAmenitiesList] = useState<any>([])
    const [team, setTeam] = useState<any>([]);

    const [promotionList, setPromotionList] = useState<any>([]);

    const [showAddCategory, setAddCategoryShow] = useState(false);
    const handleAddCategoryClose = () => setAddCategoryShow(false);
    const handleAddCategoryShow = () => {
        setAddCategoryShow(true);
    };
    const [showClient, setShowClient] = useState(false);
    const handleAddClientClose = () => setShowClient(false);
    const handleAddClientShow = () => setShowClient(true);

    const [showProfile, setShowProfile] = useState(false);
    const handleProfileClose = () => setShowProfile(false);
    const handleProfileShow = () => setShowProfile(true);

    const fetchServices = async (id: any) => {
        let res = await httpRequest(
            `getBusinessPrice?branchId=${id}&businessId=${businessData?._id}&type=package&bookingTime=${time}&bookingDate=${date}`,
            "get",
            null,
            "json"
        );
        setServices(res?.data);
    };

    const getAmenities = async (id: any) => {
        const res = await httpRequest(`getAmenities?branchId=${id}&businessId=${businessData?._id}&dataForBooking=${true}`, "get", null, "json");
        if (res.status) {
            setAmenitiesList(res.data);
        }
    };

    const deleteService = (id: any, index: any) => {
        if (id) {
            if (data) {
                const filterOldService = data?.services?.filter((service: any) => service?.serviceId?._id !== id);
                setData({ ...data, services: filterOldService })
            }
            const filterService = serviceList?.filter((service: any) => service?._id !== id);
            const deletedServices = serviceList?.find((service: any) => service?._id === id);
            setServiceList(filterService)
            setTeam(team?.filter((data: any, i: any) => i !== index))
            const filterSelectedServices = selectedServices?.filter((service: any) => service?.serviceId !== id);
            setSelectedServices(filterSelectedServices)
            startTime -= Number.parseFloat(deletedServices?.duration)
        }
    }
    function deletePackage(index: any, packId: any) {
        const filterPackage = packageeData?.filter((val: any, i: any) => {
            return i !== index
        })

        // find service that was available in package so for removing from service list i first find it in selectedService array and remove it from service list 
        const filterCommonService = selectedServices?.filter((selectedService: any) => selectedService?.packageId === packId)?.map((service: any) => service?.serviceId);
        const filterService = serviceList?.filter((service: any) => !filterCommonService?.includes(service?._id));
        setServiceList(filterService);

        // delete pack services from selected services
        const filterPackService = selectedServices?.filter((service: any) => service?.packageId !== packId);
        setSelectedServices(filterPackService);
        setPackageeData(filterPackage)

        if (data && data?.services?.length !== 0) {
            const filterOldServices = data?.services?.filter((service: any) => service?.packageId?._id !== packId);
            setData({ ...data, services: filterOldServices })
        }
    }
    const addService = (type: any, value: any, index: any, serviceId: any, packageId: any = null) => {
        let updateService = [...selectedServices];
        const serviceIndex = updateService?.findIndex((service: any) => service?.serviceId === serviceId);
        const filterAmenities = updateService[serviceIndex]?.amenitiesId?.length === 0 && type !== "amenity" ? updateService[serviceIndex]?.amenitiesId : updateService[serviceIndex]?.amenitiesId?.filter((am: any) => am !== value)
        if (type === "team") {
            updateService[serviceIndex] = {
                ...updateService[serviceIndex],
                TeamMemberId: value ? [value] : []
            }
        }
        if (type === "radeemedPackage") {
            updateService[serviceIndex] = {
                ...updateService[serviceIndex],
                radeemPackage: value,
                packageId: packageId && value ? packageId : null,
            }
        }
        // if (type === "amenity") {
        //   updateService[serviceIndex] = {
        //     ...updateService[serviceIndex],
        //     amenitiesId: value ? [...filterAmenities, value] : []
        //   }
        // }
        if (type === "amenity") {
            updateService[serviceIndex] = {
                ...updateService[serviceIndex],
                amenitiesId: value ? value?.map((am: any) => am?.value) : []
            }
        }
        if (type === "promotion") {
            updateService[serviceIndex] = {
                ...updateService[serviceIndex],
                promotionId: value === "" ? null : value
            }
        }
        setSelectedServices(updateService)
    }

    useEffect(() => {
        setErrorTrig(false)
        const filterService = selectedServices?.filter((data: any) => !serviceList?.map((existData: any) => existData?._id)?.includes(data?.serviceId)) || [];
        setSelectedServices([...filterService, ...serviceList?.map((service: any, i: any) => {
            const serviceIndex = selectedServices?.map((data: any) => data?.serviceId)?.findIndex((data: any) => data === service?._id);
            return {
                serviceId: service?._id,
                TeamMemberId: selectedServices[serviceIndex]?.TeamMemberId && selectedServices[serviceIndex]?.TeamMemberId?.length !== 0 ? selectedServices[serviceIndex]?.TeamMemberId : [],
                amenitiesId: selectedServices[serviceIndex]?.TeamMemberId && selectedServices[serviceIndex]?.amenitiesId?.length !== 0 ? selectedServices[serviceIndex]?.amenitiesId : [],
                packageId: selectedServices[serviceIndex]?.packageId && selectedServices[serviceIndex]?.packageId?.length !== 0 ? selectedServices[serviceIndex]?.packageId : null,
                promotionId: selectedServices[serviceIndex]?.promotionId && selectedServices[serviceIndex]?.promotionId?.length !== 0 ? selectedServices[serviceIndex]?.promotionId : null,
                radeemPackage: selectedServices[serviceIndex]?.radeemPackage ? selectedServices[serviceIndex]?.radeemPackage : false
            }
        })])
    }, [serviceList])

    useEffect(() => {
        setErrorTrig(false)
        let insideServices: any = [];
        packageeData?.forEach((pack: any, i: any) => {
            pack?.Services?.forEach((service: any) => {
                insideServices?.push({ packageId: pack?._id, ...service })
            })
        })
        const filterService = selectedServices?.filter((data: any) => !insideServices?.map((existData: any) => existData?.priceId?._id)?.includes(data?.serviceId))
        if (insideServices?.length !== 0) {
            setSelectedServices([...filterService, ...insideServices?.map((service: any, i: any) => {
                const serviceIndex = selectedServices?.map((data: any) => data?.serviceId)?.findIndex((data: any) => data === service?.priceId?._id);
                return {
                    serviceId: service?.priceId?._id,
                    packageId: service?.packageId,
                    TeamMemberId: selectedServices[serviceIndex]?.TeamMemberId && selectedServices[serviceIndex]?.TeamMemberId?.length !== 0 ? selectedServices[serviceIndex]?.TeamMemberId : [],
                    amenitiesId: selectedServices[serviceIndex]?.amenitiesId && selectedServices[serviceIndex]?.amenitiesId?.length !== 0 ? selectedServices[serviceIndex]?.amenitiesId : [],
                    promotionId: !service?.packageId && selectedServices[serviceIndex]?.promotionId ? selectedServices[serviceIndex]?.promotionId : null,
                }
            })])
        }
    }, [packageeData]);

    const totalDuration = serviceList?.reduce((acc: any, service: any) => {
        return Number(acc) + Number.parseInt(service?.duration)
    }, 0)

    const fetchPakages = async (id: any) => {
        let res = await httpRequest(
            `getPackages?branchId=${id}`,
            "get",
            null,
            "json"
        );
        setPackageData(res.data);
    };

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const getPromotion = async () => {
        if (businessData) {
            let res = await httpRequest(`getPromotion?businessId=${businessData?._id}&timeZone=${userTimeZone}&bookingTime=${time}&bookingDate=${date}`, "get", null, "json");
            if (res.status) {
                setPromotionList(res?.data);
            }
        }
    };

    useEffect(() => {
        if (time && date) {
            getPromotion()
        }
    }, [businessData, time, date])

    useEffect(() => {

        if (branchId) {
            getAmenities(branchId)
            // getClientData(branchId);
            fetchServices(branchId);
            fetchPakages(branchId);
        }
    }, [branchId]);

    useEffect(() => {
        if (data) {

            setClientList([data?.Client]);
            const services = data?.services?.filter((allService: any) => !allService?.packageId)?.map((service: any) => {
                let timeWithDuration = startTime;
                startTime = startTime + Number.parseInt(service?.duration)
                return { service: { ...service?.serviceId, BusinessService: { ...service?.serviceDetails, globalAmenities: service?.globalAmenities, amenitiesDetails: service?.amenitiesDetails }, ServiceCategory: service?.serviceCategoryDetails, startTime: timeWithDuration, } }
            })
            setServiceList(services?.map((data: any) => data?.service))
            const packageInfo = data?.services?.filter((allService: any) => allService?.packageId)?.map((service: any) => service?.packageId)?.reduce((acc: any, val: any) => {
                if (acc?.some((pack: any) => pack?._id === val?._id)) {
                    return acc
                } else {
                    return [...acc, val]
                }
            }, []);

            setPackageeData(packageInfo)
            // setSelectedServices(data?.services?.map((data: any) => {
            //     return {
            //         serviceId: data?.serviceId?._id,
            //         TeamMemberId: data?.TeamMemberId[0]?._id ? data?.TeamMemberId?.map((team: any) => team?._id) : [],
            //         amenitiesId: data?.amenitiesId[0]?._id ? data?.amenitiesId?.map((am: any) => am?._id) : [],
            //         packageId: data?.packageId?._id ? data?.packageId?._id : null,
            //         promotionId: data?.promotionId?._id ? data?.promotionId?._id : null,
            //     }
            // }))
        }
    }, [data]);

    useEffect(() => {
        if (emptyError && date !== "" && time !== "" && clientList?.length !== 0) {
            setEmptyError(false)
        }
    }, [date, time, clientList]);

    function handleAddServices(serviceArr: any) {
        let transformServiceArray = serviceArr?.map((item: any, index: any) => {
            let timeWithDuration = startTime;
            startTime = startTime + Number.parseInt(item?.duration)
            return ({
                ...item,
                startTime: timeWithDuration,
            })
        })
        setServiceList(transformServiceArray);
    }

    useEffect(() => {
        if (serviceList?.length !== 0) {
            let transformServiceArray = serviceList?.map((item: any, index: any) => {
                let timeWithDuration = startTime;
                startTime = startTime + Number.parseInt(item?.duration)
                return ({
                    ...item,
                    startTime: timeWithDuration,
                })
            })
            setServiceList(transformServiceArray);
        }
    }, [time,
    ])


    /// 
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const handleConfirmModalClose = () => setShowConfirmModal(false);
    const [rebookData, setRebookData] = useState<any>({})
    const selectedPromotions = selectedServices?.map((ser: any) => promotionList?.find((promo: any) => promo?._id === ser?.promotionId)?.priceId?.find((data: any) => data?.id?._id === ser?.serviceId));
    const promoServicePrice = selectedPromotions?.reduce((acc: any, data: any) => {
        if (data) {
            return Number(acc) + Number?.parseInt(data?.promotionalPrice)
        } else {
            return acc
        }

    }, 0)


    const appliedPromotion = promotionList?.filter((data: any) => selectedServices?.some((service: any) => service?.promotionId && service?.promotionId === data?._id))?.flatMap((data: any) => data?.priceId)?.filter((promo: any) => selectedServices?.some((selected: any) => selected?.promotionId && selected?.serviceId === promo?.id?._id));

    let ServicePrice = serviceList?.filter((data: any) => {
        return appliedPromotion?.length === 0 ? true : !appliedPromotion?.some((pro: any) => pro?.id?._id === data?._id)
    })
        ?.map((service: any) => {
            const freeServiceCheck = selectedServices?.some((selectedService: any) => selectedService?.serviceId === service?._id && selectedService?.radeemPackage)
            if (freeServiceCheck) {
                return "0"
            }
            if (service?.seasonPrice) {
                return service?.seasonPrice
            } else {
                return "0"
            }
        })
        .reduce((acc: any, val: any) => {
            return Number(acc) + Number(val)
        }, 0)


    // let promoServicePrice = appliedPromotion?.length !== 0 ? appliedPromotion?.reduce((acc: any, data: any) => Number(acc) + Number?.parseInt(data?.promotionalPrice), 0) : 0
    let totalPrice = `${ServicePrice + promoServicePrice}`;


    const onsubmit = async (e: any) => {
        const checkEmpty = selectedServices?.some((data: any) => {
            return data?.TeamMemberId !== undefined && data?.TeamMemberId?.length === 0 || data?.amenitiesId?.length === 0
        });
        if (checkEmpty) {
            setErrorTrig(true)
            return
        }

        if (time === "" && date === "" && serviceList?.length === 0) {
            setErrorTrig(true)
            return
        }
        const finalService = selectedServices?.map((service: any) => {
            const currService = serviceList?.find((data: any) => data?._id === service?.serviceId);
            const hours = Math.floor(currService?.startTime / 60);
            const minutes = currService?.startTime % 60;
            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            return {
                ...service,
                startTime: formattedTime,
                bookingStatus: 'Upcoming',
                _id: uuidv4(),
            }
        })

        const finalData = {
            businessId: businessData?._id,
            branchId: branchId,
            clientId: clientList?.[0]?._id,
            clientUid: clientList?.[0]?.cuId,
            bookingStatus: "Upcoming",
            salectDate: date,
            salectTime: time,
            newBookingDuration: totalDuration,
            services: finalService,
            notes: notes,
            finalPrice: totalPrice
        }
        setRebookData(finalData);
        setShowConfirmModal(true)
    }
    return (
        <>
            <Confirm showConfirmModal={showConfirmModal} message={"Do you want to rebook?"} data={rebookData} handleConfirmModalClose={handleConfirmModalClose} getBookings={getBookings} handleRebookClose={handleRebookClose} />
            {showPackage && <AddPackageModal
                packageData={packageData}
                showAddCategory={showPackage}
                handleAddCategoryClose={handleAddPackageClose}

                setPackageeData={setPackageeData}

            />}
            {showAddCategory && <AddServiceModal
                showAddCategory={showAddCategory}
                handleAddCategoryClose={handleAddCategoryClose}
                service={services}
                existData={serviceList}
                packageData={handleAddServices}
                serviceType="service"
            />}
            <Modal
                centered
                show={showRebook}
                backdrop="static"
                keyboard={false}
                onHide={handleRebookClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`${m.modalCts} ${showConfirmModal || showAddCategory || showPackage ? m.hide : ""}`}
            >
                <Modal.Header className="p-2">
                    <Modal.Title>Rebook</Modal.Title>
                    <button
                        className={`${m.closeIcon} ${m.v2}`}
                        onClick={handleRebookClose}
                    >
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className={`${m.contactInfo}`}>
                        <div className={`${m.left}`}>
                            <div className={m.imageBox}>
                                <span>{`${data?.Client?.firstName[0]?.toLocaleUpperCase()}${data?.Client?.lastName[0]?.toLocaleUpperCase()}`}</span>
                            </div>
                            <div className={`${m.content}`}>
                                <h3>{`${data?.Client?.firstName} ${data?.Client?.lastName}`}</h3>
                                <p>{data?.Client?.country}</p>
                            </div>
                        </div>
                        <div className={`${m.right}`}>
                            <div className={m.imageBox}>
                                <a href={`tel:${data?.Client?.mobile}`}>
                                    <img src={call} alt="call" />
                                </a>
                            </div>
                            <div className={m.imageBox}>
                                <a href={`mailto:${data?.Client?.email}`}>
                                    <img src={emailGreen} alt="email" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={`${m.rebook} mt-4`}>
                        <Row>
                            <Col md={6}>
                                <div className={`${st.formBox}`}>
                                    <input
                                        type="date"
                                        className="form-control"
                                        min={disablePastDate()}
                                        // defaultValue={data?.salectDate}
                                        onChange={(e: any) => {
                                            setDate(e?.target?.value)
                                        }}
                                    />
                                    {errorTrig && date === "" && (
                                        <p className={"errorMessage"}>Please select a date</p>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.formBox}`}>
                                    <div className="position-relative">
                                        <select
                                            className="form-select"
                                            onChange={(e: any) => setTime(e.target.value)} value={time}
                                        >
                                            <option value="">Select</option>
                                            {quarterlyTimeValues?.map((item: any, i: any) => {
                                                const timeHour = Number(item?.value?.split(":")[0]) * 60
                                                const timeMinute = Number(item?.value?.split(":")[1]);

                                                const timeMillisecond = timeHour + timeMinute;

                                                const currentDate = new Date();
                                                // Get the current hour and minute
                                                const currentHour = currentDate.getHours();
                                                const currentMinute = currentDate.getMinutes();

                                                const currentTotalMinute = currentHour * 60 + currentMinute;
                                                const day = currentDate.getDate();
                                                const month = currentDate.getMonth() + 1;
                                                const year = currentDate.getFullYear();

                                                const todayCount = [day, month, year]?.reduce((acc: any, count: any) => acc * count, 1);
                                                const selectedDateCount = date?.split("-")?.[0] === "" ? 1 : date?.split("-")?.reduce((acc: any, count: any) => acc * Number(count), 1);
                                                const isTodayCheck = todayCount === selectedDateCount && timeMillisecond <= currentTotalMinute

                                                const availableStartHour = Number(branchStart?.[0]) * 60;
                                                const availableStartMinute = Number(branchStart?.[1]);
                                                const availableStartMilliSecond = availableStartHour + availableStartMinute

                                                const availableEndHour = Number(branchEnd ? branchEnd[0] : 0) * 60;
                                                const availableEndMinute = Number(branchEnd ? branchEnd[1] : 0);
                                                const availableEndMilliSecond = availableEndHour + availableEndMinute;

                                                const currDateInMm = new Date()?.getTime();


                                                if (timeMillisecond < availableStartMilliSecond || isTodayCheck || timeMillisecond > availableEndMilliSecond) {
                                                    return
                                                }

                                                return (
                                                    <option value={item.value} key={i}>
                                                        {item.label}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                        {/* {<TimeField onChange={(e: any) => setTime(e.target.value)} value={time} />} */}
                                        {/* <img src={clock} alt="shedule" className={m.clockIcon} /> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <div className={`${m.serviceSection} ${m.rebookScreen}`}>
                            <Col md={12}>
                                <h5 className={`${m.serviceHeading} mt-2 mb-2`}>Select Service</h5>
                            </Col>
                            {/* after service add  */}
                            {serviceList?.map((service: any, index: any) => {
                                return (
                                    <ListViewService totalDuration={totalDuration} service={service} data={data} addService={addService} amenitiesList={amenitiesList} index={index} promotionList={promotionList} selectedServices={selectedServices} time={time} date={date} oldData={oldData} deleteService={deleteService} errorTrig={errorTrig} selectedClient={clientList?.length !== 0 ? clientList[0] : {}} />
                                )
                            })}

                            <Col md={12}>
                                <button
                                    className={`${m.addTeamBtn} singleBtn`}
                                    onClick={() => {
                                        handleAddCategoryShow();
                                    }}
                                >
                                    <img src={plusWhite} alt="add" /> Add Service
                                </button>

                                {errorTrig && serviceList?.length === 0 && (
                                    <p className={"errorMessage"}>Please select a service</p>
                                )}
                            </Col>
                            {Number.parseInt(ServicePrice) + Number.parseInt(promoServicePrice) !== 0 && <div className={m.total} role="img">
                                <p>
                                    <p>Service Amount: {ServicePrice + promoServicePrice} USD</p>
                                </p>
                            </div>}


                            {packageeData?.map((packageInfo: any, index: any) => {
                                return <>
                                    <Col md={6}>
                                        <div className={m.packageAction}>
                                            <h3 className={m.packageName}>{packageInfo?.PackageName}</h3>
                                            <span>
                                                <img src={deleteIcon} alt="delete" className={`${m.deleteItem} ${m.packageVersion}`} onClick={() => deletePackage(index, packageInfo?._id)} />
                                            </span>
                                        </div>
                                    </Col>

                                    {packageInfo?.Services?.map((service: any, index: any) => {
                                        let newTeamList = team?.filter((teamData: any) => {
                                            const teams = teamData?.serviceId?.some((data: any) => {
                                                return data?.serviceName === service?.priceId?.serviceId?.serviceName
                                            });
                                            return teams
                                        });


                                        const currService = selectedServices?.find((ser: any) => ser?.serviceId === service?.priceId?._id);

                                        return (
                                            <Col md={12}>
                                                <div className={`${m.selectedServices}`}>
                                                    <div className={m.top}>
                                                        <div className={m.left}>
                                                            <p className={m.makup}>{service?.priceId?.serviceId?.serviceName}</p>
                                                            <p className={m.makeupTime}>{`${service?.priceId?.duration} ${`${service?.priceId?.seasonType && service?.priceId?.seasonType[0]?.toLocaleUpperCase()}${service?.priceId?.seasonType && service?.priceId?.seasonType?.split("Price")[0]?.slice(1)} Price`}`}</p>
                                                        </div>
                                                        <div className={m.middle}>
                                                            {/* <p className={m.cancel}>USD</p> */}
                                                            {/* <p className={m.usd}>{service?.priceId?.seasonPrice ? service?.priceId?.seasonPrice : 0} USD</p> */}
                                                        </div>
                                                        {/* <div className={m.right}>
                            <img src={deleteIcon} style={{ cursor: "pointer" }} alt="delete" onClick={() => deleteService(service?._id, index)} />
                          </div> */}
                                                    </div>
                                                    <div className={m.bottom}>
                                                        <div className={`${st.formBox} ${m.options}`}>
                                                            {newTeamList?.length === 0 && !loading ? (
                                                                <p className={"errorMessage"}>
                                                                    Team member is not available
                                                                </p>
                                                            ) : currService?.TeamMemberId?.[0] === null || currService?.TeamMemberId?.length === 0 && !loading && errorTrig ? (
                                                                <p className={"errorMessage"}>
                                                                    Please select a team member
                                                                </p>
                                                            ) : ""}
                                                            <select className="form-select" disabled={loading || newTeamList?.length === 0} onChange={(e: any) => addService("team", e.target.value, index, service?.priceId?._id)}>
                                                                {loading ? (
                                                                    <option value="">
                                                                        Checking Team Member's Availability
                                                                    </option>
                                                                ) : (
                                                                    <option value="">
                                                                        {newTeamList?.length === 0 ? "Team member is not available" : "Add a Team Member"}
                                                                    </option>
                                                                )}

                                                                {newTeamList?.map((team: any) => {
                                                                    return <option value={team?._id} key={team?._id}>{`${team?.firstName} ${team?.lastName}`}</option>
                                                                })}
                                                            </select>

                                                        </div>

                                                        <div className={`${st.formBox} ${m.options}`}>
                                                            {currService?.amenitiesId?.length === 0 && errorTrig && <p className={"errorMessage"}>
                                                                Please select a amenity
                                                            </p>
                                                            }
                                                            <select className="form-select" onChange={(e: any) => addService("amenity", e.target.value, serviceList?.length + index, service?.priceId?._id)}>
                                                                <option value="" selected>
                                                                    Select Amenities
                                                                </option>
                                                                {service?.priceId?.serviceId?.amenitiesId?.map((amenity: any, index: any) => {
                                                                    const filterAm = amenitiesList?.find((data: any) => data?._id === amenity)
                                                                    return (
                                                                        <option value={amenity} key={amenity}>{filterAm?.itemName}</option>
                                                                    )
                                                                })}
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </>
                            })}

                            {/* <Col md={12}>
                                <button
                                    className={`${m.addTeamBtn} singleBtn`}
                                    onClick={() => {
                                        setShowPackage(true)
                                    }}
                                >
                                    <img src={plusWhite} alt="add" /> Add Package
                                </button>


                            </Col> */}
                        </div>


                        {/* <Col md={12}>
                            {packageeData?.length !== 0 && <div className={m.priceType}>
                                <label className={`${st.checkbox}`}>
                                    <input type="checkbox" checked={packageType === "regular"} value="regular" onChange={(e: any) => setPackageType(e.target.value)} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className="ms-2">Package Regular Price</span>
                                </label>
                                <label className={`${st.checkbox}`}>
                                    <input type="checkbox" checked={packageType === "member"} value="member" onChange={(e: any) => setPackageType(e.target.value)} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className="ms-2">Package Member Price</span>
                                </label>
                            </div>}
                        </Col> */}
                        {/* <Col md={12}>
                            {packageeData?.length !== 0 && <div className={m.total} role="img">
                                <p>
                                    <p>Package Amount: {PackagePrice} USD</p>
                                </p>
                            </div>}
                        </Col> */}
                        <Col md={12}>
                            <div className={`${m.coupon} mt-3`}>
                                {couponShow ? (
                                    <div
                                        className={`${m.couponBox} flex-column align-items-start`}
                                    >
                                        {/* <label className={`form-label`}>
                      Branch Name
                    </label> */}
                                        <div
                                            className={`${m.couponInput} position-relative`}
                                        >
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Apply a coupon"
                                            />
                                            <button
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                ) : <p onClick={() => setCouponShow(true)}>I have a discount coupon</p>}
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={m.total} role="img">
                                <p>
                                    <p>Total Amount: {totalPrice} USD</p>
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <div className={`${m.gotButton}`}>
                        <button className={`${m.cancel}`} onClick={handleRebookClose}>Cancel</button>
                        <button onClick={onsubmit}>Rebook</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ListViewRebook;
