import React, { useEffect, useState } from 'react';
import styles from './index.module.scss'; // Import your Sass module
import st from '../../../assets/stylesheet/style.module.scss';
import { deleteIcon } from '../../../assets/images';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';

export default function SidebarService({ service, formatEndTime, deleteArray, setSelectedServices, bookingTime, type, setTotalServiceTeamAvailibility, formatStartTime, bundleId = "", formattedDate, selectedServices, amenitiesList, selectedClient, deleteService, selectedTeam, addServiceHandler, index, selectedTeamId, errorTrig }: any) {
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );

    const [promotionList, setPromotionList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState<any>([])

    // current service 
    const currService = selectedServices?.find((ser: any) => {
        if (type === "bundle") {
            return ser?.serviceId === service?._id && ser?.bundleId === bundleId
        } else {
            return ser?.serviceId === service?._id
        }
    }
    );
    const hours = formatStartTime ? formatStartTime?.split(":")?.[0] : "00"
    const minutes = formatStartTime ? formatStartTime?.split(":")?.[1] : "00"
    let totalMinute = Number.parseFloat(hours) * 60 + Number.parseFloat(minutes);

    console.log(formatStartTime, "formatStartTime", totalMinute);

    if (totalMinute % 60 !== 0) {
        totalMinute = totalMinute % 15 !== 0 ? totalMinute + 15 - totalMinute % 15 : totalMinute;
    }

    const startHours = Math.floor(Math.min(totalMinute / 60));
    const startMinutes = totalMinute % 60;
    const formatNewStartTime = `${startHours < 10 ? `0${startHours}` : startHours}:${startMinutes < 10 ? `0${startMinutes}` : startMinutes}`



    useEffect(() => {
        if (formatStartTime && !selectedTeamId) {
            getTeam()
        }
    }, [formatStartTime, deleteArray])

    useEffect(() => {
        if (type !== "bundle") {
            getPromotion();
        }
    }, [formatStartTime, selectedClient]);


    const getTeam = async () => {
        const duration = Number.parseInt(service?.duration)
        setLoading(true)
        const res = await httpRequest(`getAvailableTeamMember?businessId=${businessData?._id}&branchId=${branchId}&bookingTime=${bookingTime}&bookingDate=${formattedDate}&newBookingDuration=${duration}&startTime=${formatNewStartTime}&serviceId=${service?.BusinessService?._id}&teamTitleId=${service?.teamTitleId || ""}`, "get", null, "json");
        if (res?.status) {
            const availableTeam = res?.data?.filter((teamData: any) => {
                const teams = teamData?.serviceId?.some((data: any) => {
                    return data?.serviceName === service?.BusinessService?.serviceName
                });
                return teams
            })
            setTotalServiceTeamAvailibility((prev: any) => {
                if (prev?.priceId?._id !== service?._id && (bundleId ? prev?.bundleId !== bundleId : true)) {
                    const ohterServices = prev?.filter((item: any) => bundleId ? item?.priceId?._id !== service?._id && item?.bundleId !== bundleId : item?.priceId?._id !== service?._id)
                    return [...ohterServices, { priceId: service, teamAvailibility: availableTeam?.length, bundleId }]
                } else {
                    return prev
                }
            })


            setTeam(availableTeam)
        }

        setLoading(false);
    };


    const getPromotion = async () => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (businessData) {
            let res = await httpRequest(`getPromotion?branchId=${branchId}&businessId=${businessData?._id}&timeZone=${userTimeZone}&bookingTime=${formatStartTime?.replaceAll(" ", "")}&bookingDate=${formattedDate}&clientId=${selectedClient?.[0]?._id}`, "get", null, "json");
            if (res.status) {
                setPromotionList(res?.data?.filter((promo: any) => promo?.type === "service"));
            }
        }
    };
    // filter promotion by services 

    let promo: any = [];
    promotionList?.forEach(((pro: any) => {
        const promotionInServieCheck = pro?.priceId?.some((price: any) => !price?.bundleId && price?.id?._id === service?._id)
        if (!promotionInServieCheck) {
            return
        }

        let radeemedCount = +pro?.redeemCount;
        let clientUses = +pro?.clientUses;
        selectedServices?.forEach((serviceItem: any) => {
            if (serviceItem?.promotionId === pro?._id && serviceItem?.serviceId !== service?._id) {
                radeemedCount += 1
                clientUses += 1
            }
        });

        const checkMaxUserCritiria: any = !pro?.LimitNumberOfUses?.maxUser ? true : radeemedCount < pro?.LimitNumberOfUses?.maxUser;
        const isclientInLimit: any = !pro?.LimitUsePerClient ? true : clientUses < pro?.LimitUsePerClient;

        if (!isclientInLimit) {
            return
        }

        if (!checkMaxUserCritiria) {
            return
        }
        promo.push(pro)
    }))


    useEffect(() => {
        if (promo?.length === 0) {
            if (currService && currService?.promotionId && !promo?.some((item: any) => currService?.promotionId === item?._id)) {
                setSelectedServices((prev: any) => {
                    let updatedService = [...prev];
                    let existService = updatedService.find((item: any) => type === "bundle" ? item?.serviceId === service?._id && item?.bundleId === bundleId : item?.serviceId === service?._id)

                    if (existService) {
                        existService.promotionId = null
                    }
                    return updatedService
                })
            }
        }
    }, [promo])




    // servicebase team list

    let newTeamList = team

    // selected promotion find 
    const promoServices = promo?.flatMap((data: any) => data?.priceId)?.filter((data: any) => {
        return data?.id?._id === service._id
    })
    const appliedPromotion = promotionList?.find((promo: any) => promo?._id === currService?.promotionId)
    const selectedPromoServices = appliedPromotion?.priceId?.find((price: any) => price?.id?._id === currService?.serviceId)

    // selected amenities 
    const selectedAmenities = amenitiesList?.filter((am: any) =>
        currService?.amenitiesId?.includes(am?._id)
    );

    const packServices = selectedClient[0]?.packageService && selectedClient[0]?.packageService?.length !== 0 ? selectedClient[0]?.packageService?.find((packServices: any) => packServices?.serviceId === service?._id) : null;
    const remainingDuration = Number(packServices?.noOfServices) * 60 - packServices?.duration;

    const isAvailableInPack = packServices?.type === "duration" ? Number.parseInt(service?.duration) <= remainingDuration && packServices?.duration < Number(packServices?.noOfServices) * 60 : packServices?.type === "noOfServices" ? packServices?.usedCounter < Number(packServices?.noOfServices) : false;
    const radeemedCount = packServices?.type === "duration" ? remainingDuration : packServices?.type === "noOfServices" ? Number(packServices?.noOfServices) - packServices?.usedCounter : 0;

    let packService: any = [];

    if (selectedClient[0]?.packageService) {
        selectedClient[0]?.packageService.forEach((packServiceItem: any) => {
            const remainingDuration = Number(packServiceItem?.noOfServices) * 60 - +packServiceItem?.duration;
            const isAvailableInPack = packServiceItem?.type === "duration" ? Number.parseInt(service?.duration) <= remainingDuration && packServiceItem?.duration < Number(packServiceItem?.noOfServices) * 60 : packServiceItem?.type === "noOfServices" ? packServiceItem?.usedCounter < Number(packServiceItem?.noOfServices) : false;
            const radeemedCount = packServiceItem?.type === "duration" ? remainingDuration : packServiceItem?.type === "noOfServices" ? Number(packServiceItem?.noOfServices) - +packServiceItem?.usedCounter : 0;

            if (packServiceItem?.serviceId === service?._id && isAvailableInPack) {
                packService.push({ ...packServiceItem, radeemedCount });
            }
        });
    }

    const sameServices = selectedServices?.filter((ser: any, i: number) => ser?.serviceId === service?._id);

    return (
        <div className={styles.serviceBox} key={service?._id}>
            <div className={styles.info}>
                {/* <h2 className={styles.serviceName}>{service?.BusinessService?.serviceName} <span>{currService?.radeemPackage ? "- $0" : `${selectedPromoServices ? `$${selectedPromoServices?.promotionalPrice}` : service?.seasonPrice ? ` - $${service?.seasonPrice}` : " - $0"}`}</span></h2> */}
                <h2 className={styles.serviceName}>{service?.BusinessService?.serviceName} <span style={selectedPromoServices?.promotionalPrice ? { textDecoration: "line-through", color: "red" } : {}}>{`$${service?.seasonPrice}`}</span> {selectedPromoServices?.promotionalPrice && <span>{`$${selectedPromoServices?.promotionalPrice}`}</span>}</h2>
                <p className={styles.duration}>{`${formatStartTime} - ${formatEndTime} (${service?.duration})`} </p>
                {selectedTeamId && <p className={styles.duration}>{`${selectedTeam?.firstName} ${selectedTeam?.lastName} ${selectedTeam?.nickName ? `(${selectedTeam?.nickName})` : ""}`}</p>}
                {selectedAmenities?.length !== 0 && <p className={styles.duration}>
                    {selectedAmenities?.map((am: any) => {
                        // return <span key={am?._id}>{`${am?.itemName} `} <img src={crossRed} className={styles.deleteAmenity} alt="deleteIcon" onClick={() => deleteAmenity(service?._id, am?._id)}/></span>
                        return <span key={am?._id}>{`${am?.AmenitiesGlobalList?.itemName}, `} </span>
                    })}
                </p>}

                {newTeamList?.length === 0 && !selectedTeamId && !loading && (
                    <p className={"errorMessage"}>
                        Team member is not available
                    </p>
                )}
                {!selectedTeamId && <div className={`${st.formBox} ${styles.bookingInputBox}`}>
                    <select className="form-select" value={currService?.TeamMemberId?.[0]} disabled={loading || newTeamList?.length === 0} onChange={(e: any) => addServiceHandler("team", e.target.value, index, currService?.serviceId, null, "", bundleId)}>
                        {loading ? (
                            <option value="">
                                Checking Team Member's Availability
                            </option>
                        ) : (
                            <option value="">
                                {newTeamList?.length === 0 ? "Team member is not available" : "Add a Team Member"}
                            </option>
                        )}
                        {newTeamList?.map((team: any) => {
                            if (sameServices?.some((item: any) => item?.TeamMemberId?.[0] === team?._id && item?._id !== service?.id)) {
                                return
                            }
                            return <option value={team?._id} key={team?._id}>{`${team?.firstName} ${team?.lastName}`}</option>
                        })}
                    </select>
                </div>}




                {/* <div className={`${st.formBox} ${styles.bookingInputBox}`}>
                    <select
                        className={`${errorTrig && currService?.amenitiesId?.length === 0 ? styles.error : ""} form-select`}
                        onChange={(e: any) => addServiceHandler("amenity", e.target.value, index, service?._id)}
                    >
                        <option value="">Select Amenities</option>

                        {service?.BusinessService?.amenitiesId?.filter((am: any) => amenitiesList?.some((availableAmenity: any) =>
                            availableAmenity?.AmenitiesGlobalList?._id === am))
                            ?.map((amenity: any, index: any) => {
                                const globalId = amenitiesList?.find((am: any) => am?.AmenitiesGlobalList?._id === amenity);
                                if (currService?.amenitiesId?.includes(globalId?._id)) {
                                    return
                                }
                                const filterAm = amenitiesList?.find((data: any) => data !== null && data?.available !== "0" && data?.AmenitiesGlobalList?._id === amenity)

                                if (!filterAm) {
                                    return
                                }
                                return (
                                    <option value={filterAm?._id} key={amenity}>{filterAm?.AmenitiesGlobalList?.itemName}</option>
                                )
                            })}
                    </select>
                    {!currService?.radeemPackage && <select
                        className="form-select"
                        disabled={promo?.length === 0}
                        onChange={(e: any) => addServiceHandler("promotion", e.target.value, index, service?._id)}
                    >
                        {promo?.length === 0 ? <option value="">Promotion Unavailable</option> : <option value="">Apply Promotion</option>}
                        {promo?.map((promo: any) => <option key={promo?._id} value={promo?._id}>{promo?.promotionName}</option>)}

                    </select>}
                </div> */}
                <div className={`${st.formBox} ${styles.bookingInputBox}`}>
                    {packService?.length !== 0 && <select
                        className={`form-select`}
                        value={currService?.packageId}
                        onChange={(e: any) => addServiceHandler("radeemedPackage", e.target.value !== "", index, service?._id, e.target.value)}
                    >
                        <option value="">Apply Package</option>

                        {packService?.map((packageItem: any, index: any) => {
                            return (
                                <option key={index} value={packageItem?.packageId?.packageId}>{packageItem?.packageId?.packageName || packageItem?.packageId?.PackageName}</option>
                            )
                        })}
                    </select>}
                    {!currService?.radeemPackage && !bundleId && <select
                        className="form-select"
                        disabled={promo?.length === 0}
                        onChange={(e: any) => addServiceHandler("promotion", e.target.value, index, service?._id)}
                    >
                        {promo?.length === 0 ? <option value="">Promotion Unavailable</option> : <option value="">Apply Promotion</option>}
                        {promo?.map((promo: any) => <option key={promo?._id} value={promo?._id}>{promo?.promotionName}</option>)}

                    </select>}
                </div>
                {/* {isAvailableInPack && <div className={`${styles.packRadeem}`}>
                    <label className={`${st.checkbox} ${styles.checkPlace}`}>
                        <input type="checkbox" checked={currService?.radeemPackage} onChange={(e: any) => addServiceHandler("radeemedPackage", e.target.checked, index, service?._id, packServices?.packageId?.packageId)} />
                        <span className={`${st.checkmark} ${styles.check}`}></span>
                        <span className={`${styles.text}`}>This service is available in your package. {packServices?.type === "duration" ? `You can use it for (${radeemedCount} mins duration)` : `You can redeem it (${radeemedCount}) times`} Do you want to redeem it ?</span>
                    </label>
                </div>} */}

            </div>
            {!bundleId && <div className={styles.deleteAction}>
                <img src={deleteIcon} alt="delete" onClick={() => deleteService(service?._id)} />
            </div>}
        </div>
    )
}
