import React, { useEffect, useRef, useState } from 'react'
import { Col, Dropdown, Row, Tab } from 'react-bootstrap'
import { deleteIcon, editButton, iconLogoGrey } from '../../../../assets/images'
import { BsThreeDotsVertical } from 'react-icons/bs';
import st from "../../../../assets/stylesheet/style.module.scss";
import cx from '../index.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { httpRequest } from '../../../../Apis/commonApis';
import { v4 as uuidv4 } from 'uuid';
import { getBusinessData } from "../../../../redux_toolkit/reducer/shareDataReducer";
import { FiPlus } from 'react-icons/fi';
import { AddTeamTitle } from '../../Modals';
import LoadingBtn from '../../Buttons/LoadingBtn';
import HoverEyeContent from '../../HoverEyeContent/HoverEyeContent';
import { Opacity } from '@mui/icons-material';

export default function Pricing(props: any) {
    const [showTitle, setShowTitle] = useState(false);
    const handleTitleClose = () => {
        setShowTitle(false);
        getTitle();
    };
    const handleTitleShow = () => setShowTitle(true);
    const { editServiceData, handleStepsFormData, businessPriceData, getBusinessPrice } = props;
    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const [teamTitleList, setTeamTitleList] = useState<any>([])
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<any>({});
    const [customAllSrvboxes, setCustomAllSrvboxes] = useState<any>({});
    const [selectedPrice, setSelectedPrice] = useState<any>([])
    const [loading, setLoading] = useState<any>(false)
    const [priceError, setPriceError] = useState<any>(false);
    const [combinationError, setCombinationError] = useState<any>(false)
    const location = useLocation();
    const [deletedPriceId, setDeletedPriceId] = useState<any>([]);
    const navigate = useNavigate();
    const [updatedId, setUpdatedId] = useState<any>("");
    const [data, setData] = useState<any>("");
    const pricesRef: any = useRef<any>(null)
    const singlePricesRef: any = useRef<any>(null)

    const dispatch = useDispatch();

    const [showServicesNotes, setServicesNotesShow] = useState(false);
    const handleServicesNotesClose = () => {
        setData("");
        setServicesNotesShow(false)
    };
    const handleServicesNotesShow = () => setServicesNotesShow(true);

    let { businessData } = useSelector((state: any) => state.shareDataReducer);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control,
    } = useForm({
        defaultValues: {
            isPublish: true,
            duration: '',
            notes: '',
            priceText: '',
            priceType: 'fixed',
            seasonPrice: '',
            teamTitleId: 'All'
        }
    });

    useEffect(() => {
        if (businessPriceData) {
            const finalPrice = businessPriceData?.map((item: any) => ({
                duration: `${Number.parseInt(item?.duration)}`,
                notes: item?.notes || "",
                priceText: item?.priceText || "",
                isPublish: item?.isPublish,
                priceType: item?.priceType || "",
                seasonPrice: item?.seasonPrice,
                seasonType: item?.seasonType || "fixed",
                teamTitleId: item?.teamTitleId || "All",
                id: item?._id,
                _id: item?._id
            }))
            setSelectedPrice(finalPrice)
        }
    }, [businessPriceData])
    const [branchData, setBranchData] = useState<any>([])

    const getBranches = async () => {
        let res = await httpRequest(`getBranch?businessId=${businessData?._id}`, "get", null, 'json');
        if (res.status) {
            setBranchData(res.data);

        }
    }

    const getTitle = async () => {
        const res = await httpRequest(
            `getTeamTitle?businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setTeamTitleList(res?.data);
        }
    };

    useEffect(() => {
        getTitle()
        getBranches();
        if (businessData?.originalAssignServiceArray) {
            let assingedArr = businessData?.originalAssignServiceArray[0];
            setSelectedCheckboxes(assingedArr?.selectedCheckboxes);
            setCustomAllSrvboxes(assingedArr?.customAllSrvboxes);
        }
    }, [businessData?._id])

    const onsubmit = async (data: any) => {
        const filterPrice = updatedId ? selectedPrice?.filter((item: any) => item?.id !== updatedId) : selectedPrice;
        const checkCombination = filterPrice?.some((priceItem: any) => {
            return priceItem?.duration === data?.duration && priceItem?.priceText?.toLocaleLowerCase()?.split(" ")?.join("") === data?.priceText?.toLocaleLowerCase()?.split(" ")?.join("") && priceItem?.teamTitleId === data?.teamTitleId
        })

        if (checkCombination) {
            setCombinationError(true);
            if (selectedPrice?.length < 2 && selectedPrice?.length > 0) {
                singlePricesRef?.current?.scrollIntoView({ behaviour: 'smooth', block: 'start' })
            } else {
                pricesRef?.current?.scrollIntoView({ behaviour: 'smooth', block: 'start' })
            }
            return
        }
        const updatedPrice: any = selectedPrice?.find((item: any, i: number) => item?.id === updatedId);
        // if (updatedId && updatedPrice?._id) {
        //     setDeletedPriceId([...deletedPriceId, updatedPrice?._id]);
        // }
        const newData = { ...data, isPublish: data?.isPublish, teamTitleId: data?.teamTitleId ? data?.teamTitleId : "All", priceType: data?.priceType ? data?.priceType : "fixed", seasonType: "regularPrice", id: uuidv4() }
        if (updatedId && businessPriceData?.some((item: any) => item?._id === updatedId)) {
            const res = await httpRequest(`updateBusinessPrice/${updatedId}`, "POST", { ...newData, duration: `${newData?.duration} mins`, teamTitleId: newData?.teamTitleId !== "All" ? data?.teamTitleId : null }, "json");
            if (res.status) {
                getBusinessPrice()
                reset()
                setValue("duration", "")
                setValue("notes", "")
                setValue("priceText", "")
                setValue("priceType", "fixed")
                setValue("seasonPrice", "")
                setValue("isPublish", true)
                setValue("teamTitleId", "All")
                setUpdatedId("")

                setCombinationError(false);
            }
            return
        }
        if (selectedPrice?.length < 2 && selectedPrice?.length > 0) {
            singlePricesRef?.current?.scrollIntoView({ behaviour: 'smooth', block: 'start' })
        } else {
            pricesRef?.current?.scrollIntoView({ behaviour: 'smooth', block: 'start' })
        }
        setSelectedPrice([...filterPrice, newData]?.map((item: any) => ({ ...item, seasonPrice: `${Number.parseFloat(item?.seasonPrice)?.toFixed(2)}` })));
        reset()
        setValue("duration", "")
        setValue("notes", "")
        setValue("priceText", "")
        setValue("priceType", "fixed")
        setValue("seasonPrice", "")
        setValue("isPublish", true)
        // setValue("seasonType", "")
        setValue("teamTitleId", "All")
        setUpdatedId("")

        setCombinationError(false);

    }
    const handlePriceSave = async (e: any) => {
        e.preventDefault();

        if (selectedPrice?.length === 0) {
            setPriceError(true);
            return;
        }
        let srvCatId = editServiceData?.ServiceCategory?._id ? editServiceData?.ServiceCategory?._id : editServiceData?.ServiceCategory;
        const newSelectedPrice = selectedPrice?.filter((price: any) => !price?._id);

        const newLocal = {
            businessId: businessData?._id,
            serviceId: location?.state?.serviceId,
            branchId: editServiceData?.branchId?.map((item: any) => item?._id),
            serviceCatagoryId: srvCatId,
            //   offPeakHours: offPeakHours,
            array: newSelectedPrice?.map((item: any, index: number) => ({
                duration: `${item?.duration} mins`,
                season: [{ ...newSelectedPrice?.[index], duration: `${item?.duration} mins`, teamTitleId: newSelectedPrice?.[index]?.teamTitleId === "All" ? null : newSelectedPrice?.[index]?.teamTitleId }]

            })),

            deletePrices: deletedPriceId
        };
        const data = newLocal;
        setLoading(true)
        const res = await httpRequest("createBusinessPrice", "post", data, "json");
        if (res.status) {
            handlePriceSelectionFromBranch(srvCatId, location.state?.serviceId, res?.data)

            // handleOneServiceToAllBranch(srvCatId, location.state?.serviceId, res?.data)

        } else {
            setLoading(false);
        }
    };

    const handlePriceSelectionFromBranch = (ServCatId: string, ServId: string, priceData: any) => {
        let assingedArr = businessData?.originalAssignServiceArray?.[0];
        if (priceData) {
            let updatedCheckboxes: any = assingedArr ? { ...assingedArr.selectedCheckboxes } : {}
            let selectAllCheckboxes: any = assingedArr ? { ...assingedArr.customAllSrvboxes } : {}

            branchData?.forEach((branch: any) => {

                priceData?.forEach((price: any) => {
                    updatedCheckboxes[branch?._id] = { ...updatedCheckboxes[branch?._id] };

                    updatedCheckboxes[branch?._id][ServCatId] = { ...updatedCheckboxes[branch?._id][ServCatId] };
                    selectAllCheckboxes[ServCatId] = { ...updatedCheckboxes[branch?._id][ServCatId], checked: selectAllCheckboxes[ServCatId]?.checked };

                    updatedCheckboxes[branch?._id][ServCatId][ServId] = { ...updatedCheckboxes[branch?._id][ServCatId][ServId], checked: true };
                    selectAllCheckboxes[ServCatId][ServId] = { ...updatedCheckboxes[branch?._id][ServCatId][ServId], checked: true };

                    updatedCheckboxes[branch?._id][ServCatId][ServId][price._id] = true;
                    selectAllCheckboxes[ServCatId][ServId][price._id] = { ...updatedCheckboxes[branch?._id][ServCatId][ServId][price._id], checked: true };
                })
            });

            let updatedAssignedArr = [
                {
                    selectedCheckboxes: updatedCheckboxes,
                    customAllSrvboxes: selectAllCheckboxes,
                    allServicesChecked: assingedArr?.allServicesChecked ? assingedArr?.allServicesChecked : true
                }
            ];

            changeAssignArray(updatedAssignedArr);
        } else {
            setLoading(false)
            handleStepsFormData(priceData, "assign-branch");
        }
    };

    const changeAssignArray = async (updatedObj: any) => {
        let body = {
            originalAssignServiceArray: updatedObj
        };
        const res = await httpRequest(`assignBusinessService?businessId=${businessData?._id}&fromBranch=${true}`, "PATCH", body, "json");
        if (res.status) {

            let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
            if (userdata !== '' && userdata?._id) {
                let bid = '';
                if (userdata?.userType === "teamMember") {
                    bid = `businessId=${userdata?.businessId}`;
                } else {
                    bid = `id=${userdata?._id}`;
                }
                dispatch(getBusinessData({ id: bid }));
            }
            setLoading(false)
            handleStepsFormData(res, "assign-branch");
        }
    }

    const handleDelete = (index: any, priceId: any = null) => {
        const filterPrice = selectedPrice?.filter((item: any, i: number) => i !== index);
        if (priceId) {
            setDeletedPriceId([...deletedPriceId, priceId]);
        }

        setSelectedPrice(filterPrice)
    }
    const handleEdit = (index: any) => {
        const filterPrice: any = selectedPrice?.find((item: any, i: number) => i === index);
        // if (filterPrice?._id) {
        //     setDeletedPriceId([...deletedPriceId, filterPrice?._id]);
        // }
        setUpdatedId(filterPrice?.id)
        setValue("duration", filterPrice?.duration ? filterPrice?.duration : "")
        setValue("notes", filterPrice?.notes ? filterPrice?.notes : "")
        setValue("priceText", filterPrice?.priceText ? filterPrice?.priceText : "")
        setValue("priceType", filterPrice?.priceType ? filterPrice?.priceType : "")
        setValue("isPublish", filterPrice?.isPublish)
        setValue("seasonPrice", filterPrice?.seasonPrice ? `${Number.parseInt(filterPrice?.seasonPrice)}` : "")
        // setValue("seasonType", filterPrice?.seasonType)
        setValue("teamTitleId", filterPrice?.teamTitleId ? filterPrice?.teamTitleId : "")
    }

    const handleCopy = (index: any) => {
        const filterPrice = selectedPrice?.find((item: any, i: number) => i === index);
        setValue("duration", filterPrice?.duration ? filterPrice?.duration : "")
        setValue("notes", filterPrice?.notes ? filterPrice?.notes : "")
        setValue("priceText", filterPrice?.priceText ? filterPrice?.priceText : "")
        setValue("priceType", filterPrice?.priceType ? filterPrice?.priceType : "")
        setValue("seasonPrice", filterPrice?.seasonPrice ? `${Number.parseInt(filterPrice?.seasonPrice)}` : "")
        // setValue("seasonType", filterPrice?.seasonType)
        setValue("teamTitleId", filterPrice?.teamTitleId ? filterPrice?.teamTitleId : "")
    }

    const skipStep = (e: any) => {
        e.preventDefault();
        handleStepsFormData("prevStep", "assign-branch");
    }

    const filterTeamTitleList = teamTitleList?.filter((titleItem: any) => titleItem?.customerService)
    const canCreateTitle = subModuleRoles?.['Teams']?.['Team Title']?.Create;
    return (
        <React.Fragment>
            {showTitle && <AddTeamTitle showTitle={showTitle} handleTitleClose={handleTitleClose}
                getTitle={getTitle} businessData={businessData} />}
            <div className={`${st.offcanvas_para}`}>
                <Col md={10}>
                    <p className="paraOff">You can add multiple price variations under a single service.
                        These prices will be visible to customers on the Wellnezza website unless you deselect
                        the "Publish on Wellnezza" option </p>
                </Col>
            </div>
            <Col md={10}>
                <Row>
                    <Col md={6}>
                        <div className={`${st.reFormBox}`}>
                            <label htmlFor="">Team Title</label>
                            <select className="form-select" {...register("teamTitleId")}>
                                <option value="All">All</option>
                                {filterTeamTitleList?.map((item: any) => {
                                    return (
                                        <option value={item?._id} key={item?._id}>{item?.teamTitle}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className={`${st.reFormBox}`}>
                            <label htmlFor="">Service options/Descriptions <HoverEyeContent number={9068} /></label>
                            <input type="text" placeholder="Enter options/Descriptions" className="form-control" {...register("priceText")} />
                        </div>
                    </Col>
                </Row>
                {canCreateTitle && <Row>
                    <Col md={12}>
                        <div className=" mb-3">
                            <button className={`${st.addServe}`} onClick={handleTitleShow}><FiPlus /> Add Team Title <HoverEyeContent number={9068} /></button>
                        </div>
                    </Col>
                </Row>}
            </Col >
            <Col md={10}>
                <Row>
                    <Col md={4}>
                        <div className={`${st.reFormBox}`}>
                            <label htmlFor="">Duration (in MIN)</label>
                            <input type="number" placeholder="Enter Duration" className="form-control" {...register("duration", { required: true })} />
                            {errors?.duration?.type === "required" && (
                                <p className={"errorMessage"}>This field is required</p>
                            )}
                        </div>
                    </Col>
                    <Col md={8}>
                        <Row>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Price From / Fixed</label>
                                    <select className="form-select" {...register("priceType")}>
                                        <option value="fixed">Fixed</option>
                                        <option value="from">From</option>
                                    </select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Price</label>
                                    <input type="number" placeholder="Enter Price" className="form-control" {...register("seasonPrice", { required: true })} />
                                    {errors?.seasonPrice?.type === "required" && (
                                        <p className={"errorMessage"}>This field is required</p>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col md={10}>
                <div className={`${st.reFormBox}`}>
                    <label htmlFor="">Notes on Price </label>
                    <input type="text" placeholder="Enter Service Type" className="form-control" {...register("notes")} />
                </div>
            </Col>
            <Row style={{ paddingTop: '12px' }}>
                <Col md={6}>
                    <div className={`${st.iconLogo_Para}`}>
                        <span className={`${st.iconPara}`}>Publish on Wellenezza</span>
                        <span className={`${st.iconLogo_checkBox} `}>
                            <span className={`${st.borderBox} `}>
                                <label className={`d-inline-flex ${st.checkbox}`}>
                                    <input type="checkbox" {...register('isPublish')} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1`}> <img src={iconLogoGrey} alt="icon" /></span>
                                </label>
                            </span>
                        </span>
                    </div>
                    &nbsp;<HoverEyeContent number={9068} />

                </Col>
                <Col md={2} className="m-auto mb-3">
                    <div>
                        <button className="btn w-100" style={{ backgroundColor: '#7C7C7C', color: '#fff', borderRadius: '10px', minWidth: '90px' }} onClick={handleSubmit(onsubmit)}>
                            {updatedId ? "Update" : "Add"}
                        </button>
                    </div>
                </Col>
            </Row>
            {
                combinationError && <Row className="mb-1">
                    <Col>
                        <p className={"errorMessage"}>This combination is already exist try different one</p>
                    </Col>
                </Row>
            }
            {
                priceError && selectedPrice?.length === 0 && <Row className="mb-1">
                    <Col>
                        <p className={"errorMessage"}>Please add a price</p>
                    </Col>
                </Row>
            }
            <div ref={pricesRef}>
                <Row>
                    <Col md={12}>
                        <div className={`${st.tableCollapse}`} style={selectedPrice?.length === 0 ? { opacity: '0' } : { marginTop: '46px' }}>
                            <table className="table" >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Team Title</th>
                                        <th scope="col">Service Type</th>
                                        <th scope="col">Duration (min)</th>
                                        <th scope="col">Price (SGD)</th>
                                        <th scope="col">From / Fixed</th>
                                        <th scope="col">Note</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedPrice?.map((item: any, i: number) => {
                                        const teamTitleObj = teamTitleList?.find((titleObj: any) => titleObj?._id === item?.teamTitleId)
                                        return (
                                            <tr key={i}>
                                                <td>{teamTitleObj?.teamTitle || "All"}</td>
                                                <td>{item?.priceText || "No price text"}</td>
                                                <td>{item?.duration?.includes('mins') ? item?.duration : `${item?.duration} mins`}</td>
                                                <td>{item?.seasonPrice}</td>
                                                <td>{item?.priceType}</td>
                                                <td>{item?.notes || "No notes"}</td>
                                                <td>
                                                    <span className={`${st.dotsDropDown}`}>
                                                        <Dropdown>
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                <BsThreeDotsVertical />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <span className='editDeleteBtn'>
                                                                    <button className="btn"><img src={editButton} alt="edit" onClick={() => handleEdit(i)} /></button>
                                                                    <button className="btn"><img src={deleteIcon} alt="delete" onClick={() => handleDelete(i, item?._id)} /></button>
                                                                </span>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>
            {selectedPrice?.length < 2 && <div ref={singlePricesRef} style={{ height: '50px', width: '100%', overflow: 'auto' }} role='img'></div>}
            <div className={`${cx.offcanvas_footer}`}>
                <Row>
                    <Col md={12}>
                        <div className={`${st.formBox} ${cx.formSign}`}>
                            <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                                <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={handlePriceSave}>
                                    {loading ? <LoadingBtn /> : 'Save'}
                                </button>
                                {/* <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} onClick={() => {
                                    handleStepsFormData(null, 'amenities')
                                }}
                                    type="submit">
                                    Cancel
                                </button> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment >
    )
}
