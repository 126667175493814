import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

import { Col, Nav, Row, Tab } from "react-bootstrap";
import { basicInfo, basicInfoA, location } from "../../../assets/images";
import BackArrrow from "../../../components/Business/Buttons/BackArrow";
import PhoneNumber from "../../../components/Business/Phonenumber/Phonenumber";

import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import { NavLink, useNavigate } from "react-router-dom";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import "react-phone-input-2/lib/style.css";
import { useForm, Controller } from "react-hook-form";
import { httpRequest } from "../../../Apis/commonApis";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

const BranchClient = (props: any) => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const Location = useLocation();
  const [errorTrig, setErrorTrig] = useState(false);
  const [apiError, setApiError] = useState<any>("");
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const [newValue, setnewValue] = useState("");
  const navigate = useNavigate();
  const [moble, setMoble] = useState("");
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<any>(false);
  const handleSuccessModalClose = () => setShowSuccessModal(false);
  const [message, setMessage] = useState("");
  const locationData: any = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (locationData?.state) {
      reset();
      setIsUpdate(true);
      setValue("gender", locationData?.state?.gender);
      setValue("guestclient", locationData?.state?.guestclient)
      setnewValue(locationData?.state?.mobile ? `+${locationData?.state?.mobile?.toString()}` : "")
    }
  }, [isUpdate, businessData]);

  const onSubmit = async (data: any) => {
    if (newValue === undefined) {
      setErrorTrig(true)
      return
    }

    if (newValue && !isValidPhoneNumber(newValue)) {
      setErrorTrig(true)
      return
    }
    let body = {
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: newValue ? newValue : undefined,
      guestclient: data?.guestclient,
      email: data.email,
      dateOfBirth: data.dob,
      gender: data.gender,
      country: data.country,
      state: data.state,
      city: data.city,
      branchId: branchId,
      businessId: businessData?._id,
      postalCode: data.postalCode,
      adderss1: data.address1,
      adderss2: data.address2,
      landmark: data.landmark,
    };

    if (isUpdate) {
      const updateData = {
        firstName: data.firstName,
        lastName: data.lastName,
        branchId: branchId,
        businessId: businessData?._id,
        mobile: newValue,
        email: data.email,
        dateOfBirth: data.dob,
        guestclient: data?.guestclient,
        gender: data.gender,
        country: data.country,
        state: data.state,
        city: data.city,
        postalCode: data.postalCode,
        adderss1: data.address1,
        adderss2: data.address2,
        landmark: data.landmark,
      };
      const res = await httpRequest(
        `updateClient/${locationData?.state?._id}`,
        "PATCH",
        updateData,
        "json"
      );
      if (res.status === true) {
        setMessage(res.message);
        setShowSuccessModal(true);
        setApiError("");
        setTimeout(() => {
          navigate(-1);
        }, 2000)
      } else {
        setApiError(res.message)
      }
    } else {
      const res = await httpRequest("createClient", "post", body, "json");
      if (res.status === true) {
        setMessage(res.message);
        setShowSuccessModal(true);
        setApiError("")
        setTimeout(() => {
          navigate(-1);
        }, 2000)
      } else {
        setApiError(res.message)
      }
    }
  };

  const currBranchId = localStorage.getItem("currentBranchId");
  useEffect(() => {
    if (branchId !== currBranchId)
      navigate("/business/branch-clients", { replace: true })
  }, [branchId])


  return (
    <>
    
      <section className={`${st.pageWrapper}`}>
      <Col md={12}>
                  <div className="text-end">
                    <HelpButon number={20} type={"help"} className={"btn"} />
                  </div>
                </Col>
        <SuccessPopup
          showSuccessModal={showSuccessModal}
          handleSuccessModalClose={handleSuccessModalClose}
          message={message}
        // path={"/clients"}
        />
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {" "}
              {isUpdate ? `${locationData?.state?.firstName || ""} ${locationData?.state?.lastName || ""}` : "Add a New Client"}
            </h5>
          </div>
          <div className={`${st.rightInfo}`}>
            {Location.pathname.includes('edit-client') && <div style={{ color: "#3e8575" }}>Edit Mode</div>}
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
                  <Row className="align-items-center">
                    
                    <div className="mb-2 col-md-12">
                      <h5 className="mb-1">Basic Information</h5>
                    </div>
                    <Col md={6} lg={4}>
                      <div className={`${st.formBox} position-relative`}>
                        <label className="form-label">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          defaultValue={locationData.state?.firstName}
                          {...register("firstName", {
                            required: true,
                          })}
                        />

                        {errors?.firstName?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )}
                      </div>
                    </Col>

                    <Col md={6} lg={4}>
                      <div className={`${st.formBox} position-relative`}>
                        <label className="form-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          defaultValue={locationData?.state?.lastName}
                          {...register("lastName", {
                            // required: true,
                          })}
                        />

                        {/* {errors?.lastName?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>

                    <Col md={6} lg={4}>
                      <div className={`${st.formBox} position-relative`}>
                        <label className="form-label">
                          Mobile Number
                        </label>
                        <div className={`${st.phoneBox}`}>
                          <PhoneInput
                            defaultCountry="SG"
                            international
                            placeholder="Enter phone number"
                            value={newValue}
                            onChange={(e: any) => { setnewValue(e) }}
                            error={newValue ? (isValidPhoneNumber(newValue) ? undefined : 'Invalid phone number') : 'Phone number required'}
                          />
                        </div>
                        {newValue && <p className={"errorMessage out"}>{newValue && isValidPhoneNumber(newValue) ? '' : 'Invalid phone number'}</p>}
                        {/* {errorTrig && !newValue && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>
                    <Col md={6} lg={4}>
                      <div className={`${st.formBox} position-relative`}>
                        <label className="form-label">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          defaultValue={locationData?.state?.email}
                          {...register("email", {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                        />


                        {apiError && (
                          <p className={"errorMessage out"}>
                            {apiError}
                          </p>
                        )}
                        {errors?.email?.type === "pattern" && (
                          <p className={"errorMessage out"}>
                            Invalid email address
                          </p>
                        )}
                        {errors?.email?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col md={6} lg={3}>
                      <div className={`${st.formBox} position-relative`}>
                        <label className="form-label">Date of Birth</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder=""
                          defaultValue={locationData?.state?.dateOfBirth}
                          {...register("dob", {
                            // required: true,
                          })}
                          max={new Date().toISOString().split("T")[0]}
                        />

                        {/* {errors?.dob?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div>
                        <label className={`d-block ${st.checkbox}`}>
                          <input type="checkbox" {...register("guestclient", {

                          })} />
                          <span className={`${st.checkmark}`}></span>
                          <span className="ms-2">Walk-in</span>
                        </label>
                      </div>

                    </Col>
                    <Col md={12} lg={12}>
                      <div className={`${st.formBox} position-relative`}>
                        <label className="form-label">Gender</label>
                        <ul className={`${st.listUl} ${cx.radioBtn}`}>
                          <li>
                            <label className={`${st.radio}`}>
                              <input
                                type="radio"
                                value="male"
                                {...register("gender", {
                                  required: true,
                                })}
                              />
                              <span className={`${st.checkmark}`}></span> Male
                            </label>
                          </li>
                          <li>
                            <label className={`${st.radio}`}>
                              <input
                                type="radio"
                                value="female"
                                {...register("gender", {
                                  required: true,
                                })}
                              />
                              <span className={`${st.checkmark}`}></span> Female
                            </label>
                          </li>
                          <li>
                            <label className={`${st.radio}`}>
                              <input
                                type="radio"
                                value="preferNotToSay"
                                {...register("gender", {
                                  required: true,
                                })}
                              />
                              <span className={`${st.checkmark}`}></span> Prefer
                              not to say
                            </label>
                          </li>
                        </ul>

                        {errors?.gender?.type === "required" && (
                          <p className={"errorMessage out"}>
                            Please select a gender
                          </p>
                        )}
                      </div>
                    </Col>

                    <div className="mb-2 col-md-12 mt-4">
                      <h5 className="mb-1">Address</h5>
                    </div>
                    <Col lg={3} md={6}>
                      <label className="form-label">Country</label>
                      <div className={`${st.formBox} position-relative`}>
                        {/* <label className="form-label">Country</label> */}
                        {/* <select className="form-select">
                        <option value="india">India</option>
                      </select> */}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          defaultValue={locationData?.state?.country}
                          {...register("country", {
                            // required: true,
                            pattern: /^[A-Za-z\s]+$/,
                          })}
                        />

                        {errors?.country?.type === "pattern" && (
                          <p className={"errorMessage out"}>
                            Only alphabets are allowed.
                          </p>
                        )}
                        {/* {errors?.country?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <label className="form-label">State</label>
                      <div className={`${st.formBox} position-relative`}>
                        {/* <label className="form-label">State</label>
                        <select className="form-select">
                          <option value="rajasthan">Rajastan</option>
                        </select> */}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="State"
                          defaultValue={locationData?.state?.state}
                          {...register("state", {
                            // required: true,
                            pattern: /^[A-Za-z\s]+$/,
                          })}
                        />

                        {errors?.state?.type === "pattern" && (
                          <p className={"errorMessage out"}>
                            Only alphabets are allowed.
                          </p>
                        )}
                        {/* {errors?.state?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <label className="form-label">City</label>
                      <div className={`${st.formBox} position-relative`}>
                        {/* <label className="form-label">City</label>
                        <select className="form-select">
                          <option value="jaipur">Jaipur</option>
                        </select> */}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="City"
                          defaultValue={locationData?.state?.city}
                          {...register("city", {
                            // required: true,
                            pattern: /^[A-Za-z\s]+$/,
                          })}
                        />

                        {errors?.city?.type === "pattern" && (
                          <p className={"errorMessage out"}>
                            Only alphabets are allowed.
                          </p>
                        )}
                        {/* {errors?.city?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className={`${st.formBox} position-relative`}>
                        <label className="form-label">Postal Code</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Postal Code"
                          defaultValue={locationData?.state?.postalCode}
                          {...register("postalCode", {
                            // required: true,
                            pattern: /[0-9a-zA-Z]{6,}/,
                          })}
                        />

                        {errors?.postalCode?.type === "pattern" && (
                          <p className={"errorMessage out"}>
                            minimum six character required .
                          </p>
                        )}
                        {/* {errors?.postalCode?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={`${st.formBox} ${cx.dateBox} position-relative`}>
                        <label className="form-label">Address 1</label>
                        <input
                          type="text"
                          className={`${cx.addressInput} form-control`}
                          placeholder="Address 1"
                          defaultValue={locationData?.state?.adderss1}
                          {...register("address1", {
                            // required: true,
                          })}
                        />

                        <img
                          src={location}
                          alt="location-icon"
                          className={cx.locationIcon}
                        />
                        {/* {errors?.address1?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={`${st.formBox} ${cx.dateBox} position-relative`}>
                        <label className="form-label">Address 2</label>
                        <input
                          type="text"
                          className={`${cx.addressInput} form-control`}
                          placeholder="Address2"
                          defaultValue={locationData?.state?.adderss2}
                          {...register("address2", {
                            // required: true,
                          })}
                        />

                        <img
                          src={location}
                          alt="location-icon"
                          className={cx.locationIcon}
                        />
                        {/* {errors?.address2?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={`${st.formBox} position-relative`}>
                        <label className="form-label">Landmark</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Landmark"
                          defaultValue={locationData?.state?.landmark}
                          {...register("landmark", {
                            // required: true,
                          })}
                        />
                        {/* {errors?.landmark?.type === "required" && (
                          <p className={"errorMessage out"}>
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                          <button
                            className={`btn ${st.btn1}`}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(-1);
                            }}
                          >
                            Back
                          </button>
                          <button type="submit" className={`btn ${st.btn4}`} onClick={(e: any) => {


                            if (newValue !== undefined && !isValidPhoneNumber(newValue)) {
                              setErrorTrig(true)

                            }
                          }}>
                            {isUpdate ? "Update" : "Save"}
                          </button>
                          {/* <button type="submit">Save</button> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Tab.Pane>
              {/* 
              <Tab.Pane eventKey="tabNew2">
                
                <div className={`${st.buttonsAction} ${st.buttonflexDiv} justify-content-end`}>
                  <div className={`${st.threeButton} ${cx.threeBtn}`}>
                    <ul className={`${cx.bookingFilter}`}>
                      <li>
                        <button className={`${st.btn} ${st.active}`}>Upcoming</button>
                      </li>
                      <li>
                        <button className={`${st.btn}`}>Completed</button>
                      </li>
                      <li>
                        <button className={`${st.btn}`}>Cancelled</button>
                      </li>
                      <li>
                        <button className={`${st.btn}`}>No Show</button>
                      </li> 
                    </ul>
                  </div>
                </div>

                <div className={`${st.tableBody}`}>
                  <div className={`${st.tableBodyIn}`}>
                  <div className={`${cx.bookingTable}`}>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Time</th>
                          <th>Services</th>
                          <th>Stylist</th>
                          <th>Price</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className={`${cx.timeCard}`}>
                              <p>Wed, 30.04.2023</p>
                              <h6>10:20 AM - 10:20 AM </h6>
                            </div>
                          </td>
                          <td>
                            <p className={`${cx.serviceCard}`}>Balayage, <br/>
                              Hair Cut, <br/> 
                              French Nails
                            </p>
                          </td>
                          <td>
                            <div className={`${cx.stylistCard}`}>
                              <img src={profile} alt="Profile" />
                              <h5>Khrystyna Chepis</h5>
                            </div>
                          </td>
                          <td>75 USD</td>
                          <td>
                            <Dropdown className={`${cx.dropDownList}`}>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <MdOutlineMoreVert />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <ul>
                                  <li><NavLink href="#">Edit</NavLink></li>
                                  <li><NavLink href="#">Delete</NavLink></li>
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <div className={`${cx.timeCard}`}>
                              <p>Wed, 30.04.2023</p>
                              <h6>10:20 AM - 10:20 AM </h6>
                            </div>
                          </td>
                          <td>
                            <p className={`${cx.serviceCard}`}>Balayage, <br/>
                              Hair Cut, <br/> 
                              French Nails
                            </p>
                          </td>
                          <td>
                            <div className={`${cx.stylistCard}`}>
                              <img src={profile} alt="Profile" />
                              <h5>Khrystyna Chepis</h5>
                            </div>
                          </td>
                          <td>75 USD</td>
                          <td>
                            <Dropdown className={`${cx.dropDownList}`}>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <MdOutlineMoreVert />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <ul>
                                  <li><NavLink href="#">Edit</NavLink></li>
                                  <li><NavLink href="#">Delete</NavLink></li>
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <div className={`${cx.timeCard}`}>
                              <p>Wed, 30.04.2023</p>
                              <h6>10:20 AM - 10:20 AM </h6>
                            </div>
                          </td>
                          <td>
                            <p className={`${cx.serviceCard}`}>Balayage, <br/>
                              Hair Cut, <br/> 
                              French Nails
                            </p>
                          </td>
                          <td>
                            <div className={`${cx.stylistCard}`}>
                              <img src={profile} alt="Profile" />
                              <h5>Khrystyna Chepis</h5>
                            </div>
                          </td>
                          <td>75 USD</td>
                          <td>
                            <Dropdown className={`${cx.dropDownList}`}>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <MdOutlineMoreVert />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <ul>
                                  <li><NavLink href="#">Edit</NavLink></li>
                                  <li><NavLink href="#">Delete</NavLink></li>
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew3">
                <div className={`${cx.reviewCard}`}>
                   <h6 className="mb-3">24.03.2023, 10.00 AM-11.30 AM</h6>
                   <div className={`${cx.reviewCardBody}`}>
                    <img src={teamPerson1} alt="team" className={`${cx.teamProfile}`} />
                    <div className={`${cx.reviewCardBodyRight}`}>
                      <ul className={`${cx.contentUl}`}>
                        <li><span>Stylist:</span> Andrey Spark</li>
                        <li><span>Total Spent:</span> $100</li>
                        <li><span>Services:</span> <b style={{ color:'#1AB192' }}>Botox, French Nails</b></li>
                      </ul>
                      <ul className={`${cx.startUl}`}>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                      </ul>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      <div className={`${cx.author}`}>
                        <h5>Anna Faust, <span>Manager Spa & Salon</span></h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                    </div>
                   </div>
                </div>
              </Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default BranchClient;
