import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { httpRequest } from "../../Apis/commonApis";
import FadeLoader from "react-spinners/FadeLoader";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessCategory, getBusinessData } from "../../redux_toolkit/reducer/shareDataReducer";

const UpdateBusinessAdmin = (props: any) => {
  const { UID, BID, TOKEN } = useParams();
  let [userData, setUserData] = useState<any[]>([]);
  let [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  let AdminToken: any = TOKEN
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    localStorage.setItem('user_token', AdminToken);
    getBusinessUser();
    if (UID) {
      dispatch(getBusinessData({id: `businessId=${UID}`}));
      dispatch(getBusinessCategory());
    }
  }, [TOKEN])

  const getBusinessUser = async () => {
    let res = await httpRequest(`getprofile?id=${UID}`, "get", null, "json");
    if (res.status) {
      let bData = res?.data[0];
      setUserData(bData);
      localStorage.setItem('user_data', JSON.stringify(bData));
      setLoading(false);
      navigate('/business/business-setting/basic/info')
    }
  };
  return (
    <>
      <FadeLoader loading={loading} margin={2} width={5} color="#36d7b7" style={{ position: "absolute", top: "50%", left: "50%" }} />

    </>
  );
};

export default UpdateBusinessAdmin;
