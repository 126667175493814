import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import st from "../../../assets/stylesheet/style.module.scss";
import "react-quill/dist/quill.snow.css";
import { Col, Row } from "react-bootstrap";

export default function ViewOpHrs(props: any) {
    const { type } = props
    const datalocation: any = useLocation();
    let state: any = datalocation?.state;
    const navigate = useNavigate();

    const [hoursArr, setHrsArr] = useState<any>([]);

    useEffect(() => {
        setHrsArr(type === 'Working' ? state?.workingHours : state?.offPeakHours);
    }, []);


    return (
        <>
            <Row>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                        <h2>{type} Hours</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                {type === 'Working' && hoursArr?.map((item: any, index: number) => {
                    return (
                        <Col lg={3} md={6} key={index}>
                            <div className={cx.timeSelect}>
                                <div className={`${st.formBox}`}>
                                    <label className="form-label">{item.dayName}</label>
                                    <div
                                        className={`position-relative d-flex align-items-center gap-5 ${cx.inputBackground}`}
                                    >
                                        <div className={cx.editContainer}>
                                            <p>{item.from ? (item.from == "Not applicable" ? "N/A" : item.from) : "not set"}</p>
                                        </div>
                                        <div className={cx.editContainer}>
                                            <p>{item.to ? (item.to == "Not applicable" ? "N/A" : item.to) : 'not set'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })}
                {type !== "working" && hoursArr?.map((item: any, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            {item?.isSameEveryDay && <Row>
                                <Col md={6}>
                                    <div
                                        className={`${cx.everyDaySelector} d-flex gap-3 mt-2`}
                                    >
                                        <div className={`${st.formBox}`}>
                                            <select className="form-select" disabled defaultValue={item?.from}>
                                                <option value="00:00">
                                                    {item?.from ? item?.from : "not selected"}
                                                </option>
                                            </select>
                                        </div>

                                        <div className={`${st.formBox}`}>
                                            <select className="form-select" disabled defaultValue={item?.to}>
                                                <option value="00:00">
                                                    {item?.to ? item?.to : "not selected"}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                </Col>
                            </Row>}
                            <Row>
                                {item?.shift?.map((data: any, i: number) => {
                                    return (
                                        <Col lg={3} md={6} key={i}>
                                            <div className={cx.timeSelect}>
                                                <div className={`${st.formBox}`}>
                                                    <label className="form-label">{data?.dayName}</label>
                                                    <div
                                                        className={`position-relative d-flex align-items-center gap-5 ${cx.inputBackground}`}
                                                    >
                                                        <div className={cx.editContainer}>
                                                            <p>{data.from ? (data.from == "Not applicable" ? "N/A" : data.from) : "not set"}</p>
                                                        </div>
                                                        <div className={cx.editContainer}>
                                                            <p>{data.to ? (data.to == "Not applicable" ? "N/A" : data.to) : 'not set'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}

                            </Row>
                        </React.Fragment>
                    )
                })}

                {hoursArr?.length === 0 && (
                    <p style={{ fontSize: "17px", textAlign: "center" }}>{type === 'Working' ? "Please enter working hours (if applicable)" : "No off-peak hours"}</p>
                )}
            </Row >
            
            <Row>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => navigate(-1)}
                            >
                                Back
                            </button>

                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
