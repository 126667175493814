import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "./SuccessPopup";
import HelpButon from "../HelpButon.tsx/HelpButton";
import HoverEyeContent from "../HoverEyeContent/HoverEyeContent";
import MultiSelect2 from "../MultiSelect/MultiSelect2";
import { convertTimeToMinute, shiftValues, timeValues, weekValues } from "../../../constants/Constants";
import Select from 'react-select';
import { SubmitHandler } from "react-hook-form";
import { copyClipBoard } from "../../../assets/images";
interface FormValues {
    [key: string]: string;
}

function AddWorkingTeams(props: any) {
    let { show, handleAddWorkingTeamsClose, branchList, selectedBranch, watch, Controller, control, handleShiftSwitchChange, handleSubmit, setAddBranchErr, errors, clearErrors, addBranchErr, assignedBranches, editAsinedBrData, filteredBranchList, setEditAsinedBrData, setEditAsinedBrIndex, setEveryDaySwitch, setAssignedBranches, setValue, setSelectedTeamTitles, setSelectedRole, register, editTeamData, selectedRole, editAsinedBrIndex, everyDaySwitch, setRoleError } = props;

    const onFormSubmit: SubmitHandler<FormValues> = (data: any) => {
        setAddBranchErr(false);
        setRoleError(false);
        let shiftData: any = [];
        let sameEvDayShifts: any = []
        shiftValues.forEach(element => {
            sameEvDayShifts.push(
                {
                    shiftNo: element.label,
                    from: data[`${element.label}From`],
                    to: data[`${element.label}to`],
                });
            let workingHrs: any = [];
            weekValues.forEach((elem) => {
                workingHrs.push({
                    day: elem.label,
                    from: data[`${elem.label}${element.label}From`],
                    to: data[`${elem.label}${element.label}to`],
                })
            });
            shiftData.push(
                {
                    shiftNo: element.label,
                    workingHours: workingHrs,
                })
        });
        let selectedBranch = branchList.find((item: any) => item._id === data.branchId);
        let assignedArr = [...assignedBranches];

        const filterSelectedRole = selectedRole?.find((item: any) => item?.branchId === selectedBranch?._id);

        const filterExistRole = editTeamData?.Role?.find((item: any) => item?.branch?._id === selectedBranch?._id);

        const formattedRole = {
            branchName: selectedBranch?.branchName,
            accessManagerId: filterExistRole?.accessManagerId && filterExistRole?.accessManagerId?.length > 0 ? filterExistRole?.accessManagerId : filterSelectedRole?.accessManagerId ? filterSelectedRole?.accessManagerId : [],
            accessManager: filterExistRole?.accessManager && filterExistRole?.accessManager?.length > 0 ? filterExistRole?.accessManager : filterSelectedRole?.accessManager ? filterSelectedRole?.accessManager : [],
            branchId: selectedBranch?._id,
            role: selectedRole?.[0]?.role
        }

        // if (selectedTeamTitles?.length === 0) {
        //     setRoleError(true)
        //     return
        // }

        const filterUnSelectedRole = selectedRole?.filter((item: any) => item?.branchId !== selectedBranch?._id && item?.branchId)
        setSelectedRole([...filterUnSelectedRole, formattedRole])
        setSelectedTeamTitles([])
        let assignedObj = {
            branchName: selectedBranch.branchName,
            branchId: data.branchId,
            isSameEveryday: {
                status: everyDaySwitch,
                shift: sameEvDayShifts
            },
            shift: shiftData,
        }
        if (editAsinedBrIndex >= 0) {
            assignedArr.splice(editAsinedBrIndex, 1);
            assignedArr.splice(editAsinedBrIndex, 0, assignedObj);
        } else {
            assignedArr.push(assignedObj);
        }
        shiftValues?.forEach(elem => {
            setValue(`${elem.label}From`, '');
            setValue(`${elem.label}to`, '');
            weekValues.forEach(item => {
                setValue(`${item.label}${elem.label}From`, '');
                setValue(`${item.label}${elem.label}to`, '');
            });
        });
        setValue('branchId', '');
        setAssignedBranches(assignedArr);
        setEveryDaySwitch(false);
        setEditAsinedBrIndex(-1);
        setEditAsinedBrData(null);
        handleAddWorkingTeamsClose()
        // reset();
    };
    const selectedBranchOpHrs = selectedBranch?.workingHours;

    // function handleshiftValChange() {
    //     if (everyDaySwitch) {
    //         shiftValues?.forEach(elem => {
    //             let fromVal = watch(`${elem.label}From`, '');
    //             let toVal = watch(`${elem.label}to`, '');
    //             weekValues.forEach(item => {
    //                 if (fromVal) setValue(`${item.label}${elem.label}From`, fromVal);
    //                 if (toVal) setValue(`${item.label}${elem.label}to`, toVal);
    //             });
    //         });
    //     }
    // }
    
    function handleCancel() {
        shiftValues?.forEach(elem => {
            setValue(`${elem.label}From`, '');
            setValue(`${elem.label}to`, '');
            weekValues.forEach(item => {
                setValue(`${item.label}${elem.label}From`, '');
                setValue(`${item.label}${elem.label}to`, '');
            });
        });
        setValue('branchId', '');
        setEveryDaySwitch(false);
        setEditAsinedBrIndex(-1);
        setEditAsinedBrData(null);
        handleAddWorkingTeamsClose()
        setSelectedTeamTitles([])
    }
    useEffect(() => {
        const selectedExistBranchTime = assignedBranches?.find((shiftBranchItem: any) => shiftBranchItem?.branchId === watch('branchId'));
        let selectedShift: any = null
        if (selectedExistBranchTime) {
            selectedShift = selectedExistBranchTime?.shift[0]
        }
        selectedBranchOpHrs?.forEach((item: any) => {
            const selectedDay = selectedShift?.workingHours?.find((shif: any) => shif?.day === item?.dayName);
            setValue(`${item?.dayName}shift1From`, selectedDay?.from || item?.from)
            setValue(`${item?.dayName}shift1to`, selectedDay?.to || item?.to)
            // setValue(`${item?.dayName}shift2From`, '')
            // setValue(`${item?.dayName}shift2to`, '')
        })
    }, [selectedBranchOpHrs])


    return (
        <>
            <Modal
                centered
                show={show}
                backdrop="static"
                keyboard={false}
                onHide={handleAddWorkingTeamsClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`${m.modalCts} ${m.redesign_modalCts}  ${m.workingModal_width}`}
            >
                <Modal.Header>
                    <Modal.Title>
                        Working Hours
                    </Modal.Title>
                    <button
                        className={`${m.closeIcon}`}
                        onClick={handleCancel}
                    >
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body className="py-2">
                    <Row className="g-0 align-items-center" >
                        {/* <Col md={5}>
                            <div className={`${st.reFormBox}`}>
                                <label htmlFor="">Role</label>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    options={teamTitleList}
                                    value={selectedTeamTitles}
                                    onChange={handleSelectChange}
                                />
                                {roleError && selectedTeamTitles?.length === 0 && (
                                    <p className={"errorMessage"}>Please add roles</p>
                                )}
                            </div>
                        </Col> */}
                        <Col sm={4} style={{ width: '30%' }}>
                            <div className={`${st.reFormBox}`}>
                                <label className="mb-0" htmlFor="">Assign Branch/Location</label>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className={`${st.reFormBox}`}>
                                {/* <label htmlFor="">Assign Location</label> */}
                                <select name="" id="" className='form-select'  {...register("branchId", {
                                    required: true,
                                })}>
                                    <option value="">--Select--</option>
                                    {editAsinedBrIndex >= 0 && <option value={editAsinedBrData.branchId} selected>{editAsinedBrData.branchName}</option>}
                                    {filteredBranchList?.map((branch: any) => {
                                        return (
                                            <option value={branch._id} key={branch._id}>{branch.branchName}</option>
                                        )
                                    })}
                                </select>
                                {errors.branchId?.type === "required" && (
                                    <p className={"errorMessage"}>This field is required</p>
                                )}
                                {addBranchErr && (
                                    <p className={"errorMessage"}>Please add atleast one branch</p>
                                )}
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={`${st.newRedesign_main} `}>
                                <div className={`${st.branchContact_tabBox} ${st.branchContact_tabBox2} `} >
                                    <ul className={`${st.branchOperating_tab_line}`}>
                                        <li className={`${st.branchOperatingLine_left}`}>
                                            <div className={`${st.branchLine_content}`}>Working hours</div>
                                        </li>
                                        <li className={`${st.branchOperatingLine_right}`}>
                                            <div className={`${st.branchLine_content}`}>Shift 1</div>
                                            <div className={`${st.branchLine_content}`}>Shift 2</div>
                                        </li>
                                    </ul>

                                    <ul className={`${st.branchOperating_tab_InputMian}`}>
                                        <li>
                                            <div className={`${st.firstBox} ${st.copyAllDays}`}>
                                                <label className={`d-inline-flex ${st.checkbox}`}>
                                                    <input type="checkbox"
                                                    //  checked={everyDaySwitch}
                                                    //     onChange={handleShiftSwitchChange}
                                                    />
                                                    <span className={`${st.checkmark}`}></span>
                                                    <span className={`${st.labelName} ms-0`}>Copy to <br /> all days</span>
                                                </label>
                                            </div>
                                            <div className='d-flex gap-2'>
                                                {shiftValues?.map((shift: any, s: any) => {
                                                    return (
                                                        <React.Fragment key={s}>
                                                            <div className={`${st.secondBox}`}>
                                                                <div className={`${st.inoutArea}`}>
                                                                    <label>From</label>
                                                                    <Controller
                                                                        name={`${shift.label}From`}
                                                                        control={control}
                                                                        rules={{ required: false }}
                                                                        render={({ field }: any) => {
                                                                            let filterTimeValue = shiftValues?.[s - 1] ? timeValues?.filter((item: any) => item?.value >= watch(`${shiftValues?.[s - 1]?.label}to`)) : timeValues
                                                                            return (
                                                                                <select className="form-select" value={field.value} onChange={field.onChange}>
                                                                                    <option value="">-Select-</option>
                                                                                    {filterTimeValue?.map((item: any) => {
                                                                                        return (
                                                                                            <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            )
                                                                        }
                                                                        }
                                                                    />

                                                                </div>
                                                                {errors[`${shift.label}From`]?.type === "required" && (
                                                                    <span className="errorMessage">required*</span>
                                                                )}
                                                            </div>
                                                            {/* <div className={`${st.secondBox}`} onClick={handleshiftValChange}> */}
                                                            <div className={`${st.secondBox}`}>
                                                                <div className={`${st.inoutArea}`}>
                                                                    <label>To</label>
                                                                    <Controller
                                                                        name={`${shift.label}to`}
                                                                        control={control}
                                                                        rules={{ required: false }}
                                                                        render={({ field }: any) => {
                                                                            let filteredTimeValues: any = []
                                                                            filteredTimeValues = shiftValues?.[s - 1]?.label ? timeValues?.filter((time: any) => time?.value >= watch(`${shiftValues?.[s - 1]?.label}to`) && time.value >= watch(`${shift.label}From`, '')) : timeValues?.filter((time: any) => time.value >= watch(`${shift.label}From`, ''))
                                                                            // if (everyDaySwitch) {
                                                                            // }
                                                                            return (
                                                                                <select className="form-select" value={field.value} onChange={field.onChange}>
                                                                                    <option value="">-Select-</option>
                                                                                    {filteredTimeValues?.map((item: any) => {
                                                                                        return (
                                                                                            <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            )
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                                {errors[`${shift.label}to`]?.type === "required" && (
                                                                    <span className="errorMessage">required*</span>
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>

                                            <div className={`${st.lastCopyClip}`}>
                                                <button onClick={() => handleShiftSwitchChange(true)}>
                                                    <img src={copyClipBoard} alt="copy" />
                                                </button>
                                            </div>

                                        </li>
                                        {weekValues.map((item: any, s: number) => {
                                            const branchStartEndTime = selectedBranchOpHrs?.find((timeVal: any) => timeVal?.dayName === `${item.label}`)
                                            const closed = watch(`${item?.label}${shiftValues?.[0]?.label}From`) === 'Closed'
                                            return (
                                                <li>
                                                    <div className={`${st.firstBox}`} key={s}>
                                                        <h6>{item?.label}</h6>
                                                        <label className={`d-inline-flex ${st.checkbox}`}>
                                                            <input type="checkbox" disabled={branchStartEndTime?.from === 'Closed'} checked={closed} onChange={(e: any) => {
                                                                if (e.target.checked) {
                                                                    shiftValues?.forEach((shift: any) => {
                                                                        setValue(`${item?.label}${shift?.label}From`, "Closed")
                                                                        setValue(`${item?.label}${shift?.label}to`, "Closed")
                                                                    })
                                                                } else {
                                                                    shiftValues?.forEach((shift: any) => {
                                                                        setValue(`${item?.label}${shift?.label}From`, "")
                                                                        setValue(`${item?.label}${shift?.label}to`, "")
                                                                    })
                                                                }
                                                            }} />
                                                            <span className={`${st.checkmark}`}></span>
                                                            <span className={`${st.labelName} ms-1`} >Not Working</span>
                                                        </label>
                                                    </div>
                                                    <div className='d-flex gap-2'>
                                                        {shiftValues.map((elem: any, i: number) => {
                                                            if (closed) {
                                                                return <></>
                                                            }
                                                            return (
                                                                <>
                                                                    <div className={`${st.secondBox}`}>
                                                                        <div className={`${st.inoutArea}`}>
                                                                            <label>From</label>
                                                                            <Controller
                                                                                name={`${item.label}${elem.label}From`}
                                                                                control={control}
                                                                                rules={{ required: elem.label === 'shift1' }}
                                                                                render={({ field }: any) => {
                                                                                    const filteredTimeValues = shiftValues?.[i - 1] ? timeValues?.filter((time: any) => time?.value >= watch(`${item?.label}${shiftValues?.[i - 1]?.label}to`) && time.value <= branchStartEndTime?.to) : timeValues?.filter((time: any) => time.value >= branchStartEndTime?.from && time.value <= branchStartEndTime?.to);
                                                                                    return (
                                                                                        <select className="form-select" value={field.value} onChange={(e: any) => {
                                                                                            // const branchWiseFilterBooking = teamBooking?.filter((bookingItem: any) => {
                                                                                            //   const [day, month, year] = bookingItem?.salectDate.split('-').map(Number);

                                                                                            //   const salectDate = new Date(day, month - 1, year);
                                                                                            //   const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                                                                            //   const activeDay = dayNames?.[salectDate?.getDay()]
                                                                                            //   const currentDate = new Date();


                                                                                            //   return bookingItem?.branchId === branchId && salectDate >= currentDate && activeDay === item?.label;
                                                                                            // })
                                                                                            // let services: any = [];

                                                                                            // branchWiseFilterBooking?.forEach((bookingItem: any) => {
                                                                                            //   services?.push(...bookingItem?.services?.map((itm: any) => itm?.startTime))
                                                                                            // })

                                                                                            // const checkIfChange = services?.some((item: any) => {
                                                                                            //   const totalMinutes: any = convertTimeToMinute(item);
                                                                                            //   const totalChangeMinutes: any = convertTimeToMinute(e.target.value);
                                                                                            //   return totalChangeMinutes < totalMinutes
                                                                                            // })
                                                                                            // if (!checkIfChange) {
                                                                                            //   setShowInfoModal(true);
                                                                                            //   setInfoMessage("you have booking in this time, you can't change timing. Please reschedule booking")
                                                                                            //   return
                                                                                            // }
                                                                                            const overlapTimeCheck = assignedBranches?.some((branchItem: any) => {
                                                                                                const shift = branchItem?.shift?.find((shift: any) => shift?.shiftNo === elem.label);
                                                                                                const day: any = shift?.workingHours?.find((d: any) => d?.day === item.label);
                                                                                                if (!day) {
                                                                                                    return false
                                                                                                }
                                                                                                const selectedTime: any = convertTimeToMinute(e.target.value);
                                                                                                const from: any = convertTimeToMinute(day?.from)
                                                                                                const to: any = convertTimeToMinute(day?.to);
                                                                                                return selectedTime >= from && selectedTime <= to

                                                                                            })

                                                                                            // if (overlapTimeCheck) {
                                                                                            //   setShowInfoModal(true);
                                                                                            //   setInfoMessage("team member is already working in another branch for this time")
                                                                                            //   return
                                                                                            // }

                                                                                            field.onChange(e)
                                                                                        }}>
                                                                                            <option value="">-Select-</option>
                                                                                            {filteredTimeValues?.map((item: any) => {
                                                                                                return (
                                                                                                    <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                                                                )
                                                                                            })}
                                                                                        </select>
                                                                                    );
                                                                                }}
                                                                            />

                                                                        </div>
                                                                        {errors[`${item.label}${elem.label}From`]?.type === "required" && (
                                                                            <span className="errorMessage">required*</span>
                                                                        )}
                                                                    </div>
                                                                    <div className={`${st.secondBox}`}>
                                                                        <div className={`${st.inoutArea}`}>
                                                                            <label>To</label>
                                                                            <Controller
                                                                                name={`${item.label}${elem.label}to`}
                                                                                control={control}
                                                                                rules={{ required: elem.label === 'shift1' }}
                                                                                render={({ field }: any) => {
                                                                                    const filteredTimeValues = shiftValues?.[i - 1] ?
                                                                                        timeValues.filter((time) =>  time.value >= watch(`${item?.label}${shiftValues?.[i - 1]?.label}to`, '') && time.value >= watch(`${item.label}${elem.label}From`, '') && time.value >= branchStartEndTime?.from && time?.value <= branchStartEndTime?.to) :
                                                                                        timeValues.filter((time) => time.value >= watch(`${item.label}${elem.label}From`, '') && time.value >= branchStartEndTime?.from && time?.value <= branchStartEndTime?.to);
                                                                                    return (
                                                                                        <select className="form-select" value={field.value} onChange={(e: any) => {
                                                                                            // const branchWiseFilterBooking = teamBooking?.filter((bookingItem: any) => {
                                                                                            //   const [day, month, year] = bookingItem?.salectDate.split('-').map(Number);

                                                                                            //   const salectDate = new Date(day, month - 1, year);
                                                                                            //   const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                                                                            //   const activeDay = dayNames?.[salectDate?.getDay()]
                                                                                            //   const currentDate = new Date();


                                                                                            //   return bookingItem?.branchId === branchId && salectDate >= currentDate && activeDay === item?.label;
                                                                                            // })
                                                                                            // let services: any = [];

                                                                                            // branchWiseFilterBooking?.forEach((bookingItem: any) => {
                                                                                            //   services?.push(...bookingItem?.services?.map((itm: any) => itm?.startTime))
                                                                                            // })

                                                                                            // const checkIfChange = services?.some((item: any) => {
                                                                                            //   const totalMinutes: any = convertTimeToMinute(item);
                                                                                            //   const totalChangeMinutes: any = convertTimeToMinute(e.target.value);
                                                                                            //   return totalChangeMinutes < totalMinutes
                                                                                            // })
                                                                                            // if (!checkIfChange) {
                                                                                            //   setShowInfoModal(true);
                                                                                            //   setInfoMessage("you have booking in this time, you can't change timing. Please reschedule booking")
                                                                                            //   return
                                                                                            // }
                                                                                            const overlapTimeCheck = assignedBranches?.some((branchItem: any) => {
                                                                                                const shift = branchItem?.shift?.find((shift: any) => shift?.shiftNo === elem.label);
                                                                                                const day: any = shift?.workingHours?.find((d: any) => d?.day === item.label);
                                                                                                if (!day) {
                                                                                                    return false
                                                                                                }
                                                                                                const selectedTime: any = convertTimeToMinute(e.target.value);
                                                                                                const from: any = convertTimeToMinute(day?.from)
                                                                                                const to: any = convertTimeToMinute(day?.to);
                                                                                                return selectedTime >= from && selectedTime <= to

                                                                                            })

                                                                                            // if (overlapTimeCheck) {
                                                                                            //   setShowInfoModal(true);
                                                                                            //   setInfoMessage("team member is already working in another branch for this time")
                                                                                            //   return
                                                                                            // }

                                                                                            field.onChange(e)
                                                                                        }}>
                                                                                            <option value="">-Select-</option>
                                                                                            {filteredTimeValues?.map((item: any) => {
                                                                                                return (
                                                                                                    <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                                                                )
                                                                                            })}
                                                                                        </select>
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {errors[`${item.label}${elem.label}to`]?.type === "required" && (
                                                                            <span className="errorMessage">required*</span>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        {/* {selectedBranchOpHrs?.length === 0 && <Col md={12}>
                            <p style={{ textAlign: 'center' }}>Please maintain branch working hours first</p>
                        </Col>} */}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={`${st.formBox} ${m.formSign} mt-1 pt-1 mb-1`}>
                        <div className={`${st.btnsGroup} ${m.formSignGroup} mt-0`}>
                            <button className={`btn w-100 ${st.btn4} ${m.btnGray}`}
                                style={{ minWidth: 'auto' }}
                                onClick={handleSubmit(onFormSubmit)}>
                                {editAsinedBrIndex >= 0 ? 'Update' : 'Save'}
                            </button>
                            <button
                                className={`btn w-100 ${st.btn1} ${m.btnGray} ${m.btnGrayLight}`}
                                style={{ minWidth: 'auto' }}
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default AddWorkingTeams;