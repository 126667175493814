import React, { useEffect, useState } from "react";
import BasicInfo from "./BasicInfo";
import BranchTab from "./Branch";
import { httpRequest } from "../../../Apis/commonApis";
import ServiceTab from "./Services";
import LeavesTab from "./Leaves";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AccessBranchControl from "./AccessControl";

export default function AddTeamRouts(props: any) {
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const navigate = useNavigate();
    const { dynamicName, handleStepsFormData, loading } = props;
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const location = useLocation();
    const [editTeamData, setEditTeamData] = useState<any>(null);
    const [editLeaveData, setEditLeaveData] = useState<any>(null);
    const [branchList, setBranchList] = useState([]);

    useEffect(() => {
        if (businessData) {
            getBranch();
        }
    }, [businessData]);

    useEffect(() => {
        if (location.state) {
            getTeamMemberData();
        }
    }, [location.state, businessData]);
    useEffect(() => {
        const prevBId = localStorage.getItem("currentBranchId");
        if (branchId && branchId !== prevBId) {
            localStorage.setItem('currentBranchId', branchId);
            navigate(-1);
        }
    }, [branchId]);
    const getTeamMemberData = async () => {
        let res = await httpRequest(`getAllTeamMembers?_id=${location.state.teamId}&businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setEditTeamData(res.data[0]);
            setEditLeaveData(res.leaveData[0])
        }
    };
    const getBranch = async () => {
        const res = await httpRequest(`getBranch?businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setBranchList(res?.data)
        }
    };

    return (
        <>
            {dynamicName === "info" &&
                <BasicInfo businessData={businessData}
                    handleStepsFormData={handleStepsFormData}
                    editTeamData={editTeamData}
                    loading={loading}
                />}
            {dynamicName === "branch" &&
                <BranchTab
                    handleStepsFormData={handleStepsFormData}
                    editTeamData={editTeamData}
                    branchList={branchList}
                    loading={loading} />}
            {dynamicName === "services" &&
                <ServiceTab
                    handleStepsFormData={handleStepsFormData}
                    editTeamData={editTeamData}
                    businessData={businessData}
                    loading={loading} />}
            {dynamicName === "leaves" &&
                <LeavesTab
                    handleStepsFormData={handleStepsFormData}
                    editLeaveData={editLeaveData}
                    branchList={branchList}
                    editTeamData={editTeamData}
                    loading={loading} />}
            {dynamicName === "accesscontrol" &&
                <AccessBranchControl
                    handleStepsFormData={handleStepsFormData}
                    editLeaveData={editLeaveData}
                    branchList={branchList}
                    loading={loading}
                    editTeamData={editTeamData}

                />}
        </>
    )
}
