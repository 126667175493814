import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import { deleteIcon, editIcon, openIcon } from "../../../assets/images";
import { Status } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import { Col } from "react-bootstrap";

const StatusButton = (props: any) => {
  let { params, getTeamMember } = props;
  const navigate = useNavigate();
  let { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  
  const [show, setShow] = useState(false);
  const [error, seterror] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isAnotherLocation =
    window.location.pathname.includes("business-setting");

  const deleteTeamMember = async () => {
    const res = await httpRequest(`deleteTeamMember/${params?.id}`, "DELETE", null, "json");
    if (res?.status) {
      props.getTeamMember();
    }
    else {
      seterror(res?.messaeg)
    }
  };

  const handleViewClick = () => {
    let redUrl = isAnotherLocation ? "/business/teams/view-team" : "view-team";
    navigate(redUrl, { state: { teamId: params?.row?.id } });
  };

  const handleEditClick = () => {
    props?.handleAddNewTeamShow()
    navigate("/business/new-teams/edit-team/info", { state: { teamId: params?.row?.id } });
  };
  const canDeleteTeam = subModuleRoles?.['Teams']?.Team?.Delete;
  const canEditTeam = subModuleRoles?.['Teams']?.Team?.Edit;
  const canDeactivate = subModuleRoles?.['Teams']?.Team?.deactivate;
  const isDisabled = params?.row?.hoursDetail === null || params?.row?.hoursDetail?.length === 0
  

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {canEditTeam && <li>
            <p
              className={`btn ${tb.edit}`}
              title="Edit"
            >
              <img src={editIcon} alt="edit" onClick={handleEditClick} />
            </p>
          </li>}
          {/* <li>
            <p
              className={`btn ${tb.edit}`}
              title="view"
            >
              <img src={openIcon} alt="view" onClick={handleViewClick} />
            </p>
          </li> */}
          {canDeleteTeam &&
            <li>
              <button
                type="button"
                className={`btn ${tb.delete}`}
                title="Delete"
                onClick={handleShow}
              >
                <img src={deleteIcon} alt="Delete" />
              </button>
            </li>}
          {canDeactivate && <li>
            <Status status={params.row.isActive}
              api='updateTeamMember' id={params?.id}
              apiMethod='formdata'
              getLatest={getTeamMember} 
              disabled={isDisabled}
              />
          </li>}
        </ul>
      </div>

      <DeletePopup show={show} deleteTeamMember={deleteTeamMember}
        handleClose={handleClose} />
    </>
  );
};

export default function TeamGridTable(props: any) {
  const { handleAddNewTeamShow } = props
  let row: any = props?.teamMember?.map((val: any, index: any) => {
    const teamTitle = val?.Role?.map((item:any) => item?.role?.map((roleItem:any) => roleItem?.teamTitle)?.join(", "))   
    
    let services = val.serviceId?.map((item: any) => item.serviceName);
    let branches = val.Role?.map((item: any) => item.branchName);
    return {
      Sr_No: index + 1,
      id: val?._id,
      employeeId: val?.teamMemberIdNo,
      name: `${val?.firstName} ${val?.lastName}`,
      nickName : val?.nickName ? val?.nickName : "",
      teamTitle:teamTitle?.join(","),
      hoursDetail : val?.hoursDetail,
      service: services.join(', '),
      joiningDate: val.createdAt?.split('T')[0],
      branch: branches.join(', '),
      createdBy: `${val?.createdBy?.firstName} ${val?.createdBy?.lastName}`,
      updatedBy: `${val?.updatedBy?.firstName} ${val?.updatedBy?.lastName}`,
      action: val?.isActive,
      isActive: val.isActive,
    };
  });

  const columns = [
    { field: "Sr_No", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 220,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        if (v1 === v2) return 0;
        return v1 ? -1 : 1;
      },
      renderCell: (params: any) => <StatusButton params={params} getTeamMember={props.getTeamMember} handleAddNewTeamShow={handleAddNewTeamShow}/>,
    },
    { field: "employeeId", headerName: "Employee ID", flex: 1, minWidth: 100 },
    { field: "name", headerName: "Name", flex: 2, minWidth: 130 },
    { field: "nickName", headerName: "Nickname", flex: 2, minWidth: 130 },
    { field: "teamTitle", headerName: "Team Title", flex: 2, minWidth: 170 },
    { field: "service", headerName: "Service", flex: 2, minWidth: 120 },
    { field: "joiningDate", headerName: "Joining Date", flex: 2, minWidth: 120 },
    { field: "branch", headerName: "Branch", flex: 2, minWidth: 120 },
    
  ];

  return (
    <div className={`${tb.dataTable}`}>
      <Col md={12}>
                  <div className="text-end mb-2">
                    <HelpButon number={37} type={"help"} className={"btn"} />
                  </div>
                </Col>
      <div style={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={row}
          autoHeight
          hideFooterPagination={false}
          rowHeight={52}
          checkboxSelection
          disableRowSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}
