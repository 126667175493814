import React, { useEffect, useState } from 'react'
import TodayData from './TodayData'
import WeeklyData from './WeeklyData';
import Month from 'react-calendar/dist/cjs/YearView/Month';
import MonthlyData from './MonthlyData';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../Apis/commonApis';

export default function DashboardRoutes({ dynamicName, loading, homepageData }: { dynamicName: string, loading:boolean, homepageData:any }) {
  return (
    <>
      {dynamicName === 'today' && <TodayData loading={loading} homepageData={homepageData}/>}
      {dynamicName === 'week' && <WeeklyData />}
      {dynamicName === 'month' && <MonthlyData />}
    </>
  )
}
