import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import {
  deleteIcon,
  editIcon,
} from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";


const StatusButton = (props: any) => {
  let {params, getAmenities} = props;  
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let deleteAmenities = async () => {
    const res = await httpRequest(`deleteAmenities/${params.id}`, "delete", null, "json");
    if (res.status == true) {
      getAmenities()
    }
  };
  
  const canEditAmnt = subModuleRoles?.['Branch Set Up']?.Amenities?.Edit;
  const canDeleteAmnt = subModuleRoles?.['Branch Set Up']?.Amenities?.Delete;

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {canEditAmnt && <li>
            <button className={`btn ${tb.edit}`} title="Edit"  onClick={() => {
                navigate("/business/branch-amenities/edit-amenity", {
                  state: params.row,
                });
              }}>
               <img src={editIcon} alt="edit" />
            </button>
          </li>}
          {/* <li>
            <NavLink className={`btn ${tb.edit}`} title="Edit" to="view-amenity">
              <img src={openIcon} alt="view" />
            </NavLink>
          </li> */}
          {/* {canDeleteAmnt && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>} */}
          {/* <li>
            <Status />
          </li> */}
        </ul>
      </div>

      <DeletePopup show={show} deleteAmenities={deleteAmenities} handleClose={handleClose} />
    </>
  );
};




export default function DataGridTable(props: any) {
  let { amenities, activeButton } = props;

  let columns: any[] = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    { field: "itemName", headerName: "Item Name", flex: 1, minWidth: 300 },
  ];

  if (activeButton === "occupied") {
    columns.push({ field: "occupied", headerName: "Occupied", flex: 1, minWidth: 130 });
  }

  // if (activeButton === "available") {
  //   columns.push({ field: "available", headerName: "Available", flex: 1, minWidth: 120 }, );
  // }

  if (activeButton === "all") {
    // columns.push({ field: "available", headerName: "Available", flex: 1, minWidth: 120 }, );
    columns.push({ field: "quantity", headerName: "Quantity", flex: 1, minWidth: 120 });
    // columns.push({ field: "occupied", headerName: "Occupied", flex: 1, minWidth: 130 });
  }

  columns.push({
    field: "action",
    headerName: "Action",
    flex: 1,
    minWidth: 280,
    renderCell: (params: any) => <StatusButton params={params} getAmenities={props?.getAmenities} />,
  });

  let row = amenities?.map((item: any, index: any) => {
    return {
      id: item?._id,
      gId: item?.AmenitiesGlobalList?._id,
      serialNumber: index + 1,
      itemName: item?.AmenitiesGlobalList?.itemName,
      quantity: item?.qty,
      global: item?.global ? item?.global : false,
      occupied: item?.occupied ? item?.occupied : "0",
      // available: item?.available ? item?.available : "0",
      action: "",
    };
  });

  return (
    <div className={`${tb.dataTable}`}>
      <div style={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={row}
          autoHeight
          hideFooterPagination={false}
          rowHeight={52}
          checkboxSelection
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}

