import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import cx from '../index.module.scss';
import st from '../../../../assets/stylesheet/style.module.scss';
import { FaPlus } from 'react-icons/fa';
import { deleteIcon, editButton } from '../../../../assets/images';
import AddWorkingTeams from '../../Modals/AddWorkingTeams';
import { useForm, Controller } from 'react-hook-form';
import { httpRequest } from '../../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { convertTimeToMinute, shiftValues, timeValues, weekValues } from '../../../../constants/Constants';
import ErrorPopup from '../../Modals/ErrorPopup';
import LoadingBtn from '../../Buttons/LoadingBtn';

interface FormValues {
  [key: string]: string;
}
export default function BranchTab(props: any) {
  const { handleStepsFormData, branchList, loading, editTeamData } = props;
  const [everyDaySwitch, setEveryDaySwitch] = useState(false);
  const [showAddWorkingTeams, setAddWorkingTeamsShow] = useState(false);
  const handleAddWorkingTeamsClose = () => setAddWorkingTeamsShow(false);
  const [roleError, setRoleError] = useState<any>(false)
  const handleAddWorkingTeamsShow = () => setAddWorkingTeamsShow(true);
  const [addBranchErr, setAddBranchErr] = useState<any>(false);
  const [teamTitleList, setTeamTitleList] = useState([]);
  const { register, control, setValue, watch, handleSubmit, formState: { errors }, reset, clearErrors } = useForm<FormValues>();
  const [showErrorModal, setShowErrorModal] = useState("");

  const handleAlertModalClose = () => setShowErrorModal("");
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
  const [selectedTeamTitles, setSelectedTeamTitles] = useState<any>([]);
  const [editAsinedBrData, setEditAsinedBrData] = useState<any>(null);
  const [editAsinedBrIndex, setEditAsinedBrIndex] = useState<number>(-1);
  const [selectedRole, setSelectedRole] = useState<any>([]);
  const selectedBranch = branchList?.find((item: any) => item._id === watch(`branchId`, ''));
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const [assignedBranches, setAssignedBranches] = useState<any>([]);
  useEffect(() => {
    if (editTeamData) {
      reset();
      let assignedArr: any = [];
      editTeamData.hoursDetail?.forEach((elem: any) => {

        assignedArr.push({
          branchName: elem.branchId?.branchName,
          branchId: elem.branchId?._id,
          isSameEveryday: elem.isSameEveryday,
          shift: elem.shift,
        });
      });

      setAssignedBranches(assignedArr);
      // const roles = editTeamData?.Role?.map((item: any) => ({ ...item, branchId: item?.branch?._id, role: item?.role?.map((roleItem: any) => roleItem?._id) })) old ways
      const roles = editTeamData?.Role?.map((item: any) => ({ ...item, branchId: item?.branch?._id || null, role: item?.role?.map((role: any) => role?._id) }))
      setSelectedRole(roles)
    }
  }, [editTeamData]);

  function handleEditBranch(values: any, id: number) {
    
    const selectRol = selectedRole?.find((item: any) => item?.branchId === values?.branchId)
    setSelectedTeamTitles(selectRol?.role?.map((roleItem: any) => {
      const data = teamTitleList?.find((teamTitle: any) => roleItem === teamTitle?.value)
      return data
    }))
    setEditAsinedBrData(values);
    setEditAsinedBrIndex(id);
    values.shift?.forEach((elem: any) => {
      elem.workingHours.forEach((item: any) => {
        console.log(`${item.day}${elem.shiftNo}From`, 'values', item?.from);
        setValue(`${item.day}${elem.shiftNo}From`, item.from)
        setValue(`${item.day}${elem.shiftNo}to`, item.to)
      });
    });

    if (values?.isSameEveryday?.status) {
      setEveryDaySwitch(true)
      values?.isSameEveryday?.shift?.forEach((item: any) => {
        setValue(`${item.shiftNo}From`, item.from)
        setValue(`${item.shiftNo}to`, item.to)
      })
    }
    setValue('branchId', values.branchId);
    // window.scrollTo(0, 0);
  }
  function handleRemoveBranch(id: string, branchId: any) {
    let newSelected: any = [...assignedBranches];
    const selectRol = selectedRole?.length > 1 ? selectedRole?.filter((item: any) => item?.branchId !== branchId) : selectedRole?.map((item: any) => (
      {
        accessManager: [],
        accessManagerId: [],
        branch: null,
        branchName: '',
        role: item?.role
      }
    ))
    setSelectedRole(selectRol)
    newSelected.splice(id, 1);
    setAssignedBranches(newSelected);
  }

  const getTitle = async () => {
    const res = await httpRequest(
      `getTeamTitle?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      const options: any = res?.data?.map((item: any) => ({
        value: item._id,
        label: item.teamTitle,
        isActive: item.isActive,
      })).filter((elm: any) => elm.isActive === true);
      setTeamTitleList(options);
    }
  };

  const branchWorkingHours = selectedBranch?.workingHours

  const handleShiftSwitchChange = (event: any) => {
    if (selectedBranch === undefined || selectedBranch === "" || selectedBranch === null) {
      handleAlertModalShow(`Error,Please select a branch first`);
      return
    }
    let isChecked = event;
    shiftValues?.forEach((elem: any, s: number) => {
      let fromVal = isChecked ? watch(`${elem.label}From`, '') : '';
      let toVal = isChecked ? watch(`${elem.label}to`, '') : '';
      weekValues.forEach(item => {
        // let previousFromVal = isChecked ? watch(`${item.label}${shiftValues?.[s - 1]?.label}From`, '') : '';
        // let previousToVal = isChecked ? watch(`${item.label}${shiftValues?.[s - 1]?.label}to`, '') : '';
        const currDay = branchWorkingHours?.find((workingObj: any) => workingObj?.dayName === item?.label);
        let modifiedFrom = fromVal
        let modifiedTo = toVal;
        if (currDay?.from) {
          modifiedFrom = !fromVal ? '' : convertTimeToMinute(fromVal) < convertTimeToMinute(currDay?.from) ? currDay?.from : fromVal
        }    
        if (currDay?.to) {
          modifiedTo = !toVal ? "" : convertTimeToMinute(toVal) < convertTimeToMinute(currDay?.from) ? currDay?.from : convertTimeToMinute(toVal) > convertTimeToMinute(currDay?.to) ? currDay?.to : toVal
        }        
        const shiftFromValue = currDay?.from === 'Closed' ? elem?.label === 'shift1' ? currDay?.from : '' : currDay?.from === undefined ? '00:00' : modifiedFrom
        const shiftToValue = currDay?.to === 'Closed' ? elem?.label === 'shift1' ? currDay?.to : '' : currDay?.to === undefined ? '00:00' : modifiedTo
        setValue(`${item.label}${elem.label}From`, shiftFromValue);
        setValue(`${item.label}${elem.label}to`, shiftToValue);
      });
    });

    setEveryDaySwitch(isChecked);
  };

  function submitAsignBranchData() {
    if (assignedBranches.length < 1) {
      setAddBranchErr(true);
      setTimeout(() => {
        setAddBranchErr(false);

      }, 3000)
      return;
    }
    let hoursDetail = { hoursDetail: JSON.stringify(assignedBranches), isActive:JSON.stringify(assignedBranches?.length > 0), Role: JSON.stringify(selectedRole?.map((item: any) => ({ branchName: item?.branchName, branch: item?.branchId, role: item?.role, accessManager: item?.accessManager, accessManagerId: item?.accessManagerId }))) };

    handleStepsFormData(hoursDetail, 'step2');
  }

  useEffect(() => {
    if (businessData) {
      getTitle();
    };
  }, [businessData]);

  const selectedBranchIds = assignedBranches?.map((item: any) => item.branchId);
  const filteredBranchList = branchList?.filter((item: any) => !selectedBranchIds?.includes(item._id));

  return (
    <>
      <ErrorPopup
        showErrorModal={showErrorModal}
        handleErrorModalClose={handleAlertModalClose}
      />
      {showAddWorkingTeams && <AddWorkingTeams
        show={showAddWorkingTeams}
        handleShiftSwitchChange={handleShiftSwitchChange}
        handleAddWorkingTeamsClose={handleAddWorkingTeamsClose}
        branchList={branchList}
        watch={watch}
        Controller={Controller}
        control={control}
        handleSubmit={handleSubmit}
        selectedBranch={selectedBranch}
        setAddBranchErr={setAddBranchErr}
        selectedRole={selectedRole}
        editAsinedBrIndex={editAsinedBrIndex}
        setRoleError={setRoleError}
        clearErrors={clearErrors}
        everyDaySwitch={everyDaySwitch}
        setEditAsinedBrData={setEditAsinedBrData}
        setEveryDaySwitch={setEveryDaySwitch}
        setValue={setValue}
        setEditAsinedBrIndex={setEditAsinedBrIndex}
        setSelectedRole={setSelectedRole}
        setSelectedTeamTitles={setSelectedTeamTitles}
        setAssignedBranches={setAssignedBranches}
        editTeamData={editTeamData}
        assignedBranches={assignedBranches}
        register={register}
        errors={errors}
        addBranchErr={addBranchErr}
        editAsinedBrData={editAsinedBrData}
        filteredBranchList={filteredBranchList}
      />}

      <div className={`${st.offTab_heading}`}>
        <h6>Working hours</h6>
        <p className={`${st.offcanvas_para}`}>Working hour of team member will be used during customer booking. </p>
      </div>
      <div className='my-4 text-center'>
        <button className={`btn ${st.addBlue}`} onClick={handleAddWorkingTeamsShow}><FaPlus /> Assign Working Hours   </button>
        {addBranchErr && (
          <p className={"errorMessage text-center"}>Please add atleast one branch</p>
        )}
      </div>

      {assignedBranches?.map((item: any, index: any) => {
        const role = selectedRole?.find((roleItem: any) => roleItem?.branchId === item?.branchId);
        let roleList: any = []

        if (role) {
          let data = role?.role?.map((roleItem: any) => teamTitleList?.find((teamTitle: any) => roleItem === teamTitle?.value))
          roleList = data
        }
        return (
          <React.Fragment key={index}>
            {/* <Row>
              <Col md={12}>
                <ul className={`${st.btnList} align-items-center justify-content-end mb-2`}>
                  <li onClick={() => {
                    handleEditBranch(item, index)
                    handleAddWorkingTeamsShow()
                  }
                  }
                  >
                    <button> <img src={editButton} alt="edit" /></button>
                  </li>
                  <li onClick={() => handleRemoveBranch(index, role?.branchId)}>
                    <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                  </li>
                </ul>
              </Col>
            </Row> */}
            <Row className='align-items-center mb-2'>
              {/* <Col md={5}>
                <div className={`${st.reFormBox}`}>
                  <label htmlFor="">Role</label>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    options={teamTitleList}
                    value={roleList}
                    isDisabled
                  />
                </div>
              </Col> */}
              <Col md={5}>
                <div className={`${st.reFormBox}`}>
                  <label htmlFor="">Assign Location</label>
                  <select name="" id="" className='form-select' value={item?.branchId} disabled>
                    <option value="">Select Location</option>
                    {branchList?.map((branch: any) => {
                      return (
                        <option value={branch._id} key={branch._id}>{branch.branchName}</option>
                      )
                    })}
                  </select>
                </div>
              </Col>
              <Col md={2}>
                <ul className={`${st.btnList} mt-1`}>
                  <li onClick={() => {
                    handleEditBranch(item, index)
                    handleAddWorkingTeamsShow()
                  }
                  }
                  >
                    <button> <img src={editButton} alt="edit" /></button>
                  </li>
                  <li onClick={() => handleRemoveBranch(index, role?.branchId)}>
                    <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row >
              <Col md={12}>
                <div className={`${st.newRedesign_main} `}>
                  <div className={`${st.branchContact_tabBox} ${st.branchContact_tabBox2} `} >
                    <ul className={`${st.branchOperating_tab_line}`}>
                      <li className={`${st.branchOperatingLine_left}`}>
                        <div className={`${st.branchLine_content}`}>Working hours</div>
                      </li>
                      <li className={`${st.branchOperatingLine_right}`}>
                        <div className={`${st.branchLine_content}`}>Shift 1</div>
                        <div className={`${st.branchLine_content}`}>Shift 2</div>
                      </li>
                    </ul>

                    <ul className={`${st.branchOperating_tab_InputMian}`}>
                      {/* <li>
                        {item?.isSameEveryday?.status && <div className={`${st.firstBox} ${st.copyAllDays}`}>
                          <label className={`d-inline-flex ${st.checkbox}`}>
                            <input type="checkbox" disabled checked={item?.isSameEveryday?.status} />
                            <span className={`${st.checkmark}`}></span>
                            <span className={`${st.labelName} ms-0`} >Copy to <br /> all Days</span>
                          </label>
                        </div>}
                        {item?.isSameEveryday?.shift?.map((sameShift: any, s: number) => {
                          if (s === 2 || !sameShift?.from || !sameShift?.to) {
                            return <></>
                          }
                          return (
                            <div className='d-flex gap-2'>
                              <div className={`${st.secondBox}`}>
                                <div className={`${st.inoutArea}`}>
                                  <label>From</label>
                                  <select className="form-select" value={sameShift?.from} disabled>
                                    <option value="">-Select-</option>
                                    {timeValues?.map((time: any, t: number) => {
                                      return (
                                        <option value={time?.value} key={t}>{time?.label}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className={`${st.secondBox}`}>
                                <div className={`${st.inoutArea}`}>
                                  <label>To</label>
                                  <select className="form-select" value={sameShift?.to} disabled>
                                    <option value="">-Select-</option>
                                    {timeValues?.map((time: any, t: number) => {
                                      return (
                                        <option value={time?.value} key={t}>{time?.label}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        <div className='d-flex gap-2'>
                          <div className={`${st.secondBox}`}>
                            <div className={`${st.inoutArea}`}>
                              <label>From</label>
                              <select className="form-select">
                                <option value="1">-Select-</option>
                                <option value="1">00:00</option>
                              </select>
                            </div>
                          </div>
                          <div className={`${st.secondBox}`}>
                            <div className={`${st.inoutArea}`}>
                              <label>To</label>
                              <select className="form-select">
                                <option value="1">-Select-</option>
                                <option value="1">00:00</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </li> */}
                      {weekValues?.map((week: any, w: number) => {
                        const dayOfWeekObj = item?.shift?.[0]?.workingHours?.find((dayShiftItem: any) => dayShiftItem?.day === week?.label)
                        return (
                          <li key={w}>
                            <div className={`${st.firstBox}`}>
                              <h6>{week?.label}</h6>
                              <label className={`d-inline-flex ${st.checkbox}`}>
                                <input type="checkbox" checked={dayOfWeekObj?.from === 'Closed'} disabled />
                                <span className={`${st.checkmark}`}></span>
                                <span className={`${st.labelName} ms-1`} >Not Working</span>
                              </label>
                            </div>
                            {dayOfWeekObj?.from !== 'Closed' && item?.shift?.map((shiftItem: any, s: number) => {
                              const dayOfWeekObj = shiftItem?.workingHours?.find((dayShiftItem: any) => dayShiftItem?.day === week?.label)
                              if (!dayOfWeekObj?.from) {
                                return <></>
                              }
                              return (
                                <div className='d-flex gap-2' key={s}>
                                  <div className={`${st.secondBox}`}>
                                    <div className={`${st.inoutArea}`}>
                                      <label>From</label>
                                      <select className="form-select" value={dayOfWeekObj?.from} disabled>
                                        <option value="">-Select-</option>
                                        {timeValues?.map((time: any, t: number) => {
                                          return (
                                            <option value={time?.value} key={t}>{time?.label}</option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  <div className={`${st.secondBox}`}>
                                    <div className={`${st.inoutArea}`}>
                                      <label>To</label>
                                      <select className="form-select" value={dayOfWeekObj?.to} disabled>
                                        <option value="">-Select-</option>
                                        {timeValues?.map((time: any, t: number) => {
                                          return (
                                            <option value={time?.value} key={t}>{time?.label}</option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}

                          </li>
                        )
                      })}
                      {/* <li>
                        <div className={`${st.firstBox}`}>
                          <h6>Tuesday</h6>
                          <label className={`d-inline-flex ${st.checkbox}`}>
                            <input type="checkbox" />
                            <span className={`${st.checkmark}`}></span>
                            <span className={`${st.labelName} ms-1`} >Closed</span>
                          </label>
                        </div>
                        <div className={`${st.secondBox}`}>
                          <div className={`${st.inoutArea}`}>
                            <label>From</label>
                            <select className="form-select">
                              <option value="1">-Select-</option>
                              <option value="1">00:00</option>
                            </select>
                          </div>
                        </div>
                        <div className={`${st.secondBox}`}>
                          <div className={`${st.inoutArea}`}>
                            <label>To</label>
                            <select className="form-select">
                              <option value="1">-Select-</option>
                              <option value="1">00:00</option>
                            </select>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className={`${st.firstBox}`}>
                          <h6>Sunday</h6>
                          <label className={`d-inline-flex ${st.checkbox}`}>
                            <input type="checkbox" />
                            <span className={`${st.checkmark}`}></span>
                            <span className={`${st.labelName} ms-1`}>Closed</span>
                          </label>
                        </div>

                      </li> */}
                    </ul>
                  </div>
                </div>
              </Col>

            </Row>
          </React.Fragment>
        )
      })
      }
      <div className={`${cx.offcanvas_footer}`}>
        <Col md={12}>
          <div className={`${st.formBox} ${cx.formSign}`}>
            <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
              <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={submitAsignBranchData} disabled={loading}>
                {loading ? (<LoadingBtn />
                ) : (
                  'Save'
                )}
              </button>
              {/* <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit" onClick={(e) => {
                e.preventDefault();
                handleStepsFormData(null, 'cancel')
              }}>
                Cancel
              </button> */}
            </div>
          </div>
        </Col>
      </div>
    </>
  )
}
