import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "./SuccessPopup";
import HelpButon from "../HelpButon.tsx/HelpButton";
import HoverEyeContent from "../HoverEyeContent/HoverEyeContent";
import MultiSelect2 from "../MultiSelect/MultiSelect2";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

function AddServicesCategory(props: any) {
    let { show, handleServicesCategoryClose, getServiceCatagory, cat } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm();
    let { allBusCategories, businessData } = useSelector((state: any) => state.shareDataReducer);
    const [apiError, setApiError] = useState("");
    const [errorTrig, setErrorTrig] = useState<any>(false);
    const [categoryTag, setCategoryTag] = useState<any>([]);
    const [selectedBusinessCat, setSelectedBusinessCat] = useState<any>([]);
    const [serviceTagList, setServiceTagList] = useState<any[]>([""])
    const [catError, setCatError] = useState(false);
    const [message, setMessage] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState<any>(false);
    const handleSuccessModalClose = () => setShowSuccessModal(false);

    const serviceTaglist = async () => {
        const res = await httpRequest(`get_ServiceTag`, "get", null, "auth");
        if (res.status === true) {
            // let servTags = res?.data?.map((item: any) => item?.tags)      
            let servTags = res?.data

            setServiceTagList(servTags);
        }
    };
    const savedCats = allBusCategories?.filter(
        (item: any) =>
            businessData && businessData?.businessCatagoryId?.includes(item._id)
    );

    const serviceTags = serviceTagList?.filter((tagItem: any) => {
        return tagItem?.businessCategoryId?.some((item: any) => selectedBusinessCat?.map((catItem: any) => catItem?.value)?.includes(item?._id))
    })

    useEffect(() => {
        serviceTaglist();
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);
        if (cat && cat?._id) {
            const options = cat?.serviceCategoryTag?.map((tag: any) => {
                return { value: tag, label: tag };
            });
            setCategoryTag(options);
        }
    }, [businessData]);
    useEffect(() => {
        if (cat?._id) {
            setSelectedBusinessCat(cat?.businessCategoryId?.map((item: any) => ({ label: item?.BusinessCategoryName, value: item?._id })))
            setValue('categoryName', cat?.serviceCategoryName)
            setValue('categoryDetail', cat?.serviceCategoryDetails)
        }
    }, [cat])

    function selectHandler(e: any) {
        setCategoryTag(e);
    }
    const submitHandler = async (data: any) => {
        if (selectedBusinessCat?.length === 0) {
            setErrorTrig(true)
            return
        }
        if (categoryTag?.length === 0) {
            setCatError(true);
            return
        }
        const tagList = categoryTag?.map((tag: any) => {
            return tag.label;
        });
        if (
            data.categoryName !== "" &&
            tagList.length > 0
            // && catColor !== ""
        ) {
            const serviceData = {
                businessId: businessData?._id,
                serviceCategoryName: data?.categoryName,
                serviceCategoryDetails: data?.categoryDetail,
                serviceCategoryTag: tagList,
                businessCategoryId: selectedBusinessCat?.map((item: any) => item?.value)
            };

            if (cat?._id) {
                const updatedData = {
                    serviceCategoryName: data?.categoryName,
                    serviceCategoryDetails: data?.categoryDetail,
                    serviceCategoryTag: tagList,
                    businessCategoryId: selectedBusinessCat?.map((item: any) => item?.value)
                };

                const res = await httpRequest(`updateServiceCatagory/${cat?._id}`, "PATCH", updatedData, "json");
                if (res.status) {
                    setErrorTrig(false);
                    setMessage(res.message);
                    setShowSuccessModal(true);
                    setApiError("");
                    reset();
                    getServiceCatagory && getServiceCatagory()
                    setTimeout(() => {
                        handleServicesCategoryClose()
                    }, 1000)
                } else {
                    setApiError(res.message);
                }
            } else {
                const res = await httpRequest(
                    "createServiceCatagory",
                    "post",
                    serviceData,
                    "json"
                );
                if (res.status) {
                    setMessage(res.message);
                    setShowSuccessModal(true);
                    setApiError("")
                    reset()
                    getServiceCatagory && getServiceCatagory()
                    setTimeout(() => {
                        handleServicesCategoryClose()
                    }, 1000)
                } else {
                    setApiError(res.message)
                }
            }

        }
    };
    console.log(cat, 'catDAta');
    

    return (
        <>
            <SuccessPopup
                showSuccessModal={showSuccessModal}
                handleSuccessModalClose={handleSuccessModalClose}
                message={message}
            />
            <Modal
                centered
                show={show}
                backdrop="static"
                keyboard={false}
                onHide={handleServicesCategoryClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`${m.modalCts} ${showSuccessModal ? m.hide : ''} ${m.redesign_modalCts}`}
            >
                <Modal.Header>
                    <Modal.Title>
                        {cat?._id ? `Service Category: ${cat?.serviceCategoryName}` : 'Add a New Service Category'}
                    </Modal.Title>
                    <button
                        className={`${m.closeIcon}`}
                        onClick={handleServicesCategoryClose}
                    >
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body className="py-2">
                    <Row>
                        <Col md={12}>
                            <div className={` ${st.reFormBox}`}>
                                <label className="form-label">Service Category Name </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Service Category Name"
                                    {...register("categoryName", {
                                        required: true,
                                        pattern: {
                                            value: /\S/,
                                            message: "This field is required",
                                        },
                                    })}
                                />
                                {errors?.categoryName?.type === "pattern" && (
                                    <p className={"errorMessage"}>This field is required</p>
                                )}
                                {errors?.categoryName?.type === "required" && (
                                    <p className={"errorMessage"}>
                                        This field is required
                                    </p>

                                )}
                                {apiError !== "" && (
                                    <p className={"errorMessage"}>
                                        {apiError}
                                    </p>

                                )}

                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={` ${st.reFormBox}`}>
                                <label htmlFor="#">Business Category</label>
                                <div className={`${st.formBox} position-relative`}>
                                    <span className={`${st.multiSelbookingect_whiteBg} mt-2`}>
                                        <MultiSelect2 value={selectedBusinessCat}
                                            onChange={(data: any) => {
                                                const filterTag = serviceTagList?.filter((tagItem: any) => {
                                                    return tagItem?.businessCategoryId?.some((item: any) => data?.map((catItem: any) => catItem?.value)?.includes(item?._id))
                                                })
                                                setCategoryTag(filterTag?.map((item: any) => ({ label: item?.tags, value: item?._id })))
                                                setSelectedBusinessCat(data)
                                            }}
                                            list={savedCats?.map((item: any) => ({ label: item?.BusinessCategoryName, value: item?._id }))}
                                        />
                                    </span>
                                </div>
                                
                                {errorTrig && selectedBusinessCat?.length === 0 && (
                                    <p className={"errorMessage"}>
                                        This field is required
                                    </p>

                                )}
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={` ${st.reFormBox}`}>
                                <label htmlFor="#">Service Category Tag</label>
                                <div className={`${st.formBox} position-relative z-0`}>
                                    <span className={`${st.multiSelbookingect_whiteBg} mt-2`}>
                                        <MultiSelect2 defaultVal={cat?.serviceCategoryTag}
                                            value={categoryTag}
                                            onChange={selectHandler}
                                            list={serviceTags?.map((item: any) => ({ label: item?.tags, value: item?._id }))}
                                            disabled={serviceTags?.length === 0} />
                                    </span>
                                    {catError && categoryTag.length === 0 && (
                                        <p className={"errorMessage"}>
                                            This field is required
                                        </p>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={` ${st.reFormBox}`}>
                                <label className="form-label">Service Category Details</label>
                                <textarea className="form-control" placeholder="Enter Your Service Category Details" style={{ height: '80px' }}
                                    defaultValue={cat?.serviceCategoryDetails}
                                    {...register("categoryDetail")}
                                ></textarea>

                            </div>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={`${st.formBox} ${m.formSign} mt-1 pt-1 mb-1`}>
                        <div className={`${st.btnsGroup} ${m.formSignGroup} mt-0`}>
                            <button
                                className={`btn w-100 ${st.btn4} ${m.btnGray}`}
                                style={{ minWidth: 'auto' }}
                                onClick={handleSubmit(submitHandler)}
                                type="submit"
                            >
                                {cat?._id ? 'Update' : 'Add Service Category'}
                            </button>
                            <button
                                className={`btn w-100 ${st.btn1} ${m.btnGray} ${m.btnGrayLight}`}
                                style={{ minWidth: 'auto' }}
                                onClick={handleServicesCategoryClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddServicesCategory;