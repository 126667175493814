import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import st from "../../../assets/stylesheet/style.module.scss";
import { deleteIcon, editButton } from '../../../assets/images';
import Status from '../../../components/Business/Form/Status';
import { IoSearchSharp } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa';
import PromotionCard from '../Promotion/PromotionCard';
import { useNavigate } from 'react-router-dom';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';

function PromotionInfo(props: any) {
  const { promotionList, getPromotion, setActiveTab } = props
  const [published, setPublished] = useState<any>('');
  const [branchList, setBranchList] = useState<any>([]);
  const [query, setQuery] = useState('')
  const [branchFilter, setBranchFilter] = useState('')
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const navigate = useNavigate()

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}`, "get", null, 'json');
    if (res.status === true) {
      setBranchList(res.data);
    }
  }

  useEffect(() => {
    if (businessData) {
      getBranches()
    }
  }, [businessData])

  let publishCounter = 0
  const filterPromotion = promotionList?.filter((promotion: any) => {
    if (promotion?.isPublish) {
      publishCounter++
    }
    const queryCheck = !query ? true : promotion?.promotionName?.toLocaleLowerCase()?.replaceAll(' ', '')?.includes(query?.toLocaleLowerCase()?.replaceAll(' ', ''))
    const branchCheck = !branchFilter ? true : promotion?.branchArr?.includes(branchFilter)
    const publishCheck = published === '' || (published === 'publish' ? promotion?.isPublish : !promotion?.isPublish)
    return publishCheck && branchCheck && queryCheck
  })
  return (
    <>
      <Row>
        <Col md={12}>
          <div className='d-flex gap-3 mb-3 justify-content-end'>
            <div className={`${st.reFormBox} mb-0`} style={{ width: '155px' }}>
              <select className="form-select" value={published} onChange={(e: any) => setPublished(e.target.value)}>
                <option value="">All</option>
                <option value="publish">List on Wellnezza</option>
                <option value="unpublish">Unpublish</option>
              </select>
            </div>
            {/* <div className='text-end'>
              <button className={`${st.addGrey} btn`} onClick={() => setActiveTab('team')}>
                Next
              </button>
            </div> */}
          </div>
        </Col>
      </Row>

      <Row className="align-items-center">
        <Col md={7} className="mb-4 pb-2">
          <div className={`${st.publish_pagesHeading}`}>
            <p className='mb-0'>{publishCounter} Promotions will be published in Wellnezza</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={9}>
          <Row className="g-3 mb-3">
            <Col md={5}>
              <Row className="g-3">
                <Col md={6}>
                  <div className={`${st.reFormBox} mb-0`}>
                    <select className="form-select">
                      <option value="">Managed by</option>
                    </select>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={`${st.reFormBox} mb-0`}>
                    <select className="form-select" value={branchFilter} onChange={(e: any) => setBranchFilter(e.target.value)}>
                      <option value="">Branch</option>
                      {branchList?.map((branch: any) => {
                        return (
                          <option value={branch?._id} key={branch?._id}>{branch?.branchName}</option>
                        )
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={7}>
              <Row className="g-3">
                <Col md={12}>
                  <div className='d-flex gap-3'>
                    <div className={`position-relative ${st.reFormBox} ${st.searchBoxIcon} mb-0`}>
                      <input type="search" placeholder="Search for Promotion" className="form-control" value={query} onChange={(e: any) => setQuery(e.target.value)}/>
                      <IoSearchSharp />
                    </div>
                    {/* <button className={`btn ${st.addBlue}`} style={{ whiteSpace: 'nowrap' }}><FaPlus /> Add Promotions</button> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
         
            {filterPromotion?.map((promotion: any) => {
              return (
                <PromotionCard key={promotion?._id} type='publish' promotion={promotion} getPromotion={getPromotion} />
              )
            })}
          
        </Col>
      </Row>
    </>
  )
}

export default PromotionInfo