import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
    massage,
    hair,
    lashes,
    nails,
    facilities,
    waxfill,
    makeupfill,
} from "../../../assets/images";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { Switch } from "@mui/material";
import { httpRequest } from "../../../Apis/commonApis";
import ErrorPopup from "./ErrorPopup";

const DurationServiceModal = (props: any) => {
    let {
        showAddCategory,
        handleAddCategoryClose,
        setSaleModalShow,
        service: services,
        packageData,
        serviceData1,
        availableBranchId = null,
        existData,
    } = props;

    const [count, setCount] = useState(0);
    const [showBranch, setshowBranch] = useState<any[]>([]);
    const [service, setService] = useState<any>([]);

    useEffect(() => {
        if (services) {
            setService(services)
        }
    }, [services])

    let clear = () => {
        setshowBranch([]);
    };
    const [isChecked, setIsChecked] = useState<any>([]);
    const [serviceData, setServiceData] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState("");

    const [showErrorModal, setShowErrorModal] = useState("");

    const handleAlertModalClose = () => setShowErrorModal("");

    useEffect(() => {
        if (existData?.length !== 0) {
            setServiceData(existData)
        }
    }, [existData])
    let savePackage = () => {
        packageData(serviceData);
    };

    function compareArrays(arr1: any, arr2: any) {
        // Check if every item in arr1 is included in arr2
        return arr2.every((item: any) => arr1.includes(item));
    }

    // Function to toggle the checkbox state
    const toggleCheckbox = (e: any, data: any) => {
        const checkServiceAvailableInBranch = availableBranchId ? compareArrays(data?.branchId, availableBranchId) : true;
        if (!checkServiceAvailableInBranch) {
            let checkdata = isChecked?.filter((obj: any) => obj !== e.target.value);
            setShowErrorModal(`Error, this service is not available in selected branch(s)`)
            setIsChecked(checkdata);
            return
        }


        const check = e.target.checked;
        if (check) {
            setIsChecked([...isChecked, e.target.value]);
            setServiceData([...serviceData, data]);
        } else {
            let filteredArray = serviceData?.filter(
                (obj: any) => obj?._id !== data?._id
            );
            let checkdata = isChecked?.filter((obj: any) => obj !== e.target.value);
            setServiceData(filteredArray);
            setIsChecked(checkdata);
        }
    };

    useEffect(() => {
        if (searchTerm !== "") {
            const filterData = services?.filter((data: any) => {
                return data?.categoryName?.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase());
            })
            setService(filterData)
        } else {
            setService(services)
        }
    }, [searchTerm])

    return (
        <>
            <Modal
                scrollable
                centered
                backdrop="static"
                keyboard={false}
                show={showAddCategory}
                onHide={handleAddCategoryClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`${m.modalCts} ${m.modalSm} ${m.ModalHeaderBG} ${showErrorModal !== "" ? m.hide : ""}`}
            >
                <Modal.Header>
                    <Modal.Title>
                        <NavLink
                            to="#"
                            onClick={() => {
                                handleAddCategoryClose();
                                setSaleModalShow && setSaleModalShow(true);
                            }}
                        >
                            <svg
                                width="38"
                                height="37"
                                viewBox="0 0 38 37"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                                    fill="#3C746D"
                                ></path>
                                <path
                                    d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                                    stroke="#ACD9D0"
                                ></path>
                                <path
                                    d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                                    stroke="#3C746D"
                                ></path>
                                <path
                                    d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                                    fill="#3C746D"
                                ></path>
                                <path
                                    d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                                    stroke="#ACD9D0"
                                ></path>
                                <path
                                    d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                                    stroke="#3C746D"
                                ></path>
                            </svg>
                        </NavLink>
                        Select Service
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <Row>
                        <Col md={12}>
                            <div className={`${m.searchBox}`}>
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search by name"
                                    onChange={(e: any) => setSearchTerm(e.target.value)}
                                    value={searchTerm}
                                />
                                <FiSearch />
                            </div>
                        </Col>
                        <Accordion className={`${m.serviceListCard}`}>
                            {service !== undefined
                                ? service.map((val: any, index: any) => {
                                    let services: any = []
                                    val.data?.forEach((data: any) => {
                                        if (services?.some((service: any) => service?.serviceId === data?.BusinessService?._id)) {
                                            return
                                        } else {
                                            services?.push(data)
                                        }
                                    })
                                    const old: any = [];
                                    services?.forEach((data: any) => {
                                        const newExistData = existData?.map((data: any) => data?.BusinessService?._id);
                                        if (newExistData?.includes(data?.BusinessService?._id)) {
                                            old?.push({ categoryName: val?.categoryName, count: 2, data })
                                        }
                                    })
                                    if (old?.length !== 0 && Number(val.count) - old?.length === 0) {
                                        return
                                    }
                                    return (
                                        <Accordion.Item eventKey={index} key={index}>
                                            <Accordion.Header>
                                                {val?.categoryName}
                                                <span>({old?.length !== 0 ? Number(services?.length) - old?.length : services?.length})</span>
                                                {val?.serviceId?.serviceName}
                                            </Accordion.Header>
                                            {services?.map((list: any) => {

                                                if (
                                                    existData?.some((data: any) => data?.BusinessService?._id === list?.BusinessService?._id)
                                                ) {
                                                    return;
                                                }

                                                const selectedService = serviceData?.find((item: any) => item?._id === list?._id);

                                                return (
                                                    <Accordion.Body key={list._id}>
                                                        <ul>
                                                            <li>
                                                                <div className={`${m.bodyLeft}`}>
                                                                    <h5>{list?.BusinessService?.serviceName}</h5>

                                                                </div>
                                                                <div className={`${m.bodyRight}`}>
                                                                    <label
                                                                        className={`d-block ${st.checkbox} mb-4`}
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            value={list?._id}
                                                                            checked={selectedService?._id !== undefined}
                                                                            onClick={(e: any) => {
                                                                                toggleCheckbox(e, list);
                                                                            }}
                                                                        />
                                                                        <span
                                                                            className={`${st.checkmark}`}
                                                                        ></span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Accordion.Body>
                                                );
                                            })}
                                        </Accordion.Item>
                                    );
                                })
                                : serviceData1?.map((val: any, index: any) => {

                                    return (
                                        <Accordion.Item eventKey={index} key={index}>
                                            <Accordion.Header>
                                                {
                                                    val?.serviceId?.serviceCatagoryId
                                                        ?.serviceCategoryDetails
                                                }
                                            </Accordion.Header>

                                            <Accordion.Body>
                                                <ul>
                                                    <li>
                                                        <div className={`${m.bodyLeft}`}>


                                                            <h5>
                                                                {
                                                                    val?.serviceId?.serviceCatagoryId
                                                                        ?.serviceCategoryName
                                                                }
                                                            </h5>
                                                            <h5>
                                                                {
                                                                    val?.serviceId?.serviceCatagoryId
                                                                        ?.serviceCategoryTag
                                                                }
                                                            </h5>
                                                            <p>{val?.duration}</p>
                                                        </div>
                                                        <div className={`${m.bodyRight}`}>
                                                            <label
                                                                className={`d-block ${st.checkbox} mb-4`}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    value={val?._id}
                                                                    onClick={(e: any) => {
                                                                        toggleCheckbox(e, val);
                                                                    }}
                                                                />
                                                                <span className={`${st.checkmark}`}></span>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Accordion.Body>

                                        </Accordion.Item>
                                    );
                                })}

                        </Accordion>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <button
                        type="button"
                        className={`btn ${m.cancelBtn}`}
                        onClick={() => {
                            handleAddCategoryClose();
                            clear();
                            setSaleModalShow && setSaleModalShow(true);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className={`btn ${m.actionBtn}`}
                        onClick={() => {
                            handleAddCategoryClose();
                            savePackage();
                        }}
                    >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
        </>
    );
};

export default DurationServiceModal;
