import React, { useEffect, useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { business, deleteIcon, plusWhite } from '../../../assets/images';
import AddServiceModal from '../../../components/Business/Modals/AddServiceModal';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import LoadingBtn from '../../../components/Business/Buttons/LoadingBtn';
import HelpButon from '../../../components/Business/HelpButon.tsx/HelpButton';
import AddBundleModal from '../../../components/Business/Modals/AddBundleModal';
import PromotionFormattedServices from '../../../components/Business/PromotionFormattedServices/PromotionFormattedServices';
import HoverEyeContent from '../../../components/Business/HoverEyeContent/HoverEyeContent';
import { FaPlus } from 'react-icons/fa';

export default function ServicesTab(props: any) {
    const { handleStepsFormData, loading, userData, editPromotionData, type = "", editLoading } = props;
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [errorTrig, setErrorTrig] = useState<any>(false)
    const [service, setService] = useState<any[]>();
    const [showAddCategory, setAddCategoryShow] = useState<any>(false);
    const handleAddCategoryClose = () => setAddCategoryShow(false);
    const [serviceType, setServiceType] = useState<any>("service")
    const handleAddCategoryShow = () => setAddCategoryShow(true);
    const [selectedServices, setSelectedServices] = useState<any>([]);
    const [selectedBundle, setSelectedBundle] = useState<any>([]);


    const [bundleList, setBundleList] = useState<any>([])

    const [selectedPriceInput, setSelectedPriceInput] = useState<any>([]);
    const [selectedBundleInput, setSelectedBundleInput] = useState<any>([]);

    let serviceHandler = (data: any) => {
        if (data?.some((item: any) => item?.type === "bundle")) {
            setSelectedBundle([...selectedBundle, ...data])
            return
        }
        setSelectedServices(data);
    }

    const getBusinessPrice = async (id: any) => {
        if (id) {
            let res = await httpRequest(`getBusinessPrice?businessId=${id}&type=package`, "get", null, "json");
            if (res.status) {
                setService(res?.data);
            }
        }
    };

    const fetchBundles = async () => {
        const res = await httpRequest(`getBundle?businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setBundleList(res?.data)
        }
    }

    useEffect(() => {
        if (businessData) {
            getBusinessPrice(businessData?._id);
            fetchBundles()

        }
    }, [businessData])

    const groupedByCategory = selectedServices?.reduce((acc: any, item: any) => {
        const category = item?.ServiceCategory?.serviceCategoryName;
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(item);
        return acc;
    }, {});

    const serviceArray = Object.values(groupedByCategory)
    const categoryArr = Object.keys(groupedByCategory);

    function handleDelete(id: any, type: string = "") {
        if (type === "bundle") {
            const filterBundle = selectedBundle?.filter((bundle: any) => bundle?._id !== id);
            const filterInput = selectedBundleInput?.filter((item: any) => item?.id !== id);
            setSelectedBundleInput(filterInput)
            setSelectedBundle(filterBundle)
        } else {
            const filterServices = selectedServices?.filter((item: any) => item?._id !== id);
            setSelectedServices(filterServices)
            const filterInput = selectedPriceInput?.filter((item: any) => item?.id !== id);
            setSelectedPriceInput(filterInput)
        }
    }



    useEffect(() => {
        if (selectedServices?.length !== 0) {
            const generatePriceInputs = selectedServices?.map((item: any) => {
                const existIndex = selectedPriceInput?.findIndex((priceItem: any) => priceItem?.id === item?._id);
                const existInput = selectedPriceInput?.[existIndex];

                const allTypeInput = selectedPriceInput?.find((priceItem: any) => priceItem?.type === "all");
                const catTypeInput = selectedPriceInput?.find((priceItem: any) => priceItem?.type === "category" && priceItem?.categoryId === item?.ServiceCategory?._id);
                const serviceTypeInput = selectedPriceInput?.find((priceItem: any) => priceItem?.type === "service" && priceItem?.serviceId === item?.BusinessService?._id);

                const regularPrice = Number.parseFloat(item?.seasonPrice);
                const discount = existInput?.discount ? existInput?.discount : allTypeInput ? allTypeInput?.discount : catTypeInput ? catTypeInput?.discount : serviceTypeInput ? serviceTypeInput?.discount : "";


                const discountType = discount?.includes("%") ? "%" : "usd"
                let promotionalPrice = existInput?.promotionalPrice ? existInput?.promotionalPrice : discount ? discountType === "%" ? (regularPrice - (regularPrice * (Number.parseInt(discount) / 100)))?.toFixed(2) : regularPrice - Number.parseInt(discount) : ""

                return {
                    id: item?._id,
                    regularPrice,
                    discount,
                    promotionalPrice,
                    categoryId: item?.ServiceCategory?._id,
                    serviceId: item?.BusinessService?._id,
                    type: existInput?.type ? existInput?.type : allTypeInput ? allTypeInput?.type : catTypeInput ? catTypeInput?.type : serviceTypeInput ? serviceTypeInput?.type : null,
                }
            })
            setSelectedPriceInput(generatePriceInputs)
        }
    }, [selectedServices])

    useEffect(() => {
        if (selectedBundle?.length !== 0) {
            const generateBundleInput: any = []


            selectedBundle?.forEach((bundle: any) => {
                const existIndex = selectedBundleInput?.findIndex((bundleItem: any) => bundleItem?.id === bundle?._id);
                const existInput = selectedBundleInput?.[existIndex];

                const discount = existInput?.discount ? existInput?.discount : "";
                const regularPrice = Number.parseFloat(bundle?.finalPrice);


                const discountType = discount?.includes("%") ? "%" : "usd"
                let promotionalPrice = existInput?.promotionalPrice ? existInput?.promotionalPrice : discount ? discountType === "%" ? (regularPrice - (regularPrice * (Number.parseInt(discount) / 100)))?.toFixed(2) : regularPrice - Number.parseInt(discount) : ""


                generateBundleInput.push({
                    id: bundle?._id,
                    regularPrice,
                    discount,
                    promotionalPrice,
                    type: "bundle"
                })
            })

            setSelectedBundleInput(generateBundleInput)
        }
    }, [selectedBundle])


    const inputHandler = (type: any, element: any, dynamicId: any) => {
        let updatedPriceInput: any = [...selectedPriceInput];

        if (type === "bundle") {
            let updateBundleInput = [...selectedBundleInput];
            const priceIndex: number = updateBundleInput?.findIndex((item: any) => item?.id === dynamicId);
            const regularPrice = Number.parseFloat(updateBundleInput[priceIndex]?.regularPrice);
            updateBundleInput[priceIndex] = {
                ...updateBundleInput[priceIndex],
                type: "bundle",
                discount: element.value === "" ? "" : element?.id === "discount percent" ? `${element.value} %` : `${element.value} usd`,
                promotionalPrice: element.value === "" ? "" : element?.id === "discount percent" ? (regularPrice - (regularPrice * (+element.value / 100)))?.toFixed(2) : +element.value
            }

            setSelectedBundleInput(updateBundleInput)
            return
        }

        if (type === "all" && !dynamicId) {
            updatedPriceInput = updatedPriceInput?.map((item: any) => {
                const regularPrice = Number.parseFloat(item?.regularPrice);
                return {
                    ...item,
                    type: "all",
                    discount: element.value === "" ? "" : element?.id === "discount percent" ? `${element.value} %` : `${element.value} usd`,
                    promotionalPrice: element.value === "" ? "" : element?.id === "discount percent" ? (regularPrice - (regularPrice * (+element.value / 100)))?.toFixed(2) : +element.value

                }
            })
        }
        if (type === "price") {
            const priceIndex: number = updatedPriceInput?.findIndex((item: any) => item?.id === dynamicId);
            const regularPrice = Number.parseFloat(updatedPriceInput[priceIndex]?.regularPrice);
            updatedPriceInput[priceIndex] = {
                ...updatedPriceInput[priceIndex],
                type: "price",
                discount: element.value === "" ? "" : element?.id === "discount percent" ? `${element.value} %` : `${element.value} usd`,
                promotionalPrice: element.value === "" ? "" : element?.id === "discount percent" ? (regularPrice - (regularPrice * (+element.value / 100)))?.toFixed(2) : +element.value
            }
        }
        if (type === "service") {
            let allPrices = updatedPriceInput?.filter((item: any) => item?.serviceId === dynamicId);
            const allFilterPrices = updatedPriceInput?.filter((item: any) => item?.serviceId !== dynamicId);
            allPrices = allPrices?.map((item: any) => {
                const regularPrice = Number.parseFloat(item?.regularPrice);
                return {
                    ...item,
                    type: "service",
                    discount: element.value === "" ? "" : element?.id === "discount percent" ? `${element.value} %` : `${element.value} usd`,
                    promotionalPrice: element.value === "" ? "" : element?.id === "discount percent" ? (regularPrice - (regularPrice * (+element.value / 100)))?.toFixed(2) : +element.value

                }
            })
            updatedPriceInput = [...allFilterPrices, ...allPrices]
        }
        if (type === "category") {
            let allPrices = updatedPriceInput?.filter((item: any) => item?.categoryId === dynamicId);
            const allFilterPrices = updatedPriceInput?.filter((item: any) => item?.categoryId !== dynamicId);
            allPrices = allPrices?.map((item: any) => {
                const regularPrice = Number.parseFloat(item?.regularPrice);
                return {
                    ...item,
                    type: "category",
                    discount: element.value === "" ? "" : element?.id === "discount percent" ? `${element.value} %` : `${element.value} usd`,
                    promotionalPrice: element.value === "" ? "" : element?.id === "discount percent" ? (regularPrice - (regularPrice * (+element.value / 100)))?.toFixed(2) : +element.value

                }
            })
            updatedPriceInput = [...allFilterPrices, ...allPrices]
        }

        setSelectedPriceInput(updatedPriceInput)
    }

    useEffect(() => {
        if (editPromotionData?.type) {
            setServiceType(editPromotionData?.type)
        }
        if (editPromotionData?.type === "service" && editPromotionData?.priceId?.length !== 0) {
            const existInput: any = [];
            const services = editPromotionData?.priceId?.map((item: any) => {
                let input = {
                    id: item?.id?._id,
                    regularPrice: Number.parseFloat(item?.id?.seasonPrice),
                    discount: item?.discount,
                    promotionalPrice: `${item?.promotionalPrice}`,
                    categoryId: item?.categoryId,
                    serviceId: item?.serviceId,
                    bundleId: item?.bundleId,
                    type: item?.type,
                }

                existInput.push(input)
                return {
                    ...item?.id,
                    BusinessService: item?.id?.serviceId,
                    ServiceCategory: item?.id?.serviceId?.serviceCatagoryId
                }
            });


            setSelectedPriceInput(existInput);
            setSelectedServices(services)
        }

        if (editPromotionData?.type === "bundle") {
            let bundleInput: any = []
            const selectedBundle = editPromotionData?.bundleId?.map((item: any) => {
                bundleInput.push({ ...item, id: item?.id?._id })
                return item?.id
            })
            setSelectedBundle(selectedBundle)
            setSelectedBundleInput(bundleInput)

        }
    }, [editPromotionData])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (selectedPriceInput?.length !== 0 && selectedPriceInput?.some((item: any) => item?.discount === "")) {
            setErrorTrig(true)
            return
        }
        if (selectedBundleInput?.length !== 0 && selectedBundleInput?.some((item: any) => item?.discount === "")) {
            setErrorTrig(true)
            return
        }

        if (selectedPriceInput?.length === 0 && selectedBundleInput?.length === 0) {
            setErrorTrig(true)
            return
        }
        let basicData: any = {
            priceId: selectedPriceInput,
            bundleId: selectedBundleInput,
            type: serviceType,
        }
        handleStepsFormData(basicData, 'step3');
        setErrorTrig(false)
    }

    return (
        <>
            {showAddCategory && <AddServiceModal
                service={service}
                bundleList={bundleList}
                packageData={serviceHandler}
                showAddCategory={showAddCategory}
                handleAddCategoryClose={handleAddCategoryClose}
                existData={selectedServices}
                serviceType={serviceType}
                selectedBundle={selectedBundle}
            />}
            <Row>
                <Col md={12}>
                    <div className='d-flex gap-5'>
                        <p className={`${st.tabbing_para}`}>Choose the services where this promotion will be valid. You have the flexibility to apply it
                            to a whole category, individual services, or even specific price tiers</p>
                        <div className={`${st.formBox} ${st.formSign} w-auto`}>
                            <div className={`${st.btnsGroup} ${st.formSignGroup} mt-0 pb-3 justify-content-end`}>
                                {/* <button className={`btn ${st.btn1} ${st.btnGray} ${st.btnGrayLight}`} onClick={(e: any) => {
                                e.preventDefault();
                                handleStepsFormData('prevStep', `${type}blackout`);
                            }}>Back</button> */}
                                <button className={`btn ${st.btn4} ${st.btnGray} mx-0`} onClick={handleSubmit} disabled={loading || editLoading}>
                                    {loading ? <LoadingBtn /> : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            {(selectedServices?.length === 0 && selectedBundle?.length === 0) && <div className={`${st.newRedesign_main}`}>
                <Row>
                    <Col md={10}>
                        <div className={`${st.branch_maintain_bg} `}>
                            <h3 className='text-start w-100'>Add Services / Bundles</h3>
                            {selectedPriceInput?.length === 0 && errorTrig && <p className='errorMessage mt-2'>Please add atleast one service</p>}
                            <Row>
                                <Col md={6}>
                                    <p>Add your next location if your services are available in multiple branches.</p>
                                    <button className="btn" onClick={(e: any) => {
                                        e.preventDefault()
                                        handleAddCategoryShow();
                                        setServiceType("service")
                                    }}><FaPlus /> Add Service</button>
                                </Col>
                                <Col md={6}>
                                    <p>Once you've added all your branches and locations, be sure to add your service menu!</p>
                                    <button className="btn" onClick={(e: any) => {
                                        e.preventDefault()
                                        handleAddCategoryShow();
                                        setServiceType("bundle")
                                    }}><FaPlus /> Add Bundles</button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>}
            {(selectedServices?.length !== 0 || selectedBundle?.length !== 0) && (
                <>
                    <Row>
                        <Col md={12}>
                            <div className="text-end">
                                <HelpButon number={49} type={"help"} className={"btn"} />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={`${st.formBox} ${cx.locationSelect} d-flex justify-content-between`}>
                                <h2>Add Services / Bundles  <HoverEyeContent number={1021} /></h2>
                                {selectedPriceInput?.length !== 0 && errorTrig && selectedPriceInput?.some((item: any) => item?.discount === "") && <p className='errorMessage'>Please add discount in every service</p>}
                                {selectedBundleInput?.length !== 0 && errorTrig && selectedBundleInput?.some((item: any) => item?.discount === "") && <p className='errorMessage'>Please add discount in every bundle</p>}
                            </div>

                        </Col>
                    </Row>

                    <div className={`${st.serviceBundle_tableList}`}>
                        <Row>
                            {(selectedServices?.length !== 0 || selectedBundle?.length !== 0) && (
                                <>
                                    <div className={cx.header}>
                                        <ul className={cx.headerList} style={{ alignItems: 'flex-start' }}>
                                            <li className={cx.services}>
                                                <div className={cx.services_labeling} style={{ fontSize: '17px', fontWeight: '300' }}>
                                                    {selectedBundle?.length !== 0 ? "bundles" : "Services"}
                                                </div>
                                            </li>
                                            <li className={cx.duration}>
                                                <div className={cx.netDis_prices}>
                                                    Duration
                                                </div>
                                            </li>
                                            <li>
                                                <div className={cx.netDis_prices}>
                                                    {selectedBundle?.length !== 0 ? "Bundle" : "Regular"} Price
                                                </div>
                                            </li>
                                            <li>
                                                <div className={cx.netDis_prices}>
                                                    Discount (%)
                                                </div>
                                            </li>
                                            <li>
                                                <div className={cx.netDis_prices}>
                                                    Net Price
                                                </div>
                                            </li>
                                            <li>
                                                <div className={cx.netDis_prices}>
                                                    Final Price(SGD)
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )}


                        </Row>
                        <Row>
                            {selectedServices?.length !== 0 && (
                                <>
                                    <div className={cx.header}>
                                        <ul className={cx.headerList} style={{ paddingBottom: '2px', marginBottom: '9px' }} >
                                            <li className={cx.services}>
                                                <div className={cx.services_labeling} style={{ position: 'relative', top: '4px', fontWeight: '500' }}>
                                                    Apply discount to all services
                                                </div>
                                            </li>
                                            <li className={cx.duration}>
                                                <div className={cx.netDis_prices}>

                                                </div>
                                            </li>
                                            <li>
                                                <div className={cx.netDis_prices}>

                                                </div>
                                            </li>
                                            <li>
                                                <div className={cx.netDis_prices}>
                                                    <div className={`${st.formBox} ${cx.inputBox} mb-1`}>
                                                        <input
                                                            type="number"
                                                            id="discount percent"
                                                            min={0}
                                                            max={100}
                                                            className={`form-control`}
                                                            onClick={(e) => e.stopPropagation()}
                                                            value={selectedPriceInput?.length !== 0 && selectedPriceInput?.[0]?.type === "all" ? Number.parseInt(selectedPriceInput?.[0]?.discount) : ""}
                                                            onChange={(e: any) => {
                                                                e.preventDefault()
                                                                if (e.target.value <= 100) {
                                                                    inputHandler("all", e.target, null)
                                                                }
                                                            }}
                                                        />
                                                        (%)
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={cx.netDis_prices}>

                                                </div>
                                            </li>
                                            <li>
                                                <div className={cx.netDis_prices}>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )}

                        </Row>

                        <Row>
                            {selectedBundle?.length > 0 && selectedBundle?.map((bundle: any, b: any) => {

                                const existBundle = selectedBundleInput?.find((item: any) => item?.id === bundle?._id)
                                const totalBundleDuration = bundle?.priceId?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.id?.duration), 0)

                                return (
                                    <div className={cx.bundleScreen} key={b}>
                                        <div className={cx.service_addAccordion}>
                                            <Accordion>
                                                <Accordion.Item eventKey={b}>
                                                    <Accordion.Header>
                                                        <div className={cx.discountScreen}>
                                                            <div className={cx.descBox}>
                                                                <h5 className={cx.bundleName}>{bundle?.bundleName}</h5>
                                                                <h5 className={cx.duration}>{`${totalBundleDuration} mins`}</h5>
                                                                <h5 className={cx.price}>{`SGD ${bundle?.finalPrice}`}</h5>
                                                            </div>

                                                            <div className={cx.discountBox}>
                                                                <div className={`${st.formBox} ${cx.inputBox}`}>
                                                                    <input
                                                                        type="number"
                                                                        id="discount percent"
                                                                        min={0}
                                                                        max={100}
                                                                        value={existBundle?.discount?.includes("%") ? Number.parseInt(existBundle?.discount) : ""}
                                                                        className={`form-control`}
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        onChange={(e: any) => {
                                                                            e.preventDefault()
                                                                            if (e.target.value <= 100) {
                                                                                inputHandler("bundle", e.target, bundle?._id)
                                                                            }
                                                                        }}
                                                                    />
                                                                    (%)
                                                                </div>
                                                                <div className={`${st.formBox} ${cx.inputBox}`}>
                                                                    <input
                                                                        style={{ minWidth: '58px' }}
                                                                        type="number"
                                                                        id="discount amount"
                                                                        value={existBundle?.discount?.includes("usd") ? Number.parseInt(existBundle?.discount) : ""}
                                                                        className={`form-control`}
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        onChange={(e: any) => {
                                                                            e.preventDefault()
                                                                            if (e.target.value <= Number.parseFloat(bundle?.finalPrice)) {
                                                                                inputHandler("bundle", e.target, bundle?._id)
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className={`${st.formBox} ${cx.inputBox} ${cx.finalPrice}`}>
                                                                    <input
                                                                        style={{ minWidth: '60px' }}
                                                                        type="text"
                                                                        id="finalPrice"
                                                                        // value={existPrice?.promotionalPrice}
                                                                        value={`${existBundle?.promotionalPrice ? Number.parseFloat(existBundle?.promotionalPrice)?.toFixed(2) : ""}`}
                                                                        className={`form-control`}
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className={cx.actionButton}>
                                                                <img src={deleteIcon} alt="Delete" onClick={() => handleDelete(bundle?._id, "bundle")} />

                                                            </div>

                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul className={cx.bundleServices}>
                                                            {bundle?.priceId?.map((price: any, p: any) => {
                                                                const listItem = price?.id;
                                                                return (
                                                                    <div key={p} className={`${cx.itemContainer} ${cx.bundle}`}>
                                                                        <li>
                                                                            <div className={`${cx.bodyLeft}`}>
                                                                                {/* <p>{`Price : ${listItem?.seasonPrice} SGD / Duration : ${listItem?.duration}`}</p>
                                                                                                        <p>{`Team title : ${listItem?.TeamTitleData?.teamTitle || ""}`}</p> */}
                                                                                <div className={`${cx.category_selected} d-flex align-items-center gap-3`}>
                                                                                    <h5 className={cx.serviceName}>{listItem?.serviceId?.serviceName || ""}</h5>
                                                                                    <h5>{listItem?.priceType === "from" ? "Fm" : ""} {listItem?.duration}</h5>
                                                                                    <h5 className={cx.teamTitle}>{listItem?.TeamTitleData?.teamTitle ? listItem?.TeamTitleData?.teamTitle : "Any Stylist"}</h5>
                                                                                    <h5 className={cx.priceText}>{listItem?.priceText ? listItem?.priceText : ""}</h5>
                                                                                </div>

                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                )
                                                            })}

                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                )
                            })}
                            <PromotionFormattedServices
                                key="services"
                                cx={cx}
                                inputHandler={inputHandler}
                                selectedPriceInput={selectedPriceInput}
                                handleDelete={handleDelete}
                                serviceArray={serviceArray}
                                categoryArr={categoryArr}
                            />
                        </Row>

                        <Row className='align-items-start '>
                            {selectedBundle?.length === 0 && <Col md={12}>
                                <div className='mt-3'>
                                    {/* <p>Add your next location if your services are available in multiple branches.</p> */}
                                    <button className={`btn ${st.addBlue}`}

                                        onClick={(e: any) => {
                                            e.preventDefault()
                                            handleAddCategoryShow();
                                            setServiceType("service")
                                        }}
                                    >
                                        <FaPlus /> Add Service
                                    </button>
                                    {selectedPriceInput?.length === 0 && errorTrig && <p className='errorMessage mt-2'>Please add atleast one service</p>}
                                </div>

                            </Col>}
                            {selectedServices?.length === 0 && <Col md={12}>
                                {/* <p>Add your next location if your services are available in multiple branches.</p> */}
                                <button className={`btn ${st.addBlue}`}
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        handleAddCategoryShow();
                                        setServiceType("bundle")
                                    }}
                                >
                                    <FaPlus />Add Bundles
                                </button>
                            </Col>}
                        </Row>
                    </div>
                </>
            )}

        </>
    )
}
