import React, { useEffect } from "react";
import cx from "./index.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import st from "../../../assets/stylesheet/style.module.scss";

import {
  barberShop,
  circlePattern,
  formLocation,
  hairIcon,
  hairImg,
  halfCirclePattern,
  locationGrad,
  makeupIcon,
  makeupImg,
  massageIcon,
  massageImg,
  test,
  triPattern,
  waxIcon,
  waxImg,
} from "../../../assets/images";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from "react-router-dom";

import Marquee from "react-fast-marquee";
import {  Brands, Deals } from "../../../components/Website";
import { Slider } from "@mui/material";

const Home = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = {
    loop: true,
    items: 4,
    dots: false,
    nav:true,
    navText:[`<svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z" fill="#3C746D"/>
    <path d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z" stroke="#ACD9D0"/>
    <path d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z" stroke="#3C746D"/>
    <path d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z" fill="#3C746D"/>
    <path d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z" stroke="#ACD9D0"/>
    <path d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z" stroke="#3C746D"/>
    </svg>
    `,`<svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9173 19.6484H22.4173V19.1484V17.6484V17.1484H21.9173H1.35359H0.853586V17.6484V19.1484V19.6484H1.35359H21.9173Z" fill="#3C746D"/>
    <path d="M21.9173 19.6484H22.4173V19.1484V17.6484V17.1484H21.9173H1.35359H0.853586V17.6484V19.1484V19.6484H1.35359H21.9173Z" stroke="#ACD9D0"/>
    <path d="M21.9173 19.6484H22.4173V19.1484V17.6484V17.1484H21.9173H1.35359H0.853586V17.6484V19.1484V19.6484H1.35359H21.9173Z" stroke="#3C746D"/>
    <path d="M13.9631 29.8577L13.9238 30.3946L14.4621 30.3942L15.3567 30.3936L15.8169 30.3933L15.8547 29.9346C15.8564 29.9149 16.0167 28.0946 17.1646 25.9007L17.1646 25.9007C18.2302 23.8637 20.4015 21.0565 24.7011 19.3307L24.7011 19.3307L25.9902 18.8131L27.1462 18.349L25.9902 17.8851L24.7006 17.3676C20.4014 15.6417 18.2297 12.8352 17.1641 10.7976L17.1641 10.7976C16.0163 8.60314 15.8559 6.78348 15.8542 6.76368C15.8542 6.76363 15.8542 6.76359 15.8542 6.76357L15.8163 6.30403L15.3551 6.30469L14.9095 6.30533L14.9088 6.30533L14.9081 6.30533L14.4625 6.30469L13.9205 6.30391L13.9633 6.84421L13.9634 6.84495C13.9734 6.97187 14.1499 9.19771 15.4895 11.7863L15.4896 11.7864C16.2723 13.2984 17.2948 14.6629 18.5283 15.8407L18.5283 15.8407C19.5392 16.8059 20.6921 17.6448 21.9705 18.3492C20.6921 19.0538 19.5391 19.893 18.5278 20.8581L18.5278 20.8582C17.2943 22.036 16.2718 23.4005 15.489 24.9125L15.489 24.9126C14.1468 27.506 13.972 29.7356 13.9631 29.8577Z" fill="#3C746D"/>
    <path d="M13.9631 29.8577L13.9238 30.3946L14.4621 30.3942L15.3567 30.3936L15.8169 30.3933L15.8547 29.9346C15.8564 29.9149 16.0167 28.0946 17.1646 25.9007L17.1646 25.9007C18.2302 23.8637 20.4015 21.0565 24.7011 19.3307L24.7011 19.3307L25.9902 18.8131L27.1462 18.349L25.9902 17.8851L24.7006 17.3676C20.4014 15.6417 18.2297 12.8352 17.1641 10.7976L17.1641 10.7976C16.0163 8.60314 15.8559 6.78348 15.8542 6.76368C15.8542 6.76363 15.8542 6.76359 15.8542 6.76357L15.8163 6.30403L15.3551 6.30469L14.9095 6.30533L14.9088 6.30533L14.9081 6.30533L14.4625 6.30469L13.9205 6.30391L13.9633 6.84421L13.9634 6.84495C13.9734 6.97187 14.1499 9.19771 15.4895 11.7863L15.4896 11.7864C16.2723 13.2984 17.2948 14.6629 18.5283 15.8407L18.5283 15.8407C19.5392 16.8059 20.6921 17.6448 21.9705 18.3492C20.6921 19.0538 19.5391 19.893 18.5278 20.8581L18.5278 20.8582C17.2943 22.036 16.2718 23.4005 15.489 24.9125L15.489 24.9126C14.1468 27.506 13.972 29.7356 13.9631 29.8577Z" stroke="#ACD9D0"/>
    <path d="M13.9631 29.8577L13.9238 30.3946L14.4621 30.3942L15.3567 30.3936L15.8169 30.3933L15.8547 29.9346C15.8564 29.9149 16.0167 28.0946 17.1646 25.9007L17.1646 25.9007C18.2302 23.8637 20.4015 21.0565 24.7011 19.3307L24.7011 19.3307L25.9902 18.8131L27.1462 18.349L25.9902 17.8851L24.7006 17.3676C20.4014 15.6417 18.2297 12.8352 17.1641 10.7976L17.1641 10.7976C16.0163 8.60314 15.8559 6.78348 15.8542 6.76368C15.8542 6.76363 15.8542 6.76359 15.8542 6.76357L15.8163 6.30403L15.3551 6.30469L14.9095 6.30533L14.9088 6.30533L14.9081 6.30533L14.4625 6.30469L13.9205 6.30391L13.9633 6.84421L13.9634 6.84495C13.9734 6.97187 14.1499 9.19771 15.4895 11.7863L15.4896 11.7864C16.2723 13.2984 17.2948 14.6629 18.5283 15.8407L18.5283 15.8407C19.5392 16.8059 20.6921 17.6448 21.9705 18.3492C20.6921 19.0538 19.5391 19.893 18.5278 20.8581L18.5278 20.8582C17.2943 22.036 16.2718 23.4005 15.489 24.9125L15.489 24.9126C14.1468 27.506 13.972 29.7356 13.9631 29.8577Z" stroke="#3C746D"/>
    </svg>
    `],
    autoplay: false,
    responsive : {
        0 : {
          items: 1,
        },
        480 : {
          items: 1,
        },
        768 : {
          items: 3,
        },
        992 : {
          items: 4,
        }
    }
  };
  

  return (
    <>
        <section className={cx.heroSection}>
          <img src={triPattern} alt="pattern" className={cx.triPattern} />
          <Container>
            <Row>
              <Col lg={6} md={12}>
                <div className={cx.left}>
                  <p className={cx.subHeading}>We Have 208,000+ Live Deals</p>
                  <h2 className={cx.heading}>
                    Experience Timeless Luxury, Redefined for Women
                  </h2>
                  <div className={cx.description}>
                    <p>
                      Our beauty specialists offer a wide range of services,
                      from glamorous hairstyles and makeup to spa treatments
                      that can be performed both at your home and at their place
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className={cx.right}>
                  <img
                    src={circlePattern}
                    alt="pattern"
                    className={cx.circlePattern}
                  />
                  <form className={cx.form}>
                    <div className={cx.entry}>
                      <label htmlFor="service">Service</label>
                      <input
                        type="text"
                        placeholder="What are you looking for"
                        id="service"
                      />
                    </div>
                    <div className={cx.entry}>
                      <label htmlFor="type">Type</label>
                      <select name="type" id="type">
                        <option value="Service Type"> Service Type</option>
                      </select>
                    </div>

                    <div className={cx.entry}>
                      <label htmlFor="location">Location</label>
                      <input
                        type="text"
                        className={cx.v2}
                        placeholder="location"
                        id="location"
                      />
                      <img src={formLocation} alt="location" />
                    </div>
                    <div className={cx.entry}>
                      <label htmlFor="popularity" className={cx.green}>
                        Most popular:
                      </label>
                      <ul className={cx.servicePopularity}>
                        <li>
                          <span className={cx.popularService}>Hair</span>
                        </li>
                        <li>
                          <span className={cx.popularService}>Massage</span>
                        </li>
                        <li>
                          <span className={cx.popularService}>Nails</span>
                        </li>
                        <li>
                          <span className={cx.popularService}>Facial</span>
                        </li>
                        <li>
                          <span className={cx.popularService}>Waxing</span>
                        </li>
                      </ul>
                    </div>
                    <div className={`${cx.entry} mb-0`}>
                      <button>Search Now</button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
          <img
            src={halfCirclePattern}
            alt="pattern"
            className={cx.halfCirclePattern}
          />
        </section>


        <section className={cx.dealsMonthSection}>
          <Container>
            <Col md={12} className={cx.titleBox}>
              <h2>Deals of the month</h2>
            </Col>
            <OwlCarousel className={`owl-theme ${st.sliderRow}`} {...options}>
              <div className='item'><Deals /></div>
              <div className='item'><Deals /></div>
              <div className='item'><Deals /></div>
              <div className='item'><Deals /></div>
              <div className='item'><Deals /></div>
          </OwlCarousel>
          </Container>
        </section>
        
        <section className={`${cx.beautySection}`}>
          <Marquee>
          <ul>
            <li className={`${cx.cardBox}`}>
              <img src={hairImg} alt="hairImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={hairIcon} alt="hairIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Hair</NavLink>
            </li>
            <li className={`${cx.headingBox}`}>
              <div className={`${cx.headingBoxBody}`}>
                <h5>Let’s make you beautiful</h5>
                <p>50% off on selected services for next month</p>
              </div>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={massageImg} alt="massageImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={massageIcon} alt="massageIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Massage</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={waxImg} alt="waxIcon" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={waxIcon} alt="hairIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Wax</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={makeupImg} alt="makeupImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={makeupIcon} alt="makeupIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Makeup</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={hairImg} alt="hairImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={hairIcon} alt="hairIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Hair</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={massageImg} alt="massageImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={massageIcon} alt="massageIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Massage</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={waxImg} alt="waxIcon" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={waxIcon} alt="hairIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Wax</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={makeupImg} alt="makeupImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={makeupIcon} alt="makeupIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Makeup</NavLink>
            </li>
          </ul>
          </Marquee>
          <Marquee>
          <ul>
            <li className={`${cx.cardBox}`}>
              <img src={hairImg} alt="hairImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={hairIcon} alt="hairIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Hair</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={massageImg} alt="massageImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={massageIcon} alt="massageIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Massage</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={waxImg} alt="waxIcon" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={waxIcon} alt="hairIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Wax</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={makeupImg} alt="makeupImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={makeupIcon} alt="makeupIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Makeup</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={hairImg} alt="hairImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={hairIcon} alt="hairIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Hair</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={massageImg} alt="massageImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={massageIcon} alt="massageIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Massage</NavLink>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={waxImg} alt="waxIcon" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={waxIcon} alt="hairIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Wax</NavLink>
            </li>
            <li className={`${cx.headingBox2}`}>
              <div className={`${cx.headingBoxBody2}`}>
                <p>Discover and Book Beauty Services Anytime and Anywhere </p>
              </div>
              <div className={`${cx.cardBox}`}>
                <img src={makeupImg} alt="makeupImg" className={`${cx.hairImg}`} />
                <div className={`${cx.beautySection}`}>
                <img src={makeupIcon} alt="makeupIcon" className={`${cx.hairIcon}`} />
                </div>
                <NavLink to="#" className={`btn ${cx.actionBtn}`}>Makeup</NavLink>
              </div>
              <div className={`${cx.cardBox}`}>
              <img src={waxImg} alt="waxIcon" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={waxIcon} alt="hairIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Wax</NavLink>
              </div>
            </li>
            <li className={`${cx.cardBox}`}>
              <img src={makeupImg} alt="makeupImg" className={`${cx.hairImg}`} />
              <div className={`${cx.beautySection}`}>
               <img src={makeupIcon} alt="makeupIcon" className={`${cx.hairIcon}`} />
              </div>
              <NavLink to="#" className={`btn ${cx.actionBtn}`}>Makeup</NavLink>
            </li>
          </ul>
          </Marquee>
        </section>

        <section className={cx.dealsMonthSection}>
          <Container>
            <Col md={12} className={cx.titleBox}>
              <h2>Featured Brands</h2>
            </Col>
            <OwlCarousel className={`owl-theme ${st.sliderRow}`} {...options}>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
              <div className='item'><Brands /></div>
          </OwlCarousel>
          </Container>
        </section>

        <section className={cx.location}>
          <Container>
            <Row>
              <Col lg={6} xl={8} md={12}>
                <div className={`${cx.mapBox}`}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15524.125310666246!2d75.75550313431334!3d26.874246942800223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5ee00af5f53%3A0xc6ba7b45713f9af6!2sAppic%20Softwares%20Development%20LLP!5e0!3m2!1sen!2sin!4v1691492991985!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    title="map"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Col>
              <Col lg={6} xl={4} md={12}>
                <div className={cx.locationBox}>
                  <h1>Deals near you</h1>
                  <p>
                    Loram buipsim tipsum gipsum some more sum, since it’s sum
                    lets say loram{" "}
                  </p>
                  <div className={cx.form}>
                    <div className={cx.entry}>
                      <label htmlFor="location">Location</label>
                      <img src={locationGrad} alt="location" />
                      <input
                        type="text"
                        name="location"
                        id="location"
                        placeholder="Turn on or select location"
                      />
                    </div>

                    <div className={cx.entry}>
                      <label htmlFor="range">Radius</label>
                      <div className={`${st.radiusSlider}`}>
                        <Slider
                          size="small"
                          defaultValue={12}
                          aria-label="Always visible"
                          valueLabelDisplay="on"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={cx.feature}>
          <Container>
            <Row>
              <Col lg={4} md={6} className={cx.image}>
                <img src={barberShop} alt="barber-shop" />
              </Col>
              <Col lg={8} md={6}>
                <div className={cx.description}>
                  <h3>FOR THE FUTURE PARTNERS</h3>
                  <p>
                    Our beauty specialists offer a wide range of services, from
                    glamorous hairstyles and makeup to spa treatments that can
                    be performed both at your home and at their place
                  </p>
                </div>
                <button>Registration your Business</button>
              </Col>
            </Row>
          </Container>
        </section>
    </>
  );
};

export default Home;
