import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";

import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import {
  hair,
  basicInfo,
  basicInfoA,
  pricingTab,
  pricingTabA,
  teamTab,
  teamTabA,
  amenitiesTab,
  amenitiesTabA,
  massage,
  lashes,
  makeupfill,
  nails,
  waxfill,
  facilities,
  chair,
  table,
  massageTable,
  deleteIcon,
  editIcon,
} from "../../../assets/images";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import BackArrrow from "../../../components/Business/Buttons/BackArrow";
import { Switch } from "../../../components/Website/Forms";
import PriceGrid from "./PriceGrid";
import { GlobalServiceContextProvider } from "../../../store/ServiceSGloabl";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import ServicesNotes from "../../../components/Business/Modals/ServicesNotes";

const ViewService = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );

  const [amenitiesList, setAmenitiesList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [prices, setPrices] = useState<any>([]);



  const [data, setData] = useState<any>("")

  const [showServicesNotes, setServicesNotesShow] = useState(false);
  const handleServicesNotesClose = () => {
    setData("");
    setServicesNotesShow(false)
  };
  const handleServicesNotesShow = () => setServicesNotesShow(true);

  const [teamTitleList, setTeamTitleList] = useState<any>([])
  const [selectedPrice, setSelectedPrice] = useState<any>([])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAmenities = async () => {
    const res = await httpRequest(`getGlobalAmenities?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      setAmenitiesList(res.data);
    }
  };

  const getTeam = async () => {
    const res = await httpRequest(`getAllTeamMembers?businessId=${businessData._id}&serviceId=${state?.BusinessService?._id}`, "get", null, "json");
    if (res.status) {
      setTeamList(res.data);
    }
  };

  const getTitle = async () => {
    const res = await httpRequest(
      `getTeamTitle?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setTeamTitleList(res?.data);
    }
  };

  useEffect(() => {
    getTitle()
  }, [businessData?._id])

  useEffect(() => {
    if (businessData) {
      getTeam();
      getAmenities();
    }
  }, [businessData]);

  useEffect(() => {
    if (state) {
      getBusinessPrice();
    }
  }, [state]);

  const getBusinessPrice = async () => {
    const res = await httpRequest(`getBusinessPrice?serviceId=${state?.BusinessService?._id}`, "get", null, "json");
    if (res.status) {
      let businessPriceData = res?.data;
      if (businessPriceData) {
        // let peakHrs = businessPriceData[0]?.offPeakHours;
        // const combinedPrices = Object.values(
        //   businessPriceData.reduce((acc: any, item: any) => {
        //     const { duration } = item;
        //     if (!acc[duration]) {
        //       acc[duration] = { duration, season: [] };
        //     }
        //     if (item.seasonType) {
        //       let seasonObj = {
        //         seasonType: item.seasonType,
        //         seasonPrice: item.seasonPrice,
        //         priceId: item._id,
        //       };
        //       acc[duration].season.push(seasonObj);
        //     } else {
        //       acc[duration].priceId = item._id;
        //     }
        //     return acc;
        //   }, {})
        // );
        setPrices(businessPriceData);
      }
    }
  };

  const priceData = [
    {
      id: state?._id,
      duration: state?.duration,
      generalPrice: state?.seasonType === "regularPrice" ? state?.seasonPrice : "",
      introductionPrice: state?.seasonType === "introductionPrice" ? state?.seasonPrice : "",
      memberPrice: state?.seasonType === "memberPrice" ? state?.seasonPrice : "",
      offPeakPrice: state?.seasonType === "offPeakPrice" ? state?.seasonPrice : "",
      priceType: state?.priceType,
    }
  ]
  const amenityList: any = [];
  state?.BusinessService?.amenitiesId?.forEach((id: any) => {
    const amenities = amenitiesList?.filter((am: any) => {
      return am?._id === id
    })
    amenityList.push(amenities[0])
  })

  useEffect(() => {
    if (prices) {
      const finalPrice = prices?.map((item: any) => ({
        duration: `${Number.parseInt(item?.duration)}`,
        notes: item?.notes || "",
        priceText: item?.priceText || "",
        priceType: item?.priceType || "",
        seasonPrice: item?.seasonPrice,
        seasonType: item?.seasonType || "fixed",
        teamTitleId: item?.teamTitleId || "All",
        id: item?._id,
        _id: item?._id
      }))
      setSelectedPrice(finalPrice)
    }
  }, [prices])

  return (
    <>
    <ServicesNotes show={showServicesNotes} handleServicesNotesClose={handleServicesNotesClose} data={data} />
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {/* <BackArrrow /> */}
              <span>{`${state?.ServiceCategory?.serviceCategoryName || state?.BusinessService?.ServiceCategory?.serviceCategoryName || ""} > ${state?.BusinessService?.serviceName}`}</span>
            </h5>
          </div>
          <div className={`${st.rightInfo}`}>
            {<div style={{ color: "#3e8575" }}>Display Mode</div>}
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              {
                <>
                  <Nav.Item>
                    <Nav.Link eventKey="tabNew2">
                      <img
                        src={pricingTab}
                        alt="info"
                        className={`${st.defalut}`}
                      />
                      <img
                        src={pricingTabA}
                        alt="info"
                        className={`${st.active}`}
                      />
                      Pricing and Duration
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tabNew3">
                      <img src={teamTab} alt="info" className={`${st.defalut}`} />
                      <img src={teamTabA} alt="info" className={`${st.active}`} />
                      Team
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="tabNew4">
                      <img
                        src={amenitiesTab}
                        alt="info"
                        className={`${st.defalut}`}
                      />
                      <img
                        src={amenitiesTabA}
                        alt="info"
                        className={`${st.active}`}
                      />
                      Amenities
                    </Nav.Link>
                  </Nav.Item> */}
                </>
              }
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <Row>
                  <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">Service Name</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{state?.serviceName ? state?.serviceName : state?.BusinessService?.serviceName}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">Service category</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{state?.ServiceCategory?.serviceCategoryName ? state?.ServiceCategory?.serviceCategoryName : state?.BusinessService?.ServiceCategory?.serviceCategoryName}</p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4}></Col>
                  <Col md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">
                        Service available for
                      </label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{state?.serviceAvilableFor ? state?.serviceAvilableFor : state?.BusinessService?.serviceAvilableFor}</p>
                      </div>
                    </div>
                  </Col>

                  <Col md={12}></Col>
                  <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">Service description</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{state?.serviceDescription ? state?.serviceDescription : state?.BusinessService?.serviceDescription ? state?.BusinessService?.serviceDescription : "No Service description"}</p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">
                        Service Aftercare description
                      </label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{state?.ServiceAftercareDescription ? state?.ServiceAftercareDescription : state?.BusinessService?.ServiceAftercareDescription ? state?.BusinessService?.ServiceAftercareDescription : "No Service Aftercare description"}</p>
                      </div>
                    </div>
                  </Col>

                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>



              <Tab.Pane eventKey="tabNew2">
                {selectedPrice?.map((item: any, i: number) => {
                  const teamTitleObj = teamTitleList?.find((titleObj: any) => titleObj?._id === item?.teamTitleId)

                  return (
                    <Row key={i}>
                      <Col xl={2} lg={4} md={6} className="px-2">
                        <div className={cx.price_categorySelect}>
                          {/* <div className={`${st.formBox} ${cx.displayBox}`} >
                                    <p>{item?.seasonType}</p>
                                </div> */}
                          <div className={`${st.formBox} ${cx.displayBox}`} >
                            <p>{teamTitleObj?.teamTitle || "All"}</p>
                          </div>
                        </div>
                      </Col>
                      {<Col xl={2} lg={4} md={6} className="px-2">
                        <div className={cx.price_categorySelect}>
                          <div className={`${st.formBox} ${cx.displayBox}`} >
                            <p>{item?.priceText || "No price text"}</p>
                          </div>
                        </div>
                      </Col>}
                      <Col xl={3} lg={4} md={6} className="px-2">
                        <div className={cx.price_categorySelect}>
                          <div className={`${st.formBox} ${cx.displayBox}`} >
                            <p>{item?.duration}</p>
                          </div>
                          <div className={`${st.formBox} ${cx.displayBox}`} >
                            <p>{item?.seasonPrice}</p>
                          </div>
                          <div className={`${st.formBox} ${cx.displayBox}`} >
                            <p>{item?.priceType}</p>

                          </div>
                        </div>
                      </Col>
                      <Col xl={5} lg={4} md={6} className="px-2">
                        <div className={cx.price_categorySelect}>
                          <div className={`${st.formBox} ${cx.displayBox}`} style={{ width: '75%' }}>
                            <div className={`${cx.eyeModal_relative}`}>
                              <p>{item?.notes || "No notes"}</p>
                              {item?.notes && <span className={`${cx.content_modal}`} onClick={() => {
                                setData(item?.notes)
                                handleServicesNotesShow()
                              }}><FaEye /></span>}
                            </div>
                          </div>

                        </div>
                      </Col>
                    </Row>
                  )
                })}
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew3">
                <Row>
                  {teamList && teamList.map((team: any) => {
                    return (
                      <Col lg={4} md={6} key={team?._id}>
                        <article className={`${cx.teamBox} ${cx.active}`} key={team?._id}>
                          <div className={`${cx.left}`}>
                            <span>{`${team?.firstName[0].toUpperCase()}${team?.lastName[0].toUpperCase()}`}</span>
                          </div>
                          <div className={`${cx.right}`}>
                            <h3 className={cx.memberName}>{`${team?.firstName} ${team?.lastName}`}</h3>
                            {/* <h3 className={cx.occupation}>Manager</h3> */}
                          </div>
                        </article>
                      </Col>
                    )
                  })}


                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew4">
                <Row>
                  <Col md={12}>
                    {/* <NavLink to="/business/services/edit-service">
                      <button className={` ${cx.editButton}`}>
                        <img src={editIcon} alt="edit" />
                      </button>
                    </NavLink> */}
                  </Col>
                </Row>
                <Row>
                  {amenityList?.length !== 0 ? amenityList?.map((amenity: any) => {
                    return (
                      <Col lg={4} md={6} key={amenity?._id}>
                        <article className={`${cx.teamBox} ${cx.active}`} key={amenity?._id}>
                          <div className={`${cx.left}`}>
                            {/* <img src={chair} alt="chair-icon" /> */}
                            <span>{amenity?.itemName[0].toUpperCase()}</span>
                            <span>{amenity?.itemName[1].toUpperCase()}</span>
                          </div>
                          <div className={`${cx.right}`}>
                            <h3 className={cx.memberName}>{amenity?.itemName}</h3>
                          </div>
                        </article>
                      </Col>
                    )
                  }) : <h5 style={{ textAlign: "center", marginTop: "50px", fontSize: "16px" }}>Amenities is not assigned</h5>}
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>



            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default ViewService;
