import React, { useState } from "react";
import m from "./Modal.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { successLarge, crossRed } from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";

const Confirm = (props: any) => {
    let {
        showConfirmModal,
        handleConfirmModalClose,
        message,
        data,
        handleRebookClose,
        getBooking,
        getBookings
    } = props;
    const [loading, setLoading] = useState<boolean>(false);


    const handleOk = async () => {
        if (data) {
            setLoading(true)
            const res = await httpRequest('createBooking', "POST", data, "json");
            if (res?.status) {
                handleRebookClose()
                getBooking && getBooking()
                getBookings && getBookings()
            }
        }
        setLoading(false)
        handleConfirmModalClose();
    };

    return (
        <>
            <Modal
                centered
                show={showConfirmModal}
                backdrop="static"
                keyboard={false}
                onHide={handleConfirmModalClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={m.modalCts}
            >
                <Modal.Body className="p-5">
                    <Row>
                        <Col md={12}>
                            <h5 className={`${m.successHeading}`}>Are You Sure</h5>
                            <p className={`${m.desc2}`}>
                                {message}
                            </p>
                        </Col>

                        <Col md={12}>
                            <div className={`${m.buttonGroup}`}>

                                <button className={`${m.cancel}`} onClick={() => {

                                    handleConfirmModalClose();
                                }}>
                                    No
                                </button>
                                <button className={`${m.ok}`} onClick={handleOk} disabled={loading} style={{ opacity: loading ? "0.9" : "1" }}>
                                    Yes
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Confirm;
