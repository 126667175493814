import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import { deleteIcon, editIcon, openIcon } from "../../../assets/images";
import { Status } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  const { params } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const memberShipInfo = props?.membershipData.filter((member:any) =>{
    return member._id === params?.row?.id
  })
  const navigate = useNavigate();
  const deleteApi = async () => {
    const res = await httpRequest(
      `deleteMembership/${params?.row?.id}`,
      "DELETE",
      null,
      "json"
    );
    if (res.status === true) {
      props.getMemberShip();
      handleClose();
    } else {
    }
  };



  
  const handleEditClick = () => {
    
    let dataToEdit = memberShipInfo?.find((val: any, index: any) => {
     return val._id == params?.row?.id;
   });
    navigate("edit-membership", { state: dataToEdit });
 };

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <p
              className={`btn ${tb.edit}`}
              title="Edit"
              // to="edit-membership"
            >
              <img src={editIcon} alt="edit"
              
              onClick={() => {
                handleEditClick();
              }}
              />
            </p>
          </li>
          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="view"
              to="view-membership"
              state={{data:memberShipInfo[0]}}
            >
              <img src={openIcon} alt="view" />
            </NavLink>
          </li>
          <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>
          <li>
            <Status status={params.row.isActive}
              api='updateMembership' id={params?.id}
              apiMethod='json'
              getLatest={props.getMemberShip} />
          </li>
        </ul>
      </div>

      <DeletePopup
        show={show}
        handleClose={handleClose}
        deleteApi={deleteApi}
      />
    </>
  );
};

export default function DataGridTable(props: any) {
  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 280,
      renderCell: (params: any) => (
        <StatusButton
          params={params}
          getMemberShip={props.getMemberShip}
          membershipData = {props.membershipData}
        />
      ),
    },
    // { field: "name", headerName: "Name", flex: 1, minWidth: 80 },
    {
      field: "membershipName",
      headerName: "Membership Name",
      flex: 1,
      minWidth: 170,
    },
    { field: "price", headerName: "Price", flex: 1, minWidth: 80 },
    { field: "validFor", headerName: "Valid for", flex: 1, minWidth: 120 },
    { field: "spent", headerName: "Spent", flex: 1, minWidth: 80 },
    { field: "hours", headerName: "Hours", flex: 1, minWidth: 80 },
    { field: "points", headerName: "Points", flex: 1, minWidth: 80 },
    
  ];

  let rows: any[] = [];
  if (props.membershipData) {
    rows = props.membershipData?.map((data: any, i: any) => {
    
      return {
        id: data?._id,
        serialNumber: i + 1,
        membershipName: data?.Membership,
        price: data?.retailPrice,
        validFor: data?.Validity,
        spent: data?.amountSpent,
        hours: data?.hoursBooked,
        isActive: data?.isActive,
        points: data?.Points,
        action: "action",
      };
    });
  }

  return (
    <div className={`${tb.dataTable}`}>
      <div style={{ width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          autoHeight
          hideFooterPagination={false}
          rowHeight={52}
          checkboxSelection
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
}
