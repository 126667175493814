import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  facebook,
  google,
  iconEmail,
  iconPassword,
  loginBg,
  unseenPassword,
  showPassword,
  passwordSuccess,
  welllogo2
} from "../../../assets/images";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { httpRequest } from "../../../Apis/commonApis";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import TermsCondition from "../../../components/Business/Modals/TermsCondition";
import { useDispatch, useSelector } from "react-redux";
import { getHelpDocument } from "../../../redux_toolkit/reducer/HelpAndDocumentsReducer";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";
import { getBusinessUserRoles } from "../../../redux_toolkit/reducer/shareDataReducer";

const Login = (props: any) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginState, setLoginState] = useState<string>("first");
  const [show, setShow] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState('');

  const handleAlertModalClose = () => setShowErrorModal('');
  const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

  const handleStep = (e: any, step: string) => {
    e.preventDefault();
    reset();
    setLoginState(step);
  };

  const userLogin = async (postdata: any) => {
    let data = {
      email: postdata.email,
      password: postdata.password
    }
    let res = await httpRequest('login', 'post', data, 'withoutToken');
    if (res?.status) {
      if (res?.data?.userType === "businessUser") {
        let stepCompleted = 0
        if (res?.businessData) {
          stepCompleted = res?.businessData?.stepCompleted
        } else {
          stepCompleted = res?.data?.stepCompleted
        }
        if (res?.businessData) {
          localStorage.setItem('business_data', JSON.stringify(res?.businessData));
        }
        localStorage.setItem('user_data', JSON.stringify(res?.data));
        localStorage.setItem('user_token', res?.token);

        if (stepCompleted < 10) {
          if (stepCompleted < 6) {
            navigate('/business/signup')
            localStorage.setItem('stepCompleted', JSON.stringify(stepCompleted));
            return
          }

          if (stepCompleted === 6) {
            const branchLength = res?.branches?.length
            if (branchLength === 0) {
              navigate('/business/branch/add-branch/info')
              return
            }
            if (branchLength === 1) {
              console.log(res?.branches?.[0], 'res?.branches?.workingHours');

              if (res?.branches?.[0]?.workingHours?.length === 0) {
                navigate('/business/branch/edit-branch/info', { state: { branchId: res?.branches?.[0]?._id } })
                return
              } else {
                navigate('/business/branch')
                return
              }
            }
            if (branchLength > 1) {
              navigate('/business/branch')
              return
            }
          }
          if (stepCompleted === 7) {
            navigate('/business/services')
            return
          }
          if (stepCompleted === 8) {
            navigate('/business/new-teams')
            return
          }
          if (stepCompleted === 9) {
            navigate('/business/wellnezza/business')
            return
          }
        } else {
          handleAlertModalShow(`Success, ${res.message}`);
          setTimeout(() => {
            navigate('/business/dashboard/today')
          }, 2000);
        }
      } else {
        localStorage.setItem('user_data', JSON.stringify(res?.data));
        // localStorage.setItem('business_data', JSON.stringify(res?.businessData));
        localStorage.setItem('user_token', res?.token);
        dispatch(getHelpDocument());
        handleAlertModalShow(`Success, ${res.message}`);
        setTimeout(() => {
          navigate("/business/dashboard/today", { replace: false });
        }, 2000)
      }
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
  }

  const userforgotpassword = async (postdata: any) => {
    let data = {
      email: postdata.email,
    }
    const res = await httpRequest('forgotpassword', 'post', data, 'withoutToken');
    if (res.status) {
      handleAlertModalShow(`Success, ${res.message}`);
    } else {
      handleAlertModalShow(`Error, ${res.message}`);
    }
  }

  useEffect(() => {
    dispatch(getHelpDocument());
  }, [])

  return (
    <>
      <section className={`${cx.loginSection}`}>
        {/* <img src={loginBg} alt="login background" className={`${cx.loginBg}`} /> */}
        <div className={`${cx.signUpBgClr}`}></div>

        <div className={`${cx.modalContent}`}>
          <div className={`${cx.modalCenter}`}>
            <div className={`${cx.imgSide}`}>
              <img src={welllogo2} alt="logo" />
            </div>
            {loginState === "first" ? (
              <div className={`${cx.formBody}`}>
                <form onSubmit={handleSubmit(userLogin)}>
                  <Row>
                    <Col md={12}>
                      <h2 className={`${cx.title}`}>
                        Welcome Back!
                      </h2>
                      <p className={`${cx.desc} mt-0`}>Please login to your Wellnezza account</p>
                    </Col>
                    <Col md={12}>
                      <div className={`${st.formBox} ${st.iconFormBox}`}>
                        <label className="form-label">Email or phone number <HoverEyeContent number={9009} /></label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email / Mobile No (without country code)"
                            {...register("email", {
                              required: true,
                            })}
                          />
                        </div>
                        {errors?.email?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={`${st.formBox} ${st.iconFormBox}`}>
                        <label className="form-label">Password <HoverEyeContent number={9010} /></label>
                        <div className="position-relative">
                          {/* <img
                            src={iconPassword}
                            className={`${st.icon}`}
                            alt="password icon"
                          /> */}
                          <input
                            className="form-control"
                            placeholder="Your password"
                            type={show ? "password" : "text"}
                            {...register("password", {
                              required: true,
                            })}
                          />
                          {show ? (
                            <AiFillEye
                              className={`${st.eyeIcon}`}
                              onClick={() => {
                                setShow(false);
                              }}
                            />
                          ) : (
                            <AiFillEyeInvisible
                              className={`${st.eyeIcon}`}
                              onClick={() => {
                                setShow(true);
                              }}
                            />
                          )}
                        </div>
                        {errors?.password?.type === "required" && (
                          <p className="errorMessage">This field is required</p>
                        )}
                      </div>
                    </Col>
                    <Col md={12}>
                      <NavLink className={`${cx.forgotBox}`} to="#" onClick={(e: any) => handleStep(e, "forgotPassword")}>
                        Forgot Password? <HoverEyeContent number={9011} />
                      </NavLink>
                    </Col>
                    <Col md={12}>
                      <div className={`${st.formBox} ${cx.formSign}`}>
                        <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-2`}>
                          <Button type="submit" className={`btn ${st.btn3} ${cx.btnGray}`}>Login</Button>
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div>
                        <NavLink className={`${cx.alreadyBox} `} to="/business/signup">
                          <span style={{ color: '#000' }}>Don't have an account yet?</span> Click here to create a new one.<HoverEyeContent number={9012} />
                        </NavLink> 
                      </div>

                      {/* <div className="text-center">
                        <button className={`${cx.terms_condition_btn} btn`}>Terms & Conditions</button>
                      </div> */}
                    </Col>

                  </Row>
                </form>
              </div>
            ) : loginState === "forgotPassword" ? (
              <div className={`${cx.formBody}`}>
                <Row>
                  <Col md={12}>
                    <h2 className={`${cx.title}`}>Update your password</h2>
                  </Col>
                  <Col md={12}>
                    <div className={`${st.formBox} ${st.iconFormBox} mt-2`}>
                      <label className="form-label">Enter your email address </label>
                      <div className="position-relative">
                        {/* <img
                          src={iconEmail}
                          className={`${st.icon}`}
                          alt="user icon"
                        /> */}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                        />
                      </div>
                      {errors?.email?.type === "pattern" && (
                        <p className={"errorMessage"}>Invalid email address</p>
                      )}
                      {errors?.email?.type === "required" && (
                        <p className={"errorMessage"}>This field is required</p>
                      )}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className={`${st.formBox} ${cx.formSign}`}>
                      <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-2`}>
                        <button
                          className={`btn ${st.btn3} ${cx.btnGray}`}
                          onClick={handleSubmit(userforgotpassword)}
                        >
                          Send me the link
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-center"> Back to&nbsp;
                      <NavLink className={`${cx.alreadyBox} m-0`} to="#" onClick={(e) => handleStep(e, "first")}>
                        Log In
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : loginState === "newPassword" ? (
              <div className={`${cx.formBody}`}>
                <Row>
                  <Col md={12}>
                    <h2 className={`${cx.title}`}>Enter new password</h2>
                  </Col>
                  <Col md={12} className="mb-3">
                    <div className={`${st.formBox} ${st.iconFormBox}`}>
                      <label className="form-label">New Password</label>
                      <div className="position-relative">
                        <img
                          src={iconPassword}
                          className={`${st.icon}`}
                          alt="password icon"
                        />
                        <input
                          className="form-control"
                          placeholder="Your password"
                          type={show ? "password" : "text"}
                        />
                        {show ? (
                          <AiFillEye
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(false);
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className={`${st.formBox} ${st.iconFormBox}`}>
                      <label className="form-label">Re-enter Your New Password</label>
                      <div className="position-relative">
                        <img
                          src={iconPassword}
                          className={`${st.icon}`}
                          alt="password icon"
                        />
                        <input
                          className="form-control"
                          placeholder="Your password"
                          type={show ? "password" : "text"}
                        />
                        {show ? (
                          <AiFillEye
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(false);
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            className={`${st.eyeIcon}`}
                            onClick={() => {
                              setShow(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup} mt-2`}>
                        <button
                          className={`btn ${st.btn3}`}
                          onClick={(e: any) => handleStep(e, "last")}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-center"> Back to&nbsp;
                      <NavLink className={`${cx.alreadyBox} m-0`} to="#" onClick={(e) => handleStep(e, "second")}>
                        Log In
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className={`${cx.formBody}`}>
                <Row>
                  <Col md={12}>

                    <div className={`${cx.successfulBox}`}>
                      <img src={passwordSuccess} alt="Success" />

                      <h2>Successful password reset!</h2>
                      <p>You can now use your new password to log in to your account</p>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
        <ErrorPopup
          showErrorModal={showErrorModal}
          handleErrorModalClose={handleAlertModalClose}
        />

      </section>
    </>
  );
};

export default Login;
