import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import cx from "./index.module.scss";
import { brand1, deals } from "../../../assets/images";
import { BsStarFill } from "react-icons/bs";

const Testimonials = (props: any) => {
  return (
    <>
      <div className={`${cx.testimonialsBox}`}>
	       
      </div>
    </>
  );
};

export default Testimonials;
