import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import {
  massage,
  hair,
  lashes,
  nails,
  facilities,
  waxfill,
  makeupfill,
  userFrame,
  edit,
  editIcon,
  upArrow,
  phonefill,
  male,
} from "../../../assets/images";
import { FiSearch } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
import { Switch } from "@mui/material";
import { BiPhone } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import SuccessPopup from "./SuccessPopup";
import { v4 as uuidv4 } from 'uuid';


const ProfilePopup = (props: any) => {
  let { promotionList, showSuccessModal, deletedServices,totalPrice, finalPrice, selectedBundle, totalDuration, otherChargesPrice, otherCharges, type, data: editData, getBookings, notes, setHide, packageeData, setShowSuccessModal, setMessage, showProfile, handleProfileClose, serviceList, clientList, apiServices, getBooking, date, time, handleBooking, handleCustomerClose } = props;
  const [packageType, setPackageType] = useState("regular");

  const [couponShow, setCouponShow] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  let { branchId } = useSelector((state: any) => state.BranchReducer);

  const formatDate = (date: any) => {
    if (date) {
      return new Intl.DateTimeFormat("en-US", { day: "numeric", month: "short", year: "numeric" }).format(new Date(date))
    }
  }


  let existPromotionArray: any = [];
  editData?.services?.forEach((item: any) => {
    if (item?.promotionId?._id) {
      existPromotionArray.push(item?.promotionId?._id)
    }
  })

  console.log(selectedBundle, serviceList, apiServices, "apiServices");


  const onsubmit = async (e: any) => {
    e.preventDefault();
    const services = apiServices?.map((service: any) => {
      let currService: any = {}
      if (selectedBundle?.length !== 0) {
        selectedBundle?.forEach((bundle: any) => {
          bundle?.services?.forEach((price: any) => {
            if (price?._id === service?.serviceId && bundle?._id === service?.bundleId) {
              currService = price
            }
          })
        })
      } else {
        currService = serviceList?.find((data: any) => data?._id === service?.serviceId);
      }
      const hours = Math.floor(currService?.startTime / 60);
      const minutes = currService?.startTime % 60;
      const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      return {
        ...service,
        startTime: formattedTime,
      }
    })
    let finalData: any = {};
    if (type === "calendar") {
      finalData = {
        salectDate: date,
        salectTime: time,
        serviceObj: { ...services[0], _id: editData?.services[0]?._id },
        finalPrice: totalPrice,
      }
    } else {
      finalData = {
        businessId: businessData?._id,
        removeServices: deletedServices,
        branchId: branchId,
        bookedBy: "offline",
        clientId: clientList?.[0]?._id,
        clientUid: clientList?.[0]?.cuId,
        existPromotionArray,
        bookingStatus: "Upcoming",
        salectDate: date,
        salectTime: time,
        newBookingDuration: totalDuration,
        services: type === "" ? services[0] : services?.map((bookData: any) => ({ ...bookData, bookingStatus: 'Upcoming' })),
        notes: notes,
        finalPrice: `${finalPrice}`,
        ...otherCharges
      }
      if (editData) {
        finalData = {
          ...finalData,
          isPromotionCheck: true
        }
      }

    }
    setLoading(true);
    const path = editData && type === "calendar" ? `updateSubBooking/${editData?._id}` : editData && type === "list" ? `updateBooking/${editData?._id}` : 'createBooking'
    const method = editData ? "PATCH" : "POST";
    const res = await httpRequest(path, method, finalData, "json");
    if (res?.status) {
      setError("")
      setMessage(res?.message)
      getBooking && getBooking()
      getBookings && getBookings()
      handleProfileClose()
      setShowSuccessModal(true);
      setHide(true)
      setTimeout(() => {
        handleCustomerClose && handleCustomerClose()
        handleBooking()
      }, 2000)
    } else {
      setMessage(res.message)
    }
    setLoading(false);
  }


  return (
    <>
      {<Modal
        scrollable
        centered
        backdrop="static"
        keyboard={false}
        show={showProfile}
        onHide={handleProfileClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts}  ${m.ModalHeaderBG} ${m.profile_serviceDiscount_coupon} ${m.onlyView_inputBox}  ${showSuccessModal ? m.hide : ""
          }`}
      >
        <Modal.Header>
          <Modal.Title>
            <div className={`${m.header}`}>
              <div className={m.title}>
                <NavLink to="#" onClick={handleProfileClose}>
                  <svg
                    width="38"
                    height="37"
                    viewBox="0 0 38 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                      fill="#3C746D"
                    ></path>
                    <path
                      d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                      stroke="#ACD9D0"
                    ></path>
                    <path
                      d="M16.0839 17.0977H15.5839V17.5977V19.0977V19.5977H16.0839H36.6476H37.1476V19.0977V17.5977V17.0977H36.6476H16.0839Z"
                      stroke="#3C746D"
                    ></path>
                    <path
                      d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                      fill="#3C746D"
                    ></path>
                    <path
                      d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                      stroke="#ACD9D0"
                    ></path>
                    <path
                      d="M24.038 6.88837L24.0773 6.35148L23.539 6.35187L22.6444 6.35251L22.1842 6.35284L22.1464 6.81149C22.1447 6.83116 21.9844 8.65148 20.8365 10.8453L20.8365 10.8454C19.7709 12.8824 17.5996 15.6896 13.3 17.4154L13.3 17.4154L12.0108 17.933L10.8549 18.3971L12.0109 18.861L13.3005 19.3785C17.5997 21.1044 19.7714 23.9109 20.837 25.9485L20.837 25.9485C21.9848 28.143 22.1452 29.9626 22.1469 29.9824C22.1469 29.9825 22.1469 29.9825 22.1469 29.9825L22.1848 30.4421L22.646 30.4414L23.0916 30.4408L23.0923 30.4408L23.093 30.4408L23.5386 30.4414L24.0806 30.4422L24.0378 29.9019L24.0377 29.9011C24.0277 29.7742 23.8512 27.5484 22.5116 24.9598L22.5115 24.9597C21.7288 23.4477 20.7063 22.0832 19.4728 20.9054L19.4728 20.9054C18.4619 19.9402 17.309 19.1013 16.0306 18.3969C17.309 17.6923 18.462 16.8531 19.4733 15.8879L19.4733 15.8879C20.7068 14.71 21.7293 13.3456 22.5121 11.8336L22.5121 11.8335C23.8543 9.24009 24.0291 7.01053 24.038 6.88837Z"
                      stroke="#3C746D"
                    ></path>
                  </svg>
                </NavLink>
                Profile
              </div>
              {/* <img className={m.editIcon} src={editIcon} alt="edit" /> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div className={m.profileInfo}>
                <div className={m.top}>
                  <div className={m.left}>
                    <img src={userFrame} alt="user" className={m.userFrame} />
                  </div>
                  <div className={m.right}>
                    <h2>
                      {`${clientList?.[0]?.firstName} ${clientList?.[0]?.lastName}`}
                      {clientList[0]?.gender === "male" && <img src={male} alt="male" />}
                    </h2>
                    <p className={m.profileDate}>{formatDate(clientList?.[0]?.dateOfBirth)}</p>

                    <p className={m.basic}>
                      <BiPhone className={m.icon} /> <span>{clientList?.[0]?.mobile}</span>
                    </p>
                    <p className={m.basic}>
                      <HiOutlineMail className={m.icon} />{" "}
                      <span>{clientList?.[0]?.email}</span>
                    </p>
                    <p className={m.basic}>
                      <CiLocationOn className={m.icon} />{" "}
                      <span>{clientList?.[0]?.adderss1}, {clientList?.[0]?.city}, {clientList?.[0]?.postalCode}</span>
                    </p>
                  </div>
                </div>
                {/* <div className={m.bottom}>
                  <span className={m.tags}>5 yearly membership</span>
                  <span className={m.tags}>35 reviews</span>
                </div> */}
              </div>
            </Col>

            <Col md={12}>
              <div className={`${m.makeupHeading}`}>
                <div className={`${m.titleMakeup}`}>
                  <div className={`${m.heading}`}>
                    {/* <h2>Past Appointments</h2> */}
                    <h2>{selectedBundle?.lenght !== 0 ? "Bundles" : "Services"}</h2>
                  </div>
                </div>
              </div>
            </Col>

            {serviceList?.map((service: any) => {
              const check = packageeData?.some((pack: any) => {
                return pack?.Services?.map((price: any) => price?.priceId?._id)?.includes(service?._id);
              })
              if (check) {
                return
              }
              const promo = promotionList?.filter(((pro: any) => {
                const result = pro?.priceId?.some((price: any) => {
                  if (price?.id?._id === service?._id) {
                    return true
                  } else {
                    return false
                  }

                })
                return result
              }))

              const currService = apiServices?.find((ser: any) => ser?._id === service?.id);

              // selected promotion find 

              const promoServices = promo?.flatMap((data: any) => data?.priceId)?.filter((data: any) => {
                return data?.id?._id === service._id
              })
              const appliedPromotion = promotionList?.find((promo: any) => promo?._id === currService?.promotionId)
              const selectedPromoServices = appliedPromotion?.priceId?.find((price: any) => price?.id?._id === currService?.serviceId)

              return (
                <Col md={12} key={service?._id}>
                  <div className={m.appointmentCard}>
                    {/* <div className={m.completeTag}>
                     <span>Completed</span>
                    </div> */}
                    <div className={m.left}></div>
                    <div className={m.right}>
                      <div className={m.top}>
                        {/* <h5>{formatDate(service?.createdAt)}</h5> */}
                        {/* <p>At 1:30 PM availed gold package</p> */}
                      </div>
                      <div className={m.bottomInfo}>
                        <div className={m.entry}>
                          <h5 className={m.serviceHeading}>{service?.BusinessService?.serviceName}</h5>
                          {!currService?.radeemPackage && selectedPromoServices?.id?._id && <p className={m.cancel}>{service?.seasonPrice ? service?.seasonPrice : 0}SGD</p>}
                        </div>
                        <div className={m.entry}>
                          <p>{`${`${service?.seasonType?.split("Price")?.[0]?.[0]?.toLocaleUpperCase()}${service?.seasonType?.split("Price")?.[0]?.slice(1)} Price`} ${service?.duration}`}</p>
                          <p className={m.ok}>{currService?.radeemPackage ? 0 : selectedPromoServices?.id?._id && service?.seasonPrice ? `${Number.parseInt(selectedPromoServices?.promotionalPrice)}` : service?.seasonPrice ? service?.seasonPrice : 0} SGD</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })}

            {selectedBundle?.map((bundle: any, b: number) => {
              const promotionableBundle = apiServices?.find((item: any) => item?.bundleId === bundle?._id && item?.bundlePromotionId);
              return (
                <div key={b} className={`${m.bundleScreen}`}>
                  <div className={m.bundleDesc}>
                    <h5>{bundle?.bundleName}</h5>
                    <span><span style={promotionableBundle ? {color:"red", textDecoration:"line-through"} : {}}>{`SGD${bundle?.finalPrice}`}</span> {promotionableBundle ? `${promotionableBundle?.bundlePromotionalPrice}` : ""}</span>
                  </div>
                  {bundle?.services?.map((service: any) => {
                    return (
                      <Col md={12} key={service?._id}>
                        <div className={m.appointmentCard}>
                          {/* <div className={m.completeTag}>
                     <span>Completed</span>
                    </div> */}
                          <div className={m.left}></div>
                          <div className={m.right}>
                            <div className={m.top}>
                              {/* <h5>{formatDate(service?.createdAt)}</h5> */}
                              {/* <p>At 1:30 PM availed gold package</p> */}
                            </div>
                            <div className={m.bottomInfo}>
                              <div className={m.entry}>
                                <h5 className={m.serviceHeading}>{service?.BusinessService?.serviceName}</h5>                              </div>
                              <div className={m.entry}>
                                <p>{`${`${service?.seasonType?.split("Price")?.[0]?.[0]?.toLocaleUpperCase()}${service?.seasonType?.split("Price")?.[0]?.slice(1)} Price`} ${service?.duration}`}</p>
                                <p className={m.ok}>{service?.seasonPrice} SGD</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  })}

                </div>
              )
            })}



            {totalPrice !== 0 && <div className={`${m.total} mt-3`} role="img">
              <p>
                <p>{selectedBundle?.length !== "" ? "Bundle" : "Service"} Amount: SGD {Number.parseFloat(totalPrice)?.toFixed()} </p>
              </p>
            </div>}

            <Col md={12}>
              <div className={`${m.new_appoint_discount_box}`}>
                {otherCharges?.otherChargeMsg && otherCharges?.otherChargeAmt && <div className={`${m.new_appoint_discountDiv}`}>
                  <h4>Other Charges</h4>
                  <Row>
                    {otherCharges?.otherChargeMsg && <Col md={6}>
                      <div className={`${m.input_left}`}>
                        <label>Details</label>
                        <input type="text" className='form-control' value={otherCharges?.otherChargeMsg} disabled />
                      </div>
                    </Col>}
                    {otherCharges?.otherChargeAmt && <Col md={6}>
                      <div className={`${m.right_left}`}>
                        <label>Amount</label>
                        <input type="text" className='form-control' value={otherCharges?.otherChargeAmt} disabled />
                      </div>
                    </Col>}
                    <div className={`${m.line_withTotal_amt}`}>
                      <span className={`${m.line}`}></span>
                      <div className={`${m.total_amt}`}>Total: SGD {otherChargesPrice?.toFixed(2)}</div>
                    </div>
                  </Row>
                </div>}

                {otherCharges?.discount && otherCharges?.discountType && <div className={`${m.new_appoint_discountDiv}`}>
                  <h4>Additional Discount</h4>
                  <Row>
                    {otherCharges?.discountType && <Col md={6}>
                      <div className={`${m.input_left}`}>
                        <label>Discount Type</label>
                        <select className='form-select' value={otherCharges?.discountType} disabled>
                          <option value=""></option>
                          <option value="%">discount percentage</option>
                          <option value="discount amount">discount amount</option>
                        </select>
                      </div>
                    </Col>}
                    {otherCharges?.discount && <Col md={6}>
                      <div className={`${m.right_left}`}>
                        <label>Amount</label>
                        <input type="text" className='form-control' value={otherCharges?.discount} disabled />
                      </div>
                    </Col>}
                    <div className={`${m.line_withTotal_amt}`}>
                      <span className={`${m.line}`}></span>
                      <div className={`${m.total_amt}`}>Total: SGD {finalPrice?.toFixed(2)}</div>
                    </div>
                  </Row>
                </div>}
                <div className={`${m.new_appoint_discountDiv}`}>
                  <h4>Payment</h4>
                  {otherCharges?.paymentStatus === "Pending" && <Row>
                    <Col md={12}>
                      <div className={`${st.formBox} mb-2 mt-2`}>
                        <label className={`${st.checkbox} mt-1`}>
                          <input type="checkbox" checked={otherCharges?.paymentStatus === "Pending"} disabled />
                          <span className={`${st.checkmark}`}></span>
                          <span className="ms-2">Pending</span>
                        </label>
                      </div>
                    </Col>
                  </Row>}
                  {otherCharges?.paymentStatus !== "Pending" && <Row>
                    <Col md={6}>
                      <div className={`${m.input_left}`}>
                        <label>Payment Method</label>
                        <select className='form-select' value={otherCharges?.paymentType} disabled>
                          <option value="">--select--</option>
                          <option value="Credit card">Credit card</option>
                          <option value="Cash">Cash</option>
                          <option value="Nets">Nets</option>
                          <option value="Paynow">Paynow</option>
                          <option value="Voucher">Voucher</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${m.right_left}`}>
                        <label>Reference Number</label>
                        <input type="text" className='form-control' value={otherCharges?.paymentRef} disabled />
                      </div>
                    </Col>
                    {/* {otherCharges?.paymentStatus === "Success" && (otherCharges?.paymentType === "" || otherCharges?.paymentRef === "") && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add discount amount</p>} */}


                  </Row>}
                </div>
              </div>
            </Col>

            {/* <div className={`${m.makeupHeading}`}>
                  <div className={`${m.titleMakeup}`}>
                    <div className={`${m.heading}`}>
                      <h2>Packages</h2>
                    </div>
                  </div>
            </div> */}
            {packageeData?.map((packageService: any) => {
              return <React.Fragment key={packageService?._id}>

                <h5 className={`${m.packageName}`}>{packageService?.PackageName}</h5>
                {
                  packageService?.Services?.map((service: any) => {

                    return (
                      <Col md={12}>
                        <div className={m.appointmentCard}>

                          <div className={m.left}></div>
                          <div className={m.right}>
                            <div className={m.top}>
                              {/* <h5>{formatDate(service?.priceId?.createdAt)}</h5> */}
                              {/* <p>At 1:30 PM availed gold package</p> */}
                            </div>
                            <div className={m.bottomInfo}>
                              <div className={m.entry}>
                                <h5 className={m.serviceHeading}>{service?.priceId?.serviceId?.serviceName}</h5>
                                {/* <p className={m.cancel}>SGD 120</p> */}
                              </div>
                              <div className={m.entry}>
                                {/* <p>{`${`${service?.priceId?.seasonType?.split("Price")?.[0]?.[0]?.toLocaleUpperCase()}${service?.priceId?.seasonType?.split("Price")?.[0]?.slice(1)} Price`} (${service?.priceId?.duration}) ${service?.priceId?.seasonPrice ? ` - $${service?.priceId?.seasonPrice}` : " - $0"}`}</p> */}
                                <p>{`${`${service?.priceId?.seasonType?.split("Price")?.[0]?.[0]?.toLocaleUpperCase()}${service?.priceId?.seasonType?.split("Price")?.[0]?.slice(1)} Price`} ${service?.priceId?.duration}`}</p>
                                {/* <p className={m.ok}>{}</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  })
                }
              </React.Fragment>
            })}

            <Col md={12}>
              {packageeData?.length !== 0 && <div className={m.priceType}>
                <label className={`${st.checkbox}`}>
                  <input type="checkbox" checked={packageType === "regular"} value="regular" onChange={(e: any) => setPackageType(e.target.value)} />
                  <span className={`${st.checkmark}`}></span>
                  <span className="ms-2">Package Regular Price</span>
                </label>
                <label className={`${st.checkbox}`}>
                  <input type="checkbox" checked={packageType === "member"} value="member" onChange={(e: any) => setPackageType(e.target.value)} />
                  <span className={`${st.checkmark}`}></span>
                  <span className="ms-2">Package Member Price</span>
                </label>
              </div>}
            </Col>



            {/* <Col md={12}>
              {packageeData?.length !== 0 && <div className={m.total} role="img">
                <p>
                  <p>Package Amount: {PackagePrice} SGD</p>
                </p>
              </div>}
            </Col> */}
            {/* <Col md={12}>
              <div className={`${m.coupon} mt-3`}>
                {couponShow ? (
                  <div
                    className={`${m.couponBox} flex-column align-items-start`}
                  >
                    
                    <div
                      className={`${m.couponInput} position-relative`}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Apply a coupon"
                      />
                      <button
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : <p onClick={() => setCouponShow(true)}>I have a discount coupon</p>}
              </div>
            </Col> */}
            {/* <Col md={12}>
              <div className={m.total} role="img">
                <p>
                  <p>Total Amount: SGD {totalPrice?.toFixed(2)} </p>
                </p>
              </div>
            </Col> */}
            <Col>
              {error && (
                <p className={"errorMessage text-center"}>{error}</p>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Col md={12}>
            <div className={`${m.new_appoint_discount_box}`}>
              <div className={`${m.new_appoint_discountDiv}`}>

                <Row>
                  <div className={`${m.line_withTotal_amt} px-4 mt-1`}>
                    <span className={`${m.line}`}></span>
                    <div className={`${m.total_amt}`}>Total: SGD {Number.parseFloat(finalPrice)?.toFixed(2)} <span className={`${m.total_time}`}>&#40;{totalDuration} mins&#41;</span></div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
          <div className="d-flex w-100">
            <button
              type="button"
              className={`btn w-100 mx-2 my-1 ${m.cancelBtn}`}
              onClick={handleProfileClose}
            >
              Cancel
            </button>
            <button type="button" className={`btn w-100 mx-2 my-1 ${m.actionBtn} ${loading ? m.disabled : ""}`} disabled={loading} onClick={(e: any) => onsubmit(e)}>
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>}
    </>
  );
};

export default ProfilePopup;


