import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from './index.module.scss';
import { plus } from "../../../assets/images";
import DataGridTable from "./DataGrid";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import ServiceGridTable from "../Services/ServiceGrid";
import { Switch } from "../../../components/Website/Forms";

// const BranchServices = (props: any) => {
//   const [showAssignModal, setShowAssignModal] = useState(false);
//   const handleAssignModalClose = () => setShowAssignModal(false);
//   const handleAssignModalShow = () => setShowAssignModal(true);
//   let { branchId } = useSelector((state: any) => state.BranchReducer);

//   let { businessData } = useSelector(
//     (state: any) => state.shareDataReducer
//   );

//   const [serviceList, setServiceList] = useState<any>([]);

//   useEffect(() => {
//     if(branchId){
//       getService();
//     }
//   }, [branchId]);

//   const getService = async () => {
//     const res = await httpRequest(`getBusinessPrice?branchId=${branchId}`, "get", null, "json");
//     if (res.status) {
//       setServiceList(res?.data);
//     }
//   };

//   return (
//     <>
//       <section className={`${st.pageWrapper}`}>
//         <div className={`${st.pageTitle}`}>
//           <div className={`${st.titleInfo}`}>
//             <h5>Services List</h5>
//           </div>
//           <div className={`${st.rightInfo}`}></div>
//         </div>

//         <div className={`${st.buttonsAction} ${cx.topbar} ${cx.actionBtn}`}>
//           <h3>Singapur Branch</h3>
//           <ul>
//             <li>
//               <ExportBtn />
//             </li>
//             <li>
//               <ImportBtn />
//             </li>
//             <li>
//               <NavLink to="addService" className={`btn ${st.darkBtn}`}>
//                 <img src={plus} alt="Create new Service" /> Create new Service
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="#"
//                 className={`btn ${st.darkBtn}`}
//                 onClick={handleAssignModalShow}
//               >
//                 <img src={plus} alt="Assign Team" /> Assign Service
//               </NavLink>
//             </li>
//           </ul>
//         </div>

//         <div className={`${st.tableBody}`}>
//           <div className={`${st.tableBodyIn}`}>
//             <DataGridTable serviceList={serviceList} getService={getService}/>
//           </div>
//         </div>
//       </section>
//       <AssignPage
//         showAssignModal={showAssignModal}
//         handleAssignModalClose={handleAssignModalClose}
//         page={"Service"}
//       />
//     </>
//   );
// };


const BranchServices = (props: any) => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [typeSwitch, setTypeSwitch] = useState(false);
  const [mainServiceList, setMainServiceList] = useState<any>([])
  const [serviceList, setServiceList] = useState([]);

  const handleTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTypeSwitch(event.target.checked);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData) {
      getService();
      getMainService();
    }
  }, [branchId]);

  const getService = async () => {
    const res = await httpRequest(`getBusinessPrice?branchId=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setServiceList(res?.data);
    }
  };
  const getMainService = async () => {
    const res = await httpRequest(`getBusinessService?branchId=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setMainServiceList(res?.data);
    }
  };

  const canCreateService = subModuleRoles?.['Branch Set Up']?.Services?.Create;

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo} w-auto`}>
            <h5>Services List</h5>
          </div>
          <div className={`${st.buttonsAction} mt-0`}>
            {canCreateService && <ul>
              <li>
                <ExportBtn />
              </li>
              <li>
                <ImportBtn />
              </li>
              <li>
                <NavLink to="addService/info" className={`btn ${st.darkBtn}`}>
                  <img src={plus} alt="Create new Service" /> Create new Service
                </NavLink>
              </li>
            </ul>}
          </div>
          {/* <div className={`${st.rightInfo}`}></div> */}
        </div>



        <div className={`${st.tableBody}`}>
          <div className={`${st.tableBodyIn}`}>
            <div className={`${cx.type}`}>
              <span>Service Table</span>
              <Switch
                checked={typeSwitch}
                onChange={handleTypeChange}
                color={"#1AB192"}
                backgroundColor="#fff"
                key={2}
                id={"team"}
                className={"mt-2"}
              />
              <span>Price Table</span>
            </div>
            {!typeSwitch ? (
              <ServiceGridTable serviceList={mainServiceList} getService={getService} getMainService={getMainService} />
            ) : (
              <DataGridTable serviceList={serviceList} getService={getService} getMainService={getMainService} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default BranchServices;
