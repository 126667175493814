import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";
import {
  clockA,
  clockGreen,
  creditCardPlus,
  crossRed,
  customer,
  dateIcon,
  deleteIcon,
  editButton,
  editGreen,
  emailIcon,
  messagecta,
  messagectaA,
  serviceGreen,
  test,
  visible,
} from "../../../assets/images";
import { BsFillTelephoneFill } from "react-icons/bs";
import NewBookingModal from "./BookingModal";
import MarkCompletedModal from "./MarkCompleteModal";
import ViewHistoryModal from "./ViewHistoryModal";
import CancelModal from "./CancelModal";
import NoShowModal from "./NoShowModal";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { bundleGrouping, convertTimeToMinute, formatDateFormat } from "../../../constants/Constants";
import PaymentModal from "./PaymentModal";
import { FaMobileScreenButton } from "react-icons/fa6";
import RescheduleBooking from "./RescheduleBooking";
import { convertMinutesToTime } from "../../../Utils/Funcs";
import AppointmentModal from "./AppointmentModal";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input';
const CustomarModal = (props: any) => {
  let { showCustomer, handleCustomerClose, bookingData, getBooking, viewBookingData } = props;

  let { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const canChangeOwnBooking = mainModuleRoles?.Bookings?.ChangeOwnBooking;
  const canChangeAllBookings = mainModuleRoles?.Bookings?.ChangeAllBookings;

  const [userData, setUserData] = useState<any>(null)

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user_data") || "{}");
    if (userInfo?.userType === "teamMember") {
      setUserData(userInfo);
    }
  }, [])
  

  

  const [showAppointment, setShowAppointment] = useState<boolean>(false);
  const handleAppointmentClose = () => setShowAppointment(false);

  const [activeButton, setActiveButton] = useState("");
  const [reschedule, setReschedule] = useState<Boolean>(false)
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  const [booking, setBooking] = useState<any>()
  const navigate = useNavigate();

  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  // const [booking, setBooking] = useState<any>({})

  // let booking: any = viewBookingData?.event?.booking;
  // let bookingDetils: any = viewBookingData?.event?.updateBooking;
  let bookingDetils: any = {
    ...viewBookingData?.event?.updateBooking, services: viewBookingData?.event?.updateBooking?.services?.map((data: any) => {
      return { ...data, serviceId: { ...data?.serviceId, serviceId: { ...data?.serviceDetails, serviceCatagoryId: data?.serviceCategoryDetails } } }
    })
  };


  const today = Date.now();
  const bookingDate = +new Date(viewBookingData?.event?.start ? viewBookingData?.event?.start : "");

  const startArr: any = bookingDetils?.services?.[0]?.startTime?.split(":");

  const starthours: any = Number?.parseInt(startArr?.[0]);
  const startMinutes: any = Number?.parseInt(startArr?.[1]);

  const totalDuration = booking ? booking?.services?.reduce((acc: any, item: any) => acc + Number.parseInt(item?.serviceId?.duration), 0) : 0

  // const totalDuration: any = bookingDetils?.services?.reduce((acc: any, val: any) => Number(acc) + Number?.parseInt(val?.serviceId?.duration), 0)
  let newMinutes = startMinutes + totalDuration
  let endHour = starthours + Math.floor(newMinutes / 60);
  let endMinute = newMinutes % 60

  const totalPrice = booking ? booking?.services?.map((item: any) => {
    return item?.serviceId?.seasonPrice
  }).reduce((sum: any, num: any) => sum + parseFloat(num), 0) : ""
  // edit Booking modal
  const [showBooking, setShowBooking] = useState(false);
  const handleBooking = () => setShowBooking(false);
  const handleBookingShow = () => setShowBooking(true);

  // mark complete modal
  const [showComplete, setShowComplete] = useState(false);
  const handleCompleteClose = () => setShowComplete(false);
  const handleCompleteShow = () => setShowComplete(true);

  // view history modal
  const [showHistory, setShowHistory] = useState(false);
  const handleHistoryClose = () => setShowHistory(false);
  const handleHistoryShow = () => setShowHistory(true);

  // cancel Modal
  const [showCancel, setShowCancel] = useState(false);
  const handleCancelClose = () => setShowCancel(false);

  // paymwnt Modal
  const [showPaymentBooking, setPaymentBookingShow] = useState(false);
  const handlePaymentBookingClose = () => setPaymentBookingShow(false);
  const handlePaymentBookingShow = () => setPaymentBookingShow(true);

  // RescheduleBooking
  const [showRescheduleBooking, setRescheduleBookingShow] = useState(false);
  const handleRescheduleBookingClose = () => setRescheduleBookingShow(false);
  const handleRescheduleBookingShow = () => setRescheduleBookingShow(true);

  // no Show modal
  const [showNoShow, setNoShow] = useState(false);
  const handleNoShowClose = () => setNoShow(false);
  let allAmenities: string[] = [];
  bookingDetils?.services?.flatMap((item: any) => {
    allAmenities = item.amenitiesId?.map((val: any) => val?.itemName?.itemName)
  }
  )

  const redirectToChangePage = (client: any, appointment: any) => {
    navigate(`/business/chat`, { state: { client, appointment } });
  };


  const allPromotion = booking?.services?.flatMap((item: any) => {
    const promoData = item?.promotionId?.priceId?.filter((priceItem: any) => priceItem?.id === item?.serviceId?._id)
    return promoData?.map((promoItem: any) => promoItem ? `${promoItem?.discount?.includes("usd") ? promoItem?.discount?.replaceAll("usd", "SGD") : promoItem?.discount} on ${item?.serviceDetails?.serviceName}` : null)
  });


  const getBookings = async () => {
    let res = await httpRequest(`getBooking?branchId=${branchId}&businessId=${businessData?._id}&_id=${bookingDetils?._id}`, "get", null, "json");
    if (res.status) {
      const singleBooking = res?.data?.find((book: any) => book?._id === bookingDetils?._id)
      setBooking(singleBooking);
    }
  }

  useEffect(() => {
    getBookings()
  }, [bookingDetils?._id])

  const bookingId = `${booking?.bookedBy === "online" ? booking?.booking_Id || "" : booking?.businessBookingId || ""}`;

  const isBundle: any = bookingDetils?.services?.some((item: any) => item?.bundleId)
  const bundleGroup = isBundle && booking ? bundleGrouping(booking?.services) : [];

  const isUnassign = booking?.services?.some((item: any) => item?.TeamMemberId?.length === 0);

  // const change: any =   canChangeAllBookings || canChangeOwnBooking ? userData !== null ? booking?.services?.every((item:any) => item?.TeamMemberId?.length > 0 ? item?.TeamMemberId?.[0]?._id === userData?._id : false) : true : false
  const change: any =   canChangeAllBookings

  return (
    <>
      <Modal
        centered
        scrollable
        show={showCustomer}
        onHide={handleCustomerClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts} ${m.bookingModalHead_imgBox} ${showCancel || showAppointment || showBooking || showRescheduleBooking || showComplete || showHistory || showNoShow || showPaymentBooking ? m.hide : ""}`}
      >
        <Modal.Header className="pt-3 pb-3">
          {/* <Modal.Title>{bookingDetils?.Client?.firstName} {bookingDetils?.Client?.lastName}</Modal.Title> */}
          {/* <Modal.Title>{bookingData?.firstName} {bookingData?.lastName} {bookingData?.nickName ? ` - ${bookingData?.nickName}` : ""}</Modal.Title> */}
          <Modal.Title>
            <div className={m.description}>
              <div className={m.customerDesc}>
                <h3 className="mb-0" style={{ fontSize: "16px" }}>Details : <span style={{ fontSize: '75%' }} title={bookingId}>(Booking #{bookingId})</span></h3>

              </div>
            </div>
          </Modal.Title>
          <button
            className={`${m.closeIcon} ${m.v2}`}
            onClick={handleCustomerClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          {booking && <Row>
            <Col md={12}>
              <div className={m.slot}>
                <div className={m.bottom}>
                  <div className={`${m.content} ${m.v2} px-0`}>
                    <div className={`${m.contactInfo}`}>
                      <div className={`${m.left}`}>
                        <div className={m.imageBox}>
                          <span>{`${booking?.Client?.firstName?.[0]?.toLocaleUpperCase() || ""}${booking?.Client?.lastName ? booking?.Client?.lastName?.[0]?.toLocaleUpperCase() : ""}`}</span>
                        </div>
                        <div className={`${m.content}`}>
                          <h3>{`${booking?.Client?.firstName || ""} ${booking?.Client?.lastName || ""}`} {booking?.guestname ? ` (${booking?.guestname})` : ""}</h3>
                          <p><img src={emailIcon} alt="email" /> {`${booking?.Client?.email || ""}`}</p>
                        </div>
                      </div>
                    </div>

                    <div className={`${m.by_services_flex} ${m.card_phoneMain}`}>
                      <div className={`${m.service} m-0`}>
                        <div className={m.serviceEntry}>
                          <img src={creditCardPlus} alt="card" />
                          <span>{booking?.Client?.clientAccountNo || ""}</span>
                        </div>
                        {booking?.Client?.mobile && <div className={m.serviceEntry}>
                          <div className={`${m.servicePhoneInput}`}>
                            <PhoneInput
                              defaultCountry="SG"
                              disabled
                              value={`+${booking?.Client?.mobile}` || ""}
                              international
                              placeholder="Enter phone number" onChange={function (value?: Value): void {
                                throw new Error("Function not implemented.");
                              }}
                            />
                          </div>
                        </div>}
                      </div>
                    </div>

                    <div className={`${m.by_services_ClrBorder}`}>
                      <div className={`${m.by_services_flex}`}>
                        <div className={`${m.service} m-0`}>
                          <div className={m.serviceEntry}>
                            <img style={{ height: "30px", width: "30px" }} src={dateIcon} alt="schedule" />
                            <span>{formatDateFormat(bookingDetils?.salectDate || "")}</span>
                          </div>
                          <div className={m.serviceEntry}>
                            <img style={{ height: "24px", width: "24px" }} src={clockA} className={`${m.v2}`} alt="time" />
                            <span>{bookingDetils?.services?.[0]?.startTime}  - {endHour < 10 ? `0${endHour}` : endHour} : {endMinute < 10 ? `0${endMinute}` : endMinute}</span>
                          </div>

                        </div>

                      </div>
                    </div>

                    {isBundle && (
                      <>
                        <div className={`${m.newBundle_Main} mt-2`}>
                          {bundleGroup?.map((item: any, i: number) => {
                            const bundlePromotionalPrice = item?.[Object.keys(item)?.[0]][0]?.bundlePromotionalPrice || ""

                            return (
                              <React.Fragment key={i}>
                                <div className="d-flex align-items-center justify-content-between">
                                  <h5>{Object.keys(item)?.[0] || ""}</h5>
                                  <p className="d-flex align-items-center gap-2 m-0">
                                    <span style={bundlePromotionalPrice ? { color: "red", textDecoration: "line-through" } : { color: "#1e8c76" }}>SGD{item?.[Object.keys(item)?.[0]][0]?.bundleId?.finalPrice}</span>
                                    {bundlePromotionalPrice && <span style={{ color: "#1e8c76" }}>{bundlePromotionalPrice}</span>}
                                  </p>
                                </div>
                                {item?.[Object.keys(item)?.[0]]?.map((service: any, s: any) => {
                                  const startMinutes = convertTimeToMinute(service?.startTime)
                                  const endMinutes: any = startMinutes + Number.parseInt(service?.serviceId?.duration);
                                  const toTime: any = convertMinutesToTime(endMinutes)
                                  const firstName = service?.TeamMemberId?.[0]?.firstName || ""
                                  const lastName = service?.TeamMemberId?.[0]?.lastName || "";
                                  const nickName = service?.TeamMemberId?.[0]?.nickName || "";


                                  return (
                                    <div key={s}>
                                      <div className={`${m.boxes}`}>
                                        <div className={`${m.newBundleServices}`}>
                                          <div className={`${m.tops}`}>
                                            <p className={`${m.specialy}`}>{service?.serviceCategoryDetails?.serviceCategoryName} : {service?.serviceDetails?.serviceName}</p>
                                            <p className={`${m.times}`}>{service?.serviceId?.priceText || ""} {service?.serviceId?.duration} - {`SGD ${service?.serviceId?.seasonPrice}`} </p>
                                          </div>

                                          <div className={`${m.by_services_imgBox}`}>
                                            <div className={`${m.by_services_main} justify-content-between`}>
                                              {/* <div className={`${m.img_text}`}>
                                                {service?.TeamMemberId?.length !== 0 ? service?.TeamMemberId?.[0]?.firstName?.[0]?.toLocaleUpperCase() : "UA"}
                                              </div> */}
                                              <div className={`${m.details} ms-0`}>
                                                <h3>{firstName ? "Served By" : "Unassigned"} {firstName} {lastName} {nickName}</h3>

                                              </div>
                                              <div className={`${m.details}`}>
                                                <h3 style={{ color: '#212529' }}> {convertMinutesToTime(startMinutes)} - {toTime} ({service?.serviceId?.duration})</h3>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </React.Fragment>
                            )
                          })}

                        </div>
                      </>
                    )}
                    {!isBundle && (
                      <>
                        <div className={`${m.newBundle_Main}`}>
                          {booking?.services?.map((service: any, s: any) => {
                            const startMinutes = convertTimeToMinute(service?.startTime)
                            const endMinutes: any = startMinutes + Number.parseInt(service?.serviceId?.duration);
                            const toTime: any = convertMinutesToTime(endMinutes)
                            const firstName = service?.TeamMemberId?.[0]?.firstName || ""
                            const lastName = service?.TeamMemberId?.[0]?.lastName || "";
                            const nickName = service?.TeamMemberId?.[0]?.nickName || "";

                            const promotion = service?.promotionId?.promotionName || ""
                            const promotionalPriceObj = service?.promotionId ? service?.promotionId?.priceId?.find((item: any) => item?.id === service?.serviceId?._id) : null

                            return (
                              <div key={s}>
                                <div className={`${m.boxes}`}>
                                  <div className={`${m.newBundleServices}`}>
                                    <div className={`${m.tops}`}>
                                      <p className={`${m.specialy}`}>{service?.serviceCategoryDetails?.serviceCategoryName} : {service?.serviceDetails?.serviceName}</p>
                                      <p className={`${m.times}`}>{service?.serviceId?.priceText || ""} {service?.serviceId?.duration} - {`SGD ${service?.serviceId?.seasonPrice}`} </p>
                                    </div>

                                    <div className={`${m.by_services_imgBox}`}>
                                      <div className={`${m.by_services_main} justify-content-between`}>
                                        {promotion && <div className={`${m.details} ms-0`}>
                                          <h3>Applied Promotion : {promotion}</h3>
                                          <h3>Discount Price : {`SGD ${promotionalPriceObj?.promotionalPrice}` || ""}</h3>
                                        </div>}

                                      </div>
                                      <div className={`${m.by_services_main} justify-content-between`}>
                                        <div className={`${m.details} ms-0`}>
                                          <h3>{firstName ? "Served By" : "Unassigned"} {firstName} {lastName} {nickName}</h3>
                                        </div>
                                        <div className={`${m.details}`}>
                                          <h3 style={{ color: '#212529' }}> {convertMinutesToTime(startMinutes)} - {toTime} ({service?.serviceId?.duration})</h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            )
                          })}


                        </div>
                      </>
                    )}


                    {/* end */}


                    <div className={m.period} style={{ marginTop: '0px', borderTop: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3', padding: '9px 0px', background: '#effdee' }}>
                      <div className={m.left}>
                        <h3>Duration</h3>
                        <p className="mb-0">{totalDuration} mins</p>
                      </div>
                      <div className={m.left}>
                        <h3>Price</h3>
                        {booking ? <p className="mb-0"> {+booking?.finalPrice !== +totalPrice && <span style={{ color: "red", textDecoration: "line-through" }}>SGD {Number.parseFloat(totalPrice)?.toFixed(2)}</span>} <span>SGD {Number.parseFloat(booking?.finalPrice)?.toFixed(2)}</span></p> : ""}
                      </div>
                    </div>
                    <div>
                      <div className={`${m.payStatus_channel}`}>
                        <ul>
                          <li>
                            <span className={`${m.left_pack}`}>Payment Status: </span>
                            <span className={`${m.right_pack}`}>{booking?.paymentStatus === "Pending" ? "Pending" : "Completed"}</span>
                          </li>

                          <li>
                            <span className={`${m.left_pack}`}>Booking Channel: </span>
                            <span className={`${m.right_pack}`}>Wellnezza-{booking?.bookedBy}</span>
                          </li>
                          {/* {bookingDetils?.Client?.email && <li>
                            <span className={`${m.left_pack}`}>Email: </span>
                            <span className={`${m.right_pack}`}>{bookingDetils?.Client?.email}</span>
                          </li>} */}
                        </ul>


                      </div>

                    </div>

                    <div className={`${m.serviceEntry} mb-3`} >

                      {bookingDetils?.notes && (
                        <>
                          <h5 style={{ color: "#1e8c76", fontSize: "15px" }}>Booking Notes : </h5>
                          <span>
                            {bookingDetils?.notes}
                          </span>
                        </>
                      )}
                    </div>

                    {/* <div className={m.priceField}>
                      <span className={m.price}>Price</span>
                      <span className={m.priceNum}>{totalPrice} USD</span>
                    </div> */}
                    <div className={m.cta}>
                      {/* {bookingData?.Role[0]?.accessManager === "superadmin" || bookingData?.Role[0]?.accessManager === "receptionist" && ( */}
                      {bookingDetils?.bookingStatus === "Upcoming" ? (
                        <>
                          {booking?.Client?.mobile && <button>
                            <FaMobileScreenButton className={m.phone} /> <span>{booking?.Client?.mobile}</span>
                          </button>}
                          <button onClick={() => redirectToChangePage(bookingDetils?.Client, bookingDetils?._id)}>
                            <img src={messagectaA} alt="message" /> <span>Chat</span>
                          </button>
                        </>) : ""}
                      {/* )} */}
                    </div>


                    <div className={`${m.cta} ${m.v2}`}>
                      {bookingDetils.bookingStatus !== "Completed" && change && !bookingDetils?.services?.some((service: any) => service?.bookingStatus === "Completed" || service?.bookingStatus === "No Show") && bookingDetils.bookingStatus !== "No Show" && bookingDate <= today && <button className={`${m.v2} ${activeButton === "complete" ? m.active : ""}`} onClick={() => {
                        setShowComplete(true)
                        setActiveButton("complete");
                      }}>Mark Complete</button>}

                      {booking?._id && bookingDate >= today && change && (bookingDetils?.bookingStatus !== "Cancelled" || bookingDetils?.bookingStatus !== "Client Cancelled") && bookingDetils?.bookingStatus !== "Completed" && <button className={`${m.v2} ${activeButton === "re" ? m.active : ""}`} onClick={() => {
                        setActiveButton("re")
                        setReschedule(true)
                        handleRescheduleBookingShow()
                      }}>

                        Reschedule
                      </button>}
                      <button className={`${m.v2} ${activeButton === "history" ? m.active : ""}`} onClick={() => {
                        setShowHistory(true);
                        setActiveButton("history")
                      }}>View history</button>
                    </div>

                    {booking?._id && bookingDate >= today && change && (bookingDetils?.bookingStatus !== "Cancelled" || bookingDetils?.bookingStatus !== "Client Cancelled") && bookingDetils?.bookingStatus !== "Completed" && <div className={`${m.cta} ${m.v2}`}>
                      <button className={`${m.v2} ${activeButton === "edit" ? m.active : ""}`} onClick={() => {
                        setActiveButton("edit")
                        setReschedule(false)
                        handleBookingShow()
                      }
                      }>
                        {/* <img src={editGreen} alt="edit" /> */}
                        <img src={editButton} alt="edit" /> Edit Booking
                      </button>

                    </div>}
                    {isUnassign && change && <div className={`${m.cta} ${m.v2}`}>
                      <button className={`${m.v2} ${activeButton === "assign" ? m.active : ""}`} onClick={() => {
                        setActiveButton("assign")
                        setShowAppointment(true)
                      }
                      }>
                        {/* <img src={editGreen} alt="edit" /> */}
                        Assign Team
                      </button>

                    </div>}

                    <div className={`${m.cta} ${m.v2}`}>
                      {bookingDetils.bookingStatus !== "Completed" && change && (bookingDetils?.bookingStatus !== "Cancelled" || bookingDetils?.bookingStatus !== "Client Cancelled") && bookingDetils?.bookingStatus !== "No Show" && !bookingDetils?.services?.some((service: any) => service?.bookingStatus === "Completed" || service?.bookingStatus === "No Show") && bookingDate <= today && <button className={`${m.v2} ${activeButton === "noShow" ? m.active : ""}`} onClick={() => {
                        setNoShow(true);
                        setActiveButton("noShow")
                      }}>
                        {/* <img src={visible} className={m.show} alt="NoShow" /> */}
                        No Show
                      </button>}
                      {change && <button className={`${m.v2} ${activeButton === "payment" ? m.active : ""}`} onClick={() => {
                        setActiveButton("payment")
                        handlePaymentBookingShow()
                      }}>Payment</button>}
                      {bookingDate >= today && change && bookingDetils?.bookingStatus === "Upcoming" && <button style={{ color: '#ff4848' }} className={`${m.v2}`} onClick={() => {
                        setShowCancel(true);
                        setActiveButton("cancel")
                      }}>
                        {/* <img src={crossRed} alt="Cancel" /> */}
                        <img src={deleteIcon} alt="cancel" /> Cancel Booking
                      </button>}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>}

        </Modal.Body>

        {showAppointment && <AppointmentModal
          showAppointment={showAppointment}
          bookingData={booking}
          getBooking={getBooking}
          handleAppointmentClose={handleAppointmentClose}
          handleCustomerClose={handleCustomerClose}
        />}

        {showBooking && booking?._id && <NewBookingModal
          reschedule={reschedule}
          showBooking={showBooking}
          handleBooking={handleBooking}
          data={booking}
          getBooking={getBooking}
          handleCompleteClose={handleCompleteClose}
          handleCustomerClose={handleCustomerClose}

        />}
        {showComplete && <MarkCompletedModal
          showComplete={showComplete}
          handleCompleteClose={handleCompleteClose}
          data={booking}
          calendarData={booking}
          getBooking={getBooking}
          handleCustomerClose={handleCustomerClose}
          type="calendar"
          booking={booking}
          serviceType={isBundle ? "bundle" : "service"}
          bundleGroup={isBundle ? bundleGroup : null}
        />}
        <ViewHistoryModal
          showHistory={showHistory}
          handleHistoryClose={handleHistoryClose}
          data={{ ...booking, Client: bookingDetils?.Client }}
          serviceType={isBundle ? "bundle" : "service"}
          bundleGroup={isBundle ? bundleGroup : null}
        />
        <CancelModal
          handleCancelClose={handleCancelClose}
          handleCustomerClose={handleCustomerClose}
          showCancel={showCancel} data={booking}
          getBooking={getBooking}
          serviceType={isBundle ? "bundle" : "service"}
          bundleGroup={isBundle ? bundleGroup : null} />

        <NoShowModal handleNoShowClose={handleNoShowClose} showNoShow={showNoShow} data={booking} id={bookingDetils?._id} getBooking={getBooking} handleCustomerClose={handleCustomerClose} />

        {showPaymentBooking && <PaymentModal
          handleCustomerClose={handleCustomerClose}
          getBooking={getBooking}
          show={showPaymentBooking}
          handlePaymentBookingClose={handlePaymentBookingClose}
          data={{ ...booking, Client: bookingDetils?.Client }}
          serviceType={isBundle ? "bundle" : "service"}
          bundleGroup={isBundle ? bundleGroup : null} />}

        {showRescheduleBooking && <RescheduleBooking getBooking={getBooking} show={showRescheduleBooking} handleRescheduleBookingClose={handleRescheduleBookingClose} handleCustomerClose={handleCustomerClose} data={booking} />}
      </Modal>
    </>
  );
};

export default CustomarModal;
