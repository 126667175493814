import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import cx from "./index.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import { copyClipBoard, deleteIcon, editIcon, openIcon } from "../../../assets/images";
import { Status } from "../../../components/Business";
import ViewPromotion from "../../../components/Business/Modals/ViewPromotion";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import { Col } from "react-bootstrap";

const StatusButton = (props: any) => {
  const { params, permotionData } = props;
  const { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPromotion, setShowPromotion] = useState(false);
  const handlePromotionClose = () => setShowPromotion(false);
  const handlePromotionShow = () => setShowPromotion(true);
  const handleEditClick = (e: any) => {
    const link = params?.row?.promotionIdentity === "business" ? `/business/promotion/edit-promotion/info` : "/business/promotion/wellnezza-edit-promotion/wellnezza-info"
    navigate(link, { state: { promotionId: params?.row?.id } });
  };

  const deleteApi = async () => {
    const res = await httpRequest(
      `deletePromotion/${params?.row?.id}`,
      "DELETE",
      null,
      "json"
    );
    if (res?.status == true) {
      props.getPromotion();
      handleClose();
    } else {
    }
  };

  const filterPromotionData = permotionData?.find((data: any) => data?._id === params?.row?.id);
  const canEditPromo = mainModuleRoles?.Promotion?.Edit;
  const canDeletePromo = mainModuleRoles?.Promotion?.Delete;
  const candeactivate = mainModuleRoles?.Promotion?.deactivate;

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {canEditPromo && <li>
            <p
              className={`btn ${tb.edit}`}
              title="Edit"
            >
              <img src={editIcon} alt="edit" onClick={() => handleEditClick(params?.row)} />
            </p>
          </li>}
          {/* <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="view"
              to="#"
              onClick={handlePromotionShow}
            >
              <img src={openIcon} alt="view" />
            </NavLink>
          </li> */}
          {canDeletePromo && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>}

          {/* {params?.row?.promotionIdentity === "business" && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="copy"
              onClick={handleCopyClick}
            >
              <img src={copyClipBoard} alt="copy" style={{height:"20px", width:"20px" , marginTop:"1px"}}/>
            </button>
          </li>} */}
          {candeactivate && <li>
            <Status status={params.row.isActive}
              api='updatePromotion'
              id={params?.id}
              apiMethod='json'
              getLatest={props.getPromotion} />
          </li>}
        </ul>
      </div>

      <DeletePopup show={show} handleClose={handleClose} deleteApi={deleteApi} />
      <ViewPromotion
        showPromotion={showPromotion}
        handlePromotionClose={handlePromotionClose}
        promotionData={filterPromotionData}
      />
    </>
  );
};

function ServiceList(props: any) {
  const { params } = props;

  if (params?.row?.applyTo?.length === 0) {
    return <>-</>
  }

  return <>
    <ul className={`${cx.serviceCatList}`}>
      {
        params?.row?.applyTo?.map((data: any) => {
          const discount = data?.discount ? data?.discount : data?.discountCriteria?.length !== 0 ? `${data?.discountCriteria?.[0]?.discount} %` : data?.discount ? data?.discount : "0 %"
          return (

            <li className={cx.listItem} key={data?._id}>
              <span className={cx.categoryName}>
                <span className={cx.bold} title={`${discount?.split(" ")[0] === "" ? `0 ${discount?.split(" ")[1]}` : discount} discount on ${data?.id?.serviceId?.serviceName ? data?.id?.serviceId?.serviceName : "-"} ${data?.id?.seasonType} ${data?.id?.duration ? data?.id?.duration : ""}`}>{`${discount?.split(" ")[0] === "" ? `0 ${discount?.split(" ")[1]}` : discount} discount on ${data?.id?.serviceId?.serviceName ? data?.id?.serviceId?.serviceName : "-"} ${data?.id?.seasonType[0]?.toLocaleUpperCase() + data?.id?.seasonType?.slice(1)} ${data?.id?.duration ? data?.id?.duration : ""}`}</span>
              </span>
            </li>

          )
        })
      }

    </ul>
  </>
}

export default function DataGridTable(props: any) {
  let rows: any;

  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 250,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        if (v1 === v2) return 0;
        return v1 ? -1 : 1;
      },
      renderCell: (params: any) =>
        <StatusButton params={params}
          permotionData={props?.permotion}
          getPromotion={props.getPromotion}
        />,
    },
    {
      field: "promotionName",
      headerName: "Promotion Name",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "promotionType",
      headerName: "Promotion Type",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "platform",
      headerName: "Managed By",
      flex: 2,
      minWidth: 140,
    },
    // {
    //   field: "applyTo", headerName: "Apply to", flex: 2, minWidth: 430, renderCell: (params: any) =>
    //     <ServiceList params={params}

    //     />,
    // },
    { field: "date", headerName: "Booking Date", flex: 2, minWidth: 220 },
    { field: "dateSell", headerName: "Sell Date", flex: 2, minWidth: 220 },
    { field: "time", headerName: "Booking Time", flex: 2, minWidth: 140 },
    { field: "useby", headerName: "Use By", flex: 2, minWidth: 140 },
    { field: "maxUsePerClient", headerName: "Max User Limit", flex: 2, minWidth: 140 },
    { field: "maxUsePerClient", headerName: "Max User Limit", flex: 2, minWidth: 140 },
    { field: "newCustomer", headerName: "New Customer", flex: 2, minWidth: 140 },


  ];
  if (props?.permotion) {
    rows = props?.permotion.map((per: any, i: any) => {
      console.log(per, "perdata");

      return (
        {
          id: per?._id,
          serialNumber: i + 1,
          promotionName: per?.promotionName,
          promotionIdentity: per?.promotionIdentity,
          platform: per?.promotionIdentity === "wellnezza" ? "Platform" : "Business",
          isActive: per?.isActive,
          applyTo: per?.priceId ? per?.priceId : "",
          date: `${per?.dateFrom ? per?.dateFrom : ""} - ${per?.dateTo ? per?.dateTo : ""}`,
          dateSell: `${per?.selldateFrom ? per?.selldateFrom : ""} - ${per?.selldateTo ? per?.selldateTo : ""}`,
          time: `${per?.startTimeFrom ? per?.startTimeFrom : ""} - ${per?.endTimeTo ? per?.endTimeTo : ""}`,
          selltime: `${per?.sellstartTimeFrom ? per?.sellstartTimeFrom : ""} - ${per?.sellendTimeTo ? per?.sellendTimeTo : ""}`,
          promotionType: per?.promotionType,
          useby: per?.promotionProvidesCategory && per?.promotionProvidesCategory !== " " ? per?.promotionProvidesCategory : '-',
          maxUsePerClient: per?.LimitNumberOfUses?.maxUser || '-',
          NumberOfUses: per?.LimitUsePerClient || '-',
          newCustomer: per?.forFirstTime || '-',
          action: per?.isActive,
        }
      )
    })
  }

  return (
    <div className={`${tb.dataTable}`}>

      <div style={{ width: "100%" }}>
        <Col md={12}>
          <div className="text-end">
            <HelpButon number={46} type={"help"} className={"btn"} />
          </div>
        </Col>
        <DataGrid
          columns={columns}
          rows={rows}
          autoHeight
          hideFooterPagination={false}
          rowHeight={60}
          checkboxSelection={false}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}

        />
      </div>
    </div>
  );
}
