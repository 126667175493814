import { configureStore } from "@reduxjs/toolkit";
import registrationReducer from "../reducer/registrationReducer";
import shareDataReducer from "../reducer/shareDataReducer";
import AlertMsgReducer from "../reducer/AlertMsgReducer";
import branchReducer from "../reducer/branchReducer";
import HelpAndDocumentsReducer from "../reducer/HelpAndDocumentsReducer";

export const store = configureStore({
  reducer: {
    registrationReducer: registrationReducer,
    shareDataReducer: shareDataReducer,
    AlertMsgReducer: AlertMsgReducer,
    BranchReducer: branchReducer,
    helpAndDocuments: HelpAndDocumentsReducer
  },
});
