import React, { useEffect, useRef, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Row } from "react-bootstrap";
import { Switch as CustomSwitch } from "../../../components/Website/Forms";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import CustomTimeSelect from "../../../constants/CustomTimeSelect";
import { timeValues, weekValues } from "../../../constants/Constants";
import { disablePastDate } from "../../../Utils/Funcs";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { copyClipBoard, deleteIcon, editButton } from "../../../assets/images";
import { v4 as uuidv4 } from 'uuid';
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";

interface FormValues {
    [key: string]: string;
}

export default function OffPeakHours(props: any) {
    const { handleStepsFormData, editPromotionData, loading, type = "", editLoading } = props;
    const offPeakRef: any = useRef()
    const navigate = useNavigate();
    const { register, setValue, control, handleSubmit, watch, formState: { errors }, reset } = useForm<FormValues>();
    const [editId, setEditId] = useState<string | null>(null);


    const [isSameEveryDay, setIsSameEveryDay] = useState(false);
    const [dayWiseOff, setDayWiseOff] = useState<any>([]);

    const [offPeakList, setOffPeakList] = useState<any>([])

    useEffect(() => {
        if (editPromotionData) {
            reset();
            setOffPeakList(editPromotionData?.offPeakHours || [])
        }
    }, [editPromotionData]);

    useEffect(() => {
        if (dayWiseOff?.length !== 0) {
            dayWiseOff?.map((item: any, index: number) => {
                item?.value && setValue(`${item.day}from`, "");
                item?.value && setValue(`${item.day}to`, "");
            })
        }
    }, [dayWiseOff])

    const handleSwitchChange = (value: any) => {
        let isChecked = value
        let fromVal = isChecked ? watch('from', '') : '';
        let toVal = isChecked ? watch('to', '') : '';
        weekValues.forEach((item: any, index: number) => {
            if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
                return
            }
            setValue(`${item.label}from`, fromVal);
            setValue(`${item.label}to`, toVal);
        });
        setIsSameEveryDay(value);

    };

    function handleTimeChange() {
        if (isSameEveryDay) {
            let fromVal = watch('from', '');
            let toVal = watch('to', '');
            weekValues.forEach((item: any, index: number) => {
                if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
                    return
                }
                if (fromVal) setValue(`${item.label}from`, fromVal);
                if (toVal) setValue(`${item.label}to`, toVal);
            });
        }
    }


    const onSubmit: SubmitHandler<FormValues> = (data, index) => {
        const id = editId || uuidv4();
        let workingHours: any = [];
        weekValues.forEach((elem: any, index: any) => {
            workingHours.push({
                dayName: elem.label,
                from: dayWiseOff?.[index]?.value ? "Not applicable" : data[`${elem.label}from`],
                to: dayWiseOff?.[index]?.value ? "Not applicable" : data[`${elem.label}to`],
            });
        });

        const offPeakData = { isSameEveryDay: isSameEveryDay, from: data.from, to: data.to, shift: workingHours, _id: id };

        const filterOffPeakList = offPeakList?.filter((item: any) => item?._id !== id);

        setOffPeakList([...filterOffPeakList, offPeakData]);
        setIsSameEveryDay(false);
        setValue(`from`, "");
        setValue(`to`, "");

        setDayWiseOff([]);
        reset();
        weekValues.forEach((elem: any, index: any) => {
            let updateDayWiseOff: any = [...dayWiseOff];
            updateDayWiseOff[index] = {
                day: elem?.label,
                value: false
            };
            setDayWiseOff(updateDayWiseOff);
            setValue(`${elem?.label}from`, "");
            setValue(`${elem?.label}to`, "");
        });

        setEditId(null);
        offPeakRef?.current?.scrollIntoView({ behaviour: "smooth" });
    };

    const skipStep = (e: any) => {
        e.preventDefault();
        handleStepsFormData('prevStep', `${type}assign-branch`);
    }

    const resetOffPeak = () => {
        let allData: any = []
        handleStepsFormData(allData, 'step4', true);
    }

    const handleSave = () => {
        handleStepsFormData(offPeakList, 'step4');
    }



    const handleEditTime = (index: any) => {
        const item = offPeakList[index];
        setEditId(item._id);

        item.shift.forEach((shiftItem: any) => {
            setValue(`${shiftItem.dayName}from`, shiftItem.from === "Not applicable" ? "" : shiftItem.from);
            setValue(`${shiftItem.dayName}to`, shiftItem.to === "Not applicable" ? "" : shiftItem.to);
        });
        setDayWiseOff(item.shift.map((shiftItem: any) => ({ day: shiftItem.dayName, value: shiftItem.from === "Not applicable" })));

        if (item.isSameEveryDay) {
            setIsSameEveryDay(item.isSameEveryDay);
            setValue(`from`, item.from);
            setValue(`to`, item.to);
        }
    };

    function handleDeleteTime(i: number) {
        const filterItem = offPeakList?.filter((item: any, index: number) => index !== i);
        setOffPeakList(filterItem)
    }

    const sameFromValue = watch('from')
    const sameToValue = watch('to')
    // useEffect(() => {
    //     if (isSameEveryDay) {
    //         weekValues.forEach((item, index) => {
    //             if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
    //                 return
    //             }
    //             item?.value && setValue(`${item.label}from`, sameFromValue);
    //         });
    //     }
    // }, [sameFromValue])
    // useEffect(() => {
    //     if (isSameEveryDay) {
    //         weekValues.forEach((item, index) => {
    //             if (dayWiseOff?.[index] && dayWiseOff?.[index]?.value) {
    //                 return
    //             }
    //             item?.value && setValue(`${item.label}to`, sameToValue);
    //         });
    //     }
    // }, [sameToValue])


    return (
        <>
            <div className={`${st.newRedesign_main}`}>
                <Row>
                    <Col md={10}>
                        <Col md={7}>
                            <div className={`${st.branchContact_tabBox} ${st.branchContact_tabBox2} mb-4`} >
                                <h4 className="mb-0">Off Peak Hours</h4>
                                <p>Loerem lorem Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt ipsa unde quae! Commodi obcaecati sunt blanditiis in voluptates minima a, vero, impedit vel perferendis omnis maiores, laboriosam veniam ullam ex!</p>

                                <ul className={`${st.branchOperating_tab_InputMian}`}>
                                    <li>
                                        <div className={`${st.firstBox} ${st.copyAllDays}`}>
                                            <label className={`d-inline-flex ${st.checkbox}`}>
                                                <input type="checkbox"
                                                     />
                                                <span className={`${st.checkmark}`}></span>
                                                <span className={`${st.labelName} ms-0`}>Copy to <br /> all days</span>
                                            </label>
                                        </div>
                                        <div className='d-flex gap-2'>
                                            <div className={`${st.secondBox}`}>
                                                <div className={`${st.inoutArea}`}>
                                                    <label>From</label>
                                                    <Controller
                                                        name="from"
                                                        control={control}
                                                        rules={{ required: isSameEveryDay }}
                                                        render={({ field }) => {
                                                            return (
                                                                <select className="form-select" onChange={field.onChange} value={field.value} required={true}>
                                                                    <option value="">-Select-</option>
                                                                    {timeValues?.map((item: any) => {
                                                                        return (
                                                                            <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                // <CustomTimeSelect value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                                                            )
                                                        }}
                                                    />
                                                    {errors?.from?.type === "required" && (
                                                        <p className="errorMessage">required*</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={`${st.secondBox}`}>
                                                <div className={`${st.inoutArea}`}>
                                                    <label>To</label>
                                                    <Controller
                                                        name="to"
                                                        control={control}
                                                        rules={{ required: isSameEveryDay }}
                                                        render={({ field }) => {
                                                            let filteredTimeValues = timeValues?.filter((item: any) => item.value >= watch('from', ''))
                                                            return (
                                                                <select className="form-select" value={field.value} onChange={field.onChange} required={true}>
                                                                    <option value="">-Select-</option>
                                                                    {filteredTimeValues?.map((item: any) => {
                                                                        return (
                                                                            <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                // <CustomTimeSelect value={field.value} onChange={field.onChange}
                                                                //     timeValues={filteredTimeValues}
                                                                //     required={true} />
                                                            )
                                                        }}
                                                    />
                                                    {errors?.to?.type === "required" && (
                                                        <p className={"errorMessage"}>required*</p>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`${st.lastCopyClip}`}>
                                            <button onClick={() => handleSwitchChange(true)}>
                                                <img src={copyClipBoard} alt="copy" />
                                            </button>
                                        </div>
                                    </li>
                                    {weekValues.map((item: any, index: number) => {
                                        const notApplicableDay = dayWiseOff?.find((notItem: any) => notItem?.day === item?.label)
                                        return (
                                            <li>
                                                <div className={`${st.firstBox}`}>
                                                    <h6 style={{lineHeight:'33px'}}>{item.label}</h6>
                                                    {/* <label className={`d-inline-flex ${st.checkbox}`}>
                                                        <input type="checkbox"
                                                            checked={dayWiseOff?.[index]?.value}
                                                            onChange={(e: any) => {
                                                                let updateDayWiseOff: any = [...dayWiseOff];
                                                                updateDayWiseOff[index] = {
                                                                    day: item?.label,
                                                                    value: e.target.checked
                                                                }
                                                                setDayWiseOff(updateDayWiseOff)
                                                            }}
                                                        />
                                                        <span className={`${st.checkmark}`}></span>
                                                        <span className={`${st.labelName} ms-1`} >Closed</span>
                                                    </label> */}
                                                </div>
                                                <div className='d-flex gap-2'>
                                                    <div className={`${st.secondBox}`}>
                                                        <div className={`${st.inoutArea}`}>
                                                            <label>From</label>
                                                            <Controller
                                                                name={`${item.label}from`}
                                                                control={control}
                                                                rules={{ required: notApplicableDay ? false : true }}
                                                                render={({ field }) => {
                                                                    let filteredTimeValues: any = [];

                                                                    filteredTimeValues = timeValues

                                                                    return (
                                                                        <select className="form-select" disabled={dayWiseOff?.[index]?.value} value={field?.value} onChange={field.onChange} required={true}>
                                                                            <option value="">-Select-</option>
                                                                            {filteredTimeValues?.map((item: any) => {
                                                                                return (
                                                                                    <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        // <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px", minWidth: "84px" }} disabled={dayWiseOff?.[index]?.value} value={field?.value} onChange={field.onChange}
                                                                        //     timeValues={filteredTimeValues} required={true} />
                                                                    )
                                                                }}
                                                            />
                                                            {errors[`${item.label}from`]?.type === "required" && (
                                                                <span className="errorMessage">required*</span>
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div className={`${st.secondBox}`}>
                                                        <div className={`${st.inoutArea}`}>
                                                            <label>To</label>
                                                            <Controller
                                                                name={`${item.label}to`}  // This should match your form data structure
                                                                control={control}
                                                                rules={{ required: notApplicableDay ? false : true }}
                                                                render={({ field }) => {
                                                                    let filteredTimeValues: any = []

                                                                    filteredTimeValues = timeValues.filter((time) => time.value >= watch(`${item.label}from`, ''));

                                                                    // const filteredTimeValues = timeValues.filter((time) => time.value >= branchStartEndTime?.from && time.value <= branchStartEndTime?.to);
                                                                    return (
                                                                        <select className="form-select" disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange} required={true}>
                                                                            <option value="">-Select-</option>
                                                                            {filteredTimeValues?.map((item: any) => {
                                                                                return (
                                                                                    <option value={item?.value} key={item?.value}>{item?.label}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        // <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px" }} disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                                                                    );
                                                                }}
                                                            />
                                                            {errors[`${item.label}to`]?.type === "required" && (
                                                                <span className="errorMessage">required*</span>
                                                            )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}

                                    <li>
                                        <button type='submit' className={`${st.addGrey} btn`} onClick={handleSubmit(onSubmit)}>
                                            Save
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
            {/* <Row>
                <Col md={12}>
                    <div className="text-end">
                        <HelpButon number={35} type={"help"} className={"btn"} />
                    </div>
                </Col>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect} d-flex align-items-center justify-content-between`}>
                        <h2>Off Peak Hours</h2>
                        <button className={`${cx.resetButton} btn`} onClick={resetOffPeak}>Reset Off Peak Hours</button>
                    </div>
                </Col>

            </Row> */}
            {/* <Row>
                <Col md={12}>
                    <div className={`${cx.everyDay}`}>
                        <div className={`${cx.everyDaySwith} align-items-center`} >
                            <label>Same every day</label>
                            <Switch
                                checked={isSameEveryDay}
                                onChange={handleSwitchChange}
                            />
                        </div>
                        <div
                            className={`${cx.everyDaySelector} d-flex gap-3 mt-2`}
                        >
                            <div className={`${st.formBox}`} onClick={handleTimeChange}>
                                <Controller
                                    name="from"
                                    control={control}
                                    rules={{ required: isSameEveryDay }}
                                    render={({ field }) => {
                                        let filteredTimeValues: any;
                                        if (isSameEveryDay) {
                                            filteredTimeValues = timeValues
                                        }
                                        return (
                                            <CustomTimeSelect value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                                        )
                                    }}
                                />
                                {errors?.from?.type === "required" && (
                                    <p className="errorMessage">required*</p>
                                )}
                            </div>

                            <div className={`${st.formBox}`} onClick={handleTimeChange}>
                                <Controller
                                    name="to"
                                    control={control}
                                    rules={{ required: isSameEveryDay }}
                                    render={({ field }) => {
                                        let filteredTimeValues;
                                        if (isSameEveryDay) {
                                            filteredTimeValues = timeValues?.filter((item: any) => item.value >= watch('from', ''))
                                        }
                                        return (
                                            <CustomTimeSelect value={field.value} onChange={field.onChange}
                                                timeValues={filteredTimeValues}
                                                required={true} />
                                        )
                                    }}
                                />
                                {errors?.to?.type === "required" && (
                                    <p className={"errorMessage"}>required*</p>
                                )}
                            </div>
                            <button type='submit' className={`btn ${cx.saveBtn}`} onClick={handleSubmit(onSubmit)}>
                                Save
                            </button>
                        </div>
                    </div>
                </Col>

                {weekValues.map((item: any, index: number) => {
                    const notApplicableDay = dayWiseOff?.find((notItem: any) => notItem?.day === item?.label)
                    return (
                        <Col xl={3} lg={4} md={6} key={item.value}>
                            <div className={cx.timeSelect}>
                                <div className={`${st.formBox}`}>
                                    <label className="form-label">{item.label}</label>
                                    <Controller
                                        name={`${item.label}from`}
                                        control={control}
                                        rules={{ required: notApplicableDay ? false : true }}
                                        render={({ field }) => {
                                            let filteredTimeValues: any = [];

                                            filteredTimeValues = timeValues

                                            return (
                                                <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px", minWidth: "84px" }} disabled={dayWiseOff?.[index]?.value} value={field?.value} onChange={field.onChange}
                                                    timeValues={filteredTimeValues} required={true} />
                                            )
                                        }}
                                    />
                                    {errors[`${item.label}from`]?.type === "required" ? (
                                        <span className="errorMessage">required*</span>
                                    ) : <span>&nbsp;</span>
                                    }
                                </div>
                                <div className={`${st.formBox}`}>
                                    <div className="d-flex align-items-center justify-content-end" style={{ height: '30px' }}>
                                        <label className="form-label ms-2 text-nowrap">N/A</label>
                                        <div style={{ transform: "translate(5%, -11%)" }}>
                                            <Switch
                                                checked={dayWiseOff?.[index]?.value}
                                                onChange={(e: any) => {
                                                    let updateDayWiseOff: any = [...dayWiseOff];
                                                    updateDayWiseOff[index] = {
                                                        day: item?.label,
                                                        value: e.target.checked
                                                    }
                                                    setDayWiseOff(updateDayWiseOff)
                                                }}
                                            />
                                        </div>

                                    </div>
                                    <Controller
                                        name={`${item.label}to`}
                                        control={control}
                                        rules={{ required: notApplicableDay ? false : true }}
                                        render={({ field }) => {
                                            let filteredTimeValues: any = []

                                            filteredTimeValues = timeValues.filter((time) => time.value >= watch(`${item.label}from`, ''));

                                            return (
                                                <CustomTimeSelect style={{ fontSize: "13px", padding: "10px 11px", backgroundSize: "11px" }} disabled={dayWiseOff?.[index]?.value} value={field.value} onChange={field.onChange} timeValues={filteredTimeValues} required={true} />
                                            );
                                        }}
                                    />
                                    {errors[`${item.label}to`]?.type === "required" ? (
                                        <span className="errorMessage">required*</span>
                                    ) : <span>&nbsp;</span>
                                    }
                                </div>
                            </div>
                        </Col>
                    )
                })
                }
            </Row > */}

            <div className={cx.offList} ref={offPeakRef}>
                {offPeakList?.map((item: any, i: any) => {
                    return (
                        <Row key={i}>
                            <Col md={12} >
                                <div className={`${cx.everyDay}`}>
                                    <div className={`${cx.everyDaySwith} align-items-center`} >
                                        <label>Same every day</label>
                                        <Switch
                                            checked={item?.isSameEveryDay}
                                            disabled={true}
                                        />
                                    </div>
                                    {item?.isSameEveryDay && <div className={cx.displayTime}>
                                        <div className={`${st.formBox} w-auto`}>
                                            <div
                                                className={`position-relative d-flex align-items-center gap-5 ${cx.inputBackground}`}
                                            >
                                                <div className={cx.editContainer}>
                                                    <p>
                                                        {item?.from || "not set"}
                                                    </p>
                                                </div>
                                                <div className={cx.editContainer}>
                                                    <p>
                                                        {item?.to || "not set"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </Col>

                            <Col md={12}>
                                <Row>
                                    {item?.shift?.map((shiftItem: any, i: number) => {

                                        return (
                                            <Col lg={3} md={6} key={i}>
                                                <div className={cx.displayTime}>
                                                    <div className={`${st.formBox}`}>
                                                        <label className="form-label">{shiftItem?.dayName}</label>
                                                        <div
                                                            className={`position-relative d-flex align-items-center gap-5 ${cx.inputBackground}`}
                                                        >
                                                            <div className={cx.editContainer}>
                                                                <p>
                                                                    {shiftItem?.from === "Not applicable" ? "N/A" : shiftItem?.from}
                                                                </p>
                                                            </div>
                                                            <div className={cx.editContainer}>
                                                                <p>
                                                                    {shiftItem?.to === "Not applicable" ? "N/A" : shiftItem?.to}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                    <Col>

                                        <div className={cx.action}>
                                            <div className={`${cx.btn} mt-3`}>
                                                <span><img src={editButton} onClick={() => handleEditTime(i)} alt="edit"
                                                /></span>
                                                <span><img src={deleteIcon} alt="delete" onClick={() => handleDeleteTime(i)}
                                                /></span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <div style={{ borderBottom: '2px solid #eff0f5' }}></div>
                        </Row>
                    )
                })}
            </div>

            <Row>
                <Col md={12}>
                    <div className={`${st.formBox} ${st.formSign}`}>
                        <div className={`${st.btnsGroup} ${st.formSignGroup}`}>
                            <button
                                className={`btn ${st.btn1} ${st.btnGray} ${st.btnGrayLight} `}
                                onClick={() => handleStepsFormData('prevStep', `${type}services`)}
                            >
                                Back
                            </button>
                            <button className={`btn ${st.btn4} ${st.btnGray}  `} onClick={handleSave} disabled={loading || editLoading}>
                                {loading ? <LoadingBtn /> : 'Save'}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <Row>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={() => handleStepsFormData('prevStep', `${type}services`)}
                            >
                                Back
                            </button>
                            <button type='submit' onClick={skipStep} className={`btn ${st.btn1} ${cx.twoBtn}`} >
                                Skip
                            </button>
                            <button type='submit' className={`btn ${st.btn4}`} onClick={handleSave} disabled={loading} >
                                {loading ? <LoadingBtn /> : "Next"}
                            </button>

                        </div>
                    </div>
                </Col>
            </Row> */}
        </>
    )
}
