import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { clockA, creditCardPlus, customer, dateIcon, filter } from "../../../assets/images";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import UnassignAppointmentService from "./UnassignAppointmentService";
import { formatDateFormat } from "../../../constants/Constants";

import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input';


const AppointmentModal = (props: any) => {
  let { showAppointment, handleAppointmentClose, bookingData, getBookings, getBooking, handleCustomerClose } = props;
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const [teamList, setTeamList] = useState<any>([])
  const [serviceData, setServiceData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [teamLoading, setTeamLoading] = useState<boolean>(false);
  const [errorTrig, setErrorTrig] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<any>([])



  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    // if (selectedServices?.some((service: any) => service?.TeamMemberId?.length === 0)) {
    //   setErrorTrig(true);
    //   return
    // }
    const finalData = {
      bookingId: bookingData?._id,
      services: selectedServices,
    }
    const res = await httpRequest("assignTeamToBooking", "PATCH", finalData, "json");
    if (res?.status) {
      getBookings && getBookings()
      getBooking && getBooking()
      handleAppointmentClose()
      handleCustomerClose && handleCustomerClose()
    }
    setLoading(false)
  }

  const addService = (type: any, value: any, index: number, serviceId: any, startTime: any = null) => {
    let updateService = [...selectedServices];
    if (type === "team") {
      updateService[index] = {
        ...updateService[index],
        TeamMemberId: value ? [value] : []
      }
    }

    setSelectedServices(updateService)
  }

  useEffect(() => {
    console.log(bookingData?.services, "bookingData?.services");

    const services = bookingData?.services?.map((bookService: any) => ({
      TeamMemberId: bookService?.TeamMemberId?.length !== 0 ? [bookService?.TeamMemberId?.[0]?._id] : [],
      amenitiesId: bookService?.amenitiesId,
      bookingStatus: bookService?.bookingStatus,
      bundleId: bookService?.bundleId?._id,
      packageId: bookService?.packageId?._id,
      radeemPackage: bookService?.radeemPackage,
      radeemPackageViaUpdate: bookService?.radeemPackageViaUpdate,
      bundlePromotionalPrice: bookService?.bundlePromotionalPrice,
      bundlePromotionId: bookService?.bundlePromotionId,
      promotionId: bookService?.promotionId?._id,
      serviceId: bookService?.serviceId?._id,
      startTime: bookService?.startTime,
      _id: bookService?._id,
    }))
    setSelectedServices(services)
  }, [bookingData]);

  const startArr: any = bookingData?.services?.[0]?.startTime?.split(":");

  const starthours: any = Number?.parseInt(startArr?.[0]);
  const startMinutes: any = Number?.parseInt(startArr?.[1]);

  const totalDuration: any = bookingData?.services?.reduce((acc: any, val: any) => Number(acc) + Number?.parseInt(val?.serviceId?.duration), 0)
  let newMinutes = startMinutes + totalDuration
  let endHour = starthours + Math.floor(newMinutes / 60);
  let endMinute = newMinutes % 60;




  return (
    <>
      <Modal
        centered
        scrollable
        show={showAppointment}
        backdrop="static"
        keyboard={false}
        onHide={handleAppointmentClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header className="pt-2 pb-2">
          <Modal.Title>Appointment</Modal.Title>
          <button
            className={`${m.closeIcon} ${m.v2}`}
            onClick={handleAppointmentClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className={m.slot}>
            <div className={m.bottom}>
              <div className={`${m.content} ${m.v2} px-0`}>
                <div className={`${m.contactInfo}`}>
                  <div className={`${m.left}`}>
                    <div className={m.imageBox}>
                      <span>{`${bookingData?.Client?.firstName?.[0]?.toLocaleUpperCase()}${bookingData?.Client?.lastName ? bookingData?.Client?.lastName?.[0]?.toLocaleUpperCase() : ""}`}</span>
                    </div>
                    <div className={`${m.content}`}>
                      <h3>{`${bookingData?.Client?.firstName} ${bookingData?.Client?.lastName || ""}`} {bookingData?.guestname ? ` (${bookingData?.guestname})` : ""}</h3>
                      <p>{bookingData?.Client?.country || ""}</p>
                    </div>
                  </div>
                </div>
                <div className={`${m.by_services_flex}  ${m.card_phoneMain}`}>
                  <div className={`${m.service} m-0`}>
                    <div className={m.serviceEntry}>
                      <img src={creditCardPlus} alt="card" />
                      <span>{bookingData?.Client?.clientAccountNo || ""}</span>
                    </div>
                    {bookingData?.Client?.mobile && <div className={m.serviceEntry}>
                      <div className={`${m.servicePhoneInput}`}>
                        <PhoneInput
                          defaultCountry="SG"
                          disabled
                          value={`+${bookingData?.Client?.mobile}` || ""}
                          international
                          placeholder="Enter phone number" onChange={function (value?: Value): void {
                            throw new Error("Function not implemented.");
                          }}
                        />
                      </div>
                    </div>}
                  </div>
                </div>

                <div className={`${m.by_services_ClrBorder} mb-3`}>
                  <div className={`${m.by_services_flex}`}>
                    <div className={`${m.service} m-0`}>
                      <div className={m.serviceEntry}>
                        <img style={{ height: "30px", width: "30px" }} src={dateIcon} alt="schedule" />
                        <span>{formatDateFormat(bookingData?.salectDate || "")}</span>
                      </div>
                      <div className={m.serviceEntry}>
                        <img style={{ height: "24px", width: "24px" }} src={clockA} className={`${m.v2}`} alt="time" />
                        <span>{bookingData?.services?.[0]?.startTime}  - {endHour < 10 ? `0${endHour}` : endHour} : {endMinute < 10 ? `0${endMinute}` : endMinute}</span>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={m.bodyContainer} style={{height:'auto', overflow:'auto'}}>
            {/* <Row>
              <Col md={12}>
                <div className={`${st.formBox}`}>
                  <label className="form-label">Service</label>
                  <select className="form-select" disabled={loading} onChange={handleService}>
                    <option value="">{loading ? "Checking Availability" : "Select Service"}</option>
                    {bookingData?.services?.map((data: any) => {
                      return <option value={data?.serviceId?._id} key={data?.serviceId?._id}>{data?.serviceDetails?.serviceName}</option>
                    })}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              {teamMember?.length === 0 && selectedService !== "" ? (
                <h2 className={`${m.serviceError}`}>No Team Member Available at this time</h2>
              ) : teamMember?.map((item: any) => {

                return (
                  <Col md={4}>
                    <div
                      className={`${m.appointmentBox} ${serviceData?.filter((data: any) => data?.serviceId === selectedService)[0]?.TeamMemberId?.includes(item._id) ? m.selected : ''}`}
                      onClick={() => handleTeamMemberClick(item._id)}
                    >
                      <img src={item?.image ?? "https://spa-saloon-images.s3.amazonaws.com/business/images/1699445846324-images%20%283%29.png"} alt="customer" />
                      <h5>{item.firstName} {item.lastName}</h5>
                    </div>
                  </Col>
                )
              })}
              
            </Row> */}
            {bookingData?.services?.map((booking: any, index: number) => {
              const currService = selectedServices[index]

              if (booking?.TeamMemberId?.[0]) {
                return
              }

              return (
                <UnassignAppointmentService
                  key={booking?._id}
                  booking={booking}
                  currService={currService}
                  errorTrig={errorTrig}
                  index={index}
                  addService={addService}
                  loading={loading}
                  bookingData={bookingData}
                  selectedServices={selectedServices}
                />
              )
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={handleAppointmentClose}
          >
            Cancel
          </button>
          <button type="button" className={`btn ${m.actionBtn}`} onClick={handleSubmit}>
            {loading && serviceData?.length !== 0 ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              ></div>
            ) : (
              'Assign'
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AppointmentModal;
