import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import cx from "./index.module.scss";
import { RiInformationFill } from "react-icons/ri";
import { useSelector } from 'react-redux';

const HoverEyeContent = (props: any) => {
    const { number = "" } = props;
    const help = useSelector((state: any) => state?.helpAndDocuments)
    const screenHelp: any = help?.helpDocuments?.find((item: any) => Number(item?.screenNumber) === number);

    console.log(help?.helpDocuments , "help?.helpDocuments");
    
    const CustomTooltip = (props: any) => (
        <Tooltip className={`${cx.customTooltipBox}`} {...props}>

            {screenHelp?.details ? <div className={`${cx.customTooltipContent}`} dangerouslySetInnerHTML={{ __html: screenHelp?.details }} /> : (
                <>
                    <div className={`${cx.customTooltipContent}`}>No Content</div>
                    <div className={`${cx.customTooltipContent}`}>Please Add Content</div>
                </>
            )}
            <div className='text-end'>
                <span>#{number || "000000"}</span>
            </div>
        </Tooltip>
    );

    return (

        <div className={`${cx.customTooltipClick}`}>
            <OverlayTrigger
                placement="top"
                overlay={CustomTooltip}
            >
                <div className="btns">
                    <RiInformationFill />
                </div>
            </OverlayTrigger>
        </div>

    );




}
export default HoverEyeContent;