import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import { clockA, creditCardPlus, dateIcon } from "../../../assets/images";
import { convertTimeToMinute, formatDateFormat, formatSlashDate } from "../../../constants/Constants";
import { convertMinutesToTime } from "../../../Utils/Funcs";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input';


const MarkCompletedModal = (props: any) => {
  let { showComplete, updateService, usertype, handleCompleteClose, data, handleCustomerClose, getBookings, getBooking, type = "list", calendarData, booking = null, serviceType, bundleGroup } = props;
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );

  const [updatedData, setUpdatedData] = useState<any>([]);
  let { branchId } = useSelector((state: any) => state.BranchReducer);

  useEffect(() => {
    if (booking) {
      setUpdatedData(booking?.services?.map((bookService: any) => {
        return {
          TeamMemberId: bookService?.TeamMemberId?.length !== 0 ? [bookService?.TeamMemberId?.[0]?._id] : [],
          amenitiesId: bookService?.amenitiesId,
          bookingStatus: bookService?.bookingStatus,
          bundleId: bookService?.bundleId?._id,
          packageId: bookService?.packageId?._id,
          radeemPackage: bookService?.radeemPackage,
          radeemPackageViaUpdate: bookService?.radeemPackageViaUpdate,
          bundlePromotionalPrice: bookService?.bundlePromotionalPrice,
          bundlePromotionId: bookService?.bundlePromotionId,
          promotionId: bookService?.promotionId?._id,
          serviceId: bookService?.serviceId?._id,
          startTime: bookService?.startTime,
          _id: bookService?._id,

        }
      }))
    }
  }, [booking])

  const handleSubmit = async (e: any) => {
    if (usertype === "Calendar") {
      updateService()
      handleCompleteClose()
    } else {
      e.preventDefault();
      let body: any = {
        businessId: businessData?._id,
        branchId: branchId,
        bookingStatus: "Completed",
        clientId: data?.Client?._id,
        clientUid: data?.Client?.cuId || "",
      }



      if (type === "calendar" && calendarData) {
        const existService = {
          TeamMemberId: calendarData?.services && calendarData?.services?.[0]?.TeamMemberId?.map((team: any) => team?._id),
          amenitiesId: calendarData?.services && calendarData?.services?.[0]?.amenitiesId?.map((am: any) => am?._id),
          promotionId: calendarData?.services?.[0]?.promotionId && calendarData?.services?.[0]?.promotionId?._id,
          serviceId: calendarData?.services?.[0]?.serviceId && calendarData?.services?.[0]?.serviceId._id,
          startTime: calendarData?.services?.[0]?.startTime && calendarData?.services?.[0]?.startTime,
          _id: calendarData?.services?.[0]?._id,
          bookingStatus: "Completed",
        }
        body = {
          businessId: businessData?._id,
          branchId: branchId,
          serviceObj: existService
        }
      }

      const path = calendarData && type === "calendar" ? `updateSubBooking/${calendarData?._id}` : `updateBooking/${data?._id}`;
      const res = await httpRequest(path, 'PATCH', body, "json");
      if (res?.status) {
        getBookings && getBookings()
        getBooking && getBooking()
        handleCompleteClose && handleCompleteClose()
        handleCustomerClose && handleCustomerClose()
      }
    }
  }

  const startMinutes = convertTimeToMinute(data?.salectTime)
  const endDuration = booking ? booking?.services?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.serviceId?.duration), 0) : 0
  const endMinutes: any = startMinutes + Number.parseInt(endDuration);
  const toTime: any = convertMinutesToTime(endMinutes)


  function handleChange(checked: any, id: any, type: string, bundleId: string = "") {
    let existData = [...updatedData]; // Copy the updatedData array

    if (type === "bundle") {
      let existServices = existData.filter((item: any) => item?.bundleId === id);
      let anotherServices = existData.filter((item: any) => item?.bundleId !== id);

      if (checked) {
        existServices = existServices.map((item: any) => ({
          ...item,
          bookingStatus: "Completed",
        }));
      } else {
        existServices = existServices.map((item: any) => ({
          ...item,
          bookingStatus: "Upcoming",
        }));
      }

      existData = [...anotherServices, ...existServices];
    } else {
      let existServices = existData.filter((item: any) => bundleId ? item?.bundleId === bundleId && item?.serviceId === id : item?.serviceId === id);
      let anotherServices = existData.filter((item: any) => bundleId ? item?.bundleId !== bundleId || item?.serviceId !== id : item?.serviceId !== id);

      console.log(existServices, anotherServices, "existAnotherServices", id);

      if (checked) {
        existServices = existServices.map((item: any) => ({
          ...item,
          bookingStatus: "Completed",
        }));
      } else {
        existServices = existServices.map((item: any) => ({
          ...item,
          bookingStatus: "Upcoming",
        }));
      }

      existData = [...anotherServices, ...existServices];
    }

    setUpdatedData(existData); // Update the state with the modified data
  }

  console.log(updatedData, "updatedDAtaaaa");


  const handleBundleSubmit = async (e: any) => {
    e.preventDefault();
    const services = updatedData?.sort((a: any, b: any) => {
      const timeA = a.startTime.split(":").map(Number);
      const timeB = b.startTime.split(":").map(Number);

      if (timeA[0] === timeB[0]) {
        return timeA[1] - timeB[1];
      }
      return timeA[0] - timeB[0];
    })

    let body: any = {
      businessId: businessData?._id,
      branchId: branchId,
      clientId: data?.Client?._id,
      clientUid: data?.clientUid ? data?.clientUid : booking?.clientUid,
      services: services,
      bookingStatus: services?.every((item: any) => item?.bookingStatus === "Completed") ? "Completed" : data?.bookingStatus
    }

    const path = `updateBooking/${data?._id}`;
    const res = await httpRequest(path, 'PATCH', body, "json");
    if (res?.status) {
      getBookings && getBookings()
      getBooking && getBooking()
      handleCompleteClose && handleCompleteClose()
      handleCustomerClose && handleCustomerClose()
    }
  }

  const startArr: any = data?.services?.[0]?.startTime?.split(":");

  const starthours: any = Number?.parseInt(startArr?.[0]);

  // const totalDuration: any = bookingDetils?.services?.reduce((acc: any, val: any) => Number(acc) + Number?.parseInt(val?.serviceId?.duration), 0)

  const totalDuration = data?.services?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.serviceId?.duration), 0)

  let newMinutes = startMinutes + totalDuration
  let endHour = starthours + Math.floor(newMinutes / 60);
  let endMinute = newMinutes % 60;

  const isBundle: any = data?.services?.some((item: any) => item?.bundleId);

  return (
    <>
      <Modal
        centered
        scrollable
        show={showComplete}
        backdrop="static"
        keyboard={false}
        onHide={handleCompleteClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={`${m.modalCts}  ${m.viewHistoryHead_imgBox}  ${m.profile_serviceDiscount_coupon}`}
      >
        <Modal.Header className="p-2">
          <Modal.Title>Mark Appointment as completed</Modal.Title>
          {/* <button
            className={`${m.closeIcon} ${m.v2}`}
            onClick={handleCompleteClose}
          >
            <MdClose />
          </button> */}
        </Modal.Header>
        <Modal.Body>
          <div className={m.slot}>
            <div className={m.bottom}>
              <div className={`${m.content} ${m.v2} px-0`}>
                <div className={`${m.contactInfo}`}>
                  <div className={`${m.left}`}>
                    <div className={m.imageBox}>
                      <span>{`${data?.Client?.firstName?.[0]?.toLocaleUpperCase()}${data?.Client?.lastName ? data?.Client?.lastName?.[0]?.toLocaleUpperCase() : ""}`}</span>
                    </div>
                    <div className={`${m.content}`}>
                      <h3>{`${data?.Client?.firstName} ${data?.Client?.lastName || ""}`} {data?.guestname ? ` (${data?.guestname})` : ""}</h3>
                      <p>{data?.Client?.country || ""}</p>
                    </div>
                  </div>
                </div>
                <div className={`${m.by_services_ClrBorder}`}>
                  <div className={`${m.by_services_flex}`}>
                    <div className={`${m.service} m-0`}>
                      <div className={m.serviceEntry}>
                        <img src={creditCardPlus} alt="card" />
                        <span>{data?.Client?.clientAccountNo || ""}</span>
                      </div>
                      {data?.Client?.mobile && <div className={m.serviceEntry}>
                        <div className={`${m.servicePhoneInput}`}>
                          <PhoneInput
                            defaultCountry="SG"
                            disabled
                            value={`+${data?.Client?.mobile}` || ""}
                            international
                            placeholder="Enter phone number" onChange={function (value?: Value): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        </div>
                      </div>}

                    </div>

                  </div>
                  <div className={`${m.by_services_flex}`}>
                    <div className={`${m.service} m-0`}>
                      <div className={m.serviceEntry}>
                        <img style={{ height: "30px", width: "30px" }} src={dateIcon} alt="schedule" />
                        <span>{formatDateFormat(data?.salectDate || "")}</span>
                      </div>
                      <div className={m.serviceEntry}>
                        <img style={{ height: "24px", width: "24px" }} src={clockA} className={`${m.v2}`} alt="time" />
                        <span>{data?.services?.[0]?.startTime}  - {endHour < 10 ? `0${endHour}` : endHour} : {endMinute < 10 ? `0${endMinute}` : endMinute}</span>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {serviceType !== "bundle" && <div className={`${m.newBundle_Main}`}>
            <div>
              {data?.services?.map((service: any, s: any) => {
                const startMinutes = convertTimeToMinute(service?.startTime)
                const endMinutes: any = startMinutes + Number.parseInt(service?.serviceId?.duration);
                const toTime: any = convertMinutesToTime(endMinutes);
                const serviceObj: any = updatedData?.find((item: any) => item?.serviceId === service?.serviceId?._id && item?.bundleId === service?.bundleId?._id)

                return (
                  <div className={`${m.boxes}`}>
                    <div className={`${m.newBundleServices} d-flex justify-content-between gap-3 align-items-baseline`}>
                      <div className={`${m.tops}`}>
                        <p className={`${m.specialy}`}>{service?.serviceCategoryDetails?.serviceCategoryName} : {service?.serviceDetails?.serviceName}</p>
                        <p className={`${m.times}`}>{service?.serviceId?.priceText || ""} {service?.serviceId?.duration} - {`SGD ${service?.serviceId?.seasonPrice}`} </p>
                        <p className={`${m.times}`}>{convertMinutesToTime(startMinutes)} - {toTime} ({service?.serviceId?.duration})</p>
                      </div>
                      <div className={`${st.formBox} w-auto m-0 d-inline-flex`}>
                        <label className={`${st.checkbox}`}>
                          <input type="checkbox" checked={serviceObj?.bookingStatus === "Completed"} onChange={(e: any) => handleChange(e.target.checked, service?.serviceId?._id, "service", service?.bundleId?._id)} />
                          <span className={`${st.checkmark}`}></span>
                        </label>
                      </div>
                    </div>
                  </div>
                )
              })}

            </div>

          </div>}


          {serviceType === "bundle" && <div className={`${m.newBundle_Main} mt-1`}>
            {bundleGroup?.map((item: any, b: any) => {
              const updatedArr: any = updatedData?.filter((data: any) => data?.bundleId === item?.[Object.keys(item)?.[0]]?.[0]?.bundleId?._id)
              const compltedBooking = updatedArr?.every((value: any) => value?.bookingStatus === "Completed");

              return (
                <React.Fragment key={b}>
                  <div className="d-flex justify-content-between mb-2" style={{ marginRight: '19px' }}>
                    <h5>{Object.keys(item)?.[0] || ""}</h5>
                    <div className={`${st.formBox} w-auto m-0 d-inline-flex`}>
                      <label className={`${st.checkbox}`}>
                        <input type="checkbox" checked={compltedBooking} onChange={(e: any) => handleChange(e.target.checked, item?.[Object.keys(item)?.[0]]?.[0]?.bundleId?._id, "bundle")} />
                        <span className={`${st.checkmark}`}></span>
                      </label>
                    </div>
                  </div>
                  <div>
                    {item?.[Object.keys(item)?.[0]]?.map((service: any, s: any) => {
                      const startMinutes = convertTimeToMinute(service?.startTime)
                      const endMinutes: any = startMinutes + Number.parseInt(service?.serviceId?.duration);
                      const toTime: any = convertMinutesToTime(endMinutes);
                      const serviceObj: any = updatedData?.find((item: any) => item?.serviceId === service?.serviceId?._id && item?.bundleId === service?.bundleId?._id)

                      return (
                        <div className={`${m.boxes}`}>
                          <div className={`${m.newBundleServices} d-flex justify-content-between gap-3 align-items-baseline`}>
                            <div className={`${m.tops}`}>
                              <p className={`${m.specialy}`}>{service?.serviceCategoryDetails?.serviceCategoryName} : {service?.serviceDetails?.serviceName}</p>
                              <p className={`${m.times}`}>{service?.serviceId?.priceText || ""} {service?.serviceId?.duration} - {`SGD ${service?.serviceId?.seasonPrice}`} </p>
                              <p className={`${m.times}`}>{convertMinutesToTime(startMinutes)} - {toTime} ({service?.serviceId?.duration})</p>
                            </div>


                            {/* <div className={`${st.formBox} w-auto m-0 d-inline-flex`}>
                              <label className={`${st.checkbox}`}>
                                <input type="checkbox" checked={serviceObj?.bookingStatus === "Completed"} onChange={(e: any) => handleChange(e.target.checked, service?.serviceId?._id, "service", service?.bundleId?._id)} />
                                <span className={`${st.checkmark}`}></span>
                              </label>
                            </div> */}
                          </div>
                        </div>
                      )
                    })}

                  </div>
                </React.Fragment>
              )
            })}
          </div>}


        </Modal.Body>
        <Modal.Footer>
          <div className={`${m.btnsAlignments} d-flex w-100`}>
            <NavLink
              className={`btn ${m.cancelBtn} w-100 mx-2 `}
              to="#"
              onClick={handleCompleteClose}
            >
              Cancel
            </NavLink>
            <button
              type="button"
              className={`btn ${m.actionBtn} w-100 mx-2 `} disabled={serviceType === "bundle" && !booking}
              onClick={(e: any) => handleBundleSubmit(e)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MarkCompletedModal;
