import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { getDynamicURIName } from "../../../Utils/Funcs";
import { useDispatch, useSelector } from "react-redux";
import StepperRow from "../../../components/Business/StepperBox/StepperRow";
import WellnezzaRoute from "./WellnezzaRoute";
import { httpRequest } from "../../../Apis/commonApis";
import { updateCounter } from "../../../redux_toolkit/reducer/shareDataReducer";

const Wellnezza = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch()
  let { businessData, counter } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const dynamicName = getDynamicURIName(location.pathname);
  const [branchList, setBranchList] = useState<any>([])
  const [serviceList, setServiceList] = useState<any>([])
  const [teamList, setTeamList] = useState<any>([])
  const [activeTab, setActiveTab] = useState(dynamicName);

  useEffect(() => {
    if (dynamicName) {
      setActiveTab(dynamicName);
    }
    if (dynamicName === 'basic') {
      handleTabSelect('info');
    }

  }, [location, dynamicName]);

  const getTeamMember = async () => {
    const res: any = await httpRequest(`getAllTeamMembers?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res?.status) {
      dispatch(updateCounter({ ...counter, team: res?.data?.length }))
      setTeamList(res?.data)
    }
  };

  const getBranch = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData?._id}&type=list`, "get", null, "json");
    if (res.status) {
      setBranchList(res?.data)
      await getService()
    }
  };
  const getService = async () => {
    const res = await httpRequest(`getBusinessService?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res.status) {
      dispatch(updateCounter({ ...counter, service: res?.data?.length }))
      setServiceList(res?.data)
      await getTeamMember();
    }
  };

  useEffect(() => {
      if (businessData?.stepCompleted === 9) {
        getBranch();
      }
  }, [businessData])

  useEffect(() =>{
    if (businessData?.stepCompleted === 9) {
      dispatch(updateCounter({ ...counter, branch: branchList?.length , service: serviceList?.length, team: teamList?.length}))
    }
  },[branchList, serviceList, teamList])

  const handleTabSelect = (tab: any) => {
    setActiveTab(tab);
    navigate(`${tab}`, { replace: true });
  };

  return (
    <>
      <section className={`${st.pageWrapper} ${st.maxWidth_pageWrapper}`}>
        <StepperRow />

        <div className={`${st.newRedesign_main}`}>
          {/* <Row className="align-items-center">
            <Col md={12}>
              <div className={`${st.head_paraBox}`}>
                <h2>Publish your business on Wellnezza</h2>
                <p>Provide address of the Business location. Welnezza support multiple branches.</p>
              </div>
            </Col>
          </Row> */}

          <div className={`${st.tabbingBox} mt-0`}>
            <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleTabSelect}>
              {/* <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="business">
                    Business Page Info
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="branch">
                    Branch and location
                  </Nav.Link>
                </Nav.Item>
                <span className={`${st.linespan}`}></span>
                <Nav.Item>
                  <Nav.Link eventKey="services">
                    Services
                  </Nav.Link>
                </Nav.Item>
              </Nav> */}
              <Tab.Content>
                <Tab.Pane eventKey={activeTab}>
                  <Routes>
                    <Route path={activeTab} element={<WellnezzaRoute dynamicName={dynamicName} />} />
                  </Routes>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>

          </div>
        </div>

      </section>
    </>
  );
};

export default Wellnezza;
