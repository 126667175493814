import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

import { Col, Nav, Row, Tab } from "react-bootstrap";
import { basicInfo, basicInfoA, membership } from "../../../assets/images";
import { useLocation } from "react-router-dom";

const ViewMemberShip = (props: any) => {
  const {state} = useLocation();
  const priceData = state.data;
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>{priceData?.Membership}</h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <Row>
                  <Col lg={3} md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">Membership</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{priceData?.Membership}</p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={3} md={4}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">Retail Price</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{`$${priceData?.retailPrice}`}</p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={6} md={4}></Col>

                  <Col lg={3} md={4}>
                    <div className={`${st.formBox} ${cx.iconPos} mt-2`}>
                      <label className="form-label">Validity</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{priceData?.Validity}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={4}>
                    <div className={`${st.formBox} ${cx.iconPos} mt-2`}>
                      <label className="form-label">Start Date</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{priceData?.startDate}</p>
                      </div>
                    </div>
                  </Col>
                 
                  <Col lg={3}></Col>

                  <Col lg={3} md={4}>
                    <div className={`${st.formBox} ${cx.iconPos} mt-2`}>
                      <label className="form-label">Amount Spent</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{`$${priceData?.amountSpent}`}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={4}>
                    <div className={`${st.formBox} ${cx.iconPos} mt-2`}>
                      <label className="form-label">Hours Booked</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{priceData?.hoursBooked} hours</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={4}>
                    <div className={`${st.formBox} ${cx.iconPos} mt-2`}>
                      <label className="form-label">Points</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{priceData?.Points}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default ViewMemberShip;
