import React from 'react';
// import { timeValues } from './Constants';

interface CustomTimeSelectProps {
    value: string;
    defaultValue?: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    timeValues: any;
    required: boolean;
    disabled?: boolean;
    className?: any;
    extraField?: any;
    marginTop?: any;
    style ? : any;
}

const CustomTimeSelect: React.FC<CustomTimeSelectProps> = ({ className = "",style = null, marginTop = "", extraField = "", value, disabled, defaultValue, onChange, timeValues, required }) => {

    return (
        <select className={`form-select ${className}`} onChange={onChange} disabled={disabled}
            defaultValue={defaultValue} value={value} required={required} style={extraField || marginTop ? { paddingRight: "31px", marginTop } : style ? style : {}}>
            <option value='' >-Select-</option>
            {extraField && <option value='Not applicable' >N/A</option>}
            {timeValues?.map((item: any, i: any) => (
                <option value={item.value} key={i}>
                    {item.label}
                </option>
            ))}
        </select>
    );
};

export default CustomTimeSelect;