import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

import { Col, Nav, Row, Tab } from "react-bootstrap";
import {
  basicInfo,
  basicInfoA,
  location,
  loacationA,
  alarmA,
  alarm,
  teamTab,
  teamTabA,
  editIcon,
} from "../../../assets/images";
import { Switch } from "../../../components/Website/Forms";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import { getDpName } from "../../../Utils/Funcs";
import { useSelector } from "react-redux";

const ViewTeam = (props: any) => {
  let { branchId } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const Location = useLocation();
  const navigate = useNavigate();

  const [everyDaySwitch, setEveryDaySwitch] = useState(false);
  const [assignedBranches, setAssignedBranches] = useState<any>([]);
  const [addedLeaves, setAddedLeaves] = useState([]);
  const [selectedServices, setSelectedServices] = useState<any>([]);
  const [teamdata, setTeamData] = useState<any>(null);

  const handleShiftSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEveryDaySwitch(event.target.checked);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = [
      { service: 'Service1', category: 'Category1' },
      { service: 'Service2', category: 'Category1' },
      { service: 'Service3', category: 'Category2' },
      // ... more data
    ];

    const combinedServices = Object.values(data.reduce((acc: any, item: any) => {
      const { service, category } = item;

      // If the category doesn't exist in the accumulator, create a new entry
      if (!acc[category]) {
        acc[category] = { category, services: [] };
      }

      // Push the service to the category's services array
      acc[category].services.push(service);
      return acc;
    }, {}));
  }, []);

  useEffect(() => {
    if (Location.state) {
      getTeamMemberData();
    }
  }, [Location.state]);

  const getTeamMemberData = async () => {
    let res = await httpRequest(`getAllTeamMembers?_id=${Location?.state?.teamId}&businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      ;
      let teamAlldata = res.data[0];
      setTeamData(teamAlldata);
      let assignedArr: any = [];
      teamAlldata?.hoursDetail?.forEach((elem: any) => {
        let isEvday = elem.shift[0]?.workingHours[0]?.day === 'All'
        assignedArr.push({
          branchName: elem.branchId?.branchName,
          branchId: elem.branchId?._id,
          isSameEveryday: isEvday,
          shift: elem.shift,
        });
      });
      let leavesArr: any = [];
      setAddedLeaves(res.leaveData[0]?.leaves?.filter((item: any) => item?.branchId?._id === branchId));
      setAssignedBranches(assignedArr);

      const combinedServices = Object.values(teamAlldata.serviceId?.reduce((acc: any, item: any) => {
        const { serviceName, serviceCatagoryId: { _id: serviceCatId, serviceCategoryName: serviceCatName } } = item;

        // If the category doesn't exist in the accumulator, create a new entry
        if (!acc[serviceCatId]) {
          acc[serviceCatId] = { serviceCatId, serviceCatName, services: [] };
        }

        // Push the service to the category's services array
        acc[serviceCatId].services.push(serviceName);
        return acc;
      }, {}));
      setSelectedServices(combinedServices)
    }
  };

  const handleEditClick = (endPoint: string) => {
    navigate(`/business/branch-teams/edit-team/${endPoint}`, { state: { teamId: Location.state.teamId } });
  };

  let isRoleGlobal = teamdata?.Role[0]?.branch ? false : true;
  let serviceCategoryList: any = []
  teamdata?.serviceId?.forEach((serviceItem: any) => {

    if (!serviceCategoryList?.some((catItem: any) => catItem?._id === serviceItem?.serviceCatagoryId?._id)) {
      serviceCategoryList.push(serviceItem?.serviceCatagoryId)
    }
  })


  const gloablRole = teamdata?.Role[0]?.role?.map((roleItem: any) => roleItem?.teamTitle)?.join(",")



  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {/* <BackArrrow /> */}
              team member
            </h5>
          </div>
          <div className={`${st.rightInfo}`}>
            <div style={{ color: "#3e8575" }}>Display Mode</div>
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew2">
                  <img src={location} alt="info" className={`${st.defalut}`} />
                  <img src={loacationA} alt="info" className={`${st.active}`} />
                  Branch Assignment
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew3">
                  <img src={teamTab} alt="info" className={`${st.defalut}`} />
                  <img src={teamTabA} alt="info" className={`${st.active}`} />
                  Services
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="tabNew4">
                  <img src={ok} alt="info" className={`${st.defalut}`} />
                  <img src={okA} alt="info" className={`${st.active}`} />
                  Working hours
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="tabNew4">
                  <img src={alarm} alt="info" className={`${st.defalut}`} />
                  <img src={alarmA} alt="info" className={`${st.active}`} />
                  Leaves
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew5">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Access Control
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <p>
                  <button className={` ${cx.editButton}`}>
                    <img src={editIcon} alt="edit" onClick={() => handleEditClick('info')} />
                  </button>
                </p>
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox} mb-3`}>
                      <div className={`${cx.logoBox}`}>
                        <div className={cx.box}>
                          {teamdata?.image ? (
                            <img
                              style={{
                                height: "150px",
                                width: "150px",
                                borderRadius: "15px",
                                objectFit: "cover",
                              }}
                              src={teamdata?.image}
                              alt="logo"
                            />) : (
                            <div className={`${cx.img_content_showName}`}><span>SS</span></div>)}
                        </div>
                        <label className={`${st.checkbox} ${cx.profileCheck}`}>
                          <input type="checkbox" checked={teamdata?.isPublic} disabled />
                          <span className={`${st.checkmark}`}></span>
                          <span className="ms-2">Image Visible to public</span>
                        </label>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">First Name</label>
                      <div className="position-relative d-flex align-items-center gap-2">
                        <div className={cx.editContainer}>
                          <p className="mb-1">{teamdata?.firstName}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Last Name</label>
                      <div className="position-relative d-flex align-items-center gap-2">
                        <div className={cx.editContainer}>
                          <p className="mb-1">{teamdata?.lastName}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Nick Name</label>
                      <div className="position-relative d-flex align-items-center gap-2">
                        <div className={cx.editContainer}>
                          <p className="mb-1">{teamdata?.nickName}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Email</label>
                      <div className="position-relative d-flex align-items-center gap-2">
                        <div className={cx.editContainer}>
                          <p className="mb-1">{teamdata?.email}</p>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Mobile Number</label>
                      <div className="position-relative d-flex align-items-center gap-2">
                        <div className={cx.editContainer}>
                        <p className="mb-1">{teamdata?.mobile !== "+undefined" ? teamdata?.mobile : ""}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">About</label>
                      <div className="position-relative d-flex align-items-center gap-2">
                        <div className={cx.editContainer}>
                          <p className="mb-1">{teamdata?.about}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}></Col>
                  <Col md={12}>
                    <div className={`${st.formBox} `}>
                      <label className={st.checkbox}>
                        <input type="checkbox" checked={teamdata?.isBooking} disabled />
                        <span className={`${st.checkmark}`}></span>
                        <span className="ms-2">
                          Profile Available for booking
                        </span>
                      </label>
                    </div>
                  </Col>
                  {/* <Col lg={3} md={2} className="d-flex align-items-center ">
                    <div className={`${st.formBox}  mt-3`}>
                      <label className={st.checkbox}>
                        <input type="checkbox" checked={isRoleGlobal} disabled />
                        <span className={`${st.checkmark}`}></span>
                        <span className="ms-2">Global</span>
                      </label>
                    </div>
                  </Col> */}
                 
                </Row>
                
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew2">
                <p>
                  <button className={` ${cx.editButton}`}>
                    <img src={editIcon} alt="edit" onClick={() => handleEditClick('branch')} />
                  </button>
                </p>
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                      <h2>Branch Assignment</h2>
                    </div>
                  </Col>
                </Row>
                {assignedBranches?.map((item: any, index: any) => {
                  if(item?.branchId !== branchId){
                    return
                  }
                  const filterRole = teamdata?.Role?.find((roleItem: any) => roleItem?.branch?._id === item?.branchId);
                  const smallRole = filterRole?.role?.map((item: any) => item?.teamTitle)?.join(" , ")

                  

                  return (
                    <div key={index} className="mt-2">
                      {index > 0 && <Row>
                        <Col md={12}>
                          <div className={`${st.formBox} ${cx.locationSelect}`}>
                            {/* <h2>Branch at</h2> */}
                          </div>
                        </Col>
                      </Row>}
                      {/* <Row>
                        <Col md={4}>
                          <div className={cx.editContainer}>
                            <p>{item.branchName}</p>
                          </div>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col md={3}>
                          <div className={`${st.formBox}`}>
                            <label className="form-label">Assigned Location</label>
                            <select
                              className="form-select"
                              disabled
                            >
                              <option value="" selected> {item.branchName}</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className={`${st.formBox}`}>
                            <label className="form-label">Role</label>
                            <select
                              className="form-select"
                              disabled
                            >
                              <option value="" selected> {smallRole}</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <>
                        <div className={`${cx.workingRow} mt-1`}>
                          <div className={`${cx.colms} ${cx.first}`}>
                            <h2 className={cx.shifHeading}>Working Hours</h2>
                            <div className={`${st.formBox} ${cx.everyDaySelector}`}>
                              <label className="form-label">Same everyday</label>
                              <div
                                className={st.greenSwitch}
                                style={{ background: `#fff` }}
                              >
                                <input
                                  type="checkbox"
                                  checked={item.isSameEveryday}
                                  disabled
                                  className={`${st.toggleInput}`}
                                />
                                <label className={st.toggleLabel}></label>
                                <div className={st.toggleThumb} style={{ background: `${!item.isSameEveryday ? "#7F8699" : "#1AB192"}` }}></div>
                              </div>
                            </div>
                          </div>
                          {item.isSameEveryday && <div className={cx.shiftBox}>
                            {item.shift?.map((elem: any) => {
                              return (<div className={`${cx.colms}`} key={elem.shiftNo}>
                                <h2 className={cx.shifHeading}>{elem.shiftNo}</h2>
                                {elem.workingHours.map((hrs: any, i: any) => {
                                  let showTimeInputs = (hrs.from !== '' && hrs.to !== '');
                                  return (<div className={cx.shift} key={i}>
                                    <div className={`${st.formBox} mb-0`}>
                                      {showTimeInputs && <select className="form-select" disabled>
                                        <option value='' >{hrs.from}</option>
                                      </select>}
                                    </div>
                                    <div className={`${st.formBox} mb-0`}>
                                      {showTimeInputs && <select className="form-select" disabled>
                                        <option value='' >{hrs.to}</option>
                                      </select>}
                                    </div>
                                  </div>
                                  )
                                })}
                              </div>
                              )
                            })}
                          </div>}
                        </div>
                      </>

                      {!item.isSameEveryday && <>
                        <div className={`${cx.workingRow} mt-1`}>
                          <div className={`${cx.colms} ${cx.first}`}>
                            <h2 className={cx.shifHeading}>&nbsp;</h2>
                            <div
                              className={`${st.formBox} ${cx.everyDaySelector}  ${cx.first} `}
                            >
                              <label className="form-label">Monday</label>
                            </div>
                            <div
                              className={`${st.formBox} ${cx.everyDaySelector} ${cx.days}`}
                            >
                              <label className="form-label">Tuesday</label>
                            </div>
                            <div
                              className={`${st.formBox} ${cx.everyDaySelector} ${cx.days}`}
                            >
                              <label className="form-label">Wednesday</label>
                            </div>
                            <div
                              className={`${st.formBox} ${cx.everyDaySelector} ${cx.days}`}
                            >
                              <label className="form-label">Thursday</label>
                            </div>
                            <div
                              className={`${st.formBox} ${cx.everyDaySelector} ${cx.days}`}
                            >
                              <label className="form-label">Friday</label>
                            </div>
                            <div
                              className={`${st.formBox} ${cx.everyDaySelector} ${cx.days}`}
                            >
                              <label className="form-label">Saturday</label>
                            </div>
                            <div
                              className={`${st.formBox} ${cx.everyDaySelector} ${cx.days} `}
                            >
                              <label className="form-label">Sunday</label>
                            </div>
                          </div>
                          <div className={cx.shiftBox}>
                            {item.shift?.map((elem: any) => {
                              if (elem?.workingHours?.some((work: any) => work?.from === "" || work?.to === "")) {
                                return
                              }

                              return (
                                <div className={`${cx.colms}`} key={elem.shiftNo}>
                                  <h2 className={cx.shifHeading}>{elem.shiftNo}</h2>
                                  {elem.workingHours.map((hrs: any, i: any) => {
                                    let showTimeInputs = (hrs.from !== '' && hrs.to !== '');
                                    return (
                                      <div className={cx.shift} key={i}>
                                        <div className={`${st.formBox}`}>
                                          <div
                                            className={`position-relative d-flex align-items-center gap-5 ${cx.inputBackground} ${cx.v2}`}
                                          >
                                            <div className={cx.editContainer}>
                                              <p>{showTimeInputs ? hrs.from : ''} &nbsp;</p>
                                            </div>
                                            <div className={cx.editContainer}>
                                              <p>{showTimeInputs ? hrs.to : ''}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </>}
                    </div>
                  )
                })}
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="tabNew3">
                <p>
                  <button className={` ${cx.editButton}`}>
                    <img src={editIcon} alt="edit" onClick={() => handleEditClick('services')} />
                  </button>
                </p>
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                      <h2>Services Member Can Offer</h2>
                      {/* <div>
                        <label className={`${st.radio}`}>
                          <input type="radio" name="category" />
                          <span className={`${st.checkmark}`}></span>All
                          Categories
                        </label>
                      </div> */}
                    </div>
                  </Col>
                </Row>
                {/* {selectedServices?.map((item: any, index: number) => {
                  return ( */}
                {/* <Row className="mb-2" key={item.serviceCatId}> */}
                <Row className="mb-2">
                  {/* <Col md={12}>
                        <div
                          className={`${st.formBox} ${cx.locationSelect} ${cx.v2}`}
                        >
                          <h2>{item.serviceCatName}</h2>
                        </div>
                      </Col> */}
                  {serviceCategoryList?.map((elem: any, i: number) => {
                    return (
                      <Col md={3} key={i}>
                        <article className={cx.teamBox}>
                          <div className={`${cx.left}`}>
                            <span>{elem?.serviceCategoryName?.slice(0, 2)?.toLocaleUpperCase()}</span>
                          </div>
                          <div className={`${cx.right}`}>
                            <h3 className={cx.memberName}>{elem?.serviceCategoryName}</h3>
                          </div>
                        </article>
                      </Col>
                    )
                  })}
                </Row>
                {/* ) */}
                {/* })} */}
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew4">
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                      <h2>Report not working timmings</h2>
                    </div>
                  </Col>
                </Row>
                {addedLeaves?.map((item: any, index: number) => {
                  let isCustomHrs = item.fromTime ? true : false;
                  return (
                    <Row key={index}>
                      {index === 0 && <>
                        <Col md={4}>
                          <div className={`${st.formBox}`}>
                            <label className="form-label">Assigned Location</label>
                            <div className="position-relative d-flex align-items-center gap-2">
                              <div className={cx.editContainer}>
                                <p className="mb-1">{item.branchId?.branchName}</p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col lg={8} md={8}></Col>
                      </>}

                      <Col lg={2} md={3}>
                        <div className={`${st.formBox} ${cx.dateBox} `}>
                          <label htmlFor="from" className="form-label">
                            From
                          </label>
                          <div className="position-relative d-flex align-items-center gap-2">
                            <div className={cx.editContainer}>
                              <p className="mb-1">{item.fromDate}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3}>
                        <div className={`${st.formBox} ${cx.dateBox} `}>
                          <label htmlFor="to" className="form-label">
                            To
                          </label>
                          <div className="position-relative d-flex align-items-center gap-2">
                            <div className={cx.editContainer}>
                              <p className="mb-1">{item.toDate}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className={cx.customHour}>
                          <div className={`${st.formBox} ${cx.toggle} ${cx.v2} `}>
                            <label htmlFor="to" className="form-label">
                              Custom Hours
                            </label>
                            <Switch
                              checked={isCustomHrs}
                              disabled={'true'}
                              color={everyDaySwitch ? "#7F8699" : "#1AB192"}
                              backgroundColor="#fff"
                              key={2}
                              id={"amenities"}
                              className={"mt-2"}
                            />
                          </div>

                          {isCustomHrs && <>
                            <div className={`${st.formBox} ${cx.customInput} `}>
                              <label htmlFor="from" className="form-label">
                                From
                              </label>
                              <div className="position-relative d-flex align-items-center gap-2">
                                <div className={cx.editContainer}>
                                  <p className="mb-1">{item.fromTime}</p>
                                </div>
                              </div>
                            </div>
                            <div className={`${st.formBox} ${cx.customInput} `}>
                              <label htmlFor="from" className="form-label">
                                To
                              </label>
                              <div className="position-relative d-flex align-items-center gap-2">
                                <div className={cx.editContainer}>
                                  <p className="mb-1">{item.toTime}</p>
                                </div>
                              </div>
                            </div>
                          </>}
                        </div>
                      </Col>
                    </Row>
                  )
                })}
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew5">
                <p>
                  <button className={` ${cx.editButton}`}>
                    <img src={editIcon} alt="edit" onClick={() => handleEditClick('accesscontrol')} />
                  </button>
                </p>
                {teamdata?.Role?.filter((roleItem:any) => roleItem?.accessManager && roleItem?.branch?._id === branchId)?.map((roleItem: any, i: any) => {                  
                  
                  return (
                    <Row key={i}>
                      <Col lg={3} md={4}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Branch</label>
                          <div className="position-relative d-flex align-items-center gap-2">
                            <div className={cx.editContainer}>
                              <p className="mb-1">{roleItem?.branchName}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} md={4}>
                        <div className={`${st.formBox}`}>
                          <label className="form-label">Access Manager</label>
                          <div className="position-relative d-flex align-items-center gap-2">
                            <div className={cx.editContainer}>
                              <p className="mb-1">{roleItem?.accessManager}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
                })}

                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default ViewTeam;
