import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import st from "../../../assets/stylesheet/style.module.scss";
import { deleteIcon, editButton } from '../../../assets/images';
import Status from '../../../components/Business/Form/Status';
import BranchItemList from '../Branch/BranchListItem';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCounter } from '../../../redux_toolkit/reducer/shareDataReducer';

function BranchInfo(props: any) {
  const { branchData, getBranches, setActiveTab } = props;
  let { counter } = useSelector((state: any) => state.shareDataReducer);
  const [published, setPublished] = useState<any>('');
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let publishedCount = 0
  const filterBranch = branchData?.filter((branch: any) => {
    if (branch?.isPublish) {
      publishedCount++
    }
    const publishCheck = published === '' || (published === 'publish' ? branch?.isPublish : !branch?.isPublish)
    return publishCheck
  })

  // useEffect(() => {
  //   dispatch(updateCounter({ ...counter, branch: publishedCount }))
  // }, [branchData])
  return (
    <>

      <div className={`${st.newRedesign_main}`}>
        <Row>
          <Col md={12}>
            <div className='d-flex gap-3 mb-3 justify-content-end'>
              <div className={`${st.reFormBox} mb-0`} style={{ width: '155px' }}>
                <select className="form-select" value={published} onChange={(e: any) => setPublished(e.target.value)}>
                  <option value="">All</option>
                  <option value="publish">List on Wellnezza</option>
                  <option value="unpublish">Unpublish</option>
                </select>
              </div>
              {/* {publishedCount !== 0 && <div className='text-end'>
                <button className={`${st.addGrey} btn`} onClick={() => setActiveTab('services')}>
                  Next
                </button>
              </div>} */}
            </div>
          </Col>
        </Row>

        {branchData?.length > 0 && <Row className="align-items-center">
          <Col md={7} className="mb-4 pb-2">
            <div className={`${st.publish_pagesHeading}`}>
              <p className='mb-0'>{branchData?.length} Location / branches will be published in Wellnezza</p>
            </div>
          </Col>
        </Row>}

        {filterBranch?.map((branch: any) => {
          return (
            <BranchItemList branch={branch} getBranches={getBranches} key={branch?._id} type='publish'/>
          )
        })}
        {/* <Row className="align-items-center mb-3" >
          <Col md={11}>
            <div className={`${st.edit_publishBranchMain}`}>
              <div className={`${st.topBox_withBtn}`}>
                <h5>Marine Parade Branch</h5>
                <ul className={`${st.btnList}`}>
                  <li title='edit'>
                    <button > <img src={editButton} alt="edit" /></button>
                  </li>
                  <li title='delete'>
                    <button> <img src={deleteIcon} alt="deleteIcon" /></button>
                  </li>
                  <li className="ms-2">
                    <Status />
                  </li>
                </ul>
              </div>
              <ul className={`${st.bottomBox_details}`}>
                <li className={`${st.leftWidth}`}>
                  <div className={`${st.infoSide}`}><span>Email:</span> ewewe</div>
                  <div className={`${st.infoSide}`}><span>Contact No:</span> 98765456790, 98765456790, 98765456790,</div>
                </li>
                <li>
                  <div className={`${st.infoSide}`}><span>Address:</span> eweuwewewewe</div>
                </li>
              </ul>
            </div>
          </Col>
        </Row> */}
      </div>

    </>
  )
}

export default BranchInfo