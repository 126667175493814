import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import cx from "./index.module.scss";
import { deals } from "../../../assets/images";
import { BsStarFill } from "react-icons/bs";

const Deals = (props: any) => {
  return (
    <>
      <div className={`${cx.dealsBox}`}>
        <div className={`${cx.dealsBoxImg}`}>
          <NavLink to="#"><img src={deals} alt="deals" className={`${cx.image}`} /></NavLink>
          <span className={`${cx.tag}`}>Free Styling</span>
          <div className={`${cx.dealsBoxBottom}`}>
            <p>On all services</p>
            <p><BsStarFill className={`${cx.icon}`}/> <b>5.0</b> <span>(673)</span></p>
          </div>
        </div>
        <div className={`${cx.dealsBoxBody}`}>
          <h3><NavLink to="#">Hair cut by Jimmy Jain</NavLink></h3>
          <p>west-coast, Singapore </p>
        </div>
      </div>
    </>
  );
};

export default Deals;
