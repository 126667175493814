import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

import { Col, Nav, Row, Tab, Table } from "react-bootstrap";
import {
  basicInfo,
  basicInfoA,
  plus,
  plusWhite,
  pricingTab,
  pricingTabA,
  search,
  tick,
} from "../../../assets/images";
import BackArrrow from "../../../components/Business/Buttons/BackArrow";
import MultiSelect from "../../../components/Business/MultiSelect/MultiSelect";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AddServiceModal from "../../../components/Business/Modals/AddServiceModal";

const ViewPackage = (props: any) => {
  // price type modal show state
  const navigate = useNavigate();
  const datalocation: any = useLocation();
  let state: any = datalocation?.state;

  const [serviceShow, setServiceShow] = useState<boolean>(false);
  const [selectedservices, setSelectedServices] = useState("Add A Services");

  const [showAddCategory, setAddCategoryShow] = useState(false);
  const handleAddCategoryClose = () => setAddCategoryShow(false);
  const handleAddCategoryShow = () => setAddCategoryShow(true);

  // set service using checkbox use
  const handleOptionChange = (event: any) => {
    setSelectedServices(event.target.value);
    setServiceShow(false); // Optionally close the price options after selection.

    //also write price type radio button value in price type select's option
  };

  const [durationData, setDurationData] = useState<any>([])
  useEffect(() => {
    const newServiceList = state?.Services?.reduce((acc: any, val: any) => {
      const exists = acc.some((item: any) => item?.serviceId?._id ? item.serviceId?._id === val.serviceId?._id : item.serviceId === val.serviceId);
      if (!exists) {
        return [...acc, val];
      }

      return acc;
    }, []);

    setDurationData(newServiceList)
  }, [state])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>{state?.PackageName}</h5>
          </div>
          <div className={`${st.rightInfo}`}>
            {<div style={{ color: "#3e8575" }}>Display Mode</div>}
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabNew2">
                  <img
                    src={pricingTab}
                    alt="info"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={pricingTabA}
                    alt="info"
                    className={`${st.active}`}
                  />
                  Pricing and Duration
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <Row>
                  <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">Package name</label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{state?.PackageName}</p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">
                        Service available for
                      </label>
                      <div className={cx.editContainer}>
                        <p className="mb-1">{`${state?.ServiceAvailableFor[0].toUpperCase()}${state?.ServiceAvailableFor.slice(1)}`}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}></Col>

                  <Col lg={12} md={12}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">Package Description</label>
                      <div
                        className={`${cx.editContainer} ${cx.v2}`}
                        dangerouslySetInnerHTML={{
                          __html: state?.PackageDescription,
                        }}
                      />
                      {/* <div className={cx.editContainer}>
                        <p className="mb-1">
                          {state?.PackageDescription==="" ? "No Description Found" : ""}
                        </p>
                      </div> */}
                    </div>
                  </Col>

                  <Col lg={12} md={12}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">
                        Package After Care Description
                      </label>
                      <div
                        className={`${cx.editContainer} ${cx.v2}`}
                        dangerouslySetInnerHTML={{
                          __html: state?.PackageAfterCareDescription,
                        }}
                      />
                    </div>
                  </Col>


                  <Col lg={12} md={12}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                      <label className="form-label">Terms and Conditions</label>
                      <div
                        className={`${cx.editContainer} ${cx.v2}`}
                        dangerouslySetInnerHTML={{
                          __html: state?.TermsAndConditions
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew2">
                {state?.typeOfService?.type === "duration" ? (
                  <>
                    <Row>
                      {/* <Col md={12}>
                        <h6 className={cx.subTitle}>Hair</h6>
                      </Col> */}
                    </Row>

                    <Row>
                      <Col lg={3} md={6}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Type of services</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">{state?.typeOfService?.type}</p>
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Number of hours</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">{durationData?.[0]?.noOfServices}</p>
                          </div>
                        </div>
                      </Col>

                    </Row>
                    <Row className={cx.listTitle}>
                      <Col md={4}>
                        <h5>Services</h5>
                      </Col>

                    </Row>
                    {state?.typeOfService?.type === "duration" && durationData?.map((item: any) => {
                      return (
                        <Row className={cx.listCardBox}>
                          <Col md={4}>
                            <article className={cx.teamBox}>
                              <div className={`${cx.left}`}>
                                <span>{item?.priceId?.serviceId?.serviceName?.slice(0, 2)?.toUpperCase()}</span>
                              </div>
                              <div className={`${cx.right}`}>
                                <h3 className={cx.memberName}>
                                  {item?.priceId?.serviceId?.serviceName}
                                </h3>
                                {/* <p className={cx.priceDesc}>
                                  {item?.priceId?.duration ? item?.priceId?.duration : ""}{" "}
                                  {` ${item?.priceId?.seasonType} ${item?.priceId?.seasonPrice ? `$${item?.priceId?.seasonPrice}` : ""}`}
                                </p> */}
                              </div>
                            </article>
                          </Col>

                        </Row>
                      );
                    })}
                    <Row className="mt-5">
                      <Col lg={3} xl={2} md={4}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Total Price</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">${state?.totalPrice}</p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={3} xl={2} md={4}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Package Regular Price</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">${state?.finalPrice}</p>
                          </div>
                        </div>
                      </Col>
                      {state?.typeOfService?.memberPrice && <Col lg={3} xl={2} md={4}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Package Member Price</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">${state?.typeOfService?.memberPrice}</p>
                          </div>
                        </div>
                      </Col>}
                    </Row>
                  </>
                ) : state?.typeOfService?.type === "bundle" ? (
                  <>
                    <Row>
                      <Col lg={3} md={6}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Type of services</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">{state?.typeOfService?.type}</p>
                          </div>
                        </div>
                      </Col>

                    </Row>
                    <Row className={cx.listTitle}>
                      <Col md={4}>{/* <h5>Services</h5> */}</Col>
                      <Col md={2}>
                        <h5>No of Person</h5>
                      </Col>

                      <Col md={2}>
                        <h5>Amount</h5>
                      </Col>
                    </Row>
                    {state?.Services?.map((item: any) => {

                      return (
                        <>
                          <Row className={cx.listCardBox}>
                            <Col md={4}>
                              <article className={cx.teamBox}>
                                <div className={`${cx.left}`}>
                                  <span>{item?.priceId?.serviceId?.serviceName?.slice(0, 2)?.toUpperCase()}</span>
                                </div>
                                <div className={`${cx.right}`}>
                                  <h3 className={cx.memberName}>
                                    {item?.priceId?.serviceId?.serviceName}
                                  </h3>
                                  <p className={cx.priceDesc}>
                                    {item?.priceId?.duration ? item?.priceId?.duration : ""}{" "}
                                    {` ${item?.priceId?.seasonType} ${item?.priceId?.seasonPrice ? `$${item?.priceId?.seasonPrice}` : ""}`}
                                  </p>
                                </div>
                              </article>
                            </Col>
                            <Col md={2}>
                              <div
                                className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                              >
                                <input
                                  type="number"
                                  value={item?.noOfPerson}
                                  className={`${cx.center} form-control`}
                                  placeholder="0"
                                  disabled
                                />
                              </div>
                            </Col>

                            <Col md={2}>
                              <div
                                className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                              >
                                <input
                                  type="number"
                                  value={item?.noOfServices}
                                  className={`${cx.center} form-control`}
                                  placeholder="0"
                                  disabled
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                    <Row className="mt-5">
                      <Col lg={3} xl={2} md={4}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Total Price</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">${state?.totalPrice}</p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={3} xl={2} md={4}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Package Regular Price</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">${state?.finalPrice}</p>
                          </div>
                        </div>
                      </Col>
                      {state?.typeOfService?.memberPrice && <Col lg={3} xl={2} md={4}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Package Member Price</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">${state?.typeOfService?.memberPrice}</p>
                          </div>
                        </div>
                      </Col>}
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col lg={3} md={6}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Type of services</label>

                          <div className={cx.editContainer}>
                            <p className="mb-1">{state?.typeOfService?.type}</p>
                          </div>
                        </div>
                      </Col>

                    </Row>
                    <Row className={cx.listTitle}>
                      <Col md={4}>
                        <h5>Services</h5>
                      </Col>
                      <Col md={2}>
                        <h5>Number of services</h5>
                      </Col>
                      <Col md={2}>
                        <h5>Amount</h5>
                      </Col>
                    </Row>
                    {state?.Services?.map((val: any) => {
                      return (
                        <Row className={cx.listCardBox} key={val?._id}>
                          <Col md={4}>
                            <article className={cx.teamBox}>
                              <div className={`${cx.left}`}>
                                <span>{val?.priceId?.serviceId?.serviceName?.slice(0, 2)?.toUpperCase()}</span>
                              </div>
                              <div className={`${cx.right}`}>
                                <h3 className={cx.memberName}>
                                  {val?.priceId?.serviceId?.serviceName}
                                </h3>
                                <p className={cx.priceDesc}>
                                  {val?.priceId?.duration ? val?.priceId?.duration : ""}{" "}
                                  {` ${val?.priceId?.seasonType} ${val?.priceId?.seasonPrice ? `$${val?.priceId?.seasonPrice}` : ""}`}
                                </p>
                              </div>
                            </article>
                          </Col>
                          <Col md={2}>
                            <div
                              className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                            >
                              <input
                                type="number"
                                value={val?.noOfServices}
                                className={`${cx.center} form-control`}
                                placeholder="Number of Hours"
                                disabled
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            <div
                              className={`${st.formBox} ${cx.formFullHeight} mb-0`}
                            >
                              <input
                                type="number"
                                value={val?.input}
                                className={`${cx.center} form-control`}

                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                    <Row className="mt-5">
                      <Col lg={3} xl={2} md={4}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Total Price</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">{`$${state?.totalPrice ? state?.totalPrice : ""}`}</p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={3} xl={2} md={4}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Package Regular Price</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">${state?.finalPrice}</p>
                          </div>
                        </div>
                      </Col>
                      {state?.typeOfService?.memberPrice && <Col lg={3} xl={2} md={4}>
                        <div className={`${st.formBox} ${cx.priceOption}`}>
                          <label className="form-label">Package Member Price</label>
                          <div className={cx.editContainer}>
                            <p className="mb-1">${state?.typeOfService?.memberPrice}</p>
                          </div>
                        </div>
                      </Col>}
                    </Row>
                  </>
                )}
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox}`}>
                      <div className={`${st.btnsGroup}`}>
                        <button
                          className={`btn ${st.btn1}`}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default ViewPackage;
