import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { ActiveDeactive } from "../Modals/ActiveDeactive";
import { httpRequest } from "../../../Apis/commonApis";
;


const Status = (props:any) => {
  const {disabled = false} = props
  const [showActiveDeactive, setActiveDeactiveShow] = useState(false);
  const handleActiveDeactiveClose = () => setActiveDeactiveShow(false);
  const handleActiveDeactiveShow = () => setActiveDeactiveShow(true);

  const [status, setStatus] = useState<any>(null)
  
  useEffect(() =>{
    setStatus(props.status)
  },[props.status])


  const updateStatus = async (activeStatus: any) => {
    let statusVal = activeStatus
    if (props.api) {
      let body: any;
      if (props.apiMethod === 'formdata') {
        body = new FormData();
        body.append("isActive", statusVal);
        if(!statusVal){
          body.append("isPublish", statusVal);
        }
      } else {
        body = {
          isActive: statusVal,
        };
        if(!statusVal){
          body = {...body, isPublish: statusVal}
        }
      }
      const res = await httpRequest(`${props.api}/${props.id}`,"PATCH",body,props.apiMethod);
      if (res?.status) {
        props.getLatest();
      }
    }
  };

  const handleActiveWithPopup = () =>{
    updateStatus(status)
    handleActiveDeactiveClose()
  } 
  return (
    <>
        <label className={`${st.switch}`} onClick={() => !disabled &&  handleActiveDeactiveShow}>
          <input className={`${st.switchInput}`} type="checkbox" disabled={disabled}  onClick={(e:any) => {
            setStatus(e.target.checked)
            handleActiveDeactiveShow()}}  checked={props.status}/> 
          <span className={`${st.switchLabel}`} data-on="Active" data-off="Deactive"></span> 
          <span className={`${st.switchHandle}`}></span> 
        </label>

        <ActiveDeactive show={showActiveDeactive} handleActiveDeactiveClose={handleActiveDeactiveClose} handleActiveWithPopup={handleActiveWithPopup}  status={status}/>
    </>
  );
};

export default Status;
