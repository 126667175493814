import React from 'react';
import cx from './index.module.scss';
import { teamPerson1 } from '../../../assets/images';

export default function RecentChat() {
    return (
        <div className={`${cx.notification_card}`}>
            <div className={`${cx.notification_header}`}>
                <div className={`${cx.notification_titleCount}`}>
                    <h2>Recent Chat Messages</h2>
                    <span className={`${cx.count}`}>1</span>
                </div>
                <div className={`${cx.notification_action}`}>
                    <button>View All</button>
                </div>
            </div>

            <ul className={`${cx.notification_item}`}>
                <li>
                    <div className={`${cx.notification_ImgPara}`}>
                        <img src={teamPerson1} alt="avatar" />
                        <div className={`${cx.paraBox}`}>
                            <p className={`${cx.paraTop}`}>Ava <span>on Mar 15 2024 at 02:45 pm</span></p>
                            <p className={`${cx.paraBottom}`}><span>@fenil</span> Hey! just checking if you're still posting the final version of the wellnezza design today?</p>
                        </div>
                    </div>
                </li>
                <li>
                    <div className={`${cx.notification_ImgPara}`}>
                        {/* <img src={teamPerson1} alt="avatar" /> */}
                        <div className={`${cx.avatarAlt}`}>AV</div>
                        <div className={`${cx.paraBox}`}>
                            <p className={`${cx.paraTop}`}>Ava <span>on Mar 15 2024 at 02:45 pm</span></p>
                            <p className={`${cx.paraBottom}`}><span>@fenil</span> Hey! just checking if you're still posting the final version of the wellnezza design today?</p>
                        </div>
                    </div>
                </li>
                <li>
                    <div className={`${cx.notification_ImgPara}`}>
                        <img src={teamPerson1} alt="avatar" />
                        <div className={`${cx.paraBox}`}>
                            <p className={`${cx.paraTop}`}>Ava <span>on Mar 15 2024 at 02:45 pm</span></p>
                            <p className={`${cx.paraBottom}`}><span>@fenil</span> Hey! just checking if you're still posting the final version of the wellnezza design today?</p>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    )
}
