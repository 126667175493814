import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import {
  bundles,
  clock,
  clockA,
  edit,
  packageActive,
  packageEmpty,
  packages,
  plus,
  rightIcon,
  schedule,
  serviceGreen,
  servicesActive,
  wrongIcon,
} from "../../../assets/images";
import cx from "./index.module.scss";
import { Col, Row } from "react-bootstrap";

const NotificationWeb = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperWebsite}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>Notification</h5>
          </div>
          <div
            className={`${st.buttonsAction} ${st.buttonflexDiv} w-100 mb-3 `}
          >
            <div className={`${st.threeButton} ${cx.btnGroup}`}>
              <ul>
                <li>
                  <button className={`${st.btn}`}>Active bookings</button>
                </li>
                <li>
                  <button className={`${st.btn} ${st.active}`}>
                    Past Bookings
                  </button>
                </li>
                <li>
                  <button className={`${st.btn} `}>Reviews</button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <Row>

            <Col lg={12} md={12}>
              <div className={cx.customer}>
                <div
                  className={cx.border}
                  role="img"

                ></div>
                <div className={cx.main}>
                  <div className={cx.top}>
                    <Row>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.date}>10 Jul, 2023</p>
                        <p className={cx.time}>10:20 am-11:20 am</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.service}>Freehand/Balayage Hair Cut</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={`${cx.status} ${cx.divider}`}>Stylist</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.status}>Client</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                        <p className={cx.status}>+380 96 63 10 000</p>
                      </Col>
                      <Col
                        lg={2}
                        md={3}
                        sm={3}
                        className="mb-3 d-flex align-items-center justify-content-between"
                      >
                        <p className={cx.name}>75USD</p>
                        <p className={cx.name}>Chair 7C</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={cx.bottom}>
                    <div className={`${cx.contentDiv} `}>
                      <p><span>Salon and spa</span> left a message for you. </p>
                      <p className={`${cx.successClr} `}> <span>Do you want straight or curls?</span></p>
                    </div>

                    <div className={`${cx.confirmBtn_box} `}>
                      <button className={`${cx.sucess_Btn} `}>Reply</button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={12} md={12}>
              <div className={`${cx.customer} ${cx.cancel}`}>
                <div className={`${cx.border} ${cx.cancel}`} role="img"></div>
                <div className={cx.main}>
                  <div className={cx.top}>
                    <Row>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.date}>10 Jul, 2023</p>
                        <p className={cx.time}>10:20 am-11:20 am</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.service}>Freehand/Balayage Hair Cut</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={`${cx.status} ${cx.divider}`}>Stylist</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.status}>Client</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                        <p className={cx.status}>+380 96 63 10 000</p>
                      </Col>
                      <Col
                        lg={2}
                        md={3}
                        sm={3}
                        className="mb-3 d-flex align-items-center justify-content-between"
                      >
                        <p className={cx.name}>75USD</p>
                        <p className={cx.name}>Chair 7C</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${cx.bottom} ${cx.cancel}`}>
                    <div className={`${cx.contentDiv} `}>
                      <p className={cx.cancel}>The booking was canceled.</p>
                      <p className={cx.cancel}><span>We do not have this stylist available</span> </p>
                    </div>
                    <div className={`${cx.confirmBtn_box} `}>
                      <button className={`${cx.sucess_Btn} ${cx.bordersucess_Btn}`}>Reschedule</button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={12} md={12}>
              <div className={cx.customer}>
                <div
                  className={cx.border}
                  role="img"

                ></div>
                <div className={cx.main}>
                  <div className={cx.top}>
                    <Row>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.date}>10 Jul, 2023</p>
                        <p className={cx.time}>10:20 am-11:20 am</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={cx.service}>Freehand/Balayage Hair Cut</p>
                      </Col>
                      <Col lg={3} md={4} sm={4} className="mb-3">
                        <p className={`${cx.status} ${cx.divider}`}>Stylist</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                      </Col>
                      <Col lg={2} md={4} sm={4} className="mb-3">
                        <p className={cx.status}>Client</p>
                        <p className={cx.name}>Anastasia Popadyk</p>
                        <p className={cx.status}>+380 96 63 10 000</p>
                      </Col>
                      <Col
                        lg={2}
                        md={3}
                        sm={3}
                        className="mb-3 d-flex align-items-center justify-content-between"
                      >
                        <p className={cx.name}>75USD</p>
                        <p className={cx.name}>Chair 7C</p>
                      </Col>
                    </Row>
                  </div>
                  <div className={cx.bottom}>
                    <div className={`${cx.contentDiv} `}>
                      <p><span>Salon and spa</span> wants to change your <span className={`${cx.successClr} `}>Saturday 9th sept, 2023</span> booking to <span className={`${cx.successClr} `}>Sunday 10th sept, 2023</span></p>
                    </div>

                    <div className={`${cx.confirmBtn_box} `}>
                      <button className={`${cx.sucess_Btn} `}>Accept</button>
                      <button className={`${cx.sucess_Btn} ${cx.bordersucess_Btn}`}>View Message</button>
                      <button className={`${cx.sucess_Btn} ${cx.bordersucess_Btn}`}>View Message</button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default NotificationWeb;
