import React, { useEffect, useState } from 'react';
import st from "../../../assets/stylesheet/style.module.scss";
import m from './Modal.module.scss';
import { deleteIcon } from '../../../assets/images';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../Apis/commonApis';

export default function BookingBundleDesc({ bundle, deleteServiceHandler, addService, selectedClient, data, formatStartTime, formattedDate, selectedServices }: any) {
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    let { businessData } = useSelector(
        (state: any) => state.shareDataReducer
    )
    const [promotionList, setPromotionList] = useState<any>([]);

    const getPromotion = async () => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (businessData) {
            let res = await httpRequest(`getPromotion?branchId=${branchId}&businessId=${businessData?._id}&timeZone=${userTimeZone}&bookingTime=${formatStartTime?.replaceAll(" ", "")}&bookingDate=${formattedDate}&clientId=${selectedClient?._id}&bookingId=${data?._id}`, "get", null, "json");
            if (res.status) {
                setPromotionList(res?.data?.filter((promo: any) => promo?.type === "bundle"));
            }
        }
    };
    

    let promo: any = [];    
    promotionList?.forEach(((pro: any) => {
        const promotionInServieCheck = pro?.type === "bundle" ? pro?.bundleId?.some((item: any) => item?.id?._id === bundle?._id) : false;
        if (!promotionInServieCheck) {
            return
        }

        let radeemedCount = +pro?.redeemCount;
        let clientUses = +pro?.clientUses;
        selectedServices?.forEach((serviceItem: any) => {
            if (serviceItem?.bundlePromotionId === pro?._id && serviceItem?.bundleId !== bundle?._id) {
                radeemedCount += 1
                clientUses += 1
            }
        });

        const checkMaxUserCritiria: any = !pro?.LimitNumberOfUses?.active ? true : radeemedCount < pro?.LimitNumberOfUses?.maxUser;
        const isclientInLimit: any = !pro?.LimitUsePerClient ? true : clientUses < pro?.LimitUsePerClient
        if (!isclientInLimit) {
            return
        }

        if (!checkMaxUserCritiria) {
            return
        }
        promo.push(pro)
    }))    

    const existBundle = selectedServices?.find((item: any) => item?.bundleId === bundle?._id);


    let selectedPromotionBundlePrice: any = ""
    if (existBundle?.bundlePromotionId) {
        const promotionObj = promo?.find((item: any) => item?._id === existBundle?.bundlePromotionId);
        if (promotionObj) {
            const bundleObj = promotionObj?.bundleId?.find((item: any) => item?.id?._id === bundle?._id);
            selectedPromotionBundlePrice = bundleObj?.promotionalPrice || ""
        }
    }

    useEffect(() => {
        if (selectedClient?._id) {
            getPromotion();
        }
    }, [formatStartTime, selectedClient]);

    return (
        <div className={m.bundleItem}>
            <div className={`${m.bundleDesc}`}>
                <h5 className={`${m.bundleName}`}>{bundle?.bundleName}</h5>
                <span className={m.bundlePrice}> <span style={selectedPromotionBundlePrice ? { color: "red", textDecoration: "line-through" } : {}}> {`SGD ${bundle?.finalPrice} `} </span> {selectedPromotionBundlePrice && <span>SGD{selectedPromotionBundlePrice}</span>}</span>
                {/* <img src={deleteIcon} style={{ cursor: "pointer" }} alt="delete" onClick={() => deleteServiceHandler(null, 0, null, bundle?._id)} /> */}
            </div>

            {promo?.length > 0 && <div className={m.promotion_item}>
                <div className={`${st.formBox} ${m.options}`}>
                    <select className="form-select" value={existBundle?.bundlePromotionId} onChange={(e: any) => {
                        const selectedPrmotion = promo?.find((item: any) => item?._id === e.target.value);
                        if (selectedPrmotion) {
                            const bundleWiseObj = selectedPrmotion?.bundleId?.find((item: any) => item?.id?._id === bundle?._id);
                            addService("bundlePromotion", e.target.value, 0, bundle?._id, null, bundle?._id, bundleWiseObj?.promotionalPrice)
                        } else {
                            addService("bundlePromotion", e.target.value, 0, bundle?._id, null, bundle?._id, "")
                        }
                        return
                    }}>
                        <option value="">Apply Promotion</option>
                        {promo?.map((pro: any) => {
                            return <option value={pro?._id} key={pro?._id}>{pro?.promotionName}</option>
                        })}
                    </select>
                </div>
            </div>}
        </div>
    )
}
