import React from 'react';
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { crossRed, successLarge, successTick } from "../../../assets/images";
import { IoArrowBackOutline } from 'react-icons/io5';

function EmailExist(props: any) {
    let { show, handleEmailExistClose } = props;
    const navigate = useNavigate()

    function handleLogin(){
       navigate("/business/login")
    }
    return (
        <>
            <Modal
                centered
                backdrop="static"
                show={show}
                onHide={handleEmailExistClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`${m.modalCts} ${m.modalsignUp}`}
            >
                <Modal.Body className="px-4 py-4">
                    {/* <div className={`${m.backSignUp_box}`}>
                        <NavLink className={`${m.backBtn}`} to={"#"} onClick={handleEmailExistClose}>
                            <IoArrowBackOutline />
                        </NavLink>
                    </div> */}
                    <div>
                        <h5 className={`${m.successHeading} mt-3`}>Email already Exist..</h5>
                        <p className={`${m.desc2}`}>
                            Hey there! It looks like your email is already exists  in our database.
                            If you've got an account, just sign in  in with your email. Forgot your password?
                            No worries, you can hit the reset button.
                        </p>
                        <p className={`${m.desc2}`}>
                            Need some help? Drop us a line at <span className={`${m.clrDarkGreen}`}>bizsupport@wellnezza.com.</span> We're here to help!
                        </p>
                    </div>
                    <Col md={12}>
                        <div className={`${st.formBox} ${m.formSign} mt-1 pt-1 mb-1`}>
                            <div className={`${st.btnsGroup} ${m.formSignGroup}`}>
                                <button
                                    className={`btn w-100 ${st.btn4} ${m.btnGray}`}
                                    style={{ minWidth: 'auto' }}
                                    onClick={handleLogin}
                                >
                                    Sign In
                                </button>
                                <button
                                    className={`btn w-100 ${st.btn1} ${m.btnGray} ${m.btnGrayLight}`}
                                    style={{ minWidth: 'auto' }}
                                >
                                    Reset Password
                                </button>
                            </div>
                            <div  className={` ${m.cancelGreenBtn}`}>
                                <button className='btn' onClick={handleEmailExistClose}>Cancel</button>
                            </div>
                        </div>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EmailExist