import React, { useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { DeletePopup } from "../../../components/Business/Modals";
import { deleteIcon, editIcon, openIcon } from "../../../assets/images";
import { Status } from "../../../components/Business";
import { httpRequest } from "../../../Apis/commonApis";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import { getBusinessData } from "../../../redux_toolkit/reducer/shareDataReducer";

const StatusButton = (props: any) => {
  const { params, data } = props;
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  let { businessData } = useSelector(
    (state: any) => state.shareDataReducer
  );
  const [apiMessage, setApiMessage] = useState<any>("")
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setApiMessage("");
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleViewClick = () => {
    let dataToView = data?.find((val: any, index: any) => {
      return val._id == params?.row?.id;
    });    
    navigate("view-service", { state: {...dataToView , BusinessService:dataToView} });
  }

  const handleEditClick = () => {
    let dataToEdit = data?.find((val: any) => {
      return val._id == params?.row?.id;
    });
    navigate("edit-service/info", { state: { serviceId: params?.row?.id, priceId: params?.row?.id } });
    // navigate("edit-service", { state: dataToEdit });
  }
  const deleteApi = async () => {
    const res = await httpRequest(
      `deleteBusinessService?_id=${params?.row?.id}&businessId=${businessData?._id}`,
      "DELETE",
      null,
      "json"
    );
    if (res.status === true) {
      props.getService();
      props?.getMainService();

      if(res?.data?.length ===0){
        dispatch(getBusinessData({ id: `businessId=${businessData?._id}` }));
      }
      handleClose();
    } else {
      setApiMessage(res?.message)
    }
  };

  const canEditService = subModuleRoles?.['Services']?.Services?.Edit;
  const canDeleteService = subModuleRoles?.['Services']?.Services?.Delete;

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
        {canEditService && <li>
            <p
              className={`btn ${tb.edit}`}
              title="Edit"
            >
              <img src={editIcon} alt="edit" onClick={() => {
                handleEditClick();
              }} />
            </p>
          </li>}
          <li>
            <p
              className={`btn ${tb.edit}`}
              title="view"
            >
              <img src={openIcon} alt="view"
                onClick={() => {
                  handleViewClick();
                }}
              />
            </p>
          </li>
          {canDeleteService && <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </li>}

        </ul>
      </div>

      <DeletePopup
        show={show}
        handleClose={handleClose}
        deleteApi={deleteApi}
        apiMessage={apiMessage}
      />
    </>
  );
};

export default function ServiceGridTable(props: any) {
  let data = props?.serviceList;
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [apiMessage, setApiMessage] = useState<any>("")
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const handleDeleteClose = () => {
    setApiMessage("");
    setShowDeletePopup(false);
  }

  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 170,
      renderCell: (params: any) => (
        <StatusButton
          data={data}
          params={params}
          getService={props.getService}
          getMainService={props.getMainService}
        />
      ),
    },
    { field: "category", headerName: "Category", flex: 2, minWidth: 120 },
    { field: "name", headerName: "Service Name", flex: 2, minWidth: 200 },
   
  ];

  let rows: any[] = [];
  if (data) {
    rows = data?.map((val: any, index: any) => {
      const priceType = `${val?.seasonType?.split("Price")[0][0]?.toLocaleUpperCase()}${val?.seasonType?.split("Price")[0]?.slice(1)} Price`;
      return {
        id: val?._id,
        serialNumber: index + 1,
        name: val?.serviceName,
        category: val?.ServiceCategory?.serviceCategoryName,
        action: "action",
      };
    });
  }

  const handleSelectionChange = (data: any) => {
    setSelectedRows(data);
  }
  const deleteServices = async () => {
    const body = {
      businessServiceIds: selectedRows
    }
    const res = await httpRequest(
      `deleteBusinessService`,
      "DELETE",
      body,
      "json"
    );
    if (res?.status == true) {
      props.getServiceCatagory();
      setShowDeletePopup(false)
      setApiMessage("")
    } else {
      setApiMessage(res?.message)
    }
  };
  return (
    <div className={`${tb.dataTable}`}>
      <div style={{ width: "100%" }}>
      <Col md={12}>
        <div className="text-end mb-2">
          <HelpButon number={26} type={"help"} className={"btn"} />
        </div>
      </Col>
        <DataGrid
          columns={columns}
          rows={rows}
          autoHeight
          hideFooterPagination={false}
          disableRowSelectionOnClick
          rowHeight={53}
          checkboxSelection={true}
          onRowSelectionModelChange={handleSelectionChange}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        {selectedRows.length > 1 && (
          <button
            className={`btn ${tb.delete} d-flex align-items-center gap-1`}
            onClick={() => setShowDeletePopup(true)}
          >
            <img src={deleteIcon} alt="Delete" />
            <span className="mt-1">Delete All</span>
          </button>
        )}
        <DeletePopup
          show={showDeletePopup}
          handleClose={handleDeleteClose}
          deleteApi={deleteServices}
          apiMessage={apiMessage}
        />
      </div>
    </div>
  );
}
