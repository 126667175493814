import React, { useEffect } from "react";
import st from "../../../assets/stylesheet/style.module.scss";

import { Col, Nav, Row, Tab } from "react-bootstrap";
import { basicAmenity, editIcon } from "../../../assets/images";
import BackArrrow from "../../../components/Business/Buttons/BackArrow";
import { NavLink } from "react-router-dom";

const ViewAmenity = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              <BackArrrow />
              <span>Edit Amenity</span>
            </h5>
          </div>
          <div className={`${st.rightInfo}`}></div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <NavLink to="/business/amenities/edit-amenity">
            <button className={st.editBtn}>
              <img src={editIcon} alt="edit" />
            </button>
          </NavLink>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img
                    src={basicAmenity}
                    alt="info"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={basicAmenity}
                    alt="info"
                    className={`${st.active}`}
                  />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
            <Tab.Pane eventKey="tabNew1">
                <Row className="align-items-center justify-content-between">
                  <Col md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Item Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Item Name"
                        disabled
                      />
                    </div>
                  </Col>

                  <Col md={6}>

                  </Col>

                  <Col md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Qty</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Qty"
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={6}></Col>

                  
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default ViewAmenity;
