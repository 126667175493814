import React, { useEffect, useState } from "react";
import PriceGrid from "./PriceGrid";
import { Col, Row } from "react-bootstrap";
import NewPrice from "../../../components/Business/Modals/NewPrice";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { copyClipBoard, deleteIcon, editIcon, plusWhite } from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import LoadingBtn from "../../../components/Business/Buttons/LoadingBtn";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pricetypeArr } from "../../../constants/Constants";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { FaEye } from "react-icons/fa";
import ServicesNotes from "../../../components/Business/Modals/ServicesNotes";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import { getBusinessData } from "../../../redux_toolkit/reducer/shareDataReducer";

const NewBranchPricing = (props: any) => {
    const { editServiceData, handleStepsFormData, businessPriceData, getBusinessPrice } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const [teamTitleList, setTeamTitleList] = useState<any>([])
    const [selectedPrice, setSelectedPrice] = useState<any>([])
    const [loading, setLoading] = useState<any>(false)
    const [priceError, setPriceError] = useState<any>(false);
    const [combinationError, setCombinationError] = useState<any>(false)
    const location = useLocation();
    const [deletedPriceId, setDeletedPriceId] = useState<any>([]);
    const [updatedId, setUpdatedId] = useState<any>("");
    const [data, setData] = useState<any>("")

    const [showServicesNotes, setServicesNotesShow] = useState(false);
    const handleServicesNotesClose = () => {
        setData("");
        setServicesNotesShow(false)
    };
    const handleServicesNotesShow = () => setServicesNotesShow(true);

    let { businessData } = useSelector((state: any) => state.shareDataReducer);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control,
    } = useForm();

    useEffect(() => {
        if (businessPriceData) {

            const finalPrice = businessPriceData?.map((item: any) => ({
                duration: `${Number.parseInt(item?.duration)}`,
                notes: item?.notes || "",
                priceText: item?.priceText || "",
                priceType: item?.priceType || "",
                seasonPrice: item?.seasonPrice,
                seasonType: item?.seasonType || "fixed",
                teamTitleId: item?.teamTitleId || "All",
                id: item?._id,
                _id: item?._id
            }))
            setSelectedPrice(finalPrice)
        }
    }, [businessPriceData])

    const getTitle = async () => {
        const res = await httpRequest(
            `getTeamTitle?businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setTeamTitleList(res?.data);
        }
    };

    useEffect(() => {
        getTitle()
    }, [businessData?._id])


    const onsubmit =async(data: any) => {
        const filterPrice = updatedId ? selectedPrice?.filter((item: any) => item?.id !== updatedId) : selectedPrice;

        const checkCombination = filterPrice?.some((priceItem: any) => {
            return priceItem?.duration === data?.duration && priceItem?.priceText?.toLocaleLowerCase()?.split(" ")?.join("") === data?.priceText?.toLocaleLowerCase()?.split(" ")?.join("") && priceItem?.teamTitleId === data?.teamTitleId
        })

        if (checkCombination) {
            setCombinationError(true);
            return
        }

        const updatedPrice: any = selectedPrice?.find((item: any, i: number) => item?.id === updatedId);
        const newData = { ...data, teamTitleId: data?.teamTitleId ? data?.teamTitleId : "All", priceType: data?.priceType ? data?.priceType : "fixed", seasonType: "regularPrice", id: uuidv4() }
        
        if (updatedId) {
            const res = await httpRequest(`updateBusinessPrice/${updatedId}`, "POST", { ...newData,duration: `${newData?.duration} mins`, teamTitleId: newData?.teamTitleId !== "All" ? data?.teamTitleId : null }, "json");
            if (res.status) {
                getBusinessPrice()
                reset()
                setValue("duration", "")
                setValue("notes", "")
                setValue("priceText", "")
                setValue("priceType", "fixed")
                setValue("seasonPrice", "")
                // setValue("seasonType", "")
                setValue("teamTitleId", "All")
                setUpdatedId("")

                setCombinationError(false);
            }
            return
        }


        setSelectedPrice([...filterPrice, newData]?.map((item: any) => ({ ...item, seasonPrice: `${Number.parseFloat(item?.seasonPrice)?.toFixed(2)}` })));
        reset()
        setValue("duration", "")
        setValue("notes", "")
        setValue("priceText", "")
        setValue("priceType", "fixed")
        setValue("seasonPrice", "")
        // setValue("seasonType", "")
        setValue("teamTitleId", "All")
        setUpdatedId("")

        setCombinationError(false);
    }

    const handleSave = async (e: any) => {
        e.preventDefault();

        if (selectedPrice?.length === 0) {
            setPriceError(true);
            return;
        }
        let srvCatId = editServiceData?.ServiceCategory?._id ? editServiceData?.ServiceCategory?._id : editServiceData?.serviceCategory;
        const newSelectedPrice = selectedPrice?.filter((price: any) => !price?._id);
        
        // setLoading(true); 
        const newLocal = {
            businessId: businessData?._id,
            branchId: editServiceData?.branchId?.map((item: any) => item?._id),
            serviceId: location.state?.serviceId,
            serviceCatagoryId: srvCatId,
            //   offPeakHours: offPeakHours,
            array: newSelectedPrice?.map((item: any, index: number) => ({
                duration: `${item?.duration} mins`,
                season: [{ ...newSelectedPrice?.[index], duration: `${item?.duration} mins`, teamTitleId: newSelectedPrice?.[index]?.teamTitleId === "All" ? null : newSelectedPrice?.[index]?.teamTitleId }]

            })),

            deletePrices: deletedPriceId
        };

        const data = newLocal;
        const res = await httpRequest("createBusinessPrice", "post", data, "json");
        if (res.status) {
            // handleStepsFormData(res, "team");
            handlePriceSelectionFromBranch(srvCatId, location.state?.serviceId, res?.data)
        }
        setLoading(false);
    };

    const handlePriceSelectionFromBranch = (ServCatId: string, ServId: string, priceData: any) => {
        let assingedArr = businessData?.originalAssignServiceArray?.[0];
        if (priceData) {
            let updatedCheckboxes: any = assingedArr ? { ...assingedArr.selectedCheckboxes } : {}
            let selectAllCheckboxes: any = assingedArr ? { ...assingedArr.customAllSrvboxes } : {}

            priceData?.forEach((price: any) => {
                updatedCheckboxes[branchId] = { ...updatedCheckboxes[branchId] };
                updatedCheckboxes[branchId][ServCatId] = { ...updatedCheckboxes[branchId][ServCatId] };
                selectAllCheckboxes[ServCatId] = { ...updatedCheckboxes[branchId][ServCatId] };

                updatedCheckboxes[branchId][ServCatId][ServId] = { ...updatedCheckboxes[branchId][ServCatId][ServId], checked: true };
                selectAllCheckboxes[ServCatId][ServId] = { ...updatedCheckboxes[branchId][ServCatId][ServId] };

                updatedCheckboxes[branchId][ServCatId][ServId][price._id] = true;
                selectAllCheckboxes[ServCatId][ServId][price._id] = { ...updatedCheckboxes[branchId][ServCatId][ServId][price._id], checked: false };
            });
            let updatedAssignedArr = [
                {
                    selectedCheckboxes: updatedCheckboxes,
                    customAllSrvboxes: selectAllCheckboxes,
                    allServicesChecked: assingedArr?.allServicesChecked ? assingedArr?.allServicesChecked : false
                }
            ];
            changeAssignArray(updatedAssignedArr);
        } else {
            handleStepsFormData(priceData, "amenities");
        }
    };

    const changeAssignArray = async (updatedObj: any) => {
        let body = {
            originalAssignServiceArray: updatedObj
        };
        const res = await httpRequest(`assignBusinessService?businessId=${businessData?._id}&fromBranch=${true}`, "PATCH", body, "json");
        if (res.status) {

            let userdata: any = JSON.parse(localStorage.getItem('user_data') || '');
            if (userdata !== '' && userdata?._id) {
                let bid = '';
                if (userdata?.userType === "teamMember") {
                    bid = `businessId=${userdata?.businessId}`;
                } else {
                    bid = `id=${userdata?._id}`;
                }
                dispatch(getBusinessData({ id: bid }));
            }
            handleStepsFormData(res, "amenities");
        }
    }


    const handleDelete = (index: any, priceId: any = null) => {
        const filterPrice = selectedPrice?.filter((item: any, i: number) => i !== index);

        if (priceId) {
            setDeletedPriceId([...deletedPriceId, priceId]);
        }

        setSelectedPrice(filterPrice)
    }

    const handleEdit = (index: any) => {
        const filterPrice: any = selectedPrice?.find((item: any, i: number) => i === index);

        setUpdatedId(filterPrice?.id)
        setValue("duration", filterPrice?.duration ? filterPrice?.duration : "")
        setValue("notes", filterPrice?.notes ? filterPrice?.notes : "")
        setValue("priceText", filterPrice?.priceText ? filterPrice?.priceText : "")
        setValue("priceType", filterPrice?.priceType ? filterPrice?.priceType : "")
        setValue("seasonPrice", filterPrice?.seasonPrice ? `${Number.parseInt(filterPrice?.seasonPrice)}` : "")
        // setValue("seasonType", filterPrice?.seasonType)
        setValue("teamTitleId", filterPrice?.teamTitleId ? filterPrice?.teamTitleId : "")
    }

    const handleCopy = (index: any) => {
        const filterPrice = selectedPrice?.find((item: any, i: number) => i === index);
        setValue("duration", filterPrice?.duration ? filterPrice?.duration : "")
        setValue("notes", filterPrice?.notes ? filterPrice?.notes : "")
        setValue("priceText", filterPrice?.priceText ? filterPrice?.priceText : "")
        setValue("priceType", filterPrice?.priceType ? filterPrice?.priceType : "")
        setValue("seasonPrice", filterPrice?.seasonPrice ? `${Number.parseInt(filterPrice?.seasonPrice)}` : "")
        // setValue("seasonType", filterPrice?.seasonType)
        setValue("teamTitleId", filterPrice?.teamTitleId ? filterPrice?.teamTitleId : "")
    }

    const skipStep = (e: any) => {
        e.preventDefault();
        handleStepsFormData("prevStep", "amenities");
    }

    const filterTeamTitleList = teamTitleList?.filter((titleItem: any) => titleItem?.customerService)

    return (
        <>
            <Row>
                <Col md={12}>
                    <div className="text-end">
                        <HelpButon number={17} type={"help"} className={"btn"} />
                    </div>
                </Col>
                <Col xl={2} lg={4} md={6} className="px-2">
                    <div className={cx.price_categorySelect}>
                        {/* <div className={st.formBox}>
                            <label className="form-label">Price Category</label>
                            <select className="form-select" {...register("seasonType", { required: true })}>
                                <option value="">--Select--</option>
                                {pricetypeArr?.map((item: any) => {
                                    return (
                                        <option value={item?.value}>{item?.label}</option>
                                    )
                                })}

                            </select>
                            {errors?.seasonType?.type === "required" && (
                                <p className={"errorMessage"}>This field is required</p>
                            )}
                        </div> */}
                        <div className={`${st.formBox}`} >
                            <label className="form-label">Team Title</label>
                            <select className="form-select" {...register("teamTitleId")}>
                                <option value="All">All</option>
                                {filterTeamTitleList?.map((item: any) => {
                                    return (
                                        <option value={item?._id}>{item?.teamTitle}</option>
                                    )
                                })}
                            </select>

                        </div>
                    </div>
                </Col>
                <Col xl={2} lg={4} md={6} className="px-2">
                    <div className={cx.price_categorySelect}>
                        <div className={st.formBox}>
                            <label className="form-label">Price Text</label>
                            <input className="form-control" placeholder="Price Text" {...register("priceText",)} />
                            {/* {errors?.priceText?.type === "required" && (
                                <p className={"errorMessage"}>This field is required</p>
                            )} */}
                        </div>

                    </div>
                </Col>
                <Col xl={3} lg={4} md={6} className="px-2">
                    <div className={cx.price_categorySelect}>
                        <div className={st.formBox}>
                            <label className="form-label">Duration <br /> <small>in min</small></label>
                            <input type="number" className="form-control"  {...register("duration", { required: true })} />
                            {/* <div className={cx.duration_withPrice}>
                                <span>|</span>
                                <input type="text" placeholder="mins" className="form-control" disabled />
                            </div> */}
                            {errors?.duration?.type === "required" && (
                                <p className={"errorMessage"}>This field is required</p>
                            )}
                        </div>
                        <div className={st.formBox}>
                            <label className="form-label">$ Price <br /> <small>SGD</small></label>
                            <input type="number" className="form-control"  {...register("seasonPrice", { required: true })} />
                            {/* <div className={cx.duration_withPrice}>
                                
                                <span>|</span>
                                <input type="text" placeholder="$" className="form-control" disabled />
                            </div> */}
                            {errors?.seasonPrice?.type === "required" && (
                                <p className={"errorMessage"}>This field is required</p>
                            )}
                        </div>
                        <div className={st.formBox}>
                            <label className="form-label">From/Fixed</label>
                            <select className="form-select" {...register("priceType")}>
                                <option value="fixed">Fixed</option>
                                <option value="from">From</option>
                            </select>

                        </div>
                    </div>
                </Col>
                <Col xl={5} lg={4} md={6} className="px-2">
                    <div className={cx.price_categorySelect}>
                        <div className={`${st.formBox}`} style={{ width: "75%" }}>
                            <label className="form-label">Notes on Price</label>
                            <input className="form-control"  {...register("notes")} />
                            {/* {errors?.notes?.type === "required" && (
                                <p className={"errorMessage"}>This field is required</p>
                            )} */}
                        </div>
                        {/* <button className="btn plusBtn" onClick={handleSubmit(onsubmit)}>{updatedId ? <img src={editIcon} alt="icon" /> : "+"}</button> */}
                        <button className="btn plusBtn" style={{ width: updatedId ? "20%" : "" }} onClick={handleSubmit(onsubmit)}>{updatedId ? "Update" : "Add"}</button>
                    </div>
                </Col>
            </Row>

            {combinationError && <Row className="mb-1">
                <Col>
                    <p className={"errorMessage"}>This combination is already exist try different one</p>
                </Col>
            </Row>}
            {priceError && <Row className="mb-1">
                <Col>
                    <p className={"errorMessage"}>Please add a price</p>
                </Col>
            </Row>}

            {selectedPrice?.map((item: any, i: number) => {
                const teamTitleObj = teamTitleList?.find((titleObj: any) => titleObj?._id === item?.teamTitleId)

                return (
                    <Row key={i}>
                        <Col xl={2} lg={4} md={6} className="px-2">
                            <div className={cx.price_categorySelect}>
                                {/* <div className={`${st.formBox} ${cx.displayBox}`} >
                                    <p>{item?.seasonType}</p>
                                </div> */}
                                <div className={`${st.formBox} ${cx.displayBox}`} >
                                    <p>{teamTitleObj?.teamTitle || "All"}</p>
                                </div>
                            </div>
                        </Col>
                        {<Col xl={2} lg={4} md={6} className="px-2">
                            <div className={cx.price_categorySelect}>
                                <div className={`${st.formBox} ${cx.displayBox}`} >
                                    <p>{item?.priceText || "No price text"}</p>
                                </div>
                            </div>
                        </Col>}
                        <Col xl={3} lg={4} md={6} className="px-2">
                            <div className={cx.price_categorySelect}>
                                <div className={`${st.formBox} ${cx.displayBox}`} >
                                    <p>{item?.duration}</p>
                                </div>
                                <div className={`${st.formBox} ${cx.displayBox}`} >
                                    <p>{item?.seasonPrice}</p>
                                </div>
                                <div className={`${st.formBox} ${cx.displayBox}`} >
                                    <p>{item?.priceType}</p>

                                </div>
                            </div>
                        </Col>
                        <Col xl={5} lg={4} md={6} className="px-2">
                            <div className={cx.price_categorySelect}>
                                <div className={`${st.formBox} ${cx.displayBox}`} style={{ width: '75%' }}>
                                    <div className={`${cx.eyeModal_relative}`}>
                                        <p>{item?.notes || "No notes"}</p>
                                        {item?.notes && <span className={`${cx.content_modal}`} onClick={() => {
                                            setData(item?.notes)
                                            handleServicesNotesShow()
                                        }}><FaEye /></span>}
                                    </div>
                                </div>
                                <div className={cx.edit_delete_btn}>
                                    {/* <label className="form-label">N</label> */}
                                    <ul>
                                        <li>
                                            <button className="btn" onClick={() => handleEdit(i)}>
                                                <img src={editIcon} alt="icon" />
                                            </button>
                                        </li>
                                        <li>
                                            <button className="btn" onClick={() => handleDelete(i, item?._id)}>
                                                <img src={deleteIcon} alt="icon" />
                                            </button>
                                        </li>
                                        <li>
                                            <button className="btn">
                                                <img src={copyClipBoard} alt="icon" onClick={() => handleCopy(i)} />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            })}
            <Row>
                <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={(e: any) => handleStepsFormData("prevStep", "info")}
                            >
                                Back
                            </button>
                            <button type='submit' onClick={skipStep} className={`btn ${st.btn1} ${cx.twoBtn}`} >
                                Skip
                            </button>
                            <button
                                className={`btn ${st.btn4}`}
                                onClick={handleSave}
                                disabled={loading}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

            <ServicesNotes show={showServicesNotes} handleServicesNotesClose={handleServicesNotesClose} data={data} />
        </>
    );
};

export default NewBranchPricing;
