import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Controller, useForm } from "react-hook-form";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { basicInfo, basicInfoA } from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "../../../components/Business/Modals/SuccessPopup";
import { disablePastDate, getDynamicURIName } from "../../../Utils/Funcs";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BasicInfo from "./BasicInfo";
import MembershipRouter from "./MembershipRouter";

const AddMemberShip = (props: any) => {
  let { businessData } = useSelector((state: any) => state.shareDataReducer);
  const [sellCheck, setSellCheck] = useState(false);
  const [achievementCheck, setAchievementCheck] = useState(false);
  const [membershipTier, setMemberShipTier] = useState<any>([0, 1, 2, 3, 4, 5]);
  const [showSuccessModal, setShowSuccessModal] = useState<any>(false);
  const [message, setMessage] = useState("");
  const [errorTrig, setErrorTrig] = useState(false);
  const [editData, setEditData] = useState<any>({})

  // const editData = location?.state ? location?.state : {}
  const Location = useLocation();
  const dynamicName = getDynamicURIName(Location.pathname);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(dynamicName);
  const [loading, setLoading] = useState<boolean>(false);

  // critiria value 


  const handleSuccessModalClose = () => setShowSuccessModal(false);

  const handleStepFormData = (data: any, step: string) => {
    if (step === "step1") {
      createMembership(data, "details")
    }
    if (step === "complete") {
      createMembership(data, step)
    }
  }

  const createMembership = async (data: any, nextStep: string) => {
    let finalData: any;
    setLoading(true);
    if (nextStep === "details") {
      finalData = {
        businessId: businessData?._id,
        Membership: data?.membership,
        tier: data?.tier,
        ValidityUnit: data?.ValidityUnit ? data?.ValidityUnit : null,
        ValidityFrequency: data?.ValidityFrequency ? data?.ValidityFrequency : null,
        retailPrice: data?.retailPrice ? data?.retailPrice : "",
        sell_Membership: sellCheck,
        criteria: data?.criteria ? data?.criteria : null,
        achievement_Membership: achievementCheck,
        criteriaUnit: data?.criteriaUnit ? data?.criteriaUnit : null,
        criteriaFrequency: data?.criteriaFrequency ? data?.criteriaFrequency : null,
        amountSpent: data?.amountSpent ? data?.amountSpent : null,
        hoursBooked: data?.hoursBooked ? data?.hoursBooked : null,
        Points: data?.Points ? data?.Points : null,
        startDate: data?.startDate && data?.criteria === "From Date" ? data?.startDate : ""
      };
    }
    if (nextStep === "complete") {
      finalData = data
    }

    const method = editData?._id ? "PATCH" : "POST";
    const endPoint = editData?._id ? `updateMembership/${editData?._id}` : "createMembership";
    const res = await httpRequest(
      endPoint,
      method,
      finalData,
      "json",
    );
    if (res.status) {
      setLoading(false);
      if (nextStep === "details") {
        setActiveTab(nextStep)
        navigate(`${nextStep}`, { state: res?.data?._id, replace: true });
        setLoading(false);
        return
      } else {
        setLoading(false)
        setMessage(res.message);
        setShowSuccessModal(true);
        // reset()
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        // reset();
        setErrorTrig(false);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (businessData?._id) {
      getMemberShip();
    }
  }, [businessData, Location?.state]);

  const getMemberShip = async function () {
    const res = await httpRequest(`getMembership?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      const selectedData = res?.data?.find((item: any) => item?._id === Location?.state)      
      setEditData(selectedData || {})
      const bookTier = res?.data?.map((mem: any) => Number(mem?.tier));      
      setMemberShipTier(membershipTier?.filter((tier: any) => !bookTier?.includes(tier)))
    }
  };

  useEffect(() => {
    if (membershipTier?.some((t: any) => !Number.isNaN(editData?.tier) && t !== Number(editData?.tier))) {
      const newTier = membershipTier?.filter((item: any) => !Number.isNaN(item))
      if (editData.tier || Number(editData?.tier) ==0) {
        setMemberShipTier([...newTier, Number(editData?.tier)]?.sort((a: any, b: any) => a - b))
      }
    }
  }, [editData])

  const handleTabSelect = (tab: any) => {
    if (Location.pathname.includes('edit-membership')) {
      setActiveTab(tab);
      navigate(`${tab}`, { state: Location?.state, replace: true });
    }
  };

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo}`}>
            <h5>
              {Location?.state ? `${editData?.Membership}` : "Add a New Membership"}
            </h5>
          </div>
          <div className={`${st.rightInfo}`}>
            {Location.pathname.includes('edit-membership') && <div style={{ color: "#3e8575", padding: "5px 50px" }}>Edit Mode</div>}
          </div>
        </div>

        <div className={`${st.tabbingBox} ${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" activeKey={activeTab}
            onSelect={handleTabSelect}>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="basic">
                  <img src={basicInfo} alt="basic" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="basic" className={`${st.active}`} />
                  Basic Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="details">
                  <img src={basicInfo} alt="details" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="details" className={`${st.active}`} />
                  Details
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                <Routes>
                  <Route path={activeTab}
                    element={<MembershipRouter
                      dynamicName={dynamicName}
                      handleStepFormData={handleStepFormData}
                      editData={editData}
                      membershipTier={membershipTier}
                      errorTrig={errorTrig}
                      setErrorTrig={setErrorTrig}
                      sellCheck={sellCheck}
                      setSellCheck={setSellCheck}
                      achievementCheck={achievementCheck}
                      setAchievementCheck={setAchievementCheck}
                      loading={loading}
                    />} />
                </Routes>

              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
      <SuccessPopup
        showSuccessModal={showSuccessModal}
        handleSuccessModalClose={handleSuccessModalClose}
        message={message}
        path="/business/membership"
      />
    </>
  );
};

export default AddMemberShip;
